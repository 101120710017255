import { css } from '@emotion/core';
import { Checkbox, TextField } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import moment from 'moment';
import Moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import Pagination from "react-js-pagination";
import { useDispatch, useSelector } from 'react-redux';
import { PulseLoader } from 'react-spinners';
import ReactTooltip from 'react-tooltip';
import { CSSTransition } from 'react-transition-group';
import swal from 'sweetalert';
import { refreshOff } from '../../../../actions/letterNotify';
import language from '../../../../language/language';
import ApprovalForm from './components/approvalForm';
import LeaveOfAbsenceForm from './components/leaveOfAbsenceForm';
import { getListDepartmentSupervisorSelectAll } from '../../../../api';
import 'moment-timezone';
import { formatTitleOption, formatTreeViewData } from '../../CommonFunction';
import TreeView from '../../TreeView';
import { Button } from 'react-bootstrap';

const NameTag = 'daysoff_form';

LeaveOfAbsenceLetterApproval.propTypes = {};
const portletBodyCss = {
  paddingTop: 0,
  paddingBottom: 0,
};
const actionButtonStyle = {
  fontFamily: 'inherit',
};
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const useStyles = makeStyles(() => ({
  root: {
    padding: '0',
  },
  paper: {
    whiteSpace: "nowrap"
  }
}));

const from_date = new Date(Moment().startOf('month').format("YYYY-MM-DD"));
const to_date = new Date(Moment().endOf('month').format("YYYY-MM-DD"));

function LeaveOfAbsenceLetterApproval() {
  const [fromDate, setFromDate] = useState(from_date);
  const [toDate, setToDate] = useState(to_date);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [offset, setOffset] = useState(0)

  const [crrData, setCrrData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [department, setDepartment] = useState(null);
  const [dataLetter, setDataLetter] = useState(null);
  const [listStaff, setListStaff] = useState([]);

  const box_engine_cf = useSelector(state => state.boxai.box_engine_cf);
  const token = useSelector(state => state.isLogin.access_token);

  const indexLanguage = useSelector(state => state.changelanguage.indexLanguage);
  const userData = JSON.parse(localStorage.getItem('data_user'));

  const isRefresh = useSelector(state => state.letterNotify.refresh);
  const dispatch = useDispatch()
  const status = [
    { name: language[indexLanguage].DayOff.all, value: [2, 3, 4] },
    { name: language[indexLanguage].DayOff.pending, value: [2] },
    { name: language[indexLanguage].DayOff.accept, value: [3] },
    { name: language[indexLanguage].DayOff.refused, value: [4] }
  ];

  const classes = useStyles();
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [checkbox, setCheckBox] = useState([]);
  const [statusCurrent, setStatusCurrent] = useState(status[1])
  const [selectDepartment, setSelectDepartment] = useState(false);
  const [departmentCurrent, setDepartmentCurrent] = useState({
    name: language[indexLanguage].placeholderSelect.all_department,
    id: -1,
  });
  const initial = {
    id: 0,
    status: -1,
    time_in: null,
    time_out: null,
  }

  const initialSearch = {
    id: userData ? userData.id_staff : 0,
    type_form: 4,
    type_search: 1,
    id_department: -1,
    arr_status: [2],
    start_date: Moment(from_date).format('YYYY-MM-DD 00:00:01'),
    end_date: Moment(to_date).format('YYYY-MM-DD 23:59:59'),
    data_search: '',
    index: 1,
    item_per_page: 10,
  }
  const dataLetterModal = initial;

  const [dataSearch, setDataSearch] = useState(initialSearch);
  const [letterRows, setLetterRows] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [buttonMultipleShow, setButtonMultipleShow] = useState(true);

  const type = localStorage.getItem("type");
  const id_staff = localStorage.getItem('id_user');
  const dataRole = useSelector(state => state.role.role);

  const totalItems = letterRows;
  const range = 5;
  const itemsForPage = 10;

  const getListStaffForDepartment = async () => {
    const result = await axios({
      method: 'POST',
      url: `${box_engine_cf.crrApiSocket}/api/staff/get_by_department`,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': token,
      },
      data: {
        id_company: box_engine_cf.idCompany,
        id_department: userData.id_department,
      }
    });
    if (result.data.status === 10000) {
      const data = result.data.data.map(map => ({
        id: map.id,
        name: `${map.name} (${map.id_staff})`,
      }));
      setListStaff(data);
    }
  }

  const getDataDepartment = async () => {
    const result = await getListDepartmentSupervisorSelectAll(box_engine_cf.crrApiSocket, id_staff, token);

    const data = result.map(map => ({
      name: map.title,
      id: map.value,
      code: map.code
    }))
    if (data.length <= 2) {
      data.shift();
      setDepartmentCurrent(data.length > 0 ? data[0] : null)
    } else {
      setDepartmentCurrent(data[0]);
    }

    setDepartment(data);
    if (data.length > 0) {
      const dataReq = {
        id: userData ? userData.id_staff : 0,
        type_form: 4,
        type_search: 1,
        id_department: data[0].id,
        arr_status: [2],
        start_date: Moment(from_date).format('YYYY-MM-DD 00:00:01'),
        end_date: Moment(to_date).format('YYYY-MM-DD 23:59:59'),
        data_search: '',
        index: activePage,
        item_per_page: itemsForPage
      }
      handleSearch(1, dataReq);
    } else {
      console.log('Department None, Stop code!')
    }
  }

  const handleChangeSearch = (event, value, name) => {
    if (name === 'arr_status') {
      return setDataSearch({
        ...dataSearch, [name]: value.value,
      })
    } else if (name === 'start_date') {
      return setDataSearch({
        ...dataSearch, [name]: Moment(value).format('YYYY-MM-DD') + ' 00:00:01',
      })
    } else if (name === 'end_date') {
      return setDataSearch({
        ...dataSearch, [name]: Moment(value).format('YYYY-MM-DD') + ' 23:59:59',
      })
    } else if (name === 'id_department') {
      return setDataSearch({
        ...dataSearch, [name]: value.id,
      })
    } else {
      return setDataSearch({
        ...dataSearch, [name]: value,
      })
    }


  }
  const handleOpenModal = (value) => {
    const time_in = moment(value.start_date).format('HH:mm');
    const time_out = moment(value.end_date).format('HH:mm');
    // const time_in = value.description ? JSON.parse(value.description).time_in : '';
    // const time_out = value.description ? JSON.parse(value.description).time_out : '';
    setDataLetter({
      id: value.id,
      name: value.staff.name,
      department: value.staff.department.name,
      id_staff: value.staff.id_staff,
      start_date: value.start_date,
      end_date: value.end_date,
      level: value.staff.level ? value.staff.level.name : "",
      supervisor: value.supervisor.name,
      status: value.status,
      time_in: value.in_late_minutes,
      time_out: value.out_soon_minutes,
      description: value.description ? JSON.parse(value.description).content : '',
      create_time: value.create_time
    })
    setShow(true);
  }
  const handleOpenModalForm = () => {
    setShowModal(true);
  }
  const closeModal = () => {
    setShow(false);
  }
  const closeModalForm = () => {
    setShowModal(false);
  }

  const checkStatus = (status) => {
    switch (status) {
      case 2: return <span style={{ color: 'orange', fontWeight: 'bold' }}>{language[indexLanguage].DayOff.pending}</span>
      case 3: return <span style={{ color: 'green', fontWeight: 'bold' }}>{language[indexLanguage].DayOff.accept}</span>
      case 4: return <span style={{ color: 'red', fontWeight: 'bold' }}>{language[indexLanguage].DayOff.refused}</span>

      default:
        break;
    }
  }
  const handleChangePage = (pageNumber) => {
    const index = (pageNumber - 1) * itemsForPage;
    setOffset(index);
    // const filterCountItems = letterRows.slice(index, index + itemsForPage);

    // setCrrData(filterCountItems);

    setDataSearch({
      ...dataSearch, index: pageNumber,
    })
    setActivePage(pageNumber);
  }

  useEffect(() => {
    handleSearch(activePage);
  }, [activePage])

  const handleUpdateStatus = (status, value) => {
    swal({
      title: status === 4 ? language[indexLanguage].DayOff.refuse : language[indexLanguage].DayOff.approve,
      text: status === 4 ? language[indexLanguage].Text_Notifi.notifi_sure_refuse_dayoff_form : language[indexLanguage].Text_Notifi.notifi_sure_accept_dayoff_form,
      buttons: {
        oke: language[indexLanguage].textButton.oke,
        cancel: language[indexLanguage].textButton.close
      },
    })
      .then(async (willDelete) => {
        if (willDelete === 'oke') {
          const result = await axios({
            method: 'POST',
            url: `${box_engine_cf.crrApiSocket}/api/daysoff_form/supervisor_handle_form`,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': token,
            },
            data: {
              arr_id: [value.id],
              status: status,
            },
          })

          if (result.data.status === 10000) {
            swal({ title: language[indexLanguage].Text_Notifi.success, text: language[indexLanguage].Text_Notifi.notifi_sure_success_dayoff_form, icon: "success", buttons: false, timer: 1500 });
            handleSearch(activePage);
          } else if (result.data.status === 10004) {
            const data = result.data.form_err;
            let name = '';
            data.map((map, index) => {
              if (index === 0) {
                name = map.staff.name
              } else {
                name = name + ' / ' + map.staff.name
              }
            })
            swal({
              title: language[indexLanguage].Text_Notifi.error,
              text: `${language[indexLanguage].Text_Notifi.notifi_sure_holidays_exceeded}${name}`,
              icon: "error",
            });
          } else if (result.data.status === 10003) {
            const data = result.data.staff_err;
            let name = '';
            data.map((map, index) => {
              if (index === 0) {
                name = map.name
              } else {
                name = name + ' / ' + map.name
              }
            })
            swal({
              title: language[indexLanguage].Text_Notifi.error,
              text: `${language[indexLanguage].Text_Notifi.notifi_sure_staff} ${name} ${language[indexLanguage].Text_Notifi.notifi_sure_not_start_day_work}`,
              icon: "error",
            });
          } else {
            swal({ title: language[indexLanguage].Text_Notifi.error, text: language[indexLanguage].Text_Notifi.notifi_sure_error_dayoff_form, icon: "error", buttons: false, timer: 1500 });
          }
        }
      });


  }

  const handleMultipleAcceptReject = (status) => {
    if (checkbox.length === 0) {
      return swal({
        title: language[indexLanguage].Text_Notifi.warning,
        text: language[indexLanguage].Text_Notifi.notifi_sure_not_dayoff_form,
        icon: 'warning',
        buttons: {
          oke: language[indexLanguage].textButton.oke,
        },
      });
    }
    swal({
      title: status === 4 ? language[indexLanguage].DayOff.refuse : language[indexLanguage].DayOff.approve,
      text: status === 4 ? language[indexLanguage].Text_Notifi.notifi_sure_refuse_dayoff_form : language[indexLanguage].Text_Notifi.notifi_sure_accept_dayoff_form,
      icon: 'warning',
      buttons: {
        oke: language[indexLanguage].textButton.oke,
        cancel: language[indexLanguage].textButton.close
      },
    })
      .then(async (willDelete) => {
        if (willDelete === 'oke') {
          const result = await axios({
            method: 'POST',
            url: `${box_engine_cf.crrApiSocket}/api/daysoff_form/supervisor_handle_form`,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': token,
            },
            data: {
              arr_id: checkbox,
              status: status,
            },
          })

          if (result.data.status === 10000) {
            swal({ title: language[indexLanguage].Text_Notifi.success, text: language[indexLanguage].Text_Notifi.notifi_sure_success_dayoff_form, icon: "success", buttons: false, timer: 1500 });
            handleSearch(activePage);
            setCheckBox([]);
            setIsCheckedAll(false);
          } else if (result.data.status === 10004) {
            const data = result.data.form_err;
            let name = '';
            data.map((map, index) => {
              if (index === 0) {
                name = map.staff.name
              } else {
                name = name + ' / ' + map.staff.name
              }
            })
            swal({
              title: language[indexLanguage].Text_Notifi.error,
              text: `${language[indexLanguage].Text_Notifi.notifi_sure_holidays_exceeded}${name}`,
              icon: "error",
            });
          } else if (result.data.status === 10003) {
            const data = result.data.staff_err;
            let name = '';
            data.map((map, index) => {
              if (index === 0) {
                name = map.name
              } else {
                name = name + ' / ' + map.name
              }
            })
            swal({
              title: language[indexLanguage].Text_Notifi.error,
              text: `${language[indexLanguage].Text_Notifi.notifi_sure_staff} ${name} ${language[indexLanguage].Text_Notifi.notifi_sure_not_start_day_work}`,
              icon: "error",
            });
          } else {
            swal({ title: language[indexLanguage].Text_Notifi.error, text: language[indexLanguage].Text_Notifi.notifi_sure_error_dayoff_form, icon: "error", buttons: false, timer: 1500 });
          }
        }
      });
  }


  const handleCheckBoxAll = () => {

    const dataCheckBox = crrData.filter(filter => filter.status === 2).map(li => li.id);

    if (!isCheckedAll[activePage - 1]) {

      let check_box = isCheckedAll;
      check_box[activePage - 1] = !check_box[activePage - 1];
      setIsCheckedAll(check_box);
      setCheckBox(state => state.concat(dataCheckBox));

    } else {
      let check_box = isCheckedAll;
      check_box[activePage - 1] = !check_box[activePage - 1];
      setIsCheckedAll(check_box);

      const newCheckBox = checkbox.filter(filter => !dataCheckBox.includes(filter));
      setCheckBox(newCheckBox);
    }
  }
  const multipleCheckbox = (e, id) => {

    setCheckBox([...checkbox, id]);
    if (!e.target.checked) {
      setCheckBox(checkbox.filter(items => items !== id))
    }

  }
  const refreshFilter = () => {
    const dataReq = {
      id: userData ? userData.id_staff : 0,
      type_form: 4,
      type_search: 1,
      id_department: departmentCurrent.id,
      arr_status: [2],
      start_date: Moment(from_date).format('YYYY-MM-DD 00:00:01'),
      end_date: Moment(to_date).format('YYYY-MM-DD 23:59:59'),
      data_search: '',
      index: 1,
      item_per_page: itemsForPage
    }

    handleSearch(1, dataReq);
    setStatusCurrent(status[1]);
    setDepartmentCurrent(department[0]);
    setDataSearch(dataReq);
    setFromDate(from_date);
    setToDate(new Date(to_date));
    setActivePage(1);
    setCheckBox([]);
    setIsCheckedAll(false);
  }
  const handleSearch = async (page, data) => {
    try {
      setLoading(true);
      const result = await axios({
        method: 'POST',
        url: `${box_engine_cf.crrApiSocket}/api/daysoff_form/search`,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        data: data ? data : dataSearch,
      })

      if (result.data.status === 10000) {
        //Sort by date create
        const sortByDateCreate = result.data.data;
        let dataFilter = checkSoonLate(sortByDateCreate);

        //filter count items pagination
        const index = (activePage - 1) * itemsForPage;
        setOffset(index)
        // const filterCountItems = sortByDateCreate.length > 10 ? sortByDateCreate.slice(index, index + itemsForPage) : sortByDateCreate;

        //Checkbox all

        let check_box = [];

        for (let i = 0; i < Math.ceil(dataFilter.length / itemsForPage); i++) {
          check_box.push(false);
        }

        setIsCheckedAll(check_box);
        setCrrData(dataFilter);
        setLetterRows(result.data.count);
        setLoading(false);
        setActivePage(page);
        if (isRefresh) {
          dispatch(refreshOff())
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    //Call API to fetch Data
    if (box_engine_cf) {
      getDataDepartment();
      getListStaffForDepartment();
    }
    // Check Super Admin Login
    if (userData === null) {
      localStorage.setItem('data_user', JSON.stringify({ id_staff: '', id_department: '' }));
    }
  }, [box_engine_cf]);

  const checkSoonLate = (data) => {
    for (let i = 0; i < data.length; i++) {
      const element = data[i];
      var shiftNow = element.shifts_work.filter((item) => {
        let day_of_week = item.day_of_week.slice(1, 14).split('|');
        let test = day_of_week[6];
        day_of_week.unshift(test);
        day_of_week.pop();
        return day_of_week[moment(element.start_date).isoWeekday()] === "1";
      })

      if (shiftNow.length > 0) {
        var time_in_shift = moment(element.start_date).format('YYYY-MM-DD') + " " + shiftNow[0].time_in.slice(0, 5) + ':00';

        var time_out_shift = moment(element.end_date).format('YYYY-MM-DD') + " " + shiftNow[0].time_out.slice(0, 5) + ':00';
        var time_in = moment(element.start_date);
        var time_out = moment(element.end_date);

        var in_late = time_in.diff(time_in_shift);
        var out_soon = time_out.diff(time_out_shift);

        var durationInLate = moment.duration(in_late);
        var durationOutSoon = moment.duration(out_soon);

        var minutesLate = durationInLate.asMinutes();
        var minutesSoon = durationOutSoon.asMinutes();

        element.in_late_minutes = Math.abs(minutesLate);
        element.out_soon_minutes = Math.abs(minutesSoon);

        if (moment(element.end_date).format("HH:mm:ss") === shiftNow[0].time_out) {
          element.end_date_blank = true
        }
        if (moment(element.start_date).format("HH:mm:ss") === shiftNow[0].time_in) {
          element.start_date_blank = true
        }
      }
    }
    return data
  }

  useEffect(() => {
    if (box_engine_cf && isRefresh) {
      const dataReq = {
        id: userData ? userData.id_staff : 0,
        type_form: 4,
        type_search: 1,
        id_department: departmentCurrent.id,
        arr_status: [2],
        start_date: Moment(from_date).format('YYYY-MM-DD 00:00:01'),
        end_date: Moment(to_date).format('YYYY-MM-DD 23:59:59'),
        data_search: '',
        index: 1,
        item_per_page: 10
      }

      handleSearch(1, dataReq);;
    }
  }, [box_engine_cf, isRefresh])

  useEffect(() => {
    const showButtonAcceptReject = () => {
      if (checkbox.length >= 1) {
        return setButtonMultipleShow(true);
      } else {
        return setButtonMultipleShow(false);
      }
    }
    showButtonAcceptReject();
  }, [checkbox])

  const handleBrowse = (value) => {
    const themeButton = dataRole.indexOf(`${type}/${NameTag}:supervisor_handle_form`) === -1 ? checkStatus(value.status) : value.status !== 2 ? checkStatus(value.status) :
      (
        <>
          <button
            type='button'
            className='btn btn-accent mr-2'
            style={{ padding: '7px 10px', fontFamily: 'inherit' }}
            onClick={() => handleUpdateStatus(3, value)}
          >
            {language[indexLanguage].DayOff.approve}
          </button>
          <button
            type='button'
            className='btn btn-danger'
            onClick={() => handleUpdateStatus(4, value)}
            style={{ padding: '7px 10px', fontFamily: 'inherit' }}
          >
            {language[indexLanguage].DayOff.refuse}
          </button>
        </>
      )
    return themeButton;
  }

  const createTableRow = () => {
    let tableRows = crrData && crrData.map((value, index) => {
      const time_in = moment(value.start_date).format('HH:mm');
      const time_out = moment(value.end_date).format('HH:mm');
      const description = value.description ? JSON.parse(value.description).content : '';
      return (
        <tr key={index}>
          {/* {dataRole.indexOf(`${type}/${NameTag}:supervisor_handle_form`) !== -1 &&
              <td>{value.status === 2 ?
                <Checkbox classes={{ root: classes.root }}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
                onChange={(e) => multipleCheckbox(e, value.id)}
                checked={checkbox.includes(value.id)}
            /> : ''
          }</td>} */}
          <td>{index + offset + 1}</td>
          <td>{value.staff.id_staff}</td>
          <td>{value.staff.name}</td>
          <td>{value.staff.department.name}</td>
          <td>{Moment.utc(value.create_time).tz('Asia/Ho_Chi_Minh').format("DD-MM-YYYY HH:mm")}</td>
          <td>{Moment(value.start_date).format('DD-MM-YYYY')}</td>
          <td>{Moment(value.end_date).format('DD-MM-YYYY')}</td>
          <td>{(value.start_date_blank && value.start_date_blank) ? '' : value.in_late_minutes}</td>
          <td>{(value.end_date_blank && value.end_date_blank) ? '' : value.out_soon_minutes}</td>
          <td>{value.supervisor.name}</td>
          <td style={{ whiteSpace: 'break-spaces' }}>{description}</td>
          <td>{value.confirm_person && value.confirm_person.name}</td>
          {<td>{handleBrowse(value)}</td>}
          <td>
            <button onClick={() => handleOpenModal(value)} className="m-portlet__nav-link btn m-btn m-btn--hover-warning m-btn--icon m-btn--icon-only m-btn--pill" data-tip data-for='View'>
              <i className="la la-eye" />
            </button>
            <ReactTooltip id='View' type='dark' effect='solid'>
              <span>{language[indexLanguage].tooltip.open_detail}</span>
            </ReactTooltip>
          </td>
        </tr>
      );
    });
    return tableRows;
  }
  return (
    <>
      <div className='row-sm row m-portlet__body'>
        <div className='col-md-1 my-1'>
          <DatePicker
            className='form-control m-input m_datetimepicker_6'
            selected={fromDate}
            selectsStart
            startDate={fromDate}
            endDate={toDate}
            onChange={(date) => {
              handleChangeSearch('', date, 'start_date');
              return setFromDate(date);
            }}
            dateFormat='dd-MM-yyy'
          />
        </div>
        <div className='col-md-1 my-1'>
          <DatePicker
            className='form-control m_datetimepicker_6'
            selected={toDate}
            startDate={fromDate}
            endDate={toDate}
            minDate={fromDate}
            selectsEnd
            onChange={(date) => {
              handleChangeSearch('', date, 'end_date');
              return setToDate(date);
            }}
            dateFormat='dd-MM-yyy'
          />
        </div>
        <div className="col-md-2 my-1">
          <Autocomplete
            className='select_option'
            options={status}
            onChange={(event, value) => {
              handleChangeSearch(event, value, 'arr_status')
              return setStatusCurrent(value);
            }}
            getOptionLabel={option => typeof option === 'string' ? option : option.name}
            value={statusCurrent}
            disableClearable
            renderInput={params => (<TextField {...params} variant="outlined" />)}
          />
        </div>
        <div className="col-md-2 my-1">
          {/* <Autocomplete
            className='select_option'
            options={department ? department : []}
            getOptionLabel={(reasonList) => reasonList.name}
            value={departmentCurrent}
            onChange={(event, value) => {
              handleChangeSearch(event, value, 'id_department')
              return setDepartmentCurrent(value);
            }}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                variant='outlined'
              />
            )}
            renderOption={(props, option, state) => (
              <div {...props}>
                {formatTitleOption(props)}
              </div>
            )}
            classes={{ paper: classes.paper }}
          /> */}
          <Button
            onClick={() => {
              setSelectDepartment(prev => !prev)
            }}
            className="btn btn-secondary btn-block m-btn m-btn--icon"
          >
            <span style={{ color: "#000000" }}>
              {departmentCurrent.value == -1 ? "Chọn phòng ban" : departmentCurrent.name}
            </span>
          </Button>
        </div>
        <div className="col-md-2 my-1">
          <input
            type='text'
            className='form-control m-input'
            placeholder={language[indexLanguage].DayOff.search_id_name}
            onChange={(e) => handleChangeSearch(e, e.target.value, 'data_search')}
            value={dataSearch.data_search}
          />
        </div>
        <div className="my-1">
          <button
            type='button'
            className='btn btn-accent m-btn m-btn--icon mx-md-1 mr-2'
            onClick={() => {
              handleSearch(1)
              setDataSearch({
                ...dataSearch, index: 1,
              })
            }}
            style={actionButtonStyle}
          ><span>
              <i className="la la-search" />
              <span>{language[indexLanguage].textButton.search}</span>
            </span>
          </button>
          <button
            type='button'
            className='btn btn-secondary m-btn m-btn--icon'
            onClick={() => refreshFilter()}
            style={actionButtonStyle}
          >
            <span>
              <i className="la la-rotate-left" />
              <span>{language[indexLanguage].textButton.reload}</span>
            </span>
          </button>
        </div>
        <div className='col-md-2 text-right' style={{ width: '50%' }}>
          {dataRole.indexOf(`${type}/${NameTag}:create_for_others`) !== -1 &&
            <button type="button" className="btn btn-accent m-btn m-btn--custom m-btn--icon m-btn--pill m-btn--air" onClick={() => handleOpenModalForm()} style={actionButtonStyle}>
              <span>
                <i className="la la-plus"></i>
                <span>{language[indexLanguage].textButton.add}</span>
              </span>
            </button>
          }
        </div>
      </div>
      {/* <div className="row-sm row m-portlet__body d-md-flex justify-content-md-between" style={{ height: '97px' }}>
        <div style={{ width: '50%' }}>
          {dataRole.indexOf(`${type}/${NameTag}:supervisor_handle_form`) !== -1 &&
            <CSSTransition
              timeout={300}
              in={buttonMultipleShow}
              unmountOnExit
            >
              <>
                <button
                  type='button'
                  className='btn btn-accent mr-md-2 mr-1'
                  onClick={() => handleMultipleAcceptReject(3)}
                  style={actionButtonStyle}
                >
                  {language[indexLanguage].DayOff.accept_multiple}
                </button>
                <button
                  type='button'
                  className='btn btn-danger'
                  onClick={() => handleMultipleAcceptReject(4)}
                  style={actionButtonStyle}
                >
                  {language[indexLanguage].DayOff.reject_multiple}
                </button>
              </>
            </CSSTransition>
          }
        </div>
        <div className='text-right' style={{ width: '50%' }}>
          {dataRole.indexOf(`${type}/${NameTag}:create_for_others`) !== -1 &&
            <button type="button" className="btn btn-accent m-btn m-btn--custom m-btn--icon m-btn--pill m-btn--air" onClick={() => handleOpenModalForm()} style={actionButtonStyle}>
              <span>
                <i className="la la-plus"></i>
                <span>{language[indexLanguage].textButton.add}</span>
              </span>
            </button>
          }
        </div>
      </div> */}
      <div className="row-sm row m-portlet__body" style={portletBodyCss}>
        {
          selectDepartment && (
            <div className='m-portlet__body dashboard_spinner col-xl-4'>
              <div style={{ maxHeight: "700px", overflow: "auto", marginRight: "20px", whiteSpace: "nowrap" }}>
                <TreeView
                  item={formatTreeViewData(department ? department : [])}
                  getList={(data) => {
                    // setSelectDepartment(false)
                    handleChangeSearch('', data, 'id_department')
                    setDepartmentCurrent(data);
                  }}
                />
              </div>
            </div>
          )
        }
        <div className={selectDepartment ? 'col-xl-8' : 'col-xl-12'}>
          <div className="table-responsive text-nowrap">
            {
              isCheckedAll &&
              <table className="table table-bordered table-hover table-checkable dataTable no-footer dtr-inline collapsed">
                <thead>
                  <tr>
                    {/* {dataRole.indexOf(`${type}/${NameTag}:supervisor_handle_form`) !== -1 && <th
                      style={{ verticalAlign: 'middle', width: '40px' }}
                    >
                      <Checkbox classes={{ root: classes.root }}
                        color="secondary"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                        onChange={() => handleCheckBoxAll()}
                        checked={isCheckedAll[activePage - 1]}
                      />
                    </th>} */}
                    <th style={{ 'verticalAlign': 'middle', 'width': '50px' }}>{language[indexLanguage].Other.no}</th>
                    <th style={{ 'verticalAlign': 'middle', width: '100px' }}>{language[indexLanguage].DayOff.id_staff}</th>
                    <th style={{ 'verticalAlign': 'middle', width: '160px' }}>{language[indexLanguage].DayOff.name}</th>
                    <th style={{ 'verticalAlign': 'middle', width: '180px' }}>{language[indexLanguage].DayOff.department}</th>
                    <th style={{ 'verticalAlign': 'middle', width: '130px' }}>{language[indexLanguage].DayOff.create_date}</th>
                    <th style={{ 'verticalAlign': 'middle', width: '150px' }}>{language[indexLanguage].DayOff.from_date}</th>
                    <th style={{ 'verticalAlign': 'middle', width: '150px' }}>{language[indexLanguage].DayOff.to_date}</th>
                    <th style={{ 'verticalAlign': 'middle', width: '130px' }}>{language[indexLanguage].DayOff.in_minute_late}</th>
                    <th style={{ 'verticalAlign': 'middle', width: '130px' }}>{language[indexLanguage].DayOff.in_minute_early}</th>
                    <th style={{ 'verticalAlign': 'middle', width: '180px' }}>{language[indexLanguage].DayOff.supervisor}</th>
                    <th style={{ 'verticalAlign': 'middle', minWidth: '150px' }}>{language[indexLanguage].DayOff.description}</th>
                    <th style={{ 'verticalAlign': 'middle', width: '180px' }}>{language[indexLanguage].DayOff.last_person}</th>
                    <th style={{ 'verticalAlign': 'middle', width: '180px' }}>{language[indexLanguage].DayOff.status}</th>
                    <th style={{ 'verticalAlign': 'middle', width: '50px' }}>{language[indexLanguage].DayOff.action}</th>
                  </tr>
                </thead>
                <tbody>
                  {createTableRow()}
                </tbody>
              </table>
            }
            <Pagination
              firstPageText={
                language[indexLanguage].pagination.first
              }
              nextPageText={
                language[indexLanguage].pagination.next
              }
              lastPageText={
                language[indexLanguage].pagination.last
              }
              prevPageText={
                language[indexLanguage].pagination.previous
              }
              activePage={activePage}
              itemsCountPerPage={itemsForPage}
              totalItemsCount={totalItems}
              pageRangeDisplayed={range}
              onChange={handleChangePage}
            />
            <ApprovalForm onHide={closeModal} show={show} onRefresh={handleSearch} page={activePage} data={dataLetter} />
            <LeaveOfAbsenceForm show={showModal} s_a_create={true} data={dataLetterModal} list_staff={listStaff} onHide={closeModalForm} onRefresh={handleSearch} />
            <PulseLoader
              css={override}
              sizeUnit={"px"}
              size={12}
              margin={'2px'}
              color={'#36D7B7'}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default LeaveOfAbsenceLetterApproval;
