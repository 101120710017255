import { css } from '@emotion/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import $ from 'jquery';
import QueueAnim from 'rc-queue-anim';
import React, { Component } from 'react';
import { Col, Image } from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay';
import { withRouter } from 'react-router';
import { PulseLoader } from 'react-spinners';
import FadeLoader from 'react-spinners/FadeLoader';
import openSocket from 'socket.io-client';
import swal from 'sweetalert';
import { getByCode, apiGetInfoStaff } from '../../../../api/integrate';
import { ARRAY_ENGINES, HOST_CLOUD } from '../../../../config';
import language from '../../../../language/language';
import { connect } from 'react-redux'
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;
var timeoutCallAPi;
class Integrated extends Component {
    constructor(props) {
        super(props);
        this.state = {
            crrApi: '',
            crrApi2: '',
            crrApiSocket: '',
            crrApiImg: '',
            indexlanguage: 1,
            id_company: '',
            Staff: {
                object_id: "",
                id: "",
                id_staff: "",
                name: "",
                id_company: "",
                token_biface: "",
            },
            dataFrame: {
                name: '',
                id_staff: '',
                id_company: '',
            },
            listChecked: {
                valueImg: [],
                _id: [],
            },
            listCamera: [],
            listImage: [],
            crrImages: [],
            images: [],
            valueCamera: '',
            loadingBtnCapture: false,
            loadingStopCapture: false,
            loadingDeleteImage: false,
            checkDeleteMulti: false,
            isCapture: false,
            activeCapture: true,
            max_training_images: 0,
            crrIdx: 0,
            activePage: 1,
            errorServer: '',
        };
        this.itemsPerPage = 10;
    }

    componentWillMount() {
        var { dataFrame } = this.state;
        var location = this.props.location.search.replace('?', '').split('&')
        if (location.length > 1) {
            dataFrame.name = location[0].split('=')[1];
            dataFrame.id_staff = location[1].split('=')[1];
            dataFrame.company_code = location[2].split('=')[1];
            dataFrame.token = location[3].split('=')[1];
        }
        this.setState({
            dataFrame
        });
    }
    
    componentWillUnmount() {
        this.socket.disconnect();
    }
    
    componentDidMount() {
        this.getByCompanyCode();
    }

    getByCompanyCode = async () => {
        try {
             const data = await getByCode(HOST_CLOUD, this.state.dataFrame.company_code);
            if (data.status === 10000) {
                if (data.data.companies.length > 0) {
                    for (let index = 0; index < data.data.companies.length; index++) {
                        for (let i = 0; i < data.data.companies[index].boxes_engine.length; i++) {
                            if (!ARRAY_ENGINES.includes(data.data.companies[index].boxes_engine[i].sub_engine_id + '')) {
                                data.data.companies[index].boxes_engine.splice(i, 1);
                                i--;
                            }
                            if (data.data.companies[index].boxes_engine[i] !== undefined) {
                                if (data.data.companies[index].boxes_engine[i].api_url !== null) {
                                    var dataApiSplit = data.data.companies[index].boxes_engine[i].api_url.split(';')
                                    if (dataApiSplit.length === 1) {
                                        data.data.companies[index].boxes_engine[i].api_url = dataApiSplit[0];
                                        data.data.companies[index].boxes_engine[i].api2_url_socket = data.data.companies[index].boxes_engine[i].api2_url;
                                        data.data.companies[index].boxes_engine[i].api_img = dataApiSplit[0];
                                    } else if (dataApiSplit.length === 3) {
                                        data.data.companies[index].boxes_engine[i].api_url = dataApiSplit[0];
                                        data.data.companies[index].boxes_engine[i].api2_url = dataApiSplit[1];
                                        data.data.companies[index].boxes_engine[i].api2_url_socket = dataApiSplit[2];
                                        data.data.companies[index].boxes_engine[i].api_img = dataApiSplit[0];
                                    } else if (dataApiSplit.length === 4) {
                                        data.data.companies[index].boxes_engine[i].api_url = dataApiSplit[0];
                                        data.data.companies[index].boxes_engine[i].api2_url = dataApiSplit[1];
                                        data.data.companies[index].boxes_engine[i].api2_url_socket = dataApiSplit[2];
                                        data.data.companies[index].boxes_engine[i].api_img = dataApiSplit[4];
                                    }
                                }
                            }
                        }
                    }

                    var valueCamera = ''
                    var { crrApi, crrApi2, crrApiSocket, id_company, crrApiImg } = this.state;
                    if (data.data.companies[0].boxes_engine[0] !== undefined) {
                        const title = data.data.companies[0].boxes_engine[0].alias_box_engine ?
                            `${data.data.companies[0].boxes_engine[0].alias_box_engine}(${data.data.companies[0].boxes_engine[0].id_box_engine})` :
                            data.data.companies[0].boxes_engine[0].id_box + '_' + data.data.companies[0].boxes_engine[0].id_box_engine;
                        valueCamera = {
                            title: title,
                            value: data.data.companies[0].boxes_engine[0].id_box_engine,
                            api: data.data.companies[0].boxes_engine[0].api_url,
                            api2: data.data.companies[0].boxes_engine[0].api2_url,
                            apisocket: data.data.companies[0].boxes_engine[0].api2_url_socket,
                            api_img: data.data.companies[0].boxes_engine[0].api_img,
                            url: data.data.companies[0].boxes_engine[0].view_url_local,
                            sub_engine: data.data.companies[0].boxes_engine[0].sub_engine_id
                        }

                        crrApi = data.data.companies[0].boxes_engine[0].api_url
                        crrApi2 = data.data.companies[0].boxes_engine[0].api2_url
                        crrApiSocket = data.data.companies[0].boxes_engine[0].api2_url_socket
                        crrApiImg = data.data.companies[0].boxes_engine[0].api_img
                        id_company = data.data.companies[0].id_company
                    }
                    this.setState({
                        crrApi,
                        crrApi2,
                        crrApiSocket,
                        crrApiImg,
                        valueCamera,
                        listCamera: data.data.companies[0].boxes_engine,
                        id_company,
                    }, () => {
                        this.getInfoStaff();
                    })
                } else {
                    this.setState({
                        activeCapture: false,
                        errorServer: language[this.props.indexLanguage].Text_Notifi.error_server,
                    });
                }
            } else {
                this.setState({
                    activeCapture: false,
                    errorServer: language[this.props.indexLanguage].Text_Notifi.error_server,
                });
            }
        } catch (error) {
            this.setState({
                activeCapture: false,
                errorServer: language[this.props.indexLanguage].Text_Notifi.error_server,
            });
            console.log(error)
        }
       
    }

    getInfoStaff = async () => {
        try {
            const result = await apiGetInfoStaff(this.state.crrApi2, this.state.id_company, this.state.dataFrame.id_staff, this.state.dataFrame.token);
            if (result.status === 10000) {
                const staff = {
                    object_id: result.data.object_id,
                    id: result.data.id,
                    id_staff: result.data.id_staff,
                    name: result.data.name,
                    id_company: result.data.id_company,
                    token_biface: this.state.dataFrame.token,
                }
                this.setState({
                    Staff: staff,
                }, () => {
                    this.getInfoPerson();
                })
            } else {
                this.setState({
                    activeCapture: false,
                    errorServer: language[this.props.indexLanguage].Text_Notifi.error_server,
                });
            }
        } catch (error) {
            this.setState({
                activeCapture: false,
                errorServer: language[this.props.indexLanguage].Text_Notifi.error_server,
            });
            console.log(error);
        }
        
    }

    getInfoPerson = () => {
        fetch(`${this.state.crrApi}/api/staff/getAllFace/${this.state.Staff['object_id']}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + this.state.dataFrame.token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                this.setState({
                    ...this.state,
                    listImage: [...data.img],
                    crrIdx: 0,
                    activeCapture: false
                }, () => {
                    this.FilterImg(1)
                })
            } else {
                this.setState({
                    ...this.state,
                    listImage: [],
                    activeCapture: false
                })
            }
        }).catch((error) => {
            if (error) {
                this.setState({
                    activeCapture: false
                });
                console.log(error)
            }
        })
    }

    FilterImg(activePage) {

        const { listImage } = this.state;

        const offset = (activePage - 1) * this.itemsPerPage;
        const crrImages = listImage.slice(offset, offset + this.itemsPerPage);

        this.setState({
            crrImages,
            showFirst: offset + 1,
            showLast: crrImages.length + offset,
            totalLength: listImage.length
        })
    }

    changeHandleCamera = (value, e) => {
        var { crrApi, crrApi2, crrApiSocket } = this.state
        crrApi = e.api
        crrApi2 = e.api2
        crrApiSocket = e.apisocket
        this.setState({
            crrApi,
            crrApiSocket,
            crrApi2,
            valueCamera: e,
        })
    }

    takeCapture = () => {
        if (this.state.crrApi === '') return;
        if(this.state.valueCamera === ''){
            return swal({
                title: language[this.props.indexLanguage].Text_Notifi.warning,
                text: language[this.props.indexLanguage].Text_Notifi.text_notifi_not_select_camera,
                icon: 'warning',
                buttons: false,
                timer: 1000,
            })
        }
        if (this.state.Staff.id_staff == "") {
            swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].Text_Notifi.text_notifi_select_staff, "warning");
        } else {
            this.setState({ loadingBtnCapture: true });
            fetch(`${this.state.crrApi}/api/staff/active_capture`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + this.state.Staff.token_biface
                },
                body: JSON.stringify({
                    "idCamera": this.state.valueCamera.value + '',
                    "id": this.state.Staff.id,
                    "id_staff": this.state.Staff.id_staff,
                    "name": this.state.Staff.name,
                    "abbreviated_name": "",
                    "birthday": "",
                    "gender": "",
                    "is_active": 1,
                    "is_delete": 0,
                    "id_department": "",
                    "id_level": ""
                })
            }).then((response) => {
                return response.json()
            }).then((data) => {
                if (data.status === 10000) {
                    $('#count_img').html(0)
                    this.setState({
                        max_training_images: data.data.max_training_images,
                        images: [],
                    }, () => {
                        if (this.socket != null) {
                            this.socket.disconnect();
                        }
                            this.socket = openSocket(`${this.state.crrApiSocket}`, { transport: ['websocket'] });
                        this.socket.on("connect", async () => {
                            console.log("Capture Successfully Connected");
                            this.socket.emit('joined', this.state.valueCamera.value + '');
                            this.setState({
                                activeCapture: true,
                                isCapture: true,
                                loadingBtnCapture: false
                            })
                        });

                        this.socket.on('my response', async (msg) => {
                            console.log(msg)
                            if (msg.operationType == 'insert') {
                                var count_img = $('#count_img').html();
                                if (parseInt(count_img) < this.state.max_training_images) {
                                    $('#count_img').html(parseInt(count_img) + 1)
                                }

                                var images = this.state.images;
                                if (this.state.activeCapture) {
                                    images.unshift(msg);
                                    this.setState({
                                        images: images
                                    })
                                }

                                if (images.length === this.state.max_training_images) {
                                    // clearTimeout(timeoutCallAPi);
                                    this.socket.disconnect();
                                    console.log('Socket Capture disconnect')
                                    swal({
                                        title: language[this.props.indexLanguage].Text_Notifi.success,
                                        text: language[this.props.indexLanguage].Text_Notifi.updated + " " + msg.name + " " + language[this.props.indexLanguage].Text_Notifi.success,
                                        icon: "success",
                                        button: "Oke!",
                                    });
                                    $('#count_img').html(0)
                                    this.setState({
                                        images: [],
                                        max_training_images: 0,
                                        activeCapture: false,
                                        isCapture: false,
                                        activePage: 1,
                                    }, () => {
                                        this.stopCapture()
                                    })
                                }
                            }
                        })

                        this.socket.on('disconnect', () => {
                            this.setState({
                                loadingBtnCapture: false
                            });
                        });
                    })
                } else if (data.status === 10001) {
                    $('#button_capture').removeClass('m-loader');
                    $('#button_capture').removeAttr('disabled', false);
                    swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].Capture.notifi_full_image, "warning");
                }else {
                    this.setState({ loadingBtnCapture: false });
                    swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].Text_Notifi.capture_false, "warning");
                }
                this.setState({
                    loadingBtnCapture: false,
                })
            }).catch((error) => {
                if (error) {
                    this.setState({ loadingBtnCapture: false });
                    swal(language[this.props.indexLanguage].Text_Notifi.warning, error, "warning");
                }
            })
        }
    }

    stopCapture = () => {
        this.setState({ loadingStopCapture: true });
        this.socket.disconnect();
        fetch(`${this.state.crrApi}/api/stopCapture/${this.state.valueCamera.value}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + this.state.Staff.token_biface
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status == 10000) {
                if (data.correct == 0) {
                    swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].Capture.notifi_stop_capture, "warning");
                }
                clearTimeout(timeoutCallAPi);
                this.setState({
                    loadingStopCapture: false
                }, () => {
                    this.getInfoPerson()
                })
            } else {
                this.setState({ loadingStopCapture: false });
                swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].Text_Notifi.stop_false, "error");
            }
        }).catch((error) => {
            if (error) {
                this.setState({ loadingStopCapture: false });
                swal(language[this.props.indexLanguage].Text_Notifi.warning, error, "warning");
            }
        })
    }

    handleCheckedImg = (event, value) => {
        let listChecked = this.state.listChecked;
        if (listChecked.valueImg.indexOf(event.target.value) === -1) {
            listChecked.valueImg.push(event.target.value)
            listChecked._id.push(value._id)
        } else {
            var i = listChecked.valueImg.indexOf(event.target.value);
            if (i != -1) {
                listChecked.valueImg.splice(i, 1);
                listChecked._id.splice(i, 1);
            }
        }
        this.setState({
            listChecked
        })
    }

    deleteFaceMulti = async (_idStaff, dataImg) => {
        this.setState({ loadingDeleteImage: true });
        await fetch(`${this.state.crrApi}/api/staff/deleteManyFace`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + this.state.Staff.token_biface
            },
            body: JSON.stringify({
                idStaff: _idStaff,
                imgFaces: dataImg._id,
                files: dataImg.valueImg
            })
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                let listImage = this.state.listImage;
                for (let index = 0; index < dataImg.valueImg.length; index++) {
                    for (let i = 0; i < listImage.length; i++) {
                        if (dataImg.valueImg[index] == listImage[i].imgFace) {
                            listImage.splice(i, 1)
                        }
                    }
                }
                var activePage = this.state.activePage;
                var totalCrrItem = this.itemsPerPage * this.state.activePage;
                if ((totalCrrItem - listImage.length) == this.itemsPerPage) {
                    activePage = activePage - 1
                }
                swal(language[this.props.indexLanguage].Text_Notifi.success, language[this.props.indexLanguage].Text_Notifi.delete_image_success, "success", {
                    buttons: false,
                    timer: 1500,
                });
                this.setState({
                    ...this.state,
                    listChecked: {
                        valueImg: [],
                        _id: [],
                    },
                    listImage: listImage,
                    activePage: activePage,
                    loadingDeleteImage: false,
                    checkDeleteMulti: false,
                }, () => {
                    this.FilterImg(this.state.activePage);
                });
            } else {
                swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].Text_Notifi.delete_image_error, "error", {
                    buttons: false,
                    timer: 1500,
                });
                this.setState({ loadingDeleteImage: false });
            }
        }).catch((error) => {
            if (error) {
                this.setState({ loadingDeleteImage: false });
                swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].Text_Notifi.delete_image_error, "error", {
                    buttons: false,
                    timer: 1500,
                });
            }
        });

    }

    handlePageChange = (pageNumber) => {
        console.log(`active page is ${pageNumber}`);
        this.setState({
            ...this.state,
            activePage: pageNumber,
            crrIdx: 0
        })
        this.FilterImg(pageNumber);
    }

    onErrorImage = (id) => {
        $("#" + id).attr("src", "/img/photo-placeholder.png");
    }

    render() {
        var optionsCamera = [];
        for (let index = 0; index < this.state.listCamera.length; index++) {
            if (ARRAY_ENGINES.includes(this.state.listCamera[index].sub_engine_id + '')) {
                const title = this.state.listCamera[index].alias_box_engine ?
                        `${this.state.listCamera[index].alias_box_engine}(${this.state.listCamera[index].id_box_engine})` :
                        this.state.listCamera[index].id_box + '_' + this.state.listCamera[index].id_box_engine;
                optionsCamera.push({
                    title: title, 
                    value: this.state.listCamera[index].id_box_engine, 
                    api: this.state.listCamera[index].api_url, 
                    api2: this.state.listCamera[index].api2_url, 
                    url: this.state.listCamera[index].view_url_local, 
                    sub_engine: this.state.listCamera[index].sub_engine_id,
                    apisocket: this.state.listCamera[index].api2_url_socket
                })
            }
        }

        let bulletedImg = this.state.crrImages.map((value, index) => {
            return (
                <Col sm={3} md={3} xs={4} className="p-0" key={index + 1}>
                    <div key="1" className='m-2'>
                        {
                            this.state.checkDeleteMulti == false ?
                                <Image style={{width: '100%', borderRadius: 3}} onError={() => this.onErrorImage('ImageSmall' + value._id)} id={'ImageSmall' + value._id} src={`${this.state.crrApiImg}/view/image/0/${value.idCamera}/${value['imgFace']}`} onClick={() => {
                                    this.setState({ crrIdx: index });
                                }} />
                                :
                                <label className="m-checkbox img-checkbox m-checkbox-day pl-0 mb-0" style={{ 'width': '100%' }}>
                                    <input type="checkbox" defaultValue={value.imgFace} name="img_checked" checked={this.state.listChecked.valueImg.indexOf(value.imgFace) == -1 ? false : true} onClick={e => this.handleCheckedImg(e, value)} readOnly />
                                    <Image style={{width: '100%', borderRadius: 3}} src={`${this.state.crrApiImg}/view/image/0/${value.idCamera}/${value.imgFace}`} onError={() => this.onErrorImage('ImageSmall' + value._id)} id={'ImageSmall' + value._id} className="opacity_img" />
                                    <span />
                                </label>
                        }
                    </div>
                </Col>
            )
        });

        if(this.state.errorServer){
            return <h4 className='p-2 d-flex text-center align-items-center justify-content-center' style={{widht: '100vw', height: '100vh'}}>{this.state.errorServer}</h4>
        }

        return (
            <div className='capture_frame'>
            <LoadingOverlay
                active={this.state.activeCapture}
                spinner={<FadeLoader
                    css={override}
                    sizeUnit={"px"}
                    size={150}
                    color={'rgb(54, 215, 183)'}
                    loading={true}
                />}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(0, 0, 0, 0.58)'
                    })
                }}
                text={
                    this.state.isCapture
                    &&
                    <div>
                        <div style={{color: 'black'}}>
                            {language[this.props.indexLanguage].Text_Notifi.text_notifi_loading_capture_frame} {language[this.props.indexLanguage].Capture.captured_faces}: <span id="count_img">0</span>/{this.state.max_training_images}
                        </div>
                        <div className="mb-4 mt-2">
                            <button style={{fontSize: 14}} className={"btn btn-danger m-loader--success m-loader--right stop-capture " + (this.state.loadingStopCapture && 'm-loader')} disabled={this.state.loadingStopCapture} onClick={() => this.stopCapture()}>{language[this.props.indexLanguage].Text_Notifi.stop_capture}</button>
                            </div>
                        <div className="row m-0 listImageCapture" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gridGap: '11px' }}>
                            {
                                this.state.images.map((image, index) => {
                                    return (
                                        <div key={index + 1}>
                                            <QueueAnim duration={1000} type="scale">
                                                <img src={`${this.state.crrApiImg}/view/image/0/${image.idCamera}/${image.imgFace}`} alt={index} key={index} style={{ borderRadius: 4, width: '100%' }} />
                                            </QueueAnim>
                                        </div>
                                    )
                                })
                            }
                        </div >
                    </div>
                }
                className={
                    this.state.UpdateDataToDevice === false
                        ?
                        "col-xl-12 p-0 loading_capture"
                        :
                        "col-xl-12 p-0"
                }
            >
                <div className="m-grid__item m-grid__item--fluid m-wrapper">
                    <div className="col-12 col-xl-6 m-auto px-0">
                        <div className='aa'>
                            <div className="p-3 m-auto d-flex justify-content-between border-bottom">
                                <label htmlFor="example-text-input" style={{ fontSize: 13 }} className="mb-0"><b>{`${this.state.Staff.name} - ${this.state.Staff.id_staff.split('_')[0]} (${this.state.listImage.length})`}</b></label>
                                <label className="mb-0">
                                    {
                                        this.state.crrImages.length > 0
                                        &&
                                        <button className='btn btn-link p-0' style={{textDecoration: 'none', fontSize: 15}} onClick={() => {
                                            this.setState({
                                                checkDeleteMulti: !this.state.checkDeleteMulti,
                                                listChecked: {
                                                    valueImg: [],
                                                    _id: [],
                                                }
                                            })
                                        }}>
                                            {this.state.checkDeleteMulti ? language[this.props.indexLanguage].Capture.unchecked : language[this.props.indexLanguage].Capture.choose_image}
                                                                </button>
                                    }
                                </label>
                            </div>
                            <div className="list_col_image my-4">
                                <div className="row col-md-12 pr-0 pl-0 m-0 pl-md-0 pl-sm-3 pull-left" style={{paddingBottom: 130}}>
                                    {
                                        bulletedImg
                                    }
                                </div>
                                <div className="text-center col-md-12">
                                    <PulseLoader
                                        css={override}
                                        sizeUnit={"px"}
                                        size={12}
                                        margin={'2px'}
                                        color={'#36D7B7'}
                                        loading={false}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="position-fixed" style={{ bottom: 0, left: 0, width: '100vw', backgroundColor: 'white', zIndex: 2 }}>
                            <div className="select_option p-0 m-2 mb-3">
                                <Autocomplete
                                    options={optionsCamera}
                                    getOptionLabel={option => option.title}
                                    value={this.state.valueCamera}
                                    onChange={this.changeHandleCamera}
                                    disableClearable
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            placeholder="Camera"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                />
                            </div>
                            <div className="m-auto text-center pb-3">
                                {
                                    this.state.checkDeleteMulti
                                        ?
                                        <button
                                            onClick={(e) => {
                                                if (this.state.listChecked.valueImg.length > 0) {
                                                    e.preventDefault();
                                                    if (!window.confirm(language[this.props.indexLanguage].Text_Notifi.text_notifi_delete_item)) {
                                                        return;
                                                    }
                                                    this.deleteFaceMulti(
                                                        this.state.Staff['object_id'],
                                                        this.state.listChecked
                                                    );
                                                } else {
                                                    swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].Capture.check_move_img, "warning");
                                                }
                                            }}
                                                className={"btn btn-danger m-loader--light m-loader--right " + (this.state.loadingDeleteImage && 'm-loader')} disabled={this.state.loadingDeleteImage} style={{ "fontFamily": "SANS-SERIF", width: '150px' }} id="btn_deleteMulti">
                                            {language[this.props.indexLanguage].textButton.delete}
                                        </button>
                                        :
                                        <button type="button" id="button_capture" style={{ "textTransform": "uppercase", width: '150px', "fontFamily": "SANS-SERIF" }} className={"btn btn-accent m-loader--light m-loader--right " + (this.state.loadingBtnCapture && "m-loader")} disabled={this.state.loadingBtnCapture} onClick={this.takeCapture}>{language[this.props.indexLanguage].textButton.capture}</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </LoadingOverlay>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        indexLanguage: state.changelanguage.indexLanguage,
    }
}
export default withRouter(connect(mapStateToProps, null)(Integrated));