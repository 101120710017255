import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import language from '../../../../../language/language';
import { KeyboardTimePicker } from '@material-ui/pickers'
import ReactGA from 'react-ga';



LeaveOfAbsenceForm.propTypes = {
    show: PropTypes.bool,
    onHide: PropTypes.func,
    supervisor: PropTypes.array,
    data: PropTypes.object,
    onRefresh: PropTypes.func,
    onDelete: PropTypes.func,
    list_staff: PropTypes.array,
    s_a_create: PropTypes.bool,
}

LeaveOfAbsenceForm.defaultProps = {
    show: false,
    onHide: null,
    supervisor: [],
    data: {},
    onRefresh: null,
    onDelete : null,
    list_staff: [],
    s_a_create: false,
}
const actionButtonStyle = {
    width: "100%",
    marginBottom: "5px",
    fontFamily: "inherit"
};

export default function LeaveOfAbsenceForm(props) {
    const { show, onHide, supervisor, data , onRefresh , onDelete, list_staff, s_a_create } = props;

    const userData = JSON.parse(localStorage.getItem('data_user'));
    const box_engine_cf = useSelector(state => state.boxai.box_engine_cf);
    const token = useSelector(state => state.isLogin.access_token);

    const [currentManager, setCurrentManager] = useState({});
    const [currentStaff, setCurrentStaff] = useState({});
    const [date,setDate] = useState({
        fromDate: new Date().setHours('08', '00' , '00'),
        toDate: new Date(),
    })
    const [staffShift,setStaffShift] = useState([])
    
    
    const indexLanguage = useSelector(state => state.changelanguage.indexLanguage);

    const [status,setStatus] = useState(-1);
    const [crrData, setCrrData] = useState({});
    const [time,setTime] = useState({
        time_in: null,
        time_out: null,
    })
    const [startTime,setStartTime] = useState(null);
    const [endTime,setEndTime] = useState(null);

    const [timeShift,setTimeShift] = useState({
        start_time_shift: null,
        end_time_shift: null,
    })

    const [isValidateToDate, setIsValidateToDate] = useState(false);
    const [isValidateFromDate, setIsValidateFromDate] = useState(false);
    const [isValidateSupervisor, setIsValidateSuper] = useState(false);
    const [isValidateStaff, setIsValidateStaff] = useState(false);
    const [validated, setValidated] = useState(false);

    function resetFormToDefault() {
        setDate({
            fromDate: new Date().setHours('08', '00' , '00'),
            toDate: new Date(),
        });
        setTime({
            time_in: null,
            time_out: null,
        })
        setCurrentManager({});
        setCurrentStaff({});
        setIsValidateSuper(false);
        setIsValidateStaff(false);
        setIsValidateFromDate(false);
        setIsValidateToDate(false);
        setCrrData({});
        setStartTime(null)
        setEndTime(null)
    }
    const validateForm = () => {
        if (!date.toDate) {
            setIsValidateToDate(true);
        }
        if (!date.fromDate) {
            setIsValidateFromDate(true);
        }
        if (supervisor.length === 0) {
            setIsValidateSuper(true);
        }
        if(Object.keys(currentStaff).length === 0){
            setIsValidateStaff(true);
        }
    }

    const resetValidate = () => {
        setIsValidateSuper(false);
        setIsValidateFromDate(false);
        setIsValidateToDate(false);
        setIsValidateStaff(false);
    }

    const handleUploadData = async () => {
        const dataPost = {
            id: crrData.id,
            id_staff: s_a_create ? currentStaff.id : userData.id_staff,
            start_date: Moment(new Date(date.fromDate)).format('YYYY-MM-DD') + ' ' + Moment(timeShift.start_time_shift).add(startTime, "minutes").format("HH:mm") + ':00' ,
            end_date: Moment(new Date(date.toDate)).format('YYYY-MM-DD') + ' ' + Moment(timeShift.end_time_shift).subtract(endTime, "minutes").format("HH:mm") + ':00',
            status: status,
            id_supervisor: s_a_create ? userData.id_staff : currentManager ? currentManager.id : '',
            description: JSON.stringify({
                content: crrData.description ? crrData.description : '',
                time_in: Moment(new Date(timeShift.start_time_shift)).add(startTime, "minutes").format("HH:mm"),
                time_out: Moment(new Date(timeShift.end_time_shift)).subtract(endTime, "minutes").format("HH:mm"),
            }),
            type: 4,
        }
        const result = await axios({
            method: 'POST',
            url: `${box_engine_cf.crrApiSocket}/api/daysoff_form/insert_or_update`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            data: dataPost,
        })
        if (result.data.status === 10000) {
            if (status === 2) {
                ReactGA.event({
                    category: 'Danh sach don di muon ve som',
                    action: 'Gui don',
                    label: supervisor.length > 0 ? "Nhan vien gui" : "Quan li gui"
                  });
            } else {
                ReactGA.event({
                    category: 'Danh sach don di muon ve som',
                    action: 'Luu nhap',
                  });
            }
            if (crrData.id !== 0) {
                swal({ title: language[indexLanguage].Text_Notifi.success, text: language[indexLanguage].Text_Notifi.update_success, icon: "success", buttons: false, timer: 1500 });
            } else {
                swal({ title: language[indexLanguage].Text_Notifi.success, text: language[indexLanguage].Text_Notifi.notifi_sure_reason_off, icon: "success", buttons: false, timer: 1500 });
            }
            onRefresh(1);
            resetFormToDefault();
            setValidated(false);
            return onHide();
        } else if(result.data.status === 10004){
            const data = result.data.data;
            const reason = data.reason ? `${data.reason.name}` : data.type === 2 ? `${language[indexLanguage].DayOff.ot}`
                : data.type === 3 ? `${language[indexLanguage].DayOff.business}`
                    : data.type === 4 ? `${language[indexLanguage].DayOff.go_late_leave_early}`
                        : data.type === 5 ? `${language[indexLanguage].DayOff.compensate}` : '';
            const date = `${Moment(data.start_date).format('HH:mm DD-MM-YYYY')} - ${Moment(data.end_date).format('HH:mm DD-MM-YYYY')}`
            let my_html = document.createElement('div');
            my_html.innerHTML = `${language[indexLanguage].Text_Notifi.notifi_sure_letter_intersect} <br>${reason.bold()}</br>${date}`;
            swal({
                title: language[indexLanguage].Text_Notifi.notifi_sure_reason_off_error, content: my_html, icon: "warning"
            });
        } else if (result.data.status === 10007) {
            swal({ title: language[indexLanguage].Text_Notifi.error, text: language[indexLanguage].Text_Notifi.notifi_check_through_year, icon: "error", buttons: false, timer: 1500 });
        } else {
            swal({ title: language[indexLanguage].Text_Notifi.error, text: language[indexLanguage].Text_Notifi.notifi_sure_reason_off_error, icon: "error", buttons: false, timer: 1500 });
        }
    }

    const handleTimeShift = () => {
        var shiftNow
        for (let index = 0; index < staffShift.length; index++) {
            const element = staffShift[index];
            let day_of_week = element.shifts_work.day_of_week.slice(1, 14).split('|');
            let test = day_of_week[6];
            day_of_week.unshift(test);
            day_of_week.pop();
            element.day_of_week = day_of_week
            if (element.day_of_week[Moment(date.fromDate).isoWeekday()] === "1") {
                shiftNow = element
            }
        }
        if (shiftNow !== undefined) {
            setTimeShift({
                ...timeShift,
                start_time_shift: Moment(date.fromDate).format('YYYY-MM-DD') + " " + shiftNow.shifts_work.time_in.slice(0, 5) + ':00',
                end_time_shift: Moment(date.toDate).format('YYYY-MM-DD') + " " + shiftNow.shifts_work.time_out.slice(0, 5) + ':00',
            });
        } else {
            setTimeShift({
                start_time_shift: null,
                end_time_shift: null,
            });
        }
    }

    const getStaffShift = async () => {
        const data = await axios({
            method: 'GET',
            url: `${box_engine_cf.crrApiSocket}/api/staff/get_by_id/${s_a_create ? currentStaff.id : userData.id_staff}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token,
            },
        })
        if (data.data.status === 10000) {
           setStaffShift(data.data.data.staff_shift);
        }

    }

    useEffect(() => {
        if (show ) {
            getStaffShift()
        }
    },[currentStaff])

    useEffect(() => {
        handleTimeShift()
    },[staffShift, date.fromDate])


    // useEffect(() => {
    //     if (show && Object.keys(currentStaff).length !== 0) {
    //         getStaffShift()
    //     }
    // }, [date.fromDate])
       

    const handleSubmit = async (event) => {
        validateForm();
        event.preventDefault();
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
          event.stopPropagation();
        }else{
            //Start date > end date
            const start_date = Moment(date.fromDate).format('YYYY-MM-DD');
            const end_date = date.toDate ? Moment(date.toDate).format('YYYY-MM-DD') : '';

            if (start_date > end_date) {
                return swal({
                    title: language[indexLanguage].Text_Notifi.warning, text: language[indexLanguage].Text_Notifi.notifi_sure_from_date_less_to_date, icon: "warning", buttons: {
                        text: "Oke!",
                        closeModal: false,
                    }
                });
            }
            // else{
            //     //start_date = end_start
            //     if((time.time_in && time.time_out) && (Moment(time.time_in).format('HH:mm') > Moment(time.time_out).format('HH:mm'))){
            //         return swal({
            //             title: language[indexLanguage].Text_Notifi.warning, text: language[indexLanguage].Text_Notifi.notifi_sure_time_out_than_time_in, icon: "warning", buttons: {
            //                 text: "Oke!",
            //                 closeModal: false,
            //             }
            //         });
            //     }
            // }

            if(startTime === null && endTime === null){
                return swal({
                    title: language[indexLanguage].Text_Notifi.warning, text: language[indexLanguage].Text_Notifi.require_time_late_time_soon, icon: "warning", buttons: {
                        text: "Oke!",
                        closeModal: false,
                    }
                });
            }
            if(timeShift.start_time_shift === null){
                return swal({
                    title: language[indexLanguage].Text_Notifi.warning, text: language[indexLanguage].Text_Notifi.error_time_start, icon: "warning", buttons: {
                        text: "Oke!",
                        closeModal: false,
                    }
                });
            }
           
            // Nếu gửi đơn từ bản nháp
            if (data.end_date && status === 2) {
                swal({
                    title: language[indexLanguage].Text_Notifi.are_you_sure,
                    text: language[indexLanguage].Text_Notifi.notifi_send_letter,
                    icon: "warning",
                    buttons: {
                        oke: language[indexLanguage].textButton.oke,
                        cancel: language[indexLanguage].textButton.close
                    },
                }).then(async (value) => {
                    if (value === 'oke') {
                       return handleUploadData();
                    }
                })
            } else {
               return handleUploadData();
            }

        }
        setValidated(true);
    }
    
    const handleInputDate = () => {
        
    }
    const handleDelete = () => {
        onDelete(crrData.id);
    }
    function cancelForm() {
        //Delete current Form from DB if exists, if not, redirect to dashboard
        swal({
            title: language[indexLanguage].Text_Notifi.are_you_sure,
            text: language[indexLanguage].Text_Notifi.notifi_sure_delete_daysoff_form,
            icon: "warning",
            buttons: {
                oke: language[indexLanguage].textButton.oke,
                cancel: language[indexLanguage].textButton.close
            },
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete === 'oke') {
                resetFormToDefault();
                onHide();
            }
        });
    }
    const checkStatus = (status) => {
        switch (status) {
            case 2: return language[indexLanguage].DayOff.pending;
            case 3: return language[indexLanguage].DayOff.accept;       
            case 4: return language[indexLanguage].DayOff.refused;
                
            default:
                break;
        }
    }
    const handleChangeTime = (date,name) => {
        setTime({
            ...time,[name]: date,
        })
    }
    useEffect(() => {
        setCrrData(Object.assign(crrData,data));
        setDate({...date,fromDate: data.start_date ? new Date(data.start_date).getTime(): date.fromDate ,toDate: data.end_date ? new Date(data.end_date).getTime() : date.toDate});
        setStartTime(data.time_in)
        setEndTime(data.time_out)
        setCurrentManager(data.supervisor);
    }, [data]);
    useEffect(() => {
        setCurrentManager(supervisor[0]);
    }, [supervisor]);
    useEffect(() => {
        handleInputDate();
    },[date]);
    useEffect(() => {
        if(!show){
            resetFormToDefault();
            setValidated(false);
        }
        if (show) {
            getStaffShift()
        }
    },[show])
       
    return (
        <Modal show={show} onHide={onHide}>
            <div className='row-sm row'>
                <div className="col-md-12">
                    <div className="m-portlet m-portlet--tab">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {
                                    crrData.status === -1 ? language[indexLanguage].DayOff.create_letter : language[indexLanguage].DayOff.detail_letter
                                }
                            </Modal.Title>
                        </Modal.Header>
                        <Form noValidate validated={validated} onSubmit={(e) => handleSubmit(e)} className="m-form m-form--fit">
                            <div className="m-portlet__body">
                                <div className="form-group m-form__group" style={{ paddingTop: 0 }}>
                                    <div className="row mb-3">
                                        <label htmlFor="example-datetime-local-input" className="col-md-3 pr-md-0 col-form-label" style={{ textAlign: "left" }}>
                                            {language[indexLanguage].Business.date_from}
                                            <span className='text-danger'> *</span></label>
                                        <div className="col-md-9">
                                            <DatePicker
                                                className="form-control m-input m_datetimepicker_6 test"
                                                selected={date.fromDate}
                                                onChange={(newDate) => {
                                                    if (newDate) {
                                                        resetValidate();
                                                    }
                                                    setDate({...date,fromDate: new Date(newDate).getTime()})}
                                                }
                                                dateFormat="dd-MM-yyyy"
                                                selectsStart
                                                name='fromDate'
                                                startDate={date.fromDate}
                                                endDate={date.toDate}
                                                maxDate={date.toDate}
                                                disabled={(crrData.status === 1 || crrData.status === -1) ? false : true}
                                                autoComplete="off"
                                                required
                                            />
                                            {isValidateFromDate && <div className='text-danger pt-2'>{language[indexLanguage].DayOff.information_blank}</div>}
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label htmlFor="example-datetime-local-input" className="col-md-3 pr-md-0 col-form-label" style={{ textAlign: "left" }}>
                                            {language[indexLanguage].Business.date_to}
                                            <span className='text-danger'> *</span></label>
                                        <div className="col-md-9">
                                            <DatePicker
                                                className="form-control m-input m_datetimepicker_6"
                                                onChange={(newDate) => {
                                                    if (newDate) {
                                                        resetValidate();
                                                    }
                                                    setDate({...date,toDate: new Date(newDate).getTime()})}
                                                }
                                                selected={date.toDate}
                                                dateFormat="dd-MM-yyyy"
                                                selectsStart
                                                name='toDate'
                                                startDate={date.fromDate}
                                                endDate={date.toDate}
                                                minDate={date.fromDate}
                                                disabled={(crrData.status === 1 || crrData.status === -1) ? false : true}
                                                autoComplete="off"
                                                required
                                            />
                                            {isValidateToDate && <div className='text-danger pt-2'>{language[indexLanguage].DayOff.information_blank}</div>}
                                        </div>
                                    </div>
                                    {
                                        s_a_create &&
                                            <div className="row mb-4">
                                                <label htmlFor="example-datetime-local-input" className="col-md-3 col-form-label" style={{ textAlign: "left" }}>{language[indexLanguage].DayOff.staff} <span className='text-danger'> *</span></label>
                                                <div className="col-md-9">
                                                    <Autocomplete
                                                        className="select_option disable_style"
                                                        options={list_staff}
                                                        getOptionLabel={(staff) => staff.name}
                                                        value={currentStaff}
                                                        onChange={(event, value) => {
                                                            if (value) {
                                                                resetValidate();
                                                            };
                                                            setCurrentStaff(value);
                                                            setTime({
                                                                time_in: null,
                                                                time_out: null,
                                                            })

                                                        }}
                                                        disableClearable
                                                        renderInput={params => (<TextField {...params} variant="outlined" placeholder={language[indexLanguage].DayOff.choose_staff} />)}
                                                        disabled={(crrData.status === 1 || crrData.status === -1) ? false : true}
                                                    />
                                                    {isValidateStaff && <div className='text-danger pt-2'>{language[indexLanguage].DayOff.information_blank}</div>}
                                                </div>
                                            </div>
                                    }
                                    <div className="row">
                                                <label htmlFor="example-datetime-local-input" className="col-md-3 col-form-label" style={{ textAlign: "left" }}>{language[indexLanguage].DayOff.in_minute_late}</label>
                                                <div className="col-md-4">
                                                    <input
                                                        className="form-control m-input"
                                                        name="startTime"
                                                        type="number"
                                                        pattern="\d+"
                                                        min="0"
                                                        defaultValue={0}
                                                        value={startTime}
                                                        onChange={(e) => setStartTime(e.target.value)}
                                                        disabled={(crrData.status === 1 || crrData.status === -1) ? false : true}
                                                    ></input>
                                                </div>
                                            </div>
                                    <div className="row">
                                                <label htmlFor="example-datetime-local-input" className="col-md-3 col-form-label" style={{ textAlign: "left" }}>{language[indexLanguage].DayOff.in_minute_early}</label>
                                                <div className="col-md-4">
                                                    <input
                                                        className="form-control m-input"
                                                        name="endTime"
                                                        type="number"
                                                        pattern="\d+"
                                                        defaultValue={0}
                                                        min="0"
                                                        value={endTime}
                                                        onChange={(e) => setEndTime(e.target.value)}
                                                        disabled={(crrData.status === 1 || crrData.status === -1) ? false : true}
                                                    ></input>
                                                </div>
                                            </div>
                                    {
                                        !s_a_create &&
                                            <div className="row mb-4">
                                                <label htmlFor="example-datetime-local-input" className="col-md-3 pr-md-0 col-form-label" style={{ textAlign: "left" }}>
                                                    {language[indexLanguage].Business.supervisor}
                                                    <span className='text-danger'> *</span></label>
                                                <div className="col-md-9">
                                                    <Autocomplete
                                                        className="select_option disable_style"
                                                        options={supervisor}
                                                        getOptionLabel={(supervisor) => supervisor.name}
                                                        value={currentManager}
                                                        onChange={(event, value) => { setCurrentManager(value) }}
                                                        disableClearable
                                                        renderInput={params => (<TextField {...params} variant="outlined" placeholder={language[indexLanguage].DayOff.choose_supervisor} />)}
                                                        disabled={(crrData.status === 1 || crrData.status === -1) ? false : true}
                                                    />
                                                    {isValidateSupervisor && <div className='text-danger pt-2'>{language[indexLanguage].DayOff.information_blank}</div>}
                                                </div>
                                            </div>
                                    }
                                    
                                    <div className="row">
                                        <label htmlFor="example-datetime-local-input" className="col-md-3 col-form-label" style={{ textAlign: "left" }}>{language[indexLanguage].DayOff.description}</label>
                                        <div className="col-md-9">
                                            <textarea
                                                className="form-control m-input"
                                                name="description"
                                                placeholder={language[indexLanguage].DayOff.description_detail}
                                                rows="5"
                                                value={crrData.description}
                                                onChange={(e) =>setCrrData({...crrData,description: e.target.value})}
                                                disabled={(crrData.status === 1 || crrData.status === -1) ? false : true}
                                            ></textarea>
                                        </div>
                                    </div>
                                    {
                                        (crrData.status && crrData.status !== 1 && crrData.status !== -1) &&
                                        <div className="row pb-4 mt-3">
                                            <label htmlFor="example-datetime-local-input" className="col-md-3 pr-md-0 col-form-label" style={{ textAlign: "left" }}>{language[indexLanguage].DayOff.status}</label>
                                            <div className="col-md-9">
                                                <input type="text" className="form-control m-input" value={checkStatus(crrData.status)} disabled={true} disabled />
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="form-group m-form__group row d-flex justify-content-center">
                                    {(crrData.status === -1) &&
                                        <div className="px-0 mx-2" style={{width:'90px'}}>
                                            <button type="submit" className="btn btn-accent" onClick={() => setStatus(2)} style={actionButtonStyle}>{language[indexLanguage].DayOff.send}</button>
                                        </div>
                                    }
                                    {crrData.status === -1 &&
                                        <>
                                            {
                                                !s_a_create &&
                                                <div className="col-md-2 px-0 mx-4">
                                                    <button type="submit" className="btn btn-info w-auto" onClick={() => setStatus(1)} style={actionButtonStyle}>{language[indexLanguage].DayOff.save_draff}</button>
                                                </div>
                                            }
                                            <div className="col-md-2 px-0 mx-4">
                                                <button type="button" className="btn btn-danger" style={actionButtonStyle} onClick={() => cancelForm()}>{language[indexLanguage].DayOff.cancel}</button>
                                            </div>
                                        </>
                                    }
                                    {crrData.status === 2 &&
                                        <div className="px-0 mx-2" style={{width:'90px'}}>
                                            <button type="button" className="btn btn-danger" style={actionButtonStyle} onClick={() => handleDelete()}>{language[indexLanguage].DayOff.delete}</button>
                                        </div>
                                    }
                                    {crrData.status === 1 &&
                                        <>
                                            <div className="col-md-2 px-0 mx-4">
                                                <button type="submit" className="btn btn-accent" onClick={() => setStatus(2)} style={actionButtonStyle}>{language[indexLanguage].DayOff.send}</button>
                                            </div>
                                            {
                                                !s_a_create &&
                                                <div className="col-md-2 px-0 mx-4">
                                                    <button type="submit" className="btn btn-info w-auto" onClick={() => setStatus(1)} style={actionButtonStyle}>{language[indexLanguage].DayOff.save_draff}</button>
                                                </div>
                                            }
                                            <div className="col-md-2 px-0 mx-4">
                                                <button type="button" className="btn btn-danger" style={actionButtonStyle} onClick={() => handleDelete()}>{language[indexLanguage].DayOff.delete}</button>
                                            </div>
                                        </>
                                    }
                                    {(crrData.status !== 1 && crrData.status !== -1) &&
                                        <div className="px-0 mx-2" style={{width:'90px'}}>
                                            <button type="button" className="btn btn-secondary" style={actionButtonStyle} onClick={() => onHide()}>{language[indexLanguage].DayOff.close}</button>
                                        </div>
                                    }
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
