import {combineReducers} from 'redux';
import isLogin from './isLogin';
import boxai from './boxai';
import changelanguage from './changelanguage'
import letterNotify from './letterNotify';
import role from './role'
export default combineReducers({
    isLogin,
    boxai,
    changelanguage,
    role,
    letterNotify,
});