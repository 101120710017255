import $ from 'jquery';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import Switch from "react-switch";
import swal from 'sweetalert';
import { ARRAY_ENGINES } from '../../../../config';
import language from '../../../../language/language';
import ReactGA from 'react-ga';
import Store from '../../../../store';
import { connect } from 'react-redux'
const animatedComponents = makeAnimated();
  
const createOption = (label) => ({
    label,
    value: label,
});
class ModalDoor extends Component {

    constructor(props) {
        super(props);
        this.state = {
            language: Store.getState().changelanguage.language,
            box_engine_cf: Store.getState().boxai.box_engine_cf,
            listEngine: [],
            isChecked: false,
            inputValue: '',
            valueBoxengine: [],
            crrDoor: {
                box_engine_id: "",
                name: "",
                description: "",
                is_active: 0
            },
            indexlanguage: 0
        }
        Store.subscribe(() => {
            this.setState({
                language: Store.getState().changelanguage.language,
                box_engine_cf: Store.getState().boxai.box_engine_cf,
            });
        });
    }

    updateDoor = async (dataDoor,event) => {
        //Insert

        var form = $("#formUpdateDoor")
        if (form[0].checkValidity() === false) {
            event.preventDefault()
            event.stopPropagation()
            form.addClass('was-validated')
        } else {
            if (dataDoor.box_engine_id.length >= 3) {
                if (dataDoor.id == undefined) {
                    let data = await fetch(`${dataDoor.HOST}/api/door/insert_or_update`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': Store.getState().isLogin.access_token
                        },
                        body: JSON.stringify(dataDoor)
                    }).then((response) => {
                        return response.json()
                    });
    
                    if (data.status === 10000) {
                        ReactGA.event({
                            category: 'Quan li cua',
                            action: 'Them moi cua',
                          });
                        swal(language[this.props.indexLanguage].Text_Notifi.success, language[this.props.indexLanguage].Text_Notifi.add_door_success, "success", {
                            buttons: false,
                            timer: 1500,
                        });
                        return this.props.onHide();
                    }
                    else {
                        swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].Text_Notifi.add_door_false, "error");
                    }
                } else {
                    let data = await fetch(`${dataDoor.HOST}/api/door/insert_or_update`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': Store.getState().isLogin.access_token
                        },
                        body: JSON.stringify(dataDoor)
                    }).then((response) => {
                        return response.json()
                    });
                    if (data.status === 10000) {
                        ReactGA.event({
                            category: 'Quan li cua',
                            action: 'Sua cua',
                          });
                        swal(language[this.props.indexLanguage].Text_Notifi.success, language[this.props.indexLanguage].Text_Notifi.edit_door_success, "success", {
                            buttons: false,
                            timer: 1500,
                        });
                        return this.props.onHide();
                    }
                    else {
                        swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].Text_Notifi.edit_door_false, "error");
                    }
                }
            } else {
                swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].Text_Notifi.notifi_check_select_boxengine, "warning");
            }
        }
    }


    doorHandle(e) {
        var doorHand = this.state.crrDoor;
        doorHand[e.target.name] = e.target.value;
        this.setState({ crrDoor: doorHand });
    }

    handleChange = (value, actionMeta) => {
        var value = value;
        if (value == null) {
            value = []
        }
        this.setState({ 
            value 
        },() => {
            var doorHand = this.state.crrDoor;
            var value2 = this.state.value;
            var listBoxengine = '|';
            if (value2.length > 0) {
                for (let index = 0; index < value2.length; index++) {
                    listBoxengine += value2[index].value + '|'
                }
            } else {
                listBoxengine = ''
            }
            doorHand['box_engine_id'] = listBoxengine
            this.setState({
                crrDoor: doorHand
            })
        });
    };
    handleInputChange = (inputValue) => {
        this.setState({ inputValue });
    };
    handleKeyDown = (event) => {
        
        const { inputValue, value } = this.state;
        if (!inputValue) return;
        switch (event.which) {
            case 13:
            case 32:
            case 9:    
            this.setState({
              inputValue: '',
              value: [...value, createOption(inputValue)],
            },() => {
                var doorHand = this.state.crrDoor;
                var valueIdBoxEngine = this.state.value;
                var listBoxengine = '|';
                if (valueIdBoxEngine.length > 0) {
                    for (let index = 0; index < valueIdBoxEngine.length; index++) {
                        listBoxengine += valueIdBoxEngine[index].value + '|'
                    }
                } else {
                    listBoxengine = ''
                }
                doorHand['box_engine_id'] = listBoxengine
                this.setState({
                    crrDoor: doorHand
                })
            });
            event.preventDefault();
        }
    };

    changeHandleBox = (valueBox) => {
        var doorHand = this.state.crrDoor;

        var listBoxengine = '|';
        if (valueBox == null ) {
            listBoxengine = ''
        } else {
            for (let index = 0; index < valueBox.length; index++) {
                listBoxengine += valueBox[index].value + '|'
            }
        }
        
        doorHand['box_engine_id'] = listBoxengine
        this.setState({
            crrDoor: doorHand
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.show === true) {
            var arr = nextProps.data.box_engine_id.split('|');
            arr.pop();
            arr.shift();
            var optionIdBoxEngine = [];
            for (let index = 0; index < arr.length; index++) {
                for (let i = 0; i < nextProps.databoxengine.length; i++) {
                    if (Number(arr[index]) === nextProps.databoxengine[i].id_box_engine) {
                        optionIdBoxEngine.push({ 'value': Number(arr[index]), 'label': nextProps.databoxengine[i].alias_box_engine ? `${nextProps.databoxengine[i].alias_box_engine} ( ${nextProps.databoxengine[i].id_box_engine} )` : Number(arr[index])})
                    }
                }
            }
            this.setState({
                crrDoor: nextProps.data,
                dataBoxengine: nextProps.databoxengine,
                valueBoxengine: optionIdBoxEngine
            });
        }
    }

    handleEnter = (event) => {
        if (event.keyCode === 13) {
            const form = event.target.form;
            const index = Array.prototype.indexOf.call(form, event.target);
            form.elements[index + 1].focus();
            event.preventDefault();
        }
    }

    render() {
        let click_handle = (event) => {
            this.updateDoor(this.state.crrDoor,event);
        }

        if (this.state.crrDoor.is_active === 1) {
            this.state.isChecked = true
        } else {
            this.state.isChecked = false
        }

        var optionsCamera = [];

        if (this.state.dataBoxengine != undefined) {
            this.state.dataBoxengine.filter(filter => ARRAY_ENGINES.includes(filter.sub_engine_id.toString())).map((f, j) => {
                return (
                    optionsCamera.push({
                        label: f.alias_box_engine ? `${f.alias_box_engine} ( ${f.id_box_engine} )` : f.id_box_engine, value: f.id_box_engine
                    })
                )
            })
        }

        return (
            <Modal
                {...this.props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form id="formUpdateDoor">
                        <div className="col-xl-12">
                            <div className="m-widget14">
                                <div className="form-group m-form__group">
                                    <label htmlFor="Name">{language[this.props.indexLanguage].textTable.name} <span className="text-danger"> *</span></label>
                                    <input className="form-control m-input" id="Name" name='name' value={this.state.crrDoor.name === '' ? '' : this.state.crrDoor.name} onKeyDown={(event) => this.handleEnter(event)} onChange={e => this.doorHandle(e)} placeholder={language[this.props.indexLanguage].textTable.name} required />
                                </div>
                                <div className="form-group m-form__group">
                                    <label htmlFor="Engine">Engine <span className="text-danger"> *</span></label>
                                    <Select
                                        onChange={this.changeHandleBox}
                                        getOptionValue ={(option)=>option.label}
                                        options={optionsCamera}
                                        defaultValue={this.state.valueBoxengine}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        placeholder={language[this.props.indexLanguage].placeholderSelect.select_engine}
                                    />
                                </div>
                                <div className="form-group m-form__group">
                                    <label htmlFor="Name">{language[this.props.indexLanguage].textTable.status} </label>
                                    <div>
                                        <Switch
                                            onChange={() => {
                                                var is_active;
                                                if (!this.state.isChecked == true) {
                                                    is_active = 1
                                                } else {
                                                    is_active = 0
                                                }
                                                var door = this.state.crrDoor;
                                                door['is_active'] = is_active;
                                                this.setState({
                                                    crrDoor: door,
                                                    isChecked: !this.state.isChecked
                                                });
                                            }}
                                            checked={this.state.isChecked}
                                            handleDiameter={17}
                                            offColor="#969696"
                                            onColor="#00c5dc"
                                            offHandleColor="#ffffff"
                                            onHandleColor="#08f"
                                            height={25}
                                            width={55}
                                            className="react-switch"
                                            id="small-radius-switch"
                                        />
                                    </div>    
                                </div>
                                <div className="form-group m-form__group">
                                    <label htmlFor="Description">{language[this.props.indexLanguage].textTable.description}</label>
                                    <textarea rows="4" className="form-control m-input" id="Description" value={this.state.crrDoor.description === '' ? '' : this.state.crrDoor.description} name='description' onChange={e => this.doorHandle(e)} placeholder={language[this.props.indexLanguage].textTable.description} />
                                </div>

                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="accent" onClick={click_handle}>{language[this.props.indexLanguage].textButton.save}</Button>
                    <Button variant="secondary" onClick={this.props.onHide}>{language[this.props.indexLanguage].textButton.close}</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

ModalDoor.propTypes = {
    data: PropTypes.object,
    databoxengine: PropTypes.array,
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool,
}
const mapStateToProps = state => {
    return {
        indexLanguage: state.changelanguage.indexLanguage,
    }
}

export default connect(mapStateToProps, null)(ModalDoor);