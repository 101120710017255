import {CHANGELANGUAGE} from '../../actions/changelanguage';

let language = localStorage.getItem('language');
const initialState = {
    language:  language,
    indexLanguage: language === 'us' ? 0 : language === 'jp' ? 2 : 1,
};
export default function changelanguage(state = initialState, action) {
    switch(action.type){
        case CHANGELANGUAGE:
            return{
                ...state,
                language: action.payload.language,
                indexLanguage: action.payload.index,
            };
        default:
            return state;
    }
}
