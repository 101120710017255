import React, { Component, Event } from 'react';
import { HOST, HOST_CLOUD, ARRAY_ENGINES } from '../../../config';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'moment';
import { PulseLoader } from 'react-spinners';
import { Row, Col, Image } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import Store from '../../../store';
import { css } from '@emotion/core';
import swal from 'sweetalert';
import $ from 'jquery';
import language from '../../../language/language';
import openSocket from 'socket.io-client';
import Pagination from "react-js-pagination";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { connect } from 'react-redux'
import Header from '../../layouts/Header';
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class WarningInOut extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogin: Store.getState().isLogin.isLogin,
            access_token: Store.getState().isLogin.access_token,
            box_engine_cf: Store.getState().boxai.box_engine_cf,
            language: Store.getState().changelanguage.language,
            crrApi2: Store.getState().boxai.box_engine_cf.crrApiSocket,
            crrApiSocket2: Store.getState().boxai.box_engine_cf.crrApiSocket2,
            crrApi: Store.getState().boxai.box_engine_cf.crrApi,
            dataCamera: Store.getState().boxai.box_engine_cf.dataCamera,
            loading: true,
            loadingUnknown: true,
            loadingHistory: false,
            listImageKnown: [],
            listImageUnknown: [],
            listHistory: [],
            crrHistory: [],
            img_face: "",
            crr_person: "",
            valuePerson: "",
            activePage: 1,
            listCompany: [],
            idEngine: 0,
            totalCount: 0,
            crrApi: [],
            crrDate: new Date(),
            toStaff: '',
            offset: 0,
            showFirst: 0,
            showLast: 0,
            totalLength: 0,
            crrCamera: { title: '' },
        };
        this.itemsPerPage = 4;
        Store.subscribe(() => {
            this.setState({
                ...this.state,
                activePage: 1,
                offset: 0,
                showFirst: 0,
                showLast: 0,
                totalLength: 0,
                loading: true,
                loadingUnknown: true,
                loadingHistory: false,
                listImageKnown: [],
                listImageUnknown: [],
                isLogin: Store.getState().isLogin.isLogin,
                access_token: Store.getState().isLogin.access_token,
                box_engine_cf: Store.getState().boxai.box_engine_cf,
                language: Store.getState().changelanguage.language,
                crrApi2: Store.getState().boxai.box_engine_cf.crrApiSocket,
                crrApiSocket2: Store.getState().boxai.box_engine_cf.crrApiSocket2,
                crrApi: Store.getState().boxai.box_engine_cf.crrApi,
                dataCamera: Store.getState().boxai.box_engine_cf.dataCamera,
            }, () => {
                var crrCamera = { title: '' };
                if (this.state.box_engine_cf.dataCamera !== undefined) {
                    crrCamera = { title: this.state.dataCamera[0].id_box + '_' + this.state.dataCamera[0].id_box_engine, value: this.state.dataCamera[0].id_box_engine }
                }
                this.setState({
                    crrCamera: crrCamera
                }, () => {
                    this.getImg(this.state.box_engine_cf, this.state.crrDate);
                })
            });
        });
        this.state.box_engine_cf = Store.getState().boxai.box_engine_cf;
        this.state.crrApi = Store.getState().boxai.box_engine_cf.crrApi;
        this.getImg(this.state.box_engine_cf, this.state.crrDate);
    }

    componentWillMount() {

        var crrCamera = { title: '' };
        if (this.state.box_engine_cf.dataCamera !== undefined) {
            crrCamera = { title: this.state.dataCamera[0].id_box + '_' + this.state.dataCamera[0].id_box_engine, value: this.state.dataCamera[0].id_box_engine }
        }

        this.setState({
            crrCamera: crrCamera
        })
    }

    getImg(value, crrDate) {
        //alert(this.crrDate);
        if (value !== '') {
            if (value.crrApi !== '') {
                this.updateImgKnown(value, crrDate);
                this.updateImgUnknown(value, crrDate);
            } else {
                this.setState({
                    loading: false,
                })
            }
        } else {
            this.setState({
                loading: false,
            })
        }
    }

    updateImgKnown = (value, crrDate) => {
        if (value.crrApi === '') return;
        fetch(`${value.crrApi}/api/staff/getFaceByTime/1/${this.state.crrCamera.value}/${Moment(crrDate).format("YYYY-MM-DD")}/${Moment(crrDate).format("YYYY-MM-DD")}/${this.state.activePage}/10000000`).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                this.setState({
                    listImageKnown: [...data.img],
                    loading: false,
                }, () => {
                    if (this.state.crrApiSocket2 !== '' && this.state.crrApiSocket2 !== undefined) {
                        if (this.socket !== undefined) {
                            if (this.socket.connected === true) {
                                this.socket.disconnect();
                                console.log('Socket disconnect')
                            }
                        }
                        this.socket = openSocket(`${this.state.crrApiSocket2}`);
                        this.socket.on("connect", async () => {
                            console.log("Client Successfully Connected");
                            this.socket.emit('joined', this.state.crrCamera.value.toString(10));
                        });
                        this.socket.on('my response', async (msg) => {
                            if (Moment(msg['timeget']).format("YYYY-MM-DD") == Moment(this.state.crrDate).format("YYYY-MM-DD")) {
                                if (msg['operationType'] === 'insert') {
                                    if (msg['idStaff'] === 'unknown') {
                                        var _listImageUK = this.state.listImageUnknown;
                                        if (_listImageUK.length >= this.number) {
                                            _listImageUK.pop();
                                        }
                                        _listImageUK.unshift(msg);
                                        this.setState({
                                            listImageUnknown: _listImageUK
                                        });
                                    }
                                    else {
                                        var _listImage = this.state.listImageKnown;
                                        _listImage.pop();
                                        _listImage.unshift(msg);
                                        this.setState({
                                            listImageKnown: _listImage
                                        });
                                    }
                                }
                                else {
                                    if (msg['operationType'] === 'update') {
                                        var _listImageUK = this.state.listImageUnknown;
                                        for (var i = 0; i < _listImageUK.length; i++) {
                                            if (_listImageUK[i]['_id'] === msg['_id']) {
                                                if (msg['field'] === 'confidence') {
                                                    _listImageUK[i]['imgFace'] = msg['imgFace'] !== '' ? msg['imgFace'] : _listImageUK[i]['imgFace'];
                                                    _listImageUK[i]['confidence'] = msg['confidence'] !== '' ? msg['confidence'] : _listImageUK[i]['confidence'];
                                                }
                                            }
                                        }
                                        var _listImage = this.state.listImageKnown;
                                        for (var i = 0; i < _listImage.length; i++) {
                                            if (_listImage[i]['_id'] === msg['_id']) {
                                                if (msg['field'] === 'confidence') {
                                                    _listImage[i]['imgFace'] = msg['imgFace'] !== '' ? msg['imgFace'] : _listImage[i]['imgFace'];
                                                    _listImage[i]['confidence'] = msg['confidence'] !== '' ? msg['confidence'] : _listImage[i]['confidence'];
                                                    _listImage[i]['timeget'] = msg['timeget'] !== '' ? msg['timeget'] : _listImage[i]['timeget'];
                                                }
                                                else {
                                                    if (msg['field'] === 'timeget') {
                                                        _listImage[i]['timeget'] = msg['timeget'];
                                                    }
                                                }
                                            }
                                        }
                                        _listImage = _listImage.sort((a, b) => new Moment(a['timeget']).format('YYYY-MM-DD HH:mm:ss') < new Moment(b['timeget']).format('YYYY-MM-DD HH:mm:ss') ? 1 : -1)
                                        this.setState({
                                            listImageKnown: _listImage,
                                            listImageUnknown: _listImageUK
                                        });
                                    }
                                    else {
                                        var _listImageUK = this.state.listImageUnknown;
                                        console.log('delete');
                                        var newList = _listImageUK.filter((item, index) => {
                                            return item['_id'] !== msg['_id']
                                        });
                                        this.setState({
                                            listImageUnknown: newList
                                        });
                                    }
                                }
                            }
                        });
                    }
                });
            } else {
                this.setState({
                    loading: false,
                })
            }
        }).catch(() => {
            this.setState({
                loading: false,
            })
        });
    }

    updateImgUnknown = (value, crrDate) => {
        if (value.crrApi === '') return;
        fetch(`${value.crrApi}/api/staff/getFaceByTime/2/${this.state.crrCamera.value}/${Moment(crrDate).format("YYYY-MM-DD")}/${Moment(crrDate).format("YYYY-MM-DD")}/${this.state.activePage}/${this.itemsPerPage}`).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                this.setState({
                    listImageUnknown: [...data.img],
                    loadingUnknown: false,
                });
            } else {
                this.setState({
                    loadingUnknown: false,
                })
            }
        }).catch(() => {
            this.setState({
                loadingUnknown: false,
            })
        });
    }

    getinfo = (value) => {
        if (this.state.crrApi == undefined) return;
        fetch(`${this.state.crrApi}/api/staff/search_history`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.access_token
            },
            body: JSON.stringify(
                {
                    "type_face": 1,
                    "id_camera": this.state.crrCamera.value,
                    "search": value.idStaff,
                    "img": "",
                    "department": "",
                    "from_date": Moment(this.state.crrDate).format("YYYY-MM-DD"),
                    "to_date": Moment(this.state.crrDate).format("YYYY-MM-DD"),
                    "index": this.state.activePage,
                    "item_per_page": this.itemsPerPage
                }
            )
        }).then((response) => {
            return response.json()
        }).then(async (data) => {
            if (data.status === 10000) {
                var img_face = ""
                if (data.data.length > 0) {
                    img_face = data.data[0].acceptFace
                }
                this.setState({
                    listHistory: data.data,
                    img_face: img_face,
                    loadingHistory: false,
                    totalLength: data.count
                })
            } else {
                this.setState({
                    listHistory: [],
                    img_face: "",
                    loadingHistory: false
                })
            }
        })
    }

    // Filter = (activePage) => {
    //     const offset = (activePage - 1) * this.itemsPerPage;
    //     const crrHistory = this.state.listHistory.slice(offset, offset + this.itemsPerPage);
    //     this.setState({
    //         crrHistory,
    //         offset,
    //         showFirst: offset + 1,
    //         showLast: crrHistory.length + offset,
    //         totalLength: this.state.listHistory.length
    //     });
    // }

    handlePageChange = (pageNumber) => {
        this.setState({
            ...this.state,
            activePage: pageNumber,
            loadingHistory: true,
            listHistory: [],
        }, () => {
            this.getinfo(this.state.crr_person);
        })
    }

    handleChangeStartDate = startDate => {
        this.setState({
            crrDate: startDate,
            activePage: 1,
            loading: true,
            loadingUnknown: true,
            listImageKnown: [],
            listImageUnknown: [],
            listHistory: [],
            crr_person: "",
            img_face: "",
            totalLength: 0,
        }, () => {
            this.getImg(this.state.box_engine_cf, this.state.crrDate);
        });
    }


    changeHandleCamera = (valueCamera, e) => {
        this.setState({ 
            crrCamera: e,
            activePage: 1,
            loading: true,
            loadingUnknown: true,
            listImageKnown: [],
            listImageUnknown: [],
            listHistory: [],
            crr_person: "",
            img_face: "",
            totalLength: 0,
        }, () => {
            this.getImg(this.state.box_engine_cf, this.state.crrDate);
        })
    }

    render() {
        if (this.state.isLogin == false) {
            return (
                <Redirect to={'/login'} />
            )
        }

        let bulletedImgKnown = this.state.listImageKnown.map((e, i) => {
            return (
                <Col key={i} xl={4} lg={6} md={6} sm={4} className={"item p-1" + (this.state.crr_person == e ? ' active' : '')}>

                    <div className="column">
                        <div className="card form_img_reva">
                            <div onClick={() => {
                                this.setState({
                                    crr_person: e,
                                })
                                this.getinfo(e);
                            }}>
                                <Image width='100%' style={{ 'height': '13vh' }} src={`${this.state.box_engine_cf.crrApi}/view/thumbnail/${e._id}`} ></Image>
                                <div className="p-3 col-md-12" >
                                    <div style={{ 'display': 'grid' }}>
                                        <h5 className='truncate mb-1'>{e.name}</h5>
                                    </div>


                                    <span className='tag tag-success' style={{ 'fontSize': '13px' }}>{(e.department === "" ? "" : e.department)}</span>
                                    <ul className="face_details pt-1 mb-2" style={{ 'fontSize': '15px', 'paddingLeft': '0px' }}>
                                        {/* <li className="category">{(e.confidence * 100).toFixed(2)}%</li> */}
                                        <li className="date tag tag-warning" style={{ 'listStyle': 'none', 'fontSize': '13px' }}>
                                            {Moment(e.timeget).format("HH:mm:ss")}
                                        </li>
                                        {/* {img_emotion} */}
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </Col>
            )
        })

        let bulletedImgUnknown = this.state.listImageUnknown.map((e, i) => {
            return (
                <Col key={i} xl={4} lg={6} md={6} sm={4} className={"item p-1"}>

                    <div className="column">
                        <div className="card form_img_reva">
                            <div>
                                <Image width='100%' style={{ 'height': '13vh' }} src={`${this.state.box_engine_cf.crrApi}/view/thumbnail/${e._id}`} ></Image>
                            </div>
                            <div className="p-2 text-center">
                                {/* <h5 className='truncate mb-1'>{e.name}</h5> */}
                                <ul className="face_details pt-1 mb-2" style={{ 'fontSize': '15px', 'paddingLeft': '0px' }}>
                                    {/* <li className="category">{(e.confidence * 100).toFixed(2)}%</li> */}
                                    <li className="date tag tag-warning" style={{ 'listStyle': 'none', 'fontSize': '13px' }}>
                                        {Moment(e.timeget).format("HH:mm:ss")}
                                    </li>
                                    {/* {img_emotion} */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </Col>
            )
        })

        let bullet_data_history = this.state.listHistory.map((e, i) => {
            return (
                <tr key={i}>
                    <td>
                        <Image width='70' height='70' src={`${this.state.crrApi}/view/thumbnail/${e._id}`}></Image>
                    </td>
                    <td style={{ 'whiteSpace': 'normal' }}>
                        {e.timeget}
                    </td>
                </tr>
            )
        })

        var optionsCamera = [];
        if (this.state.dataCamera !== undefined) {
            for (let index = 0; index < this.state.dataCamera.length; index++) {
                if (ARRAY_ENGINES.includes(this.state.dataCamera[index].sub_engine_id + '')) {
                    optionsCamera.push({
                        title: this.state.dataCamera[index].id_box + '_' + this.state.dataCamera[index].id_box_engine, value: this.state.dataCamera[index].id_box_engine
                    })
                }
            }
        }

        return (
            <>
            <Header />
            <div className={"m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body "}>
            <div className="m-grid__item m-grid__item--fluid m-wrapper mb-0">
                <div className="m-content mt-3-phone pd_phone_0 p-3">
                    <div className="m-portlet mb-0" id="form_reva" >
                        <div className="m-portlet__head">
                            <div className="m-form__group row col-12 pl-1">
                                <div className="select_option mt-3 col-xl-2 col-md-3 col-xs-12 pl-2 pr-2">
                                    <Autocomplete
                                        options={optionsCamera}
                                        getOptionLabel={option => option.title}
                                        value={this.state.crrCamera}
                                        onChange={this.changeHandleCamera}
                                        disableClearable
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                placeholder="Camera"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </div>
                                <div className="col-xl-2 mt-3 col-md-3 col-xs-12 pr-2 pl-2 mb-3">
                                    <DatePicker
                                        className="form-control m-input m_datetimepicker_6"
                                        selected={this.state.crrDate}
                                        onChange={this.handleChangeStartDate}
                                        withPortal
                                        timeIntervals={10}
                                        dateFormat="yyyy-MM-dd"
                                        placeholderText="Start Date"
                                    />
                                </div>
                                <div className="mt-3 pl-2 col-md-3 col-xl-2 col-xs-12">
                                    <button className="btn btn-secondary m-btn m-btn--icon" id="m_daterangepicker_6" onClick={() => {
                                        this.setState({
                                            loading: true,
                                            loadingUnknown: true,
                                            listImageKnown: [],
                                            listImageUnknown: [],
                                            crrDate: new Date(),
                                            listHistory: [],
                                            crr_person: "",
                                            img_face: "",
                                            totalLength: 0,
                                        }, () => {
                                            this.getImg(this.state.box_engine_cf, this.state.crrDate)
                                        });
                                    }}>
                                        <span>
                                            <i className="la la-rotate-left" />
                                            <span>{language[this.props.indexLanguage].textButton.reload}</span>
                                        </span>
                                    </button>
                                </div>

                            </div>
                        </div>
                        <div className="m-portlet__body  m-portlet__body--no-padding" style={{ 'height': '805px' }}>
                            <div className="row m-row--no-padding m-row--col-separator-xl" style={{ 'height': '100%' }}>
                                <div className="col-md-4 col-xl-4">
                                    {/*begin::Total Profit*/}
                                    <div className="card-box p-4">
                                        <h5 className="mt-0 mb-3 ml-1">{language[this.props.indexLanguage].WarninginOut.list_of_known}</h5>
                                        <Row className='m-row--no-padding m-scrollable scroll scroll_customer_daily'>
                                            <PulseLoader
                                                css={override}
                                                sizeUnit={"px"}
                                                size={12}
                                                margin={'2px'}
                                                color={'#36D7B7'}
                                                loading={this.state.loading}
                                            />
                                            {bulletedImgKnown}
                                        </Row>
                                    </div>
                                    {/*end::Total Profit*/}
                                </div>
                                <div className="col-md-4 col-xl-4">
                                    {/*begin::Total Profit*/}
                                    <div className="card-box p-4">
                                        <h5 className="mt-0 mb-3 ml-1">{language[this.props.indexLanguage].WarninginOut.list_of_unknown}</h5>
                                        <Row className='m-row--no-padding m-scrollable scroll scroll_customer_daily'>

                                            <PulseLoader
                                                css={override}
                                                sizeUnit={"px"}
                                                size={12}
                                                margin={'2px'}
                                                color={'#36D7B7'}
                                                loading={this.state.loadingUnknown}
                                            />
                                            {bulletedImgUnknown}
                                        </Row>
                                    </div>
                                    {/*end::Total Profit*/}
                                </div>
                                <div className="col-md-4 col-xl-4">
                                    {/*begin::Total Profit*/}
                                    <div className="card-box p-4">
                                        <h5 className="mt-0 mb-3 ml-1">{language[this.props.indexLanguage].WarninginOut.information}</h5>
                                        <Row className='mb-4'>
                                            <div className="col-md-5 pb-3 pt-3">
                                                <div style={{ 'position': 'relative' }}>
                                                    <Image onClick={() => {
                                                        this.setState({
                                                            modalShow: true
                                                        });
                                                    }} style={{ 'cursor': 'pointer', 'height': '22vh' }} width='100%' src={this.state.crr_person == "" ? '/img/photo-placeholder.png' : `${this.state.crrApi}/view/image/${this.state.crr_person._id}`} className='rounded-circle'></Image>
                                                    <Image style={{ 'cursor': 'pointer', 'height': '10vh', 'position': 'absolute', 'bottom': '-20px', 'right': '-10px', 'border': 'solid 5px white' }} width='45%' src={this.state.img_face == "" ? '/img/photo-placeholder.png' : `${this.state.crrApi}/view/origin/image/${this.state.crr_person._idStaff}`} className='rounded-circle'></Image>
                                                </div>
                                            </div>
                                            <div className="col-md-7 pl-0 table-responsive">
                                                <table className='tb--Info'>
                                                    <tbody>
                                                        <tr>
                                                            <td><i className='fa fa-id-card-alt'></i> {language[this.props.indexLanguage].textTable.id_staff}</td>
                                                            <td><b>{this.state.listHistory.length > 0 ? this.state.listHistory[0].idStaff : ""}</b></td>
                                                        </tr>
                                                        <tr>
                                                            <td><i className='fa fa-user'></i> {language[this.props.indexLanguage].textTable.name}</td>
                                                            <td><b>{this.state.listHistory.length > 0 ? this.state.listHistory[0].name : ""}</b></td>
                                                        </tr>
                                                        <tr>
                                                            <td><i className='fa fa-building'></i> {language[this.props.indexLanguage].textTable.department}</td>
                                                            <td><b>{this.state.listHistory.length > 0 ? this.state.listHistory[0].department : ""}</b></td>
                                                        </tr>
                                                        <tr>
                                                            <td><i className='fa fa-venus-mars'></i> {language[this.props.indexLanguage].textTable.gender}</td>
                                                            <td><b>{this.state.listHistory.length > 0 ?

                                                                this.state.listHistory[0].gender == 1 ? 'Male' : this.state.listHistory[0].gender == 2 ? "Female" : "Orther Gender"

                                                                : ""}</b></td>
                                                        </tr>
                                                        <tr>
                                                            <td><i className='fa fa-clock'></i> {language[this.props.indexLanguage].WarninginOut.time}</td>
                                                            <td><b>{this.state.crr_person != null ? this.state.crr_person.timeget : ''}</b></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="pl_phone_0 pr_phone_0 p-30 form_info_reva mt-5 col-md-12">
                                                <div className="text-nowrap" id="table_devices" >
                                                    <table id='tbHistoryWarning' className="table text-center table-bordered table-hover table-checkable dataTable no-footer dtr-inline collapsed" style={{ "maxHeight": "800px", "overflow": "hidden scroll" }}>
                                                        <thead>
                                                            <tr>
                                                                <th>{language[this.props.indexLanguage].WarninginOut.image}</th>
                                                                <th>{language[this.props.indexLanguage].WarninginOut.time}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="scroll">
                                                            {
                                                                bullet_data_history
                                                            }
                                                        </tbody>
                                                    </table>
                                                    <PulseLoader
                                                        css={override}
                                                        sizeUnit={"px"}
                                                        size={12}
                                                        margin={'2px'}
                                                        color={'#36D7B7'}
                                                        loading={this.state.loadingHistory}
                                                    />
                                                </div>
                                                {/* <span>
                                                    Showing {this.state.showFirst} to {this.state.showLast} of {this.state.totalLength} entries
                                                        </span> */}
                                                <Pagination
                                                    firstPageText={language[this.props.indexLanguage].pagination.first}
                                                    nextPageText={language[this.props.indexLanguage].pagination.next}
                                                    lastPageText={language[this.props.indexLanguage].pagination.last}
                                                    prevPageText={language[this.props.indexLanguage].pagination.previous}
                                                    activePage={this.state.activePage}
                                                    itemsCountPerPage={this.itemsPerPage}
                                                    totalItemsCount={this.state.totalLength}
                                                    pageRangeDisplayed={3}
                                                    onChange={this.handlePageChange}
                                                />
                                            </div>
                                        </Row>
                                    </div>
                                    {/*end::Total Profit*/}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            </div>
            </>
        );
    }
}
const mapStateToProps = state => {
    return {
        indexLanguage: state.changelanguage.indexLanguage,
    }
}
export default connect(mapStateToProps, null)(WarningInOut);
