import { Checkbox, FormControlLabel } from '@material-ui/core';
import { KeyboardTimePicker } from '@material-ui/pickers';
import $ from 'jquery';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import makeAnimated from 'react-select/animated';
import swal from 'sweetalert';
import language from '../../../../language/language';
import Store from '../../../../store';
import ReactGA from 'react-ga';

import { connect } from 'react-redux'
const animatedComponents = makeAnimated();

class ModalShift extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: Store.getState().changelanguage.language,
      indexlanguage: 0,
      totalMinute: 0,
      startTime: null,
      endTime: null,
      startTimeOff: '',
      endTimeOff: '',
      Expire_timein: '',
      Expire_timeout: '',
      crrShift: {
        day_of_week: '',
        id: '',
        name: '',
        time_in: '',
        time_out: '',
        start_time_off: '',
        end_time_off: '',
        expire_timein: '',
        expire_timein_to: '',
        expire_timeout: '',
        expire_timeout_to: '',
        description: '',
        total_time: 0,
        work: 1,
        id_company: '',
        is_active: 1,
      },
      date_to_work: {
        mon: true,
        tue: true,
        wed: true,
        thu: true,
        fri: true,
        sat: true,
        sun: true,
      },
      selectedDate: new Date(),
    };
    Store.subscribe(() => {
      this.setState({
        language: Store.getState().changelanguage.language,
      });
    });
  }

  componentWillMount() {
    this.setState({
      crrShift: this.props.data,
    });
  }

  updateShift = async (dataShift, event) => {
    dataShift.name.trim();
    // var form = $('#formAddShift');
    // console.log(form[0].checkValidity());
    // if (form[0].checkValidity() === false) {
    //   event.preventDefault();
    //   event.stopPropagation();
    //   form.addClass('was-validated');
    // } else {
    //Insert
    if (!dataShift.name) {
      return swal(
        language[this.props.indexLanguage].Text_Notifi.warning,
        language[this.props.indexLanguage].Text_Notifi.notifi_name,
        'warning'
      );
    }
    if (dataShift.expire_timein_to && !dataShift.expire_timeout) {
      return swal(
        language[this.props.indexLanguage].Text_Notifi.warning,
        language[this.props.indexLanguage].Text_Notifi.notifi_expire_timeout,
        'warning'
      );
    }
    if (dataShift.expire_timein_to && !dataShift.expire_timeout) {
      return swal(
        language[this.props.indexLanguage].Text_Notifi.warning,
        language[this.props.indexLanguage].Text_Notifi.notifi_expire_timeout,
        'warning'
      );
    }
    if (!dataShift.expire_timein_to && dataShift.expire_timeout) {
      return swal(
        language[this.props.indexLanguage].Text_Notifi.warning,
        language[this.props.indexLanguage].Text_Notifi.notifi_expire_timein_to,
        'warning'
      );
    }
    if (dataShift.expire_timein_to > dataShift.expire_timeout) {
      return swal(
        language[this.props.indexLanguage].Text_Notifi.warning,
        language[this.props.indexLanguage].Text_Notifi
          .notifi_expire_timein_to_less_timeout,
        'warning'
      );
    }
    if (
      dataShift.time_in == 'Invalid date' ||
      dataShift.time_out == 'Invalid date' ||
      dataShift.start_time_off == 'Invalid date' ||
      dataShift.end_time_off == 'Invalid date' ||
      dataShift.expire_timein == 'Invalid date' ||
      dataShift.expire_timein_to == 'Invalid date' ||
      dataShift.expire_timeout == 'Invalid date' ||
      dataShift.expire_timeout_to == 'Invalid date'
    ) {
      swal(
        language[this.props.indexLanguage].Text_Notifi.warning,
        language[this.props.indexLanguage].Text_Notifi.notifi_date_time_invalid,
        'warning'
      );
    } else if (dataShift.time_in == '' || dataShift.time_out == '') {
      swal(
        language[this.props.indexLanguage].Text_Notifi.warning,
        language[this.props.indexLanguage].Text_Notifi
          .notifi_you_not_select_time_in_out,
        'warning'
      );
    } else if (
      dataShift.expire_timein == '' ||
      dataShift.expire_timeout_to == ''
    ) {
      swal(
        language[this.props.indexLanguage].Text_Notifi.warning,
        language[this.props.indexLanguage].Text_Notifi
          .notifi_you_not_select_expire_time_in_out,
        'warning'
      );
    } else if (dataShift.start_time_off == '' && dataShift.end_time_off != '') {
      swal(
        language[this.props.indexLanguage].Text_Notifi.warning,
        language[this.props.indexLanguage].Text_Notifi
          .notifi_you_not_select_rest_time_in_out,
        'warning'
      );
    } else if (dataShift.start_time_off != '' && dataShift.end_time_off == '') {
      swal(
        language[this.props.indexLanguage].Text_Notifi.warning,
        language[this.props.indexLanguage].Text_Notifi
          .notifi_you_not_select_end_rest_time_in_out,
        'warning'
      );
    } else {
      $('#button_addShift').addClass('m-loader');
      $('#button_addShift').attr('disabled', true);
      if (dataShift.id == undefined) {
        let data = await fetch(`${dataShift.HOST}/api/shift_work/insert`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: Store.getState().isLogin.access_token,
          },
          body: JSON.stringify(dataShift),
        }).then((response) => {
          return response.json();
        });

        if (data.status === 10000) {
          ReactGA.event({
            category: 'Ca',
            action: 'Them moi ca',
          });
          swal(
            language[this.props.indexLanguage].Text_Notifi.success,
            language[this.props.indexLanguage].Text_Notifi.add_success,
            'success',
            {
              buttons: false,
              timer: 1500,
            }
          );
          this.setState({
            date_to_work: {
              mon: true,
              tue: true,
              wed: true,
              thu: true,
              fri: true,
              sat: true,
              sun: true,
            },
          });
          return this.props.onHide();
        } else if (data.status === 10001) {
          $('#button_addShift').removeClass('m-loader');
          $('#button_addShift').removeAttr('disabled', true);
          swal(
            language[this.props.indexLanguage].Text_Notifi.warning,
            language[this.props.indexLanguage].Text_Notifi
              .notifi_name_same_shift,
            'warning'
          );
        } else {
          $('#button_addShift').removeClass('m-loader');
          $('#button_addShift').removeAttr('disabled', true);
          swal(
            language[this.props.indexLanguage].Text_Notifi.error,
            language[this.props.indexLanguage].Text_Notifi.add_false,
            'error'
          );
        }
      } else {
        //Update
        $('#button_addShift').addClass('m-loader');
        $('#button_addShift').attr('disabled', true);
        let data = await fetch(`${dataShift.HOST}/api/shift_work/update`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: Store.getState().isLogin.access_token,
          },
          body: JSON.stringify(dataShift),
        }).then((response) => {
          return response.json();
        });
        if (data.status === 10000) {
          ReactGA.event({
            category: 'Ca',
            action: 'Sua ca',
          });
          swal(
            language[this.props.indexLanguage].Text_Notifi.success,
            language[this.props.indexLanguage].Text_Notifi.edit_success,
            'success',
            {
              buttons: false,
              timer: 1500,
            }
          );
          this.setState({
            date_to_work: {
              mon: true,
              tue: true,
              wed: true,
              thu: true,
              fri: true,
              sat: true,
              sun: true,
            },
          });
          return this.props.onHide();
        } else if (data.status === 10001) {
          $('#button_addShift').removeClass('m-loader');
          $('#button_addShift').removeAttr('disabled', true);
          swal(
            language[this.props.indexLanguage].Text_Notifi.warning,
            language[this.props.indexLanguage].Text_Notifi
              .notifi_name_same_shift,
            'warning'
          );
        } else {
          $('#button_addShift').removeClass('m-loader');
          $('#button_addShift').removeAttr('disabled', true);
          swal(
            language[this.props.indexLanguage].Text_Notifi.error,
            language[this.props.indexLanguage].Text_Notifi.edit_false,
            'error'
          );
        }
      }
      //   }
    }
  };

  ShiftHandle(e) {
    var dataShift = this.state.crrShift;
    dataShift[e.target.name] = e.target.value;
    this.setState({ crrShift: dataShift });
  }

  handleCheckBox(e) {
    var dataShift = this.state.date_to_work;
    dataShift[e.target.name] = e.target.checked;

    this.setState({
      date_to_work: dataShift,
    });
    const a = Object.values(this.state.date_to_work);

    let b = '|';
    a.forEach((value, index) => {
      if (value === true) {
        b += '1|';
      } else {
        b += '0|';
      }
    });

    this.setState({
      ...this.setState,
      crrShift: {
        ...this.state.crrShift,
        day_of_week: b,
      },
    });
  }

  //Timein
  handleChangeHourStartTime = (startTime) => {
    var dataShift = this.state.crrShift;
    if (dataShift.time_out == '') {
      if (startTime === null) {
        dataShift['time_in'] = '';
        this.setState({
          crrShift: dataShift,
        });
      } else {
        dataShift['time_in'] = Moment(startTime).format('HH:mm:00');
        dataShift['start_time_off'] = '';
        dataShift['end_time_off'] = '';
        dataShift['expire_timein'] = '';
        dataShift['expire_timein_to'] = '';
        dataShift['expire_timeout'] = '';
        dataShift['expire_timeout_to'] = '';
        dataShift['total_time'] = 0;
        this.setTime(
          dataShift['time_in'],
          dataShift['time_out'],
          dataShift['start_time_off'],
          dataShift['end_time_off']
        );
        this.setState({
          crrShift: dataShift,
        });
      }
    } else {
      if (
        Moment(startTime).format('HH:mm') <
        Moment(dataShift.time_out, 'H:mm').format('HH:mm')
      ) {
        if (startTime === null) {
          dataShift['time_in'] = '';
          this.setState({
            crrShift: dataShift,
          });
        } else {
          dataShift['time_in'] = Moment(startTime).format('HH:mm:00');
          dataShift['start_time_off'] = '';
          dataShift['end_time_off'] = '';
          dataShift['expire_timein'] = '';
          dataShift['expire_timein_to'] = '';
          dataShift['expire_timeout'] = '';
          dataShift['expire_timeout_to'] = '';
          dataShift['total_time'] = 0;
          this.setTime(
            dataShift['time_in'],
            dataShift['time_out'],
            dataShift['start_time_off'],
            dataShift['end_time_off']
          );
          this.setState({
            crrShift: dataShift,
          });
        }
      } else {
        if (Moment(startTime).format('HH:mm:ss') === 'Invalid date') {
          if (startTime === null) {
            dataShift['time_in'] = '';
            this.setState({
              crrShift: dataShift,
            });
          } else {
            dataShift['time_in'] = Moment(startTime).format('HH:mm:00');
            dataShift['start_time_off'] = '';
            dataShift['end_time_off'] = '';
            dataShift['expire_timein'] = '';
            dataShift['expire_timein_to'] = '';
            dataShift['expire_timeout'] = '';
            dataShift['expire_timeout_to'] = '';
            dataShift['total_time'] = 0;
            this.setState({
              crrShift: dataShift,
            });
          }
        } else {
          dataShift['time_in'] = '';
          dataShift['start_time_off'] = '';
          dataShift['end_time_off'] = '';
          dataShift['expire_timein'] = '';
          dataShift['expire_timein_to'] = '';
          dataShift['expire_timeout'] = '';
          dataShift['expire_timeout_to'] = '';
          dataShift['total_time'] = 0;
          this.setState({
            crrShift: dataShift,
          });
          swal(
            language[this.props.indexLanguage].Text_Notifi.warning,
            language[this.props.indexLanguage].Text_Notifi.notifi_start_time,
            'warning'
          );
        }
      }
    }
  };

  //Timeout
  handleChangeHourEndTime = (endTime) => {
    var dataShift = this.state.crrShift;
    if (dataShift.time_in === '') {
      if (endTime === null) {
        dataShift['time_out'] = '';
        this.setState({
          crrShift: dataShift,
        });
      } else {
        dataShift['time_out'] = Moment(endTime).format('HH:mm:00');
        dataShift['start_time_off'] = '';
        dataShift['end_time_off'] = '';
        dataShift['expire_timein'] = '';
        dataShift['expire_timein_to'] = '';
        dataShift['expire_timeout'] = '';
        dataShift['expire_timeout_to'] = '';
        dataShift['total_time'] = 0;
        this.setTime(
          dataShift['time_in'],
          dataShift['time_out'],
          dataShift['start_time_off'],
          dataShift['end_time_off']
        );
        this.setState({
          crrShift: dataShift,
        });
      }
    } else {
      if (
        Moment(endTime).format('HH:mm') >
        Moment(dataShift.time_in, 'H:mm').format('HH:mm')
      ) {
        if (endTime === null) {
          dataShift['time_out'] = '';
          this.setState({
            crrShift: dataShift,
          });
        } else {
          dataShift['time_out'] = Moment(endTime).format('HH:mm:00');
          dataShift['start_time_off'] = '';
          dataShift['end_time_off'] = '';
          dataShift['expire_timein'] = '';
          dataShift['expire_timein_to'] = '';
          dataShift['expire_timeout'] = '';
          dataShift['expire_timeout_to'] = '';
          dataShift['total_time'] = 0;
          this.setTime(
            dataShift['time_in'],
            dataShift['time_out'],
            dataShift['start_time_off'],
            dataShift['end_time_off']
          );
          this.setState({
            crrShift: dataShift,
          });
        }
      } else {
        if (Moment(endTime).format('HH:mm:ss') === 'Invalid date') {
          if (endTime === null) {
            dataShift['time_out'] = '';
            this.setState({
              crrShift: dataShift,
            });
          } else {
            dataShift['time_out'] = Moment(endTime).format('HH:mm:00');
            dataShift['start_time_off'] = '';
            dataShift['end_time_off'] = '';
            dataShift['expire_timein'] = '';
            dataShift['expire_timein_to'] = '';
            dataShift['expire_timeout'] = '';
            dataShift['expire_timeout_to'] = '';
            dataShift['total_time'] = 0;
            this.setState({
              crrShift: dataShift,
            });
          }
        } else {
          dataShift['time_out'] = '';
          dataShift['start_time_off'] = '';
          dataShift['end_time_off'] = '';
          dataShift['expire_timein'] = '';
          dataShift['expire_timein_to'] = '';
          dataShift['expire_timeout'] = '';
          dataShift['expire_timeout_to'] = '';
          dataShift['total_time'] = 0;
          this.setState({
            crrShift: dataShift,
          });
          swal(
            language[this.props.indexLanguage].Text_Notifi.warning,
            language[this.props.indexLanguage].Text_Notifi.notifi_end_time,
            'warning'
          );
        }
      }
    }
  };

  //TimeinOff
  handleChangeHourStartTimeOff = (startTimeOff) => {
    var dataShift = this.state.crrShift;
    if (dataShift.time_in === '' || dataShift.time_out === '') {
      //nếu timein && timeout rỗng
      if (Moment(startTimeOff).format('HH:mm:ss') === 'Invalid date') {
        if (startTimeOff === null) {
          dataShift['start_time_off'] = '';
          this.setState({
            crrShift: dataShift,
          });
        } else {
          dataShift['start_time_off'] = Moment(startTimeOff).format('HH:mm:00');
          this.setState({
            crrShift: dataShift,
          });
        }
      } else {
        dataShift['start_time_off'] = '';
        this.setState({
          crrShift: dataShift,
        });
        swal(
          language[this.props.indexLanguage].Text_Notifi.warning,
          language[this.props.indexLanguage].Text_Notifi
            .notifi_you_not_select_time_in_out,
          'warning'
        );
      }
      if (dataShift.end_time_off != '') {
        this.setTime(dataShift['time_in'], dataShift['time_out'], '', '');
      }
    } else {
      //nếu timein && timeout không rỗng
      if (
        Moment(startTimeOff).format('HH:mm:ss') >= dataShift.time_in &&
        Moment(startTimeOff).format('HH:mm:ss') <= dataShift.time_out
      ) {
        //nếu Giờ nghỉ nằm trong khoảng thời gian vào và thời gian ra
        if (Moment(startTimeOff).format('HH:mm:ss') < dataShift.end_time_off) {
          //nếu Giờ nghỉ nhỏ hơn hoặc bằng giờ kết thúc nghỉ
          dataShift['start_time_off'] = Moment(startTimeOff).format('HH:mm:00');
          if (dataShift.end_time_off != '') {
            this.setTime(
              dataShift['time_in'],
              dataShift['time_out'],
              dataShift['start_time_off'],
              dataShift['end_time_off']
            );
          }
          this.setState({
            crrShift: dataShift,
          });
        } else {
          if (Moment(startTimeOff).format('HH:mm:ss') === 'Invalid date') {
            dataShift['start_time_off'] = Moment(startTimeOff).format(
              'HH:mm:00'
            );
            if (dataShift.end_time_off != '') {
              this.setTime(dataShift['time_in'], dataShift['time_out'], '', '');
            }
            this.setState({
              crrShift: dataShift,
            });
          } else {
            if (dataShift.end_time_off === '') {
              dataShift['start_time_off'] = Moment(startTimeOff).format(
                'HH:mm:00'
              );
              if (dataShift.end_time_off != '') {
                this.setTime(
                  dataShift['time_in'],
                  dataShift['time_out'],
                  dataShift['start_time_off'],
                  dataShift['end_time_off']
                );
              }
              this.setState({
                crrShift: dataShift,
              });
            } else {
              dataShift['start_time_off'] = '';
              if (dataShift.end_time_off != '') {
                this.setTime(
                  dataShift['time_in'],
                  dataShift['time_out'],
                  '',
                  ''
                );
              }
              this.setState({
                crrShift: dataShift,
              });
              swal(
                language[this.props.indexLanguage].Text_Notifi.warning,
                language[this.props.indexLanguage].Text_Notifi
                  .notifi_start_time_off,
                'warning'
              );
            }
          }
        }
      } else {
        if (Moment(startTimeOff).format('HH:mm:ss') === 'Invalid date') {
          if (startTimeOff === null) {
            dataShift['start_time_off'] = '';
            this.setState({
              crrShift: dataShift,
            });
          } else {
            dataShift['start_time_off'] = Moment(startTimeOff).format(
              'HH:mm:00'
            );
            this.setState({
              crrShift: dataShift,
            });
          }
        } else {
          dataShift['start_time_off'] = '';
          this.setState({
            crrShift: dataShift,
          });
          swal(
            language[this.props.indexLanguage].Text_Notifi.warning,
            language[this.props.indexLanguage].Text_Notifi
              .notifi_start_time_off_intime,
            'warning'
          );
        }
        if (dataShift.end_time_off != '') {
          this.setTime(dataShift['time_in'], dataShift['time_out'], '', '');
        }
      }
    }
  };

  //TimeoutOff
  handleChangeHourEndTimeOff = (endTimeOff) => {
    var dataShift = this.state.crrShift;
    if (dataShift.time_in === '' || dataShift.time_out === '') {
      //nếu timein && timeout rỗng
      if (Moment(endTimeOff).format('HH:mm:ss') === 'Invalid date') {
        if (endTimeOff === null) {
          dataShift['end_time_off'] = '';
          this.setState({
            crrShift: dataShift,
          });
        } else {
          dataShift['end_time_off'] = Moment(endTimeOff).format('HH:mm:00');
          this.setState({
            crrShift: dataShift,
          });
        }
      } else {
        dataShift['end_time_off'] = '';
        this.setState({
          crrShift: dataShift,
        });
        swal(
          language[this.props.indexLanguage].Text_Notifi.warning,
          language[this.props.indexLanguage].Text_Notifi
            .notifi_you_not_select_time_in_out,
          'warning'
        );
      }
      if (dataShift.start_time_off != '') {
        this.setTime(dataShift['time_in'], dataShift['time_out'], '', '');
      }
    } else {
      //nếu timein && timeout không rỗng
      if (
        Moment(endTimeOff).format('HH:mm:00') >= dataShift.time_in &&
        Moment(endTimeOff).format('HH:mm:00') <= dataShift.time_out
      ) {
        //nếu Giờ nghỉ nằm trong khoảng thời gian vào và thời gian ra
        if (Moment(endTimeOff).format('HH:mm:00') > dataShift.start_time_off) {
          //nếu Giờ nghỉ nhỏ hơn hoặc bằng giờ kết thúc nghỉ
          dataShift['end_time_off'] = Moment(endTimeOff).format('HH:mm:00');
          if (dataShift.start_time_off != '') {
            this.setTime(
              dataShift['time_in'],
              dataShift['time_out'],
              dataShift['start_time_off'],
              dataShift['end_time_off']
            );
          }
          this.setState({
            crrShift: dataShift,
          });
        } else {
          if (Moment(endTimeOff).format('HH:mm:ss') === 'Invalid date') {
            dataShift['end_time_off'] = Moment(endTimeOff).format('HH:mm:00');
            if (dataShift.start_time_off != '') {
              this.setTime(dataShift['time_in'], dataShift['time_out'], '', '');
            }
            this.setState({
              crrShift: dataShift,
            });
          } else {
            if (dataShift.start_time_off === '') {
              dataShift['end_time_off'] = Moment(endTimeOff).format('HH:mm:00');
              if (dataShift.start_time_off != '') {
                this.setTime(
                  dataShift['time_in'],
                  dataShift['time_out'],
                  dataShift['start_time_off'],
                  dataShift['end_time_off']
                );
              }
              this.setState({
                crrShift: dataShift,
              });
            } else {
              dataShift['end_time_off'] = '';
              if (dataShift.start_time_off != '') {
                this.setTime(
                  dataShift['time_in'],
                  dataShift['time_out'],
                  '',
                  ''
                );
              }
              this.setState({
                crrShift: dataShift,
              });
              swal(
                language[this.props.indexLanguage].Text_Notifi.warning,
                language[this.props.indexLanguage].Text_Notifi
                  .notifi_end_time_off,
                'warning'
              );
            }
          }
        }
      } else {
        if (Moment(endTimeOff).format('HH:mm:ss') === 'Invalid date') {
          if (endTimeOff === null) {
            dataShift['end_time_off'] = '';
            this.setState({
              crrShift: dataShift,
            });
          } else {
            dataShift['end_time_off'] = Moment(endTimeOff).format('HH:mm:00');
            this.setState({
              crrShift: dataShift,
            });
          }
        } else {
          dataShift['end_time_off'] = '';
          this.setState({
            crrShift: dataShift,
          });
          swal(
            language[this.props.indexLanguage].Text_Notifi.warning,
            language[this.props.indexLanguage].Text_Notifi
              .notifi_end_time_off_intime,
            'warning'
          );
        }
        if (dataShift.start_time_off != '') {
          this.setTime(dataShift['time_in'], dataShift['time_out'], '', '');
        }
      }
    }
  };

  //Expire timein
  handleChangeExpireTimein = (Expire_timein) => {
    var dataShift = this.state.crrShift;
    if (dataShift.time_in === '' || dataShift.time_out === '') {
      //nếu timein && timeout rỗng
      if (Moment(Expire_timein).format('HH:mm:ss') === 'Invalid date') {
        if (Expire_timein === null) {
          dataShift['expire_timein'] = '';
          this.setState({
            crrShift: dataShift,
          });
        } else {
          dataShift['expire_timein'] = Moment(Expire_timein).format('HH:mm:00');
          this.setState({
            crrShift: dataShift,
          });
        }
      } else {
        dataShift['expire_timein'] = '';
        this.setState({
          crrShift: dataShift,
        });
        swal(
          language[this.props.indexLanguage].Text_Notifi.warning,
          language[this.props.indexLanguage].Text_Notifi
            .notifi_you_not_select_time_in_out,
          'warning'
        );
      }
    } else {
      if (Moment(Expire_timein).format('HH:mm:ss') <= dataShift.time_in) {
        dataShift['expire_timein'] = Moment(Expire_timein).format('HH:mm:00');
        this.setState({
          crrShift: dataShift,
        });
      } else {
        if (Moment(Expire_timein).format('HH:mm:ss') === 'Invalid date') {
          if (Expire_timein === null) {
            dataShift['expire_timein'] = '';
            this.setState({
              crrShift: dataShift,
            });
          } else {
            dataShift['expire_timein'] = Moment(Expire_timein).format(
              'HH:mm:00'
            );
            this.setState({
              crrShift: dataShift,
            });
          }
        } else {
          dataShift['expire_timein'] = '';
          this.setState({
            crrShift: dataShift,
          });
          swal(
            language[this.props.indexLanguage].Text_Notifi.warning,
            language[this.props.indexLanguage].Text_Notifi
              .notifi_before_checkin,
            'warning'
          );
        }
      }
    }
  };
  handleChangeExpireTimeinTo = (Expire_timein) => {
    var dataShift = this.state.crrShift;
    if (dataShift.time_in === '' || dataShift.time_out === '') {
      //nếu timein && timeout rỗng
      if (Moment(Expire_timein).format('HH:mm:ss') === 'Invalid date') {
        if (Expire_timein === null) {
          dataShift['expire_timein_to'] = '';
          this.setState({
            crrShift: dataShift,
          });
        } else {
          dataShift['expire_timein_to'] = Moment(Expire_timein).format(
            'HH:mm:00'
          );
          this.setState({
            crrShift: dataShift,
          });
        }
      } else {
        dataShift['expire_timein_to'] = '';
        this.setState({
          crrShift: dataShift,
        });
        swal(
          language[this.props.indexLanguage].Text_Notifi.warning,
          language[this.props.indexLanguage].Text_Notifi
            .notifi_you_not_select_time_in_out,
          'warning'
        );
      }
    } else {

      if (
        Moment(Expire_timein).format('HH:mm:ss') > dataShift.time_in &&
        Moment(Expire_timein).format('HH:mm:ss') <= dataShift.time_out) {
        dataShift['expire_timein_to'] = Moment(Expire_timein).format(
          'HH:mm:00'
        );
        this.setState({
          crrShift: dataShift,
        });
      } else {
        if (Moment(Expire_timein).format('HH:mm:ss') === 'Invalid date') {
          if (Expire_timein === null) {
            dataShift['expire_timein_to'] = '';
            this.setState({
              crrShift: dataShift,
            });
          } else {
            dataShift['expire_timein_to'] = Moment(Expire_timein).format(
              'HH:mm:00'
            );
            this.setState({
              crrShift: dataShift,
            });
          }
        } else {
          // dataShift['expire_timein_to'] = '';
          // this.setState({
          //   crrShift: dataShift,
          // });
          // lan thu nhat chon gio ma trung voi gio vao lam thi se bao loi.
          // nhung o lan thu 2 van co the add duoc gio trung voi gio vao lam.
          // nen em comment lai.
          swal(
            language[this.props.indexLanguage].Text_Notifi.warning,
            language[this.props.indexLanguage].Text_Notifi.notifi_after_checkin,
            'warning'
          );
          return
        }
      }

    }
  };

  //Expire timeout
  handleChangeExpireTimeout = (Expire_timeout) => {
    var dataShift = this.state.crrShift;
    if (dataShift.time_in === '' || dataShift.time_out === '') {
      //nếu timein && timeout rỗng
      if (Moment(Expire_timeout).format('HH:mm:ss') === 'Invalid date') {
        if (Expire_timeout === null) {
          dataShift['expire_timeout'] = '';
          this.setState({
            crrShift: dataShift,
          });
        } else {
          dataShift['expire_timeout'] = Moment(Expire_timeout).format(
            'HH:mm:00'
          );
          this.setState({
            crrShift: dataShift,
          });
        }
      } else {
        dataShift['expire_timeout'] = '';
        this.setState({
          crrShift: dataShift,
        });
        swal(
          language[this.props.indexLanguage].Text_Notifi.warning,
          language[this.props.indexLanguage].Text_Notifi
            .notifi_you_not_select_time_in_out,
          'warning'
        );
      }
    } else {
      if (Moment(Expire_timeout).format('HH:mm:ss') > dataShift.expire_timein_to) {
        if (
          Moment(Expire_timeout).format('HH:mm:ss') <= dataShift.time_out &&
          Moment(Expire_timeout).format('HH:mm:ss') >= dataShift.expire_timein_to
        ) {
          if (Expire_timeout === null) {
            dataShift['expire_timeout'] = '';
            this.setState({
              crrShift: dataShift,
            });
          } else {
            dataShift['expire_timeout'] = Moment(Expire_timeout).format(
              'HH:mm:00'
            );
            this.setState({
              crrShift: dataShift,
            });
          }
        } else {
          if (Moment(Expire_timeout).format('HH:mm:ss') === 'Invalid date') {
            if (Expire_timeout === null) {
              dataShift['expire_timeout'] = '';
              this.setState({
                crrShift: dataShift,
              });
            } else {
              dataShift['expire_timeout'] = Moment(Expire_timeout).format(
                'HH:mm:00'
              );
              this.setState({
                crrShift: dataShift,
              });
            }
          } else {
            dataShift['expire_timeout'] = '';
            this.setState({
              crrShift: dataShift,
            });
            swal(
              language[this.props.indexLanguage].Text_Notifi.warning,
              language[this.props.indexLanguage].Text_Notifi
                .notifi_before_checkout,
              'warning'
            );
          }
        }
      } else {
        swal(
          language[this.props.indexLanguage].Text_Notifi.warning,
          language[this.props.indexLanguage].Text_Notifi
            .notifi_check_timeout,
          'warning'
        );
      }

    }
  };
  handleChangeExpireTimeoutTo = (Expire_timeout) => {
    var dataShift = this.state.crrShift;
    if (dataShift.time_in === '' || dataShift.time_out === '') {
      //nếu timein && timeout rỗng
      if (Moment(Expire_timeout).format('HH:mm:ss') === 'Invalid date') {
        if (Expire_timeout === null) {
          dataShift['expire_timeout_to'] = '';
          this.setState({
            crrShift: dataShift,
          });
        } else {
          dataShift['expire_timeout_to'] = Moment(Expire_timeout).format(
            'HH:mm:00'
          );
          this.setState({
            crrShift: dataShift,
          });
        }
      } else {
        dataShift['expire_timeout_to'] = '';
        this.setState({
          crrShift: dataShift,
        });
        swal(
          language[this.props.indexLanguage].Text_Notifi.warning,
          language[this.props.indexLanguage].Text_Notifi
            .notifi_you_not_select_time_in_out,
          'warning'
        );
      }
    } else {
      if (Moment(Expire_timeout).format('HH:mm:ss') >= dataShift.time_out) {
        if (Expire_timeout === null) {
          dataShift['expire_timeout_to'] = '';
          this.setState({
            crrShift: dataShift,
          });
        } else {
          dataShift['expire_timeout_to'] = Moment(Expire_timeout).format(
            'HH:mm:00'
          );
          this.setState({
            crrShift: dataShift,
          });
        }
      } else {
        if (Moment(Expire_timeout).format('HH:mm:ss') === 'Invalid date') {
          if (Expire_timeout === null) {
            dataShift['expire_timeout_to'] = '';
            this.setState({
              crrShift: dataShift,
            });
          } else {
            dataShift['expire_timeout_to'] = Moment(Expire_timeout).format(
              'HH:mm:00'
            );
            this.setState({
              crrShift: dataShift,
            });
          }
        } else {
          dataShift['expire_timeout_to'] = '';
          this.setState({
            crrShift: dataShift,
          });
          swal(
            language[this.props.indexLanguage].Text_Notifi.warning,
            language[this.props.indexLanguage].Text_Notifi
              .notifi_after_checkout,
            'warning'
          );
        }
      }
    }
  };

  setTime = (time_in, time_out, start_time_off, end_time_off) => {
    var dataShift = this.state.crrShift;
    var totalMinute = 0;
    var totalMinuteTime = 0;
    var totalMinuteTimeOff = 0;
    if (time_in && time_out != '') {
      //timeIn
      var splitTimeIn = time_in.split(':');
      var totalMinuteTimeIn = splitTimeIn[0] * 60 + parseInt(splitTimeIn[1]);
      //TimeOut
      var splitTimeOut = time_out.split(':');
      var totalMinuteTimeOut = splitTimeOut[0] * 60 + parseInt(splitTimeOut[1]);

      //timeinOff
      var totalMinuteTimeInOff = 0;
      if (start_time_off != '') {
        var splitTimeInOff = start_time_off.split(':');
        totalMinuteTimeInOff =
          splitTimeInOff[0] * 60 + parseInt(splitTimeInOff[1]);
      }

      //timeoutOff
      var totalMinuteTimeOutOff = 0;
      if (end_time_off != '') {
        var splitTimeOutOff = end_time_off.split(':');
        totalMinuteTimeOutOff =
          splitTimeOutOff[0] * 60 + parseInt(splitTimeOutOff[1]);
      }

      totalMinuteTime = totalMinuteTimeOut - totalMinuteTimeIn;
      totalMinuteTimeOff = totalMinuteTimeOutOff - totalMinuteTimeInOff;

      //Totalminute
      totalMinute = totalMinuteTime - totalMinuteTimeOff;
    }

    dataShift['total_time'] = totalMinute;
    this.setState({
      crrShift: dataShift,
    });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.show === true) {
      var dataShift = nextProps.data;
      var totalMinute = 0;
      let date_to_work = {};
      if (dataShift.modal == 'edit') {
        var totalMinuteTime = 0;
        var totalMinuteTimeOff = 0;
        //timeIn
        var splitTimeIn = nextProps.data.time_in.split(':');
        var totalMinuteTimeIn = splitTimeIn[0] * 60 + parseInt(splitTimeIn[1]);
        //TimeOut
        var splitTimeOut = nextProps.data.time_out.split(':');
        var totalMinuteTimeOut =
          splitTimeOut[0] * 60 + parseInt(splitTimeOut[1]);

        if (
          nextProps.data.start_time_off != '' ||
          nextProps.data.end_time_off != ''
        ) {
          //timeinOff
          var splitTimeInOff = nextProps.data.start_time_off.split(':');
          var totalMinuteTimeInOff =
            splitTimeInOff[0] * 60 + parseInt(splitTimeInOff[1]);
          //timeoutOff
          var splitTimeOutOff = nextProps.data.end_time_off.split(':');
          var totalMinuteTimeOutOff =
            splitTimeOutOff[0] * 60 + parseInt(splitTimeOutOff[1]);
          totalMinuteTimeOff = totalMinuteTimeOutOff - totalMinuteTimeInOff;
        }

        totalMinuteTime = totalMinuteTimeOut - totalMinuteTimeIn;

        //Totalminute
        totalMinute = totalMinuteTime - totalMinuteTimeOff;


      }
      //Day of week
      if (dataShift.day_of_week) {
        const a = dataShift.day_of_week.slice(1, 14).split('|');

        a.forEach((value, i) => {
          switch (i) {
            case 0:
              date_to_work.mon = value === '1' ? true : false;
            case 1:
              date_to_work.tue = value === '1' ? true : false;
            case 2:
              date_to_work.wed = value === '1' ? true : false;
            case 3:
              date_to_work.thu = value === '1' ? true : false;
            case 4:
              date_to_work.fri = value === '1' ? true : false;
            case 5:
              date_to_work.sat = value === '1' ? true : false;
            case 6:
              date_to_work.sun = value === '1' ? true : false;
          }
        });
      }
      dataShift['total_time'] = totalMinute;
      this.setState({
        crrShift: dataShift,
        date_to_work: dataShift.day_of_week
          ? date_to_work
          : this.state.date_to_work,
      });
    }
  }

  handleEnter = (event) => {
    if (event.keyCode === 13) {
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  render() {
    let click_handle = (event) => {
      this.updateShift(this.state.crrShift, event);
    };
    return (
      <Modal
        {...this.props}
        size='md'
        aria-labelledby='contained-modal-title-vcenter'
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'></Modal.Title>
        </Modal.Header>
        <Modal.Body className='p-0'>
          <form id='formAddShift'>
            <div className='col-xl-12'>
              <div className='m-widget14 row'>
                <div className='form-group m-form__group col-md-12 p-0'>
                  <label htmlFor='Name'>
                    {language[this.props.indexLanguage].Shift.id_shift}
                    <span className='text-danger'> *</span>
                  </label>
                  <input
                    className='form-control m-input'
                    id='Name'
                    name='name'
                    value={
                      this.state.crrShift.name === ''
                        ? ''
                        : this.state.crrShift.name
                    }
                    onKeyDown={(event) => this.handleEnter(event)}
                    onChange={(e) => this.ShiftHandle(e)}
                    placeholder={
                      language[this.props.indexLanguage].Shift.id_shift
                    }
                    required
                  />
                </div>
                <div className=' m-form__group col-md-12 pl-0-deskt p-0-mobie mt-3'>
                  <label >
                    {language[this.props.indexLanguage].Shift.time_working}
                  </label>
                </div>
                <div className='form-group m-form__group col-md-2 col-3 p-1-mobie d-flex align-items-center justify-content-md-start' >
                  <label style={{ marginBottom: 0 }} >
                    {language[this.props.indexLanguage].Shift.from}
                    <span className='text-danger'> *</span>
                  </label>
                </div>
                <div className='form-group m-form__group col-md-4 col-8 pl-0-deskt p-0-mobie '>
                  <div >
                    <KeyboardTimePicker
                      placeholder='HH:mm'
                      mask='__:__'
                      ampm={false}
                      style={{ width: "100%" }}
                      value={
                        this.state.crrShift.time_in != ''
                          ? new Date(
                            Moment(this.state.crrShift.time_in, 'H:mm')
                          )
                          : null
                      }
                      onChange={this.handleChangeHourStartTime}
                      invalidDateMessage={
                        language[this.props.indexLanguage].Text_Notifi
                          .notifi_date_time_invalid
                      }
                      required
                    />
                  </div>
                </div>
                <div className='form-group m-form__group col-md-2 col-3 p-1-mobie d-flex align-items-center justify-content-md-center pl-xl-5'>
                  <label style={{ marginBottom: 0 }} >
                    {language[this.props.indexLanguage].Shift.to}
                    <span className='text-danger'>*</span>
                  </label>
                </div>
                <div className='form-group m-form__group col-md-4 col-8 pr-0-deskt p-0-mobie'>

                  <div>
                    <KeyboardTimePicker
                      placeholder='HH:mm'
                      mask='__:__'
                      ampm={false}
                      style={{ width: "100%" }}

                      value={
                        this.state.crrShift.time_out != ''
                          ? new Date(
                            Moment(this.state.crrShift.time_out, 'H:mm')
                          )
                          : null
                      }
                      onChange={this.handleChangeHourEndTime}
                      invalidDateMessage={
                        language[this.props.indexLanguage].Text_Notifi
                          .notifi_date_time_invalid
                      }
                      required
                    />
                  </div>
                </div>

                <div className=' m-form__group col-md-12 pl-0-deskt p-0-mobie mt-3'>
                  <label>
                    {language[this.props.indexLanguage].Shift.checkin_start}
                  </label>
                </div>
                <div className='form-group m-form__group col-md-2 col-3 p-1-mobie d-flex align-items-center justify-content-md-start' >
                  <label style={{ marginBottom: 0 }}>
                    {language[this.props.indexLanguage].Shift.from}
                    <span className='text-danger'> *</span>
                  </label>
                </div>
                <div className='form-group m-form__group col-md-4 col-8 pl-0-deskt p-0-mobie'>

                  <div>
                    <KeyboardTimePicker
                      placeholder='HH:mm'
                      mask='__:__'
                      ampm={false}
                      style={{ width: "100%" }}
                      value={
                        this.state.crrShift.expire_timein != ''
                          ? new Date(
                            Moment(this.state.crrShift.expire_timein, 'H:mm')
                          )
                          : null
                      }
                      onChange={this.handleChangeExpireTimein}
                      invalidDateMessage={
                        language[this.props.indexLanguage].Text_Notifi
                          .notifi_date_time_invalid
                      }
                      required
                    />
                  </div>
                </div>
                <div className='form-group m-form__group col-md-2 col-3 p-1-mobie d-flex align-items-center justify-content-md-center pl-xl-5' >
                  <label style={{ marginBottom: 0 }}>
                    {language[this.props.indexLanguage].Shift.to}
                  </label>
                  <span className='invisible text-danger'>*</span>{' '}
                </div>
                <div className='form-group m-form__group col-md-4 col-8 pr-0-deskt p-0-mobie'>
                  <div>
                    <KeyboardTimePicker
                      placeholder='HH:mm'
                      mask='__:__'
                      ampm={false}
                      style={{ width: "100%" }}

                      value={
                        this.state.crrShift.expire_timein_to != ''
                          ? new Date(
                            Moment(
                              this.state.crrShift.expire_timein_to,
                              'H:mm'
                            )
                          )
                          : null
                      }
                      onChange={this.handleChangeExpireTimeinTo}
                      invalidDateMessage={
                        language[this.props.indexLanguage].Text_Notifi
                          .notifi_date_time_invalid
                      }
                    />
                  </div>
                </div>
                <div className=' m-form__group col-md-12 pl-0-deskt p-0-mobie mt-3' >
                  <label>
                    {language[this.props.indexLanguage].Shift.checkin_end}
                  </label>
                </div>
                <div className='form-group m-form__group col-md-2 col-3 p-1-mobie d-flex align-items-center justify-content-md-start'>
                  <label style={{ marginBottom: 0 }}>
                    {language[this.props.indexLanguage].Shift.from}
                  </label>
                </div>
                <div className='form-group m-form__group col-md-4 col-8 pl-0-deskt p-0-mobie'>

                  <div>
                    <KeyboardTimePicker
                      placeholder='HH:mm'
                      mask='__:__'
                      ampm={false}
                      style={{ width: "100%" }}

                      value={
                        this.state.crrShift.expire_timeout != ''
                          ? new Date(
                            Moment(this.state.crrShift.expire_timeout, 'H:mm')
                          )
                          : null
                      }
                      onChange={this.handleChangeExpireTimeout}
                      invalidDateMessage={
                        language[this.props.indexLanguage].Text_Notifi
                          .notifi_date_time_invalid
                      }
                    />
                  </div>
                </div>
                <div className='form-group m-form__group col-md-2 col-3 p-1-mobie d-flex align-items-center justify-content-md-center pl-xl-5'>
                  <label style={{ marginBottom: 0 }}>
                    {language[this.props.indexLanguage].Shift.to}
                    <span className='text-danger'>*</span>{' '}
                  </label>
                </div>
                <div className='form-group m-form__group col-md-4 col-8 pr-0-deskt p-0-mobie'>

                  <div>
                    <KeyboardTimePicker
                      placeholder='HH:mm'
                      mask='__:__'
                      ampm={false}
                      style={{ width: "100%" }}

                      value={
                        this.state.crrShift.expire_timeout_to != ''
                          ? new Date(
                            Moment(
                              this.state.crrShift.expire_timeout_to,
                              'H:mm'
                            )
                          )
                          : null
                      }
                      onChange={this.handleChangeExpireTimeoutTo}
                      invalidDateMessage={
                        language[this.props.indexLanguage].Text_Notifi
                          .notifi_date_time_invalid
                      }
                      required
                    />
                  </div>
                </div>
                <div className=' m-form__group col-md-12 pl-0-deskt p-0-mobie mt-3'>

                  <label>
                    {language[this.props.indexLanguage].Shift.rest_time}
                  </label>
                </div>
                <div className='form-group m-form__group col-md-2 col-3 p-1-mobie d-flex align-items-center justify-content-md-start'>
                  <label style={{ marginBottom: 0 }}>
                    {language[this.props.indexLanguage].Shift.from}
                  </label>
                </div>
                <div className='form-group m-form__group col-md-4 col-8 pl-0-deskt p-0-mobie'>

                  <div>
                    <KeyboardTimePicker
                      placeholder='HH:mm'
                      mask='__:__'
                      ampm={false}
                      style={{ width: "100%" }}

                      value={
                        this.state.crrShift.start_time_off != ''
                          ? new Date(
                            Moment(this.state.crrShift.start_time_off, 'H:mm')
                          )
                          : null
                      }
                      onChange={this.handleChangeHourStartTimeOff}
                      invalidDateMessage={
                        language[this.props.indexLanguage].Text_Notifi
                          .notifi_date_time_invalid
                      }
                    />
                  </div>
                </div>
                <div className='form-group m-form__group col-md-2 col-3 p-1-mobie d-flex align-items-center justify-content-md-center pl-xl-5'>
                  <label style={{ marginBottom: 0 }}>
                    {language[this.props.indexLanguage].Shift.to}
                  </label>
                  <span className='invisible text-danger'>*</span>{' '}

                </div>
                <div className='form-group m-form__group col-md-4 col-8 pr-0-deskt p-0-mobie'>

                  <div>
                    <KeyboardTimePicker
                      placeholder='HH:mm'
                      mask='__:__'
                      ampm={false}
                      style={{ width: "100%" }}

                      value={
                        this.state.crrShift.end_time_off != ''
                          ? new Date(
                            Moment(this.state.crrShift.end_time_off, 'H:mm')
                          )
                          : null
                      }
                      onChange={this.handleChangeHourEndTimeOff}
                      invalidDateMessage={
                        language[this.props.indexLanguage].Text_Notifi
                          .notifi_date_time_invalid
                      }
                    />
                  </div>
                </div>
                <div className='form-group m-form__group col-md-6 pl-0-deskt p-0-mobie mt-2'>
                  <label>
                    {language[this.props.indexLanguage].Shift.total_minute}
                  </label>
                  <div>
                    <input
                      type='number'
                      className='form-control m-input'
                      id='total_time'
                      value={
                        this.state.crrShift.total_time === 0
                          ? 0
                          : this.state.crrShift.total_time
                      }
                      name='total_time'
                      placeholder='Số phút'
                      required
                      readOnly
                    />
                  </div>
                </div>
                <div className='form-group m-form__group col-md-6 pr-0-deskt p-0-mobie mt-2'>
                  <label>
                    {language[this.props.indexLanguage].Shift.number_work}
                    <span className='text-danger'> *</span>
                  </label>
                  <div>
                    <input
                      type='number'
                      min='0'
                      step='0.5'
                      pattern='[0-9]'
                      className='form-control m-input'
                      id='work'
                      name='work'
                      value={
                        this.state.crrShift.work === ''
                          ? ''
                          : this.state.crrShift.work
                      }
                      onChange={(e) => this.ShiftHandle(e)}
                      required
                    />
                  </div>
                </div>
                <div className='form-group m-form__group col-md-12 p-0 mt-2'>
                  <label htmlFor='DateToWork' className='d-block'>
                    {language[this.props.indexLanguage].textTable.date_to_work}
                  </label>
                  {language[this.props.indexLanguage].DateToWork.map(
                    (map, i) => (
                      <FormControlLabel
                        key={i}
                        control={
                          <Checkbox
                            onChange={(e) => this.handleCheckBox(e)}
                            name={map.name}
                            checked={this.state.date_to_work[map.name]}
                          />
                        }
                        label={map.label}
                      />
                    )
                  )}
                </div>
                <div className='form-group m-form__group col-md-12 p-0'>
                  <label htmlFor='Description'>
                    {language[this.props.indexLanguage].textTable.description}
                  </label>
                  <textarea
                    rows='4'
                    className='form-control m-input'
                    id='Description'
                    value={
                      this.state.crrShift.description === ''
                        ? ''
                        : this.state.crrShift.description
                    }
                    name='description'
                    onChange={(e) => this.ShiftHandle(e)}
                    placeholder={
                      language[this.props.indexLanguage].textTable.description
                    }
                  />
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='accent'
            id='button_addShift'
            className='m-loader--light m-loader--right'
            onClick={click_handle}
          >
            {language[this.props.indexLanguage].textButton.save}
          </Button>
          <Button
            variant='secondary'
            onClick={() => {
              this.setState({
                date_to_work: {
                  mon: true,
                  tue: true,
                  wed: true,
                  thu: true,
                  fri: true,
                  sat: true,
                  sun: true,
                },
              });
              return this.props.onHide();
            }}
          >
            {language[this.props.indexLanguage].textButton.close}
          </Button>
        </Modal.Footer>
      </Modal >
    );
  }
}

ModalShift.propTypes = {
  data: PropTypes.object,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool,
};
const mapStateToProps = state => {
  return {
      indexLanguage: state.changelanguage.indexLanguage,
  }
}
export default connect(mapStateToProps, null)(ModalShift);
