export const CHANGELANGUAGE = 'CHANGELANGUAGE';
export function changelanguage(language, index){
    return {
        type: CHANGELANGUAGE,
        payload:{
            language: language,
            index: index,
        }
    }
}
