import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { addDays, subDays } from 'date-fns';
import vi from 'date-fns/locale/vi';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { createRef, forwardRef, useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import language from '../../../../../language/language';
import ReactGA from 'react-ga';
import { makeStyles } from "@material-ui/core/styles";
import { formatTitleOption, formatTreeViewData } from '../../../CommonFunction';
import TreeView from '../../../TreeView';
import { Button } from 'react-bootstrap';

const useStyles = makeStyles({
    paper: {
        whiteSpace: "nowrap"
    }
});

HeaderTimekeeping.propTypes = {
    data: PropTypes.object,
    list_shift: PropTypes.array,
    list_department: PropTypes.array,
    list_staff: PropTypes.array,
    list_reason: PropTypes.array,
    tab: PropTypes.number,
    getData: PropTypes.func,
    report: PropTypes.number,
    getListStaff: PropTypes.func,
    resetData: PropTypes.func,
    getDataForReason: PropTypes.func,
};

HeaderTimekeeping.defaultProps = {
    data: null,
    list_shift: null,
    list_department: null,
    list_staff: null,
    list_reason: null,
    tab: 1,
    getData: null,
    report: 0,
    getListStaff: null,
    resetData: null,
    getDataForReason: null,
}

registerLocale('vi', vi);


function HeaderTimekeeping(props) {
    const ref = createRef();

    const { data, selectedDepartment, list_shift, list_department, list_staff, tab, getData, report, getListStaff, resetData, list_reason, getDataForReason, selectDepartment } = props;
    const indexLanguage = useSelector(state => state.changelanguage.indexLanguage);

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const [defaultStartDate, setDefaultStartDate] = useState();
    const [defaultEndDate, setDefaultEndDate] = useState();

    const [listShift, setListShift] = useState([]);
    const [listDepartment, setListDepartment] = useState([]);
    const [listReason, setListReason] = useState([]);
    const [listStaff, setListStaff] = useState([]);

    const [crrShift, setCrrShift] = useState(null);
    const [crrDepartment, setCrrDepartment] = useState(null);
    const [crrStaff, setCrrStaff] = useState(null);
    const [crrReason, setCrrReason] = useState(null);

    const type = localStorage.getItem("type");
    const dataRole = useSelector(state => state.role.role);

    const [inputSearch, setInputSearch] = useState('');

    const start_end_date = tab === 2 || (tab === 3 && (report === 1 || report === 2 || report === 3 || report === 4 || report === 5 || report === 6 || report === 7 || report === 10 || report === 11 || report === 12 || report === 13)) ? true : false;
    const is_month = tab === 1 || (tab === 3 && (report === 8 || report === 9)) ? true : false;

    const show_department = tab === 3 ? true : false;
    const show_shift = false;
    const show_staff = tab === 3 && (report === 6 || report === 7) ? true : false;
    const show_reason = tab === 3 && report === 10 ? true : false;

    const ExampleCustomInput = forwardRef((props, ref) => (
        <button className='btn btn-secondary m-btn m-btn--icon col-md-12' onClick={props.onClick} ref={ref}>
            {props.value}
        </button>
    ))

    const handleChangeDate = (value, name) => {
        ReactGA.event({
            category: tab === 1 ? 'Bao cao tong hop' : tab === 2 ? 'Bao cao chi tiet' : 'Xem tung bao cao',
            action: 'Tim kiem',
            label: 'Chon thang'
        });
        if (name === 'startDate') {
            setStartDate(value);
            if (moment(value).format('MM') !== moment(startDate).format('MM') && dataRole.indexOf(type + '/timekeeping:view_by_manager') !== -1) {
                setEndDate(new Date(moment(value).endOf('month').format('YYYY-MM-DD')));
                let dataPost = {
                    ...getDataSearch(),
                    start_date: moment(value).format('YYYY-MM-DD'),
                    end_date: moment(new Date(moment(value).endOf('month').format('YYYY-MM-DD'))).format('YYYY-MM-DD'),
                };
                if (report === 6 && !crrStaff) return;
                // getData(dataPost, true);
            } else {
                let dataPost = {
                    ...getDataSearch(),
                    start_date: moment(value).format('YYYY-MM-DD'),
                    end_date: moment(endDate).format('YYYY-MM-DD'),
                };
                if (report === 6) {
                    if (!crrStaff) {
                        return;
                    }
                    dataPost = {
                        id_department: crrDepartment ? crrDepartment.value : -1,
                        start_date: moment(value).format('YYYY-MM-DD'),
                        end_date: moment(value).endOf('month').format('YYYY-MM-DD'),
                        id_staff_list: crrStaff.value !== "" ? [crrStaff.value] : null,
                    }
                }
                if (report === 10 || report === 11) {
                    dataPost = {
                        ...dataPost,
                        start_date: dataPost.start_date + ' 00:00:01',
                        end_date: dataPost.end_date + ' 23:59:59',
                    }
                }
            }
        } else if (name === 'endDate') {
            setEndDate(value);
            if (moment(value).format('MM') !== moment(endDate).format('MM') && dataRole.indexOf(type + '/timekeeping:view_by_manager') !== -1) {
                setStartDate(new Date(moment(value).startOf('month').format('YYYY-MM-DD')));
                let dataPost = {
                    ...getDataSearch(),
                    start_date: moment(new Date(moment(value).startOf('month').format('YYYY-MM-DD'))).format('YYYY-MM-DD'),
                    end_date: moment(value).format('YYYY-MM-DD'),
                };
                if (report === 6 && !crrStaff) return;
                // getData(dataPost, true);
            } else {
                let dataPost = {
                    ...getDataSearch(),
                    start_date: moment(startDate).format('YYYY-MM-DD'),
                    end_date: moment(value).format('YYYY-MM-DD'),
                };
                if (report === 6) {
                    if (!crrStaff) {
                        return;
                    }
                    dataPost = {
                        id_department: crrDepartment ? crrDepartment.value : -1,
                        start_date: moment(value).startOf('month').format('YYYY-MM-DD'),
                        end_date: moment(value).format('YYYY-MM-DD'),
                        id_staff_list: crrStaff.value !== "" ? [crrStaff.value] : null,
                    }
                }
                if (report === 10 || report === 11) {
                    dataPost = {
                        ...dataPost,
                        start_date: dataPost.start_date + ' 00:00:01',
                        end_date: dataPost.end_date + ' 23:59:59',
                    }
                }
                // getData(dataPost, true);
            }
        }
    }
    const handleChangeShift = (value) => {
        ReactGA.event({
            category: tab === 1 ? 'Bao cao tong hop' : tab === 2 ? 'Bao cao chi tiet' : 'Xem tung bao cao',
            action: 'Tim kiem',
            label: 'Chon ca'
        });
        setCrrShift(value);
        let dataPost = getDataSearch();
        dataPost.id_shift = value.value;

        // getData(dataPost);
    }
    const handleChangeDepartment = (value) => {
        ReactGA.event({
            category: tab === 1 ? 'Bao cao tong hop' : tab === 2 ? 'Bao cao chi tiet' : 'Xem tung bao cao',
            action: 'Tim kiem',
            label: 'Chon phong ban'
        });
        setCrrDepartment(value);
        if (report === 7 || report === 6) {
            getListStaff(value.value);
            setCrrStaff(null);
        } else if (report === 10 || report === 11) {
            if (report === 10) {
                setCrrReason(list_reason[0]);
            }
            const dataPost = {
                ...getDataSearch(),
                start_date: moment(getDataSearch().start_date).format('YYYY-MM-DD 00:00:01'),
                end_date: moment(getDataSearch().end_date).format('YYYY-MM-DD 23:59:59'),
                id_department: value.value,
            };
            // getData(dataPost);
        } else {
            const dataPost = {
                ...getDataSearch(),
                id_department: value.value,
            };
            // getData(dataPost);
        }
    }
    const handleChangeStaff = (value) => {
        ReactGA.event({
            category: 'Xem tung bao cao',
            action: 'Tim kiem',
            label: 'Chon nhan vien'
        });
        setCrrStaff(value);

        if (report === 6) {
            // const dataPost = {
            //     ...getDataSearch(),
            //     id_department: crrDepartment ? crrDepartment.value : -1,
            //     id_staff_list: value.value !== "" ? [value.value] : null,
            // }
            // getData(dataPost, true);
        } else if (report === 7) {
            const dataPost = {
                ...getDataSearch(),
                data_search: value.value,
            }
            // getData(dataPost, true);
        }
    }
    const handleChangeReason = (value) => {
        setCrrReason(value);
        getDataForReason(value.value);
    }

    const getDataSearch = () => {
        var data = {}
        if (report !== 7 && tab === 3) {
            data = {
                id_shift: crrShift ? crrShift.value : -1,
                id_department: crrDepartment ? crrDepartment.value : -1,
                start_date: moment(startDate).format('YYYY-MM-DD'),
                end_date: moment(endDate).format('YYYY-MM-DD'),
            }
        } else {
            data = {
                data_search: inputSearch,
                id_shift: crrShift ? crrShift.value : -1,
                id_department: crrDepartment ? crrDepartment.value : -1,
                start_date: moment(startDate).format('YYYY-MM-DD'),
                end_date: moment(endDate).format('YYYY-MM-DD'),
            }
        }

        return data;
    }
    const handleSearch = () => {
        const dataPost = getDataSearch();
        if (dataPost.data_search.length > 0) {
            ReactGA.event({
                category: tab === 1 ? 'Bao cao tong hop' : tab === 2 ? 'Bao cao chi tiet' : 'Xem tung bao cao',
                action: 'Tim kiem',
                label: 'Nhap ID va Ten'
            });
        }
        getData(dataPost);
    }

    const handleSearchReport = () => {
        if ((new Date(endDate).getTime() - new Date(startDate).getTime()) / 86400000 > new Date(new Date(startDate).getFullYear(), new Date(startDate).getMonth() + 1, 0).getDate()) {
            return swal({
                text: language[indexLanguage].Timekeeping.limited_1_month,
                icon: "warning",
                buttons: {
                    oke: language[indexLanguage].textButton.close
                },
            })
        } else if (report === 7 && crrStaff) {
            resetData();
            const dataPost = {
                ...getDataSearch(),
                start_date: moment(startDate).format('YYYY-MM-DD'),
                end_date: moment(endDate).format('YYYY-MM-DD'),
                data_search: crrStaff.value,
            }
            getData(dataPost, true);
        } else if (report === 7 && !crrStaff) {
            return swal({
                text: language[indexLanguage].Text_Notifi.text_notifi_select_staff,
                icon: "warning",
                buttons: {
                    oke: language[indexLanguage].textButton.close
                },
            })
        } else if (report === 6) {
            resetData();
            const dataPost = {
                ...getDataSearch(),
                id_department: crrDepartment ? crrDepartment.value : -1,
                id_staff_list: crrStaff && crrStaff.value !== "" ? [crrStaff.value] : null,
                start_date: moment(startDate).format('YYYY-MM-DD'),
                end_date: moment(endDate).format('YYYY-MM-DD'),
            }
            getData(dataPost, true);
        } else {
            resetData();
            const dataPost = {
                ...getDataSearch(),
            }
            getData(dataPost, true);
        }
    }
    const handleReset = () => {
        setCrrShift(listShift[0]);
        setCrrDepartment(listDepartment[0]);
        setStartDate(defaultStartDate);
        setEndDate(defaultEndDate);
        setInputSearch('');

        const dataPost = {
            data_search: '',
            id_shift: listShift[0].value,
            id_department: listDepartment[0] ? listDepartment[0].value : -1,
            start_date: moment(defaultStartDate).format('YYYY-MM-DD'),
            end_date: moment(defaultEndDate).format('YYYY-MM-DD'),
        }
        getData(dataPost);
    }
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    }
    useEffect(() => {
        setCrrDepartment(selectedDepartment);
    }, [selectedDepartment])
    useEffect(() => {
        if (list_shift) {
            setCrrShift(list_shift[0]);
            setListShift(list_shift);
        }
        if (list_department) {
            // console.log(list_department);
            // if (list_department.length <= 2 && list_department.length > 0) {
            //     list_department.shift();
            // }
            setCrrDepartment(list_department[0]);
            setListDepartment(list_department);
        }
        if (list_reason) {
            setCrrReason(list_reason[0]);
            setListReason(list_reason);
        }
    }, [list_shift, list_department, list_reason]);
    useEffect(() => {
        setListStaff(list_staff);
    }, [list_staff, report])

    useEffect(() => {
        if (data) {
            setStartDate(new Date(data.start_date));
            setEndDate(new Date(data.end_date));
            setDefaultStartDate(new Date(data.start_date));
            setDefaultEndDate(new Date(data.end_date));
        }
    }, [data]);

    useEffect(() => {
        setCrrStaff(null);
    }, [report]);
    const classes = useStyles();
    return (
        <div className={report ? 'col-md-8' : 'col-md-12'}>
            <div className='row-sm row'>
                <div className='col-md-2 pb-2 col-xs-6 pl-0 select_date_month'>
                    <DatePicker
                        className='form-control m-input m_datetimepicker_6'
                        selected={startDate}
                        onChange={date => handleChangeDate(date, 'startDate')}
                        dateFormat={start_end_date ? 'dd-MM-yyyy' : is_month ? 'MM/yyyy' : 'MM/yyyy'}
                        // minDate={report === 7 && new Date(moment(endDate).subtract(new Date(new Date(endDate).getFullYear(), new Date(endDate).getMonth() + 1, 0).getDate(), 'days'))}
                        maxDate={start_end_date && endDate}
                        showMonthYearPicker={is_month ? true : false}
                        placeholderText={start_end_date ? 'dd-MM-yyyy' : is_month ? 'MM/yyyy' : 'MM/yyyy'}
                        customInput={<ExampleCustomInput ref={ref} />}
                        locale={(indexLanguage === 1 && is_month) ? vi : ''}
                    />
                </div>
                {
                    (start_end_date) &&
                    <div className='col-md-2 pb-2 col-xs-6 pl-0 select_date_month'>
                        <DatePicker
                            className='form-control m-input m_datetimepicker_6'
                            selected={endDate}
                            onChange={date => handleChangeDate(date, 'endDate')}
                            dateFormat='dd-MM-yyyy'
                            placeholderText='dd-MM-yyyy'
                            selectsEnd
                            minDate={startDate}
                            maxDate={new Date(moment(startDate).add(3, 'M').format('YYYY-MM-DD'))}
                            customInput={<ExampleCustomInput ref={ref} />}
                        />
                    </div>
                }
                {
                    (show_shift) &&
                    <div className='col-md-2 pb-2 pl-0  m--margin-bottom-10-tablet-and-mobile select_option'>
                        <div className='m-form__control select_option'>
                            <Autocomplete
                                value={crrShift}
                                options={listShift}
                                onChange={(e, value) => handleChangeShift(value)}
                                getOptionLabel={option => option.title}
                                disableClearable
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        variant='outlined'
                                        placeholder={language[indexLanguage].placeholderSelect.select_shift}
                                        fullWidth
                                    />
                                )}
                            />
                        </div>
                    </div>
                }
                {
                    (tab === 1 || tab === 2 || show_department) &&
                    dataRole.indexOf(type + '/timekeeping:view_by_manager') !== -1 &&
                    <div className='col-md-2 pl-0 m--margin-bottom-10-tablet-and-mobile select_option'>
                        <Button
                            onClick={selectDepartment}
                            className="btn btn-secondary btn-block m-btn m-btn--icon"
                        >
                            <span style={{ color: "#000000" }}>
                                {crrDepartment ? crrDepartment.value == -1 ? "Chọn phòng ban" : crrDepartment.name : ""}
                            </span>
                        </Button>
                        {/* <Autocomplete
                            options={listDepartment}
                            value={crrDepartment}
                            onChange={(e, value) => handleChangeDepartment(value)}
                            getOptionLabel={option => option.title}
                            disableClearable
                            renderOption={(props, option, state) => (
                                <div {...props}>
                                    {formatTitleOption(props)}
                                </div>
                            )}
                            classes={{ paper: classes.paper }}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    variant='outlined'
                                    placeholder={language[indexLanguage].placeholderSelect.select_department}
                                    fullWidth
                                />
                            )}
                        /> */}
                    </div>
                }
                {
                    show_staff &&
                    <div className='pl-0 col-md-3 m--margin-bottom-10-tablet-and-mobile select_option'>
                        <div className='m-form__control select_option'>
                            <Autocomplete
                                // style={{ width: 300 }}
                                value={crrStaff}
                                options={listStaff}
                                onChange={(e, value) => handleChangeStaff(value)}
                                getOptionLabel={option => option.title}
                                disableClearable
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        variant='outlined'
                                        placeholder={language[indexLanguage].placeholderSelect.select_staff}
                                        fullWidth
                                    />
                                )}
                            />
                        </div>
                    </div>
                }
                {
                    show_reason &&
                    <div className='pl-0 col-md-2 m--margin-bottom-10-tablet-and-mobile select_option'>
                        <div className='m-form__control select_option'>
                            <Autocomplete
                                // style={{ width: 300 }}
                                value={crrReason}
                                options={listReason}
                                onChange={(e, value) => handleChangeReason(value)}
                                getOptionLabel={option => option.title}
                                disableClearable
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        variant='outlined'
                                        placeholder={language[indexLanguage].placeholderSelect.select_reason}
                                        fullWidth
                                    />
                                )}
                            />
                        </div>
                    </div>
                }
                {
                    tab !== 3 &&
                    <>
                        {
                            dataRole.indexOf(type + '/timekeeping:view_by_manager') !== -1 &&
                            <div className='col-md-2 pl-0 pr-0 ml-desktop-2 m--margin-bottom-10-tablet-and-mobile'>
                                <input type='text' id='myInput' onKeyPress={e => handleKeyPress(e)} onChange={(e) => setInputSearch(e.target.value)} id='inputSearchTotal' value={inputSearch} className='form-control m-input' placeholder={language[indexLanguage].placeholderInput.id_or_name} />
                            </div>
                        }
                        <div className='ml-desktop-2'>
                            <button id='myBtn' className='btn btn-accent m-btn m-btn--icon' onClick={() => handleSearch()}>
                                <span>
                                    <i className='la la-search' />
                                    <span>{language[indexLanguage].textButton.search}</span>
                                </span>
                            </button>
                        </div>
                        <div className='ml-2 mb-mobile-10'>
                            <button className='btn btn-secondary m-btn m-btn--icon' onClick={() => handleReset()}>
                                <span>
                                    <i className='la la-rotate-left' />
                                    <span>{language[indexLanguage].textButton.reload}</span>
                                </span>
                            </button>
                        </div>
                    </>
                }
                {
                    (tab === 3) &&
                    <div className='ml-desktop-2'>
                        <button id='myBtn' className='btn btn-accent m-btn m-btn--icon' onClick={() => handleSearchReport()}>
                            <span>
                                <i className='la la-search' />
                                <span>{language[indexLanguage].Report.view_report}</span>
                            </span>
                        </button>
                    </div>
                }
            </div>
        </div>
    );
}

export default HeaderTimekeeping;