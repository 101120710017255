import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useEffect } from 'react';
import language from '../../../../../language/language';
import Moment from 'moment';

export default function ListOfLeaveOfAbsenceInfo(props) {

    const box_engine_cf = useSelector(state => state.boxai.box_engine_cf);
    const userData = JSON.parse(localStorage.getItem('data_user'));
    const token = useSelector(state => state.isLogin.access_token);
    
    const indexLanguage = useSelector(state => state.changelanguage.indexLanguage);
    const [dataUser,setDataUser] = useState({
        ndo: 0,
        ndo_unpaid: 0,
        ndo_used: 0,
        ndo_ya: 0,
    });

    const getDataUser = async () => {
        try {
            const data = await axios({
                method:'GET',
                url: `${box_engine_cf.crrApiSocket}/api/daysoff/get_by_id_staff/${userData.id_staff}/${Moment(new Date()).format("YYYY")}`,
                headers:{
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': token,
                }
            })
            if(data.data.status === 10000){
                if (Object.keys(data.data.data).length) {
                    setDataUser(data.data.data)
                }
            }
        } catch (error) {
            console.log(error);
        }
    }    

    useEffect(() => {
        if(box_engine_cf){
            getDataUser()
        }
    },[box_engine_cf]);

    

     return (
         <div className="p-md-5">
             <div className="row py-4 px-md-5" style={{border:'5px solid #f0f0f0'}}>
                 <div className="col-md-4 col-12">
                     <div className='dayoff_form row'>
                         <div className='col-md-8 col-9'>{language[indexLanguage].DayOff.number_of_day_off}</div>
                         <span style={{width:'50px'}}>{dataUser.ndo.toFixed(2)}</span>
                     </div>
                     <div className='dayoff_form row'>
                         <div className='col-md-8 col-9'>{language[indexLanguage].DayOff.number_surplus_day_off_previous_year}</div>
                         <span style={{width:'50px'}}>{dataUser.ndo_ya.toFixed(2)}</span>
                     </div>
                 </div>
                 <div className="col-md-4 col-12">
                     <div className='dayoff_form row'>
                         <div className='col-md-8 col-9'>{language[indexLanguage].DayOff.number_day_off_not_salary}</div>
                         <span style={{width:'50px'}}>{dataUser.ndo_unpaid.toFixed(2)}</span>
                     </div>
                     <div className='dayoff_form row'>
                         <div className='col-md-8 col-9'>{language[indexLanguage].DayOff.number_day_off_used}</div>
                         <span style={{width:'50px'}}>{dataUser.ndo_used.toFixed(2)}</span>
                     </div>
                 </div>
                 <div className="col-md-4 col-12">
                     <div className='dayoff_form row'>
                         <div className='col-md-8 col-9'>{language[indexLanguage].DayOff.number_day_off_rest}</div>
                         <span style={{width:'50px'}}>{(dataUser.ndo + dataUser.ndo_ya - dataUser.ndo_used).toFixed(2)}</span>
                     </div>
                 </div>
             </div>
         </div>
    )
}