import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Axios from 'axios';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import LoadingOverlay from 'react-loading-overlay';
import moment, { lang } from 'moment';
import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import language from '../../../language/language';
import { CHART } from '../../chart';
import ReactGA from 'react-ga';
import { getListDepartmentSupervisorSelectAll } from '../../../api';
import { formatTitleOption, formatTreeViewData } from '../CommonFunction';
import { makeStyles } from "@material-ui/core/styles";
import { Button } from 'react-bootstrap';
import TreeView from '../TreeView';
import Header from '../../layouts/Header';

const useStyles = makeStyles({
    paper: {
        whiteSpace: "nowrap"
    }
});


const today = moment(new Date()).format('YYYY-MM-DD') + ' 23:59:59';

function DashBoard() {

    const box_engine_cf = useSelector(state => state.boxai.box_engine_cf);
    const token = useSelector(state => state.isLogin.access_token);

    const indexLanguage = useSelector(state => state.changelanguage.indexLanguage);
    const type = localStorage.getItem("type");
    const id_staff = localStorage.getItem('id_user');
    const dataRole = useSelector(state => state.role.role);
    const is_company = useSelector(state => state.boxai.is_company);

    const [view, setView] = useState(2);
    const [col, setCol] = useState('col-xl-6');

    let xAxis = [];
    for (let i = 0; i <= 12; i++) {
        let month = ''
        switch (i + 1) {
            case 1:
                month = language[indexLanguage].Month.january
                break;
            case 2:
                month = language[indexLanguage].Month.february
                break;
            case 3:
                month = language[indexLanguage].Month.march
                break;
            case 4:
                month = language[indexLanguage].Month.april
                break;
            case 5:
                month = language[indexLanguage].Month.may
                break;
            case 6:
                month = language[indexLanguage].Month.june
                break;
            case 7:
                month = language[indexLanguage].Month.july
                break;
            case 8:
                month = language[indexLanguage].Month.august
                break;
            case 9:
                month = language[indexLanguage].Month.september
                break;
            case 10:
                month = language[indexLanguage].Month.october
                break;
            case 11:
                month = language[indexLanguage].Month.november
                break;
            case 12:
                month = language[indexLanguage].Month.december
                break;

            default:
                break;
        }
        xAxis.push(month);
    }

    const default_department = {
        title: language[indexLanguage].DashBoard.all_department,
        id: -1,
    }

    const default_shift = {
        title: language[indexLanguage].DashBoard.all_shift,
        id: -1,
    }

    const [date_1, setDate_1] = useState(new Date());
    const [department_1, setDepartment_1] = useState({});

    const [shift_3, setShift_3] = useState({});
    const [total_3, setTotal_3] = useState({
        staff_on_time: '',
        staff_business: '',
        staff_day_off: '',
        staff_to_late: '',
    });

    const [isActive_1, setIsActive_1] = useState(false);
    const [isActive_2, setIsActive_2] = useState(false);
    const [isActive_3, setIsActive_3] = useState(false);
    const [isActive_4, setIsActive_4] = useState(false);
    const [selectDepartment, setSelectDepartment] = useState(false);
    const [selectDepartment2, setSelectDepartment2] = useState(false);

    const [date_4, setDate_4] = useState(new Date());
    const [department_4, setDepartment_4] = useState({});
    const [shift_4, setShift_4] = useState({});

    const [listDepartment, setListDepartment] = useState([]);
    const [listShift, setListShift] = useState([]);

    const [chartOptions_1, setChartOptions_1] = useState(Object.assign({
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: true,
                    color: 'red',
                    formatter: function () {
                        return (this.y !== 0) ? this.y : "";
                    }
                }
            }
        },
        series: [
            {
                name: language[indexLanguage].DashBoard.is_work,
                data: [],
            },
            {
                name: language[indexLanguage].DashBoard.quit_job,
                data: [],
            }
        ],
        xAxis: {
            categories: xAxis,
        },
        yAxis: {
            title: {
                text: language[indexLanguage].DashBoard.staff,
            },
            allowDecimals: false,
        }
    }, CHART));

    const [chartOptions_2, setChartOptions_2] = useState(Object.assign({
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: true,
                    color: 'red',
                    formatter: function () {
                        return (this.y !== 0) ? this.y : "";
                    }
                }
            }
        },
        xAxis: {
            categories: xAxis,
        },
        yAxis: {
            title: {
                text: language[indexLanguage].DashBoard.hour,
            },
            allowDecimals: false,
        }
    }, CHART));

    const [chartOptions_3, setChartOptions_3] = useState(Object.assign({
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: true,
                    color: 'red',
                    formatter: function () {
                        return '' + this.point.text;
                    }
                }
            }
        },
        yAxis: {
            title: {
                text: language[indexLanguage].DashBoard.staff,
            },
            allowDecimals: false,
        }
    }, CHART));
    const [chartOptions_4, setChartOptions_4] = useState(Object.assign({
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: true,
                    color: 'red',
                    formatter: function () {
                        return (this.y !== 0) ? this.y : "";
                    }
                }
            }
        },
        xAxis: {
            categories: xAxis,
        },
        yAxis: {
            title: {
                text: language[indexLanguage].DashBoard._number,
            },
            allowDecimals: false,
        }
    }, CHART));

    const getListDepartment = async () => {
        if (dataRole && dataRole.indexOf(type + '/dashboard:view_all') !== -1) {
            const result = await Axios({
                method: 'GET',
                url: `${box_engine_cf.crrApiSocket}/api/department/${is_company}/get_all`,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': token,
                }
            });
            if (result.data.status === 10000) {
                let data = result.data.data.map(map => ({
                    title: map.name,
                    id: map.id,
                    code: map.code
                }));
                data.unshift(default_department);
                setListDepartment(data);
                setDepartment_1(data[0]);
                setDepartment_4(data[0]);
                getDataHighChart_1(data[0].id);
                getDataHighChart_3(data[0].id);
                getDataHighChart_4(data[0].id);
            } else {
                setListDepartment([default_department]);
                setDepartment_1(default_department);
                setDepartment_4(default_department);
            }
        } else {
            const result = await getListDepartmentSupervisorSelectAll(box_engine_cf.crrApiSocket, id_staff, token);

            const data = result.map(map => ({
                title: map.title,
                id: map.value,
                code: map.code
            }))

            if (data.length <= 2 && data.length > 0) {
                data.shift();
            }
            getDataHighChart_1(data[0].id);
            getDataHighChart_3(data[0].id);
            getDataHighChart_4(data[0].id);

            setListDepartment(data);
            setDepartment_1(data[0]);
            setDepartment_4(data[0]);
        }
    }
    const getListShift = async () => {
        const result = await Axios({
            method: 'GET',
            url: `${box_engine_cf.crrApiSocket}/api/shift_work/${is_company}/get_by_active/1`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token,
            }
        });
        if (result.data.status === 10000) {
            let data = result.data.data.map(map => ({
                title: map.name,
                id: map.id,
            }));
            data.unshift(default_shift);
            setListShift(data);
            setShift_3(data[0]);
            setShift_4(data[0]);
        } else {
            setListShift([default_shift]);
            setShift_3(default_shift);
            setShift_4(default_shift);
        }
    }

    const getDataHighChart_1 = async (department, date) => {
        setIsActive_1(true);
        const dataPost = {
            start_date: moment(date ? date : date_1).startOf('year').format('YYYY-MM-DD HH:mm:ss'),
            end_date: parseInt(moment(date ? date : date_1).format('YYYY')) === new Date(today.replace(/-/g, "/")).getFullYear() ? today : moment(date ? date : date_1).endOf('year').format('YYYY-MM-DD HH:mm:ss'),
            ids_company: [is_company],
            id_department: department ? department : department_1 && Object.keys(department_1).length > 0 ? department_1.id : -1,
        }
        const result = await Axios({
            method: 'POST',
            url: `${box_engine_cf.crrApiSocket}/api/time_keeping/statistics_staff_month`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            data: dataPost,
        });
        if (result.data.status === 10000) {
            setIsActive_1(false);
            const data = Object.values(result.data.data);
            let staff = [];
            let quit_job = [];
            data.map(map => {
                staff.push(Object.values(map)[0]['total_staff']);
                quit_job.push(Object.values(map)[0]['total_staff_quit_job']);
            });
            setChartOptions_1({
                ...chartOptions_1,
                series: [
                    {
                        name: language[indexLanguage].DashBoard.is_work,
                        data: staff,
                    },
                    {
                        name: language[indexLanguage].DashBoard.quit_job,
                        data: quit_job,
                    }
                ]
            })
        }
    }

    const getDataHighChart_3 = async (value) => {
        setIsActive_3(true);
        const dataPost = {
            start_date: moment(new Date()).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            end_date: today,
            ids_company: [is_company],
            id_shift: value ? value.id : -1,
        };
        const result = await Axios({
            method: 'POST',
            url: `${box_engine_cf.crrApiSocket}/api/time_keeping/statistics_staff_day`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            data: dataPost,
        });
        if (result.data.status === 10000) {
            setIsActive_3(false);
            const data = Object.values(Object.values(result.data.data)[0]);
            let staff_business = [];
            let staff_day_off = [];
            let staff_on_time = [];
            let staff_to_late = [];

            let sum_total_on_time = 0;
            let sum_total_staff = 0;
            let sum_total_day_off = 0;
            let sum_total_business = 0;
            let sum_total_staff_to_late = 0;

            let xAxis = [];
            data.map(map => {
                const on_time = map.staff_on_time + map.staff_late;
                const total_staff = map.total_staff ? map.total_staff : '';
                xAxis.push(map.name_department);
                staff_business.push(map.staff_business ? { y: map.staff_business, text: map.staff_business } : { y: 0, text: '' });
                staff_day_off.push(map.staff_day_off ? { y: map.staff_day_off, text: map.staff_day_off } : { y: 0, text: '' });
                staff_on_time.push(on_time ? { y: on_time, text: `${on_time}/${total_staff}` } : { y: 0, text: '' });
                staff_to_late.push(map.staff_late ? { y: map.staff_late, text: map.staff_late } : { y: 0, text: '' });

                sum_total_business += map.staff_business;
                sum_total_day_off += map.staff_day_off;
                sum_total_on_time += map.staff_on_time + map.staff_late;
                sum_total_staff += map.total_staff;
                sum_total_staff_to_late += map.staff_late;
            });
            setTotal_3({
                staff_on_time: sum_total_on_time + '/' + sum_total_staff,
                staff_day_off: sum_total_day_off,
                staff_business: sum_total_business,
                staff_to_late: sum_total_staff_to_late,
            })
            setChartOptions_3({
                ...chartOptions_3,
                xAxis: {
                    categories: xAxis,
                },
                series: [
                    {
                        type: 'column',
                        name: language[indexLanguage].DashBoard.present,
                        data: staff_on_time,
                    },
                    {
                        type: 'column',
                        name: language[indexLanguage].DashBoard.off,
                        data: staff_day_off,
                    },
                    {
                        type: 'column',
                        name: language[indexLanguage].DashBoard.business,
                        data: staff_business,
                    },
                    {
                        type: 'column',
                        name: language[indexLanguage].DashBoard.to_late,
                        data: staff_to_late,
                    },
                ]
            })
        }
    }

    const getDataHighChart_4 = async (department, date, shift) => {
        setIsActive_2(true);
        setIsActive_4(true);
        const dataPost = {
            start_date: moment(date ? date : date_4).startOf('year').format('YYYY-MM-DD HH:mm:ss'),
            end_date: parseInt(moment(date ? date : date_4).format('YYYY')) === new Date(today).getFullYear() ? today : moment(date ? date : date_4).endOf('year').format('YYYY-MM-DD HH:mm:ss'),
            ids_company: [is_company],
            id_department: department ? department : department_1 && Object.keys(department_4).length > 0 ? department_4.id : -1,
            id_shift: shift ? shift.id : -1,
        }
        const result = await Axios({
            method: 'POST',
            url: `${box_engine_cf.crrApiSocket}/api/time_keeping/statistic_by_year`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            data: dataPost,
        });
        if (result.data.status === 10000) {
            setIsActive_2(false);
            setIsActive_4(false);
            const data = Object.values(Object.values(result.data.data)[0]);
            let out_soon = [];
            let in_late = [];
            let ot = [];
            let ct = [];
            let time_inlate = [];
            let time_outsoon = [];
            let timekeeping = [];
            data.map(map => {
                out_soon.push(map.out_soon);
                in_late.push(map.in_late);
                ot.push(parseInt(map['OT']));
                ct.push(parseInt(map['CT']));
                time_inlate.push(parseInt(map.time_inlate));
                time_outsoon.push(parseInt(map.time_outsoon));
                timekeeping.push(parseInt(map.timekeeping));
            });
            setChartOptions_2({
                ...chartOptions_2,
                series: [
                    {
                        name: language[indexLanguage].DashBoard.hour_timekeeping,
                        data: timekeeping,
                    },
                    {
                        name: language[indexLanguage].DashBoard.ot,
                        data: ot,
                    },
                    {
                        name: language[indexLanguage].DashBoard.hour_business,
                        data: ct,
                    },
                    {
                        name: language[indexLanguage].DashBoard.hour_in_late,
                        data: time_inlate,
                    },
                    {
                        name: language[indexLanguage].DashBoard.hour_out_soon,
                        data: time_outsoon,
                    },
                ]
            })
            setChartOptions_4({
                ...chartOptions_4,
                series: [
                    {
                        name: language[indexLanguage].DashBoard.out_soon,
                        data: out_soon,
                    },
                    {
                        name: language[indexLanguage].DashBoard.in_late,
                        data: in_late,
                    }
                ]
            })
        }
    }

    const handleGirdLayout = (data) => {
        setView(data);
        setCol(data === 1 ? 'col-xl-12' : data === 2 ? 'col-xl-6' : '');
        setChartOptions_1({
            ...chartOptions_1,
            chart: {
                ...chartOptions_1.chart,
                height: data === 1 ? windowSize.height - 255 : data === 2 ? 300 : '',
            }
        });
        setChartOptions_2({
            ...chartOptions_2,
            chart: {
                ...chartOptions_2.chart,
                height: data === 1 ? windowSize.height - 255 : data === 2 ? 300 : '',
            }
        });
        setChartOptions_3({
            ...chartOptions_3,
            chart: {
                ...chartOptions_3.chart,
                height: data === 1 ? windowSize.height - 255 : data === 2 ? 300 : '',
            }
        });
        setChartOptions_4({
            ...chartOptions_4,
            chart: {
                ...chartOptions_4.chart,
                height: data === 1 ? windowSize.height - 255 : data === 2 ? 300 : '',
            }
        });
    }
    const ExampleCustomInput = ({ value, onClick }) => (
        <button className="btn btn-secondary m-btn m-btn--icon col-md-12" onClick={onClick}>
            {value}
        </button>
    );

    useEffect(() => {
        const fetchData = () => {
            if (Highcharts.charts[0]) {
                Highcharts.charts[0].reflow();
                Highcharts.charts[1].reflow();
                Highcharts.charts[2].reflow();
                Highcharts.charts[3].reflow();
            }
        }
        fetchData();
    }, [view]);

    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
            setChartOptions_1({
                ...chartOptions_1,
                chart: {
                    ...chartOptions_1.chart,
                    height: window.innerWidth < 550 ? 300 : view === 2 ? 300 : window.innerHeight - 255,
                }
            });
            setChartOptions_2({
                ...chartOptions_2,
                chart: {
                    ...chartOptions_2.chart,
                    height: window.innerWidth < 550 ? 300 : view === 2 ? 300 : window.innerHeight - 255,
                }
            });
            setChartOptions_3({
                ...chartOptions_3,
                chart: {
                    ...chartOptions_3.chart,
                    height: window.innerWidth < 550 ? 300 : view === 2 ? 300 : window.innerHeight - 255,
                }
            });
            setChartOptions_4({
                ...chartOptions_4,
                chart: {
                    ...chartOptions_4.chart,
                    height: window.innerWidth < 550 ? 300 : view === 2 ? 300 : window.innerHeight - 255,
                }
            });
        }

        // Add event listener
        window.addEventListener("resize", handleResize);

        // Call handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        if (box_engine_cf) {
            getListDepartment();
            getListShift();
        }
    }, [box_engine_cf, is_company]);

    const classes = useStyles();
    return (
        <>
        <Header />
        <div className={"m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body "}>
        <div className="m-grid__item m-grid__item--fluid m-wrapper dashboard_wrapper">
            <div className="m-content mt-3-phone pd_phone_0 pl-0 pr-0">
                {
                    windowSize.width > 1200 &&
                    <div className="px-2">
                        <div className="m-portlet header_dashboard my-1">
                            <button onClick={() => handleGirdLayout(2)}>
                                <i className="fas fa-th-large" style={view === 2 ? { color: '#000' } : {}}></i>
                            </button>
                            <button onClick={() => handleGirdLayout(1)}>
                                <i className="fas fa-th-list" style={view === 1 ? { color: '#000' } : {}}></i>
                            </button>
                        </div>
                    </div>
                }
                <div className="row m_phone_0 mr-0 ml-0 d-flex">
                    <div className={`${col} col-md-12 pr-2 pl-2 pd_phone_0 order-1`}>
                        <div className="m-portlet body_dashboard mb-2">
                            <div className="m-portlet__head d-md-flex justify-content-md-end">
                                <div className="m-portlet__head-tools select_date_month col-md-6 p-0 body_dashboard_portlet d-md-flex justify-content-md-end">
                                    {/* <Autocomplete
                                        className='select_option mr-2'
                                        options={listDepartment}
                                        getOptionLabel={(option) => option.title}
                                        style={{ width: 180 }}
                                        value={department_1}
                                        renderOption={(props, option, state) => (
                                            <div {...props}>
                                                {formatTitleOption(props)}
                                            </div>
                                        )}
                                        classes={{ paper: classes.paper }}
                                        onChange={(e, value) => {
                                            ReactGA.event({
                                                category: 'Dashboard',
                                                action: 'Xem bien dong nhan su',
                                                label: 'Chon phong ban'
                                            });
                                            setDepartment_1(value);
                                            getDataHighChart_1(value.id, 0);
                                        }}
                                        disableClearable
                                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                                    /> */}
                                    <Button
                                        onClick={() => {
                                        setSelectDepartment(prev => !prev)
                                        }}
                                        className="btn btn-secondary btn-block m-btn m-btn--icon"
                                    >
                                        <span style={{ color: "#000000" }}>
                                        {department_1.value == -1 ? "Chọn phòng ban" : department_1.title}
                                        </span>
                                    </Button>

                                    <ReactDatePicker
                                        className="form-control m-input m_datetimepicker_6"
                                        selected={date_1}
                                        onChange={date => {
                                            ReactGA.event({
                                                category: 'Dashboard',
                                                action: 'Xem bien dong nhan su',
                                                label: 'Chon nam'
                                            });
                                            setDate_1(date);
                                            getDataHighChart_1(0, date);
                                        }}
                                        showYearPicker
                                        dateFormat="yyyy"
                                        customInput={<ExampleCustomInput />}
                                    />
                                </div>
                            </div>
                            <div className="m-portlet__body dashboard_spinner">
                                <div className='row'>
                                        {
                                            selectDepartment && (
                                                <div className='col-xl-12 overflow-chart'>
                                                    <TreeView
                                                        item={formatTreeViewData(listDepartment ? listDepartment : [])} 
                                                        getList={(data) => {
                                                            setSelectDepartment(false)
                                                            ReactGA.event({
                                                                category: 'Dashboard',
                                                                action: 'Xem bien dong nhan su',
                                                                label: 'Chon phong ban'
                                                            });
                                                            setDepartment_1(data);
                                                            getDataHighChart_1(data.id, 0);
                                                        }}
                                                    />
                                                </div>
                                            )
                                        }
                                    <div className={selectDepartment ? 'hidden' : 'col-xl-12'}>
                                        <LoadingOverlay
                                            active={isActive_1}
                                            spinner
                                            styles={{
                                                overlay: (base) => ({
                                                    ...base,
                                                    background: 'rgba(202, 202, 202, 0)',
                                                })
                                            }}
                                        >
                                            <HighchartsReact
                                                highcharts={Highcharts}
                                                options={chartOptions_1}
                                            />
                                        </LoadingOverlay>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${col} col-md-12 pr-2 pl-2 pd_phone_0 ${windowSize.width < 1200 ? 'order-0' : view === 1 ? 'order-0' : 'order-2'}`}>
                        <div className="m-portlet body_dashboard mb-2">
                            <div className="m-portlet__head d-md-flex justify-content-md-between flex-column flex-md-row high_chart_3">
                                <div className="m-portlet__head-tools select_date_month col-md-5 p-0 body_dashboard_portlet d-md-flex justify-content-md-start text-center" style={{ fontSize: '18px' }}>
                                    {`${language[indexLanguage].DashBoard.to_day} ${moment(new Date()).format('DD/MM/YYYY')}`}
                                    <Autocomplete
                                        className='select_option ml-2'
                                        options={listShift}
                                        getOptionLabel={(option) => option.title}
                                        style={{ width: 180 }}
                                        value={shift_3}
                                        onChange={(e, value) => {
                                            ReactGA.event({
                                                category: 'Dashboard',
                                                action: 'Xem bao cao hom nay',
                                                label: 'Chon ca'
                                            });
                                            setShift_3(value);
                                            getDataHighChart_3(value);
                                        }}
                                        disableClearable
                                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                                    />
                                </div>
                                <div className='d-md-flex align-items-md-center high_chart_3_param' style={{ fontSize: '18px', whiteSpace: 'pre-line' }}>
                                    <span className='mx-2 text-center'>{`${language[indexLanguage].DashBoard.present}: ${total_3.staff_on_time}`}    </span>
                                    <span className='mx-2 text-center'>{`${language[indexLanguage].DashBoard.off}: ${total_3.staff_day_off}`}      </span>
                                    <span className='mx-2 text-center'>{`${language[indexLanguage].DashBoard.business}: ${total_3.staff_business}`}     </span>
                                    <span className='mx-2 text-center'>{`${language[indexLanguage].DashBoard.to_late}: ${total_3.staff_to_late}`}</span>
                                </div>
                            </div>
                            <div className="m-portlet__body dashboard_spinner">
                                <LoadingOverlay
                                    active={isActive_3}
                                    spinner
                                    styles={{
                                        overlay: (base) => ({
                                            ...base,
                                            background: 'rgba(202, 202, 202, 0)',
                                        })
                                    }}
                                >
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={chartOptions_3}
                                    />
                                </LoadingOverlay>
                            </div>
                        </div>
                    </div>
                    <div className={`${col} col-md-12 pr-2 pl-2 pd_phone_0 order-3`}>
                        <div className="m-portlet body_dashboard mb-2">
                            <div className="m-portlet__head d-md-flex justify-content-md-start">
                                <div className="m-portlet__head-tools select_date_month col-md-10 p-0 body_dashboard_portlet d-md-flex justify-content-md-start">
                                    <ReactDatePicker
                                        className="form-control m-input m_datetimepicker_6"
                                        selected={date_4}
                                        onChange={date => {
                                            ReactGA.event({
                                                category: 'Dashboard',
                                                action: 'Xem nang suat lam viec',
                                                label: 'Chon nam'
                                            });
                                            setDate_4(date);
                                            getDataHighChart_4(0, date, 0)
                                        }}
                                        showYearPicker
                                        dateFormat="yyyy"
                                        customInput={<ExampleCustomInput />}
                                    />
                                    {/* <Autocomplete
                                        className='select_option ml-2'
                                        options={listDepartment}
                                        getOptionLabel={(option) => option.title}
                                        style={{ width: 180 }}
                                        value={department_4}
                                        renderOption={(props, option, state) => (
                                            <div {...props}>
                                                {formatTitleOption(props)}
                                            </div>
                                        )}
                                        classes={{ paper: classes.paper }}
                                        onChange={(e, value) => {
                                            ReactGA.event({
                                                category: 'Dashboard',
                                                action: 'Xem nang suat lam viec',
                                                label: 'Chon phong ban'
                                            });
                                            setDepartment_4(value);
                                            getDataHighChart_4(value.id, 0, 0);
                                        }}
                                        disableClearable
                                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                                    /> */}
                                    <Button
                                        onClick={() => {
                                        setSelectDepartment2(prev => !prev)
                                        }}
                                        className="btn btn-secondary btn-block m-btn m-btn--icon"
                                    >
                                        <span style={{ color: "#000000" }}>
                                        {department_4?.value == -1 ? "Chọn phòng ban" : department_4.title}
                                        </span>
                                    </Button>
                                    <Autocomplete
                                        className='select_option ml-2'
                                        options={listShift}
                                        getOptionLabel={(option) => option.title}
                                        style={{ width: 180 }}
                                        value={shift_4}
                                        onChange={(e, value) => {
                                            ReactGA.event({
                                                category: 'Dashboard',
                                                action: 'Xem nang suat lam viec',
                                                label: 'Chon ca '
                                            });
                                            setShift_4(value);
                                            getDataHighChart_4(0, 0, value);
                                        }}
                                        disableClearable
                                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                                    />
                                </div>
                            </div>
                            <div className="m-portlet__body dashboard_spinner">
                                <div className='row'>
                                    {
                                        selectDepartment2 && (
                                            <div className='col-xl-12 overflow-chart'>
                                                <TreeView
                                                    item={formatTreeViewData(listDepartment ? listDepartment : [])} 
                                                    getList={(data) => {
                                                        setSelectDepartment2(false)
                                                        ReactGA.event({
                                                            category: 'Dashboard',
                                                            action: 'Xem nang suat lam viec',
                                                            label: 'Chon phong ban'
                                                        });
                                                        setDepartment_4(data);
                                                        getDataHighChart_4(data.id, 0, 0);
                                                    }}
                                                />
                                            </div>
                                        )
                                    }
                                    <div className={selectDepartment2 ? 'hidden' : 'col-xl-12'}>
                                        
                                        <LoadingOverlay
                                            active={isActive_2}
                                            spinner
                                            styles={{
                                                overlay: (base) => ({
                                                    ...base,
                                                    background: 'rgba(202, 202, 202, 0)',
                                                })
                                            }}
                                        >
                                            <HighchartsReact
                                                highcharts={Highcharts}
                                                options={chartOptions_2}
                                            />
                                        </LoadingOverlay>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${col} col-md-12 pr-2 pl-2 pd_phone_0 order-4`}>
                        <div className="m-portlet body_dashboard mb-2">
                            <div className="m-portlet__head d-md-flex justify-content-md-start">

                            </div>
                            <div className="m-portlet__body dashboard_spinner">
                                <LoadingOverlay
                                    active={isActive_4}
                                    spinner
                                    styles={{
                                        overlay: (base) => ({
                                            ...base,
                                            background: 'rgba(202, 202, 202, 0)',
                                        })
                                    }}
                                >
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={chartOptions_4}
                                    />
                                </LoadingOverlay>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        </div>
        </>
    );
}

export default DashBoard;
