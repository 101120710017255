import Axios from 'axios';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import language from '../../../language/language';
import moment from 'moment';

const NameTag = 'daysoff_form';

ListNotification.propTypes = {
    data:PropTypes.array,
    scrollData: PropTypes.func,
    setUnread: PropTypes.func,
    handleClick: PropTypes.func,
    handleModal: PropTypes.func,
};

ListNotification.defaultProps = {
    data: null,
    scrollData: null,
    setUnread: null,
    handleClick: null,
    handleModal: null,
}

function ListNotification(props) {
    const {data , scrollData , setUnread , handleClick , handleModal} = props;
    const box_engine_cf = useSelector(state => state.boxai.box_engine_cf);
    const token = useSelector(state => state.isLogin.access_token);
    const indexLanguage = useSelector(state => state.changelanguage.indexLanguage);

    const dataRole = useSelector(state => state.role.role);
    const type = localStorage.getItem('type');
    const userData = JSON.parse(localStorage.getItem('data_user'));

    const height = window.innerHeight;
    const history = useHistory();
    const [reason,setReason] = useState(null);
    const [offsetY,setOffSetY] = useState(0);


    const handleReadeAll = async () => {
        try {
            const result = await Axios({
                method: 'POST',
                url: `${box_engine_cf.crrApiSocket}/api/staff/app/message/all_seen`,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                data: {
                    id_staff: userData.id_staff,
                    id_company: box_engine_cf.idCompany,
                }
            });
            if (result.data.status === 10000) {
                setUnread(0);
                scrollData(0);
            }
            
        } catch (error) {
            console.log(error);
        }
    }
    const getReasonOff = async () => {
        try {
          const data = await Axios({
            method: 'GET',
            url: `${box_engine_cf.crrApiSocket}/api/reason_off/${box_engine_cf.idCompany}/get_all`,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': token,
            }
          })
          if (data.data.status === 10000) {
            setReason(data.data.data);
          }
        } catch (error) {
          console.log(error);
        }
      }

    const handleReade = async (id_history,id_daysoff_form,status,content) => {
        try {
            if (status === 2) {
                const result = await Axios({
                    method: 'POST',
                    url: `${box_engine_cf.crrApiSocket}/api/staff/app/message/update_seen`,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': token,
                    },
                    data: {
                        ids_history: [id_history]
                    }
                })
                if (result.data.status === 10000) {
                    scrollData(0);
                }
            }

            const result = await Axios({
                method: 'GET',
                url: `${box_engine_cf.crrApiSocket}/api/daysoff_form/get_by_id/${id_daysoff_form}`,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
            });
            if (result.data.status === 10000) {
                //Redirect
                
                handleRedirect(JSON.parse(content).type_form);
                
                const data = result.data.data;
                const time_in = data.description ? JSON.parse(data.description).time_in : '';
                const time_out = data.description ? JSON.parse(data.description).time_out : '';

                if (Object.keys(data).length > 0) {
                    const dataModal = {
                        department: data.staff.department.name,
                        description: JSON.parse(data.description).content,
                        end_date: data.end_date,
                        id: data.id,
                        id_staff: data.staff.id_staff,
                        name: data.staff.name,
                        reason: (reason && reason.filter(filter => filter.id === data.id_reason)[0]) ? reason.filter(filter => filter.id === data.id_reason)[0].name : '',
                        start_date: data.start_date,
                        status: data.status,
                        time_in: time_in === 'Invalid date' ? '' : time_in,
                        time_out: time_out === 'Invalid date' ? '' : time_out,
                        supervisor: data.supervisor.name,
                        work_off: data.work_off,
                        work_place: data.description ? JSON.parse(data.description).work_place : '',
                        work_content: data.description ? JSON.parse(data.description).work_content : '',
                        admin: dataRole.indexOf(`${type}/${NameTag}:showall`) !== -1 ? true : false,
                    }
                    handleModal(true,dataModal,JSON.parse(content).type_form);
                }
            }
            handleClick();
        } catch (error) {
            console.log(error);
        }

    }
    const handleRedirect = (type_form) => {
        const leave_of_absence = 'leave-of-absence-letter';
        const sign_for_overtime = 'signup-for-overtime';
        const go_on_business = 'go-on-business';
        const go_late_leave_early = 'go-late-leave-early';
        const timekeeping_compensate = 'timekeeping-compensate';
        let pathname = '';

        switch (type_form) {
            case 1:
                 pathname = leave_of_absence;
                 break;
            case 2:
                 pathname = sign_for_overtime;
                 break;
            case 3:
                 pathname = go_on_business;
                 break;
            case 4:
                 pathname = go_late_leave_early;
                 break;
            case 5:
                 pathname = timekeeping_compensate;
                 break;            
            default:
                break;
        }
        if(dataRole.indexOf(`${type}/${NameTag}:view_all`) !== -1){
           return history.push(`/${pathname}/4`);
        }else if(dataRole.indexOf(`${type}/${NameTag}:view_by_manager`) !== -1){
           return history.push(`/${pathname}/3`);
        }else{
           return history.push(`/${pathname}/2`);
        }

    }

    const checkTypeForm = (data) => {
        try {
            const status = JSON.parse(data.content).type === 3 ? language[indexLanguage].Notify.approved : JSON.parse(data.content).type === 4 ? language[indexLanguage].Notify.rejected : '';

            switch (JSON.parse(data.content).type_form) {
                case 1:
                    return <span className='text-lowercase'>
                        {`
                            ${language[indexLanguage].Menubar.take_leave} ${language[indexLanguage].Notify.from_date} 
                            ${moment(JSON.parse(data.content).start_date).format('DD-MM-YYYY HH:mm')} ${language[indexLanguage].Notify.to_date} ${moment(JSON.parse(data.content).end_date).format('DD-MM-YYYY HH:mm')}
                            ${status}
                        `}
                    </span>;
                case 2:
                    return <span className='text-lowercase'>
                        {`
                            ${language[indexLanguage].Menubar.signup_for_overtime} ${language[indexLanguage].Notify.from_date} 
                            ${moment(JSON.parse(data.content).start_date).format('DD-MM-YYYY HH:mm')} ${language[indexLanguage].Notify.to_date} ${moment(JSON.parse(data.content).end_date).format('DD-MM-YYYY HH:mm')}
                            ${status}
                        `}
                    </span>;
                case 3:
                    return <span className='text-lowercase'>
                        {`
                            ${language[indexLanguage].Menubar.go_on_business} ${language[indexLanguage].Notify.from_date} 
                            ${moment(JSON.parse(data.content).start_date).format('DD-MM-YYYY HH:mm')} ${language[indexLanguage].Notify.to_date} ${moment(JSON.parse(data.content).end_date).format('DD-MM-YYYY HH:mm')}
                            ${status}
                        `}
                    </span>;
                case 4:
                    return <span className='text-lowercase'>
                        {`
                            ${language[indexLanguage].Menubar.go_late_leave_early} ${language[indexLanguage].Notify.from_date} 
                            ${moment(JSON.parse(data.content).start_date).format('DD-MM-YYYY')} ${language[indexLanguage].Notify.to_date} ${moment(JSON.parse(data.content).end_date).format('DD-MM-YYYY')}
                            ${status}
                        `}
                    </span>;
                case 5:
                    return <span className='text-lowercase'>
                        {`
                            ${language[indexLanguage].Menubar.timekeeping_compensate} ${language[indexLanguage].Notify.day}
                            ${moment(JSON.parse(data.content).start_date).format('DD-MM-YYYY')}
                            ${status}
                        `}
                    </span>;
                default:
                    break;
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        //Call API to fetch Data
        if (box_engine_cf) {
          getReasonOff();
        }
      }, [box_engine_cf]);
    return (
        <div className='notification-list'>
            {
                data.length > 0 ?
                    <>
                        <div className='notification-watched__all text-right'>
                            <a href="#" onClick={handleReadeAll}>{language[indexLanguage].Notification.readed_all}</a>
                        </div>
                        <InfiniteScroll
                            onScroll={(e) => setOffSetY(e.target.scrollTop)}
                            initialScrollY={offsetY}
                            dataLength={data.length}
                            hasMore={true}
                            next={() => scrollData(10)}
                            className='notification-scroll'
                            height={
                                data.length > 7 ? (height < 700 ? height - 170 : 600) : 0
                            }

                        >
                            <ul>
                                {data.map((data, key) => (
                                    <li key={key} style={data.status === 2 ? { backgroundColor: '#edf2ff' } : {}}>
                                        <a style={{cursor: 'pointer'}} onClick={() => handleReade(data.id_history, data.id_daysoff_form, data.status, data.content)}>
                                            <strong>{JSON.parse(data.content).name_staff} - {JSON.parse(data.content).name_department} </strong>
                                            {checkTypeForm(data)}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </InfiniteScroll>
                    </>
                    : 
                    <ul className='notification-watched__all p-4'>{language[indexLanguage].Notify.not_notify}</ul>
            }
            
        </div>
    );
}

export default ListNotification;