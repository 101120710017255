import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import swal from 'sweetalert';
import Store from '../../../../store';
import $ from 'jquery';
import language from '../../../../language/language';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux'

function compare(a, b) {
    if (a.countImg > b.countImg) {
        return 1
    } else {
        if (a.countImg < b.countImg) {
            return -1;
        }
        else {
            if (a.title > b.title) {
                return 1;
            }
            else if (a.title < b.title) {
                return -1;
            }
        }
    }
}

class ModalMoveImg extends Component {

    constructor(props) {
        super(props);
        this.state = {
            language: Store.getState().changelanguage.language,
            crrApi: Store.getState().boxai.box_engine_cf.crrApi,
            crrApiSocket: Store.getState().boxai.box_engine_cf.crrApiSocket,
            idCompany: Store.getState().boxai.box_engine_cf.idCompany,
            toStaff: { title: '' },
            valueDepartment: { title: '' },
            listStaff: [],
            listimage: [],
            listDepartment: [],
            dataCountStaff: [],
            Staff_img: {
                id: "",
                id_staff: "",
                name: "",
                abbreviated_name: "",
                birthday: "",
                gender: 1,
                id_department: '',
                id_level: '',
                is_active: 1,
                is_delete: 0,
                ids_shift: [],
            },
            dataStaff: [],
            dataObjStaff: [],
            crridx: 0,
            crrimages: [],
            listchecked: {
                valueImg: [],
                _id: [],
            },
        }
        Store.subscribe(() => {
            this.setState({
                language: Store.getState().changelanguage.language,
                crrApi: Store.getState().boxai.box_engine_cf.crrApi,
                crrApiSocket: Store.getState().boxai.box_engine_cf.crrApiSocket,
                idCompany: Store.getState().boxai.box_engine_cf.idCompany,
            });
        });
    }

    handleChangeStaff = (index, optionStaff) => {
        this.setState({
            toStaff: optionStaff,
        })
    }

    handleChangeDepartment = (index, value) => {
        this.setState({
            valueDepartment: value,
            toStaff: { title: '' }
        }, () => {
            this.dataGetObjStaff()
        })
    }

    dataGetObjStaff = () => {
        fetch(`${this.state.crrApiSocket}/api/staff/get_by_department`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            body: JSON.stringify({
                'id_company': this.state.idCompany,
                'id_department': this.state.valueDepartment.value
            })
        }).then((response) => {
            return response.json()
        }).then((data) => {
            var dataObjStaff = []
            if (data.status === 10000) {
                for (let index = 0; index < data.data.length; index++) {
                    dataObjStaff.push(data.data[index].object_id)
                }
                this.setState({
                    dataObjStaff: dataObjStaff,
                    dataStaff: data.data
                }, () => {
                    this.getCountStaff();
                });
            } else {
                this.setState({
                    loading_countStaffImg: false
                })
            }
        }).catch((error) => {
            if (error) {
                this.setState({
                    loading_countStaffImg: false
                })
            }
        })
    }

    getCountStaff = () => {
        if (this.state.crrApi === undefined) return;
        fetch(`${this.state.crrApi}/api/staff/getAllWithCountImage`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            body: JSON.stringify({
                'array_obj_staff': this.state.dataObjStaff
            })
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                var dataCount = []
                for (let index = 0; index < data.staffs.length; index++) {
                    if (data.staffs[index].idStaff != 'unknown') {
                        if (data.staffs[index].count_image > 0) {
                            dataCount.push(data.staffs[index])
                        }
                    }
                }

                var dataStaff = this.state.dataStaff;
                var dataIMG = [];
                if (dataStaff.length > 0) {
                    dataStaff.map((e, index) => {
                        dataIMG.push({ 'value': e.id, 'title': e.name + ' - ' + e.id_staff + ' ( 0 ) ', 'countImg': 0, 'is_active': e.is_active, 'info_Person': e })
                        for (let i = 0; i < dataCount.length; i++) {
                            if (dataCount[i]._id === e.object_id) {
                                dataIMG.splice(index, 1);
                                dataIMG.push({ 'value': e.id, 'title': e.name + ' - ' + e.id_staff + ' ( ' + dataCount[i].count_image + ' ) ', 'countImg': dataCount[i].count_image, 'is_active': e.is_active, 'info_Person': e })
                                return
                            }
                        }
                    })
                }

                var dataActive = []
                for (let index = 0; index < dataIMG.length; index++) {
                    if (dataIMG[index].is_active !== 0) {
                        dataActive.push(dataIMG[index])
                    }
                }

                const orderedNewOptions = dataActive.sort(compare);

                this.setState({
                    listStaff: orderedNewOptions,
                });
            } else {
                this.setState({
                    listStaff: [],
                })
            }
        }).catch((error) => {
            if (error) {
                this.setState({
                    listStaff: [],
                })
            }
        })
    }

    moveFace = async (_fromStaff, _toStaff, _imgFace, name) => {
        if (this.state.crrApi == undefined) return;
        var dataImgFace = [];
        if (this.state.listchecked._id.length == 0) {
            dataImgFace.push(_imgFace)
        } else {
            for (let index = 0; index < this.state.listchecked._id.length; index++) {
                dataImgFace.push(this.state.listchecked._id[index])
            }
        }
        let data = await fetch(`${this.state.crrApi}/api/staff/moveFace`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            body: JSON.stringify({
                fromStaff: _fromStaff,
                toStaff: _toStaff,
                imgFaces: dataImgFace
            })
        }).then((response) => {
            return response.json()
        });
        if (data.status === 10000) {
            swal(language[this.props.indexLanguage].Text_Notifi.success, language[this.props.indexLanguage].Text_Notifi.move_face_success, "success", {
                buttons: false,
                timer: 1500,
            });
            let listimage = this.state.listimage;

            for (let index = 0; index < dataImgFace.length; index++) {
                for (let i = 0; i < listimage.length; i++) {
                    if (listimage[i]._id === dataImgFace[index]) {
                        listimage.splice(i, 1);
                    }
                }
            }

            let listStaff = this.state.listStaff;
            let valueStaff = this.state.valueStaff;
            listStaff.forEach((value, index) => {
                //FromStaff
                if (value.info_Person.object_id == _fromStaff) {
                    valueStaff = {
                        countImg: listimage.length,
                        info_Person: value.info_Person,
                        is_active: value.is_active,
                        title: value.info_Person.name + ' - ' + value.info_Person.id_staff + ' ( ' + listimage.length + ' ) ',
                        value: value.value
                    }

                }
            });
            return this.props.onHide(valueStaff, listimage, true);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.show === true) {
            var indexlanguage = 0;
            if (this.state.language == "us") {
                indexlanguage = 0
            } else if (this.state.language == "vn") {
                indexlanguage = 1
            }
            this.setState({
                listDepartment: nextProps.department,
                indexlanguage: indexlanguage,
                listStaff: nextProps.datatostaff,
                valueDepartment: nextProps.valuedepartment,
                toStaff: { title: '' },
                dataCountStaff: nextProps.datacountstaff,
                Staff_img: nextProps.staffimage,
                listimage: nextProps.listimage,
                crrimages: nextProps.crrimages,
                crridx: nextProps.crridx,
                listchecked: nextProps.listchecked,
            });
        }
    }

    render() {
        return (
            <Modal
                {...this.props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form id="formMoveImage">
                        <div className="col-md-12 pr-0 pl-0 select_option">
                            <Autocomplete
                                options={this.state.listDepartment}
                                getOptionLabel={option => option.title}
                                defaultValue={{ 'value': -1, 'title': language[this.props.indexLanguage].placeholderSelect.all_department }}
                                value={this.state.valueDepartment}
                                onChange={this.handleChangeDepartment}
                                disableClearable
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder={language[this.props.indexLanguage].placeholderSelect.select_department}
                                        fullWidth
                                    />
                                )}
                            />
                        </div>
                        <div className="col-md-12 pr-0 pl-0 select_option pt-3">
                            <Autocomplete
                                options={this.state.listStaff}
                                getOptionLabel={option => option.title}
                                value={this.state.toStaff}
                                onChange={this.handleChangeStaff}
                                disableClearable
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder={language[this.props.indexLanguage].Trainning.text_select_placeholder}
                                        fullWidth
                                    />
                                )}
                            />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="accent" id="button_addLevel" className="m-loader--light m-loader--right"
                        onClick={(e) => {
                            e.preventDefault();
                            if (this.state.toStaff.title === '') {
                                swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].Text_Notifi.text_notifi_select_staff_move_item, "warning", {
                                    buttons: false,
                                    timer: 3000,
                                });
                                return;
                            }
                            this.moveFace(
                                this.state.Staff_img['object_id'],
                                this.state.toStaff.info_Person.object_id,
                                this.state.crrimages[this.state.crridx]['_id'],
                                this.state.crrimages[this.state.crridx]['imgFace']);
                        }}
                    >{language[this.props.indexLanguage].textButton.move_img}</Button>
                    <Button variant="secondary" onClick={this.props.onHide}>{language[this.props.indexLanguage].textButton.close}</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

ModalMoveImg.propTypes = {
    listchecked: PropTypes.object,
    staffimage: PropTypes.object,
    valuedepartment: PropTypes.object,
    listimage: PropTypes.array,
    crrimages: PropTypes.array,
    crridx: PropTypes.number,
    datacountstaff: PropTypes.array,
    department: PropTypes.array,
    datatostaff: PropTypes.array,
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool,
}
const mapStateToProps = state => {
    return {
        indexLanguage: state.changelanguage.indexLanguage,
    }
}


export default connect(mapStateToProps, null)(ModalMoveImg);