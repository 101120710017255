import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { HOST_CLOUD, ARRAY_ENGINES } from '../../../../config';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment'
import { Redirect } from 'react-router-dom';
import Store from '../../../../store';
import ModalShowImgEmotion from './ModalShowImgEmotion';
import language from '../../../../language/language';
import $ from 'jquery';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { PulseLoader } from 'react-spinners';
import { css } from '@emotion/core';
import { connect } from 'react-redux'
import Header from '../../../layouts/Header';
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

Highcharts.setOptions({
    colors: ['#008ffb', '#00e396', '#feb019', '#ff4560']
});

class Statistical extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogin: Store.getState().isLogin.isLogin,
            access_token: Store.getState().isLogin.access_token,
            box_engine_cf: Store.getState().boxai.box_engine_cf,
            crrApi2: Store.getState().boxai.box_engine_cf.crrApiSocket,
            language: Store.getState().changelanguage.language,
            dataCamera: Store.getState().boxai.box_engine_cf.dataCamera,
            dataEmotion: [],
            datatime: 0,
            optionsTrafic: null,
            //chartColumn
            startDateDays: new Date(moment().subtract(6, "days").format("YYYY-MM-DD")),
            endDateDays: new Date(),
            Date: new Date(),
            loading: true,
            modalShow: false,
            optionsHours: {
                chart: {
                    type: 'column',
                    options3d: {
                        enabled: true,
                        alpha: 0,
                        beta: 0,
                        depth: 0,
                        viewDistance: 0
                    },
                    height: 440,
                    backgroundColor: {
                        linearGradient: { x1: 1, y1: 1, x2: 1, y2: 1 },
                        stops: [
                            [0, '#ffff']
                        ]
                    },
                    style: {
                        fontFamily: '\'Unica One\', sans-serif'
                    },
                    plotBorderColor: '#e6e6e6'
                },

                title: {
                    text: ' ',
                    style: {
                        fontSize: '15px',
                        color: '#666666',
                        textTransform: 'uppercase',
                    }
                },

                xAxis: {
                    type: 'datetime',
                    labels: {
                        skew3d: true,
                        format: '{value: %H:%S}',
                        style: {
                            color: '#666666'
                        }
                    },
                    gridLineColor: '#e6e6e6',
                    lineColor: '#e6e6e6',
                    minorGridLineColor: '#505053',
                    tickColor: '#e6e6e6',
                    title: {
                        style: {
                            color: '#666666'
                        }
                    }
                },

                yAxis: {
                    allowDecimals: false,
                    min: 0,
                    title: {
                        text: '',
                        skew3d: true,
                        style: {
                            color: '#666666'
                        },
                    },
                    gridLineColor: '#e6e6e6',
                    labels: {
                        style: {
                            color: '#666666'
                        }
                    },
                    lineColor: '#e6e6e6',
                    minorGridLineColor: '#505053',
                    tickColor: '#e6e6e6',
                    tickWidth: 1,
                },

                tooltip: {
                    xDateFormat: '%Y-%m-%d %H:%S',
                    shared: true,
                    backgroundColor: 'rgba(0, 0, 0, 0.85)',
                    style: {
                        color: '#b1b1b5'
                    }
                },
                legend: {
                    itemStyle: {
                        color: '#666666'
                    },
                },
                plotOptions: {
                    column: {
                        // stacking: 'normal',
                        pointPadding: 0.05,
                        groupPadding: 0.12,
                        borderWidth: 0.2,
                    },

                },

                series: [{
                    name: 'Neutral',
                    data: [],
                }, {
                    name: 'Happy',
                    data: [],
                }, {
                    name: 'Sad',
                    data: [],
                }],
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 500
                        },
                        chartOptions: {
                            chart: {
                                height: 300
                            },
                            plotOptions: {
                                series: {
                                    pointPadding: 0.15,
                                    groupPadding: 0.25,
                                    borderWidth: 0.1,
                                }
                            },
                        }
                    }]
                }
            },
            optionsDays: {
                chart: {
                    type: 'column',
                    options3d: {
                        enabled: true,
                        alpha: 0,
                        beta: 0,
                        depth: 0,
                        viewDistance: 0
                    },
                    height: 440,
                    backgroundColor: {
                        linearGradient: { x1: 1, y1: 1, x2: 1, y2: 1 },
                        stops: [
                            [0, '#ffff']
                        ]
                    },
                    style: {
                        fontFamily: '\'Unica One\', sans-serif'
                    },
                    plotBorderColor: '#e6e6e6'
                },

                title: {
                    text: ' ',
                    style: {
                        fontSize: '15px',
                        color: '#666666',
                        textTransform: 'uppercase',
                    }
                },

                xAxis: {
                    type: 'datetime',
                    labels: {
                        skew3d: true,
                        format: '{value:%d/%m}',
                        style: {
                            color: '#666666'
                        }
                    },
                    gridLineColor: '#e6e6e6',
                    lineColor: '#e6e6e6',
                    minorGridLineColor: '#505053',
                    tickColor: '#e6e6e6',
                    title: {
                        style: {
                            color: '#666666'
                        }
                    }
                },

                yAxis: {
                    allowDecimals: false,
                    min: 0,
                    title: {
                        text: '',
                        skew3d: true,
                        style: {
                            color: '#666666'
                        },
                    },
                    gridLineColor: '#e6e6e6',
                    labels: {
                        style: {
                            color: '#666666'
                        }
                    },
                    lineColor: '#e6e6e6',
                    minorGridLineColor: '#505053',
                    tickColor: '#e6e6e6',
                    tickWidth: 1,
                },

                tooltip: {
                    xDateFormat: '%Y-%m-%d',
                    shared: true,
                    backgroundColor: 'rgba(0, 0, 0, 0.85)',
                    style: {
                        color: '#b1b1b5'
                    }
                },
                legend: {
                    itemStyle: {
                        color: '#666666'
                    },
                },
                plotOptions: {
                    column: {
                        // stacking: 'normal',
                        pointPadding: 0.05,
                        groupPadding: 0.25,
                        borderWidth: 0.2,
                    },

                },

                series: [{
                    name: 'Neutral',
                    data: [],
                }, {
                    name: 'Happy',
                    data: [],
                }, {
                    name: 'Sad',
                    data: [],
                }],
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 500
                        },
                        chartOptions: {
                            chart: {
                                height: 300
                            },
                            plotOptions: {
                                series: {
                                    pointPadding: 0.15,
                                    groupPadding: 0.25,
                                    borderWidth: 0.1,
                                }
                            },
                        }
                    }]
                }
            },
            api_countface: '',
            check: '',
            crrCamera: { title: '' },
        }
        Store.subscribe(() => {
            this.setState({
                isLogin: Store.getState().isLogin.isLogin,
            access_token: Store.getState().isLogin.access_token,
            box_engine_cf: Store.getState().boxai.box_engine_cf,
            crrApi2: Store.getState().boxai.box_engine_cf.crrApiSocket,
            language: Store.getState().changelanguage.language,
            dataCamera: Store.getState().boxai.box_engine_cf.dataCamera,
            dataEmotion: [],
            datatime: 0,
            optionsTrafic: null,
            //chartColumn
            startDateDays: new Date(moment().subtract(6, "days").format("YYYY-MM-DD")),
            endDateDays: new Date(),
            Date: new Date(),
            loading: true,
            modalShow: false,
            optionsHours: {
                chart: {
                    type: 'column',
                    options3d: {
                        enabled: true,
                        alpha: 0,
                        beta: 0,
                        depth: 0,
                        viewDistance: 0
                    },
                    height: 440,
                    backgroundColor: {
                        linearGradient: { x1: 1, y1: 1, x2: 1, y2: 1 },
                        stops: [
                            [0, '#ffff']
                        ]
                    },
                    style: {
                        fontFamily: '\'Unica One\', sans-serif'
                    },
                    plotBorderColor: '#e6e6e6'
                },

                title: {
                    text: ' ',
                    style: {
                        fontSize: '15px',
                        color: '#666666',
                        textTransform: 'uppercase',
                    }
                },

                xAxis: {
                    type: 'datetime',
                    labels: {
                        skew3d: true,
                        format: '{value: %H:%S}',
                        style: {
                            color: '#666666'
                        }
                    },
                    gridLineColor: '#e6e6e6',
                    lineColor: '#e6e6e6',
                    minorGridLineColor: '#505053',
                    tickColor: '#e6e6e6',
                    title: {
                        style: {
                            color: '#666666'
                        }
                    }
                },

                yAxis: {
                    allowDecimals: false,
                    min: 0,
                    title: {
                        text: '',
                        skew3d: true,
                        style: {
                            color: '#666666'
                        },
                    },
                    gridLineColor: '#e6e6e6',
                    labels: {
                        style: {
                            color: '#666666'
                        }
                    },
                    lineColor: '#e6e6e6',
                    minorGridLineColor: '#505053',
                    tickColor: '#e6e6e6',
                    tickWidth: 1,
                },

                tooltip: {
                    xDateFormat: '%Y-%m-%d %H:%S',
                    shared: true,
                    backgroundColor: 'rgba(0, 0, 0, 0.85)',
                    style: {
                        color: '#b1b1b5'
                    }
                },
                legend: {
                    itemStyle: {
                        color: '#666666'
                    },
                },
                plotOptions: {
                    column: {
                        // stacking: 'normal',
                        pointPadding: 0.05,
                        groupPadding: 0.12,
                        borderWidth: 0.2,
                    },

                },

                series: [{
                    name: 'Neutral',
                    data: [],
                }, {
                    name: 'Happy',
                    data: [],
                }, {
                    name: 'Sad',
                    data: [],
                }],
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 500
                        },
                        chartOptions: {
                            chart: {
                                height: 300
                            },
                            plotOptions: {
                                series: {
                                    pointPadding: 0.15,
                                    groupPadding: 0.25,
                                    borderWidth: 0.1,
                                }
                            },
                        }
                    }]
                }
            },
            optionsDays: {
                chart: {
                    type: 'column',
                    options3d: {
                        enabled: true,
                        alpha: 0,
                        beta: 0,
                        depth: 0,
                        viewDistance: 0
                    },
                    height: 440,
                    backgroundColor: {
                        linearGradient: { x1: 1, y1: 1, x2: 1, y2: 1 },
                        stops: [
                            [0, '#ffff']
                        ]
                    },
                    style: {
                        fontFamily: '\'Unica One\', sans-serif'
                    },
                    plotBorderColor: '#e6e6e6'
                },

                title: {
                    text: ' ',
                    style: {
                        fontSize: '15px',
                        color: '#666666',
                        textTransform: 'uppercase',
                    }
                },

                xAxis: {
                    type: 'datetime',
                    labels: {
                        skew3d: true,
                        format: '{value:%d/%m}',
                        style: {
                            color: '#666666'
                        }
                    },
                    gridLineColor: '#e6e6e6',
                    lineColor: '#e6e6e6',
                    minorGridLineColor: '#505053',
                    tickColor: '#e6e6e6',
                    title: {
                        style: {
                            color: '#666666'
                        }
                    }
                },

                yAxis: {
                    allowDecimals: false,
                    min: 0,
                    title: {
                        text: '',
                        skew3d: true,
                        style: {
                            color: '#666666'
                        },
                    },
                    gridLineColor: '#e6e6e6',
                    labels: {
                        style: {
                            color: '#666666'
                        }
                    },
                    lineColor: '#e6e6e6',
                    minorGridLineColor: '#505053',
                    tickColor: '#e6e6e6',
                    tickWidth: 1,
                },

                tooltip: {
                    xDateFormat: '%Y-%m-%d',
                    shared: true,
                    backgroundColor: 'rgba(0, 0, 0, 0.85)',
                    style: {
                        color: '#b1b1b5'
                    }
                },
                legend: {
                    itemStyle: {
                        color: '#666666'
                    },
                },
                plotOptions: {
                    column: {
                        // stacking: 'normal',
                        pointPadding: 0.05,
                        groupPadding: 0.25,
                        borderWidth: 0.2,
                    },

                },

                series: [{
                    name: 'Neutral',
                    data: [],
                }, {
                    name: 'Happy',
                    data: [],
                }, {
                    name: 'Sad',
                    data: [],
                }],
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 500
                        },
                        chartOptions: {
                            chart: {
                                height: 300
                            },
                            plotOptions: {
                                series: {
                                    pointPadding: 0.15,
                                    groupPadding: 0.25,
                                    borderWidth: 0.1,
                                }
                            },
                        }
                    }]
                }
            },
            api_countface: '',
            check: '',
            crrCamera: { title: '' },
            }, () => {
                var crrCamera = { title: '' };
                if (this.state.box_engine_cf.dataCamera !== undefined) {
                    if (this.state.box_engine_cf.dataCamera.length > 0) {
                        crrCamera = { title: this.state.dataCamera[0].id_box + '_' + this.state.dataCamera[0].id_box_engine, value: this.state.dataCamera[0].id_box_engine }
                    }
                }
                this.setState({
                    crrCamera: crrCamera
                }, () => {
                    this.getData();
                })
            });
        });
        this.state.api_countface = Store.getState().boxai.box_engine_cf.crrApi
        this.getData();
    }

    componentWillMount() {

        var crrCamera = { title: '' };
        if (this.state.box_engine_cf.dataCamera !== undefined) {
            if (this.state.box_engine_cf.dataCamera.length > 0) {
                crrCamera = { title: this.state.dataCamera[0].id_box + '_' + this.state.dataCamera[0].id_box_engine, value: this.state.dataCamera[0].id_box_engine }
            }
        }

        this.setState({
            crrCamera: crrCamera
        })
    }

    getData = () => {
        this.getEmotionbyDays();
        this.getEmotionbyHours();
    }

    getEmotionbyDays = () => {
        if (this.state.api_countface === "" || this.state.api_countface === undefined) return;
        fetch(`${this.state.api_countface}/api/emotion_perday`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            dataType: 'json',
            body: JSON.stringify({
                'idCamera': this.state.crrCamera === undefined ? '' : this.state.crrCamera.value + '',
                'start_date': moment(this.state.startDateDays).format("YYYY-MM-DD"),
                'end_date': moment(this.state.endDateDays).format("YYYY-MM-DD")
            })
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                let Neutral = [];
                let Happy = [];
                let Sad = [];
                for (let index = 0; index < data.data.length; index++) {
                    let dataday = data.data[index].day;
                    let today = new Date(dataday);
                    let dd = today.getDate();
                    let MM = today.getMonth() + 1;
                    let yyyy = today.getFullYear();
                    let hh = today.getHours();
                    let mm = today.getMinutes();

                    if (mm < 10) {
                        mm = '0' + mm
                    }

                    if (hh < 10) {
                        hh = '0' + hh
                    }

                    if (dd < 10) {
                        dd = '0' + dd
                    }

                    if (MM < 10) {
                        MM = '0' + MM
                    }

                    let data_neutral = [Date.UTC(yyyy, MM - 1, dd), data.data[index].neutral];
                    let data_happy = [Date.UTC(yyyy, MM - 1, dd), data.data[index].happy];
                    let data_sad = [Date.UTC(yyyy, MM - 1, dd), data.data[index].sad];
                    Neutral.push(data_neutral);
                    Happy.push(data_happy);
                    Sad.push(data_sad);
                }
                this.setState({
                    optionsDays: {
                        title: {
                            text: ' ',
                            style: {
                                fontSize: '15px'
                            }
                        },
                        plotOptions: {
                            series: {
                                cursor: 'pointer',
                                point: {
                                    events: {
                                        click: (e) => { this.emotionDayClicked(e, 'Day') }
                                    }
                                }
                            },
                        },

                        series: [{
                            name: language[this.props.indexLanguage].Statistical.emotion_neutral,
                            data: Neutral,
                        }, {
                            name: language[this.props.indexLanguage].Statistical.emotion_happy,
                            data: Happy,
                        }, {
                            name: language[this.props.indexLanguage].Statistical.emotion_sad,
                            data: Sad,
                        }]
                    }
                });
            }
        }).catch((error) => {
            if (error) {
                this.setState({
                    optionsDays: {
                        title: {
                            text: ' ',
                            style: {
                                fontSize: '15px'
                            }
                        },

                        series: [{
                            name: language[this.props.indexLanguage].Statistical.emotion_neutral,
                            data: [],
                        }, {
                            name: language[this.props.indexLanguage].Statistical.emotion_happy,
                            data: [],
                        }, {
                            name: language[this.props.indexLanguage].Statistical.emotion_sad,
                            data: [],
                        }]
                    }
                });
            }
        });
    }

    getEmotionbyHours = () => {
        if (this.state.api_countface === "" || this.state.api_countface === undefined) return;
        fetch(`${this.state.api_countface}/api/emotion_perhour`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            dataType: 'json',
            body: JSON.stringify({
                'idCamera': this.state.crrCamera === undefined ? '' : this.state.crrCamera.value + '',
                'date': moment(this.state.Date).format("YYYY-MM-DD")
            })
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                let Neutral = [];
                let Happy = [];
                let Sad = [];
                for (let index = 0; index < data.data.length; index++) {
                    let datahour = data.data[index].hour;
                    let today = new Date(datahour);
                    let dd = today.getDate();
                    let MM = today.getMonth() + 1;
                    let yyyy = today.getFullYear();
                    let hh = today.getHours();
                    let mm = today.getMinutes();

                    if (mm < 10) {
                        mm = '0' + mm
                    }

                    if (hh < 10) {
                        hh = '0' + hh
                    }

                    if (dd < 10) {
                        dd = '0' + dd
                    }

                    if (MM < 10) {
                        MM = '0' + MM
                    }

                    let data_neutral = [Date.UTC(yyyy, MM - 1, dd, hh, mm), data.data[index].neutral];
                    let data_happy = [Date.UTC(yyyy, MM - 1, dd, hh, mm), data.data[index].happy];
                    let data_sad = [Date.UTC(yyyy, MM - 1, dd, hh, mm), data.data[index].sad];
                    Neutral.push(data_neutral);
                    Happy.push(data_happy);
                    Sad.push(data_sad);
                }

                this.setState({
                    optionsHours: {
                        title: {
                            text: ' ',
                            style: {
                                fontSize: '15px'
                            }
                        },
                        plotOptions: {
                            series: {
                                cursor: 'pointer',
                                point: {
                                    events: {
                                        click: (e) => { this.emotionDayClicked(e, 'Hour') }
                                    }
                                }
                            }
                        },
                        series: [{
                            name: language[this.props.indexLanguage].Statistical.emotion_neutral,
                            data: Neutral
                        }, {
                            name: language[this.props.indexLanguage].Statistical.emotion_happy,
                            data: Happy
                        }, {
                            name: language[this.props.indexLanguage].Statistical.emotion_sad,
                            data: Sad
                        }]
                    }
                });
            }
        }).catch((error) => {
            if (error) {
                this.setState({
                    optionsHours: {
                        title: {
                            text: ' ',
                            style: {
                                fontSize: '15px'
                            }
                        },

                        series: [{
                            name: language[this.props.indexLanguage].Statistical.emotion_neutral,
                            data: [],
                        }, {
                            name: language[this.props.indexLanguage].Statistical.emotion_happy,
                            data: [],
                        }, {
                            name: language[this.props.indexLanguage].Statistical.emotion_sad,
                            data: [],
                        }]
                    }
                });
            }
        });
    }

    emotionDayClicked = (e, check) => {
        this.setState({
            check: check,
            datatime: e.point.options.x,
            modalShow: true
        });
    }

    modalClose = () => {
        this.setState({
            modalShow: false,
        });
        this.getData();
    }


    //chartColumn
    handleChangeStartDateDays = startDateDays => {
        this.setState({
            startDateDays: startDateDays
        })
    }
    handleChangeEndDateDays = endDateDays => {
        this.setState({
            endDateDays: endDateDays
        })
    }

    handleChangeDate = dataDate => {
        this.setState({
            Date: dataDate
        })
    }

    handleClickSearchDays = () => {
        this.getEmotionbyDays()
    }

    handleClickSearchHours = () => {
        this.getEmotionbyHours()
    }

    componentDidMount() {
        $('text.highcharts-credits').html('');
    }

    changeHandleCamera = (valueCamera, e) => {
        this.setState({ 
            crrCamera: e 
        }, () => {
            this.getData();
        })
    }

    render() {
        if (this.state.isLogin === false) {
            return (
                <Redirect to={'/login'} />
            )
        }

        var optionsCamera = [];
        if (this.state.dataCamera !== undefined) {
            for (let index = 0; index < this.state.dataCamera.length; index++) {
                if (ARRAY_ENGINES.includes(this.state.dataCamera[index].sub_engine_id + '')) {
                    optionsCamera.push({
                        title: this.state.dataCamera[index].id_box + '_' + this.state.dataCamera[index].id_box_engine, value: this.state.dataCamera[index].id_box_engine
                    })
                }
            }
        }

        const { optionsDays, optionsHours } = this.state;
        return (
            <>
            <Header />
            <div className={"m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body "}>
            <div className="m-grid__item m-grid__item--fluid m-wrapper">
                <div className="m-content mt-3-phone pd_phone_0 p-2">
                    <div className="row m-0 m_phone_0 m-portlet" style={{ boxShadow: 'none'}}>
                        <div className="m-portlet__head col-md-12">
                            <div className="select_option m-auto mt-3 col-xl-2 col-md-2 pl-2 pr-2">
                                <Autocomplete
                                    options={optionsCamera}
                                    getOptionLabel={option => option.title}
                                    value={this.state.crrCamera}
                                    onChange={this.changeHandleCamera}
                                    disableClearable
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            placeholder="Camera"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="col-xl-12 pl_phone_0 pr_phone_0 p-0">
                            <div className="m-portlet  m-portlet--full-height mb-3" style={{ boxShadow: 'none'}}>
                                <div className="m-portlet__head">
                                    <div className="m-portlet__head-caption">
                                        <div className="m-portlet__head-title">
                                            <h3 className="m-portlet__head-text">
                                                {language[this.props.indexLanguage].Statistical.emotion_by_hour}
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="m-portlet__head-tools">
                                        <div className="m-form__group row">
                                            <div className="col-md-8 mt-1 pr-0 dateRanger d-none d-md-block mr-2">
                                                <div className="input-daterange input-group">
                                                    <DatePicker
                                                        className="form-control m-input m_datetimepicker_6"
                                                        selected={this.state.Date}
                                                        onChange={this.handleChangeDate}
                                                        dateFormat="yyyy-MM-dd"
                                                        withPortal
                                                        placeholderText="End Date" />
                                                </div>
                                            </div>
                                            <div className="col-md-2 pl-0 d-none d-md-block">
                                                <button onClick={this.handleClickSearchHours} className="btn btn-accent m-btn m-btn--custom m-btn--icon m-btn--air" id="searchEmotionDays">{language[this.props.indexLanguage].textButton.search}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="m-portlet__body pl_phone_0 pr_phone_0">
                                    <div className="text-center">
                                        <div className="col-md-10 mt-1 col-8 m-auto dateRanger dateEmotion d-block d-md-none">
                                            <div className="input-daterange input-group">
                                                <DatePicker
                                                    className="form-control m-input"
                                                    selected={this.state.Date}
                                                    onChange={this.handleChangeDate}
                                                    dateFormat="yyyy-MM-dd"
                                                    withPortal
                                                    placeholderText="Date" />
                                            </div>
                                        </div>
                                        <div className="col-md-2 pl-0 d-block btn_pd_mobile d-md-none">
                                            <button onClick={this.handleClickSearchHours} className="btn btn-accent m-btn m-btn--custom m-btn--icon m-btn--air" id="searchEmotionDays">{language[this.props.indexLanguage].textButton.search}</button>
                                        </div>
                                    </div>
                                    <div className="col-xl-12 pl_phone_0 pr_phone_0 p-0">
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={optionsHours}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 pl_phone_0 pr_phone_0 p-0">
                            <div className="m-portlet  m-portlet--full-height " style={{ boxShadow: 'none'}}>
                                <div className="m-portlet__head">
                                    <div className="m-portlet__head-caption">
                                        <div className="m-portlet__head-title">
                                            <h3 className="m-portlet__head-text">
                                                {language[this.props.indexLanguage].Statistical.emotion_by_day}
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="m-portlet__head-tools">
                                        <div className="m-form__group row">
                                            <div className="col-md-10 mt-1 pr-0 dateRanger d-none d-md-block">
                                                <div className="input-daterange input-group">
                                                    <DatePicker
                                                        className="form-control m-input m_datetimepicker_6"
                                                        selected={this.state.startDateDays}
                                                        onChange={this.handleChangeStartDateDays}
                                                        dateFormat="yyyy-MM-dd"
                                                        withPortal
                                                        placeholderText="Start Date" />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text">
                                                            <i className="la la-calendar glyphicon-th" />
                                                        </span>
                                                    </div>
                                                    <DatePicker
                                                        className="form-control m-input m_datetimepicker_6"
                                                        selected={this.state.endDateDays}
                                                        onChange={this.handleChangeEndDateDays}
                                                        dateFormat="yyyy-MM-dd"
                                                        withPortal
                                                        minDate={this.state.startDateDays}
                                                        placeholderText="End Date" />
                                                </div>
                                            </div>
                                            <div className="col-md-2 pl-0 d-none d-md-block">
                                                <button onClick={this.handleClickSearchDays} className="btn btn-accent m-btn m-btn--custom m-btn--icon m-btn--air" id="searchEmotionDays">{language[this.props.indexLanguage].textButton.search}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="m-portlet__body pl_phone_0 pr_phone_0">
                                    <div className="text-center">
                                        <div className="col-md-10 mt-1 pr-0 dateRanger d-block d-md-none">
                                            <div className="input-daterange input-group">
                                                <DatePicker
                                                    className="form-control m-input m_datetimepicker_6"
                                                    selected={this.state.startDateDays}
                                                    onChange={this.handleChangeStartDateDays}
                                                    dateFormat="yyyy-MM-dd"
                                                    withPortal
                                                    disabledKeyboardNavigation
                                                    placeholderText="Start Date" />
                                                <div className="input-group-append">
                                                    <span className="input-group-text">
                                                        <i className="la la-calendar glyphicon-th" />
                                                    </span>
                                                </div>
                                                <DatePicker
                                                    className="form-control m-input m_datetimepicker_6"
                                                    selected={this.state.endDateDays}
                                                    onChange={this.handleChangeEndDateDays}
                                                    dateFormat="yyyy-MM-dd"
                                                    withPortal
                                                    disabledKeyboardNavigation
                                                    minDate={this.state.startDateDays}
                                                    placeholderText="End Date" />
                                            </div>
                                        </div>
                                        <div className="col-md-2 pl-0 d-block btn_pd_mobile d-md-none">
                                            <button onClick={this.handleClickSearchDays} className="btn btn-accent m-btn m-btn--custom m-btn--icon m-btn--air" id="searchEmotionDays">{language[this.props.indexLanguage].textButton.search}</button>
                                        </div>
                                    </div>
                                    <div className="col-xl-12 pl_phone_0 pr_phone_0 p-0">
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={optionsDays}
                                        />
                                        <ModalShowImgEmotion
                                            idcamera={this.state.crrCamera === undefined ? '' : this.state.crrCamera.value + ''}
                                            datatime={this.state.datatime}
                                            check={this.state.check}
                                            apicountface={this.state.api_countface}
                                            show={this.state.modalShow}
                                            onHide={this.modalClose}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </>
        );
    }
}
const mapStateToProps = state => {
    return {
        indexLanguage: state.changelanguage.indexLanguage,
    }
}
export default connect(mapStateToProps, null)(Statistical);
