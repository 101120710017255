import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Store from '../../../../store';
import swal from 'sweetalert';
import swals from '@sweetalert/with-react';
import ModalStaff from "./ModalStaff";
import ModalProgress from "./ModalProgress";
import { css } from '@emotion/core';
import { PulseLoader } from 'react-spinners';
import Switch from "react-switch";
import Pagination from "react-js-pagination";
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import Moment from 'moment';
import $ from 'jquery';
import Files from 'react-files';
import FadeLoader from 'react-spinners/FadeLoader';
import LoadingOverlay from 'react-loading-overlay';
import Select from "react-select";
import XLSX from 'xlsx';
import { NavLink } from 'react-router-dom';
import { make_cols } from './MakeColumms';
import ReactTooltip from 'react-tooltip';
import language from '../../../../language/language';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ReactGA from 'react-ga';
import { getTemplate } from '../../../../config';
import { connect } from 'react-redux'
import { CSVLink } from "react-csv";
import { formatTitleOption, formatTreeViewData, formatUsernameFromEmail } from '../../CommonFunction';
import { withStyles } from "@material-ui/core/styles";
import TreeView from '../../TreeView';
import { Button } from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import moment from 'moment';
import Header from '../../../layouts/Header';

const useStyles = theme => ({
    paper: {
        whiteSpace: "nowrap"
    }
});

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;
const nameTab = 'staff';

function sortData(a, b) {
    if (a.label > b.label) {
        return 1
    } else {
        if (a.label < b.label) {
            return -1;
        }
        else {
            return 0
        }
    }
}

class Staff extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogin: Store.getState().isLogin.isLogin,
            access_token: Store.getState().isLogin.access_token,
            crrApi2: Store.getState().boxai.box_engine_cf.crrApiSocket,
            idCompany: Store.getState().boxai.box_engine_cf.idCompany,
            language: Store.getState().changelanguage.language,
            box_engine_cf: Store.getState().boxai.box_engine_cf,
            dataRole: Store.getState().role.role,
            modalStaff: false,
            isView: false,
            // isChecked: false,
            activeUploadExcel: false,
            listStaff: [],
            listDepartment: [],
            optionDepartment: [],
            optionsLevel: [],
            optionsShift: [],
            optionsRole: [],
            listLevel: [],
            listShift: [],
            activePage: 1,
            offset: 0,
            crrStaff: {
                box_engine_id: "",
                name: "",
                description: "",
                is_active: 0
            },
            checkDate: {
                validStartDate: true,
                validOfficialDate: true,
            },
            tempValue: '',
            searchStaff: '',
            data_json_export: [],
            loading: true,
            location: '',
            countDataStaff: 0,
            id_staff_auto_increase: 1,
            type: '',
            ExportContent: '',
            valueDepartment: { value: -1, title: "" },
            valueLevel: { value: -1, title: "" },
            valueShift: { value: -1, title: "" },
            valueStatus: { value: 1, title: "" },
            valueDayStatus: { value: 1, title: "" },
            valueRole: { value: -1, title: "" },
            dateInActive: new Date(),
            isExcel: true,
            importProgress: 0,
            modalProgress: false,
            selectDepartment: false
        };
        Store.subscribe(() => {
            this.setState({
                isLogin: Store.getState().isLogin.isLogin,
                access_token: Store.getState().isLogin.access_token,
                crrApi2: Store.getState().boxai.box_engine_cf.crrApiSocket,
                idCompany: Store.getState().boxai.box_engine_cf.idCompany,
                language: Store.getState().changelanguage.language,
                box_engine_cf: Store.getState().boxai.box_engine_cf,
                dataRole: Store.getState().role.role,
                modalStaff: false,
                isView: false,
                // isChecked: false,
                activeUploadExcel: false,
                listStaff: [],
                listDepartment: [],
                optionDepartment: [],
                optionsLevel: [],
                optionsShift: [],
                optionsRole: [],
                listLevel: [],
                listShift: [],
                activePage: 1,
                offset: 0,
                crrStaff: {
                    box_engine_id: "",
                    name: "",
                    description: "",
                    is_active: 0
                },
                tempValue: '',
                searchStaff: '',
                data_json_export: [],
                loading: true,
                optionStatus: [
                    { value: 1, title: language[this.props.indexLanguage].placeholderSelect.status_active },
                    { value: 0, title: language[this.props.indexLanguage].placeholderSelect.status_inactive },
                    { value: -1, title: language[this.props.indexLanguage].placeholderSelect.all_status },
                ],
                optionDayStatus: [
                    { value: 1, title: "Ngày làm việc chính thức" },
                    { value: 0, title: "Ngày nghỉ việc" },
                    { value: -1, title: "Tất cả" },
                ],
                valueStatus: { value: 1, title: language[this.props.indexLanguage].placeholderSelect.status_active },
                valueDayStatus: { value: -1, title: "Tất cả" },
                valueFromDate: "",
                valueToDate: "",
                optionDepartment: [
                    { value: -1, title: language[this.props.indexLanguage].placeholderSelect.all_department }
                ],
                optionsLevel: [
                    { value: -1, title: language[this.props.indexLanguage].placeholderSelect.all_level }
                ],
                optionsShift: [
                    { value: -1, title: language[this.props.indexLanguage].placeholderSelect.all_shift }
                ],
                optionsRole: [
                    { value: -1, title: language[this.props.indexLanguage].placeholderSelect.all_role }
                ],
                valueDepartment: { value: -1, title: language[this.props.indexLanguage].placeholderSelect.all_department },
                valueLevel: { value: -1, title: language[this.props.indexLanguage].placeholderSelect.all_level },
                valueRole: { value: -1, title: language[this.props.indexLanguage].placeholderSelect.all_role },
                valueShift: { value: -1, title: language[this.props.indexLanguage].placeholderSelect.all_shift },
                isExcel: true,
                headerCSV: [],
                selectDepartment: false
            }, () => {
                $('#inputSearch').val('')
                this.getData()
            });
        });
        this.itemsPerPage = 10;
        this.getData()
    }

    componentWillMount() {
        var type = localStorage.getItem("type")
        this.setState({
            type: type,
            optionStatus: [
                { value: 1, title: language[this.props.indexLanguage].placeholderSelect.status_active },
                { value: 0, title: language[this.props.indexLanguage].placeholderSelect.status_inactive },
                { value: -1, title: language[this.props.indexLanguage].placeholderSelect.all_status },
            ],
            optionDayStatus: [
                { value: -1, title: "Tất cả" },
                { value: 1, title: "Ngày làm việc chính thức" },
                { value: 0, title: "Ngày nghỉ việc" },
            ],
            valueStatus: { value: 1, title: language[this.props.indexLanguage].placeholderSelect.status_active },
            valueDayStatus: { value: -1, title: "Tất cả" },
            valueFromDate: "",
            valueToDate: "",
            optionDepartment: [
                { value: -1, title: language[this.props.indexLanguage].placeholderSelect.all_department }
            ],
            optionsLevel: [
                { value: -1, title: language[this.props.indexLanguage].placeholderSelect.all_level }
            ],
            optionsShift: [
                { value: -1, title: language[this.props.indexLanguage].placeholderSelect.all_shift }
            ],
            optionsRole: [
                { value: -1, title: language[this.props.indexLanguage].placeholderSelect.all_role }
            ],
            valueDepartment: { value: -1, title: language[this.props.indexLanguage].placeholderSelect.all_department },
            valueLevel: { value: -1, title: language[this.props.indexLanguage].placeholderSelect.all_level },
            valueShift: { value: -1, title: language[this.props.indexLanguage].placeholderSelect.all_shift },
            valueRole: { value: -1, title: language[this.props.indexLanguage].placeholderSelect.all_role },
        })
    }

    getData = () => {
        this.getDepartment()
        this.getLevel()
        this.getRole()
        this.getShift()
        this.configGetByIDCompany()
    }

    configGetByIDCompany = () => {
        if (this.state.crrApi2 === undefined) return;
        fetch(`${this.state.crrApi2}/api/config/get_info/${this.state.box_engine_cf.idCompany}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                var ExportContent = ""
                if (data.data.data.id_staff_auto_increase === 1) {
                    ExportContent = language[this.props.indexLanguage].textTable.id_auto
                    this.setState({
                        ExportContent: ExportContent,
                        id_staff_auto_increase: data.data.data.id_staff_auto_increase
                    })
                } else {
                    this.setState({
                        ExportContent: ExportContent,
                        id_staff_auto_increase: data.data.data.id_staff_auto_increase
                    })
                }
            }
        })
    }

    getDepartment = async () => {
        this.setState({
            optionDepartment: [
                { value: -1, title: language[this.props.indexLanguage].placeholderSelect.all_department }
            ]
        }, () => {
        if (this.state.crrApi2 === undefined) return;
        var type = localStorage.getItem("type")

        if (this.state.dataRole.indexOf(type + '/staff:view_all') !== -1) {
            fetch(`${this.state.crrApi2}/api/department/${this.state.box_engine_cf.idCompany}/get_all`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': Store.getState().isLogin.access_token
                },
            }).then((response) => {
                return response.json()
            }).then((data) => {
                if (data.status === 10000) {
                    var optionDepartment = [
                        { value: -1, title: language[this.props.indexLanguage].placeholderSelect.all_department }
                    ]
                    for (let index = 0; index < data.data.length; index++) {
                        optionDepartment.push({ 'value': data.data[index].id, 'title': data.data[index].name, 'code': data.data[index].code })
                    }
                    var dataOptionSort = optionDepartment.sort(sortData);
                    this.setState({
                        listDepartment: [...data.data],
                        optionDepartment: dataOptionSort,
                    }, () => {
                        this.getStaff()
                    });
                } else {
                    this.setState({
                        loading: false
                    })
                }
            })
        } else {
            const id_staff = localStorage.getItem('id_user');
            fetch(`${this.state.crrApi2}/api/department/get_by_supervisor/${parseInt(id_staff)}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': Store.getState().isLogin.access_token
                },
            }).then((response) => {
                return response.json()
            }).then((data) => {
                if (data.status === 10000) {
                    var optionDepartment = data.data.length > 1 ? this.state.optionDepartment : []
                    for (let index = 0; index < data.data.length; index++) {
                        optionDepartment.push({ 'value': data.data[index].id, 'title': data.data[index].name, 'code': data.data[index].code })
                    }
                    var dataOptionSort = optionDepartment.sort(sortData);
                    this.setState({
                        listDepartment: [...data.data],
                        optionDepartment: dataOptionSort,
                        valueDepartment: dataOptionSort[0],
                    }, () => {
                        this.getStaff()
                    });
                } else {
                    this.setState({
                        loading: false
                    })
                }
            })
        }
        })

    }

    getStaff = () => {
        if (this.state.crrApi2 === undefined) {
            this.setState({
                loading: false
            })
        }
        if (this.state.crrApi2 === undefined) return;
        fetch(`${this.state.crrApi2}/api/staff/search`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            body: JSON.stringify({
                id_department: this.state.valueDepartment ? this.state.valueDepartment.value : '',
                id_company: this.state.idCompany,
                is_active: this.state.valueStatus.value,
                data_search: this.state.tempValue.trim(),
                item_per_page: this.itemsPerPage,
                index: this.state.activePage,
                id_level: this.state.valueLevel.value,
                id_shift: this.state.valueShift.value,
                id_role: this.state.valueRole.value,
                type_date: this.state.valueDayStatus.value,
                from_date: this.state.valueFromDate != "" ? moment(this.state.valueFromDate).format("YYYY-MM-DD") : "",
                to_date: this.state.valueToDate != "" ? moment(this.state.valueToDate).format("YYYY-MM-DD") : ""
            })
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                var dataStaff = []
                for (let index = 0; index < data.data.length; index++) {
                    if (data.data[index].birthday !== null) {
                        data.data[index].birthday = Moment(data.data[index].birthday).format("YYYY-MM-DD");
                    }

                    if (data.data[index].department !== null) {
                        data.data[index]['name_department'] = data.data[index].department.name
                    } else {
                        data.data[index]['name_department'] = ""
                    }

                    if (data.data[index].level !== null) {
                        data.data[index]['name_level'] = data.data[index].level.name
                    } else {
                        data.data[index]['name_level'] = ""
                    }
                    dataStaff.push(data.data[index])
                }
                this.setState({
                    listStaff: dataStaff,
                    loading: false,
                    countDataStaff: data.count
                }, () => {
                    this.FilterStaff(this.state.activePage)
                });
            } else {
                this.setState({
                    listStaff: [],
                    loading: false,
                    countDataStaff: 0
                });
            }
        }).catch((error) => {
            if (error) {
                this.setState({
                    listStaff: [],
                    loading: false,
                    countDataStaff: 0
                });
            }
        })
    }

    getLevel = () => {
        if (this.state.crrApi2 === undefined) return;
        fetch(`${this.state.crrApi2}/api/level/${this.state.box_engine_cf.idCompany}/get_all`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                var optionsLevel = this.state.optionsLevel
                for (let index = 0; index < data.data.length; index++) {
                    optionsLevel.push({ "value": data.data[index].id, "title": data.data[index].name })
                }
                var dataLevelSoft = optionsLevel.sort(sortData)
                this.setState({
                    optionsLevel: dataLevelSoft,
                    listLevel: [...data.data],
                });
            }
        })
    }

    getRole = () => {
        if (this.state.crrApi2 === undefined) return;
        fetch(`${this.state.crrApi2}/api/role/${this.state.box_engine_cf.idCompany}/get_all`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                var optionsRole = this.state.optionsRole
                for (let index = 0; index < data.data.length; index++) {
                    optionsRole.push({ "value": data.data[index].id, "title": data.data[index].name })
                }
                var dataRoleSoft = optionsRole.sort(sortData)
                this.setState({
                    optionsRole: dataRoleSoft,
                    // listRole: [...data.data],
                });
            }
        })
    }

    getShift = () => {
        if (this.state.crrApi2 === undefined) return;
        fetch(`${this.state.crrApi2}/api/shift_work/${this.state.idCompany}/get_by_active/1`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                var optionsShift = this.state.optionsShift
                for (let index = 0; index < data.data.length; index++) {
                    optionsShift.push({ "value": data.data[index].id, "title": data.data[index].name })
                }
                var optionShiftSoft = optionsShift.sort(sortData)
                this.setState({
                    optionsShift: optionShiftSoft,
                    listShift: [...data.data],
                });
            }
        })
    }


    FilterStaff = (activePage) => {
        const offset = (activePage - 1) * this.itemsPerPage;
        this.setState({
            offset: offset
        })
    }

    handlePageChange = (pageNumber) => {
        this.setState({
            ...this.state,
            activePage: pageNumber
        }, () => {
            this.getStaff()
        })
        // this.FilterStaff(pageNumber);

    }

    DeleteStaff = async (Staff) => {
        if (this.state.crrApi2 === undefined) return;
        let data = await fetch(`${this.state.crrApi2}/api/staff/delete`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token,
            },
            body: JSON.stringify({
                'id': Staff.id
            })
        }).then((response) => {
            return response.json()
        });
        if (data.status === 10000) {
            swal(language[this.props.indexLanguage].Text_Notifi.success, language[this.props.indexLanguage].Text_Notifi.notifi_delete_success, "success", {
                buttons: false,
                timer: 1500,
            });
            this.getStaff()
        }
        else {
            swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].Text_Notifi.notifi_delete_false, "error");
        }
    }

    modalClose = () => {
        this.setState({
            modalStaff: false,
            isEdit: false,
            isView: false,
        });
        this.getStaff()
    }

    btnSearch = () => {
        this.setState({
            activePage: 1,
            listStaff: [],
            data_json_export: [],
            loading: true,
        }, () => {
            this.getStaff()
        })
    }

    reset = (e) => {
        // $('#inputSearch').val('');
        // this.setState({
        //     tempValue: '',
        //     activePage: 1,
        //     listStaff: [],
        //     data_json_export: [],
        //     loading: true,
        //     valueDepartment: { value: -1, title: language[this.props.indexLanguage].placeholderSelect.all_department },
        //     valueStatus: { value: 1, title: language[this.props.indexLanguage].placeholderSelect.status_active },
        // }, () => {
        //     this.getStaff();
        // });
        this.setState({
            activePage: 1,
            listStaff: [],
            data_json_export: [],
            loading: true,
            tempValue: '',
            valueDepartment: this.state.optionDepartment[0],
            valueLevel: { value: -1, title: language[this.props.indexLanguage].placeholderSelect.all_level },
            valueRole: { value: -1, title: language[this.props.indexLanguage].placeholderSelect.all_role },
            valueShift: { value: -1, title: language[this.props.indexLanguage].placeholderSelect.all_shift },
            valueStatus: { value: 1, title: language[this.props.indexLanguage].placeholderSelect.status_active },
            valueDayStatus: { value: -1, title: "Tất cả" },
            valueFromDate: "",
            valueToDate: ""
        }, () => {
            this.getStaff();
        });
    }


    isChange = (event) => {
        if (event.key === 'Enter') {
            this.btnSearch()
        }
        this.setState({
            tempValue: event.target.value
        });
    }

    handleOnKeyDown = (event) => {
        if (event.key === 'Enter') {
            this.btnSearch()
        }
    }

    downloadFormImport = (type) => {
        ReactGA.event({
            category: 'Nhan vien',
            action: 'Xuat tap tin mau',
        });
        const nameTemplate = getTemplate();
        var url = window.location.href;
        var urlImport = url.replace(this.props.location.pathname, '/')
        if (this.state.id_staff_auto_increase === 1 && type === 1) {
            window.location.href = urlImport + nameTemplate + "/Form-Template-Import-ID-Auto-Create.xlsx"
        } else if (this.state.id_staff_auto_increase !== 1 && type === 1) {
            window.location.href = urlImport + nameTemplate + "/Form-Template-Import.xlsx"
        } else if (this.state.id_staff_auto_increase === 1 && type === 2) {
            window.location.href = urlImport + nameTemplate + "/Form-Template-Import-ID-Auto-Create.csv"
        } else {
            window.location.href = urlImport + nameTemplate + "/Form-Template-Import.csv"
        }
    }



    downloadTemplateResignation = (type) => {
        ReactGA.event({
            category: 'Nhan vien',
            action: 'Xuat tap tin nghi viec mau',
        });
        const nameTemplate = getTemplate();
        var url = window.location.href;
        var urlImport = url.replace(this.props.location.pathname, '/')
        if (type === 1) {
            window.location.href = urlImport + nameTemplate + "/Form-Template-Del-Resignation.xlsx"
        } else {
            window.location.href = urlImport + nameTemplate + "/Form-Template-Del-Resignation.csv"
        }
    }

    _exporter;
    export = (type) => {
        if (this.state.crrApi2 == undefined) return;
        fetch(`${this.state.crrApi2}/api/staff/export_excel`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            body: JSON.stringify({
                id_department: this.state.valueDepartment.value,
                is_active: this.state.valueStatus.value,
                data_search: this.state.tempValue,
                id_company: this.state.idCompany
            })
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status == 10000) {
                ReactGA.event({
                    category: 'Nhan vien',
                    action: 'Xuat tap tin nhan vien',
                });
                var dataStaff = []
                for (let index = 0; index < data.data.length; index++) {
                    if (data.data[index].birthday !== null) {
                        data.data[index].birthday = Moment(data.data[index].birthday).format("DD-MM-YYYY");
                    }

                    if (data.data[index].department !== null) {
                        data.data[index]['name_department'] = data.data[index].department.name
                    } else {
                        data.data[index]['name_department'] = ""
                    }

                    if (data.data[index].level !== null) {
                        data.data[index]['name_level'] = data.data[index].level.name
                    } else {
                        data.data[index]['name_level'] = ""
                    }

                    if (data.data[index].phone_number !== null) {
                        data.data[index]['phone_number'] = data.data[index].phone_number
                    } else {
                        data.data[index]['phone_number'] = ""
                    }

                    if (data.data[index].shifts.length > 0) {
                        data.data[index]['name_shift'] = data.data[index].shifts + ''
                    }

                    if (data.data[index].start_official_work !== null) {
                        data.data[index].start_official_work = Moment(data.data[index].start_official_work).format("DD-MM-YYYY");
                    }

                    if (data.data[index].start_work !== null) {
                        data.data[index].start_work = Moment(data.data[index].start_work).format("DD-MM-YYYY");
                    }

                    if (data.data[index].end_official_work !== null) {
                        data.data[index].end_official_work = Moment(data.data[index].end_official_work).format("DD-MM-YYYY");
                    }

                    if (data.data[index].gender === 1) {
                        data.data[index].gender = "1";
                    } else if (data.data[index].gender === 2) {
                        data.data[index].gender = "2";
                    } else {
                        data.data[index].gender = "3";
                    }

                    dataStaff.push(data.data[index])
                }
                var headers = [
                    { label: language[this.props.indexLanguage].textTable.id_staff, key: "id_staff" },
                    { label: language[this.props.indexLanguage].textTable.name, key: "name" },
                    { label: language[this.props.indexLanguage].textTable.nick_name, key: "nick_name" },
                    { label: language[this.props.indexLanguage].textTable.birthday, key: "birthday" },
                    { label: language[this.props.indexLanguage].textTable.gender, key: "gender" },
                    { label: language[this.props.indexLanguage].textTable.phone, key: "phone" },
                    { label: language[this.props.indexLanguage].textTable.email, key: "email" },
                    { label: language[this.props.indexLanguage].textTable.department, key: "name_department" },
                    { label: language[this.props.indexLanguage].textTable.level, key: "name_level" },
                    { label: language[this.props.indexLanguage].textTable.shift, key: "shift" },
                    { label: language[this.props.indexLanguage].textTable.start_work, key: "start_work" },
                    { label: language[this.props.indexLanguage].textTable.start_official_work, key: "start_official_work" },
                    { label: language[this.props.indexLanguage].textTable.resignationDate, key: "end_official_work" },
                ];
                if (dataStaff.length > 0) {
                    this.setState({
                        data_json_export: dataStaff,
                        headerCSV: headers
                    }, () => {
                        if (type === 2) {
                            this.setState({
                                isExcel: false,
                            }, () => {
                                this.csvLink.link.click()
                            })
                        } else {
                            this._exporter.save();
                        }
                    })
                } else {
                    swal({
                        title: language[this.props.indexLanguage].Text_Notifi.error,
                        text: language[this.props.indexLanguage].Text_Notifi.notifi_export_failed,
                        icon: "error",
                        buttons: "OK",
                    });
                }
            } else {
                swal(language[this.props.indexLanguage].Text_Notifi.error, "", "error");
            }
        }).catch((error) => {
            swal(language[this.props.indexLanguage].Text_Notifi.error, error, "error");
        })
        this.setState({ isExcel: true })
    }

    make_api_call = async (data, session, id_staff, total_file, index) => {
        console.time(`[Import excel] request time ${index + 1}`)
        let date_sesson = session + "_" + id_staff
        let res;
        try {
            let result = await fetch(`${this.state.crrApi2}/api/staff/import_excel`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': Store.getState().isLogin.access_token,
                },
                body: JSON.stringify({
                    id_company: this.state.idCompany,
                    data,
                    session_id: date_sesson,
                    session_amount: total_file
                })
            }).then((response) => {
                return response.json()
            })
            console.timeEnd(`[Import excel] request time ${index + 1}`)
            return result
        } catch (error) {
            console.timeEnd(`[Import excel] request time ${index + 1}`)
            return
        }
    }

    chunkFileImport(a, num) {
        var res = [];
        var i = 0;
        while (i <= a.length - num) {
            var chunk = Array(num);

            for (var j = 0; j < num; j++)
                chunk[j] = a[i + j];

            res.push(chunk);
            i += num;
        }

        if (i < a.length)
            res.push(a.slice(i));

        return res;
    }

    importExcel = async (data) => {
        let dataChunk = this.chunkFileImport(data, 300)
        let result = [];
        let progressImport = 0
        const makeSession = Moment().format("YYYYMMDDHHmmss")
        const id_staff = localStorage.getItem('id_user')
        for (let i = 0; i < dataChunk.length; i++) {
            const dataI = dataChunk[i];
            let res = await this.make_api_call(dataI, makeSession, id_staff, dataChunk.length, i);
            progressImport = (i + 1) / dataChunk.length * 100
            result.push(res);
            this.setState({
                importProgress: progressImport,
                modalProgress: true
            })
        }
        this.setState({
            modalProgress: false
        })

        let listShiftsError = []
        let listDateError = []
        let listLackAbbName = []
        let listLackName = []
        let listLackGender = []
        let listLackId = []
        let listEmailError = []
        let listPhoneError = []

        let import_error = false
        let limit_staff_error = false
        let default_role = false
        let import_another = false
        for (let i = 0; i < result.length; i++) {
            const element = result[i];
            if (element.status === 10002) {
                import_error = true

                element.shifts_err.length > 0 && listShiftsError.push(element.shifts_err)
                element.date_err.length > 0 && listDateError.push(element.date_err)
                Object.keys(element.email_err).length > 0 && listEmailError.push(element.email_err)
                Object.keys(element.phone_err).length > 0 && listPhoneError.push(element.phone_err)
                element.lack_of_data.abbreviated_name.length > 0 && listLackAbbName.push(element.lack_of_data.abbreviated_name)
                element.lack_of_data.name.length > 0 && listLackName.push(element.lack_of_data.name)
                element.lack_of_data.id && element.lack_of_data.id.length > 0 && listLackId.push(element.lack_of_data.id)
                element.lack_of_data.gender.length > 0 && listLackGender.push(element.lack_of_data.gender)
            } else if (element.status === 10005) {
                limit_staff_error = true
            } else if (element.status === 10006) {
                import_another = true
            } else if (element.status === 10003) {
                default_role = true
            }
        }

        if (!import_error && !limit_staff_error && !default_role && !import_another) {
            ReactGA.event({
                category: 'Nhan vien',
                action: 'Nhap tap tin nhan vien thanh cong',
            });
            this.setState({
                activeUploadExcel: false,
            })
            swal(language[this.props.indexLanguage].Text_Notifi.success, language[this.props.indexLanguage].Text_Notifi.import_success, "success", {
                button: language[this.props.indexLanguage].textButton.close,
            });
            this.getData();
        } else if (import_another) {
            ReactGA.event({
                category: 'Nhan vien',
                action: 'Nhap tap tin nhan vien that bai',
            });
            this.setState({
                activeUploadExcel: false,
            })
            swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].Text_Notifi.another_import_progress, "warning");
        } else if (limit_staff_error) {
            console.log("dấda")
            ReactGA.event({
                category: 'Nhan vien',
                action: 'Nhap tap tin nhan vien that bai',
            });
            this.setState({
                activeUploadExcel: false,
            })
            swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].Text_Notifi.notifi_full_limit_staff, "warning");
        } else if (import_error) {
            if ((listShiftsError.length > 0 || listDateError.length > 0 || listEmailError.length > 0 || listPhoneError.length > 0
                || listLackAbbName.length > 0 || listLackGender.length > 0 || listLackId.length > 0 || listLackName.length > 0)) {
                swals({
                    title: language[this.props.indexLanguage].Text_Notifi.warning,
                    content: <div className={listEmailError.length > 2 || listPhoneError.length > 2 ? "swal-text-aleart" : ""}>
                        <div>{language[this.props.indexLanguage].Text_Notifi.import_error}</div>
                        {listShiftsError.length > 0 && <div>{`${language[this.props.indexLanguage].textTable.the_shift} [${listShiftsError.join(', ').toString()}] ${language[this.props.indexLanguage].textTable.does_not_exits}`}</div>}

                        {listDateError.length > 0 && <div>{language[this.props.indexLanguage].Text_Notifi.notifi_validate_start_work} : <b>{listDateError.join(', ')}</b></div>}

                        {Object.assign({}, ...listEmailError) &&
                            Object.entries(Object.assign({}, ...listEmailError)).map((e, i) => {
                                if (e[1]) {
                                    return <div>Email [ {e[0]} ] {`${language[this.props.indexLanguage].textTable.same_as_employee_email}`} [ {e[1]} ]</div>
                                } else {
                                    return <div>[ {e[0]} ] {`${language[this.props.indexLanguage].textTable.email_wrong_format}`}</div>
                                }
                            })
                        }

                        {Object.assign({}, ...listPhoneError) &&
                            Object.entries(Object.assign({}, ...listPhoneError)).map((e, i) => {
                                if (e[1]) {
                                    return <div>{`${language[this.props.indexLanguage].textTable.phone}`} [ {e[0]} ] {`${language[this.props.indexLanguage].textTable.same_as_employee_phone}`} [ {e[1]} ]</div>
                                } else {
                                    return <div>[ {e[0]} ] {`${language[this.props.indexLanguage].textTable.phone_wrong_format}`}</div>
                                }
                            })
                        }
                        {listLackAbbName.length > 0 && <div> {language[this.props.indexLanguage].Text_Notifi.lack_of_field_abb_name}</div>}
                        {listLackGender.length > 0 && <div> {language[this.props.indexLanguage].Text_Notifi.lack_of_field_gender}</div>}
                        {listLackId.length > 0 && <div> {language[this.props.indexLanguage].Text_Notifi.lack_of_field_id}</div>}
                        {listLackName.length > 0 && <div> {language[this.props.indexLanguage].Text_Notifi.lack_of_field_name}</div>}
                    </div>,
                    icon: "warning",
                });

                this.setState({
                    activeUploadExcel: false,
                })
            } else {
                ReactGA.event({
                    category: 'Nhan vien',
                    action: 'Nhap tap tin nhan vien that bai',
                });
                this.setState({
                    activeUploadExcel: false,
                })
                swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].Text_Notifi.import_error, "error");
            }
        } else {
            ReactGA.event({
                category: 'Nhan vien',
                action: 'Nhap tap tin nhan vien that bai',
            });
            this.setState({
                activeUploadExcel: false,
            })
            swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].Text_Notifi.import_warning, "error");
        }
        console.timeEnd("[Import excel] total time")
    }

    splitDate = (date) => {
        if (date.length > 0) {
            var splitDate = date.split("-");
            var a = splitDate[2];
            splitDate[2] = splitDate[0];
            splitDate[0] = a;
            return splitDate.join("-")
        }
    }

    onFilesChange = (files) => {
        console.time("[Import excel] validate time")
        console.time("[Import excel] total time")
        // const files = e.target.files;
        // if (files && files[0]) this.setState({ file: files[0] });
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;

        reader.onload = (e) => {
            /* Parse data */
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws, {
                raw: false,
            });
            const header = XLSX.utils.sheet_to_json(ws, { header: 1 })[0]
            //check file import khong giong file mau

            if (
                !header.some((title) => title === `${language[this.props.indexLanguage].textTable.name} (*)`) ||
                !header.some((title) => title === `${language[this.props.indexLanguage].textTable.nick_name} (*)`) ||
                !header.some((title) => title === `${language[this.props.indexLanguage].textTable.birthday} (*)`) ||
                !header.some((title) => title === `${language[this.props.indexLanguage].textTable.gender} (*)`) ||
                !header.some((title) => title === `${language[this.props.indexLanguage].textTable.department} (*)`) ||
                !header.some((title) => title === `${language[this.props.indexLanguage].textTable.phone} (*)`) ||
                !header.some((title) => title === `${language[this.props.indexLanguage].textTable.shift} (*)`) ||
                !header.some((title) => title === `${language[this.props.indexLanguage].textTable.email}`) ||
                !header.some((title) => title === `${language[this.props.indexLanguage].textTable.level}`) ||
                !header.some((title) => title === `${language[this.props.indexLanguage].textTable.start_work}`) ||
                !header.some((title) => title === `${language[this.props.indexLanguage].textTable.start_official_work}`)
            ) {
                swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].Text_Notifi.file_valid, "error");
                return;
            }
            /* Update state */

            this.setState({ data: data, cols: make_cols(ws['!ref']) }, () => {
                var dataExport = this.state.data;
                var dataExportConvert = [];
                var regexEmail = /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}/
                var regexPhone = /[0-9]{9,13}/
                let indexErrId = [];
                let indexErrShift = [];
                let indexErrName = [];
                let indexErrNickname = [];
                let indexErrPhone = [];
                let indexErrMail = [];
                let indexErrBirthday = [];
                let indexErrGender = [];
                let indexErrDepartment = [];
                let validPhone = [];
                let validEmail = [];
                let validStartWork = [];
                let validOfficailWork = [];
                let validBirthday = [];


                for (let index = 0; index < dataExport.length; index++) {

                    //Shift
                    var array = [];
                    if (dataExport[index][`${language[this.props.indexLanguage].textTable.shift} (*)`] !== undefined) {
                        array = dataExport[index][`${language[this.props.indexLanguage].textTable.shift} (*)`].split(',').map(map => map.trim());
                    } else {
                        indexErrShift.push(index + 2)
                    }

                    //name
                    var name = ""
                    if (dataExport[index][`${language[this.props.indexLanguage].textTable.name} (*)`] !== undefined) {
                        name = dataExport[index][`${language[this.props.indexLanguage].textTable.name} (*)`].toString().trim()
                    } else {
                        indexErrName.push(index + 2)
                    }

                    //name
                    var nick_name = ""
                    if (dataExport[index][`${language[this.props.indexLanguage].textTable.nick_name} (*)`] !== undefined) {
                        nick_name = dataExport[index][`${language[this.props.indexLanguage].textTable.nick_name} (*)`].toString().trim()
                    } else {
                        indexErrNickname.push(index + 2)
                    }

                    var Birthday = ""
                    if (dataExport[index][`${language[this.props.indexLanguage].textTable.birthday} (*)`] !== undefined) {
                        let birthday = dataExport[index][`${language[this.props.indexLanguage].textTable.birthday} (*)`].trim()
                        var checkDate = Moment(birthday, 'DD-MM-YYYY', true).isValid();
                        if (!checkDate) {
                            validBirthday.push(index + 2)
                        } else {
                            Birthday = this.splitDate(birthday);
                        }
                    } else {
                        indexErrBirthday.push(index + 2)
                    }


                    //id_staff
                    var id_staff = ""
                    if (this.state.id_staff_auto_increase === 0) {
                        if (dataExport[index]["ID"] !== undefined) {
                            id_staff = dataExport[index]["ID"].toString().trim()
                        } else {
                            indexErrId.push(index + 2)
                        }
                    } else {
                        id_staff = undefined
                    }


                    //gender
                    var gender = ""
                    if (dataExport[index][`${language[this.props.indexLanguage].textTable.gender} (*)`] !== undefined) {
                        gender = dataExport[index][`${language[this.props.indexLanguage].textTable.gender} (*)`].toString().trim()
                    } else {
                        indexErrGender.push(index + 2)
                    }

                    //sdt
                    var phone_number = ""
                    if (dataExport[index][`${language[this.props.indexLanguage].textTable.phone} (*)`] !== undefined) {
                        if (regexPhone.test(dataExport[index][`${language[this.props.indexLanguage].textTable.phone} (*)`])) {
                            phone_number = dataExport[index][`${language[this.props.indexLanguage].textTable.phone} (*)`].toString().trim()
                        } else {
                            validPhone.push(index + 2)
                        }
                    } else {
                        indexErrPhone.push(index + 2)
                    }
                    //email
                    var email = ""
                    if (dataExport[index][`${language[this.props.indexLanguage].textTable.email}`] !== undefined) {
                        if (regexEmail.test(dataExport[index][`${language[this.props.indexLanguage].textTable.email}`])) {
                            email = dataExport[index][`${language[this.props.indexLanguage].textTable.email}`].trim()
                        } else {
                            validEmail.push(index + 2)
                        }
                    }


                    //department
                    var department = ""
                    if (dataExport[index][`${language[this.props.indexLanguage].textTable.department} (*)`] !== undefined) {
                        department = dataExport[index][`${language[this.props.indexLanguage].textTable.department} (*)`].trim()
                    } else {
                        indexErrDepartment.push(index + 2)
                    }

                    //level
                    var level = ""
                    if (dataExport[index][`${language[this.props.indexLanguage].textTable.level}`] !== undefined) {
                        level = dataExport[index][`${language[this.props.indexLanguage].textTable.level}`].trim()
                    }

                    // start official work
                    var start_official_work = null
                    if (dataExport[index][`${language[this.props.indexLanguage].textTable.start_official_work}`] !== undefined) {
                        let startOfficialWork = dataExport[index][`${language[this.props.indexLanguage].textTable.start_official_work}`].trim()
                        var checkDate1 = Moment(startOfficialWork, 'DD-MM-YYYY', true).isValid();
                        if (!checkDate1) {
                            validOfficailWork.push(index + 2)
                        }
                        start_official_work = this.splitDate(startOfficialWork);
                    } else {
                        dataExport[index][`${language[this.props.indexLanguage].textTable.start_official_work}`] = null
                    }

                    // start work
                    var start_work = null
                    if (dataExport[index][`${language[this.props.indexLanguage].textTable.start_work}`] !== undefined) {
                        let startWork = dataExport[index][`${language[this.props.indexLanguage].textTable.start_work}`].trim()
                        var checkDate2 = Moment(startWork, 'DD-MM-YYYY', true).isValid();
                        if (!checkDate2) {
                            validStartWork.push(index + 2)
                        }
                        start_work = this.splitDate(startWork);
                    } else {
                        dataExport[index][`${language[this.props.indexLanguage].textTable.start_work}`] = null
                    }


                    dataExportConvert.push(
                        {
                            "id_staff": id_staff,
                            "name": name,
                            "abbreviated_name": nick_name,
                            "birthday": Birthday,
                            "gender": gender,
                            "phone_number": phone_number,
                            "email": email,
                            "department": department,
                            "level": level,
                            "shifts": array,
                            "start_work": start_work,
                            "start_official_work": start_official_work
                        }
                    )

                }

                //check thieu truong va check sai dinh dang cac truong email, phone, start work, start official work
                if (indexErrId.length > 0 || indexErrName.length > 0 || indexErrNickname.length > 0 || indexErrBirthday.length > 0 || indexErrGender.length > 0
                    || indexErrPhone.length > 0 || indexErrMail.length > 0 || indexErrDepartment.length > 0 || indexErrShift.length > 0
                    || validOfficailWork.length > 0 || validStartWork.length > 0 || validEmail.length > 0 || validPhone.length > 0) {
                    swals({
                        title: language[this.props.indexLanguage].Text_Notifi.warning,
                        content: <div>
                            <div>{language[this.props.indexLanguage].Text_Notifi.import_error}</div>
                            {(indexErrId.length > 0 &&
                                <div>{language[this.props.indexLanguage].textTable.id_blank} ({language[this.props.indexLanguage].textTable.line_error} {indexErrId.map((err, i) => {
                                    return <span>{(i ? ', ' : '') + err}</span>
                                })})
                                </div>
                            )}
                            {(indexErrName.length > 0 &&
                                <div>{language[this.props.indexLanguage].textTable.name_blank} ({language[this.props.indexLanguage].textTable.line_error} {indexErrName.map((err, i) => {
                                    return <span>{(i ? ', ' : '') + err}</span>
                                })})
                                </div>
                            )}
                            {(indexErrNickname.length > 0 &&
                                <div>{language[this.props.indexLanguage].textTable.nick_name_blank} ({language[this.props.indexLanguage].textTable.line_error} {indexErrNickname.map((err, i) => {
                                    return <span>{(i ? ', ' : '') + err}</span>
                                })})
                                </div>
                            )}
                            {(indexErrBirthday.length > 0 &&
                                <div>{language[this.props.indexLanguage].textTable.birthday_blank} ({language[this.props.indexLanguage].textTable.line_error} {indexErrBirthday.map((err, i) => {
                                    return <span>{(i ? ', ' : '') + err}</span>
                                })})
                                </div>
                            )}
                            {(validBirthday.length > 0 &&
                                <div>{language[this.props.indexLanguage].textTable.birthday_err_format} (DD-MM-YYYY) ({language[this.props.indexLanguage].textTable.line_error} {validBirthday.map((err, i) => {
                                    return <span>{(i ? ', ' : '') + err}</span>
                                })})
                                </div>
                            )}
                            {(indexErrGender.length > 0 &&
                                <div>{language[this.props.indexLanguage].textTable.gender_blank} ({language[this.props.indexLanguage].textTable.line_error} {indexErrGender.map((err, i) => {
                                    return <span>{(i ? ', ' : '') + err}</span>
                                })})
                                </div>
                            )}
                            {(indexErrPhone.length > 0 &&
                                <div>{language[this.props.indexLanguage].textTable.phone_blank} ({language[this.props.indexLanguage].textTable.line_error} {indexErrPhone.map((err, i) => {
                                    return <span>{(i ? ', ' : '') + err}</span>
                                })})
                                </div>
                            )}
                            {(validPhone.length > 0 &&
                                <div>{language[this.props.indexLanguage].textTable.phone_err_format} ({language[this.props.indexLanguage].textTable.line_error} {validPhone.map((err, i) => {
                                    return <span>{(i ? ', ' : '') + err}</span>
                                })})
                                </div>
                            )}
                            {/* {(indexErrMail.length > 0 &&
                                <div>{language[this.props.indexLanguage].textTable.email_blank} ({language[this.props.indexLanguage].textTable.line_error} {indexErrMail.map((err, i) => {
                                    return <span>{(i ? ', ' : '') + err}</span>
                                })})
                                </div>
                            )} */}
                            {(validEmail.length > 0 &&
                                <div>{language[this.props.indexLanguage].textTable.email_err_format} ({language[this.props.indexLanguage].textTable.line_error} {validEmail.map((err, i) => {
                                    return <span>{(i ? ', ' : '') + err}</span>
                                })})
                                </div>
                            )}
                            {(indexErrDepartment.length > 0 &&
                                <div>{language[this.props.indexLanguage].textTable.department_blank} ({language[this.props.indexLanguage].textTable.line_error} {indexErrDepartment.map((err, i) => {
                                    return <span>{(i ? ', ' : '') + err}</span>
                                })})
                                </div>
                            )}
                            {(indexErrShift.length > 0 &&
                                <div>{language[this.props.indexLanguage].textTable.shift_blank} ({language[this.props.indexLanguage].textTable.line_error} {indexErrShift.map((err, i) => {
                                    return <span>{(i ? ', ' : '') + err}</span>
                                })})
                                </div>
                            )}
                            {(validStartWork.length > 0 &&
                                <div>{language[this.props.indexLanguage].textTable.start_work_err_format} (DD-MM-YYYY) ({language[this.props.indexLanguage].textTable.line_error} {validStartWork.map((err, i) => {
                                    return <span>{(i ? ', ' : '') + err}</span>
                                })})
                                </div>
                            )}
                            {(validOfficailWork.length > 0 &&
                                <div>{language[this.props.indexLanguage].textTable.start_offi_work_err_format} (DD-MM-YYYY) ({language[this.props.indexLanguage].textTable.line_error} {validOfficailWork.map((err, i) => {
                                    return <span>{(i ? ', ' : '') + err}</span>
                                })})
                                </div>
                            )}
                        </div>,
                        icon: "warning",
                    });
                    return;
                }

                //check email & phone & ID trung nhau trong file import
                if (this.state.id_staff_auto_increase === 0) {
                    var filterID = dataExportConvert.filter(data => data.id_staff !== undefined);
                    var uniqueID = new Set(filterID.map((data) =>
                        data.id_staff
                    ));
                    var arr_err_id = filterID.filter((val, i, self) => self.findIndex(k => k.id_staff == val.id_staff) != i);
                }

                var filterEmail = dataExportConvert.filter(data => data.email !== "");
                var filterPhone = dataExportConvert.filter(data => data.phone_number !== undefined && data.phone_number !== null && data.phone_number !== "");
                var uniqueEmail = new Set(filterEmail.map((data) =>
                    data.email
                ));
                var uniquePhone = new Set(filterPhone.map((data) =>
                    data.phone_number
                ));
                var arr_err_phone = filterPhone.filter((val, i, self) => self.findIndex(k => k.phone_number == val.phone_number) != i);
                var arr_err_mail = filterEmail.filter((val, i, self) => self.findIndex(k => k.email == val.email) != i);


                if (uniquePhone.size < filterPhone.length || uniqueEmail.size < filterEmail.length || (filterID && uniqueID && uniqueID.size < filterID.length)) {
                    var nameErrPhone = []
                    var nameErrEmail = []
                    var nameErrID = []
                    for (let index = 0; index < arr_err_mail.length; index++) {
                        nameErrEmail.push(arr_err_mail[index].name)
                    }
                    for (let index = 0; index < arr_err_phone.length; index++) {
                        nameErrPhone.push(arr_err_phone[index].name)
                    }
                    if (filterID && uniqueID) {
                        for (let index = 0; index < arr_err_id.length; index++) {
                            nameErrID.push(arr_err_id[index].name)
                        }
                    }
                    swals({
                        title: language[this.props.indexLanguage].Text_Notifi.warning,
                        content: <div>
                            <div>{language[this.props.indexLanguage].Text_Notifi.import_error}</div>
                            {(uniqueEmail.size < filterEmail.length && <div>{nameErrEmail.join(', ')} {language[this.props.indexLanguage].textTable.duplicate_email}</div>)}
                            {(uniquePhone.size < filterPhone.length && <div>{nameErrPhone.join(', ')} {language[this.props.indexLanguage].textTable.duplicate_phone}</div>)}
                            {((filterID && uniqueID) && uniqueID.size < filterID.length && <div>{nameErrID.join(', ')} {language[this.props.indexLanguage].textTable.duplicate_id}</div>)}
                        </div>,
                        icon: "warning",
                    });
                    return;
                }


                this.setState({
                    activeUploadExcel: true,
                }, () => {
                    console.timeEnd("[Import excel] validate time")
                    this.importExcel(dataExportConvert)
                })
            });
        };

        if (rABS) {
            reader.readAsBinaryString(files[0]);
        } else {
            reader.readAsArrayBuffer(files[0]);
        };
    }

    onFilesChangeDA = (files, type) => {
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;

        reader.onload = (e) => {
            /* Parse data */
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws);
            /* Update state */
            this.setState({ data: data, cols: make_cols(ws['!ref']) }, () => {
                var dataExport = this.state.data;
                var listDataDA = []
                for (let index = 0; index < dataExport.length; index++) {
                    var id_staff = dataExport[index]["ID"];
                    var end_official_work = ""
                    if (dataExport[index][`${language[this.props.indexLanguage].textTable.resignationDate}`] !== undefined) {
                        end_official_work = Moment(this.splitDate(dataExport[index][`${language[this.props.indexLanguage].textTable.resignationDate}`])).format("YYYY-MM-DD 23:59:59")
                    }
                    if (type === 1) {
                        listDataDA.push({
                            id_staff,
                        })
                    } else {
                        listDataDA.push({
                            id_staff,
                            end_official_work
                        })
                    }
                }
                this.importDA(listDataDA, type)
            });
        };

        if (rABS) {
            reader.readAsBinaryString(files[0]);
        } else {
            reader.readAsArrayBuffer(files[0]);
        };
    }

    importDA = (listDataDA, type) => {
        if (this.state.crrApi2 == undefined) return;
        this.setState({
            activeUploadExcel: true,
        })
        fetch(`${this.state.crrApi2}/api/staff/import_inactive`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            body: JSON.stringify({
                id_company: this.state.idCompany,
                items: listDataDA,
                type: type,
            })
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status == 10000) {
                this.setState({
                    activeUploadExcel: false,
                    activePage: 1
                }, () => {
                    this.getStaff()
                });
                if (type === 1) {
                    ReactGA.event({
                        category: 'Nhan vien',
                        action: 'Nhap tap xoa',
                    });
                    swal(language[this.props.indexLanguage].Text_Notifi.success, language[this.props.indexLanguage].Text_Notifi.notifi_delete_success, "success", {
                        buttons: false,
                        timer: 1500,
                    });
                } else {
                    if (data.result_failed.length > 0) {
                        let listUserError = data.result_failed.map((e) => {
                            return e.name + ' - ' + e.id_staff
                        }
                        )
                        swal(language[this.props.indexLanguage].Text_Notifi.notifi_inactive_failed, (`${language[this.props.indexLanguage].textTable.list_user_error} : ${listUserError}`), "warning", {
                        });
                    } else {
                        ReactGA.event({
                            category: 'Nhan vien',
                            action: 'Nhap tap tin ngung hoat dong',
                        });
                        swal(language[this.props.indexLanguage].Text_Notifi.success, language[this.props.indexLanguage].Text_Notifi.inactive_success, "success", {
                            buttons: false,
                            timer: 1500,
                        });
                    }
                }
            } else if (data.status == 10002) {
                if (data.result_failed.length > 0) {
                    let listUserError = data.result_failed.map((e) => {
                        return e.name + ' - ' + e.id_staff
                    }
                    )
                    swal(language[this.props.indexLanguage].Text_Notifi.notifi_inactive_failed, (`${language[this.props.indexLanguage].textTable.list_user_error} : ${listUserError}`), "warning", {
                    });
                } else {
                    if (type === 1) {
                        swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].Text_Notifi.notifi_delete_false, "error");
                    } else {
                        swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].Text_Notifi.inactive_false, "error");
                    }
                }
                this.setState({
                    activeUploadExcel: false
                });
            } else {
                this.setState({
                    activeUploadExcel: false
                });
                if (type === 1) {
                    swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].Text_Notifi.notifi_delete_false, "error");
                } else {
                    swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].Text_Notifi.inactive_false, "error");
                }
            }
        }).catch((error) => {
            if (error) {
                this.setState({
                    activeUploadExcel: false
                });
                swal(language[this.props.indexLanguage].Text_Notifi.error, error, "error");
            }
        })
    }

    onFilesError = (error, file) => {
        alert('error code ' + error.code + ': ' + error.message)
    }

    handleChangeDepartment = (index, value) => {
        ReactGA.event({
            category: 'Nhan vien',
            action: 'Tim kiem',
            label: 'Thay doi phong ban'
        });
        this.setState({
            valueDepartment: value,
            activePage: 1,
            listStaff: [],
            data_json_export: [],
            loading: true,
        }, () => {
            this.getStaff()
        })
    }
    handleChangeLevel = (index, value) => {
        ReactGA.event({
            category: 'Nhan vien',
            action: 'Tim kiem',
            label: 'Thay doi chuc vu'
        });
        this.setState({
            valueLevel: value,
            activePage: 1,
            listStaff: [],
            data_json_export: [],
            loading: true,
        }, () => {
            this.getStaff()
        })
    }

    handleChangeShift = (index, value) => {
        ReactGA.event({
            category: 'Nhan vien',
            action: 'Tim kiem',
            label: 'Thay doi ca'
        });
        this.setState({
            valueShift: value,
            activePage: 1,
            listStaff: [],
            data_json_export: [],
            loading: true,
        }, () => {
            this.getStaff()
        })
    }

    handleChangeRole = (index, value) => {
        ReactGA.event({
            category: 'Nhan vien',
            action: 'Tim kiem',
            label: 'Thay doi quyen han'
        });
        this.setState({
            valueRole: value,
            activePage: 1,
            listStaff: [],
            data_json_export: [],
            loading: true,
        }, () => {
            this.getStaff()
        })
    }

    handleChangeStatus = (index, value) => {
        ReactGA.event({
            category: 'Nhan vien',
            action: 'Tim kiem',
            label: 'Thay doi trang thai'
        });
        this.setState({
            valueStatus: value,
            activePage: 1,
            listStaff: [],
            data_json_export: [],
            loading: true,
        }, () => {
            this.getStaff()
        })
    }

    handleChangeDayStatus = (index, value) => {
        this.setState({
            valueDayStatus: value,
            valueFromDate: value.value == -1 ? "" : this.state.valueFromDate,
            valueToDate: value.value == -1 ? "" : this.state.valueToDate,
            valueStatus: value.value == 0 ? { value: 0, title: language[this.props.indexLanguage].placeholderSelect.status_inactive } : { value: 1, title: language[this.props.indexLanguage].placeholderSelect.status_active }
        })
    }

    handleChangeDate = (value, type) => {
        if (type == "from")
            this.setState({
                valueFromDate: value
            })
        else
            this.setState({
                valueToDate: value
            })
    }

    render() {
        const { classes } = this.props;
        if (this.state.isLogin === false) {
            return (
                <Redirect to={'/login'} />
            )
        }
        let bulletedStaff = this.state.listStaff.map((e, i) => {
            var checked = true;
            if (e.is_active === 1) {
                checked = true

            }
            if (e.is_active === 0) {
                checked = false

            }
            var gender = '';
            if (e.gender === 1) {
                gender = language[this.props.indexLanguage].Gender.Male
            } else if (e.gender === 2) {
                gender = language[this.props.indexLanguage].Gender.Female
            } else if (e.gender === 3) {
                gender = language[this.props.indexLanguage].Gender.OtherGender
            }
            var name_level = "";
            if (e.level !== null) {
                name_level = e.level.name
                e['id_level'] = e.level.id;
                e['name_level'] = e.level.name;
            }

            var department = ""
            if (e.department !== null) {
                department = e.department.name
            }

            var birthday = ""
            if (e.birthday !== null) {
                birthday = Moment(e.birthday).format("DD-MM-YYYY")
            }

            var start_work = ""
            if (e.start_work !== null) {
                start_work = Moment(e.start_work).format("DD-MM-YYYY")
            }

            var start_official_work = ""
            if (e.start_official_work !== null) {
                start_official_work = Moment(e.start_official_work).format("DD-MM-YYYY")
            }

            var end_official_work = ""
            if (e.end_official_work !== null) {
                end_official_work = Moment(e.end_official_work).format("DD-MM-YYYY")
            }

            var resignation_date = ""
            if (e.end_official_work !== null) {
                resignation_date = Moment(e.end_official_work).format("DD-MM-YYYY")
            }


            var role = ""
            if (e.role !== null) {
                role = e.role.name
            }
            var location = "";
            location = {
                pathname: '/capture',
                state: { idStaff: e.id, department: { title: department, value: e.id_department } }
            }
            return (
                <tr key={(i + 1)}>
                    <td>{(i + this.state.offset + 1)}</td>
                    <td>{e.id_staff}</td>
                    <td>{formatUsernameFromEmail(e.email)}</td>
                    <td className="text_normal_table">{e.name}</td>
                    <td>{e.abbreviated_name}</td>
                    <td>{birthday}</td>
                    <td>{gender}</td>
                    <td>{department}</td>
                    <td>{name_level}</td>
                    <td>{role}</td>
                    <td>{e.shift_name.join(', ')}</td>
                    {/* <td>{start_work}</td> */}
                    <td>{start_official_work}</td>
                    <td>{end_official_work}</td>
                    {/* <td>{resignation_date}</td> */}
                    {
                        this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':insert_or_update') !== -1
                            ?
                            <td>
                                <Switch
                                    onChange={() => {
                                        if (e.is_active === 0) {
                                            e.is_active = 1;
                                            e.end_official_work = null;
                                            e.ids_shift = e.ids_shift
                                            if (e.phone_number === null) {
                                                e.phone_number = ""
                                            }
                                            if (e.email === null) {
                                                e.email = ""
                                            }
                                            delete e.department;
                                            delete e.level;
                                            delete e.role;
                                            swal({
                                                title: language[this.props.indexLanguage].Text_Notifi.are_you_sure,
                                                text: language[this.props.indexLanguage].Text_Notifi.notifi_active + e.name,
                                                icon: "warning",
                                                buttons: {
                                                    oke: language[this.props.indexLanguage].textButton.oke,
                                                    cancel: language[this.props.indexLanguage].textButton.close
                                                },

                                            })
                                                .then(name => {
                                                    if (!name) throw null;
                                                    return fetch(`${this.state.crrApi2}/api/staff/insertOrUpdate`, {
                                                        method: 'POST',
                                                        headers: {
                                                            'Content-Type': 'application/json',
                                                            'Authorization': Store.getState().isLogin.access_token
                                                        },
                                                        body: JSON.stringify(e)
                                                    })
                                                })
                                                .then(response => {
                                                    return response.json()
                                                }).then(data => {
                                                    if (data.status === 10000) {
                                                        ReactGA.event({
                                                            category: 'Nhan vien',
                                                            action: 'Chuyen trang thai Active',
                                                        });
                                                        this.getStaff();
                                                        swal(language[this.props.indexLanguage].Text_Notifi.success, language[this.props.indexLanguage].Text_Notifi.active_success, "success", {
                                                            buttons: false,
                                                            timer: 1500,
                                                        });
                                                    } else if (data.status === 10005) {
                                                        swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].Text_Notifi.notifi_full_limit_staff, "warning");
                                                    }
                                                    else {
                                                        swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].Text_Notifi.active_false, "error");
                                                    }
                                                }).catch((error) => {
                                                    swal(language[this.props.indexLanguage].Text_Notifi.error, error, "error");
                                                })
                                        } else {
                                            e.is_active = 0;
                                            e.ids_shift = e.ids_shift
                                            e.end_official_work = Moment(this.state.dateInActive).format("YYYY-MM-DD");
                                            if (e.phone_number === null) {
                                                e.phone_number = ""
                                            }
                                            if (e.email === null) {
                                                e.email = ""
                                            }
                                            delete e.department;
                                            delete e.level;
                                            delete e.role;
                                            swal({
                                                title: language[this.props.indexLanguage].Text_Notifi.are_you_sure,
                                                text: language[this.props.indexLanguage].Text_Notifi.notifi_active + e.name,
                                                icon: "warning",
                                                buttons: {
                                                    oke: language[this.props.indexLanguage].textButton.oke,
                                                    cancel: language[this.props.indexLanguage].textButton.close
                                                },
                                            })
                                                .then(name => {
                                                    if (!name) throw null;

                                                    return fetch(`${this.state.crrApi2}/api/staff/insertOrUpdate`, {
                                                        method: 'POST',
                                                        headers: {
                                                            'Content-Type': 'application/json',
                                                            'Authorization': Store.getState().isLogin.access_token
                                                        },
                                                        body: JSON.stringify(e)
                                                    })
                                                })
                                                .then(response => {
                                                    return response.json()
                                                }).then(data => {
                                                    if (data.status === 10000) {
                                                        ReactGA.event({
                                                            category: 'Nhan vien',
                                                            action: 'Chuyen trang thai Inactive',
                                                        });
                                                        this.getStaff();
                                                        swal(language[this.props.indexLanguage].Text_Notifi.success, language[this.props.indexLanguage].Text_Notifi.inactive_success, "success", {
                                                            buttons: false,
                                                            timer: 1500,
                                                        });
                                                    } else if (data.status === 10005) {
                                                        swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].Text_Notifi.notifi_full_limit_staff, "warning");
                                                    }
                                                    else {
                                                        swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].Text_Notifi.inactive_false, "error");
                                                    }
                                                }).catch((error) => {
                                                    swal(language[this.props.indexLanguage].Text_Notifi.error, error, "error");
                                                })
                                        }
                                    }}
                                    checked={checked}
                                    handleDiameter={17}
                                    offColor="#969696"
                                    onColor="#00c5dc"
                                    offHandleColor="#ffffff"
                                    onHandleColor="#08f"
                                    height={25}
                                    width={55}
                                    className="react-switch"
                                    id="small-radius-switch"
                                />
                            </td>
                            :
                            <td>
                                {
                                    e.is_active === 0
                                        ?
                                        <span className="m-badge  m-badge--secondary m-badge--wide">Inactive</span>
                                        :
                                        <span className="m-badge  m-badge--accent m-badge--wide">Active</span>
                                }
                            </td>
                    }
                    {

                        <td>
                            {
                                this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':capture') !== -1
                                    ?
                                    <>
                                        {
                                            checked === true
                                                ?
                                                <>
                                                    <NavLink onClick={() => {
                                                        ReactGA.event({
                                                            category: 'Nhan vien',
                                                            action: 'Click lay anh',
                                                            label: 'Lay anh tu button',
                                                        });
                                                    }}
                                                        to={location} className="m-portlet__nav-link btn m-btn m-btn--hover-success m-btn--icon m-btn--icon-only m-btn--pill" data-tip data-for='GoToCapture' >
                                                        <i className="la la-camera-retro" />
                                                    </NavLink>
                                                    <ReactTooltip id='GoToCapture' type='dark' effect='solid'>
                                                        <span>{language[this.props.indexLanguage].textButton.go_to_capture}</span>
                                                    </ReactTooltip>
                                                </>
                                                :
                                                ""
                                        }

                                    </>
                                    :
                                    ''
                            }
                            {
                                this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':insert_or_update') !== -1
                                    ?
                                    <>
                                        <button onClick={(e) => {
                                            var data = this.state.listStaff
                                            var birthday = null
                                            if (data[i].birthday !== null) {
                                                birthday = Moment(data[i].birthday).format("YYYY-MM-DD")
                                            }

                                            var startWork = null
                                            if (data[i].start_work !== null) {
                                                startWork = Moment(data[i].start_work).format("YYYY-MM-DD")
                                            }

                                            var startOfficialWork = null
                                            if (data[i].start_official_work !== null) {
                                                startOfficialWork = Moment(data[i].start_official_work).format("YYYY-MM-DD")
                                            }

                                            var endOfficialWork = null
                                            if (data[i].end_official_work !== null) {
                                                endOfficialWork = Moment(data[i].end_official_work).format("YYYY-MM-DD")
                                            }
                                            var dataStaff = {
                                                id: data[i].id,
                                                object_id: data[i].object_id,
                                                id_staff: data[i].id_staff,
                                                id_role: data[i].id_role,
                                                name: data[i].name,
                                                abbreviated_name: data[i].abbreviated_name,
                                                birthday: birthday,
                                                phone_number: data[i].phone_number,
                                                email: data[i].email,
                                                start_work: startWork,
                                                start_official_work: startOfficialWork,
                                                end_official_work: endOfficialWork,
                                                gender: data[i].gender,
                                                is_active: data[i].is_active,
                                                is_delete: data[i].is_delete,
                                                id_department: data[i].id_department,
                                                id_level: data[i].id_level,
                                                HOST: this.state.crrApi2,
                                                idCompany: this.state.idCompany,
                                                modelCheck: true
                                            };
                                            this.setState({
                                                crrStaff: dataStaff,
                                                modalStaff: true,
                                                isEdit: true,
                                            });


                                        }} className="m-portlet__nav-link btn m-btn m-btn--hover-warning m-btn--icon m-btn--icon-only m-btn--pill" data-tip data-for='Edit'>
                                            <i className="la la-edit" />
                                        </button>
                                        <ReactTooltip id='Edit' type='dark' effect='solid'>
                                            <span>{language[this.props.indexLanguage].tooltip.open_edit}</span>
                                        </ReactTooltip>
                                    </>
                                    :
                                    <>
                                        <button onClick={(e) => {
                                            var data = this.state.listStaff
                                            var birthday = null
                                            if (data[i].birthday !== null) {
                                                birthday = Moment(data[i].birthday).format("YYYY-MM-DD")
                                            }

                                            var startWork = null
                                            if (data[i].start_work !== null) {
                                                startWork = Moment(data[i].start_work).format("YYYY-MM-DD")
                                            }

                                            var startOfficialWork = null
                                            if (data[i].start_official_work !== null) {
                                                startOfficialWork = Moment(data[i].start_official_work).format("YYYY-MM-DD")
                                            }

                                            var endOfficialWork = null
                                            if (data[i].end_official_work !== null) {
                                                endOfficialWork = Moment(data[i].end_official_work).format("YYYY-MM-DD")
                                            }
                                            var dataStaff = {
                                                id: data[i].id,
                                                object_id: data[i].object_id,
                                                id_staff: data[i].id_staff,
                                                id_role: data[i].id_role,
                                                name: data[i].name,
                                                abbreviated_name: data[i].abbreviated_name,
                                                birthday: birthday,
                                                phone_number: data[i].phone_number,
                                                email: data[i].email,
                                                start_work: startWork,
                                                start_official_work: startOfficialWork,
                                                end_official_work: endOfficialWork,
                                                gender: data[i].gender,
                                                is_active: data[i].is_active,
                                                is_delete: data[i].is_delete,
                                                id_department: data[i].id_department,
                                                id_level: data[i].id_level,
                                                HOST: this.state.crrApi2,
                                                idCompany: this.state.idCompany,
                                                modelCheck: true
                                            };
                                            this.setState({
                                                crrStaff: dataStaff,
                                                modalStaff: true,
                                                isEdit: true,
                                                isView: true,
                                            });
                                        }} className="m-portlet__nav-link btn m-btn m-btn--hover-info m-btn--icon m-btn--icon-only m-btn--pill" data-tip data-for='Info'>
                                            <i className="la la-info-circle" />
                                        </button>
                                        <ReactTooltip id='Info' type='dark' effect='solid'>
                                            <span>{language[this.props.indexLanguage].tooltip.info}</span>
                                        </ReactTooltip>
                                    </>
                            }
                            {
                                this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':delete') !== -1
                                    ||
                                    this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':reset_password') !== -1
                                    ?
                                    <span className="dropdown">
                                        <a href="#" className="btn m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only m-btn--pill" data-toggle="dropdown" aria-expanded="false">
                                            <i className="la la-ellipsis-h"></i>
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-right" x-placement="bottom-end">
                                            {
                                                this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':delete') !== -1
                                                    ?
                                                    <button
                                                        onClick={v => {
                                                            v.preventDefault();
                                                            swal({
                                                                title: language[this.props.indexLanguage].Text_Notifi.are_you_sure,
                                                                text: language[this.props.indexLanguage].Text_Notifi.notifi_sure_delete_staff + e.name,
                                                                icon: "warning",
                                                                button: {
                                                                    text: "Oke!",
                                                                    closeModal: false,
                                                                },
                                                            })
                                                                .then(name => {
                                                                    if (!name) throw null;
                                                                    if (this.state.crrApi2 === undefined) return;

                                                                    return fetch(`${this.state.crrApi2}/api/staff/delete`, {
                                                                        method: 'POST',
                                                                        headers: {
                                                                            'Content-Type': 'application/json',
                                                                            'Authorization': Store.getState().isLogin.access_token,
                                                                        },
                                                                        body: JSON.stringify({
                                                                            'id': e.id
                                                                        })
                                                                    })
                                                                })
                                                                .then(response => {
                                                                    return response.json()
                                                                }).then(data => {
                                                                    if (data.status === 10000) {
                                                                        ReactGA.event({
                                                                            category: 'Nhan vien',
                                                                            action: 'Xoa nhan nhan vien',
                                                                        });
                                                                        swal(language[this.props.indexLanguage].Text_Notifi.success, language[this.props.indexLanguage].Text_Notifi.notifi_delete_success, "success", {
                                                                            buttons: false,
                                                                            timer: 1500,
                                                                        });
                                                                        this.getStaff()
                                                                    }
                                                                    else {
                                                                        swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].Text_Notifi.notifi_delete_false, "error");
                                                                    }
                                                                }).catch((error) => {
                                                                    if (error) {
                                                                        swal(language[this.props.indexLanguage].Text_Notifi.error, error, "error");
                                                                    } else {
                                                                        swal.stopLoading();
                                                                        swal.close();
                                                                    }
                                                                })

                                                        }}
                                                        className="dropdown-item" style={{ cursor: 'pointer' }} ><i className="la la-trash"></i> {language[this.props.indexLanguage].tooltip.open_delete}</button>
                                                    :
                                                    ''
                                            }
                                            {/* {
                                                this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':reset_password') !== -1
                                                    ?
                                                    <>
                                                        {
                                                            checked === true
                                                            &&
                                                            <button
                                                                onClick={v => {
                                                                    v.preventDefault();
                                                                    swal({
                                                                        title: language[this.props.indexLanguage].Text_Notifi.are_you_sure,
                                                                        text: language[this.props.indexLanguage].Text_Notifi.reset_password + e.name,
                                                                        icon: "warning",
                                                                        button: {
                                                                            text: "Oke!",
                                                                            closeModal: false,
                                                                        },
                                                                    })
                                                                        .then(name => {
                                                                            if (!name) throw null;
                                                                            if (this.state.crrApi2 === undefined) return;
                                                                            return fetch(`${this.state.crrApi2}/api/staff/reset_password`, {
                                                                                method: 'POST',
                                                                                headers: {
                                                                                    'Content-Type': 'application/json',
                                                                                    'Authorization': Store.getState().isLogin.access_token,
                                                                                },
                                                                                body: JSON.stringify({
                                                                                    'id_staff': e.id
                                                                                })
                                                                            })
                                                                        })
                                                                        .then(response => {
                                                                            return response.json()
                                                                        }).then(data => {
                                                                            if (data.status === 10000) {
                                                                                ReactGA.event({
                                                                                    category: 'Nhan vien',
                                                                                    action: 'Dat lai mat khau',
                                                                                });
                                                                                swals({
                                                                                    title: language[this.props.indexLanguage].Text_Notifi.success,
                                                                                    content: <div>
                                                                                        <div>{language[this.props.indexLanguage].Text_Notifi.reset_password_success}</div>
                                                                                        <div>{language[this.props.indexLanguage].Text_Notifi.reset_password_new + e.name + `: ${data?.data}`}</div>
                                                                                    </div>,
                                                                                    icon: "success",
                                                                                    button: language[this.props.indexLanguage].textButton.close,
                                                                                });
                                                                            }
                                                                            else {
                                                                                swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].Text_Notifi.reset_password_error, "error");
                                                                            }
                                                                        }).catch((error) => {
                                                                            if (error) {
                                                                                swal(language[this.props.indexLanguage].Text_Notifi.error, error, "error");
                                                                            } else {
                                                                                swal.stopLoading();
                                                                                swal.close();
                                                                            }
                                                                        })

                                                                }}
                                                                className="dropdown-item" style={{ cursor: 'pointer' }} ><i className="fa flaticon-lock"></i> {language[this.props.indexLanguage].textButton.reset_password}</button>
                                                        }
                                                    </>
                                                    :
                                                    ''
                                            } */}
                                        </div>
                                    </span>
                                    :
                                    ''
                            }
                        </td>

                    }
                </tr>
            )
        });

        var pageRangeDisplayed = 1;
        if ($(window).width() < 768) {
            pageRangeDisplayed = 3
        } else {
            pageRangeDisplayed = 5
        }
        return (
            <>
            <Header />
            <div className={"m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body "}>
            <LoadingOverlay
                active={this.state.activeUploadExcel}
                spinner={<FadeLoader
                    css={override}
                    sizeUnit={"px"}
                    size={150}
                    color={'rgb(54, 215, 183)'}
                    loading={true}
                />}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(0, 0, 0, 0.58)'
                    })
                }}
                text={`${language[this.props.indexLanguage].Other.loading}`}
                className="col-xl-12 p-0"
            >
                <div className="m-grid__item m-grid__item--fluid m-wrapper">
                    <div className="m-content p-2">
                        <div className="m-portlet m-portlet--tab mb-0">
                            <div className="m-portlet__head p-3">
                                <div className="m-portlet__head-caption pl-3">
                                    <div className="m-portlet__head-title">
                                        <h3 className="m-portlet__head-text">
                                            {language[this.props.indexLanguage].Menubar.staffs}
                                        </h3>
                                    </div>
                                </div>
                                <div className="m-portlet__head-tools pr-3">
                                    <ul className="m-portlet__nav">
                                        <li className="m-portlet__nav-item">
                                            {
                                                this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':insert_or_update') !== -1
                                                    ?
                                                    <button onClick={(e) => {
                                                        this.setState({
                                                            crrStaff: {
                                                                id_staff: "",
                                                                id_role: null,
                                                                name: "",
                                                                abbreviated_name: "",
                                                                birthday: null,
                                                                start_work: null,
                                                                start_official_work: null,
                                                                end_official_work: null,
                                                                gender: 1,
                                                                id_department: null,
                                                                id_level: null,
                                                                ids_shift: '',
                                                                is_active: 1,
                                                                is_delete: 0,
                                                                HOST: this.state.crrApi2,
                                                                idCompany: this.state.idCompany,
                                                                modelCheck: false
                                                            },
                                                            modalStaff: true,
                                                        });
                                                    }} className="btn btn-accent m-btn m-btn--custom m-btn--icon m-btn--pill m-btn--air">
                                                        <span>
                                                            <i className="la la-plus" />
                                                            <span>{language[this.props.indexLanguage].textButton.add}</span>
                                                        </span>
                                                    </button>
                                                    :
                                                    ''
                                            }
                                            {
                                                this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':import_excel') !== -1
                                                    ||
                                                    this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':export_excel') !== -1
                                                    ?
                                                    <div className="text-right ml-3">
                                                        <button className="btn btn-success dropdown-toggle m-btn--pill" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <span>{language[this.props.indexLanguage].textButton.utilities} </span>
                                                        </button>
                                                        <div className="dropdown-menu dropdown-menu-left" aria-labelledby="dropdownMenuButton">
                                                            <div style={{ padding: "0.25rem 1.5rem" }}><span className="text-danger">* </span><b>{language[this.props.indexLanguage].Text_Notifi.text_notifi_import_staff}</b></div>
                                                            <ul className="m-nav">
                                                                {
                                                                    this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':import_excel') !== -1
                                                                        ?
                                                                        <>
                                                                            <li style={{ padding: "0.25rem 1.5rem" }} className="m-nav__section m-nav__section--first mt-2">
                                                                                <span className="m-nav__section-text">{language[this.props.indexLanguage].textTable.import_file}</span>
                                                                            </li>


                                                                            <Files
                                                                                className='files-dropzone dropdown-item'
                                                                                onChange={this.onFilesChange}
                                                                                onError={this.onFilesError}
                                                                                accepts={[".csv", ".xlsx"]}
                                                                                multiple={false}
                                                                                // maxFiles={3}
                                                                                minFileSize={0}
                                                                                clickable
                                                                                style={{ cursor: 'pointer' }}
                                                                            >
                                                                                <li className="m-nav__item">
                                                                                    <a className="m-nav__link">
                                                                                        <i className="m-nav__link-icon la la-cloud-upload" />
                                                                                        <span style={{ textDecoration: "underline" }}>{language[this.props.indexLanguage].textTable.import_staff}</span>
                                                                                        <br></br>
                                                                                        ({language[this.props.indexLanguage].textTable.templateDownload}  <a data-tip data-for="downloadTemplate" onClick={() => this.downloadFormImport(1)}><span style={{ textDecoration: "underline", color: "#34bfa3" }}>.XLSX</span></a> /
                                                                                        <a data-tip data-for="downloadTemplate" onClick={() => this.downloadFormImport(2)}> <span style={{ textDecoration: "underline", color: "#34bfa3" }}>.CSV</span> {this.state.ExportContent}</a>)
                                                                                        <ReactTooltip id='downloadTemplate' type='dark' effect='solid'>{language[this.props.indexLanguage].textTable.download_template}</ReactTooltip>
                                                                                    </a>
                                                                                </li>
                                                                            </Files>


                                                                            <Files
                                                                                className='files-dropzone dropdown-item'
                                                                                onChange={(files) => this.onFilesChangeDA(files, 1)}
                                                                                onError={this.onFilesError}
                                                                                accepts={[".csv", ".xlsx"]}
                                                                                multiple={false}
                                                                                // maxFiles={3}
                                                                                minFileSize={0}
                                                                                clickable
                                                                                style={{ cursor: 'pointer' }}
                                                                            >
                                                                                <li className="m-nav__item">
                                                                                    <a className="m-nav__link">
                                                                                        <i className="m-nav__link-icon la la-cloud-upload" />
                                                                                        <span style={{ textDecoration: "underline" }}>{language[this.props.indexLanguage].textTable.import_delete}</span>
                                                                                        <br></br>
                                                                                        ({language[this.props.indexLanguage].textTable.templateDownload} <a data-tip data-for="downloadTemplate" onClick={() => this.downloadTemplateResignation(1)}> <span style={{ textDecoration: "underline", color: "#34bfa3" }}>.XLSX</span></a> /
                                                                                        <a data-tip data-for="downloadTemplate" onClick={() => this.downloadTemplateResignation(2)}> <span style={{ textDecoration: "underline", color: "#34bfa3" }}>.CSV</span> {this.state.ExportContent}</a>)
                                                                                    </a>
                                                                                </li>
                                                                            </Files>


                                                                            <Files
                                                                                className='files-dropzone dropdown-item'
                                                                                onChange={(files) => this.onFilesChangeDA(files, 0)}
                                                                                onError={this.onFilesError}
                                                                                accepts={[".csv", ".xlsx"]}
                                                                                multiple={false}
                                                                                // maxFiles={3}
                                                                                minFileSize={0}
                                                                                clickable
                                                                                style={{ cursor: 'pointer' }}
                                                                            >
                                                                                <li className="m-nav__item">
                                                                                    <a className="m-nav__link">
                                                                                        <i className="m-nav__link-icon la la-cloud-upload" />
                                                                                        <span style={{ textDecoration: "underline" }}>{language[this.props.indexLanguage].textTable.import_inactive}</span>
                                                                                        <br></br>
                                                                                        ({language[this.props.indexLanguage].textTable.templateDownload} <a data-tip data-for="downloadTemplate" onClick={() => this.downloadTemplateResignation(1)}> <span style={{ textDecoration: "underline", color: "#34bfa3" }}>.XLSX</span></a> /
                                                                                        <a data-tip data-for="downloadTemplate" onClick={() => this.downloadTemplateResignation(2)}> <span style={{ textDecoration: "underline", color: "#34bfa3" }}>.CSV</span> {this.state.ExportContent}</a>)
                                                                                    </a>
                                                                                </li>
                                                                            </Files>

                                                                        </>
                                                                        :
                                                                        ""
                                                                }
                                                                {
                                                                    this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':export_excel') !== -1
                                                                        ?
                                                                        <>
                                                                            <li style={{ padding: "0.25rem 1.5rem", marginTop: "10px" }} className="m-nav__section">
                                                                                <span className="m-nav__section-text">{language[this.props.indexLanguage].textTable.export_file}</span>
                                                                            </li>
                                                                            <li style={{ cursor: 'pointer' }} className="files-dropzone dropdown-item m-nav__item">
                                                                                <a className="m-nav__link">
                                                                                    <i className="m-nav__link-icon la la-cloud-download" />
                                                                                    <span>{language[this.props.indexLanguage].textTable.export_staff}</span>
                                                                                    <br></br>
                                                                                    (<a className="" onClick={() => this.export(1)}>{language[this.props.indexLanguage].textTable.export_file} <span style={{ textDecoration: "underline", color: "#34bfa3" }}>.XLSX</span></a>  /
                                                                                    <a className="" onClick={() => this.export(2)}> <span style={{ textDecoration: "underline", color: "#34bfa3" }}>.CSV</span> {this.state.ExportContent}</a>)
                                                                                </a>
                                                                            </li>
                                                                        </>
                                                                        :
                                                                        ""

                                                                }
                                                            </ul>
                                                        </div>

                                                        {/* Export XLSX*/}

                                                        <ExcelExport
                                                            data={this.state.data_json_export}
                                                            fileName={`${language[this.props.indexLanguage].textTable.file_name_staff} ` + Moment(new Date()).format("DD-MM-YYYY") + ".xlsx"}
                                                            ref={(exporter) => { this._exporter = exporter; }}
                                                        >
                                                            <ExcelExportColumn field="id_staff" title="ID" width={100} />
                                                            <ExcelExportColumn field="name" title={language[this.props.indexLanguage].textTable.name + " (*)"} width={200} />
                                                            <ExcelExportColumn field="abbreviated_name" title={language[this.props.indexLanguage].textTable.nick_name + " (*)"} width={150} />
                                                            <ExcelExportColumn field="birthday" title={language[this.props.indexLanguage].textTable.birthday + " (*)"} width={120} />
                                                            <ExcelExportColumn field="gender" title={language[this.props.indexLanguage].textTable.gender + " (*)"} width={100} />
                                                            <ExcelExportColumn field="phone_number" title={language[this.props.indexLanguage].textTable.phone + " (*)"} width={120} />
                                                            <ExcelExportColumn field="email" title={language[this.props.indexLanguage].textTable.email} width={150} />
                                                            <ExcelExportColumn field="name_department" title={language[this.props.indexLanguage].textTable.department + " (*)"} width={120} />
                                                            <ExcelExportColumn field="name_level" title={language[this.props.indexLanguage].textTable.level} width={120} />
                                                            <ExcelExportColumn field="name_shift" title={language[this.props.indexLanguage].textTable.shift + " (*)"} />
                                                            <ExcelExportColumn field="start_work" title={language[this.props.indexLanguage].textTable.start_work} />
                                                            <ExcelExportColumn field="start_official_work" title={language[this.props.indexLanguage].textTable.start_official_work} />
                                                            <ExcelExportColumn field="end_official_work" title={language[this.props.indexLanguage].textTable.resignationDate} />
                                                        </ExcelExport>

                                                        <CSVLink
                                                            ref={(r) => this.csvLink = r}
                                                            headers={this.state.headerCSV}
                                                            data={this.state.data_json_export}
                                                            filename={`${language[this.props.indexLanguage].textTable.file_name_staff} ` + Moment(new Date()).format("DD-MM-YYYY") + ".csv"}
                                                        ></CSVLink>
                                                    </div> : ""}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="m-portlet__body m-portlet__body--no-padding">
                                <div className="row m-row--no-padding m-row--col-separator-xl">
                                    <div className="col-xl-12">
                                        <div className="m-widget1">
                                            <div className="row m-0 pb-3">
                                                <div className="col-md-12 p-0 m-0 row">
                                                    <div className="col-md-10 row">
                                                        <div className="col-lg-2 pl-0 m--margin-bottom-10-tablet-and-mobile select_option">
                                                            {/* <Autocomplete
                                                                onClick={() => {
                                                                    this.setState({
                                                                        selectDepartment: true
                                                                    })
                                                                }}
                                                                options={this.state.optionDepartment}
                                                                getOptionLabel={option => option.title}
                                                                value={this.state.valueDepartment}
                                                                onChange={this.handleChangeDepartment}
                                                                disableClearable
                                                                renderOption={(props, option, state) => (
                                                                    <div {...props}>
                                                                        {formatTitleOption(props)}
                                                                    </div>
                                                                )}
                                                                classes={{ paper: classes.paper }}
                                                                renderInput={params => (
                                                                    <TextField
                                                                        {...params}
                                                                        variant="outlined"
                                                                        placeholder={language[this.props.indexLanguage].placeholderSelect.select_department}
                                                                        fullWidth
                                                                    />
                                                                )}
                                                            /> */}
                                                            <Button onClick={() => {
                                                                this.setState({
                                                                    selectDepartment: !this.state.selectDepartment
                                                                })
                                                            }}
                                                                className="btn btn-secondary btn-block m-btn m-btn--icon"
                                                            >
                                                                <span style={{ color: "#000000" }}>
                                                                    {this.state.valueDepartment.value == -1 ? "Chọn phòng ban" : this.state.valueDepartment.title}
                                                                </span>
                                                            </Button>
                                                        </div>
                                                        <div className="col-lg-2 pl-0 m--margin-bottom-10-tablet-and-mobile select_option">
                                                            <Autocomplete
                                                                options={this.state.optionsLevel}
                                                                getOptionLabel={option => option.title}
                                                                value={this.state.valueLevel}
                                                                onChange={this.handleChangeLevel}
                                                                disableClearable
                                                                renderInput={params => (
                                                                    <TextField
                                                                        {...params}
                                                                        variant="outlined"
                                                                        placeholder={language[this.props.indexLanguage].placeholderSelect.select_level}
                                                                        fullWidth
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                        {
                                                            this.state.dataRole.indexOf(this.state.type + '/shift_work:view') !== -1 &&
                                                            <div className="col-lg-2 pl-0 m--margin-bottom-10-tablet-and-mobile select_option">
                                                                <Autocomplete
                                                                    options={this.state.optionsShift}
                                                                    getOptionLabel={option => option.title}
                                                                    value={this.state.valueShift}
                                                                    onChange={this.handleChangeShift}
                                                                    disableClearable
                                                                    renderInput={params => (
                                                                        <TextField
                                                                            {...params}
                                                                            variant="outlined"
                                                                            placeholder={language[this.props.indexLanguage].placeholderSelect.select_shift}
                                                                            fullWidth
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                        }
                                                        {
                                                            this.state.dataRole.indexOf(this.state.type + '/role:view') !== -1
                                                            &&
                                                            <div className="col-lg-2 pl-0 m--margin-bottom-10-tablet-and-mobile select_option">
                                                                <Autocomplete
                                                                    options={this.state.optionsRole}
                                                                    getOptionLabel={option => option.title}
                                                                    value={this.state.valueRole}
                                                                    onChange={this.handleChangeRole}
                                                                    disableClearable
                                                                    renderInput={params => (
                                                                        <TextField
                                                                            {...params}
                                                                            variant="outlined"
                                                                            placeholder={language[this.props.indexLanguage].placeholderSelect.select_role}
                                                                            fullWidth
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                        }

                                                        <div className="col-lg-2 pl-0 m--margin-bottom-10-tablet-and-mobile select_option">
                                                            <Autocomplete
                                                                options={this.state.optionStatus}
                                                                getOptionLabel={option => option.title}
                                                                value={this.state.valueStatus}
                                                                onChange={this.handleChangeStatus}
                                                                disableClearable
                                                                renderInput={params => (
                                                                    <TextField
                                                                        {...params}
                                                                        variant="outlined"
                                                                        fullWidth
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                        <div className="col-lg-2 pl-0 m--margin-bottom-10-tablet-and-mobile">
                                                            <input value={this.state.tempValue} type="text" onKeyDown={(event) => this.handleOnKeyDown(event)} onChange={(event) => this.isChange(event)} id="inputSearch" className="form-control m-input" placeholder={language[this.props.indexLanguage].placeholderInput.id_or_name} />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-2'>
                                                        <div style={{ display: 'flex' }}>
                                                            <div>
                                                                <button
                                                                    onClick={(dataSearch) => {
                                                                        ReactGA.event({
                                                                            category: 'Nhan vien',
                                                                            action: 'Tim kiem',
                                                                            label: this.state.tempValue.length > 0 ? "Nhap ID / Ten" : ""
                                                                        });
                                                                        this.btnSearch(this.state.tempValue)
                                                                    }}
                                                                    className="btn btn-accent m-btn m-btn--icon" id="m_search">
                                                                    <span>
                                                                        <i className="la la-search" />
                                                                        <span>{language[this.props.indexLanguage].textButton.search}</span>
                                                                    </span>
                                                                </button>
                                                            </div>
                                                            <div className="ml-3 ">
                                                                <button
                                                                    onClick={() => {
                                                                        this.reset()
                                                                    }}
                                                                    className="btn btn-secondary m-btn m-btn--icon" id="m_reset">
                                                                    <span>
                                                                        <i className="la la-rotate-left" />
                                                                        <span>{language[this.props.indexLanguage].textButton.reload}</span>
                                                                    </span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-10 row">
                                                        <div className="col-lg-6"></div>
                                                        <div className="col-lg-2 pl-0 m--margin-bottom-10-tablet-and-mobile select_option">
                                                            <Autocomplete
                                                                options={this.state.optionDayStatus}
                                                                getOptionLabel={option => option.title}
                                                                value={this.state.valueDayStatus}
                                                                onChange={this.handleChangeDayStatus}
                                                                disableClearable
                                                                renderInput={params => (
                                                                    <TextField
                                                                        {...params}
                                                                        variant="outlined"
                                                                        fullWidth
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                        <div className='col-lg-2 pl-0 m--margin-bottom-10-tablet-and-mobile'>
                                                            <DatePicker
                                                                className='form-control m-input m_datetimepicker_6'
                                                                selected={this.state.valueFromDate}
                                                                onChange={date => this.handleChangeDate(date, 'from')}
                                                                dateFormat={'dd-MM-yyyy'}
                                                                maxDate={this.state.valueToDate}
                                                                placeholderText={'dd-MM-yyyy'}
                                                                locale={"vi"}
                                                            />
                                                        </div>
                                                        <div className='col-lg-2 pl-0 m--margin-bottom-10-tablet-and-mobile'>
                                                            <DatePicker
                                                                className='form-control m-input m_datetimepicker_6'
                                                                selected={this.state.valueToDate}
                                                                onChange={date => this.handleChangeDate(date, 'to')}
                                                                dateFormat={'dd-MM-yyyy'}
                                                                minDate={this.state.valueFromDate}
                                                                placeholderText={'dd-MM-yyyy'}
                                                                locale={"vi"}
                                                            />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className='row m-0 pb-3'>
                                                {
                                                    this.state.selectDepartment === true && (
                                                        <div className='col-xl-4'>
                                                            <div style={{ maxHeight: "650px", overflow: "auto", marginRight: "20px", marginTop: "10px", whiteSpace: "nowrap" }}>
                                                                <TreeView item={formatTreeViewData(this.state.optionDepartment)} getList={(data) => {
                                                                    this.handleChangeDepartment(0, {
                                                                        value: data.value,
                                                                        title: data.title,
                                                                        code: data.code
                                                                    });
                                                                    console.log('data', data)
                                                                }} />
                                                            </div>
                                                        </div>)
                                                }
                                                <div className={this.state.selectDepartment === true ? 'col-xl-8' : 'col-xl-12'}>
                                                    <div className="mb-2 mt-4">
                                                        {language[this.props.indexLanguage].Search.total_result_search}{this.state.countDataStaff}
                                                    </div>
                                                    <div className="table-responsive text-nowrap">
                                                        <table className="table table-bordered table-hover table-checkable dataTable no-footer dtr-inline collapsed">
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ 'verticalAlign': 'middle', 'width': '80px' }}>{language[this.props.indexLanguage].Other.no}</th>
                                                                    <th style={{ 'verticalAlign': 'middle' }}>{language[this.props.indexLanguage].textTable.id_staff}</th>
                                                                    <th style={{ 'verticalAlign': 'middle' }}>Username</th>
                                                                    <th style={{ 'verticalAlign': 'middle' }}>{language[this.props.indexLanguage].textTable.name}</th>
                                                                    <th style={{ 'verticalAlign': 'middle' }}>{language[this.props.indexLanguage].textTable.nick_name}</th>
                                                                    <th style={{ 'verticalAlign': 'middle' }}>{language[this.props.indexLanguage].textTable.birthday}</th>
                                                                    <th style={{ 'verticalAlign': 'middle' }}>{language[this.props.indexLanguage].textTable.gender}</th>
                                                                    <th style={{ 'verticalAlign': 'middle' }}>{language[this.props.indexLanguage].textTable.department}</th>
                                                                    <th style={{ 'verticalAlign': 'middle' }}>{language[this.props.indexLanguage].textTable.level}</th>
                                                                    <th style={{ 'verticalAlign': 'middle' }}>{language[this.props.indexLanguage].Role.Role}</th>
                                                                    <th style={{ 'verticalAlign': 'middle' }}>{language[this.props.indexLanguage].textTable.shift}</th>
                                                                    <th style={{ 'verticalAlign': 'middle', 'width': '150px' }}>{language[this.props.indexLanguage].textTable.start_official_work}</th>
                                                                    <th style={{ 'verticalAlign': 'middle' }}>{language[this.props.indexLanguage].textTable.end_official_work}</th>
                                                                    <th style={{ 'verticalAlign': 'middle', 'width': '150px' }}>{language[this.props.indexLanguage].textTable.status}</th>
                                                                    <th style={{ 'verticalAlign': 'middle', 'width': '200px' }}>{language[this.props.indexLanguage].textTable.action}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {bulletedStaff}
                                                            </tbody>

                                                        </table>
                                                        <PulseLoader
                                                            css={override}
                                                            sizeUnit={"px"}
                                                            size={12}
                                                            margin={'2px'}
                                                            color={'#36D7B7'}
                                                            loading={this.state.loading}
                                                        />

                                                        <ModalProgress
                                                            show={this.state.modalProgress}
                                                            onHide={this.modalProgress}
                                                            importProgress={this.state.importProgress}
                                                        />
                                                        <ModalStaff
                                                            listdepartment={this.state.listDepartment}
                                                            listlevel={this.state.listLevel}
                                                            listshift={this.state.listShift}
                                                            liststaff={this.state.listStaff}
                                                            data={this.state.crrStaff}
                                                            show={this.state.modalStaff}
                                                            onHide={this.modalClose}
                                                            isEdit={this.state.isEdit}
                                                            isView={this.state.isView}
                                                        />
                                                    </div>
                                                    <Pagination
                                                        firstPageText={language[this.props.indexLanguage].pagination.first}
                                                        nextPageText={language[this.props.indexLanguage].pagination.next}
                                                        lastPageText={language[this.props.indexLanguage].pagination.last}
                                                        prevPageText={language[this.props.indexLanguage].pagination.previous}
                                                        activePage={this.state.activePage}
                                                        itemsCountPerPage={this.itemsPerPage}
                                                        totalItemsCount={this.state.countDataStaff}
                                                        pageRangeDisplayed={pageRangeDisplayed}
                                                        onChange={this.handlePageChange}

                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LoadingOverlay>
            </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        indexLanguage: state.changelanguage.indexLanguage
    }
}

export default withStyles(useStyles)(connect(mapStateToProps, null)(Staff));
