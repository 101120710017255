import vi from 'date-fns/locale/vi';
import $ from 'jquery';
import Moment from 'moment';
import React, { Component } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
// import events from './events';
import 'react-big-calendar/lib/sass/styles.scss';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { withRouter } from 'react-router';
import { Redirect } from 'react-router-dom';
import language from '../../../language/language';
import Store from '../../../store';
import Report from './components/report';
import { connect } from 'react-redux'
import Header from '../../layouts/Header';
registerLocale('vi', vi)

const localizer = momentLocalizer(Moment)

const nameTab = 'timekeeping';

const ExampleCustomInput = ({ value, onClick }) => (
    <button className="btn btn-secondary m-btn m-btn--icon col-md-12" onClick={onClick}>
        {value}
    </button>
);

class TimekeepingPersonal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogin: Store.getState().isLogin.isLogin,
            access_token: Store.getState().isLogin.access_token,
            box_engine_cf: Store.getState().boxai.box_engine_cf,
            crrApi2: Store.getState().boxai.box_engine_cf.crrApiSocket,
            language: Store.getState().changelanguage.language,
            idCompany: Store.getState().boxai.box_engine_cf.idCompany,
            dataRole: Store.getState().role.role,
            crrMonth: new Date(),
            dataTimeKeeping: [],
            totalShiftWork: 0,
            timeKeeping: [],
            letter: [],
            workMonth: 0,
            Holiday: [],
            total: {}
        };
        var itemsPerPage = 1;
        if ($(window).width() < 768) {
            itemsPerPage = 8
        } else {
            itemsPerPage = 10
        }
        this.itemsPerPage = itemsPerPage;
        Store.subscribe(() => {
            this.setState({
                isLogin: Store.getState().isLogin.isLogin,
                access_token: Store.getState().isLogin.access_token,
                box_engine_cf: Store.getState().boxai.box_engine_cf,
                crrApi2: Store.getState().boxai.box_engine_cf.crrApiSocket,
                language: Store.getState().changelanguage.language,
                idCompany: Store.getState().boxai.box_engine_cf.idCompany,
                dataRole: Store.getState().role.role,
                crrMonth: new Date(),
                dataTimeKeeping: [],
                totalShiftWork: 0,
                total: null
            }, () => {
                this.getDataTimekeeping();
            });
        });
        this.getDataTimekeeping();
    }

    componentWillMount() {
        var type = localStorage.getItem("type")

        this.setState({
            type: type,
        })
    }
    // getUnique = (arr, comp) => {
    //     const unique = arr
    //         .map(e => e[comp])
    //         // store the keys of the unique objects
    //         .map((e, i, final) => final.indexOf(e) === i && i)

    //         // eliminate the dead keys & store unique objects
    //         .filter(e => arr[e]).map(e => arr[e]);

    //     return unique;
    // }

    splitDayOff = (data) => {
        const obj = data.map(map => ({
            id: map.id,
            start: map.start_date,
            end: map.end_date,
            title: <div className='d-flex justify-content-between'>
                <span className='cut-text'>
                    {(map.type === 2 || map.type === 5) && (Moment(map.start_date).format('HH:mm') + ' - ' + Moment(map.end_date).format('HH:mm')) + ' ('}
                    {map.reason ? map.reason.name : language[this.props.indexLanguage].Timekeeping[map.type === 2 ? 'ot' : map.type === 3 ? 'business' : map.type === 4 ? 'late_early' : map.type === 5 ? 'compensate' : '']}
                    {(map.type === 2 || map.type === 5) && ')'}
                </span>
                <span>{(map.reason && map.reason.coefficient === 0) ? 0 : map.work_off}</span>
            </div>,
            type: 6,
        }));
        return obj;
    }

    holidayOff = (data) => {
        const data_arr = Object.entries(data);

        const array = data_arr.map(map => ({
            start: new Date(map[0]),
            end: new Date(map[0]),
            title: map[1],
            type: 5,
            toolTip: `${map[1]} (${Moment(new Date(map[0])).format('DD-MM-YYYY')} - ${Moment(new Date(map[0])).format('DD-MM-YYYY')})`
        }))

        return array;
    }

    splitTimekeeping = (data) => {
        const data_arr = Object.values(data);
        const array = []
        for (let i = 0; i < data_arr.length; i++) {
            for (let j = 0; j < data_arr[i].timekeeping_list.length; j++) {
                const element = data_arr[i].timekeeping_list[j];
                var time_in = "--:--";
                var time_out = "--:--";
                var nameShift = Object.keys(element.shift).length > 0 ? element.shift.name : "";
                if (element.time_in !== null || element.time_out !== null) {
                    if (element.time_out !== null) {
                        time_out = Moment(element.time_out).format('HH:mm')
                    }
                    time_in = Moment(element.time_in).format('HH:mm');
                    array.push({
                        id: element.id,
                        start: new Date(element.date),
                        end: new Date(element.date),
                        title: <div className='d-flex justify-content-between'>
                            <span className='cut-text'>
                                {time_in + ' - ' + time_out + ' ' + nameShift}
                            </span>
                            <span>{element.work}</span>
                        </div>,
                        type: element.type_tk,
                        toolTip: `${time_in} → ${time_out} ${nameShift}`
                    })
                }
                else {
                    if (!element.id && element.work < 1) {
                        array.push({
                            start: new Date(element.date),
                            end: new Date(element.date),
                            title: <div className='d-flex justify-content-between'>
                                <span className='cut-text'>
                                    {"--:--"}
                                </span>
                                <span>{element.work}</span>
                            </div>,
                            type: 6,
                        })
                    }
                }
            }
        }

        return array;
    }

    splitReason = (data, shifts) => {
        const data_arr = Object.values(data);
        const array = []
        for (let i = 0; i < data_arr.length; i++) {
            for (let j = 0; j < data_arr[i].dof_list.length; j++) {
                const element = data_arr[i].dof_list[j];
                var renderText = ""
                var renderTextFull = ""
                if (element.type === 4) {
                    let shiftNow
                    if (shifts.length > 0) {
                        for (let index = 0; index < shifts.length; index++) {
                            const e = shifts[index];
                            let day_of_week = e.day_of_week.slice(1, 14).split('|');
                            let test = day_of_week[6];
                            day_of_week.unshift(test);
                            day_of_week.pop();
                            e.dayOfWeekk = day_of_week
                            if (e.dayOfWeekk[Moment(element.start_date).isoWeekday()] === "1") {
                                shiftNow = e
                            }
                        }
                    }
                    if (shiftNow !== undefined && Object.keys(shiftNow).length !== 0 && shiftNow.time_in !== undefined) {
                        let time_in_shift = Moment(element.start_date).format('YYYY-MM-DD') + " " + shiftNow.time_in.slice(0, 5) + ':00';

                        let time_out_shift = Moment(element.end_date).format('YYYY-MM-DD') + " " + shiftNow.time_out.slice(0, 5) + ':00';
                        let time_in = Moment(element.start_date);
                        let time_out = Moment(element.end_date);

                        let in_late = time_in.diff(time_in_shift);
                        let out_soon = time_out.diff(time_out_shift);

                        let durationInLate = Moment.duration(in_late);
                        let durationOutSoon = Moment.duration(out_soon);

                        let minutesLate = durationInLate.asMinutes();
                        let minutesSoon = durationOutSoon.asMinutes();

                        let minute_late = Math.abs(minutesLate);
                        let minute_soon = Math.abs(minutesSoon);

                        if (minute_late === 0) {
                            renderText = `(${language[this.props.indexLanguage].Other.S}: ${minute_soon}')`
                            renderTextFull = `(${language[this.props.indexLanguage].Report.out_soon_time}: ${minute_soon} ${language[this.props.indexLanguage].Report.minutes})`
                        } else if (minute_soon === 0) {
                            renderText = `(${language[this.props.indexLanguage].Other.L}: ${minute_late}')`
                            renderTextFull = `(${language[this.props.indexLanguage].Report.to_late_time}: ${minute_late} ${language[this.props.indexLanguage].Report.minutes})`
                        } else {
                            renderText = `(${language[this.props.indexLanguage].Other.L}: ${minute_late}', ${language[this.props.indexLanguage].Other.S}: ${minute_soon}')`
                            renderTextFull = `(${language[this.props.indexLanguage].Report.to_late_time}: ${minute_late} ${language[this.props.indexLanguage].Report.minutes} - ${language[this.props.indexLanguage].Report.out_soon_time}: ${minute_soon} ${language[this.props.indexLanguage].Report.minutes})`
                        }
                    }
                }

                array.push({
                    id: element.id,
                    start: new Date(element.start_date),
                    end: new Date(element.end_date),
                    title: <div className='d-flex justify-content-between'>
                        <span className='cut-text'>
                            {element.reason ? element.reason.name : element.type === 2 ? 'OT' : element.type === 3 ? 'CT' : element.type === 4 ? `MS` : element.type === 5 ? 'CCB' : ''}
                            {(element.type === 2 || element.type === 5) && ' (' + (Moment(element.start_date).format('HH:mm') + ' - ' + Moment(element.end_date).format('HH:mm')) + ')'}
                        </span>
                        <span>{element.type === 4 ? renderText : (element.reason && element.reason.coefficient === 0) ? 0 : element.work_off}{element.type === 2 ? 'h' : ''}</span>
                    </div>,
                    type: 6,
                    toolTip: `${element.reason ? element.reason.name : language[this.props.indexLanguage].Timekeeping[element.type === 2 ? 'ot' : element.type === 3 ? 'business' : element.type === 4 ? 'late_early' : element.type === 5 ? 'compensate' : '']} ${element.type === 4 ? renderTextFull : `(${Moment(element.start_date).format('DD-MM-YYYY HH:mm') + ' → ' + Moment(element.end_date).format('DD-MM-YYYY HH:mm')})`}`
                })
            }

        }

        return array;
    }

    calculationTotalWork = (data) => {
        let work = 0;
        data.map(map => {
            if (map.reason) {
                if (map.reason.coefficient !== 0) {
                    work += map.work_off;
                }
            } else {
                work += map.work_off;
            }
        })
        return work;
    }

    calculationTotalWorkForMonth = (data) => {
        let work = 0;
        let work_not = 0;
        data.map(map => {
            if (map.type === 1 && map.reason.coefficient !== 0) {
                work += map.work_off;
            } else if (map.type === 1 && map.reason.coefficient === 0) {
                work_not += map.work_off;
            }
        });
        return [work, work_not];
    }

    calculationWorkIntersect = (time_keeping, dof) => {
        let work = 0;
        const dof_not_type_4 = dof.filter(filter => filter.type !== 4);
        const dof_not_work = dof_not_type_4.filter(filter => filter.type !== 1 || (filter.reason && filter.reason.coefficient !== 0));
        time_keeping.forEach(data_time_keeping => {
            dof_not_work.forEach(data_dof => {
                if (Moment(data_time_keeping.time_in).format('YYYY-MM-DD HH:mm:ss') >= Moment(data_dof.end_date).format('YYYY-MM-DD HH:mm:ss') ||
                    Moment(data_time_keeping.time_out).format('YYYY-MM-DD HH:mm:ss') <= Moment(data_dof.start_date).format('YYYY-MM-DD HH:mm:ss')) {
                } else {
                    let start = data_time_keeping.time_in;
                    let end = data_time_keeping.time_out;

                    if (!start || !end) {
                        return 0;
                    }

                    const start_dof = Moment(data_dof.start_date).format('YYYY-MM-DD')
                    const end_dof = Moment(data_dof.end_date).format('YYYY-MM-DD')

                    if (start_dof === end_dof) {
                        if (Moment(data_time_keeping.time_in).format('HH:mm:ss') < Moment(data_dof.start_date).format('HH:mm:ss')) {
                            start = data_dof.start_date
                        }
                        if (Moment(data_time_keeping.time_out).format('HH:mm:ss') > Moment(data_dof.end_date).format('HH:mm:ss')) {
                            end = data_dof.end_date
                        }
                    } else {

                        //Ngay dau
                        if (start_dof === Moment(data_time_keeping.date).format('YYYY-MM-DD')) {
                            if (Moment(data_time_keeping.time_in).format('HH:mm:ss') < Moment(data_dof.start_date).format('HH:mm:ss')) {
                                start = data_dof.start_date
                            }
                        } else if (end_dof === Moment(data_time_keeping.date).format('YYYY-MM-DD')) {
                            if (Moment(data_time_keeping.time_out).format('HH:mm:ss') > Moment(data_dof.end_date).format('HH:mm:ss')) {
                                end = data_dof.end_date
                            }
                        }
                    }

                    const time_in = this.coverTimeToMinute(Moment(start).format('HH:mm:ss'));
                    const time_out = this.coverTimeToMinute(Moment(end).format('HH:mm:ss'));

                    const total_time = this.coverTimeToMinute(Moment(data_time_keeping.time_out).format('HH:mm:ss')) - this.coverTimeToMinute(Moment(data_time_keeping.time_in).format('HH:mm:ss'))

                    work += (time_out - time_in) / (total_time) * data_time_keeping.work;
                }
            })
        });
        return work;
    }

    coverTimeToMinute = (time) => {
        //HH:mm:ss
        if (!time) return 0;
        let a = time.split(':');
        return parseInt(a[0]) * 60 + parseInt(a[1]);
    }

    getDataTimekeeping = () => {
        if (this.state.crrApi2 == undefined) return;
        fetch(`${this.state.crrApi2}/api/time_keeping/get_by_id_staff_v2`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            body: JSON.stringify({
                "start_date": Moment(this.state.crrMonth).startOf('month').format("YYYY-MM-DD"),
                "end_date": Moment(this.state.crrMonth).endOf('month').format("YYYY-MM-DD"),
            })
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status == 10000) {
                var dataTimeKeeping = [];
                var totalShiftWork = this.state.totalShiftWork
                let array_holiday = this.holidayOff(data.data.holiday);
                var array_timekeeping = this.splitTimekeeping(data.data.timekeepings);
                let array_reason = this.splitReason(data.data.timekeepings, data.data.shifts);

                dataTimeKeeping = dataTimeKeeping.concat(array_holiday);
                dataTimeKeeping = dataTimeKeeping.concat(array_reason);
                dataTimeKeeping = dataTimeKeeping.concat(array_timekeeping);
                this.setState({
                    dataTimeKeeping: dataTimeKeeping,
                    timeKeeping: data.data.timekeepings,
                    letter: data.data.dof,
                    Holiday: Object.keys(data.data.holiday),
                    total: data.data.total
                });
            } else {
                this.setState({
                    loading: false,
                })
            }
        }).catch((error) => {
            if (error) {
                this.setState({
                    loading: false,
                })
            }
        })
    }

    handleChangeMonth = month => {
        this.setState({
            crrMonth: month,
            totalShiftWork: 0,
        }, () => {
            this.getDataTimekeeping();
        });
    }



    render() {
        if (this.state.isLogin === false) {
            return (
                <Redirect to={'/login'} />
            )
        }
        return (
            <>
            <Header />
            <div className={"m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body "}>
            <div className="m-grid__item m-grid__item--fluid m-wrapper p-2 d-md-flex" id="timekeepingPersonal">
                <div className="m-content d-md-inline-block flex-grow-1">
                    <div className="m-portlet m-portlet--tab mb-0">
                        <div className="m-portlet__head p-3">
                            <div className="m-portlet__head-caption col-xs-12 col-md-12 p-2 col-lg-10 col-xl-8 ">
                                <div className="m-portlet__head-title hide_mobile">
                                    <h3 className="m-portlet__head-text pr-3">
                                        {language[this.props.indexLanguage].Menubar.timekeeping_personal}
                                    </h3>
                                </div>
                                <div className="col-md-2 col-xs-6 pr-0 pl-0 select_date_month">
                                    <DatePicker
                                        className="form-control m-input m_datetimepicker_6"
                                        selected={this.state.crrMonth}
                                        onChange={this.handleChangeMonth}
                                        dateFormat="MM/yyyy"
                                        customInput={<ExampleCustomInput />}
                                        showMonthYearPicker
                                        locale={this.props.indexLanguage === 1 ? vi : ''}
                                        placeholderText="MM/YYYY"
                                    />
                                </div>
                            </div>
                            {/* <div className="m-portlet__head-tools pr-3">
                                <ul className="m-portlet__nav">
                                    <li className="m-portlet__nav-item">
                                        <h4 className="mb-0">{language[this.props.indexLanguage].Timekeeping.total_shift_work}: <b> {this.state.totalShiftWork.toFixed(2)}</b></h4>
                                    </li>
                                </ul>
                            </div> */}
                        </div>
                        {/*begin::Form*/}
                        <div className="m-form m-form--fit m-form--label-align-right">
                            <div className="m-portlet__body p-4">
                                <div className="col-md-12 p-0">
                                    <Calendar
                                        popup
                                        // onSelectEvent={() => {console.log('a')}}
                                        toolbar={false}
                                        style={{ height: 600 }}
                                        views={['month']}
                                        events={this.state.dataTimeKeeping}
                                        localizer={localizer}
                                        date={this.state.crrMonth}
                                        tooltipAccessor={(e) => (e.toolTip)}
                                        eventPropGetter={(event) => ({
                                            style: {
                                                backgroundColor: event.type === 1 ? '#34bfa3' : event.type === 2 ? '#ffb822' : event.type === 0 ? '#c4c5d6'
                                                    : event.type === 5 ? 'green' : event.type === 6 ? '#7f7f7f' : '#f4516c',
                                            }
                                        })}
                                    />
                                    <div>
                                        <div className="m-demo__preview m-demo__preview--badge text-center pt-4">
                                            <i className="pl-5 btn-success m-btn m-btn--icon" style={{ fontWeight: '600' }}></i><span className="pr-3 pl-1" style={{ fontSize: ".9rem" }}> - <b>{language[this.props.indexLanguage].Text_Notifi.note_enough_work_timekeeping}</b></span>
                                            <i className="pl-5 btn-warning m-btn m-btn--icon" style={{ fontWeight: '600' }}></i><span className="pr-3" style={{ fontSize: ".9rem" }}> - <b>{language[this.props.indexLanguage].Text_Notifi.note_not_enough_work_timekeeping}</b></span>
                                            <i className="pl-5 btn-danger m-btn m-btn--icon" style={{ fontWeight: '600' }}></i><span className="pr-3" style={{ fontSize: ".9rem" }}> - <b>{language[this.props.indexLanguage].Text_Notifi.note_not_checkin_timekeeping}</b></span>
                                            {/* <i className="pl-5 btn-metal m-btn m-btn--icon" style={{ fontWeight: '600' }}></i><span className="pr-3" style={{ fontSize: ".9rem" }}> - <b>{language[this.props.indexLanguage].Text_Notifi.note_not_shift_timekeeping}</b></span> */}
                                        </div>
                                    </div>
                                    {/* <div className="m-demo">
                                        <div className="m-demo__preview m-demo__preview--badge text-center pt-4">
                                            <span className="m-badge m-badge--success m-badge--wide m-badge--rounded" style={{ fontSize: ".9rem" }}>{language[this.props.indexLanguage].Text_Notifi.note_enough_work_timekeeping}</span>
                                            <span className="m-badge m-badge--warning m-badge--wide m-badge--rounded" style={{ fontSize: ".9rem", color: '#fff' }}>{language[this.props.indexLanguage].Text_Notifi.note_not_enough_work_timekeeping}</span>
                                            <span className="m-badge m-badge--danger m-badge--wide m-badge--rounded" style={{ fontSize: ".9rem" }}>{language[this.props.indexLanguage].Text_Notifi.note_not_checkin_timekeeping}</span>
                                            <span className="m-badge m-badge--metal m-badge--wide m-badge--rounded" style={{ fontSize: ".9rem" }}>{language[this.props.indexLanguage].Text_Notifi.note_not_shift_timekeeping}</span>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        {/*end::Form*/}
                    </div>
                </div>
                <div className='d-md-inline-block timekeeping_report'>
                    <Report work_month={this.state.workMonth} holiday={this.state.Holiday} time_keeping={this.state.timeKeeping} letter={this.state.letter} total={this.state.totalShiftWork.toFixed(2)} total_timekeeping={this.state.total} />
                </div>
            </div>
            </div>
            </>
        )
    }
}
const mapStateToProps = state => {
    return {
        indexLanguage: state.changelanguage.indexLanguage,
    }
}


export default withRouter(connect(mapStateToProps, null)(TimekeepingPersonal));
