import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Route } from "react-router-dom";
import { logout } from '../actions/isLogin';
import Footer from '../components/layouts/Footer';
import Header from '../components/layouts/Header';
//Layout
import MenuBar from '../components/layouts/MenuBar';
import ChangePassword from '../components/screen/ChangePassword';
//DashBoard
import Dashboard from '../components/screen/DashBoard';
import DashboardSA from '../components/screen/DashBoardSA';
//Door-Management
import Door from '../components/screen/DoorManagement/Door';
import DoorManagement from '../components/screen/DoorManagement/DoorManagement';
//Face-Management
import Capture from '../components/screen/FaceManagement/Capture';
import SubModel from '../components/screen/FaceManagement/Model';
import GoLateApproval from '../components/screen/InOut/GoLateApproval';
import History from '../components/screen/InOut/History';
import Statistical from '../components/screen/InOut/Statistical';
//In-Out
import Timekeeping from '../components/screen/InOut/Timekeeping';
import TimekeepingPersonal from '../components/screen/InOut/TimekeepingPersonal';
import WarningInOut from '../components/screen/InOut/WarningInOut';
import KPIScreen from '../components/screen/kpi';
import GoLateLeaveEarly from '../components/screen/LeaveOfAbsenceLetter/goLateLeaveEarly';
import GoOnBusiness from '../components/screen/LeaveOfAbsenceLetter/goOnBussiness';
import LeaveOfAbsenceLetter from '../components/screen/LeaveOfAbsenceLetter/leaveOfAbsenceLetter';
import reasonOff from '../components/screen/LeaveOfAbsenceLetter/reasonOff';
import SignUpForOvertime from '../components/screen/LeaveOfAbsenceLetter/signupForOvertime';
import StatisticalLeaveOfAbsence from '../components/screen/LeaveOfAbsenceLetter/statisticalLeaveOfAbsence';
import timekeepingCompensate from '../components/screen/LeaveOfAbsenceLetter/timekeepingCompensate';
import LongTerm from '../components/screen/LeaveOfAbsenceLetter/longTerm';
import Department from '../components/screen/Organization/Department';
import Group from '../components/screen/Organization/Group';
import Level from '../components/screen/Organization/Level';
import Role from '../components/screen/Organization/Role';
import Shift from '../components/screen/Organization/Shift';
//Organization
import Staff from '../components/screen/Organization/Staff';
import Settings from '../components/screen/Setting';
import Appointment from '../components/screen/Utilities/Appointment';
import { HOST_CLOUD } from '../config';
import Store from '../store';


const nameTab = 'config'
class ReactRouter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataRole: Store.getState().role.role,
            type: '',
            type_login: '',
            supervisor: true,
            crrApi2: Store.getState().boxai.box_engine_cf.crrApiSocket,
            listCompany: [],
            access_token_cloud: '',
        }
        Store.subscribe(() => {
            this.setState({
                dataRole: Store.getState().role.role,
                crrApi2: Store.getState().boxai.box_engine_cf.crrApiSocket,
                listCompany: Store.getState().boxai.list_company,
            }, () => {
                this.checkSupervisor();
            });
        });
    }

    getUser = () => {
        fetch(`${HOST_CLOUD}/api/user/biface_getbytoken`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            }
        }).then((response) => {
            if (response.status == 401) {
                this.setState({
                    isLogin: false
                }, () => {
                    localStorage.clear();
                    Store.dispatch(logout());
                })
            }
            return response.json();
        })
    }

    componentDidUpdate(prevProps, prevState) {
        this.getUser();
    }

    componentWillMount() {
        var access_token_cloud = localStorage.getItem("access_token_cloud")
        var type = localStorage.getItem("type")
        var type_login = localStorage.getItem("type_login")
        this.setState({
            access_token_cloud: access_token_cloud,
            type: type,
            type_login: type_login
        })
    }


    checkSupervisor = () => {
        if (this.state.crrApi2 === undefined) return;
        fetch(`${this.state.crrApi2}/api/department/check_supervisor`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            }
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                this.setState({
                    supervisor: true
                });
            }
            else {
                this.setState({
                    supervisor: false
                });
            }
        })
    }


    render() {
        return (
            <>
            <div className="m-grid m-grid--hor m-grid--root m-page">
                <Header />
                <MenuBar />
                            <>
                            {/* <div className={"m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body "}> */}
                                <Route path="/kpiscreen" component={KPIScreen} />
                            {/* </div> */}
                            </>
                    {
                        (this.state.dataRole.indexOf(this.state.type + '/dashboard:view_all') !== -1 && this.state.listCompany.length > 1)
                            ?
                            <>
                                <Route path="/dashboardsa" component={DashboardSA} />
                            </>
                            :
                            ''
                    }
                    {
                        (this.state.dataRole.indexOf(this.state.type + '/dashboard:view_all') !== -1 || this.state.dataRole.indexOf(this.state.type + '/dashboard:view_by_manager') !== -1)
                            ?
                            <>
                                <Route path="/" exact component={Dashboard} />
                                <Route path="/dashboard" component={Dashboard} />
                            </>
                            :
                            ''
                    }
                    {
                        this.state.dataRole.indexOf(this.state.type + '/capture:view') !== -1
                            ?
                            <>
                                <Route path="/capture" component={Capture} />
                            </>
                            :
                            ''
                    }
                    {
                        this.state.dataRole.indexOf(this.state.type + '/daysoff_form:view') !== -1
                            ?
                            <Route path="/go-late-approval" component={GoLateApproval} />
                            :
                            ''
                    }
                    {
                        this.state.dataRole.indexOf(this.state.type + '/history:view_all') !== -1
                            ||
                            this.state.dataRole.indexOf(this.state.type + '/history:view_personal') !== -1
                            ?
                            <Route path="/history" component={History} />
                            :
                            ''
                    }
                    {
                        this.state.dataRole.indexOf(this.state.type + '/timekeeping:view_all') !== -1 || this.state.dataRole.indexOf(this.state.type + '/timekeeping:view_by_manager') !== -1
                            ?
                            <Route path="/timekeeping" component={Timekeeping} />
                            :
                            ''
                    }
                    {
                        this.state.dataRole.indexOf(this.state.type + '/daysoff_form:view') !== -1 &&
                        <>
                            <Route path="/leave-of-absence-letter" component={LeaveOfAbsenceLetter} />
                            <Route path="/signup-for-overtime" component={SignUpForOvertime} />
                            <Route path="/go-on-business" component={GoOnBusiness} />
                            <Route path="/go-late-leave-early" component={GoLateLeaveEarly} />
                            <Route path="/timekeeping-compensate" component={timekeepingCompensate} />
                            <Route path="/long-term" component={LongTerm} />
                        </>
                    }

                    {
                        this.state.dataRole.indexOf(this.state.type + '/config:insert_or_update') !== -1 &&
                        <Route path="/reason-off" component={reasonOff} />
                    }

                    {
                        (this.state.dataRole.indexOf(this.state.type + '/statistical_leave_of_absence:view') !== -1 || this.state.supervisor)
                            ?
                            <Route path="/statistical-leave-of-absence" component={StatisticalLeaveOfAbsence} />
                            :
                            ''
                    }
                    {
                        this.state.dataRole.indexOf(this.state.type + '/timekeeping:view_personal') !== -1
                            ?
                            <Route path="/timekeeping_personal" component={TimekeepingPersonal} />
                            :
                            ''
                    }
                    {
                        (this.state.dataRole.indexOf(this.state.type + '/department:view_all') !== -1 || this.state.dataRole.indexOf(this.state.type + '/department:view_by_manager') !== -1)
                            ?
                            <Route path="/department" component={Department} />
                            :
                            ''
                    }
                    {
                        this.state.dataRole.indexOf(this.state.type + '/door:view') !== -1
                            ?
                            <Route path="/door" component={Door} />
                            :
                            ''
                    }
                    {
                        this.state.dataRole.indexOf(this.state.type + '/door_management:view') !== -1
                            ?
                            <Route path="/door_management" component={DoorManagement} />
                            :
                            ''
                    }
                    {
                        this.state.dataRole.indexOf(this.state.type + '/sub_model:view') !== -1
                            ?
                            <Route path="/submodel" component={SubModel} />
                            :
                            ''
                    }
                    {
                        this.state.dataRole.indexOf(this.state.type + '/group:view') !== -1
                            ?
                            <Route path="/group" component={Group} />
                            :
                            ''
                    }
                    {
                        this.state.dataRole.indexOf(this.state.type + '/level:view') !== -1
                            ?
                            <Route path="/level" component={Level} />
                            :
                            ''
                    }
                    {
                        (this.state.dataRole.indexOf(this.state.type + '/staff:view_all') !== -1 || this.state.dataRole.indexOf(this.state.type + '/staff:view_by_manager') !== -1)
                            ?
                            <Route path="/staff" component={Staff} />
                            :
                            ''
                    }
                    {
                        this.state.dataRole.indexOf(this.state.type + '/shift_work:view') !== -1
                            ?
                            <Route path="/shift" component={Shift} />
                            :
                            ''
                    }
                    {
                        this.state.dataRole.indexOf(this.state.type + '/staff:update_password') !== -1
                            ?
                            <Route path="/changepassword" component={ChangePassword} />
                            :
                            ''
                    }
                    {
                        this.state.dataRole.indexOf(this.state.type + '/statistical:view') !== -1
                            ?
                            <Route path="/statistical" component={Statistical} />
                            :
                            ''
                    }
                    {
                        this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':insert_or_update') !== -1
                            ||
                            this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':get_info') !== -1
                            ?
                            <>
                                <Route path="/settings" component={Settings} />
                            </>
                            :
                            ''
                    }
                    {
                        this.state.dataRole.indexOf(this.state.type + '/warning_in_out:view') !== -1
                            ?
                            <Route path="/warning_in_out" component={WarningInOut} />
                            :
                            ''
                    }
                    {
                        this.state.dataRole.indexOf(this.state.type + '/appointment:view') != -1
                            ?
                            <Route path="/appointment" component={Appointment} />
                            :
                            ''
                    }
                    {
                        this.state.type_login == 1
                            ?
                            <Route path="/role" component={Role} />
                            :
                            ''
                    }

                </div>
                <Footer />
            
            </>
        );
    }
}
export default withRouter(ReactRouter);
