import { css } from '@emotion/core';
import $ from 'jquery';
import React, { Component } from 'react';
import Pagination from "react-js-pagination";
import { Redirect } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import swal from 'sweetalert';
import language from '../../../../language/language';
import Store from '../../../../store';
import ModalDepartment from "./ModalDepartment";
import axios from 'axios';
import equal from 'fast-deep-equal'
import { PulseLoader } from 'react-spinners';
import ReactGA from 'react-ga';

import { connect } from 'react-redux'
import Moment from 'moment'
import { formatTitleOption } from '../../CommonFunction';
import InfiniteScroll from 'react-infinite-scroll-component';
import Header from '../../../layouts/Header';
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;
const nameTab = 'department'
class Department extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogin: Store.getState().isLogin.isLogin,
            access_token: Store.getState().isLogin.access_token,
            crrApi2: Store.getState().boxai.box_engine_cf.crrApiSocket,
            box_engine_cf: Store.getState().boxai.box_engine_cf,
            idCompany: Store.getState().boxai.box_engine_cf.idCompany,
            language: Store.getState().changelanguage.language,
            dataRole: Store.getState().role.role,
            modalDepartment: false,
            listDepartment: [],
            crrDatas: [],
            activePage: 1,
            offset: 0,
            totalLength: 0,
            crrDepartment: {
                name: "",
                description: "",
                array_supervisor: [],
                departmentSelected: "",
                ot_time: ""
            },
            type: '',
            valueSearch: '',
            optionsShift: [],
            items: [],
            hasMore: true,
            index: 1
        };
        Store.subscribe(() => {
            this.setState({
                isLogin: Store.getState().isLogin.isLogin,
                access_token: Store.getState().isLogin.access_token,
                crrApi2: Store.getState().boxai.box_engine_cf.crrApiSocket,
                box_engine_cf: Store.getState().boxai.box_engine_cf,
                language: Store.getState().changelanguage.language,
                idCompany: Store.getState().boxai.box_engine_cf.idCompany,
                dataRole: Store.getState().role.role,
                listDepartment: [],
                crrDatas: [],
                activePage: 1,
                offset: 0,
                totalLength: 0,
                valueSearch: '',
                items: [],
                hasMore: true,
                index: 1
            }, () => {
                $('#inputSearch').val('')
                this.getDepartment()
                this.getShift()
                this.departmentArray()
            });
        });
        this.itemsPerPage = 10;
        this.getDepartment();
        this.getShift();
        this.departmentArray();
    }

    componentWillMount() {
        var type = localStorage.getItem("type")
        this.setState({
            type: type
        })

    }

    getDepartment = (dataReq) => {
        if (this.state.crrApi2 == undefined) return;
        fetch(`${this.state.crrApi2}/api/department/search`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            body: JSON.stringify(dataReq ? dataReq :
                {
                    id_company: this.state.box_engine_cf.idCompany,
                    index: 1,
                    item_per_page: this.itemsPerPage,
                    data_search: "",
                }
            )
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                console.log(data)
                this.setState({
                    crrDatas: [...data.data],
                    loading: false,
                    totalLength: data.count,
                }, () => {
                    this.FilterDepart(this.state.activePage)
                });
            } else {
                this.setState({
                    crrDatas: [],
                    loading: false,
                    totalLength: 0,
                })
            }
        })

    }



    FilterDepart = (activePage) => {
        const offset = (activePage - 1) * this.itemsPerPage;
        this.setState({
            offset: offset
        })
    }


    handlePageChange = (pageNumber) => {
        this.setState({
            ...this.state,
            activePage: pageNumber
        })
        const dataReq = {
            id_company: this.state.box_engine_cf.idCompany,
            index: pageNumber,
            item_per_page: this.itemsPerPage,
            data_search: "",
        }
        this.getDepartment(dataReq)
    }



    DeleteDepartment = async (department) => {
        let data = await fetch(`${this.state.crrApi2}/api/department/delete`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token,
            },
            body: JSON.stringify({
                'id': department.id
            })
        }).then((response) => {
            return response.json()
        });
        if (data.status === 10000) {
            ReactGA.event({
                category: 'Phong ban',
                action: 'Xoa phong ban',
            });
            swal(language[this.props.indexLanguage].Text_Notifi.success, language[this.props.indexLanguage].Text_Notifi.notifi_delete_success, "success", {
                buttons: false,
                timer: 1500,
            });
            this.getDepartment();
        }
        else {
            swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].Text_Notifi.notifi_delete_false, "error");
        }

    }

    modalClose = () => {
        this.setState({
            modalDepartment: false,
        });
        this.getDepartment();
    }

    getShift = () => {
        if (this.state.crrApi2 === undefined) return;
        fetch(`${this.state.crrApi2}/api/shift_work/${this.state.idCompany}/get_by_active/1`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                var optionsShift = this.state.optionsShift
                for (let index = 0; index < data.data.length; index++) {
                    optionsShift.push({ value: data.data[index].id, label: data.data[index].name + ' (' + Moment(data.data[index].time_in, "H:mm").format("HH:mm") + ' - ' + Moment(data.data[index].time_out, "H:mm").format("HH:mm") + ')' })
                }
                this.setState({
                    optionsShift: optionsShift,
                });
            }
        })
    }

    departmentArray = async () => {
        if (this.state.crrApi2 === undefined) return;
        let listDepartment = [];
        const data = await axios({
            method: 'GET',
            url: `${this.state.crrApi2}/api/department/${this.state.idCompany}/get_all`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: Store.getState().isLogin.access_token,
            }
        });
        if (data.data.status === 10000) {
            listDepartment = data.data.data.map(map => ({
                label: map.name, value: map.id
            }))

            this.setState({
                listDepartment: listDepartment,
                items: listDepartment.slice(0, 20)
            })
        }
    }

    btnSearch = (value) => {
        this.setState({
            activePage: 1
        }, () => {
            const dataReq = {
                id_company: this.state.box_engine_cf.idCompany,
                index: 1,
                item_per_page: this.itemsPerPage,
                data_search: value,
            }
            this.getDepartment(dataReq)
        })
    }

    isChange = (event) => {
        if (event.key === 'Enter') {
            this.btnSearch(event.target.value.trim())
        }
        this.setState({
            valueSearch: event.target.value.trim()
        });
    }

    fetchMoreData = () => {
        if (this.state.items.length >= this.state.listDepartment.length) {
            this.setState({ hasMore: false });
            return;
        }

        // this.setState({
        //     index: this.state.index + 1
        // }, () => {
        //     // a fake async api call like which sends
        //     // 20 more records in .5 secs

        // })
        setTimeout(() => {
            this.setState({
                // items: this.state.items.concat(this.state.listDepartment.slice())
                index: this.state.index + 1
            });
        }, 500);
    };


    render() {
        if (this.state.isLogin === false) {
            return (
                <Redirect to={'/login'} />
            )
        }

        let bulletedDepartment = this.state.crrDatas.slice(0, this.state.index * 20).map((e, i) => {
            var shift = this.state.optionsShift.filter((x => x.value === +e.description))
            return (
                <tr key={(i + 1)}>
                    <td>{(i + this.state.offset + 1)}</td>
                    <td>{formatTitleOption(e)}</td>
                    <td style={{ "whiteSpace": "normal" }}>{shift.length > 0 ? shift[0].label : ""}</td>

                    <td>{e.array_supervisor && e.array_supervisor.length > 0
                        ? e.array_supervisor.map((map, i) => (
                            <span key={i} className='px-1'>
                                {`${map.staff.name} / `}
                            </span>))
                        : ''}</td>
                    <td>{e.ot_time}</td>
                    {
                        this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':insert_or_update') !== -1
                            ||
                            this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':delete') !== -1
                            ?
                            <td>
                                {
                                    this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':insert_or_update') !== -1
                                        ?
                                        <>
                                            <button onClick={(e) => {
                                                var data = this.state.crrDatas
                                                var dataDepartment = {
                                                    id: data[i].id,
                                                    code: data[i].code,
                                                    name: data[i].name,
                                                    description: data[i].description,
                                                    array_supervisor: data[i].array_supervisor.map(map => ({
                                                        value: map.id_staff,
                                                        label: map.staff.name,
                                                    })),
                                                    id_company: this.state.box_engine_cf.idCompany,
                                                    HOST: this.state.crrApi2,
                                                    ot_time: data[i].ot_time
                                                };
                                                this.setState({
                                                    crrDepartment: dataDepartment,
                                                    modalDepartment: true,
                                                });


                                            }} className="m-portlet__nav-link btn m-btn m-btn--hover-warning m-btn--icon m-btn--icon-only m-btn--pill" data-tip data-for='Edit'>
                                                <i className="la la-edit" />
                                            </button>
                                            <ReactTooltip id='Edit' type='dark' effect='solid'>
                                                <span>{language[this.props.indexLanguage].tooltip.open_edit}</span>
                                            </ReactTooltip>

                                        </>
                                        :
                                        ""
                                }
                                {
                                    this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':delete') !== -1
                                        ?
                                        <>
                                            <button className="m-portlet__nav-link btn m-btn m-btn--hover-danger m-btn--icon m-btn--icon-only m-btn--pill" data-tip data-for='Delete'
                                                onClick={v => {
                                                    v.preventDefault();
                                                    swal({
                                                        title: language[this.props.indexLanguage].Text_Notifi.are_you_sure,
                                                        text: language[this.props.indexLanguage].Text_Notifi.notifi_sure_delete_department + e.name,
                                                        icon: "warning",
                                                        buttons: true,
                                                        dangerMode: true,
                                                    })
                                                        .then(willDelete => {
                                                            if (willDelete) {
                                                                this.DeleteDepartment(e);
                                                            }
                                                        });

                                                }}
                                            ><i className="la la-trash"></i></button>
                                            <ReactTooltip id='Delete' type='dark' effect='solid'>
                                                <span>{language[this.props.indexLanguage].tooltip.open_delete}</span>
                                            </ReactTooltip>
                                        </>
                                        :
                                        ""
                                }
                            </td>
                            :
                            ""
                    }
                </tr>
            )
        });

        var pageRangeDisplayed = 1;
        if ($(window).width() < 768) {
            pageRangeDisplayed = 3
        } else {
            pageRangeDisplayed = 5
        }

        return (
            <>
            <Header />
            <div className={"m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body "}>
            <div className="m-grid__item m-grid__item--fluid m-wrapper">
                <div className="m-content p-2">
                    <div className="m-portlet m-portlet--tab mb-0">
                        <div className="m-portlet__head p-3">
                            <div className="m-portlet__head-caption pl-3">
                                <div className="m-portlet__head-title">
                                    <h3 className="m-portlet__head-text">
                                        {language[this.props.indexLanguage].Menubar.departments}
                                    </h3>
                                </div>
                            </div>
                            <div className="m-portlet__head-tools pr-3">
                                <ul className="m-portlet__nav">
                                    <li className="m-portlet__nav-item">
                                        {
                                            this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':insert_or_update') !== -1
                                                ?
                                                <button onClick={(e) => {
                                                    this.setState({
                                                        crrDepartment: {
                                                            code: "",
                                                            name: "",
                                                            ot_time: null,
                                                            description: "",
                                                            array_supervisor: [],
                                                            id_company: this.state.box_engine_cf.idCompany,
                                                            HOST: this.state.crrApi2
                                                        },
                                                        modalDepartment: true,
                                                    });
                                                }} className="btn btn-accent m-btn m-btn--custom m-btn--icon m-btn--pill m-btn--air">
                                                    <span>
                                                        <i className="la la-plus" />
                                                        <span>{language[this.props.indexLanguage].textButton.add}</span>
                                                    </span>
                                                </button>
                                                :
                                                ""
                                        }
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="m-portlet__body m-portlet__body--no-padding">
                            <div className="row m-row--no-padding m-row--col-separator-xl">
                                <div className="col-xl-12">
                                    <div className="m-widget1 col-xl-8 mx-auto">
                                        <div className="row col-md-12 ml-0 mr-0 p-0 mb-2">
                                            <div className="col-lg-2 pl-0 m--margin-bottom-10-tablet-and-mobile">
                                                <input type="text" onKeyUp={(event) => this.isChange(event)} id="inputSearch" className="form-control m-input" placeholder={language[this.props.indexLanguage].placeholderInput.enter_name} data-col-index={0} />
                                            </div>
                                            <div>
                                                <button
                                                    onClick={() => {
                                                        this.btnSearch(this.state.valueSearch)
                                                    }}
                                                    className="btn btn-accent m-btn m-btn--icon" id="m_search">
                                                    <span>
                                                        <i className="la la-search" />
                                                        <span>{language[this.props.indexLanguage].textButton.search}</span>
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="table-responsive text-nowrap">
                                            <InfiniteScroll
                                                dataLength={this.state.crrDatas.slice(0, this.state.index * 20).length}
                                                next={this.fetchMoreData}
                                                hasMore={this.state.hasMore}
                                                loader={
                                                    <PulseLoader
                                                        css={override}
                                                        sizeUnit={"px"}
                                                        size={12}
                                                        margin={'2px'}
                                                        color={'#36D7B7'}
                                                        loading={this.state.hasMore}
                                                    />
                                                }>
                                                <table className="table table-bordered table-hover table-checkable dataTable no-footer dtr-inline collapsed">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ 'verticalAlign': 'middle', 'width': '100px' }}>{language[this.props.indexLanguage].Other.no}</th>
                                                            <th style={{ 'verticalAlign': 'middle' }}>{language[this.props.indexLanguage].textTable.name}</th>
                                                            <th style={{ 'verticalAlign': 'middle', 'width': '450px' }}>{language[this.props.indexLanguage].textTable.shift}</th>
                                                            <th style={{ 'verticalAlign': 'middle', 'width': '450px' }}>{language[this.props.indexLanguage].textTable.supervisor}</th>
                                                            <th style={{ 'verticalAlign': 'middle' }}>Thời gian tính OT</th>

                                                            {
                                                                this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':insert_or_update') !== -1
                                                                    ||
                                                                    this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':delete') !== -1
                                                                    ?
                                                                    <th style={{ 'verticalAlign': 'middle', 'width': '150px' }}>{language[this.props.indexLanguage].textTable.action}</th>
                                                                    :
                                                                    ""
                                                            }

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {bulletedDepartment}
                                                    </tbody>
                                                </table>
                                            </InfiniteScroll>
                                            <ModalDepartment
                                                data={this.state.crrDepartment}
                                                show={this.state.modalDepartment}
                                                onHide={this.modalClose}
                                            />
                                        </div>
                                        {/* <span>
                                            Showing {this.state.showFirst} to {this.state.showLast} of {this.state.totalLength} entries
                                        </span> */}
                                        {/* <Pagination
                                            firstPageText={language[this.props.indexLanguage].pagination.first}
                                            nextPageText={language[this.props.indexLanguage].pagination.next}
                                            lastPageText={language[this.props.indexLanguage].pagination.last}
                                            prevPageText={language[this.props.indexLanguage].pagination.previous}
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={this.itemsPerPage}
                                            totalItemsCount={this.state.totalLength}
                                            pageRangeDisplayed={pageRangeDisplayed}
                                            onChange={this.handlePageChange}
                                        /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </>
        );
    }
}
const mapStateToProps = state => {
    return {
        indexLanguage: state.changelanguage.indexLanguage,
    }
}
export default connect(mapStateToProps, null)(Department);
