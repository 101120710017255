import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import $ from 'jquery';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { NavLink, Redirect } from 'react-router-dom';
import { setbox } from '../../actions/boxai';
import { logout, login } from '../../actions/isLogin';
import { role } from '../../actions/role';
import { ARRAY_ENGINES, HOST_CLOUD } from '../../config';
import language from '../../language/language';
import Store from '../../store';
import Notifications from '../Notifications';
import ReactGA from 'react-ga'
import { connect } from 'react-redux'

const nameTab = 'config'
class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // crrAutocomplete: { title: 'Monty Python and the Holy Grail', year: 1975 },
            crrcompany: { title: '' },
            crrCamera: { title: '' },
            listCompany: [],
            dataCamera: [],
            dataRole: [],
            user: null,
            isLogin: Store.getState().isLogin.isLogin,
            access_token: Store.getState().isLogin.access_token,
            language: Store.getState().changelanguage.language,
            access_token_cloud: '',
            refresh_token: '',
            type: '',
            valueCompanyLocalStorage: null,
            logo_link: ""
        };
        Store.subscribe(() => {
            this.setState({
                language: Store.getState().changelanguage.language,
            });
        });
    }

    //WARNING! To be deprecated in React v17. Use componentDidMount instead.
    componentWillMount() {
        if (!localStorage.getItem("language")) {
            localStorage.setItem("language", "vn");
            window.location.href = "/";
        }
        var access_token_cloud = localStorage.getItem("access_token_cloud")
        var refresh_token = localStorage.getItem("refresh_token")
        var valueCompanyLocalStorage = localStorage.getItem("valueCompany")
        var type = localStorage.getItem("type")
        this.setState({
            access_token_cloud: access_token_cloud,
            type: type,
            refresh_token: refresh_token,
            valueCompanyLocalStorage: valueCompanyLocalStorage
        }, () => {
            this.refreshToken();
            // this.getUser();
        })
    }

    componentDidMount() {
        // setInterval(() => {
        //     this.refreshToken();
        // }, 180000);
    }

    refreshToken = () => {
        if (Store.getState().isLogin.isLogin === true) {
            fetch(`${HOST_CLOUD}/api/refresh_token`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": this.state.refresh_token
                }
            }).then((response) => {
                return response.json()
            }).then((result) => {
                localStorage.setItem("access_token_cloud", "Bearer " + result.access_token)
                localStorage.setItem("refresh_token", "Bearer " + result.refresh_token)
                Store.dispatch(login("Bearer " + result.access_token));
                this.getUser(result.access_token);
                this.setState({
                    refresh_token: "Bearer " + result.refresh_token
                });
            }).catch((error) => {
                if (error) {
                    console.log(error)
                    // this.setState({
                    //     isLogin: false
                    // })
                    // localStorage.clear();
                    // Store.dispatch(logout());
                }
            });
        }
    }



    getUser = (access_token) => {
        if (Store.getState().isLogin.isLogin === true) {
            fetch(`${HOST_CLOUD}/api/user/biface_getbytoken`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + access_token
                }
            }).then((response) => {
                if (response.status === 401) {
                    this.setState({
                        isLogin: false
                    }, () => {
                        localStorage.clear();
                        Store.dispatch(logout());
                    })
                }
                return response.json();
            }).then((result) => {
                if (result.status === 10000) {
                    if (result.data.roles !== undefined) {
                        // if (result.data.roles.indexOf(this.state.type + '/dashboard:view') === -1 && result.data.roles.indexOf(this.state.type + '/timekeeping:view_personal') !== -1) {
                        //     this.props.history.push('/timekeeping_personal');
                        // }
                        const dataUser = {
                            id_staff_notification: result.data.id_staff,
                            id_staff: result.data.id,
                            id_department: result.data.id_department,
                            object_id: result.data.object_id,
                            name: result.data.name,
                        }
                        localStorage.setItem("id_user", result.data.id);
                        localStorage.setItem('data_user', JSON.stringify(dataUser));
                        this.setState({
                            user: result.data,
                            dataRole: result.data.roles,
                        }, () => {
                            this.getCompany();
                            Store.dispatch(role(result.data.roles));
                        })
                    } else {
                        this.setState({
                            dataRole: result.data,
                        })
                        this.getCompany();
                        Store.dispatch(role(result.data));
                    }
                }
                else {
                    this.setState({
                        isLogin: false,
                        user: null
                    })
                    localStorage.clear();
                    Store.dispatch(logout());
                }
            }).catch((error) => {
                if (error) {
                    this.setState({
                        isLogin: false
                    })
                    localStorage.clear();
                    Store.dispatch(logout());
                }
            });
        }
    }

    getCompany = () => {
        fetch(`${HOST_CLOUD}/api/company/getbyuser2`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            }
        }).then((response) => {
            return response.json()
        }).then(async (data) => {
            if (data.status === 10000) {
                for (let index = 0; index < data.companies.length; index++) {
                    for (let i = 0; i < data.companies[index].boxes_engine.length; i++) {
                        if (!ARRAY_ENGINES.includes(data.companies[index].boxes_engine[i].sub_engine_id + '')) {
                            data.companies[index].boxes_engine.splice(i, 1);
                            i--;
                        }
                        if (data.companies[index].boxes_engine[i] !== undefined) {
                            if (data.companies[index].boxes_engine[i].api_url !== null) {
                                var dataApiSplit = data.companies[index].boxes_engine[i].api_url.split(';')
                                if (dataApiSplit.length === 1) {
                                    data.companies[index].boxes_engine[i].api_url = dataApiSplit[0];
                                    data.companies[index].boxes_engine[i].api2_url_socket = data.companies[index].boxes_engine[i].api2_url;
                                    data.companies[index].boxes_engine[i].api_img = dataApiSplit[0];
                                } else if (dataApiSplit.length == 3) {
                                    data.companies[index].boxes_engine[i].api_url = dataApiSplit[0];
                                    data.companies[index].boxes_engine[i].api2_url = dataApiSplit[1];
                                    data.companies[index].boxes_engine[i].api2_url_socket = dataApiSplit[2];
                                    data.companies[index].boxes_engine[i].api_img = dataApiSplit[0];
                                } else if (dataApiSplit.length === 4) {
                                    data.companies[index].boxes_engine[i].api_url = dataApiSplit[0];
                                    data.companies[index].boxes_engine[i].api2_url = dataApiSplit[1];
                                    data.companies[index].boxes_engine[i].api2_url_socket = dataApiSplit[2];
                                    data.companies[index].boxes_engine[i].api_img = dataApiSplit[3];
                                }
                                break;
                            }
                        }
                    }
                }
                var urlCam = ""
                if (this.state.valueCompanyLocalStorage === null) {
                    if (data.companies[0] !== undefined) {
                        if (data.companies[0].boxes_engine[0] !== undefined) {
                            if (data.companies[0].boxes_engine[0].view_url_local != "") {
                                urlCam = data.companies[0].boxes_engine[0].view_url_local + "?id=" + data.companies[0].boxes_engine[0].id_box_engine
                            }
                            localStorage.setItem('not_company', data.companies[0].name);
                            this.setState({
                                listCompany: data.companies,
                                crrcompany: { title: data.companies[0].name, value: data.companies[0].id_company, dataBox: data.companies[0].boxes_engine },
                                crrCameraAll: {
                                    nameCompany: data.companies[0].name,
                                    idCompany: data.companies[0].id_company,
                                    crrApi: data.companies[0].boxes_engine[0].api_url,
                                    crrApiSocket: data.companies[0].boxes_engine[0].api2_url,
                                    crrApiSocket2: data.companies[0].boxes_engine[0].api2_url_socket,
                                    crrApiImg: data.companies[0].boxes_engine[0].api_img,
                                    urlCam: urlCam,
                                    url: data.companies[0].boxes_engine[0].view_url_local,
                                    dataCamera: data.companies[0].boxes_engine,

                                    dataCompanyChild: data.companies[0].child,
                                    crrCamera: { title: data.companies[0].boxes_engine[0].id_box + '_' + data.companies[0].boxes_engine[0].id_box_engine, value: data.companies[0].boxes_engine[0].id_box_engine, api: data.companies[0].boxes_engine[0].api_url, api2: data.companies[0].boxes_engine[0].api2_url, url: data.companies[0].boxes_engine[0].view_url_local }
                                },
                                dataCamera: data.companies[0].boxes_engine,
                                logo_link: data.companies[0].logo_link !== undefined ? data.companies[0].logo_link : "/img/logo_biface.png",
                            }, () => {
                                Store.dispatch(setbox(this.state.crrCameraAll, this.state.listCompany, this.state.crrCameraAll.idCompany));
                            })
                        } else {
                            localStorage.setItem('not_company', data.companies[0].name)
                            this.setState({
                                listCompany: data.companies,
                                crrcompany: { title: data.companies[0].name, value: data.companies[0].id_company, dataBox: data.companies[0].boxes_engine },
                                crrCameraAll: {
                                    nameCompany: data.companies[0].name,
                                    idCompany: data.companies[0].id_company,
                                    crrApi: "",
                                    crrApiSocket: "",
                                    crrApiSocket2: "",
                                    crrApiImg: "",
                                    urlCam: "",
                                    url: "",
                                    dataCamera: [],
                                    crrCamera: { title: "", value: "", url: "" }
                                },
                                dataCamera: data.companies[0].boxes_engine,
                                logo_link: data.companies[0].logo_link !== undefined ? data.companies[0].logo_link : "/img/logo_biface.png",

                            }, () => {
                                Store.dispatch(setbox(this.state.crrCameraAll, this.state.listCompany, this.state.crrCameraAll.idCompany));
                            })
                        }
                    }

                } else {
                    var dataCompanyFilter = data.companies.filter((value) => {
                        return value.id_company == this.state.valueCompanyLocalStorage
                    })
                    if (dataCompanyFilter[0] !== undefined) {
                        if (dataCompanyFilter[0].boxes_engine[0] !== undefined) {
                            if (dataCompanyFilter[0].boxes_engine[0].view_url_local != "") {
                                urlCam = dataCompanyFilter[0].boxes_engine[0].view_url_local + "?id=" + dataCompanyFilter[0].boxes_engine[0].id_box_engine
                            }
                            localStorage.setItem('not_company', dataCompanyFilter[0].name)
                            this.setState({
                                listCompany: data.companies,
                                crrcompany: { title: dataCompanyFilter[0].name, value: dataCompanyFilter[0].id_company, dataBox: dataCompanyFilter[0].boxes_engine },
                                crrCameraAll: {
                                    nameCompany: dataCompanyFilter[0].name,
                                    idCompany: dataCompanyFilter[0].id_company,
                                    crrApi: dataCompanyFilter[0].boxes_engine[0].api_url,
                                    crrApiSocket: dataCompanyFilter[0].boxes_engine[0].api2_url,
                                    crrApiSocket2: dataCompanyFilter[0].boxes_engine[0].api2_url_socket,
                                    crrApiImg: dataCompanyFilter[0].boxes_engine[0].api_img,
                                    urlCam: urlCam,
                                    url: dataCompanyFilter[0].boxes_engine[0].view_url_local,
                                    dataCamera: dataCompanyFilter[0].boxes_engine,

                                    dataCompanyChild: dataCompanyFilter[0].child,
                                    crrCamera: { title: dataCompanyFilter[0].boxes_engine[0].id_box + '_' + dataCompanyFilter[0].boxes_engine[0].id_box_engine, value: dataCompanyFilter[0].boxes_engine[0].id_box_engine, api: dataCompanyFilter[0].boxes_engine[0].api_url, api2: dataCompanyFilter[0].boxes_engine[0].api2_url, url: dataCompanyFilter[0].boxes_engine[0].view_url_local }
                                },
                                dataCamera: dataCompanyFilter[0].boxes_engine,
                                logo_link: dataCompanyFilter[0].logo_link !== undefined ? dataCompanyFilter[0].logo_link : "/img/logo_biface.png",
                            }, () => {
                                Store.dispatch(setbox(this.state.crrCameraAll, this.state.listCompany, this.state.crrCameraAll.idCompany));
                            })
                        } else {
                            localStorage.setItem('not_company', dataCompanyFilter[0].name);
                            this.setState({
                                listCompany: data.companies,
                                crrcompany: { title: dataCompanyFilter[0].name, value: dataCompanyFilter[0].id_company, dataBox: dataCompanyFilter[0].boxes_engine },
                                crrCameraAll: {
                                    nameCompany: dataCompanyFilter[0].name,
                                    idCompany: dataCompanyFilter[0].id_company,
                                    crrApi: "",
                                    crrApiSocket: "",
                                    crrApiSocket2: "",
                                    crrApiImg: "",
                                    urlCam: "",
                                    url: "",
                                    dataCamera: [],
                                    crrCamera: { title: "", value: "", url: "" }
                                },
                                dataCamera: dataCompanyFilter[0].boxes_engine,
                                logo_link: dataCompanyFilter[0].logo_link !== undefined ? dataCompanyFilter[0].logo_link : "/img/logo_biface.png",
                            }, () => {
                                Store.dispatch(setbox(this.state.crrCameraAll, this.state.listCompany, this.state.crrCameraAll.idCompany));
                            })
                        }
                    }
                }

            }
        })
    }

    changeHandleCompany = (value, e) => {
        ReactGA.event({
            category: 'Thay doi cong ty',
            action: 'Thay doi cong ty',
            label: 'Button Change Comapany'
        });
        var crrCameraAll = this.state.crrCameraAll;
        localStorage.setItem("valueCompany", e.value);
        localStorage.setItem("not_company", e.title);

        if (e.dataBox.length > 0) {
            crrCameraAll.crrCamera = { title: e.dataBox[0].id_box + '_' + e.dataBox[0].id_box_engine, value: e.dataBox[0].id_box_engine, url: e.dataBox[0].view_url_local };
            crrCameraAll.crrApi = e.dataBox[0].api_url;
            crrCameraAll.crrApiSocket = e.dataBox[0].api2_url;
            crrCameraAll.crrApiSocket2 = e.dataBox[0].api2_url_socket;
            crrCameraAll.url = e.dataBox[0].view_url_local;
            crrCameraAll.dataCamera = e.dataBox;
            crrCameraAll.dataCompanyChild = e.dataCompanyChild;
            crrCameraAll.idCompany = e.value;
            crrCameraAll.nameCompany = e.title;
            if (crrCameraAll.url != "") {
                crrCameraAll.urlCam = e.dataBox[0].view_url_local + "?id=" + e.dataBox[0].id_box_engine;
            } else {
                crrCameraAll.urlCam = ""
            }
            Store.dispatch(setbox(crrCameraAll, null, this.state.crrCameraAll.idCompany));
            this.setState({
                crrcompany: e,
                dataCamera: e.dataBox,
                crrCamera: { title: e.dataBox[0].id_box + '_' + e.dataBox[0].id_box_engine, value: e.dataBox[0].id_box_engine, url: e.dataBox[0].view_url_local }
            })
        } else {
            crrCameraAll.dataCamera = [];
            crrCameraAll.crrCamera = "";
            crrCameraAll.crrApi = "";
            crrCameraAll.crrApiSocket = "";
            crrCameraAll.crrApiSocket2 = "";
            crrCameraAll.url = "";
            crrCameraAll.urlCam = ""
            Store.dispatch(setbox(crrCameraAll, null, ''));
            this.setState({
                crrcompany: e,
                dataCamera: e.dataBox,
                crrCamera: { title: '' }
            })
        }
    }

    onClickOpen = () => {
        $('#m_aside_left').addClass('m-aside-left--on')
        $('#body_form').addClass('m-aside-left--on')
        $('#m_aside_left_toggle').addClass('m-aside-left-toggler--active')
        $('#root').append(
            $('<div/>', {
                'class': 'm-aside-left-overlay',
            }).on({
                'click': function () {
                    $('#m_aside_left').removeClass('m-aside-left--on')
                    $('#body_form').removeClass('m-aside-left--on')
                    $('#m_aside_left_toggle').removeClass('m-aside-left-toggler--active')
                    $('.m-aside-left-overlay').remove()
                }
            })
        )
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.dataRole !== this.state.dataRole) {
            var partname = this.props.location.pathname;
            if (partname === '/') {
                if (this.state.dataRole.indexOf(this.state.type + '/dashboard:view') !== -1) {
                    return this.props.history.push('dashboard')
                } else {
                    return this.props.history.push('timekeeping_personal')
                }
            }
        }
    }

    render() {
        var partname = this.props.location.pathname;
        // var dislable_select_option = 'd-none';
        // if (partname === "/dashboard" || partname === "/warning_in_out" || partname === "/" || partname === "/viewcamera" || partname === "/timekeeping" || partname === "/submodel" || partname === "/history" || partname === "/statistical" || partname === "/capture" || partname === "/attendance_student") {
        //     dislable_select_option = '';
        // }
        if (this.state.isLogin === false) {
            return (
                <Redirect to={'/login'} />
            )
        }

        var dislable_select_boxenigne = '';

        if (partname === "/timekeeping" || partname === "/submodel") {
            dislable_select_boxenigne = 'd-none';
        }

        var options = [];
        for (var i = 0; i < this.state.listCompany.length; i++) {
            options.push(
                { title: this.state.listCompany[i].name, value: this.state.listCompany[i].id_company, dataBox: this.state.listCompany[i].boxes_engine, dataCompanyChild: this.state.listCompany[i].child })
        }
        return (
            <header id="m_header" className="m-grid__item m-header" m-minimize="minimize" m-minimize-mobile="minimize" m-minimize-offset={200} m-minimize-mobile-offset={200}>
                <div className="m-container m-container--fluid m-container--full-height">
                    <div className="m-stack m-stack--ver m-stack--desktop  m-header__wrapper">
                        <div className="m-stack__item m-brand m-brand--mobile">
                            <div className="m-stack m-stack--ver m-stack--general">
                                <div className="m-stack__item m-stack__item--middle m-brand__logo">
                                    <a href="/dashboard" className="m-brand__logo-wrapper">
                                        <img alt="" src={this.state.logo_link} style={{ 'width': '160px' }} />
                                    </a>
                                </div>
                                <div className="m-stack__item m-stack__item--middle m-brand__tools">
                                    {/* BEGIN: Responsive Aside Left Menu Toggler */}
                                    <a href="#/" id="m_aside_left_toggle_mobile" className="m-brand__icon m-brand__toggler m-brand__toggler--left">
                                        <span />
                                    </a>
                                    {/* END */}
                                    {/* BEGIN: Topbar Toggler */}
                                    <a id="m_aside_header_topbar_mobile_toggle" href="#/" className="m-brand__icon">
                                        <i className="flaticon-more" />
                                    </a>
                                    {/* BEGIN: Topbar Toggler */}
                                </div>
                            </div>
                        </div>
                        <div className="m-stack__item m-stack__item--middle m-stack__item--left m-header-head" id="m_header_nav">
                            <div className="m-stack m-stack--ver m-stack--desktop">
                                <div className="m-stack__item m-stack__item--middle m-stack__item--fit">
                                    {/* BEGIN: Aside Left Toggle */}
                                    <a className="m-aside-left-toggler m-aside-left-toggler--left m_aside_left_toggler" onClick={() => this.onClickOpen()}>
                                        <span />
                                    </a>
                                    {/* END: Aside Left Toggle */}
                                </div>
                            </div>
                        </div>
                        <div className="m-stack__item m-stack__item--middle m-stack__item--center" style={{ 'width': '160px' }}>
                            {
                                this.state.dataRole.indexOf(this.state.type + '/dashboard:view') === -1 &&
                                    this.state.dataRole.indexOf(this.state.type + '/timekeeping:view_personal') !== -1
                                    ?
                                    <a href="/timekeeping_personal" className="m-brand m-brand--desktop">
                                        <img alt="" src={this.state.logo_link} style={{ 'width': '100%' }} />
                                    </a>
                                    :
                                    <a href="/dashboard" className="m-brand m-brand--desktop">
                                        <img alt="" src={this.state.logo_link} style={{ 'width': '100%' }} />
                                    </a>
                            }
                        </div>
                        <div className="m-stack__item m-stack__item--right">
                            <div id="m_header_topbar" className="m-topbar  m-stack m-stack--ver m-stack--general width-100">
                                <div className="m-stack__item m-topbar__nav-wrapper">
                                    <ul className="m-topbar__nav m-nav m-nav--inline width-100 ml-0">
                                        <li className={"m-nav__item m-topbar__user-profile m-topbar__user-profile--img select_company "}>
                                            {
                                                partname !== '/dashboardsa' &&
                                                <Autocomplete
                                                    options={options}
                                                    getOptionLabel={option => option.title}
                                                    value={this.state.crrcompany}
                                                    onChange={this.changeHandleCompany}
                                                    disableClearable
                                                    renderInput={params => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            placeholder="Company"
                                                            fullWidth
                                                        />
                                                    )}
                                                />
                                            }
                                        </li>
                                        <li className='m-nav__item m-topbar__user-profile'>
                                            <Notifications />
                                        </li>
                                        <li className="m-nav__item m-dropdown m-dropdown--medium m-dropdown--arrow  m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light icon_logout" m-dropdown-toggle="click">
                                            <a href="#" className="m-nav__link m-dropdown__toggle">
                                                <span className="m-topbar__userpic">
                                                    <img src="/img/photo-placeholder.png" className="m--img-rounded m--marginless m--img-centered" alt="" />
                                                </span>
                                                <span className="m-nav__link-icon m-topbar__usericon  m--hide">
                                                    <span className="m-nav__link-icon-wrapper"><i className="flaticon-user-ok" /></span>
                                                </span>
                                            </a>
                                            <div className="m-dropdown__wrapper">
                                                <span className="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust" />
                                                <div className="m-dropdown__inner">
                                                    <div className="m-dropdown__header m--align-center">
                                                        <div className="m-card-user m-card-user--skin-light">
                                                            <div className="m-card-user__pic">
                                                                <img src="/img/photo-placeholder.png" className="m--img-rounded m--marginless" alt="" />
                                                            </div>
                                                            <div className="m-card-user__details">
                                                                <span className="m-card-user__name m--font-weight-500">{this.state.user == null ? "Super Admin" : this.state.user.name + ' ( ' + this.state.user.abbreviated_name + ' )'}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="m-dropdown__body">
                                                        <div className="m-dropdown__content">
                                                            <ul className="m-nav m-nav--skin-light">
                                                                {
                                                                    this.state.dataRole.indexOf(this.state.type + '/staff:update_password') !== -1
                                                                        ?
                                                                        <li className="m-nav__item">
                                                                            <NavLink to="/changepassword" className="m-nav__link " activeClassName="m-menu__item--active">
                                                                                <i className="m-nav__link-icon flaticon-profile-1" />
                                                                                <span className="m-nav__link-title">
                                                                                    <span className="m-nav__link-wrap">
                                                                                        <span className="m-nav__link-text">{language[this.props.indexLanguage].Infor.my_profile}</span>
                                                                                    </span>
                                                                                </span>
                                                                            </NavLink>
                                                                        </li>
                                                                        :
                                                                        ""
                                                                }
                                                                <li className="m-nav__separator m-nav__separator--fit">
                                                                </li>
                                                                <li className="m-nav__item">
                                                                    <a className="btn m-btn--pill btn-secondary m-btn m-btn--custom m-btn--label-brand m-btn--bolder"
                                                                        onClick={() => {
                                                                            ReactGA.event({
                                                                                category: 'User profile',
                                                                                action: 'Dang xuat',
                                                                            });
                                                                            localStorage.removeItem("access_token");
                                                                            localStorage.removeItem("data_user");
                                                                            localStorage.removeItem("id_user");
                                                                            localStorage.removeItem("not_company");
                                                                            // localStorage.removeItem("language");
                                                                            window.location.href = "/login";
                                                                        }}
                                                                    >{language[this.props.indexLanguage].Infor.logout}</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}
const mapStateToProps = state => {
    return {
        indexLanguage: state.changelanguage.indexLanguage,
    }
}

export default withRouter(connect(mapStateToProps, null)(Header));