import { css } from '@emotion/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import $ from 'jquery';
import Moment from 'moment';
import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import Pagination from "react-js-pagination";
import { Redirect } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import ReactGA from 'react-ga';
import swal from 'sweetalert';
import language from '../../../../language/language';
import Store from '../../../../store';
import ModalDoorOpen from "./ModalDoorOpen";
import { connect } from 'react-redux'
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import Header from '../../../layouts/Header';
const name_door = 'door_management'

class DoorOpen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogin: Store.getState().isLogin.isLogin,
            access_token: Store.getState().isLogin.access_token,
            crrApi2: Store.getState().boxai.box_engine_cf.crrApiSocket,
            language: Store.getState().changelanguage.language,
            box_engine_cf: Store.getState().boxai.box_engine_cf,
            dataRole: Store.getState().role.role,
            type: '',
            modalDoorOpen: false,
            isChecked: false,
            listDoorOpen: [],
            optionStaff: [],
            listStaff: [],
            valueStaff: "",
            listDoor: [],
            crrDatas: [],
            activePage: 1,
            offset: 0,
            indexlanguage: 0,
            crrDoorOpen: {
                box_engine_id: "",
                name: "",
                description: "",
                is_active: 0
            },
            start_date: new Date(),
            end_date: null,
            data_json_export: [],
        };
        Store.subscribe(() => {
            this.setState({
                isLogin: Store.getState().isLogin.isLogin,
                access_token: Store.getState().isLogin.access_token,
                crrApi2: Store.getState().boxai.box_engine_cf.crrApiSocket,
                language: Store.getState().changelanguage.language,
                box_engine_cf: Store.getState().boxai.box_engine_cf,
                listDoorOpen: [],
                optionStaff: [],
                listStaff: [],
                valueStaff: { value: -1, title: language[this.props.indexLanguage].placeholderSelect.all_staff, object_id: "0" },
                listDoor: [],
                crrDatas: [],
                activePage: 1,
                offset: 0,
            }, () => {
                this.getData()
            });
        });
        this.itemsPerPage = 10;
        this.getData()
    }

    componentWillMount() {
        var type = localStorage.getItem("type")
        this.setState({
            valueStaff: { value: -1, title: language[this.props.indexLanguage].placeholderSelect.all_staff, object_id: "0" },
            type: type
        })
    }

    getData = () => {
        this.filterDoorCalendar()
        this.getDoor()
        this.getStaff()
    }

    getDoorOpen = () => {
        if (this.state.crrApi2 == undefined) return;
        fetch(`${this.state.crrApi2}/api/door_calendar/${this.state.box_engine_cf.idCompany}/get_all`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                this.setState({
                    listDoorOpen: [...data.data],
                });
                this.FilterDoorOpen(this.state.activePage)
            }
        })
    }

    getDoor = () => {
        if (this.state.crrApi2 == undefined) return;
        fetch(`${this.state.crrApi2}/api/door/${this.state.box_engine_cf.idCompany}/get_by_active`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                this.setState({
                    listDoor: [...data.data],
                });
            }
        })
    }

    FilterDoorOpen(activePage) {
        const { listDoorOpen } = this.state;
        const offset = (activePage - 1) * this.itemsPerPage;
        const crrDatas = listDoorOpen.slice(offset, offset + this.itemsPerPage);

        this.setState({
            crrDatas,
            offset: offset
        })
    }

    handlePageChange = (pageNumber) => {
        this.setState({
            ...this.state,
            activePage: pageNumber
        })
        this.FilterDoorOpen(pageNumber);

    }

    DeleteDoorOpen = async (DoorOpen) => {
        let data = await fetch(`${this.state.crrApi2}/api/door_calendar/delete`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token,
            },
            body: JSON.stringify({
                'id': DoorOpen.id
            })
        }).then((response) => {
            return response.json()
        });
        if (data.status === 10000) {
            ReactGA.event({
                category: 'Quyen mo cua',
                action: 'Xoa quyen mo cua',
              });
            swal(language[this.props.indexLanguage].Text_Notifi.success, language[this.props.indexLanguage].Text_Notifi.notifi_delete_success, "success", {
                buttons: false,
                timer: 1500,
            });
            this.filterDoorCalendar()
        }
        else {
            swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].Text_Notifi.notifi_delete_false, "error");
        }

    }

    handleChangeStaff = (index, value) => {
        this.setState({
            valueStaff: value,
        })
    }

    getDoorByStaff = (id) => {
        if (this.state.crrApi2 == undefined) return;
        fetch(`${this.state.crrApi2}/api/staff/get_door_calendar/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                var listDoorOpen = [];
                for (let index = 0; index < data.data.length; index++) {
                    listDoorOpen.push(data.data[index].door_calendar)
                }
                this.setState({
                    listDoorOpen: listDoorOpen,
                });
                this.FilterDoorOpen(this.state.activePage)
            }
        })
    }

    getStaff = () => {
        if (this.state.crrApi2 == undefined) return;
        fetch(`${this.state.crrApi2}/api/staff/${this.state.box_engine_cf.idCompany}/get_all`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                var optionStaff = [{ 'value': -1, 'title': language[this.props.indexLanguage].placeholderSelect.all_staff }]
                for (let index = 0; index < data.data.length; index++) {
                    optionStaff.push({ 'value': data.data[index].id, 'title': data.data[index].name + ' (' + data.data[index].id_staff + ')', 'object_id': data.data[index].object_id })
                }
                this.setState({
                    listStaff: [...data.data],
                    optionStaff: optionStaff
                });
            }
        })
    }

    modalClose = () => {
        this.setState({
            modalDoorOpen: false,
        });
        console.log("close")
        this.filterDoorCalendar()
    }

    reset = (e) => {
        this.setState({
            activePage: 1,
            crrDatas: [],
            loading: true,
            start_date: new Date(),
            end_date: null,
            valueStaff: { value: -1, title: language[this.props.indexLanguage].placeholderSelect.all_staff, object_id: "0" },
        }, () => {
            this.filterDoorCalendar()
        });
    }

    _exporter;
    ExportExcel = () => {
        let startDate = new Date(Moment(this.state.start_date).format('YYYY-MM-DD'))
        let endDate = this.state.end_date !== null ? new Date(Moment(this.state.end_date).format('YYYY-MM-DD')) : new Date(Moment().format('YYYY-MM-DD')) 
        let diffTime = Math.abs(endDate - startDate);
        let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
        if (diffDays > 31) {
            swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].Text_Notifi.notifi_time_export_door, "warning");
        } else {
        fetch(`${this.state.crrApi2}/api/door_calendar/search`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token,
            },
            body: JSON.stringify({
                "id_company": this.state.box_engine_cf.idCompany,
                "start_date": Moment(this.state.start_date).format('YYYY-MM-DD'),
                "end_date": this.state.end_date !== null ? Moment(this.state.end_date).format('YYYY-MM-DD') : this.state.end_date,
                "index": 0,
                "id_staff": this.state.valueStaff.value,
                "item_per_page": 0
            })
        }).then((response) => {
            return response.json()
        }).then((dataRes) => {
            if (dataRes.status === 10000) {
                for (let i = 0; i < dataRes.data.length; i++) {
                    var DayOfWeek = Array.prototype.filter.call(dataRes.data[i].day_of_week, eachChar => eachChar !== '|');
                    var DayOfWeekText = [];
                    for (let index = 0; index < DayOfWeek.length; index++) {
                        var day_name = "";
                        switch (DayOfWeek[index]) {
                            case '0':
                                day_name = language[this.props.indexLanguage].DayOfWeek.monday;
                                break;
                            case '1':
                                day_name = language[this.props.indexLanguage].DayOfWeek.tuesday;
                                break;
                            case '2':
                                day_name = language[this.props.indexLanguage].DayOfWeek.wednesday;
                                break;
                            case '3':
                                day_name = language[this.props.indexLanguage].DayOfWeek.thursday;
                                break;
                            case '4':
                                day_name = language[this.props.indexLanguage].DayOfWeek.friday;
                                break;
                            case '5':
                                day_name = language[this.props.indexLanguage].DayOfWeek.saturday;
                                break;
                            case '6':
                                day_name = language[this.props.indexLanguage].DayOfWeek.sunday;
                        };

                        DayOfWeekText.push(day_name)
                    }

                    let nameStaff = ""
                    if (dataRes.data[i].door_open_obj && dataRes.data[i].door_open_obj.groups.length > 0) {
                        nameStaff = dataRes.data[i].door_open_obj.groups.map((group => {
                            return group.name
                        })).join(', ')
                    }
                    if (dataRes.data[i].door_open_obj && dataRes.data[i].door_open_obj.department_levels.length > 0) {
                        nameStaff = dataRes.data[i].door_open_obj.department_levels.map((department => {
                            return department.department.name
                        })).join(', ')
                    }
                    if (dataRes.data[i].door_open_obj && dataRes.data[i].door_open_obj.staffs.length > 0) {
                        nameStaff = dataRes.data[i].door_open_obj.staffs.map((name => {
                            return name.name
                        })).join(', ')
                    }
                    let renderText = ""
                    if (dataRes.data[i].door_open_obj && (dataRes.data[i].door_open_obj.department_levels.length === 1 && dataRes.data[i].door_open_obj.department_levels[0].department.id === -1)) {
                        renderText = language[this.props.indexLanguage].textTable.all
                    } else {
                        renderText = nameStaff
                    }

                    if (dataRes.data[i].door.name !== null) {
                        dataRes.data[i]['door'] = dataRes.data[i].door.name
                    } else {
                        dataRes.data[i]['door'] = ""
                    }

                    if (dataRes.data[i].time_in !== null) {
                        dataRes.data[i]['time_in'] = dataRes.data[i].time_in.length > 6 ? dataRes.data[i].time_in.slice(0,-3) : dataRes.data[i].time_in
                    } else {
                        dataRes.data[i]['time_in'] = ""
                    }

                    if (dataRes.data[i].time_out !== null) {
                        dataRes.data[i]['time_out'] = dataRes.data[i].time_out.length > 6 ? dataRes.data[i].time_out.slice(0,-3) : dataRes.data[i].time_out
                    } else {
                        dataRes.data[i]['time_out'] = ""
                    }

                    if (dataRes.data[i].day_in !== null) {
                        dataRes.data[i]['start_date'] = Moment(dataRes.data[i].day_in).format("DD-MM-YYYY") 
                    } else {
                        dataRes.data[i]['start_date'] = ""
                    }

                    if (dataRes.data[i].day_out !== null) {
                        dataRes.data[i]['end_date'] = Moment(dataRes.data[i].day_out).format("DD-MM-YYYY") 
                    } else {
                        dataRes.data[i]['end_date'] = ""
                    }

                    if (dataRes.data[i].day_of_week !== null) {
                        dataRes.data[i]['day_of_week'] = DayOfWeekText.toString()
                    } else {
                        dataRes.data[i]['day_of_week'] = ""
                    }
                
                    if (Moment(dataRes.data[i].day_out).format("YYYY-MM-DD") < Moment(new Date()).format("YYYY-MM-DD")) {
                        dataRes.data[i]['status_door'] = language[this.props.indexLanguage].textTable.inactive_door
                    } else {
                        dataRes.data[i]['status_door'] = language[this.props.indexLanguage].textTable.active_door
                    }

                    if (dataRes.data[i].door_open_obj && dataRes.data[i].door_open_obj.staffs !== undefined) {
                        dataRes.data[i]['users'] = renderText
                    } else {
                        dataRes.data[i]['users'] = ""
                    }
                }
                this.setState({
                    data_json_export: dataRes.data,
                    loading: false,
                })
                this._exporter.save();
            } else {
                this.setState({
                    data_json_export: [],
                    loading: false
                })
            }
        }).catch((error) => {
            console.log(error)
        })
    }
    }

    handleChangeStart = date => {
        this.setState({
            start_date: date,
        });
    };

    handleChangeEnd = date => {
        this.setState({
            end_date: date,
        });
    };

    handleClickSearch = () => {
        this.filterDoorCalendar()
    }

    filterDoorCalendar = async () => {
        let data = await fetch(`${this.state.crrApi2}/api/door_calendar/search`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token,
            },
            body: JSON.stringify({
                "id_company": this.state.box_engine_cf.idCompany,
                "start_date": Moment(this.state.start_date).format('YYYY-MM-DD'),
                "end_date": this.state.end_date !== null ? Moment(this.state.end_date).format('YYYY-MM-DD') : this.state.end_date,
                "index": 0,
                "id_staff": this.state.valueStaff.value,
                "item_per_page": this.itemsPerPage
            })
        }).then((response) => {
            return response.json()
        });
        if (data.status === 10000) {
            this.setState({
                listDoorOpen: [...data.data],
            });
            this.FilterDoorOpen(this.state.activePage)
        }
    }

    render() {
        if (this.state.isLogin === false) {
            return (
                <Redirect to={'/login'} />
            )
        }

        let bulletedDoorOpen = this.state.crrDatas.map((e, i) => {
            var DayOfWeek = Array.prototype.filter.call(e.day_of_week, eachChar => eachChar !== '|');
            var DayOfWeekText = [];
            for (let index = 0; index < DayOfWeek.length; index++) {
                var day_name = "";
                switch (DayOfWeek[index]) {
                    case '0':
                        day_name = language[this.props.indexLanguage].DayOfWeek.monday;
                        break;
                    case '1':
                        day_name = language[this.props.indexLanguage].DayOfWeek.tuesday;
                        break;
                    case '2':
                        day_name = language[this.props.indexLanguage].DayOfWeek.wednesday;
                        break;
                    case '3':
                        day_name = language[this.props.indexLanguage].DayOfWeek.thursday;
                        break;
                    case '4':
                        day_name = language[this.props.indexLanguage].DayOfWeek.friday;
                        break;
                    case '5':
                        day_name = language[this.props.indexLanguage].DayOfWeek.saturday;
                        break;
                    case '6':
                        day_name = language[this.props.indexLanguage].DayOfWeek.sunday;
                };

                DayOfWeekText.push(day_name)
            }

            let nameStaff = ""
            if (e.door_open_obj && e.door_open_obj.groups.length > 0) {
                nameStaff = e.door_open_obj.groups.map((group => {
                    return group.name
                })).join(', ')
            }
            if (e.door_open_obj && e.door_open_obj.department_levels.length > 0) {
                nameStaff = e.door_open_obj.department_levels.map((department => {
                    return department.department.name
                })).join(', ')
            }
            if (e.door_open_obj && e.door_open_obj.staffs.length > 0) {
                nameStaff = e.door_open_obj.staffs.map((name => {
                    return name.name
                })).join(', ')
            }
          
            let renderText = ""
            if (e.door_open_obj && (e.door_open_obj.department_levels.length === 1 && e.door_open_obj.department_levels[0].department.id === -1)) {
                renderText = language[this.props.indexLanguage].textTable.all
            } else {
                renderText = nameStaff
            }

            return (
                <tr key={(i + 1)}>
                    <td>{(i + this.state.offset + 1)}</td>
                    <td>{e.door.name}</td>
                    <td>{e.time_in.length > 6 ? e.time_in.slice(0,-3) : e.time_in}</td>
                    <td>{e.time_out.length > 6 ? e.time_out.slice(0,-3) : e.time_out}</td>
                    <td>{Moment(e.day_in).format("YYYY-MM-DD")}</td>
                    <td>{Moment(e.day_out).format("YYYY-MM-DD")}</td>
                    <td>{DayOfWeekText.toString()}</td>
                    <td style={{whiteSpace: "initial"}}>{renderText}</td>
                    <td className="text-center">
                        {
                            Moment(e.day_out).format("YYYY-MM-DD") < Moment(new Date()).format("YYYY-MM-DD")
                                ?
                                <span className="m-badge  m-badge--secondary m-badge--wide">{language[this.props.indexLanguage].textTable.inactive_door}</span>
                                :
                                <span className="m-badge  m-badge--accent m-badge--wide">{language[this.props.indexLanguage].textTable.active_door}</span>
                        }
                    </td>
                    {
                        this.state.dataRole.indexOf(this.state.type + '/' + name_door + ':insert_or_update') !== -1
                            ||
                            this.state.dataRole.indexOf(this.state.type + '/' + name_door + ':delete') !== -1
                            ?
                            <td>
                                {
                                    this.state.dataRole.indexOf(this.state.type + '/' + name_door + ':insert_or_update') !== -1
                                        ?
                                        <>
                                            <button onClick={(e) => {
                                                var data = this.state.crrDatas
                                                var dataDoorOpen = {
                                                    id_company: this.state.box_engine_cf.idCompany,
                                                    id: data[i].id,
                                                    id_door: data[i].id_door,
                                                    time_in: data[i].time_in.length > 6 ? data[i].time_in.slice(0,-3) : data[i].time_in ,
                                                    time_out: data[i].time_out.length > 6 ? data[i].time_out.slice(0,-3) : data[i].time_out,
                                                    day_in: Moment(data[i].day_in).format("YYYY-MM-DD"),
                                                    day_out: Moment(data[i].day_out).format("YYYY-MM-DD"),
                                                    day_of_week: data[i].day_of_week,
                                                    HOST: this.state.crrApi2
                                                };
                                                this.setState({
                                                    crrDoorOpen: dataDoorOpen,
                                                    modalDoorOpen: true,
                                                });


                                            }} className="m-portlet__nav-link btn m-btn m-btn--hover-warning m-btn--icon m-btn--icon-only m-btn--pill" data-tip data-for='Edit'>
                                                <i className="la la-edit" />
                                            </button>
                                            <ReactTooltip id='Edit' type='dark' effect='solid'>
                                                <span>{language[this.props.indexLanguage].tooltip.open_edit}</span>
                                            </ReactTooltip>
                                        </>
                                        :
                                        ""
                                }
                                {
                                    this.state.dataRole.indexOf(this.state.type + '/' + name_door + ':delete') !== -1
                                        ?
                                        <>
                                            <button className="m-portlet__nav-link btn m-btn m-btn--hover-danger m-btn--icon m-btn--icon-only m-btn--pill" data-tip data-for='Delete'
                                                onClick={v => {
                                                    v.preventDefault();
                                                    swal({
                                                        title: language[this.props.indexLanguage].Text_Notifi.are_you_sure,
                                                        text: language[this.props.indexLanguage].Text_Notifi.notifi_sure_delete_open_door + e.door.name,
                                                        icon: "warning",
                                                        buttons: true,
                                                        dangerMode: true,
                                                    })
                                                        .then(willDelete => {
                                                            if (willDelete) {
                                                                this.DeleteDoorOpen(e);
                                                            }
                                                        });

                                                }}
                                            ><i className="la la-trash"></i></button>
                                            <ReactTooltip id='Delete' type='dark' effect='solid'>
                                                <span>{language[this.props.indexLanguage].tooltip.open_delete}</span>
                                            </ReactTooltip>
                                        </>
                                        :
                                        ""
                                }
                            </td>
                            :
                            ""
                    }

                </tr>
            )
        });

        var pageRangeDisplayed = 1;
        if ($(window).width() < 768) {
            pageRangeDisplayed = 3
        } else {
            pageRangeDisplayed = 5
        }


        return (
            <>
            <Header />
            <div className={"m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body "}>
            <div className="m-grid__item m-grid__item--fluid m-wrapper">
                <div className="m-content p-2">
                    <div className="m-portlet m-portlet--tab mb-0">
                        <div className="m-portlet__head p-3">
                            <div className="m-portlet__head-caption pl-3">
                                <div className="m-portlet__head-title">
                                    <h3 className="m-portlet__head-text">
                                        {language[this.props.indexLanguage].Menubar.open_door}
                                    </h3>
                                </div>
                            </div>
                            <div className="m-portlet__head-tools pr-3">
                                <ul className="m-portlet__nav">
                                    <li className="m-portlet__nav-item">
                                        {
                                            this.state.dataRole.indexOf(this.state.type + '/' + name_door + ':insert_or_update') !== -1
                                                ?
                                                <button onClick={(e) => {
                                                    this.setState({
                                                        crrDoorOpen: {
                                                            id_company: this.state.box_engine_cf.idCompany,
                                                            day_of_week: "",
                                                            id_door: "",
                                                            time_in: "00:00",
                                                            day_in: Moment(new Date()).format("YYYY-MM-DD"),
                                                            time_out: "23:59",
                                                            day_out: Moment(new Date()).format("YYYY-MM-DD"),
                                                            HOST: this.state.crrApi2
                                                        },
                                                        modalDoorOpen: true,
                                                    });
                                                }} className="btn btn-accent m-btn m-btn--custom m-btn--icon m-btn--pill m-btn--air">
                                                    <span>
                                                        <i className="la la-plus" />
                                                        <span>{language[this.props.indexLanguage].textButton.add}</span>
                                                    </span>
                                                </button>
                                                :
                                                ""
                                        }
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="m-portlet__body m-portlet__body--no-padding">
                            <div className="row m-row--no-padding m-row--col-separator-xl">
                                <div className="col-xl-12">
                                    <div className="m-widget1">
                                        <div className="col-md-12 p-0 m-0 row pb-2">
                                            <div className="col-md-2 pl-md-0">
                                                <DatePicker
                                                    className="form-control"
                                                    selected={this.state.start_date}
                                                    dateFormat="dd/MM/yyyy"
                                                    onChange={this.handleChangeStart}
                                                    placeholderText='Ngày bắt đầu...'
                                                    selectsStart
                                                    startDate={this.state.start_date}
                                                    endDate={this.state.end_date}
                                                />
                                            </div>
                                            <div className="col-md-2 pl-md-0">
                                                <DatePicker
                                                    className="form-control"
                                                    dateFormat="dd/MM/yyyy"
                                                    selected={this.state.end_date}
                                                    onChange={this.handleChangeEnd}
                                                    placeholderText='Ngày kết thúc...'
                                                    selectsEnd
                                                    startDate={this.state.start_date}
                                                    endDate={this.state.end_date}
                                                    minDate={this.state.start_date}
                                                />
                                            </div>
                                            <div className="col-lg-2 pr-2 pl-0 m--margin-bottom-10-tablet-and-mobile select_option">
                                                {/* <Select
                                                    onChange={valueStaff => this.handleChangeStaff(valueStaff)}
                                                    options={this.state.optionStaff}
                                                    value={this.state.valueStaff}
                                                    placeholder="Select Staff"
                                                /> */}
                                                <Autocomplete
                                                    options={this.state.optionStaff}
                                                    getOptionLabel={option => option.title}
                                                    defaultValue={{ value: -1, title: language[this.props.indexLanguage].placeholderSelect.all_staff, object_id: "0" }}
                                                    value={this.state.valueStaff}
                                                    onChange={this.handleChangeStaff}
                                                    disableClearable
                                                    renderInput={params => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            placeholder={language[this.props.indexLanguage].placeholderSelect.select_staff}
                                                            fullWidth
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <button
                                                    onClick={(dataSearch) => {
                                                        this.handleClickSearch()

                                                    }}
                                                    className="btn btn-accent m-btn m-btn--icon mr-3" id="m_search">
                                                    <span>
                                                        <i className="la la-search" />
                                                        <span>{language[this.props.indexLanguage].textButton.search}</span>
                                                    </span>
                                                </button>

                                                <button
                                                    onClick={() => {
                                                        this.reset()
                                                    }}
                                                    className="btn btn-secondary m-btn m-btn--icon" id="m_reset">
                                                    <span>
                                                        <i className="la la-rotate-left" />
                                                        <span>{language[this.props.indexLanguage].textButton.reload}</span>
                                                    </span>
                                                </button>
                                            </div>
                                            <div className="col-md-3 text-right">
                                            <button
                                                    onClick={() => {
                                                        this.ExportExcel()
                                                    }}
                                                    className="btn btn-success m-btn m-btn--icon">
                                                    <span>
                                                        <i className="la la-download pr-2" />
                                                        <span>{language[this.props.indexLanguage].Capture.button_export}</span>
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="table-responsive text-nowrap">
                                            <table className="table table-bordered table-hover table-checkable dataTable no-footer dtr-inline collapsed">
                                                <thead>
                                                    <tr>
                                                        <th style={{ 'verticalAlign': 'middle', 'width': '80px' }}>{language[this.props.indexLanguage].Other.no}</th>
                                                        <th style={{ 'verticalAlign': 'middle' }}>{language[this.props.indexLanguage].Menubar.door}</th>
                                                        <th style={{ 'verticalAlign': 'middle', 'width': '150px' }}>{language[this.props.indexLanguage].textTable.time_in}</th>
                                                        <th style={{ 'verticalAlign': 'middle', 'width': '150px' }}>{language[this.props.indexLanguage].textTable.time_out}</th>
                                                        <th style={{ 'verticalAlign': 'middle', 'width': '170px' }}>{language[this.props.indexLanguage].textTable.start_date}</th>
                                                        <th style={{ 'verticalAlign': 'middle', 'width': '150px' }}>{language[this.props.indexLanguage].textTable.end_date}</th>
                                                        <th style={{ 'verticalAlign': 'middle', 'width': '450px' }}>{language[this.props.indexLanguage].textTable.day_of_week}</th>
                                                        <th style={{ 'verticalAlign': 'middle', 'width': '850px' }}>{language[this.props.indexLanguage].textTable.staff}</th>
                                                        <th style={{ 'verticalAlign': 'middle', 'width': '150px' }}>{language[this.props.indexLanguage].textTable.status_door}</th>
                                                        {
                                                            this.state.dataRole.indexOf(this.state.type + '/' + name_door + ':insert_or_update') !== -1
                                                                ||
                                                                this.state.dataRole.indexOf(this.state.type + '/' + name_door + ':delete') !== -1
                                                                ?
                                                                < th style={{ 'verticalAlign': 'middle', 'width': '150px' }}>{language[this.props.indexLanguage].textTable.action}</th>
                                                                :
                                                                ""
                                                        }

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {bulletedDoorOpen}
                                                </tbody>

                                            </table>
                                            <ModalDoorOpen
                                                listdoor={this.state.listDoor}
                                                data={this.state.crrDoorOpen}
                                                show={this.state.modalDoorOpen}
                                                onHide={this.modalClose}
                                            />
                                        </div>
                                        <ExcelExport
                                            data={this.state.data_json_export}
                                            fileName={"Xuất_Danh_Sách_Quyền_Mở_Cửa_" + Moment(new Date()).format("DD-MM-YYYY") + ".xlsx"}
                                            ref={(exporter) => { this._exporter = exporter; }}>
                                            <ExcelExportColumn field="door" title={language[this.props.indexLanguage].Menubar.door} width={150} />
                                            <ExcelExportColumn field="time_in" title={language[this.props.indexLanguage].textTable.time_in} width={80} />
                                            <ExcelExportColumn field="time_out" title={language[this.props.indexLanguage].textTable.time_out} width={80} />
                                            <ExcelExportColumn field="start_date" title={language[this.props.indexLanguage].textTable.start_date} width={100} />
                                            <ExcelExportColumn field="end_date" title={language[this.props.indexLanguage].textTable.end_date} width={100} />
                                            <ExcelExportColumn field="day_of_week" title={language[this.props.indexLanguage].textTable.day_of_week} width={330} />
                                            <ExcelExportColumn field="status_door" title={language[this.props.indexLanguage].textTable.status_door} width={80} />
                                            <ExcelExportColumn field="users" title={language[this.props.indexLanguage].textTable.staff} />
                                        </ExcelExport>
                                        <Pagination
                                            firstPageText={language[this.props.indexLanguage].pagination.first}
                                            nextPageText={language[this.props.indexLanguage].pagination.next}
                                            lastPageText={language[this.props.indexLanguage].pagination.last}
                                            prevPageText={language[this.props.indexLanguage].pagination.previous}
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={this.itemsPerPage}
                                            totalItemsCount={this.state.listDoorOpen.length}
                                            pageRangeDisplayed={pageRangeDisplayed}
                                            onChange={this.handlePageChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </>
        );
    }
}
const mapStateToProps = state => {
    return {
        indexLanguage: state.changelanguage.indexLanguage,
    }
}
export default connect(mapStateToProps, null)(DoorOpen);
