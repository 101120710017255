
const COLOR = ['#00c5dc', '#ffb822','#ff94ab','#E74C3C','#0EAB30' ];

export const CHART = {

    credits: {
        enabled: false
    },
    chart: {
        height: 300,
        style: {
            fontFamily: '\'Unica One\', sans-serif'
        },
    },
    title: {
        text: '',
    },
    colors: COLOR,
}





