import React, { Component } from 'react';

class TreeItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        }
    }

    handleCollapse = () => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    render() {
        return (
            <div>
                <p>
                    <i style={{ cursor: "pointer" }} className={this.props.item?.children ? !this.state.isOpen ? "fa fa-plus-square" : "fa fa-minus-square" : "fa fa-circle"} onClick={this.props.item?.children ? this.handleCollapse : () => { }}></i> &nbsp;
                    <span className="tree-item" onClick={() => {
                        this.props.getList(this.props.item);
                    }}>
                        {this.props.item.title}
                    </span>
                </p>
                {this.state.isOpen && <div style={{ paddingLeft: 10 }}>
                    {this.props.item?.children && <TreeView item={this.props.item.children} getList={this.props.getList} />}
                </div>}
            </div>
        )
    }
}

export default class TreeView extends Component {
    render() {
        return (
            <div style={{ userSelect: 'none' }}>
                {this.props.item.map((item, index) => (
                    <TreeItem key={item.title + index.toString()} item={item} getList={this.props.getList} />
                ))}
            </div>
        )
    }
}