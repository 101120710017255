import { css } from '@emotion/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import $ from 'jquery';
import Moment from 'moment';
import React, { Component } from 'react';
import { Button, Col, Image, Row } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Files from 'react-files';
import Pagination from "react-js-pagination";
// First way to import
import { Redirect } from 'react-router-dom';
import Select from "react-select";
import { PulseLoader } from 'react-spinners';
import ReactTooltip from 'react-tooltip';
import swal from 'sweetalert';
import { ARRAY_ENGINES } from '../../../../config';
import language from '../../../../language/language';
import Store from '../../../../store';
import ShowImg from "./ShowImg";
import ReactGA from 'react-ga';
import ModalMoveImg from './ModalMoveImg';

import { connect } from 'react-redux'
import { formatTitleOption, formatTreeViewData } from '../../CommonFunction';
import { withStyles } from "@material-ui/core/styles";
import TreeView from '../../TreeView';
import Header from '../../../layouts/Header';
const useStyles = theme => ({
    paper: {
        whiteSpace: "nowrap"
    }
});
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

function sortData(a, b) {
    if (a.name < b.name) {
        return 1
    } else {
        if (a.name > b.name) {
            return -1;
        }
        else {
            return 0
        }
    }
}

const stylesSelect = {
    menuList: base => ({
        ...base,
        height: '220px',
    }),
};

const nameTab = 'history'
class History extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLogin: Store.getState().isLogin.isLogin,
            access_token: Store.getState().isLogin.access_token,
            box_engine_cf: Store.getState().boxai.box_engine_cf,
            language: Store.getState().changelanguage.language,
            crrApi2: Store.getState().boxai.box_engine_cf.crrApiSocket,
            dataCamera: Store.getState().boxai.box_engine_cf.dataCamera,
            dataRole: Store.getState().role.role,
            type: '',
            loading: false,
            listImage: [],
            listmodel: [],
            listPerson: [],
            crrImages: [],
            listIdModels: [],
            data_json: [],
            valueDepartment: { 'value': "", 'title': "" },
            tempValue: '',
            HOST: '',
            valuePerson: "",
            crrmodel: null,
            selectedStaff: '',
            crrFrom_Date: new Date().setHours(0, 0, 0, 0),
            crrTo_Date: new Date().setHours(23, 59, 59, 0),
            activePage: 1,
            listCompany: [],
            idEngine: 0,
            totalCount: 0,
            crrApi: [],
            crr_select_type: {},
            crr_comp: {},
            toStaff: '',
            optionDepartment: [],
            listImage: [],
            is_show: false,
            searchUnknown: false,
            crrFile: null,
            search: '',
            listData: [],
            dataCameraAll: [],
            crrCamera: { title: '' },
            exChangeSearch: true,
            openPopver: null,
            openSelecDown: false,
            optionDepartmentSelect: [],
            valueDepartmentPopover: { 'value': -1, 'label': language[this.props.indexLanguage].placeholderSelect.all_department },
            modalMoveImage: false,
            dataPersonMove: "",
            dataPersonMoveSelect: "",
            typeMove: 1,
            selectDepartment: false
        };
        var itemsPerPage = 1;
        if ($(window).width() < 768) {
            itemsPerPage = 8
        } else {
            itemsPerPage = 16
        }
        this.itemsPerPage = itemsPerPage;
        Store.subscribe(() => {
            this.setState({
                ...this.state,
                crrFrom_Date: new Date().setHours(0, 0, 0, 0),
                crrTo_Date: new Date().setHours(23, 59, 59, 0),
                activePage: 1,
                loading: false,
                listImage: [],
                listIdModels: [],
                listPerson: [],
                dataCameraAll: [],
                optionDepartment: [],
                optionDepartmentSelect: [],
                exChangeSearch: true,
                searchUnknown: false,
                is_show: false,
                crrFile: null,
                search: '',
                crr_comp: { title: language[this.props.indexLanguage].placeholderSelect.there_is_data, value: 1 },
                crr_select_type: { title: language[this.props.indexLanguage].Text_Notifi.text_select_type_history_name, value: 1 },
                crrCamera: { title: language[this.props.indexLanguage].placeholderSelect.all_camera },
                valueDepartment: { 'value': "", 'title': "" },
                isLogin: Store.getState().isLogin.isLogin,
                access_token: Store.getState().isLogin.access_token,
                box_engine_cf: Store.getState().boxai.box_engine_cf,
                language: Store.getState().changelanguage.language,
                crrApi2: Store.getState().boxai.box_engine_cf.crrApiSocket,
                dataCamera: Store.getState().boxai.box_engine_cf.dataCamera,
                valueDepartmentPopover: { 'value': -1, 'label': language[this.props.indexLanguage].placeholderSelect.all_department },
                modalMoveImage: false,
                dataPersonMove: "",
                dataPersonMoveSelect: "",
                selectDepartment: false
            }, () => {
                var dataCameraAll = [];
                if (this.state.box_engine_cf.dataCamera !== undefined) {
                    for (let index = 0; index < this.state.box_engine_cf.dataCamera.length; index++) {
                        dataCameraAll.push(this.state.box_engine_cf.dataCamera[index].id_box_engine)
                    }
                }

                var indexlanguage = 0;
                if (this.state.language === "us") {
                    indexlanguage = 0
                } else if (this.state.language === "vn") {
                    indexlanguage = 1
                }

                this.setState({
                    dataCameraAll: dataCameraAll,
                    crrCamera: { title: language[indexlanguage].placeholderSelect.all_camera, value: dataCameraAll },
                }, () => {
                    this.getData()
                })
            });
        });
        this.state.box_engine_cf = Store.getState().boxai.box_engine_cf;
    }

    componentDidMount() {
        this.getData()
    }


    getData = () => {
        var type = localStorage.getItem("type")
        this.getPerson();
        this.getUnknonw();
        this.getDepartment();

    }

    componentWillMount() {
        const select_option_type = [
            { title: language[this.props.indexLanguage].Text_Notifi.text_select_type_history_name, value: 1 },
            { title: language[this.props.indexLanguage].Text_Notifi.text_select_type_history_image, value: 2 },
        ]

        const select_option = [
            { title: language[this.props.indexLanguage].placeholderSelect.there_is_data, value: 1 },
            { title: language[this.props.indexLanguage].placeholderSelect.no_data, value: 2 },
            { title: language[this.props.indexLanguage].placeholderSelect.all_status, value: 0 },
        ]

        var dataCameraAll = [];
        if (this.state.box_engine_cf.dataCamera !== undefined) {
            for (let index = 0; index < this.state.box_engine_cf.dataCamera.length; index++) {
                dataCameraAll.push(this.state.box_engine_cf.dataCamera[index].id_box_engine)
            }
        }

        var type = localStorage.getItem("type")

        this.setState({
            type: type,
            select_option_type: select_option_type,
            select_option: select_option,
            crr_comp: { title: language[this.props.indexLanguage].placeholderSelect.there_is_data, value: 1 },
            crr_select_type: { title: language[this.props.indexLanguage].Text_Notifi.text_select_type_history_name, value: 1 },
            dataCameraAll: dataCameraAll,
            crrCamera: { title: language[this.props.indexLanguage].placeholderSelect.all_camera, value: dataCameraAll },
        })
    }

    getUnknonw = () => {
        if (this.state.box_engine_cf.crrApi === undefined) return;
        fetch(`${this.state.box_engine_cf.crrApi}/api/staff/getUnknown`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            }
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                var listPerson = this.state.listPerson
                listPerson.push({
                    object_id: data.staffs.object_id,
                    id_staff: data.staffs.id_staff,
                    name: data.staffs.name,
                    abbreviated_name: data.staffs.abbreviated_name
                })
                this.setState({
                    listPerson: listPerson
                })
            }
        }).catch((error) => {
            if (error) {
                console.log(error)
            }
        })
    }

    getPerson() {
        if (this.state.crrApi2 === undefined) return;
        fetch(`${this.state.crrApi2}/api/staff/${this.state.box_engine_cf.idCompany}/get_all`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            }
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                var listPerson = this.state.listPerson
                for (let index = 0; index < data.data.length; index++) {
                    listPerson.push(data.data[index])
                }
                var dataOptionSort = listPerson.sort(sortData);
                this.setState({
                    listPerson: dataOptionSort
                })
            }
        }).catch((error) => {
            if (error) {
                console.log(error)
            }
        })
    }

    _exporter;
    export = () => {
        this._exporter.save();
    }

    // FilterImg(activePage) {
    //     //console.log('123')
    //     const offset = (activePage - 1) * this.itemsPerPage;
    //     this.setState({ offset })
    // }
    handlePageChange = (pageNumber) => {
        this.setState({
            ...this.state,
            activePage: pageNumber,
            listImage: [],
        }, () => {
            this.getImg();
        })
        // this.FilterImg(pageNumber);
    }

    getImg = () => {
        if (this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':view_all') !== -1 || this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':view_by_manager') !== -1) {
            this.getFilter();
        } else {
            this.getHistoryByStaff();
        }
    }

    getHistoryByStaff = () => {
        if (this.state.box_engine_cf.crrApi == undefined) return;
        this.setState({
            loading: true,
        }, () => {
            fetch(`${this.state.box_engine_cf.crrApi}/api/staff/get_history`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': Store.getState().isLogin.access_token
                },
                body: JSON.stringify({
                    "id_cameras": this.state.crrCamera.value,
                    "from_date": Moment(this.state.crrFrom_Date).format("YYYY-MM-DD HH:mm:ss"),
                    "to_date": Moment(this.state.crrTo_Date).format("YYYY-MM-DD HH:mm:ss"),
                    "index": this.state.activePage,
                    "item_per_page": this.itemsPerPage,
                })
            }).then((response) => {
                return response.json()
            }).then((data) => {
                if (data.status == 10000) {
                    this.setState({
                        loading: false,
                        data_json: data.data,
                        totalCount: data.count,
                        listImage: data.data
                    })
                } else {
                    this.setState({
                        loading: false,
                        totalCount: 0,
                        listImage: []
                    })
                }
            }).catch(() => {
                this.setState({
                    loading: false,
                    totalCount: 0,
                    listImage: []
                })
            });
        });
    }


    toDataURL = (url, callback) => {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
            var reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }

    getFilter = () => {
        if (this.state.box_engine_cf.crrApi == undefined) return;
        // var dataCamera = [];
        // for (let index = 0; index < this.state.box_engine_cf.dataCamera.length; index++) {
        //     dataCamera.push(this.state.box_engine_cf.dataCamera[index].id_box_engine)
        // }
        this.setState({
            loading: true,
        }, () => {
            if (!this.state.optionDepartment || this.state.optionDepartment.length === 0) return this.setState({ loading: false });
            if (this.state.crrFile == null) {
                fetch(`${this.state.box_engine_cf.crrApi}/api/staff/search_history`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': Store.getState().isLogin.access_token
                    },
                    body: JSON.stringify({
                        "id_cameras": this.state.crrCamera.value,
                        "type_face": this.state.crr_comp.value,
                        "search": this.state.search,
                        "img": "",
                        "department": this.state.valueDepartment ? this.state.valueDepartment.value.replace("┗━━", "") : '',
                        "from_date": Moment(this.state.crrFrom_Date).format("YYYY-MM-DD HH:mm:ss"),
                        "to_date": Moment(this.state.crrTo_Date).format("YYYY-MM-DD HH:mm:ss"),
                        "index": this.state.activePage,
                        "item_per_page": this.itemsPerPage,
                    })
                }).then((response) => {
                    return response.json()
                }).then((data) => {
                    if (data.status == 10000) {
                        this.setState({
                            loading: false,
                            data_json: data.data,
                            totalCount: data.count,
                            listImage: data.data
                        })
                    } else {
                        this.setState({
                            loading: false,
                            totalCount: 0,
                            listImage: []
                        })
                    }
                }).catch(() => {
                    this.setState({
                        loading: false,
                        totalCount: 0,
                        listImage: []
                    })
                });
            }
            else {
                console.log(this.state.crrFile.preview.url)
                this.toDataURL(this.state.crrFile.preview.url, (dataUrl) => {
                    var imgBase64 = dataUrl.split(',')[1]
                    fetch(`${this.state.box_engine_cf.crrApi}/api/staff/search_history`, {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': Store.getState().isLogin.access_token
                        },
                        body: JSON.stringify({
                            "id_cameras": this.state.crrCamera.value,
                            "type_face": this.state.crr_comp.value,
                            "search": this.state.search,
                            "img": imgBase64,
                            "department": this.state.valueDepartment ? this.state.valueDepartment.value : '',
                            "from_date": Moment(this.state.crrFrom_Date).format("YYYY-MM-DD HH:mm:ss"),
                            "to_date": Moment(this.state.crrTo_Date).format("YYYY-MM-DD HH:mm:ss"),
                            "index": this.state.activePage,
                            "item_per_page": this.itemsPerPage,
                        })
                    }).then((rs) => {
                        return rs.json()
                    }).then((data) => {
                        if (data.status == 10000) {
                            this.setState({
                                loading: false,
                                data_json: data.data,
                                totalCount: data.count,
                                listImage: data.data
                            })
                        } else {
                            this.setState({
                                loading: false,
                                totalCount: 0,
                                listImage: []
                            })
                        }
                    }).catch(() => {
                        this.setState({
                            loading: false,
                            totalCount: 0,
                            listImage: []
                        })
                    });
                })
            }
        });
    }

    moveImgToTrain = (event, value) => {
        event.preventDefault();
        swal({
            title: language[this.props.indexLanguage].Text_Notifi.are_you_sure,
            text: language[this.props.indexLanguage].Text_Notifi.text_are_you_sure_history,
            icon: "warning",
            button: {
                text: language[this.props.indexLanguage].textButton.oke,
                closeModal: false,
            },
        })
            .then((name) => {
                if (!name) throw null;
                if (this.state.box_engine_cf.crrApi == undefined) return;
                return fetch(`${this.state.box_engine_cf.crrApi}/api/staff/acceptFace/${value._id}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': Store.getState().isLogin.access_token
                    }
                })
            })
            .then(response => {
                return response.json()
            }).then(data => {
                if (data.status === 10000) {
                    ReactGA.event({
                        category: 'Lich su / Tim kiem',
                        action: 'Chuyen anh vao mau',
                    });
                    swal(language[this.props.indexLanguage].Text_Notifi.success, language[this.props.indexLanguage].Text_Notifi.move_success, "success", {
                        buttons: false,
                        timer: 1500,
                    });
                    this.setState({
                        crrItem: '',
                        openPopver: false,
                    })
                    this.getImg();
                } else if (data.status === 10003) {
                    swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].Text_Notifi.detect_face_failed, "error");
                } else {
                    swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].Text_Notifi.move_failed, "error");
                }
            }).catch((err) => {
                if (err) {
                    swal(language[this.props.indexLanguage].Text_Notifi.error, err, "error");
                }
            })
    }

    handleChangeStartDate = startDate => {
        this.setState({
            crrFrom_Date: startDate,
        });
    }

    handleChangeEndDate = enddate => {
        this.setState({
            crrTo_Date: enddate,
        });
    }

    handleChangeOption = (index, option) => {
        if (option.value === 2 || option.value === 0) {
            this.setState({
                searchUnknown: true,
                crr_comp: option,
                crrFile: null,
                search: "",
            });
        } else {
            this.setState({
                searchUnknown: false,
                crr_comp: option,
            });
        }
    }

    handleChangeOptionSelectType = (index, option) => {
        var exChangeSearch = this.state.exChangeSearch;
        if (option.value == 1) {
            exChangeSearch = true
        } else {
            exChangeSearch = false
        }
        this.setState({
            crr_select_type: option,
            exChangeSearch: exChangeSearch,
            search: "",
            crrFile: null,
            is_show: false
        });
    }

    handleChangeDepartment = (index, value) => {
        this.setState({
            valueDepartment: value,
        })

    }

    getDepartment = () => {
        if (this.state.crrApi2 === undefined) return;
        if (this.state.dataRole.indexOf(this.state.type + '/staff:view_all') !== -1) {
            fetch(`${this.state.crrApi2}/api/department/${this.state.box_engine_cf.idCompany}/get_all`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': Store.getState().isLogin.access_token
                },
            }).then((response) => {
                return response.json()
            }).then((data) => {
                if (data.status === 10000) {
                    var optionDepartment = [{ 'value': "", 'title': language[this.props.indexLanguage].placeholderSelect.all_department }]
                    var optionDepartmentSelect = [{ 'value': -1, 'label': language[this.props.indexLanguage].placeholderSelect.all_department }]
                    for (let index = 0; index < data.data.length; index++) {
                        optionDepartment.push({ 'value': data.data[index].name, 'title': data.data[index].name, 'code': data.data[index].code })
                        optionDepartmentSelect.push({ 'value': data.data[index].id, 'label': data.data[index].name })
                    }
                    this.setState({
                        listDepartment: [...data.data],
                        optionDepartment: optionDepartment,
                        optionDepartmentSelect: optionDepartmentSelect,
                        valueDepartment: { value: "", title: language[this.props.indexLanguage].placeholderSelect.all_department },
                    }, () => {
                        this.getImg();
                    });
                } else {
                    this.setState({
                        loading: false
                    }, () => {
                        console.log('call api department error!, stopcode')
                    })
                }
            })
        } else if (this.state.dataRole.indexOf(this.state.type + '/staff:view_by_manager' !== -1)) {
            const id_staff = localStorage.getItem('id_user');
            fetch(`${this.state.crrApi2}/api/department/get_by_supervisor/${parseInt(id_staff)}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': Store.getState().isLogin.access_token
                },
            }).then((response) => {
                return response.json()
            }).then((data) => {
                if (data.status === 10000) {
                    var optionDepartment = []
                    var optionDepartmentSelect = []
                    for (let index = 0; index < data.data.length; index++) {
                        optionDepartment.push({ 'value': data.data[index].name, 'title': data.data[index].name, 'code': data.data[index].code })
                        optionDepartmentSelect.push({ 'value': data.data[index].id, 'label': data.data[index].name, 'code': data.data[index].code })
                    }
                    this.setState({
                        listDepartment: [...data.data],
                        optionDepartment: optionDepartment,
                        optionDepartmentSelect: optionDepartmentSelect,
                        valueDepartment: optionDepartment[0],
                    }, () => {
                        this.getImg();
                    });
                } else {
                    this.setState({
                        loading: false
                    }, () => {
                        console.log('call api department error!, stopcode')
                    })
                }
            })
        } else if (this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':view_personal') !== -1 && this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':view_all') === -1) {
            this.getHistoryByStaff();
        }
    }

    onClickChangeMove = (event, index) => {
        event.preventDefault()
        var datalistImage = this.state.listImage;
        if (datalistImage[index].changeMove == true) {
            datalistImage[index].changeMove = false
        } else {
            datalistImage[index].changeMove = true
        }
        this.setState({
            listImage: datalistImage
        })
    }


    onFilesChange = (files) => {
        // console.log(files)
        this.setState({
            crrFile: files[0],
            is_show: true
        }, () => {
            // console.log(this.state.crrFile)
        })
    }
    onFilesError = (error, file) => {
        alert('error code ' + error.code + ': ' + error.message)
    }
    onHide = () => {
        console.log('close img');
        this.setState({
            crrFile: null,
            is_show: false
        }, () => {

        })
    }

    changeHandleCamera = (valueCamera, e) => {
        this.setState({ crrCamera: e })
    }

    handleClick = (valuePerson, valueImg, typeMove) => {
        this.setState({
            modalMoveImage: true,
            dataImgMove: valueImg,
            dataPersonMove: valuePerson,
            typeMove: typeMove
        })
    };

    modalClose = (checkSuccess) => {
        this.setState({
            modalMoveImage: false,
        })
        if (checkSuccess === true) {
            this.getImg();

        }
    }

    handleClose = () => {
        this.setState({
            openPopver: null,
            popno: -1
        })
    };

    onErrorImage = (idstaff) => {
        $('#imageFace_' + idstaff).attr("src", "/img/photo-placeholder.png");
    }

    render() {
        const { classes } = this.props;
        if (this.state.isLogin === false) {
            return (
                <Redirect to={'/login'} />
            )
        }
        var options = []
        var listPersonFilter
        if (this.state.valueDepartmentPopover.value !== -1) {
            listPersonFilter = this.state.listPerson.filter(item => item.department?.id === this.state.valueDepartmentPopover.value)
        } else {
            listPersonFilter = this.state.listPerson
        }
        for (var i = 0; i < listPersonFilter.length; i++) {
            if (listPersonFilter[i]['id_staff'] === "unknown") {
                options.push({ 'label': listPersonFilter[i]['name'] + ' (' + listPersonFilter[i]['id_staff'] + ')', 'value': listPersonFilter[i]['object_id'] })
            } else {
                options.unshift(
                    { 'label': listPersonFilter[i]['name'] + ' (' + listPersonFilter[i]['id_staff'] + ')', 'value': listPersonFilter[i]['object_id'] }
                )
            }
        }

        let bulletedImg = this.state.listImage.map((value, index) => {
            const open = Boolean(this.state.openPopver);
            return (
                <Col xs={12} sm={6} md={6} lg={4} xl={3} key={index} className="p-1 col__xxl">
                    <div className={'box box-block form_info_history mb-0 ' + (value.idStaff === 'unknown' ? 'bg-warning' : 'bg-white')} style={{ 'borderRadius': '5px' }}>
                        <Row className='row-sm'>
                            <Col xs={4} md={4} style={{ 'textAlign': 'center' }} className="imgHover">
                                <Image width='100%' src={value.url_img ? value.url_img : value.imgFace !== null ? `${this.state.box_engine_cf.crrApi}/view/image/0/${value.idCamera}/${value.imgFace}` : value.idStaff !== 'unknown' ? `${this.state.box_engine_cf.crrApi}/view/origin/image/${value._idStaff}` : '/img/photo-placeholder.png'} className='img-fluid imgHistory   border-3' />
                                {/* {
                                    (this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':move_image_to_train') !== -1 && process.env.REACT_APP_ONPREMISE == '0')
                                        ?
                                        <button
                                            onClick={(event) => this.moveImgToTrain(event, value)}
                                            className={"btn btn-success m-btn m-btn--icon btn-sm m-btn--icon-only btn-move-img m-btn--pill m-btn--air btn-removeimg-capture " + (value.idStaff === 'unknown' ? 'd-none' : '')} data-tip data-for='MoveToTrain'>
                                            <ReactTooltip id='MoveToTrain' type='dark' effect='solid'>
                                                <span>{language[this.props.indexLanguage].tooltip.move_img_to_train}</span>
                                            </ReactTooltip>
                                            <i className="la la-share"></i>
                                        </button>
                                        :
                                        ""
                                } */}
                            </Col>
                            <Col xs={8} md={8} className='position-relative' style={{ 'fontSize': '20px' }}>
                                <Row className="ml-0 mr-0">
                                    <Col xs={12} md={12} className="pr-0 pl-0">
                                        <Row className="ml-0 mr-0">
                                            <Col xs={12} md={12} className="pr-2 pl-1" style={{ 'display': 'inline-block' }}>
                                                <ReactTooltip id={'Name_Staff_' + index} type='dark' effect='solid'>
                                                    <span>{value.name} ({value.idStaff})</span>
                                                </ReactTooltip>
                                                <div className='row p-0 m-0'>

                                                    <div className='col-8 pl-0 response_history'>
                                                        <h5 className='truncate mb-0' data-tip data-for={'Name_Staff_' + index}>{value.name} ({value.idStaff})</h5>

                                                        <div style={{ 'fontSize': '13px', 'whiteSpace': 'nowrap', 'width': '100%', 'overflow': 'hidden', 'textOverflow': 'ellipsis', 'paddingTop': '0.25em', 'paddingBottom': '0.25em', }}>
                                                            <span className='tag tag-success' style={{ 'fontSize': '13px' }}>{value.department}</span>
                                                        </div>
                                                        <ul className="face_details pt-0 mb-1" style={{ 'fontSize': '15px', 'paddingLeft': '0px' }}>
                                                            <li className="date tag tag-warning" style={{ 'listStyle': 'none', 'fontSize': '13px' }}>
                                                                ID Camera: {(value.idCamera)}
                                                            </li>
                                                        </ul>
                                                        {
                                                            (value.temperature && value.temperature !== '0.0')
                                                            &&
                                                            <ul className="face_details mb-1" style={{ 'fontSize': '15px', 'paddingLeft': '0px' }}>
                                                                <li className="date tag tag-warning" style={{ 'listStyle': 'none', 'fontSize': '13px' }}>
                                                                    {language[this.props.indexLanguage].Settings.temperature}: {(value.temperature !== "" && value.temperature + ' ⁰C')}
                                                                </li>
                                                            </ul>
                                                        }
                                                        <ul className="face_details mb-1" style={{ 'fontSize': '15px', 'paddingLeft': '0px' }}>
                                                            <li className="date tag tag-warning" style={{ 'listStyle': 'none', 'fontSize': '13px' }}>
                                                                {Moment(value.timeget).format("DD-MM-YYYY HH:mm")}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="pl-0 pr-0 col-4 my-1" style={{ 'display': 'inline-flex', 'flexDirection': 'column', 'zIndex': 0, 'maxHeight': '75px' }}>
                                                        <div className="d-flex justify-content-between mb-1">
                                                            <button onClick={(e) => this.handleClick(e, value, 1)} data-tip data-for='edit_data' style={{ width: "30px", height: "30px" }} className="btn btn-secondary m-btn m-btn--icon m-btn--icon-only">
                                                                <ReactTooltip id={'edit_data'} type='dark' effect='solid'>
                                                                    <span>{language[this.props.indexLanguage].tooltip.edit_data}</span>
                                                                </ReactTooltip>
                                                                <i className="fa fa-exchange-alt"></i>
                                                            </button>
                                                            {(this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':move_image_to_staff') !== -1 && process.env.REACT_APP_ONPREMISE == '0') &&
                                                                <button onClick={(e) => {
                                                                    if (value.idStaff === 'unknown') {
                                                                        this.handleClick(e, value, 2)
                                                                    } else {
                                                                        this.moveImgToTrain(e, value)
                                                                    }
                                                                }} data-tip data-for='move_to_face_sample' style={{ width: "30px", height: "30px" }} className="btn btn-secondary m-btn m-btn--icon m-btn--icon-only">
                                                                    <ReactTooltip id={'move_to_face_sample'} type='dark' effect='solid'>
                                                                        <span>{language[this.props.indexLanguage].tooltip.move_to_face_sample}</span>
                                                                    </ReactTooltip>
                                                                    <i className="fa fa-external-link-alt"></i>
                                                                </button>
                                                            }
                                                        </div>
                                                        {
                                                            value.idStaff == 'unknown'
                                                                ?
                                                                ''
                                                                :
                                                                <Image width='100%' style={{ width: 70, height: 70, objectFit: 'cover' }} src={`${this.state.box_engine_cf.crrApi}/view/origin/image/${value._idStaff}`} id={'imageFace_' + value._id} onError={() => this.onErrorImage(value._id)} className={'img-fluid ' + (value.acceptFace === "" ? "d-none" : "") + ' border-3'} />
                                                        }
                                                    </div>
                                                </div>

                                            </Col>

                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Col>
            )
        });

        var pageRangeDisplayed = 1;
        if ($(window).width() < 768) {
            pageRangeDisplayed = 3
        } else {
            pageRangeDisplayed = 5
        }

        var optionsCamera = [{ title: language[this.props.indexLanguage].placeholderSelect.all_camera, value: this.state.dataCameraAll }];
        if (this.state.dataCamera !== undefined) {
            for (let index = 0; index < this.state.dataCamera.length; index++) {
                if (ARRAY_ENGINES.includes(this.state.dataCamera[index].sub_engine_id + '')) {
                    optionsCamera.push({
                        title: this.state.dataCamera[index].alias_box_engine ? `${this.state.dataCamera[index].alias_box_engine} ( ${this.state.dataCamera[index].id_box_engine} )` : this.state.dataCamera[index].id_box + '_' + this.state.dataCamera[index].id_box_engine + (this.state.dataCamera[index].alias_box_engine ? " ( " + this.state.dataCamera[index].alias_box_engine + " ) " : ""), value: [this.state.dataCamera[index].id_box_engine]
                    })
                }
            }
        }
        // if(this.state.optionDepartment.length === 0) return <></>;
        return (
            <>
            <Header />
            <div className={"m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body "}>
                <ModalMoveImg
                    dataPersonMove={this.state.dataPersonMove}
                    dataImgMove={this.state.dataImgMove}
                    department={this.state.optionDepartmentSelect}
                    valuedepartment={this.state.valueDepartmentPopover}
                    valuePerson={this.state.listPerson}
                    show={this.state.modalMoveImage}
                    typeMove={this.state.typeMove}
                    onHide={(checkSuccess) => this.modalClose(checkSuccess)}
                />
                <div className="m-grid__item m-grid__item--fluid m-wrapper p-2" id="capture">
                    <div className="m-content">
                        <div className="m-portlet m-portlet--tab mb-0">
                            <div className="m-portlet__head p-3">
                                <div className="m-portlet__head-caption col-xs-12 col-md-12 col-lg-10 col-xl-8 ">
                                    <div className="m-portlet__head-title hide_mobile">
                                        <h3 className="m-portlet__head-text text-nowrap pr-3">
                                            {language[this.props.indexLanguage].Menubar.history}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            {/*begin::Form*/}
                            <div className="m-form m-form--fit m-form--label-align-right">
                                <div className="m-portlet__body pt-3">
                                    <div className="row col-md-12 pl-4 m-0" id="form_search_history">
                                        <div className="col-xl-2 col-md-2 col-xs-12 pr-2 pl-2 select_option mb-3">
                                            <Autocomplete
                                                options={optionsCamera}
                                                getOptionLabel={option => option.title}
                                                value={this.state.crrCamera}
                                                onChange={this.changeHandleCamera}
                                                disableClearable
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        placeholder="Camera"
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className="col-xl-2 col-md-2 col-xs-12 pr-2 pl-2 mb-3">
                                            <DatePicker
                                                className="form-control m-input m_datetimepicker_6"
                                                selected={this.state.crrFrom_Date}
                                                onChange={this.handleChangeStartDate}
                                                timeFormat="HH:mm"
                                                dateFormat="dd-MM-yyyy HH:mm"
                                                withPortal
                                                selectsStart
                                                showTimeSelect
                                                startDate={this.state.crrFrom_Date}
                                                endDate={this.state.crrTo_Date}
                                                placeholderText="Start date"
                                            />
                                        </div>
                                        <div className="col-xl-2 col-md-2 col-xs-12 pr-2 pl-2 mb-3">
                                            <DatePicker
                                                className="form-control m-input m_datetimepicker_6"
                                                selected={this.state.crrTo_Date}
                                                onChange={this.handleChangeEndDate}
                                                timeFormat="HH:mm"
                                                dateFormat="dd-MM-yyyy HH:mm"
                                                showTimeSelect
                                                withPortal
                                                selectsEnd
                                                startDate={this.state.crrFrom_Date}
                                                endDate={this.state.crrTo_Date}
                                                minDate={this.state.crrFrom_Date}
                                                placeholderText="End date"
                                            />
                                        </div>

                                        {
                                            (this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':view_all') !== -1 || this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':view_by_manager') !== -1)
                                                ?
                                                <>
                                                    {
                                                        process.env.REACT_APP_ONPREMISE == '0' &&
                                                        <div className="col-xl-2 col-md-2 col-xs-6 pr-2 pl-2 select_option mb-3">
                                                            <Autocomplete
                                                                options={this.state.select_option}
                                                                getOptionLabel={option => option.title}
                                                                value={this.state.crr_comp}
                                                                onChange={this.handleChangeOption}
                                                                disableClearable
                                                                renderInput={params => (
                                                                    <TextField
                                                                        {...params}
                                                                        variant="outlined"
                                                                        placeholder=""
                                                                        fullWidth
                                                                    />
                                                                )}
                                                            />
                                                        </div>}
                                                    {
                                                        this.state.searchUnknown === false
                                                            ?
                                                            <>
                                                                {
                                                                    process.env.REACT_APP_ONPREMISE == '0' &&
                                                                    <div className="col-xl-2 col-md-2 col-xs-6 pr-2 pl-2 select_option mb-3">
                                                                        <Autocomplete
                                                                            options={this.state.select_option_type}
                                                                            getOptionLabel={option => option.title}
                                                                            value={this.state.crr_select_type}
                                                                            onChange={this.handleChangeOptionSelectType}
                                                                            disableClearable
                                                                            renderInput={params => (
                                                                                <TextField
                                                                                    {...params}
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                />
                                                                            )}
                                                                        />
                                                                    </div>
                                                                }
                                                                {
                                                                    this.state.exChangeSearch == true
                                                                        ?
                                                                        <div className="col-xl-2 col-md-2 col-xs-12 pr-2 pl-2 mb-3">
                                                                            <input type="text" className="form-control m-input" value={this.state.search} placeholder={language[this.props.indexLanguage].Search.text_placeholder_input_search_history} name="Staff" id="Staff"
                                                                                onChange={e => {
                                                                                    this.setState({
                                                                                        search: e.target.value
                                                                                    })
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        :
                                                                        <div className="col-xs-12 col-xl-2 col-md-2 pr-2 pl-2 width-mobie--100 mb-3">
                                                                            <Files
                                                                                className='files-dropzone truncate col-md-12 btn btn-default width-mobie--100'
                                                                                onChange={this.onFilesChange}
                                                                                onError={this.onFilesError}
                                                                                accepts={['image/png', 'image/jpg', 'image/jpeg']}
                                                                                multiple={false}
                                                                                // maxFiles={3}
                                                                                maxFileSize={10000000}
                                                                                minFileSize={0}
                                                                                clickable
                                                                            >
                                                                                {language[this.props.indexLanguage].textButton.drop_file}
                                                                            </Files>
                                                                        </div>
                                                                }
                                                            </>
                                                            :
                                                            ""
                                                    }
                                                    {
                                                        this.state.exChangeSearch == true
                                                            ?
                                                            <div className="col-xl-2 col-md-2 pr-2 pl-2 m--margin-bottom-10-tablet-and-mobile select_option mb-3">
                                                                {/* <Autocomplete
                                                                    options={this.state.optionDepartment}
                                                                    getOptionLabel={option => option.title}
                                                                    defaultValue={{ 'value': -1, 'title': language[this.props.indexLanguage].placeholderSelect.all_department }}
                                                                    value={this.state.valueDepartment}
                                                                    onChange={this.handleChangeDepartment}
                                                                    disableClearable
                                                                    // disabled={this.state.exChangeSearch == true ? false : true}
                                                                    renderOption={(props, option, state) => (
                                                                        <div {...props}>
                                                                            {formatTitleOption(props)}
                                                                        </div>
                                                                    )}
                                                                    classes={{ paper: classes.paper }}
                                                                    renderInput={params => (
                                                                        <TextField
                                                                            {...params}
                                                                            variant="outlined"
                                                                            placeholder={language[this.props.indexLanguage].placeholderSelect.select_department}
                                                                            fullWidth
                                                                        />
                                                                    )}
                                                                /> */}
                                                                <Button
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            selectDepartment: !this.state.selectDepartment
                                                                        })
                                                                    }}
                                                                    className="btn btn-secondary btn-block m-btn m-btn--icon"
                                                                >
                                                                    <span style={{ color: "#000000" }}>
                                                                        {this.state.valueDepartment.value == -1 ? "Chọn phòng ban" : this.state.valueDepartment.title}
                                                                    </span>
                                                                </Button>
                                                            </div>
                                                            :
                                                            ""
                                                    }
                                                </>
                                                :
                                                ''
                                        }


                                        <div className="col-xs-12 pr-2 pl-2 btn-search mb-3">
                                            <button className="btn btn-accent m-btn m-btn--icon"
                                                onClick={() => {
                                                    const fromDateInit = new Date().setHours(0, 0, 0, 0)
                                                    const toDateInit = new Date().setHours(23, 59, 59, 0)
                                                    const searchByDate = (this.state.crrFrom_Date !== fromDateInit || this.state.crrTo_Date !== toDateInit) ? '- Chon ngay' : ""
                                                    const searchByName = this.state.search.length > 0 ? '- Nhap ten/ID' : ""
                                                    const searchByDepartment = this.state.valueDepartment.value.length > 0 ? '- Chon phong ban' : ""
                                                    ReactGA.event({
                                                        category: 'Lich su / Tim kiem',
                                                        action: 'Tim kiem',
                                                        label: `${this.state.crr_comp.title} ${searchByDate} ${searchByName} ${searchByDepartment}`
                                                    });
                                                    this.setState({
                                                        loading: true,
                                                        listImage: [],
                                                        activePage: 1,
                                                        totalCount: 0,
                                                        crrImages: []
                                                    }, () => {
                                                        this.getImg();
                                                    })
                                                }}
                                            >
                                                <span>
                                                    <i className="la la-filter"></i>
                                                    <span>{language[this.props.indexLanguage].textButton.search}</span>
                                                </span>
                                            </button>
                                        </div>

                                        <div className="ml-2 mb-mobile-10 mb-3">
                                            <button
                                                onClick={() => {
                                                    var dataCameraAll = [];
                                                    if (this.state.box_engine_cf.dataCamera !== undefined) {
                                                        for (let index = 0; index < this.state.box_engine_cf.dataCamera.length; index++) {
                                                            dataCameraAll.push(this.state.box_engine_cf.dataCamera[index].id_box_engine)
                                                        }
                                                    }
                                                    this.setState({
                                                        activePage: 1,
                                                        loading: true,
                                                        listImage: [],
                                                        totalCount: 0,
                                                        crrImages: [],
                                                        crrFrom_Date: new Date().setHours(0, 0, 0, 0),
                                                        crrTo_Date: new Date().setHours(23, 59, 59, 0),
                                                        dataCameraAll: dataCameraAll,
                                                        crrCamera: { title: language[this.props.indexLanguage].placeholderSelect.all_camera, value: dataCameraAll },
                                                        // crr_comp: { title: language[this.props.indexLanguage].placeholderSelect.there_is_data, value: 1 },
                                                        valueDepartment: this.state.optionDepartment[0],
                                                        crr_select_type: { title: language[this.props.indexLanguage].Text_Notifi.text_select_type_history_name, value: 1 },
                                                        search: '',
                                                        exChangeSearch: true,
                                                    }, () => {
                                                        this.getImg();
                                                    })
                                                }}
                                                className="btn btn-secondary m-btn m-btn--icon" id="m_reset">
                                                <span>
                                                    <i className="la la-rotate-left" />
                                                    <span>{language[this.props.indexLanguage].textButton.reload}</span>
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='row-sm row'>
                                            {
                                                this.state.selectDepartment === true && (
                                                    <div className='col-xl-4'>
                                                        <div className='ml-4' style={{ maxHeight: "650px", overflow: "auto", marginRight: "20px", marginTop: "10px", whiteSpace: "nowrap" }}>
                                                            <TreeView item={formatTreeViewData(this.state.optionDepartment)} getList={(data) => {
                                                                // this.setState({
                                                                //     selectDepartment: false
                                                                // })
                                                                console.log(data);
                                                                this.handleChangeDepartment(0, {
                                                                    value: data.value,
                                                                    title: data.title,
                                                                    code: data.code
                                                                });
                                                            }} />
                                                        </div>
                                                    </div>)
                                            }
                                        <div className={this.state.selectDepartment ? "col-xl-8" : "col-xl-12"}>
                                            {/* <span>
                                            Showing {this.state.showFirst} to {this.state.showLast} of {this.state.totalLength} entries
                                        </span> */}
                                            <div className="pl-4">
                                                <Pagination
                                                    firstPageText={language[this.props.indexLanguage].pagination.first}
                                                    nextPageText={language[this.props.indexLanguage].pagination.next}
                                                    lastPageText={language[this.props.indexLanguage].pagination.last}
                                                    prevPageText={language[this.props.indexLanguage].pagination.previous}
                                                    activePage={this.state.activePage}
                                                    itemsCountPerPage={this.itemsPerPage}
                                                    totalItemsCount={this.state.totalCount}
                                                    pageRangeDisplayed={pageRangeDisplayed}
                                                    onChange={this.handlePageChange}
                                                />
                                            </div>
                                            <div className='row-sm row pl-0 pr-0 pb-3 pl-md-3 pr-md-3'>
                                                {bulletedImg}
                                                <PulseLoader
                                                    css={override}
                                                    sizeUnit={"px"}
                                                    size={12}
                                                    margin={'2px'}
                                                    color={'#36D7B7'}
                                                    loading={this.state.loading}
                                                />
                                            </div>
                                            <div className="hide_desktop pl-2" style={{ 'paddingBottom': '50px' }}>
                                                <Pagination
                                                    firstPageText={language[this.props.indexLanguage].pagination.first}
                                                    nextPageText={language[this.props.indexLanguage].pagination.next}
                                                    lastPageText={language[this.props.indexLanguage].pagination.last}
                                                    prevPageText={language[this.props.indexLanguage].pagination.previous}
                                                    activePage={this.state.activePage}
                                                    itemsCountPerPage={this.itemsPerPage}
                                                    totalItemsCount={this.state.totalCount}
                                                    pageRangeDisplayed={pageRangeDisplayed}
                                                    onChange={this.handlePageChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <ShowImg
                                        src={this.state.crrFile == null ? '' : this.state.crrFile.preview.url}
                                        onHide={this.onHide}
                                        show={this.state.is_show}
                                    />
                                </div>
                            </div>
                            {/*end::Form*/}
                        </div>

                    </div>
                </div>
                </div>
            </>
        );
    }
}
const mapStateToProps = state => {
    return {
        indexLanguage: state.changelanguage.indexLanguage,
    }
}
export default withStyles(useStyles)(connect(mapStateToProps, null)(History));
