import $ from 'jquery';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Select from 'react-select';
import Switch from "react-switch";
import swal from 'sweetalert';
import { changelanguage } from '../../../actions/changelanguage';
import language from '../../../language/language';
import Axios from 'axios';
// First way to import
import Store from '../../../store';
import { connect } from 'react-redux'
import Header from '../../layouts/Header';

const nameTab = 'config';



class Settings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLogin: Store.getState().isLogin.isLogin,
            access_token: Store.getState().isLogin.access_token,
            box_engine_cf: Store.getState().boxai.box_engine_cf,
            dataCamera: Store.getState().boxai.box_engine_cf.dataCamera,
            language: Store.getState().changelanguage.language,
            dataRole: Store.getState().role.role,
            type: '',
            idStaffAutoCreate: false,
            incrementalChecked: false,
            isMonth: {
                label: 'Tháng 3',
                value: '01-03',
            },
            isDay: 1,
            dataConfig: 0,
            webHook: '',
            valueTemp: "",
            temp_thresholds: [],
            enable_accumultion: 0,
            expired_accumulation: 0,
            advanceTakeLeave: false,
            dataPost: {
                'id_company': 0,
                content:
                {
                    id_staff_auto_increase: 0,
                    web_hook: {
                        url: '',
                    },
                    temp_thresholds: [],
                    daysoff: {
                        enable_accumultion: 0,
                        expired_accumulation: '01-03',
                        advance_take_leave: 0,
                        date_increase_daysoff: 1,
                    }
                },
               
            }
        };

        Store.subscribe(() => {
            this.setState({
                ...this.state,
                isLogin: Store.getState().isLogin.isLogin,
                access_token: Store.getState().isLogin.access_token,
                box_engine_cf: Store.getState().boxai.box_engine_cf,
                dataCamera: Store.getState().boxai.box_engine_cf.dataCamera,
                language: Store.getState().changelanguage.language,
            }, () => {
                this.configGetByIDCompany();
            });
        });
        this.handleSwitch = this.handleSwitch.bind(this);
    }

    componentDidMount() {
        this.configGetByIDCompany();
    }
    

    componentWillMount() {
        var indexlanguage = 0;
        var value;
        if (this.state.language == "us") {
            indexlanguage = 0 
            value = "us"
        } else if (this.state.language == "vn") {
            indexlanguage = 1
            value = "vn"
        }
        var type = localStorage.getItem("type")
        this.setState({
            type: type,
            value: value,
            isMonth: {
                label: language[this.props.indexLanguage].Month.march, 
                value: '01-03',
            }
        })
        window.bootstrap_select();
    }

    // componentDidMount() {
    //     this.configGetByIDCompany()
    // }

    monthToString = (month) => {
        switch (month) {
            case '01-01':
                return { label: language[this.props.indexLanguage].Month.january, value: month }
            case '01-02':
                return { label: language[this.props.indexLanguage].Month.february, value: month }
            case '01-03':
                return { label: language[this.props.indexLanguage].Month.march, value: month }
            case '01-04':
                return { label: language[this.props.indexLanguage].Month.april, value: month }
            case '01-05':
                return { label: language[this.props.indexLanguage].Month.may, value: month }
            case '01-06':
                return { label: language[this.props.indexLanguage].Month.june, value: month }
            case '01-07':
                return { label: language[this.props.indexLanguage].Month.july, value: month }
            case '01-08':
                return { label: language[this.props.indexLanguage].Month.august, value: month }
            case '01-09':
                return { label: language[this.props.indexLanguage].Month.september, value: month }
            case '01-10':
                return { label: language[this.props.indexLanguage].Month.october, value: month }
            case '01-11':
                return { label: language[this.props.indexLanguage].Month.november, value: month }
            case '01-12':
                return { label: language[this.props.indexLanguage].Month.december, value: month }
            default:
                break;
        }
    }


    configGetByIDCompany = () => {
        if (this.state.box_engine_cf.crrApiSocket == undefined || this.state.box_engine_cf.crrApiSocket == "") return;
        fetch(`${this.state.box_engine_cf.crrApiSocket}/api/config/get_info/${this.state.box_engine_cf.idCompany}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                var valueUrl = ""
                if (data.data.data.web_hook != undefined) {
                    valueUrl = data.data.data.web_hook.url
                }
                var temp_thresholds = []
                for (let index = 0; index < this.state.dataCamera.length; index++) {
                    var temperature_threshold = ""
                    if (data.data.data.temp_thresholds !== undefined) {
                        for (let i = 0; i < data.data.data.temp_thresholds.length; i++) {
                            if (this.state.dataCamera[index].id_box_engine == data.data.data.temp_thresholds[i].cam_id) {
                                temperature_threshold = data.data.data.temp_thresholds[i].temperature_threshold
                            }
                        }
                    }

                    temp_thresholds.push({
                        cam_id: this.state.dataCamera[index].id_box_engine,
                        temperature_threshold: temperature_threshold,
                        alias: this.state.dataCamera[index].alias_box_engine
                    })
                }
                this.setState({
                    webHook: valueUrl,
                    temp_thresholds: temp_thresholds,
                    incrementalChecked: (data.data.data.daysoff && data.data.data.daysoff.enable_accumultion === 1) ? true : false,
                    isMonth: data.data.data.daysoff ? this.monthToString(data.data.data.daysoff.expired_accumulation) : this.state.isMonth,
                    isDay: data.data.data.daysoff.date_increase_daysoff ? data.data.data.daysoff.date_increase_daysoff : this.state.isDay,
                    advanceTakeLeave: (data.data.data.daysoff && data.data.data.daysoff.advance_take_leave === 1) ? true : false,
                    idStaffAutoCreate: data.data.data.id_staff_auto_increase === 1 ? true : false,
                }, () => {
                    this.setState({
                        dataPost: {
                            'id_company': this.state.box_engine_cf.idCompany,
                            content:
                            {
                                id_staff_auto_increase: this.state.idStaffAutoCreate === true ? 1 : 0,
                                web_hook: {
                                    url: this.state.valueUrl
                                },
                                temp_thresholds: this.state.temp_thresholds,
                                daysoff: {
                                    enable_accumultion: this.state.incrementalChecked === true ? 1 : 0,
                                    expired_accumulation: this.state.isMonth.value,
                                    advance_take_leave: this.state.advanceTakeLeave === true ? 1 : 0,
                                    date_increase_daysoff: this.state.isDay,
                                }
                            },
                           
                        }
                    })
                })
            }
        })
    }

    SubmitUrl = async () => {
        if (this.state.box_engine_cf.crrApiSocket == undefined || this.state.box_engine_cf.crrApiSocket == "") return;

        const result = await Axios({
            method: 'POST',
            url: `${this.state.box_engine_cf.crrApiSocket}/api/config/insert_or_update`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            data: JSON.stringify(this.state.dataPost),
        });
        try {
            if (result.data.status === 10000) {
                swal(language[this.props.indexLanguage].Text_Notifi.success, language[this.props.indexLanguage].Settings.notifi_success, "success", {
                    buttons: false,
                    timer: 1500,
                });
                this.configGetByIDCompany()
            } else if (result.data.status === 10001) {
                swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].Settings.notifi_warning, "warning");
            }
            else {
                swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].Settings.notifi_error, "error");
            }
        } catch (error) {
            console.log(error)
        }
    }

    handleChangeInput = (event, index) => {
        var { temp_thresholds } = this.state
        temp_thresholds[index].temperature_threshold = parseFloat(event.target.value)
        this.setState({
            temp_thresholds,
            dataPost: {
                ...this.state.dataPost,
                content: {
                    ...this.state.dataPost.content,
                    temp_thresholds: temp_thresholds,
                }
            }
        });
    }

    handleSwitch(e, name) {
        let name_property = '';

        if (name === 'idStaffAutoCreate') {
            swal({
                title: this.state.dataConfig == 1 ? language[this.props.indexLanguage].Settings.notifi_change_default : language[this.props.indexLanguage].Settings.notifi_change_auto,
                text: this.state.dataConfig == 1 ? language[this.props.indexLanguage].Settings.notifi_change_default_text : language[this.props.indexLanguage].Settings.notifi_change_auto_text,
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then(switchID => {
                    if (switchID) {
                        name_property = 'id_staff_auto_increase';
                        return this.setState({
                            [name]: e,
                            dataPost: {
                                ...this.state.dataPost,
                                content: {
                                    ...this.state.dataPost.content,
                                    [name_property]: e === true ? 1 : 0,
                                    web_hook: {
                                        url: this.state.webHook,
                                    }
                                }
                            },
                        },() => {
                            this.SubmitUrl();
                        })
                    };
                })

        } else {
            swal({
                title: language[this.props.indexLanguage].Text_Notifi.are_you_sure,
                text: language[this.props.indexLanguage].Text_Notifi.notifi_active,
                icon: "warning",
                buttons: {
                    oke: language[this.props.indexLanguage].textButton.oke,
                    cancel: language[this.props.indexLanguage].textButton.close
                },
            }).then(switchName => {
                if (switchName) {
                    if (name === 'incrementalChecked') {
                        name_property = 'enable_accumultion';
                    } else if (name === 'advanceTakeLeave') {
                        name_property = 'advance_take_leave';
                    }
                    return this.setState({
                        [name]: e,
                        dataPost: {
                            ...this.state.dataPost,
                            content: {
                                ...this.state.dataPost.content,
                                daysoff: {
                                    ...this.state.dataPost.content.daysoff,
                                    [name_property]: e === true ? 1 : 0,
                                }
                            }
                        }
                    }, () => {
                        this.SubmitUrl();
                    })
                }
            })

        }
    }

    render() {
        if (this.state.isLogin === false) {
            return (
                <Redirect to={'/login'} />
            )
        }
        return (
            <>
            <Header />
            <div className={"m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body "}>
            <div className="m-grid__item m-grid__item--fluid m-wrapper p-2">
                <div className="m-content">
                    <div className="m-portlet m-portlet--space">
                        <div className="m-portlet__head">
                            <div className="m-portlet__head-caption">
                                <div className="m-portlet__head-title">
                                    <h3 className="m-portlet__head-text">
                                        {language[this.props.indexLanguage].Infor.settings}
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className="m-portlet__body">
                            <div className="row">
                                {
                                    this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':insert_or_update') !== -1
                                        ?
                                        <>
                                            <div className="row col-md-12 mb-4">
                                                <div className="col-md-4 col-xl-2">
                                                    {language[this.props.indexLanguage].Settings.id_staff_auto_create}
                                                </div>
                                                <div className="col-md-8 col-xl-10">
                                                    <Switch
                                                        onChange={(e) => this.handleSwitch(e, 'idStaffAutoCreate')}
                                                        checked={this.state.idStaffAutoCreate}
                                                        handleDiameter={17}
                                                        offColor="#969696"
                                                        onColor="#00c5dc"
                                                        offHandleColor="#ffffff"
                                                        onHandleColor="#08f"
                                                        height={25}
                                                        width={55}
                                                        className="react-switch"
                                                        id="small-radius-switch"
                                                        disabled = {
                                                            this.state.idStaffAutoCreate === false ? true : false
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="row col-md-12 mb-4">
                                                <div className="col-md-4 col-xl-2">
                                                    {language[this.props.indexLanguage].Settings.webhook}
                                                </div>
                                                <div className="col-md-8 col-xl-10">
                                                    <div id="formUpdateConfig">
                                                        <div className="form-group m-form__group row m-0">
                                                            <input className="form-control col-8 col-md-4 col-xl-2" placeholder={language[this.props.indexLanguage].Settings.placeholderWebHook} value={this.state.webHook} onChange={(e) => {
                                                                this.setState({
                                                                    webHook: e.target.value,
                                                                    dataPost: {
                                                                        ...this.state.dataPost,
                                                                        content: {
                                                                            ...this.state.dataPost.content,
                                                                            web_hook: {
                                                                                url: e.target.value,
                                                                            }
                                                                        }
                                                                    }
                                                                })
                                                            }} />
                                                            <button className="btn btn-accent ml-2 m-loader--light m-loader--right" id="btnSubmitUrl" onClick={this.SubmitUrl}>{language[this.props.indexLanguage].Profile.submit}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row col-md-12">
                                                <div className="col-md-4 col-xl-2">
                                                    {language[this.props.indexLanguage].Settings.temperature}
                                                </div>
                                                <div className="col-md-8 col-xl-10">
                                                    <div id="formUpdateConfig">
                                                        <div className="form-group m-form__group m-0">
                                                            {
                                                                this.state.temp_thresholds.map((value, index) => {
                                                                    return (
                                                                        <div className="form-group m-form__group" key={index}>
                                                                            <label htmlFor={"temp_" + value.cam_id}>BiFaceID: {value.alias ? `${value.alias} ( ${value.cam_id} )` : value.cam_id}</label>
                                                                            <input type="number" className="form-control m-input col-md-3" min="0" id={"temp_" + value.cam_id} value={value.temperature_threshold} onChange={(event) => this.handleChangeInput(event, index)} placeholder={`${language[this.props.indexLanguage].Settings.temperature}`} />
                                                                        </div>
                                                                    )
                                                                })
                                                            }

                                                            <button className="btn btn-accent m-loader--light m-loader--right" id="btnSubmitUrl" onClick={this.SubmitUrl}>{language[this.props.indexLanguage].Profile.submit}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                           
                                        </>
                                        :
                                        ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </>
        );
    }
}
const mapStateToProps = state => {
    return {
        indexLanguage: state.changelanguage.indexLanguage,
    }
}
export default connect(mapStateToProps, null)(Settings);
