import React, { useEffect } from 'react';
import { useState } from 'react';
import { Checkbox } from '@material-ui/core';
import axios from 'axios';
import Store from '../../../../store';
import { PulseLoader } from 'react-spinners';
import { css } from '@emotion/core';
import ReactTooltip from 'react-tooltip';
import {useSelector} from 'react-redux';
import Switch from "react-switch";
import { makeStyles } from "@material-ui/core/styles";
import swal from 'sweetalert';
import { CSSTransition } from 'react-transition-group';
import language from '../../../../language/language';
import ReasonOffForm from './components/reasonOffForm';
import ReactGA from 'react-ga';

const NameTag = 'reason_off';

const useStyles = makeStyles(() => ({
  root: {
      padding: '0',
  }
}));

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;


function ReasonOff(props) {

    const [showModal,setShowModal] = useState(false);
    const [crrData,setCrrData] = useState([]);
    const [dataReason,setDataReason] = useState({});
    const [loading,setLoading]= useState(false);
    const [checkbox,setCheckBox] = useState([]);
    const [isCheckedAll,setIsCheckedAll] = useState(false);
  
    const classes = useStyles();
  
    const dataRole = useSelector(state => state.role.role);
    const box_engine_cf = useSelector(state => state.boxai.box_engine_cf);
  
    const type = localStorage.getItem('type');
  
    
    const indexLanguage = useSelector(state => state.changelanguage.indexLanguage);
  
    const initial = {
      id: 0,
      name: '',
      description: '',
      coefficient: 100,
      status: 1,
      symbol: '',
      id_company: box_engine_cf.idCompany,
    }
  
     const multipleCheckbox = (e, id) => {
       setCheckBox([...checkbox, id]);
       if (!e.target.checked) {
         setCheckBox(checkbox.filter(items => items !== id))
       }
     }
   
    const checkBoxAll = () => {
      setIsCheckedAll(!isCheckedAll);
      setCheckBox(crrData.map(li => li.id)); 
      if(isCheckedAll){
        setCheckBox([]);
      }
    }
    const handleCloseModal = () => {
      setShowModal(false);
    }
  
    const handleShowModal = (e,value) => {
      setShowModal(true);
      if(value){
        setDataReason({
          id: value.id,
          name: value.name,
          symbol: value.symbol,
          coefficient: value.coefficient,
          id_company: value.id_company,
          symbol: value.symbol,
          status: value.status,
        })
      }else{
       setDataReason(initial);
      }
    }
    const handleStatus = async (checked,value) => {
      let dataPerson = value;
      if(checked){
        dataPerson = {...dataPerson,status: 1}
      }else{
        dataPerson = {...dataPerson,status: 0}
      }
      const data = await axios({
        method: 'POST',
        url: `${box_engine_cf.crrApiSocket}/api/reason_off/insert_or_update`,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': Store.getState().isLogin.access_token
        },
        data: dataPerson,
    })
    if(data.data.status === 10000){
      swal({ title: language[indexLanguage].Text_Notifi.success, text: language[indexLanguage].Text_Notifi.update_success, icon: "success", buttons: false, timer: 1500 });
      fetchData();
    }else{
        swal({ title: language[indexLanguage].Text_Notifi.error, text: language[indexLanguage].Text_Notifi.update_false, icon: "error", buttons: false, timer: 1500 });
    }
    }
    const handleDeleteById = (id , name) => {
      swal({
        title: language[indexLanguage].Text_Notifi.are_you_sure,
        text: language[indexLanguage].Text_Notifi.notifi_sure_delete_reason_off + name,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then(async (willDelete) => {
        if (willDelete) {
          const data = await axios({
            method: 'POST',
            url: `${box_engine_cf.crrApiSocket}/api/reason_off/delete`,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': Store.getState().isLogin.access_token
            },
            data: {
              ids: [id]
            }
          })
          if(data.data.status === 10000){
            swal(language[indexLanguage].Text_Notifi.success, language[indexLanguage].Text_Notifi.notifi_delete_success, "success", {
              buttons: false,
              timer: 1500,
          });
          fetchData();
          }else {
            swal(language[indexLanguage].Text_Notifi.error, language[indexLanguage].Text_Notifi.notifi_delete_false, "error");
        }
        }
      });
    }
    const handleDeleteMulti = () => {
      
      swal({
        title: language[indexLanguage].Text_Notifi.are_you_sure,
        text: language[indexLanguage].Text_Notifi.notifi_sure_delete_reason_off,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then(async (willDelete) => {
        if (willDelete) {
          if(checkbox.length === 0){
            swal(language[indexLanguage].Text_Notifi.warning, language[indexLanguage].Text_Notifi.notifi_sure_not_reason_off, "warning");
            return;
          }
          const data = await axios({
            method: 'POST',
            url: `${box_engine_cf.crrApiSocket}/api/reason_off/delete`,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': Store.getState().isLogin.access_token
            },
            data: {
              ids: checkbox,
            }
          })
          if(data.data.status === 10000){
            swal(language[indexLanguage].Text_Notifi.success, language[indexLanguage].Text_Notifi.notifi_delete_success, "success", {
              buttons: false,
              timer: 1500,
          });
          setIsCheckedAll(false);
          setCheckBox([]);
          fetchData();
          }else {
            swal(language[indexLanguage].Text_Notifi.error, language[indexLanguage].Text_Notifi.notifi_delete_false, "error");
        }
        }
      });
    }
    
    const fetchData = async () => {
      const data = await axios({
        method: 'GET',
        url: `${box_engine_cf.crrApiSocket}/api/reason_off/${box_engine_cf.idCompany}/get_all`,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': Store.getState().isLogin.access_token
        },
      })
      if (data.data.status === 10000) {
        setCrrData(data.data.data);
        setLoading(false);
      }
    }
  
  
    useEffect(() => {
      setLoading(true);
      if(box_engine_cf){
        fetchData();
      }
    },[box_engine_cf.idCompany])
    
    const tableRows = crrData.map((value, index) => {
      let check = true;
      if(value.status === 0){
        check = false;
      }
      if(value.state === 1){
        check = true;
      }
      if(dataRole.indexOf(`${type}/${NameTag}:get_all`) !== -1) return (
        <tr key={index}>
          {/* {
            dataRole.indexOf(`${type}/${NameTag}:delete`) !== -1 &&
            <td><Checkbox classes={{ root: classes.root }}
              color="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
              onChange={(e) => multipleCheckbox(e, value.id)}
              checked={checkbox.includes(value.id)}
            /></td>
          } */}
          <td>{value.name}</td>
          <td>{value.symbol.toUpperCase()}</td>
          <td>{value.coefficient > 0 ? language[indexLanguage].ReasonOff.yes : language[indexLanguage].ReasonOff.no}</td>
          <td>{value.coefficient + '%'}</td>
          {
            dataRole.indexOf(`${type}/${NameTag}:insert_or_update`) !== -1 ?
            <td><Switch handleDiameter={17}
            offColor="#969696"
            onColor="#00c5dc"
            offHandleColor="#ffffff"
            onHandleColor="#08f"
            height={25}
            width={55}
            disabled={dataRole.indexOf(`${type}/${NameTag}:insert_or_update`) === -1}
            className="react-switch" onChange={(checked) => {
              swal({
                title: language[indexLanguage].Text_Notifi.are_you_sure ,
                text: language[indexLanguage].Text_Notifi.notifi_sure_change_status + value.name,
                icon: "warning",
                button: {
                  text: "Oke!",
                  closeModal: false,
                },
              })
                .then(name => {
                  ReactGA.event({
                    category: 'Li do nghi',
                    action: value.status !== 1 ? 'Chuyen trang thai active' : 'Chuyen trang thai Inactive',
                  });
                  if (!name) throw null;
                  return handleStatus(checked, value);
                })
            }} name='1' checked={check} /></td>
            :
            <td>
              {
                value.status === 1 ? 
                <span class="m-badge  m-badge--accent m-badge--wide">Active</span>
                :
              <span class="m-badge  m-badge--secondary m-badge--wide">Inactive</span>
              }
            </td>
          }
          
          {
            (dataRole.indexOf(`${type}/${NameTag}:insert_or_update`) !== -1/* && dataRole.indexOf(`${type}/${NameTag}:delete`) !== -1*/) &&
            <td>
              {
                dataRole.indexOf(`${type}/${NameTag}:insert_or_update`) !== -1 &&
                <>
                  <button onClick={(e) => handleShowModal(e, value)} className="m-portlet__nav-link btn m-btn m-btn--hover-warning m-btn--icon m-btn--icon-only m-btn--pill" data-tip data-for='Edit'>
                    <i className="la la-edit" />
                  </button>
                  <ReactTooltip id='Edit' type='dark' effect='solid'>
                  <span>{language[indexLanguage].tooltip.open_edit}</span>
                  </ReactTooltip>
                </>
              }
              {/* {
                dataRole.indexOf(`${type}/${NameTag}:delete`) !== -1 &&
                <>
                  <button onClick={() => handleDeleteById(value.id, value.name)} className="m-portlet__nav-link btn m-btn m-btn--hover-warning m-btn--icon m-btn--icon-only m-btn--pill" data-tip data-for='Delete'>
                    <i className="la la-trash" />
                  </button>
                  <ReactTooltip id='Delete' type='dark' effect='solid'>
                    <span>{language[indexLanguage].tooltip.open_delete}</span>
                  </ReactTooltip>
                </>
              } */}
            </td>
          }
        </tr>
      );
    });
  
    if(!crrData) return <></>
    return (
      <div className='m-grid__item m-grid__item--fluid m-wrapper'>
        <div className='m-content p-2'>
          <div className='m-portlet--tab mb-0'>
          <div className="m-portlet__head p-3">
              <div className="m-portlet__head-caption pl-3">
                {/* <div className="m-portlet__head-title">
                  <h3 className="m-portlet__head-text">{language[indexLanguage].DayOff.reason_off}</h3>
                </div> */}
              </div>
              {
                dataRole.indexOf(`${type}/${NameTag}:insert_or_update`) !== -1 &&
                <div className="m-portlet__head-tools pr-md-3">
                  <ul className="m-portlet__nav">
                    <li className="m-portlet__nav-item">
                      <button
                        className='btn btn-accent m-btn m-btn--custom m-btn--icon m-btn--pill m-btn--air'
                        style={{ fontFamily: 'inherit' }}
                        onClick={handleShowModal}
                      >
                        <span>
                          <i className="la la-plus"></i>
                          <span>{language[indexLanguage].textButton.add}</span>
                        </span>
                      </button>
                    </li>
                  </ul>
                </div>
              }
            </div>
            <div className='m-portlet__body m-portlet__body--no-padding'>
              <div className='row m-row--no-padding m-row--col-separator-xl'>
                <div className='col-xl-8 mx-auto'>
                  <div className='m-widget1 col-xl-12 mx-auto'>
                    {/* <div className='row col-md-12 ml-0 mr-0 p-0 mb-2' style={{height:'45px'}}>
                      <div className='col-lg-2 pl-0 m--margin-bottom-10-tablet-and-mobile'>
                        <CSSTransition 
                        timeout={300} 
                        in={buttonMultipleDelete} 
                        unmountOnExit
                        >
                            <button
                              className='btn btn-accent m-btn m-btn--icon'
                              style={{ fontFamily: 'inherit', transition: 'all .5s' }}
                              onClick={handleDeleteMulti}
                            >
                              <span>
                              <span>{language[indexLanguage].Text_Notifi.notifi_sure_delete_select}</span>
                              </span>
                            </button>
                        </CSSTransition>
                      </div>
                    </div> */}
                    <div className='table-responsive text-nowrap'>
                      <table className='table table-bordered table-hover table-checkable dataTable no-footer dtr-inline collapsed'>
                        <thead>
                          <tr>
                            {/* {
                              dataRole.indexOf(`${type}/${NameTag}:delete`) !== -1 &&
                              <th
                                style={{ verticalAlign: 'middle', width: '40px' }}
                              >
                                <Checkbox classes={{ root: classes.root }}
                                  color="secondary"
                                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                                  onChange={() => checkBoxAll()}
                                  checked={isCheckedAll}
                                />
                              </th>
                            } */}
                            <th style={{ verticalAlign: 'middle' }}>{language[indexLanguage].ReasonOff.reason}</th>
                            <th style={{ verticalAlign: 'middle' }}>{language[indexLanguage].ReasonOff.symbol}</th>
                            <th style={{ verticalAlign: 'middle' }}>{language[indexLanguage].ReasonOff.calculation}</th>
                            <th style={{ verticalAlign: 'middle' }}>
                            {language[indexLanguage].ReasonOff.calculation_percent}
                            </th>
                            <th style={{ verticalAlign: 'middle' }}>
                            {language[indexLanguage].ReasonOff.status}
                            </th>
                            {
                              dataRole.indexOf(`${type}/${NameTag}:insert_or_update`) !== -1 &&
                              <th style={{ verticalAlign: 'middle',width:'100px' }}>{language[indexLanguage].ReasonOff.action}</th>
                            }
                          </tr>
                        </thead>
                        <tbody>{tableRows}</tbody>
                      </table>
                      <PulseLoader
                          css={override}
                          sizeUnit={"px"}
                          size={12}
                          margin={'2px'}
                          color={'#36D7B7'}
                          loading={loading}
                        />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ReasonOffForm show={showModal} data={dataReason} onRefresh={fetchData} box_engine_cf={box_engine_cf} onHide={handleCloseModal}/>
      </div>
    );
  }

  export default ReasonOff;
