import VN from './vietnamese.json';
import EN from './english.json';
import JP from './japanese.json';
let language = [];

language.push(EN)
language.push(VN)
language.push(JP)

console.log(language)
export default language;
