import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardTimePicker } from '@material-ui/pickers';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import { getApiOverviewFull, updateTimeKeeping } from '../../../../../api';
import language from '../../../../../language/language';
import HeaderTimekeeping from '../components/HeaderTimekeeping';
import LoadingOverLay from '../components/LoadingOverlay';
import PaginationTab from '../components/PaginationTab';
import { processDataListDay } from '../components/processData';
import TreeView from '../../../TreeView';
import { getCameraArray, formatTreeViewData, formatUsernameFromEmail } from '../../../CommonFunction';
const portletBodyCss = {
    paddingTop: 0,
    paddingBottom: 0,
};

TimekeepingDetail.propTypes = {
    data: PropTypes.object,
};
TimekeepingDetail.defaultProps = {
    data: null,
}

const default_data_post = {
    id: 0,
    id_staff: 0,
    id_shift_work: 0,
    date: '',
    timein: '',
    timeout: '',
}

function TimekeepingDetail(props) {
    const { data } = props;
    const indexLanguage = useSelector(state => state.changelanguage.indexLanguage);
    const box_engine_cf = useSelector(state => state.boxai.box_engine_cf);
    const token = useSelector(state => state.isLogin.access_token);

    const [totalItem, setTotalItem] = useState(0);
    const [crrData, setCrrData] = useState(null);
    const [loading, setLoading] = useState(false);

    const type = localStorage.getItem("type");
    const dataRole = useSelector(state => state.role.role);
    const [selectDepartment, setSelectDepartment] = useState(false);
    const [departmentCurrent, setDepartmentCurrent] = useState({
        name: language[indexLanguage].placeholderSelect.all_department,
        value: -1,
    });

    const cameraArray = getCameraArray(box_engine_cf.dataCamera);
    const initial = {
        data_search: '',
        id_shift: -1,
        id_department: -1,
        start_date: moment().startOf('month').format('YYYY-MM-DD'),
        end_date: moment(new Date).format('YYYY-MM-DD'),
        id_company: box_engine_cf.idCompany,
        index: 1,
        is_export: 0,
        item_per_page: 20,
    }
    const [dataSearch, setDataSearch] = useState(initial);
    const [dataEditPost, setDataEditPost] = useState(default_data_post);
    const [showOpenEdit, setShowOpenEdit] = useState('');
    const [crrShift, setCrrShift] = useState('');

    const getDataSearch = (data) => {
        const dataPost = {
            ...Object.assign(dataSearch, data),
            index: 1,
        };
        setDataSearch(dataPost);
        fetchData(dataPost);
    }
    const getDataPagination = (data) => {
        const dataPost = Object.assign(dataSearch, data);
        fetchData(dataPost);
    }
    const fetchData = async (dataPost) => {
        setLoading(true);
        setCrrData(null);
        setTotalItem(0);
        const result = await getApiOverviewFull(box_engine_cf.crrApiSocket, dataPost, token);

        setTotalItem(result.count);
        setCrrData(processData(result.data, result.key));
        setLoading(false);
    }
    const handleSelectDepartment = () => {
        setSelectDepartment(prev => !prev);
    }
    const timePickerTimeIn = (index, time) => {
        const value_time = time ? `${new Date(moment(time, 'HH:mm:ss'))}` : null;
        const timein = `${new Date(moment(dataEditPost.timein, 'HH:mm:ss'))}`;

        return <>
            {
                showOpenEdit === index ?
                    <KeyboardTimePicker
                        placeholder='HH:mm'
                        mask='__:__'
                        ampm={false}
                        style={{ width: "100%" }}
                        value={timein !== 'Invalid Date' ? timein : value_time}
                        invalidDateMessage={
                            language[indexLanguage].Text_Notifi
                                .notifi_date_time_invalid
                        }
                        onChange={(date) => setDataEditPost({
                            ...dataEditPost,
                            timein: moment(date).format('HH:mm'),
                        })}
                    />
                    :
                    <div>{time}</div>
            }
        </>
    }
    const timePickerTimeOut = (index, time) => {
        const value_time = time ? `${new Date(moment(time, 'HH:mm:ss'))}` : null;
        const timeout = `${new Date(moment(dataEditPost.timeout, 'HH:mm:ss'))}`;
        return <>
            {
                showOpenEdit === index ?
                    <KeyboardTimePicker
                        placeholder='HH:mm'
                        mask='__:__'
                        ampm={false}
                        style={{ width: "100%" }}
                        value={timeout !== 'Invalid Date' ? timeout : value_time}
                        invalidDateMessage={
                            language[indexLanguage].Text_Notifi
                                .notifi_date_time_invalid
                        }
                        onChange={(date) => setDataEditPost({
                            ...dataEditPost,
                            timeout: moment(date).format('HH:mm'),
                        })}
                    />
                    :
                    <div>{time}</div>
            }
        </>
    }
    const timeKeepingShift = (index, shift) => {
        return <>
            {
                showOpenEdit === index ?
                    <Autocomplete
                        className='select_option'
                        options={data ? data.list_shift.slice(1) : null}
                        getOptionLabel={option => option.title}
                        value={crrShift ? crrShift : shift}
                        onChange={(e, value) => {
                            setDataEditPost({
                                ...dataEditPost,
                                id_shift_work: value.value,
                            });
                            setCrrShift(value);
                        }}
                        disableClearable
                        renderInput={params => (
                            <TextField
                                {...params}
                                variant="outlined"
                                placeholder={language[indexLanguage].placeholderSelect.select_shift}
                                fullWidth
                            />
                        )}
                    />
                    :
                    <div>{shift.title}</div>
            }
        </>
    }
    const timeKeepingAction = (index, e) => {
        return <>
            {
                showOpenEdit === index ?
                    <>
                        <button
                            onClick={() => handleSaveEdit(index)}
                            className='m-portlet__nav-link btn m-btn m-btn--hover-warning m-btn--icon m-btn--icon-only m-btn--pill'
                            data-tip
                            data-for='Save'
                        >
                            <i className='la la-check' />
                        </button>
                        <button
                            onClick={() => handleCloseEdit()}
                            className='m-portlet__nav-link btn m-btn m-btn--hover-warning m-btn--icon m-btn--icon-only m-btn--pill'
                            data-tip
                            data-for='Close'
                        >
                            <i className='la la-times' />
                        </button>
                    </>
                    :
                    <>
                        <button
                            onClick={() => handleOpenEdit(index, e)}
                            className='m-portlet__nav-link btn m-btn m-btn--hover-warning m-btn--icon m-btn--icon-only m-btn--pill'
                            data-tip
                            data-for='Edit'
                        >
                            <i className='la la-edit' />
                        </button>
                    </>
            }
        </>
    }

    const handleOpenEdit = (index, data) => {
        if (showOpenEdit === '') {
            setShowOpenEdit(index);
            setDataEditPost({
                ...dataEditPost,
                timein: data.time_in,
                timeout: data.time_out,
                date: data.date,
                id_staff: parseInt(data.id_staff_db),
                id_shift_work: data.shift && data.shift.id > 0 ? data.shift.id : null,
                id: data.id ? data.id : 0,
            })
        }
        if (showOpenEdit !== '' && showOpenEdit !== index) {
            swal({
                title: language[indexLanguage].Text_Notifi.are_you_sure,
                text: language[indexLanguage].Timekeeping.cancel_edit,
                icon: "warning",
                buttons: {
                    oke: language[indexLanguage].textButton.oke,
                    cancel: language[indexLanguage].textButton.close
                },
                dangerMode: true,
            }).then(async (willDelete) => {
                if (willDelete === 'oke') {
                    setCrrShift('');
                    setShowOpenEdit(index);
                    setDataEditPost({
                        ...dataEditPost,
                        timein: data.time_in,
                        timeout: data.time_out,
                        date: data.date,
                        id_staff: parseInt(data.id_staff_db),
                        id_shift_work: data.shift && data.shift.id > 0 ? data.shift.id : null,
                        id: data.id ? data.id : 0,
                    })
                }
            });
        }
    }

    const handleCloseEdit = () => {
        swal({
            title: language[indexLanguage].Text_Notifi.are_you_sure,
            text: language[indexLanguage].Timekeeping.cancel_edit,
            icon: "warning",
            buttons: {
                oke: language[indexLanguage].textButton.oke,
                cancel: language[indexLanguage].textButton.close
            },
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete === 'oke') {
                setCrrShift('');
                setShowOpenEdit('');
                setDataEditPost(default_data_post)
            }
        });
    }
    const handleSaveEdit = async () => {
        swal({
            title: language[indexLanguage].Text_Notifi.are_you_sure,
            text: language[indexLanguage].Timekeeping.save,
            buttons: {
                oke: language[indexLanguage].textButton.oke,
                cancel: language[indexLanguage].textButton.close
            },
            dangerMode: true,
        }).then(async (willDelete) => {
            if (dataEditPost.timein == 'Invalid date' || !dataEditPost.timein.length) {
                swal({
                    title: language[indexLanguage].Text_Notifi.warning,
                    text: language[indexLanguage].Timekeeping.time_in_required,
                    icon: "warning",
                });
            } else {
                if (willDelete === 'oke') {
                    if (dataEditPost.timeout && dataEditPost.timein && dataEditPost.timeout <= dataEditPost.timein) {
                        return swal(language[indexLanguage].Text_Notifi.warning, language[indexLanguage].Text_Notifi.notifi_start_time, "warning");
                    }
                    let dataPost = {
                        ...dataEditPost,
                        date: dataEditPost.date + ' 00:00:00',
                        timein: (dataEditPost.timein !== 'Invalid date' && dataEditPost.timein !== '') ? (dataEditPost.date + ' ' + dataEditPost.timein) : null,
                        timeout: (dataEditPost.timeout !== 'Invalid date' && dataEditPost.timeout !== '') ? (dataEditPost.date + ' ' + dataEditPost.timeout) : null,
                    };

                    const status = await updateTimeKeeping(box_engine_cf.crrApiSocket, dataPost, token);
                    if (status === 10000) {
                        swal({
                            title: language[indexLanguage].Text_Notifi.success,
                            text: language[indexLanguage].Text_Notifi.success_update,
                            icon: "success",
                            timer: 1000,
                        });
                        fetchData(dataSearch);
                        setCrrShift('');
                        setShowOpenEdit('');
                        setDataEditPost(default_data_post);
                    } else {
                        swal({
                            title: language[indexLanguage].Text_Notifi.success,
                            text: language[indexLanguage].Text_Notifi.success_update,
                            icon: "error",
                            timer: 1000,
                            buttons: true,
                        });
                    }
                }
            }
        });

    }

    const processData = (data, key) => {
        const result = processDataListDay(data, key, dataSearch);
        return result;
    }
    const renderCamera = (cameras) => {
        if (typeof cameras == "undefined" || cameras == "" || cameras == null)
            return "";
        var camera_array = cameras.split(',');
        var camera_name = [];
        camera_array.forEach(element => {
            camera_name.push(cameraArray[parseInt(element)]);
        });
        return camera_name.join(",");
    }
    const viewData = (data, i) => {
        return <tr key={i}>
            {/* <td>{i + 1}</td> */}
            <td>{data.id_staff}</td>
            <td>{formatUsernameFromEmail(data.email)}</td>
            <td>{data.name}</td>
            <td>{data.department}</td>
            <td>{moment(data.date).format('DD-MM-YYYY')}</td>
            <td>{data.day}</td>
            <td>{timePickerTimeIn(i, data.time_in)}</td>
            <td>{timePickerTimeOut(i, data.time_out)}</td>
            <td>{data.in_soon != 0 ? parseInt(data.in_soon) : ""}</td>
            <td>{data.in_late != 0 ? parseInt(data.in_late) : ""}</td>
            <td>{data.out_soon != 0 ? parseInt(data.out_soon) : ""}</td>
            <td>{data.out_late != 0 ? parseInt(data.out_late) : ""}</td>
            <td>{data.work}</td>
            <td>{data.reason_text}</td>
            <td>{timeKeepingShift(i, data.shift)}</td>
            <td>{renderCamera(data.box_engine_id)}</td>
            {dataRole.indexOf(type + '/timekeeping:update_shift') !== -1 && <td>{timeKeepingAction(i, data)}</td>}
        </tr>
    }

    useEffect(() => {
        if (box_engine_cf) {
            const dataPost = {
                ...dataSearch,
                id_company: box_engine_cf.idCompany,
            }
            fetchData(dataPost);
            setDataSearch({
                ...dataSearch,
                id_company: box_engine_cf.idCompany,
            })
        }
    }, [box_engine_cf.idCompany]);
    return (
        <div>
            <HeaderTimekeeping selectedDepartment={departmentCurrent} data={dataSearch} list_shift={data ? data.list_shift : null} list_department={data ? data.list_department : null} tab={2} getData={getDataSearch} selectDepartment={handleSelectDepartment} />
            <LoadingOverLay loading={loading} />
            <div className="row-sm row m-portlet__body" style={portletBodyCss}>
                {
                    selectDepartment && (
                        <div className='m-portlet__body dashboard_spinner col-xl-4'>
                            <div style={{ maxHeight: "600px", overflow: "auto", marginRight: "20px", whiteSpace: "nowrap" }}>
                                <TreeView
                                    item={formatTreeViewData(data ? data.list_department : [])}
                                    getList={(data) => {
                                        setDepartmentCurrent({
                                            name: data.title,
                                            value: data.value,
                                            code: data.code
                                        });
                                        getDataSearch({ id_department: data.value });
                                    }}
                                />
                            </div>
                        </div>
                    )
                }
                <div className={selectDepartment ? 'col-xl-8' : 'col-xl-12'}>
                    <div className="table-responsive show-scroll-hoz text-nowrap mt-3">
                        <table className="table table-bordered bg-white table-hover table-checkable dataTable no-footer dtr-inline collapsed mb-0">
                            <thead>
                                <tr>
                                    <th style={{ 'verticalAlign': 'middle' }}>ID</th>
                                    <th style={{ 'verticalAlign': 'middle' }}>Username</th>
                                    <th style={{ 'verticalAlign': 'middle', width: '160px' }}>{language[indexLanguage].Timekeeping.name}</th>
                                    <th style={{ 'verticalAlign': 'middle' }}>{language[indexLanguage].Timekeeping.department}</th>
                                    <th style={{ 'verticalAlign': 'middle' }}>{language[indexLanguage].Timekeeping.date}</th>
                                    <th style={{ 'verticalAlign': 'middle', width: '130px' }}>{language[indexLanguage].Timekeeping.day_of_week}</th>
                                    <th style={{ 'verticalAlign': 'middle', width: '120px' }}>{language[indexLanguage].Timekeeping.time_in}</th>
                                    <th style={{ 'verticalAlign': 'middle', width: '120px' }}>{language[indexLanguage].Timekeeping.time_out}</th>
                                    <th style={{ 'verticalAlign': 'middle' }}>{language[indexLanguage].Timekeeping.in_soon}</th>
                                    <th style={{ 'verticalAlign': 'middle' }}>{language[indexLanguage].Timekeeping.in_late}</th>
                                    <th style={{ 'verticalAlign': 'middle' }}>{language[indexLanguage].Timekeeping.out_soon}</th>
                                    <th style={{ 'verticalAlign': 'middle' }}>{language[indexLanguage].Timekeeping.out_late}</th>
                                    <th style={{ 'verticalAlign': 'middle', 'width': '20px' }}>{language[indexLanguage].Timekeeping.work_day}</th>
                                    <th style={{ 'verticalAlign': 'middle' }}>{language[indexLanguage].Timekeeping.note}</th>
                                    <th style={{ 'verticalAlign': 'middle' }}>{language[indexLanguage].Timekeeping.shift}</th>
                                    <th style={{ 'verticalAlign': 'middle', 'width': '30px' }}>{language[indexLanguage].Timekeeping.id_cam}</th>
                                    {dataRole.indexOf(type + '/timekeeping:update_shift') !== -1 && <th style={{ 'verticalAlign': 'middle', 'width': '90px' }}>{language[indexLanguage].Timekeeping.action}</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {crrData &&
                                    crrData.map((map, i) => viewData(map, i))
                                }
                            </tbody>
                        </table>
                    </div>
                    <PaginationTab total={totalItem} page={dataSearch.index} item_per_page={(initial.item_per_page).toString()} getData={getDataPagination} />
                </div>
            </div>
        </div>
    );
}

export default TimekeepingDetail;