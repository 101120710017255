import { css } from '@emotion/core';
import vi from 'date-fns/locale/vi';
import React, { Component } from 'react';
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LoadingOverlay from 'react-loading-overlay';
import { withRouter } from 'react-router';
import { Redirect } from 'react-router-dom';
import FadeLoader from 'react-spinners/FadeLoader';
import Store from '../../../../store';
import LeaveOfAbsenceLetterApproval from './leaveOfAbsenceLetterApproval';
import LeaveOfAbsenceLetterTableTab from './leaveOfAbsenceLetterTableTab';
import language from '../../../../language/language';
import Axios from 'axios';
import equal from 'fast-deep-equal'
import AdminApproval from './AdminApproval';
import { connect } from 'react-redux'
import Header from '../../../layouts/Header';
registerLocale('vi', vi)
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

const NameTag = 'daysoff_form';
class LeaveOfAbsenceLetter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogin: Store.getState().isLogin.isLogin,
            access_token: Store.getState().isLogin.access_token,
            box_engine_cf: Store.getState().boxai.box_engine_cf,
            crrApi2: Store.getState().boxai.box_engine_cf.crrApiSocket,
            language: Store.getState().changelanguage.language,
            idCompany: Store.getState().boxai.box_engine_cf.idCompany,
            dataRole: Store.getState().role.role,
            type: '',
            supervisor: false,
            styleActive: 2,
        };

        Store.subscribe(() => {
            this.setState({
                isLogin: Store.getState().isLogin.isLogin,
                access_token: Store.getState().isLogin.access_token,
                box_engine_cf: Store.getState().boxai.box_engine_cf,
                crrApi2: Store.getState().boxai.box_engine_cf.crrApiSocket,
                language: Store.getState().changelanguage.language,
                idCompany: Store.getState().boxai.box_engine_cf.idCompany,
                dataRole: Store.getState().role.role
            });
        });
        this.checkSupervisorToken();
    }

    componentWillMount() {
        this.setState({
            type: localStorage.getItem("type")
        });
    }

    componentDidMount(){
        let url = this.props.location.pathname;
        this.setState({
            styleActive: parseInt(url.substr(-1,1))
        })
    }

    componentDidUpdate(prevProps,prevState) {
        if(!equal(this.state.crrApi2,prevState.crrApi2)){
            this.checkSupervisorToken();
        }
      } 

    async checkSupervisorToken() {
        if(this.state.crrApi2 === undefined) return;
        const result = await Axios({
            method: 'GET',
            url: `${this.state.crrApi2}/api/department/check_supervisor`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': this.state.access_token,
              }
        })
        if (result.data.status === 10000) {
            this.setState({
                supervisor: true,
            })
        }
    }


    render() {
        if (this.state.isLogin === false) {
            return <Redirect to={'/login'} />
        }
        return (
            <>
            <Header />
            <div className={"m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body "}>
            <LoadingOverlay
                active={this.state.loading_export}
                spinner={<FadeLoader css={override} sizeUnit={"px"} size={150} color={'rgb(54, 215, 183)'} loading={true} />}
                styles={{ overlay: (base) => ({ ...base, background: 'rgba(0, 0, 0, 0.58)' }) }}
                text={<div>Đang tải dữ liệu...</div>}
                className="col-xl-12 p-0 loading_timekeeping" >
                <div className="m-grid__item m-grid__item--fluid m-wrapper p-2" id="timekeeping">
                    <div className="m-content" id="timekeepingPersonal">
                        <div className="m-portlet m-portlet--tab mb-0">
                            <ul className="nav nav-tabs" role="tablist" style={{ 'padding': '15px 20px 0px 20px', marginBottom: '15px' }}>
                                {
                                    this.state.dataRole.indexOf(`${this.state.type}/${NameTag}:view) !== -1`) &&
                                    <li className="nav-item">
                                        <a className={"nav-link " + (this.state.styleActive === 2 ? "active" : "")} data-toggle="tab" href="#m_tabs_1_2">{language[this.props.indexLanguage].DayOff.list_letter_off}</a>
                                    </li>
                                }
                                {
                                    ((this.state.dataRole.indexOf(`${this.state.type}/${NameTag}:view_by_manager`) !== -1 || this.state.dataRole.indexOf(`${this.state.type}/${NameTag}:supervisor_handle_form`) !== -1) && this.state.dataRole.indexOf(`${this.state.type}/${NameTag}:view_all`) === -1) &&
                                    <li className="nav-item">
                                        <a className={"nav-link " + (this.state.styleActive === 3 ? "active" : "")} data-toggle="tab" href="#m_tabs_1_3">{language[this.props.indexLanguage].DayOff.approve}</a>
                                    </li>
                                }
                                {
                                   this.state.dataRole.indexOf(`${this.state.type}/${NameTag}:view_all`) !== -1 &&
                                    <li className="nav-item">
                                        <a className={"nav-link " + (this.state.styleActive === 4 ? "active" : "")} data-toggle="tab" href="#m_tabs_1_4">{language[this.props.indexLanguage].DayOff.approve}</a>
                                    </li>
                                }
                            </ul>
                            <div className="m-form m-form--fit m-form--label-align-right">
                                <div className="m-portlet__body pl-3 pr-3 pt-0">
                                    <div className="tab-content">
                                        {
                                            this.state.dataRole.indexOf(`${this.state.type}/${NameTag}:view) !== -1`) &&
                                            <div className={"tab-pane " + (this.state.styleActive === 2 && "active show")} id="m_tabs_1_2" role="tabpanel">
                                                <LeaveOfAbsenceLetterTableTab />
                                            </div>
                                        }
                                        {
                                            ((this.state.dataRole.indexOf(`${this.state.type}/${NameTag}:view_by_manager`) !== -1 || this.state.dataRole.indexOf(`${this.state.type}/${NameTag}:supervisor_handle_form`) !== -1) && this.state.dataRole.indexOf(`${this.state.type}/${NameTag}:view_all`) === -1) &&
                                            <div className={"tab-pane " + (this.state.styleActive === 3 && "active show")} id="m_tabs_1_3" role="tabpanel">
                                                <LeaveOfAbsenceLetterApproval />
                                            </div>
                                        }
                                        {
                                            this.state.dataRole.indexOf(`${this.state.type}/${NameTag}:view_all`) !== -1 &&
                                            <div className={"tab-pane " + (this.state.styleActive === 4 && "active show")} id="m_tabs_1_4" role="tabpanel">
                                                <AdminApproval />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LoadingOverlay>
            </div>
            </>
        )
    }
}
const mapStateToProps = state => {
    return {
        indexLanguage: state.changelanguage.indexLanguage,
    }
}
export default withRouter(connect(mapStateToProps, null)(LeaveOfAbsenceLetter));
