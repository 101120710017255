import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import Switch from "react-switch";
import { HOST_CLOUD, HOST, ARRAY_ENGINES } from '../../../../config';
import swal from 'sweetalert';
import Store from '../../../../store';
import $ from 'jquery';
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import DatePicker from "react-datepicker";
import Moment from 'moment';
import language from '../../../../language/language';
import toastr from 'reactjs-toastr';
// import 'reactjs-toastr/lib/toast.css';
import "react-datepicker/dist/react-datepicker.css";
import { connect } from 'react-redux'
const animatedComponents = makeAnimated();

function sortData(a, b) {
    if (a.label > b.label) {
        return 1
    } else {
        if (a.label < b.label) {
            return -1;
        }
        else {
            return 0
        }
    }
}


class ModalModel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            language: Store.getState().changelanguage.language,
            crrApi2: Store.getState().boxai.box_engine_cf.crrApiSocket,
            box_engine_cf: Store.getState().boxai.box_engine_cf,
            id_door_calendar: 'null',
            optionDoor: [],
            DayOfWeek: [],
            listStaff: [],
            listGroup: [],
            optionsCamera: [],
            valueGroups: [],
            valueStaffs: [],
            valueCamera: [],
            listDepartment: [],
            listLevel: [],
            DataModel: {
                "department_levels": [],
                "groups": [],
                "staffs": []
            },
            HOST: '',
            valueDoor: '0',
            display: true,
            crrModel: {
                "id": 0,
                "name": "",
                "description": "",
                "id_company": "",
                "arr_child_company": [],
                "box_engines": [],
                "obj_type": []
            },
            valueType: ""
        }
        Store.subscribe(() => {
            this.setState({
                language: Store.getState().changelanguage.language,
                crrApi2: Store.getState().boxai.box_engine_cf.crrApiSocket,
                box_engine_cf: Store.getState().boxai.box_engine_cf,
            });
        });
    }

    componentWillMount() {

        const Type = [
            {
                value: 4, label: language[this.props.indexLanguage].Text_Notifi.all
            },
            {
                value: 0, label: language[this.props.indexLanguage].Text_Notifi.multi
            }
        ]

        this.setState({
            listType: Type,
        })
    }

    getStaff = () => {
        if (this.state.crrApi2 == undefined) return
        fetch(`${this.state.crrApi2}/api/staff/${this.state.box_engine_cf.idCompany}/get_all`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                var dataStaff = [];
                for (let index = 0; index < data.data.length; index++) {
                    dataStaff.push({ 'value': data.data[index].id, 'label': data.data[index].name + ' (' + data.data[index].id_staff + ')' })
                }
                var dataOptionSort = dataStaff.sort(sortData);
                this.setState({
                    listStaff: dataOptionSort,
                });
            }
        })
    }

    getGroup = () => {
        if (this.state.crrApi2 == undefined) return
        fetch(`${this.state.crrApi2}/api/group/${this.state.box_engine_cf.idCompany}/get_all`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                var dataGroup = [];
                for (let index = 0; index < data.data.length; index++) {
                    dataGroup.push({ 'value': data.data[index].id, 'label': data.data[index].name })
                }
                var dataOptionSort = dataGroup.sort(sortData);
                this.setState({
                    listGroup: dataOptionSort,
                });
            }
        })
    }

    getDepartment = (idcompany, listCompany) => {
        if (this.state.crrApi2 == undefined) return
        fetch(`${this.state.crrApi2}/api/department/get_by_id_company`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            body: JSON.stringify({
                arr_idcompany: idcompany
            })
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                var listDepartment = [];
                for (let index = 0; index < data.data.length; index++) {
                    if (data.data[index].id_company == idcompany[0]) {
                        listDepartment.push({ 'value': data.data[index].id, 'label': data.data[index].name })
                    }
                    for (let i = 0; i < listCompany.length; i++) {
                        if (data.data[index].id_company == listCompany[i].idCompany) {
                            listDepartment.push({ 'value': data.data[index].id, 'label': data.data[index].name + ' (' + listCompany[i].nameCompany + ')' })
                        }
                    }
                }
                // var dataOptionSort = listDepartment.sort(sortData);
                this.setState({
                    listDepartment
                });
            }
        })
    }

    getLevel = () => {
        if (this.state.crrApi2 == undefined) return
        fetch(`${this.state.crrApi2}/api/level/${this.state.box_engine_cf.idCompany}/get_all`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                var listLevel = [];
                for (let index = 0; index < data.data.length; index++) {
                    listLevel.push({ 'value': data.data[index].id, 'label': data.data[index].name })
                }
                this.setState({
                    listLevel: listLevel
                });
            }
        })
    }

    modelGetById = (id, id_company) => {
        if (this.state.crrApi2 == undefined) return
        fetch(`${this.state.crrApi2}/api/submodel/get_by_id/${id}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                var crrModel = data.data
                var valueType = ""
                var CheckType = crrModel.obj_type.find(x => x.type == 4);
                if (CheckType != undefined) {
                    valueType = { value: 4, label: language[this.props.indexLanguage].Text_Notifi.all }
                } else {
                    valueType = { value: 0, label: language[this.props.indexLanguage].Text_Notifi.multi }
                }
                crrModel['id_company'] = id_company
                this.setState({
                    crrModel: crrModel,
                    valueType: valueType
                })
            }
        })
    }

    updateDoorOpen = async (dataModel, event) => {
        //Insert
        var form = $("#formUpdateDoorOpen")
        if (form[0].checkValidity() === false) {
            event.preventDefault()
            event.stopPropagation()
            form.addClass('was-validated')
        } else {
            if (dataModel.box_engines.length > 0) {
                if (dataModel.id == 0) {
                    if (this.state.crrApi2 == undefined) return
                    $('#button_save_model').addClass('m-loader');
                    $('#button_save_model').attr('disabled', true);
                    fetch(`${this.state.crrApi2}/api/submodel/insert_or_update`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': Store.getState().isLogin.access_token
                        },
                        body: JSON.stringify(dataModel)
                    }).then((response) => {
                        return response.json()
                    }).then((data) => {
                        if (data.status === 10000) {
                            swal(language[this.props.indexLanguage].Text_Notifi.success, language[this.props.indexLanguage].Text_Notifi.notifi_add_model_success, "success", {
                                buttons: false,
                                timer: 1500,
                            });
                            return this.props.onHide();
                        } else if (data.status === 10001) {
                            swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].Text_Notifi.notifi_name_model_exist, "warning");
                            $('#button_save_model').removeClass('m-loader');
                            $('#button_save_model').removeAttr('disabled');
                        } else if (data.status === 10004) {
                            swal(language[this.props.indexLanguage].Text_Notifi.warning, `Camera ${data.be_err.box_engine_id} ${language[this.props.indexLanguage].Text_Notifi.notifi_used_in_modal}  ${data.be_err.model.name}`, "warning");
                            $('#button_save_model').removeClass('m-loader');
                            $('#button_save_model').removeAttr('disabled');
                        }
                        else {
                            swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].Text_Notifi.notifi_add_model_false, "error");
                            $('#button_save_model').removeClass('m-loader');
                            $('#button_save_model').removeAttr('disabled');
                        }
                    }).catch((error) => {
                        if (error) {
                            swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].Text_Notifi.notifi_add_model_false, "error");
                            $('#button_save_model').removeClass('m-loader');
                            $('#button_save_model').removeAttr('disabled');
                        }
                    });
                } else {
                    if (this.state.crrApi2 == undefined) return
                    $('#button_save_model').addClass('m-loader');
                    $('#button_save_model').attr('disabled', true);
                    fetch(`${this.state.crrApi2}/api/submodel/insert_or_update`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': Store.getState().isLogin.access_token
                        },
                        body: JSON.stringify(dataModel)
                    }).then((response) => {
                        return response.json()
                    }).then((data) => {
                        if (data.status === 10000) {
                            swal(language[this.props.indexLanguage].Text_Notifi.success, language[this.props.indexLanguage].Text_Notifi.notifi_edit_model_success, "success", {
                                buttons: false,
                                timer: 1500,
                            });
                            return this.props.onHide();
                        } else if (data.status === 10001) {
                            swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].Text_Notifi.notifi_name_model_exist, "warning");
                            $('#button_save_model').removeClass('m-loader');
                            $('#button_save_model').removeAttr('disabled');
                        } else if (data.status === 10004) {
                            swal(language[this.props.indexLanguage].Text_Notifi.warning, `Camera ${data.be_err.box_engine_id} ${language[this.props.indexLanguage].Text_Notifi.notifi_used_in_modal}  ${data.be_err.model.name}`, "warning");
                            $('#button_save_model').removeClass('m-loader');
                            $('#button_save_model').removeAttr('disabled');
                        }
                        else {
                            swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].Text_Notifi.notifi_edit_model_false, "error");
                            $('#button_save_model').removeClass('m-loader');
                            $('#button_save_model').removeAttr('disabled');
                        }
                    }).catch((error) => {
                        if (error) {
                            swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].Text_Notifi.notifi_edit_model_false, "error");
                            $('#button_save_model').removeClass('m-loader');
                            $('#button_save_model').removeAttr('disabled');
                        }
                    });

                }
            } else {
                swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].Text_Notifi.notifi_check_select_camera, "warning");
            }

        }
    }


    ModelHandle(e) {
        var ModelHand = this.state.crrModel;
        ModelHand[e.target.name] = e.target.value;
        this.setState({ crrModel: ModelHand });
    }

    handleChangeStaffs(valueStaff) {

        var crrModel = this.state.crrModel;
        var listStaff = []
        if (valueStaff == null) {
            listStaff = [];
            for (let index = 0; index < crrModel.obj_type.length; index++) {
                if (crrModel.obj_type[index].type == 1) {
                    crrModel.obj_type.splice(index, 1)
                    break;
                }
            }

        } else {
            for (let index = 0; index < valueStaff.length; index++) {
                listStaff.push(valueStaff[index].value)
            }
            var CheckStaff = crrModel.obj_type.find(x => x.type == 1);
            if (CheckStaff != undefined) {
                CheckStaff.ids_ref = listStaff;
            } else {
                crrModel.obj_type.push({ "type": 1, "ids_ref": listStaff })
            }
        }

        this.setState({
            crrModel: crrModel,
        })
    }

    handleChangeDepartment(valueDepartment) {
        var crrModel = this.state.crrModel;
        var listDepartment = []
        if (valueDepartment == null) {
            listDepartment = [];
            for (let index = 0; index < crrModel.obj_type.length; index++) {
                if (crrModel.obj_type[index].type == 2) {
                    crrModel.obj_type.splice(index, 1)
                    break;
                }
            }
        } else {
            for (let index = 0; index < valueDepartment.length; index++) {
                listDepartment.push(valueDepartment[index].value)
            }
            var CheckDepartment = crrModel.obj_type.find(x => x.type == 2);
            if (CheckDepartment != undefined) {
                CheckDepartment.ids_ref = listDepartment;
            } else {
                crrModel.obj_type.push({ "type": 2, "ids_ref": listDepartment })
            }
        }
        this.setState({
            crrModel: crrModel,
        })
    }

    handleChangeGroups(valueGroup) {
        var crrModel = this.state.crrModel;
        var listGroup = []
        if (valueGroup == null) {
            listGroup = [];
            for (let index = 0; index < crrModel.obj_type.length; index++) {
                if (crrModel.obj_type[index].type == 3) {
                    crrModel.obj_type.splice(index, 1)
                    break;
                }
            }
        } else {
            for (let index = 0; index < valueGroup.length; index++) {
                listGroup.push(valueGroup[index].value)
            }
            var CheckGroup = crrModel.obj_type.find(x => x.type == 3);
            if (CheckGroup != undefined) {
                CheckGroup.ids_ref = listGroup;
            } else {
                crrModel.obj_type.push({ "type": 3, "ids_ref": listGroup })
            }
        }
        this.setState({
            crrModel: crrModel,
        })
    }


    handleChangeCamera(valueCamera) {
        var crrModel = this.state.crrModel;
        var listCamera = []
        if (valueCamera == null) {
            listCamera = []
        } else {
            for (let index = 0; index < valueCamera.length; index++) {
                listCamera.push(valueCamera[index].value)
            }
        }
        crrModel['box_engines'] = listCamera

        this.setState({
            crrModel: crrModel,
        })
    }

    handleChangeType(valueType) {
        var crrModel = this.state.crrModel;
        if (valueType.value == 4) {
            crrModel.obj_type = [{ "type": 4, "ids_ref": [] }]
        } else {
            crrModel.obj_type = []
        }
        this.setState({
            crrModel: crrModel,
            valueStaffs: "",
            valueDepartment: "",
            valueGroups: "",
            valueType: valueType
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.show === true) {
            var optionsCamera = [];
            if (nextProps.datacamera.length > 0) {
                for (let index = 0; index < nextProps.datacamera.length; index++) {
                    if (ARRAY_ENGINES.includes(nextProps.datacamera[index].sub_engine_id + '')) {
                        optionsCamera.push({
                            label: nextProps.datacamera[index].alias_box_engine ? `${nextProps.datacamera[index].alias_box_engine} ( ${nextProps.datacamera[index].id_box_engine} )` : nextProps.datacamera[index].id_box + '_' + nextProps.datacamera[index].id_box_engine , value: nextProps.datacamera[index].id_box_engine
                        })
                    }
                }
            }
            var datacompanychild = nextProps.datacompanychild
            var idcompany = [nextProps.idcompany]
            var listCompany = []
            if (datacompanychild !== undefined) {
                if (datacompanychild.length > 0) {
                    for (let index = 0; index < datacompanychild.length; index++) {
                        if (datacompanychild[index].boxes_engine.length > 0) {
                            for (let i = 0; i < datacompanychild[index].boxes_engine.length; i++) {
                                if (ARRAY_ENGINES.includes(datacompanychild[index].boxes_engine[i].sub_engine_id + '')) {
                                    optionsCamera.push({
                                        label: datacompanychild[index].boxes_engine[i].id_box + '_' + datacompanychild[index].boxes_engine[i].id_box_engine + ' (' + datacompanychild[index].name + ')', value: datacompanychild[index].boxes_engine[i].id_box_engine
                                    })
                                }
                            }
                        }
                        idcompany.push(datacompanychild[index].id_company)
                        listCompany.push({
                            idCompany: datacompanychild[index].id_company,
                            nameCompany: datacompanychild[index].name,
                        })
                    }
                }
            }

            var crrModel = nextProps.data
            var { valueType } = this.state

            if (listCompany.length > 0) {
                valueType = { value: 0, label: language[this.props.indexLanguage].Text_Notifi.multi }
                crrModel.obj_type = []
            } else {
                valueType = { value: 4, label: language[this.props.indexLanguage].Text_Notifi.all }
                crrModel.obj_type = [{ "type": 4, "ids_ref": [] }]
            }

            this.getStaff()
            this.getGroup()
            this.getDepartment(idcompany, listCompany)
            this.getLevel()
            if (crrModel.id == 0) {
                this.setState({
                    crrModel: crrModel,
                    optionsCamera: optionsCamera,
                    valueType,
                });
            } else {
                this.setState({
                    optionsCamera: optionsCamera,
                    valueType
                });
                this.modelGetById(crrModel.id, crrModel.id_company)
            }
        } else {
            this.setState({
                crrModel: {
                    "id": 0,
                    "name": "",
                    "description": "",
                    "id_company": "",
                    "arr_child_company": [],
                    "box_engines": [],
                    "obj_type": []
                },
            })
        }
    }

    render() {
        let click_handle = (event) => {
            this.updateDoorOpen(this.state.crrModel, event);
        }

        var display
        if (this.state.valueType.value == 4) {
            display = "d-none"
        } else {
            display = ""
        }

        return (
            <Modal
                {...this.props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form id="formUpdateDoorOpen">
                        <div className="col-xl-12">
                            <div className="m-widget14 row">
                                <div className="form-group m-form__group col-md-12 p-0">
                                    <label htmlFor="Name">{language[this.props.indexLanguage].textTable.name} <span className="text-danger"> *</span></label>
                                    <input className="form-control m-input" id="Name" name='name' value={this.state.crrModel.name} onChange={e => this.ModelHandle(e)} placeholder={language[this.props.indexLanguage].textTable.name} required />
                                </div>
                                <div className="form-group m-form__group col-md-12 p-0">
                                    <label htmlFor="Description">{language[this.props.indexLanguage].textTable.description}</label>
                                    <textarea rows="4" className="form-control m-input" id="Description" name='description' value={this.state.crrModel.description} onChange={e => this.ModelHandle(e)} placeholder={language[this.props.indexLanguage].textTable.description} />
                                </div>
                                <div className="form-group m-form__group col-md-12 p-0">
                                    <label htmlFor="Model">{language[this.props.indexLanguage].Submodel.ID_Cameras}</label>
                                    <Select
                                        getOptionValue={(option) => option.label}
                                        onChange={value => this.handleChangeCamera(value)}
                                        options={this.state.optionsCamera}
                                        value={this.state.optionsCamera.filter((option) => { return this.state.crrModel.box_engines.findIndex(op => op == option.value) >= 0 })}
                                        placeholder={language[this.props.indexLanguage].Submodel.select_camera}
                                        isMulti
                                        isClearable={false}
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                    />
                                </div>
                                <div className="form-group m-form__group col-md-12 p-0">
                                    <label>{language[this.props.indexLanguage].Submodel.Type_select}</label>
                                    <Select
                                        getOptionValue={(option) => option.label}
                                        onChange={Type => this.handleChangeType(Type)}
                                        options={this.state.listType}
                                        value={this.state.valueType}
                                    />
                                </div>
                                <div className={"test col-xl-12 p-0 " + display}>
                                    <div className="form-group m-form__group col-md-12 p-0">
                                        <label>{language[this.props.indexLanguage].textTable.staffs}</label>
                                        <Select
                                            getOptionValue={(option) => option.label}
                                            onChange={valueStaff => this.handleChangeStaffs(valueStaff)}
                                            options={this.state.listStaff}
                                            value={this.state.listStaff.filter((option) => {
                                                var obj = this.state.crrModel.obj_type.find(op => op.type == 1);
                                                if (obj != undefined) {
                                                    return obj.ids_ref.findIndex(op => op == option.value) >= 0
                                                }
                                                else {
                                                    return false;
                                                }
                                            })}
                                            placeholder={language[this.props.indexLanguage].placeholderSelect.select_staff}
                                            isMulti
                                            isClearable={false}
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                        />
                                    </div>
                                    <div className="form-group m-form__group col-md-12 p-0">
                                        <label>{language[this.props.indexLanguage].textTable.departments}</label>
                                        <Select
                                            getOptionValue={(option) => option.label}
                                            onChange={valueDepartment => this.handleChangeDepartment(valueDepartment)}
                                            options={this.state.listDepartment}
                                            value={this.state.listDepartment.filter((option) => {
                                                var obj = this.state.crrModel.obj_type.find(op => op.type == 2);
                                                if (obj != undefined) {
                                                    return obj.ids_ref.findIndex(op => op == option.value) >= 0
                                                }
                                                else {
                                                    return false;
                                                }
                                            })}
                                            isMulti
                                            isClearable={false}
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            placeholder={language[this.props.indexLanguage].placeholderSelect.select_department}
                                        />
                                    </div>
                                    <div className="form-group m-form__group col-md-12 p-0">
                                        <label>{language[this.props.indexLanguage].textTable.groups}</label>
                                        <Select
                                            getOptionValue={(option) => option.label}
                                            onChange={valueGroup => this.handleChangeGroups(valueGroup)}
                                            options={this.state.listGroup}
                                            value={this.state.listGroup.filter((option) => {
                                                var obj = this.state.crrModel.obj_type.find(op => op.type == 3);
                                                if (obj != undefined) {
                                                    return obj.ids_ref.findIndex(op => op == option.value) >= 0
                                                }
                                                else {
                                                    return false;
                                                }
                                            })}
                                            isMulti
                                            isClearable={false}
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            placeholder={language[this.props.indexLanguage].placeholderSelect.select_group}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="accent" id="button_save_model" className="m-loader--light m-loader--right" onClick={click_handle}>{language[this.props.indexLanguage].textButton.save}</Button>
                    <Button variant="secondary" onClick={this.props.onHide}>{language[this.props.indexLanguage].textButton.close}</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

ModalModel.propTypes = {
    data: PropTypes.object,
    datacamera: PropTypes.array,
    listdoor: PropTypes.array,
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool,
}
const mapStateToProps = state => {
    return {
        indexLanguage: state.changelanguage.indexLanguage,
    }
}

export default connect(mapStateToProps, null)(ModalModel);