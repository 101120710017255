import { SETBOX } from '../../actions/boxai';

const initialState = {
    box_engine_cf: '',
    list_company: [],
    is_company: null,
};
export default function boxai(state = initialState, action) {
    switch(action.type){
        case SETBOX:
            return{
                box_engine_cf: action.payload.box_engine_cf,
                list_company: action.payload.list_company ? action.payload.list_company : state.list_company,
                is_company: action.payload.is_company,
            };
        default:
            return state;
    }
}
