import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { HOST } from '../../../../config';
import Store from '../../../../store';
import swal from 'sweetalert';
import ModalModel from "./ModalModel";
import { css } from '@emotion/core';
import { PulseLoader } from 'react-spinners';
import Switch from "react-switch";
import Pagination from "react-js-pagination";
import Moment from 'moment';
import Select from "react-select";
import language from '../../../../language/language';
import ReactTooltip from 'react-tooltip';
import $ from 'jquery';
import { connect } from 'react-redux'
import Header from '../../../layouts/Header';
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

function compare(a, b) {
    if (a.label > b.label) {
        return 1
    } else {
        if (a.label < b.label) {
            return -1;
        }
        else {
            return 0
        }
    }
}
const nameTab = 'sub_model'
class SubModel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogin: Store.getState().isLogin.isLogin,
            access_token: Store.getState().isLogin.access_token,
            crrApi2: Store.getState().boxai.box_engine_cf.crrApiSocket,
            crrApi: Store.getState().boxai.box_engine_cf.crrApi,
            dataCamera: Store.getState().boxai.box_engine_cf.dataCamera,
            dataCompanyChild: Store.getState().boxai.box_engine_cf.dataCompanyChild,
            idCompany: Store.getState().boxai.box_engine_cf.idCompany,
            language: Store.getState().changelanguage.language,
            box_engine_cf: Store.getState().boxai.box_engine_cf,
            dataRole: Store.getState().role.role,
            type: '',
            modalModel: false,
            isChecked: false,
            listModel: [],
            optionStaff: [],
            listStaff: [],
            valueStaff: { value: "0", label: "All Staff", object_id: "0" },
            listDoor: [],
            crrDatas: [],
            activePage: 1,
            offset: 0,
            totalLength: 0,
            crrModel: {
                box_engine_id: "",
                name: "",
                description: "",
                is_active: 0
            },
            valueSearch: '',
        };
        Store.subscribe(() => {
            this.setState({
                isLogin: Store.getState().isLogin.isLogin,
                access_token: Store.getState().isLogin.access_token,
                crrApi2: Store.getState().boxai.box_engine_cf.crrApiSocket,
                crrApi: Store.getState().boxai.box_engine_cf.crrApi,
                dataCamera: Store.getState().boxai.box_engine_cf.dataCamera,
                dataCompanyChild: Store.getState().boxai.box_engine_cf.dataCompanyChild,
                idCompany: Store.getState().boxai.box_engine_cf.idCompany,
                language: Store.getState().changelanguage.language,
                box_engine_cf: Store.getState().boxai.box_engine_cf,
                listModel: [],
                crrDatas: [],
                activePage: 1,
                offset: 0,
                totalLength: 0,
                valueSearch: '',
            }, () => {
                $('#inputSearch').val('')
                this.getData()
            });
        });
        this.itemsPerPage = 10;
        this.getData()
    }

    componentWillMount() {
        var type = localStorage.getItem("type")
        this.setState({
            type: type
        })
    }

    getData = () => {
        this.getModel()
    }

    getModel = () => {
        if (this.state.crrApi2 == undefined) return;
        fetch(`${this.state.crrApi2}/api/submodel/${this.state.box_engine_cf.idCompany}/get_all`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                this.setState({
                    listModel: [...data.data],
                });
                this.FilterDoorOpen(this.state.activePage)
            }
        })
    }


    FilterDoorOpen(activePage) {

        //console.log('123')
        var dataSearch = [];
        this.state.listModel.forEach((item) => {
            if (item.name.toLowerCase().indexOf(this.state.valueSearch.toLowerCase()) !== -1) {
                dataSearch.push(item);
            }
        })

        const offset = (activePage - 1) * this.itemsPerPage;
        const crrDatas = dataSearch.slice(offset, offset + this.itemsPerPage);

        this.setState({
            crrDatas,
            offset: offset,
            totalLength: dataSearch.length
        })
    }

    handlePageChange = (pageNumber) => {
        this.setState({
            ...this.state,
            activePage: pageNumber
        })
        this.FilterDoorOpen(pageNumber);

    }

    DeleteModel = async (Model) => {
        let data = await fetch(`${this.state.crrApi2}/api/submodel/delete`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token,
            },
            body: JSON.stringify({
                'id': Model.id
            })
        }).then((response) => {
            return response.json()
        });
        if (data.status === 10000) {
            swal(language[this.props.indexLanguage].Text_Notifi.success, language[this.props.indexLanguage].Text_Notifi.notifi_delete_success, "success", {
                buttons: false,
                timer: 1500,
            });
            this.getModel()
        }
        else {
            swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].Text_Notifi.notifi_delete_false, "error");
        }

    }

    makeTraining = () => {
        if (this.state.crrApi == undefined) return;
        $('#button_maketrain').addClass('m-loader');
        $('#button_maketrain').attr('disabled', true);
        fetch(`${this.state.crrApi2}/api/submodel/${this.state.box_engine_cf.idCompany}/training`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                return response.json()
            }).then((data) => {
                swal(language[this.props.indexLanguage].Text_Notifi.success, language[this.props.indexLanguage].Text_Notifi.notifi_trainning_success, "success", {
                    buttons: false,
                    timer: 1500,
                });
                $('#button_maketrain').removeClass('m-loader');
                $('#button_maketrain').removeAttr('disabled', false);
            }).catch((error) => {
                // might be a timeout error
                if (error) {
                    swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].Text_Notifi.notifi_trainning, "warning", {
                        buttons: false,
                        timer: 1500,
                    });
                    $('#button_maketrain').removeClass('m-loader');
                    $('#button_maketrain').removeAttr('disabled', false);
                }

            })
    }

    modalClose = () => {
        this.setState({
            modalModel: false,
        });
        this.getModel()
    }

    btnSearch = () => {
        this.setState({
            activePage: 1
        }, () => {
            this.FilterDoorOpen(this.state.activePage);
        })
    }

    isChange = (event) => {
        if (event.key === 'Enter') {
            this.btnSearch(event.target.value.trim())
        }
        this.setState({
            valueSearch: event.target.value.trim()
        });
    }


    render() {
        if (this.state.isLogin === false) {
            return (
                <Redirect to={'/login'} />
            )
        }



        let bulletedDoorOpen = this.state.crrDatas.map((e, i) => {
            return (
                <tr key={(i + 1)}>
                    <td>{(i + this.state.offset + 1)}</td>
                    <td>{e.name}</td>
                    <td>{e.description}</td>
                    {
                        this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':insert_or_update') !== -1
                            ||
                            this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':delete') !== -1
                            ?
                            <td>
                                {
                                    this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':insert_or_update') !== -1
                                        ?
                                        <>
                                            <button onClick={(e) => {
                                                var data = this.state.crrDatas
                                                var dataModel = {
                                                    id: data[i].id,
                                                    id_company: this.state.box_engine_cf.idCompany
                                                };
                                                this.setState({
                                                    crrModel: dataModel,
                                                    modalModel: true,
                                                });
                                            }} className="m-portlet__nav-link btn m-btn m-btn--hover-warning m-btn--icon m-btn--icon-only m-btn--pill" data-tip data-for='Edit'>
                                                <i className="la la-edit" />
                                            </button>
                                            <ReactTooltip id='Edit' type='dark' effect='solid'>
                                                <span>{language[this.props.indexLanguage].tooltip.open_edit}</span>
                                            </ReactTooltip>
                                        </>
                                        :
                                        ""
                                }
                                {
                                    this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':delete') !== -1
                                        ?
                                        <>
                                            <button className="m-portlet__nav-link btn m-btn m-btn--hover-danger m-btn--icon m-btn--icon-only m-btn--pill" data-tip data-for='Delete'
                                                onClick={v => {
                                                    v.preventDefault();
                                                    swal({
                                                        title: language[this.props.indexLanguage].Text_Notifi.are_you_sure,
                                                        text: language[this.props.indexLanguage].Text_Notifi.notifi_delete_model + e.name,
                                                        icon: "warning",
                                                        buttons: true,
                                                        dangerMode: true,
                                                    })
                                                        .then(willDelete => {
                                                            if (willDelete) {
                                                                this.DeleteModel(e);
                                                            }
                                                        });

                                                }}
                                            ><i className="la la-trash"></i></button>
                                            <ReactTooltip id='Delete' type='dark' effect='solid'>
                                                <span>{language[this.props.indexLanguage].tooltip.open_delete}</span>
                                            </ReactTooltip>
                                        </>
                                        :
                                        ""
                                }

                            </td>
                            :
                            ""
                    }

                </tr>
            )
        });

        var pageRangeDisplayed = 1;
        if ($(window).width() < 768) {
            pageRangeDisplayed = 3
        } else {
            pageRangeDisplayed = 5
        }


        return (
            <>
            <Header />
            <div className={"m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body "}>
            <div className="m-grid__item m-grid__item--fluid m-wrapper">
                <div className="m-content p-2">
                    <div className="m-portlet m-portlet--tab mb-0">
                        <div className="m-portlet__head p-3">
                            <div className="m-portlet__head-caption pl-3">
                                <div className="m-portlet__head-title">
                                    <h3 className="m-portlet__head-text">
                                        {language[this.props.indexLanguage].Menubar.submodel}
                                    </h3>
                                </div>
                            </div>
                            <div className="m-portlet__head-tools pr-3">
                                <ul className="m-portlet__nav">
                                    <li className="m-portlet__nav-item">
                                        {
                                            this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':insert_or_update') !== -1
                                                ?
                                                <button onClick={(e) => {
                                                    this.setState({
                                                        crrModel: {
                                                            "id": 0,
                                                            "name": "",
                                                            "description": "",
                                                            "box_engines": [],
                                                            "id_company": this.state.box_engine_cf.idCompany,
                                                            "obj_type": [{ "type": 4, "ids_ref": [] }]
                                                        },
                                                        modalModel: true,
                                                    });
                                                }} className="btn btn-accent m-btn m-btn--custom m-btn--icon m-btn--pill m-btn--air">
                                                    <span>
                                                        <i className="la la-plus" />
                                                        <span>{language[this.props.indexLanguage].textButton.add}</span>
                                                    </span>
                                                </button>
                                                :
                                                ""
                                        }
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="m-portlet__body m-portlet__body--no-padding">
                            <div className="row m-row--no-padding m-row--col-separator-xl">
                                <div className="col-xl-8 m-auto">
                                    <div className="m-widget1">
                                        <div className="row col-md-12 ml-0 mr-0 p-0 mb-2">
                                            <div className="col-lg-2 pl-0 m--margin-bottom-10-tablet-and-mobile">
                                                <input type="text" onKeyUp={(event) => this.isChange(event)} id="inputSearch" className="form-control m-input" placeholder={language[this.props.indexLanguage].placeholderInput.enter_name} data-col-index={0} />
                                            </div>
                                            <div>
                                                <button
                                                    onClick={() => {
                                                        this.btnSearch(this.state.valueSearch)
                                                    }}
                                                    className="btn btn-accent m-btn m-btn--icon" id="m_search">
                                                    <span>
                                                        <i className="la la-search" />
                                                        <span>{language[this.props.indexLanguage].textButton.search}</span>
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="table-responsive text-nowrap">
                                            <table className="table table-bordered table-hover table-checkable dataTable no-footer dtr-inline collapsed">
                                                <thead>
                                                    <tr>
                                                        <th style={{ 'verticalAlign': 'middle', 'width': '80px' }}>{language[this.props.indexLanguage].Other.no}</th>
                                                        <th style={{ 'verticalAlign': 'middle', }}>{language[this.props.indexLanguage].textTable.name}</th>
                                                        <th style={{ 'verticalAlign': 'middle', }}>{language[this.props.indexLanguage].textTable.description}</th>
                                                        {
                                                            this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':insert_or_update') !== -1
                                                                ||
                                                                this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':delete') !== -1
                                                                ?
                                                                <th style={{ 'verticalAlign': 'middle', 'width': '150px' }}>{language[this.props.indexLanguage].textTable.action}</th>
                                                                :
                                                                ''
                                                        }

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {bulletedDoorOpen}
                                                </tbody>

                                            </table>
                                            <ModalModel
                                                listdoor={this.state.listDoor}
                                                datacamera={this.state.dataCamera}
                                                datacompanychild={this.state.dataCompanyChild}
                                                idcompany={this.state.idCompany}
                                                data={this.state.crrModel}
                                                show={this.state.modalModel}
                                                onHide={this.modalClose}
                                            />
                                        </div>
                                        <Pagination
                                            firstPageText={language[this.props.indexLanguage].pagination.first}
                                            nextPageText={language[this.props.indexLanguage].pagination.next}
                                            lastPageText={language[this.props.indexLanguage].pagination.last}
                                            prevPageText={language[this.props.indexLanguage].pagination.previous}
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={this.itemsPerPage}
                                            totalItemsCount={this.state.totalLength}
                                            pageRangeDisplayed={pageRangeDisplayed}
                                            onChange={this.handlePageChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </>
        );
    }
}
const mapStateToProps = state => {
    return {
        indexLanguage: state.changelanguage.indexLanguage,
    }
}
export default connect(mapStateToProps, null)(SubModel);
