import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import Switch from "react-switch";
import { HOST_CLOUD } from '../../../../config';
import swal from 'sweetalert';
import Store from '../../../../store';
import $ from 'jquery';
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import language from '../../../../language/language';
import ReactGA from 'react-ga';

import { connect } from 'react-redux'
const animatedComponents = makeAnimated();

class ModalRole extends Component {

    constructor(props) {
        super(props);
        this.state = {
            language: Store.getState().changelanguage.language,
            box_engine_cf: Store.getState().boxai.box_engine_cf,
            listStaff: [],
            listStaffDefault: [],
            listLicense: [],
            listCheckOperation: [],
            listRoleTemplate: [],
            crrRole: {
                name: "",
                active: 1,
                roles: [],
                id_company: "",
                is_default: 0,
            },
            isChecked: true,
            valueRoleTemplate: null,
            access_token_cloud: '',
        }
        Store.subscribe(() => {
            this.setState({
                language: Store.getState().changelanguage.language,
                box_engine_cf: Store.getState().boxai.box_engine_cf,
            });
        });
    }

    componentWillMount() {
        var access_token_cloud = localStorage.getItem("access_token_cloud")
        this.setState({
            access_token_cloud: access_token_cloud,
        })
    }

    updateRole = async (dataRole, event) => {
        dataRole.name.trim()
        var form = $("#formAddRole")
        if (form[0].checkValidity() === false) {
            event.preventDefault()
            event.stopPropagation()
            form.addClass('was-validated')
        } else {
            //Insert
            if (dataRole.active === 0 && dataRole.is_default === 1) {
                swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].Text_Notifi.notifi_role_is_default, "warning");
            } else {
                if (dataRole.roles.length > 0) {
                    $('#button_addRole').addClass('m-loader');
                    $('#button_addRole').attr('disabled', true);
                    if (dataRole.id == undefined) {
                        await fetch(`${dataRole.HOST}/api/role/insert_or_update`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': Store.getState().isLogin.access_token
                            },
                            body: JSON.stringify(dataRole)
                        }).then((response) => {
                            return response.json()
                        }).then((data) => {
                            if (data.status === 10000) {
                                ReactGA.event({
                                    category: 'Quyen',
                                    action: 'Them moi quyen',
                                  });
                                swal(language[this.props.indexLanguage].Text_Notifi.success, language[this.props.indexLanguage].Text_Notifi.add_success, "success", {
                                    buttons: false,
                                    timer: 1500,
                                });
                                return this.props.onHide();
                            } else if (data.status === 10001) {
                                $('#button_addRole').removeClass('m-loader');
                                $('#button_addRole').removeAttr('disabled', true);
                                swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].Text_Notifi.notifi_name_same_role, "warning");
                            } else {
                                $('#button_addRole').removeClass('m-loader');
                                $('#button_addRole').removeAttr('disabled', true);
                                swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].Text_Notifi.add_false, "error");
                            }
                        }).catch((error) => {
                            if (error) {
                                $('#button_addRole').removeClass('m-loader');
                                $('#button_addRole').removeAttr('disabled', true);
                                swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].Text_Notifi.add_false, "error");
                            }
                        });
                    } else {
                        //Update
                        $('#button_addRole').addClass('m-loader');
                        $('#button_addRole').attr('disabled', true);
                        await fetch(`${dataRole.HOST}/api/role/insert_or_update`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': Store.getState().isLogin.access_token
                            },
                            body: JSON.stringify(dataRole)
                        }).then((response) => {
                            return response.json()
                        }).then((data) => {
                            if (data.status === 10000) {
                                ReactGA.event({
                                    category: 'Quyen',
                                    action: 'Sua quyen',
                                  });
                                swal(language[this.props.indexLanguage].Text_Notifi.success, language[this.props.indexLanguage].Text_Notifi.edit_success, "success", {
                                    buttons: false,
                                    timer: 1500,
                                });
                                return this.props.onHide();
                            } else if (data.status === 10001) {
                                $('#button_addRole').removeClass('m-loader');
                                $('#button_addRole').removeAttr('disabled', true);
                                swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].Text_Notifi.notifi_name_same_role, "warning");
                            } else {
                                $('#button_addRole').removeClass('m-loader');
                                $('#button_addRole').removeAttr('disabled', true);
                                swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].Text_Notifi.edit_false, "error");
                            }
                        }).catch((error) => {
                            if (error) {
                                $('#button_addRole').removeClass('m-loader');
                                $('#button_addRole').removeAttr('disabled', true);
                                swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].Text_Notifi.edit_false, "error");
                            }
                        });
                        
                    }
                } else {
                    swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].Text_Notifi.notifi_role_null, "warning");
                }
            }
        }
    }

    RoleHandle(e) {
        var dataRole = this.state.crrRole;
        dataRole[e.target.name] = e.target.value;
        this.setState({ crrRole: dataRole });
    }

    handleChangeIsDefault(event) {
        var dataRole = this.state.crrRole;
        if (event.target.value == 0) {
            dataRole['is_default'] = 1
        } else {
            dataRole['is_default'] = 0
        }
        this.setState({
            crrRole: dataRole
        })
    }

    handleChangeRoleTemplate(value) {
        var listCheckOperation = []
        if (value.roles.length > 0) {
            for (let index = 0; index < value.roles.length; index++) {
                listCheckOperation.push(value.roles[index].code_operation)
            }
        }
        var roles = this.state.crrRole;
        roles['roles'] = listCheckOperation;
        roles['name'] = value.label;
        this.setState({
            valueRoleTemplate: value,
            crrRole: roles,
            listCheckOperation: listCheckOperation,
            listLicense: []
        }, () => {
            this.getLicense()
        })
    }



    getLicense = () => {
        fetch(`${HOST_CLOUD}/api/license/get_by_company/${this.state.box_engine_cf.idCompany}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.access_token_cloud
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                this.setState({
                    listLicense: data.data
                })
            }
        })
    }

    getRoleTemplate = (HOST) => {
        fetch(`${HOST}/api/role/0/get_all`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                var optionRoleTemplate = [];
                if (data.data.length > 0) {
                    for (let index = 0; index < data.data.length; index++) {
                        optionRoleTemplate.push({ 'value': data.data[index].id, 'label': data.data[index].name, 'roles': data.data[index].roles })
                    }
                }
                this.setState({
                    optionRoleTemplate: optionRoleTemplate
                })
            }
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.show === true) {
            //Staff
            var listCheckOperation = []
            if (nextProps.data.roles.length > 0) {
                for (let index = 0; index < nextProps.data.roles.length; index++) {
                    listCheckOperation.push(nextProps.data.roles[index].code_operation)
                }
            }
            var crrRole = nextProps.data
            crrRole.roles = listCheckOperation

            this.setState({
                crrRole: crrRole,
                listCheckOperation: listCheckOperation,
                valueRoleTemplate: null,
            }, () => {
                this.getLicense()
                this.getRoleTemplate(nextProps.data.HOST)
            });
        }
    }

    handleEnter = (event) => {
        if (event.keyCode === 13) {
            const form = event.target.form;
            const index = Array.prototype.indexOf.call(form, event.target);
            form.elements[index + 1].focus();
            event.preventDefault();
        }
    }

    handleCheckOperation = (event) => {
        const checkedArr = [];
        let value;
        if (event.target.type !== 'checkbox') {
            value = event.target.value;
        } else {
            const checkeds = document.getElementsByName('operation');
            for (let i = 0; i < checkeds.length; i++) {
                if (checkeds[i].checked) {
                    checkedArr.push(checkeds[i].value);
                }
            }
            value = checkedArr;
        }
        var roles = this.state.crrRole;
        roles['roles'] = value;
        this.setState({
            crrRole: roles
        })
    }

    render() {
        let click_handle = (event) => {
            this.updateRole(this.state.crrRole, event);
        }

        const dataModule = this.state.listLicense.map((value, index) => {
            var lengthChecked = 0
            if (value.module !== null) {
                var checkboxOperation = value.module.operation.map((val, i) => {
                    let codeOperation = val.code.split(':')
                    if (this.state.listCheckOperation.indexOf(val.code) !== -1) {
                        lengthChecked++
                    }
                    return (
                        <label className="m-checkbox m-checkbox-day col-md-3 text_white_space pr-0" key={val.id}>
                            <input type="checkbox" name="operation" defaultValue={val.code} onChange={e => this.handleCheckOperation(e)} defaultChecked={this.state.listCheckOperation.indexOf(val.code) === -1 ? false : true} /> {codeOperation[1]}
                            <span />
                        </label>
                    )
                })

                return (
                    <div className="m-accordion__item" key={index}>
                        <div className="m-accordion__item-head">
                            <span className="m-accordion__item-title">{value.module.code}</span>
                            {/* <span className="m-accordion__item-title">{value.module.code} --- {lengthChecked} / {value.module.operation.length}</span> */}
                            {/* <span className="m-accordion__item-mode" /> */}
                        </div>
                        <div className="m-accordion__item-body collapse show">
                            <div className="m-accordion__item-content">
                                <div className="m-form__group form-group">
                                    <div className="m-checkbox-inline row m-0">
                                        {checkboxOperation}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

        })

        if (this.state.crrRole.active === 1) {
            this.state.isChecked = true
        } else {
            this.state.isChecked = false
        }

        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form id="formAddRole">
                        <div className="col-xl-12">
                            <div className="m-widget14">
                                <div className="form-group m-form__group">
                                    <label htmlFor="Name">{language[this.props.indexLanguage].textTable.name} <span className="text-danger"> *</span></label>
                                    <input className="form-control m-input" id="Name" name='name' value={this.state.crrRole.name === '' ? '' : this.state.crrRole.name} onKeyDown={(event) => this.handleEnter(event)} onChange={e => this.RoleHandle(e)} placeholder={language[this.props.indexLanguage].textTable.name} required />
                                </div>
                                <div className="form-group m-form__group ">
                                    <label>{language[this.props.indexLanguage].textTable.status}</label>
                                    <div className="pt-2 pb-2">
                                        <Switch
                                            onChange={() => {
                                                var active;
                                                if (!this.state.isChecked == true) {
                                                    active = 1
                                                } else {
                                                    active = 0
                                                }
                                                var crrRole = this.state.crrRole;
                                                crrRole['active'] = active;
                                                this.setState({
                                                    crrRole: crrRole,
                                                    isChecked: !this.state.isChecked
                                                });
                                            }}
                                            checked={this.state.isChecked}
                                            handleDiameter={17}
                                            offColor="#969696"
                                            onColor="#00c5dc"
                                            offHandleColor="#ffffff"
                                            onHandleColor="#08f"
                                            height={25}
                                            width={55}
                                            className="react-switch"
                                            id="small-radius-switch"
                                        />
                                    </div>
                                </div>
                                <div className="form-group m-form__group ">
                                    <label>{language[this.props.indexLanguage].Text_Notifi.role_template}</label>
                                    <Select
                                        getOptionValue={(option) => option.label}
                                        onChange={value => this.handleChangeRoleTemplate(value)}
                                        options={this.state.optionRoleTemplate}
                                        value={this.state.valueRoleTemplate}
                                        placeholder={language[this.props.indexLanguage].placeholderSelect.select_role_template}
                                    />
                                </div>
                                <div className="form-group m-form__group col-md-12 p-0 m-scrollable scroll scroll_customer_daily" style={{ 'height': '450px' }}>
                                    <div className="m-accordion m-accordion--bordered" id="m_accordion_2" role="tablist">
                                        {dataModule}
                                    </div>
                                </div>
                                <div className="form-group m-form__group ">
                                    <div className="m-checkbox-inline">
                                        <label className="m-checkbox m-checkbox-day">
                                            <input type="checkbox" name="is_default" defaultValue={this.state.crrRole.is_default} onClick={e => this.handleChangeIsDefault(e)} defaultChecked={this.state.crrRole.is_default === 0 ? false : true} /> Cài làm quyền mặc định
                                            <span />
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="accent" id="button_addRole" className="m-loader--light m-loader--right" onClick={click_handle}>{language[this.props.indexLanguage].textButton.save}</Button>
                    <Button variant="secondary" onClick={this.props.onHide}>{language[this.props.indexLanguage].textButton.close}</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

ModalRole.propTypes = {
    data: PropTypes.object,
    liststaff: PropTypes.array,
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool,
}
const mapStateToProps = state => {
    return {
        indexLanguage: state.changelanguage.indexLanguage,
    }
}
export default connect(mapStateToProps, null)(ModalRole);