import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, {useEffect} from 'react';
import { Router, Route, Switch } from "react-router-dom";
import Integrated from './components/screen/FaceManagement/Capture/Integrated';
import './css/main.css';
import Login from './Login';
import LoginSuperAdmin from './Login/LoginSuperAdmin';
import ReactRouter from './router/ReactRouter';
import store from './store';
import {Provider} from 'react-redux';
import ReactGA  from 'react-ga'
import createHistory from 'history/createBrowserHistory'

require('dotenv').config();

function App() {

  const history = createHistory()
  const env = process.env
  ReactGA.initialize(env.REACT_APP_GA_TRACKING_ID);
  history.listen((location) => {
    const title = location.pathname
    ReactGA.pageview(location.pathname + location.search, [""], title);
  });
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search, [""], window.location.pathname)
}, [history])

  return (
    <Provider store={store}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Router history={history}>
          <Switch>
            <Route path="/login" render={() => <Login />} />
            <Route path="/loginsa" render={() => <LoginSuperAdmin />} />
            <Route path="/capture_frame" render={() => <Integrated />} />
            <ReactRouter />
          </Switch>
        </Router>
      </MuiPickersUtilsProvider>
    </Provider>
  );
}

export default App;
