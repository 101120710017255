import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import language from '../../../../language/language';
import Axios from 'axios';
import moment from 'moment';

Report.propTypes = {    
    total_timekeeping: PropTypes.object
};

Report.defaultProps = {
    total_timekeeping: {}
}

function Report(props) {
    const {  total_timekeeping } = props;

    const [totalShift, setTotalShift] = useState(0);
    const [totaWorkSalary, setTotaWorkSalary] = useState(0);
    const [totalWorkWithoutSalary, setTotalWorkWithoutSalary] = useState(0);
    const [totalInLate, setTotalInLate] = useState(0);
    const [totalMinuteInLate, setTotalMinuteInLate] = useState(0);
    const [totalOutSoon, setTotalOutSoon] = useState(0);
    const [totalMinuteOutSoon, setTotalMinuteOutSoon] = useState(0);
    const [totalOT, setTotalOT] = useState(0);
    const [totalBusiness, setTotalBusiness] = useState(0);


    useEffect(() => {
        if(total_timekeeping) {
            setTotalShift(parseFloat(total_timekeeping.total_work))
            setTotaWorkSalary(parseFloat(total_timekeeping.total_workoff_with_salary))
            setTotalWorkWithoutSalary(parseFloat(total_timekeeping.total_workoff_without_salary))
            setTotalInLate(parseFloat(total_timekeeping.total_in_late))
            setTotalMinuteInLate(parseFloat(total_timekeeping.total_minute_in_late))
            setTotalOutSoon(parseFloat(total_timekeeping.total_out_soon))
            setTotalMinuteOutSoon(parseFloat(total_timekeeping.total_minute_out_soon))
            setTotalOT(parseFloat(total_timekeeping.total_hour_OT))
            setTotalBusiness(parseFloat(total_timekeeping.total_go_on_business))
        }
    } , [total_timekeeping]);
    
    const indexLanguage = useSelector(state => state.changelanguage.indexLanguage);


    return (
        <div className=' m-portlet m-portlet--tab mb-0 ml-md-1 p-3'>
            <div className="d-flex justify-content-between pb-3">
                <span>{language[indexLanguage].Timekeeping.total_shift_work}</span>
                {Number(totalShift.toFixed(2))}
            </div>
            <div className="d-flex justify-content-between pb-3">
                <span>{language[indexLanguage].Timekeeping.report_day_off}</span>
                {Number(totaWorkSalary.toFixed(2)) }
            </div>
            <div className="d-flex justify-content-between pb-3">
                <span>{language[indexLanguage].Timekeeping.report_day_off_no_salary}</span>
                {Number(totalWorkWithoutSalary.toFixed(2)) }
            </div>
            <div className="d-flex justify-content-between pb-3">
                <span>{language[indexLanguage].Timekeeping.in_late_counter}</span>
                {Number(totalInLate.toFixed(2))  }
            </div>
            <div className="d-flex justify-content-between pb-3">
                <span>{language[indexLanguage].Timekeeping.in_minute_late_counter}</span>
                {Number(totalMinuteInLate.toFixed(2))  }
            </div>
            <div className="d-flex justify-content-between pb-3">
                <span>{language[indexLanguage].Timekeeping.in_early_counter}</span>
                {Number(totalOutSoon.toFixed(2))  }
            </div>
            <div className="d-flex justify-content-between pb-3">
                <span>{language[indexLanguage].Timekeeping.in_minute_early_counter}</span>
                {Number(totalMinuteOutSoon.toFixed(2)) }
            </div>
            <div className="d-flex justify-content-between pb-3">
                <span>{language[indexLanguage].Timekeeping.in_hours_ot}</span>
                {Number(totalOT.toFixed(2)) }   
            </div>
            <div className="d-flex justify-content-between pb-3">
                <span>{language[indexLanguage].Timekeeping.work_go_business}</span>
                {Number(totalBusiness.toFixed(2)) }
            </div>
        </div>
    );
}

export default Report;