import { ExcelExport, ExcelExportColumnGroup } from '@progress/kendo-react-excel-export';
import { Grid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { useSelector } from 'react-redux';
import language from '../../../../../language/language';
import ReactGA  from 'react-ga'


TableTab.propTypes = {
    data: PropTypes.array,
    day_in_month: PropTypes.array,
    report: PropTypes.number,
    start_end: PropTypes.object,
};

TableTab.defaultProps = {
    data: null,
    day_in_month: null,
    report: 0,
    start_end: null,
}

function TableTab(props) {
    const { data, day_in_month, report, start_end } = props;

    const indexLanguage = useSelector(state => state.changelanguage.indexLanguage);
    
    const total_work = (report === 8) ? true : false;

    const minute = report === 1 || report === 2 ? true : false;
    const off = report === 1 || report === 2 || report === 3 || report === 4 || report === 13 ? true : false;

    const date = (report === 4 || report === 13 || report === 6 || report === 7 || report === 12) ? true : false;
    const day = (report === 7 || report === 6) ? true : false;
    const time_in = (report === 7 || report === 12) ? true : false;
    const time_out = (report === 7 || report === 12) ? true : false;
    const in_soon = report === 7 ? true : false;
    const in_late = report === 7 ? true : false;
    const out_soon = report === 7 ? true : false;
    const out_late = report === 7 ? true : false;
    const work = report === 7 ? true : false;
    const shift = report === 1 || report === 2 || report === 3 || report === 4 || report === 13 || report === 7 ? true : false;
    const reason_text = report === 7 ? true : false;

    const day_off = (report === 8) ? true : false;
    const business = (report === 8) ? true : false;

    const from_date = report === 10 || report === 11 ? true : false;
    const to_date = report === 10 || report === 11 ? true : false;

    const is_day_in_month = (report === 5 || report === 8) ? true : false;

    const [titleExcel, setTitleExcel] = useState('');

    const _export = React.createRef();;
    const exportFile = () => {
        ReactGA.event({
            category: 'Xem tung bao cao',
            action: "Xuất báo cáo",
            label: `Xuất ${titleExcel}`
          });
        _export.current.save();
    };

    const fieldExcel = () => {
        let title = '';
        switch (report) {
            case 1:
                title = `${language[indexLanguage].Report.report_1} ${language[indexLanguage].Report.from} ${moment(start_end.start_date).format('DD-MM-YYYY')} ${language[indexLanguage].Report.to} ${moment(start_end.end_date).format('DD-MM-YYYY')}`;
                break;
            case 2:
                title = `${language[indexLanguage].Report.report_2} ${language[indexLanguage].Report.from} ${moment(start_end.start_date).format('DD-MM-YYYY')} ${language[indexLanguage].Report.to} ${moment(start_end.end_date).format('DD-MM-YYYY')}`;
                break;
            case 3:
                title = `${language[indexLanguage].Report.report_3} ${moment(start_end.start_date).format('DD-MM-YYYY')}`;
                break;
            case 4:
                title = `${language[indexLanguage].Report.report_4} ${moment(start_end.start_date).format('DD-MM-YYYY')}`;
                break;
            case 5:
                title = `${language[indexLanguage].Report.report_5} ${language[indexLanguage].Report.from} ${moment(start_end.start_date).format('DD-MM-YYYY')} ${language[indexLanguage].Report.to} ${moment(start_end.end_date).format('DD-MM-YYYY')}`;
                break;
            case 6:
                title = `${language[indexLanguage].Report.report_6} ${language[indexLanguage].Report.from} ${moment(start_end.start_date).format('DD-MM-YYYY')} ${language[indexLanguage].Report.to} ${moment(start_end.end_date).format('DD-MM-YYYY')}`;
                break;
            case 7:
                title = `${language[indexLanguage].Report.report_7} ${language[indexLanguage].Report.month} ${new Date(start_end.start_date).getMonth() + 1}`;
                break;
            case 8:
                title = `${language[indexLanguage].Report.report_8} ${language[indexLanguage].Report.month} ${new Date(start_end.start_date).getMonth() + 1}`;
                break;
            case 9:
                title = `${language[indexLanguage].Report.report_9} ${language[indexLanguage].Report.month} ${new Date(start_end.start_date).getMonth() + 1}`;
                break;
            case 10:
                title = `${language[indexLanguage].Report.report_10} ${language[indexLanguage].Report.from} ${moment(start_end.start_date).format('DD-MM-YYYY')} ${language[indexLanguage].Report.to} ${moment(start_end.end_date).format('DD-MM-YYYY')}`;
                break;
            case 11:
                title = `${language[indexLanguage].Report.report_11} ${language[indexLanguage].Report.from} ${moment(start_end.start_date).format('DD-MM-YYYY')} ${language[indexLanguage].Report.to} ${moment(start_end.end_date).format('DD-MM-YYYY')}`;
                break;
            case 12:
                title = `${language[indexLanguage].Report.report_12} ${language[indexLanguage].Report.from} ${moment(start_end.start_date).format('DD-MM-YYYY')} ${language[indexLanguage].Report.to} ${moment(start_end.end_date).format('DD-MM-YYYY')}`;
                break;
            case 13:
                title = `${language[indexLanguage].Report.report_13} ${moment(start_end.start_date).format('DD-MM-YYYY')}`;
                break;
            default:
                break;
        }
        setTitleExcel(title);
    }

    const bulletedReportDetail = (data) => {
        const result = data.map((map, i) => {
            return (
                <tr key={i}>
                    {map.key === 1 &&
                        <>
                            <td style={{ verticalAlign: 'middle', borderBottomColor: '#000' }} rowSpan={map.number_shift * 2 + 2} >{map.id_staff}</td>
                            <td style={{ verticalAlign: 'middle', borderBottomColor: '#000' }} rowSpan={map.number_shift * 2 + 2} >{map.name}</td>
                            <td style={{ verticalAlign: 'middle', borderBottomColor: '#000' }} rowSpan={map.number_shift * 2 + 2} >{map.department}</td>
                        </>
                    }
                    <td style={map.key === 9 ? { verticalAlign: 'middle', textAlign: 'center', borderBottomColor: '#000' } : { verticalAlign: 'middle', textAlign: 'center' }} >{map.index}</td>
                    {
                        day_in_month.map((value, index) => {
                            return <td style={map.key === 9 ? { verticalAlign: 'middle', width: 50, borderBottomColor: '#000' } : { verticalAlign: 'middle', width: 50 }} key={index}>{map[value]}</td>
                        })
                    }
                    {map.key === 1 &&
                        <>
                            <td style={{ verticalAlign: 'middle', textAlign: 'center', borderBottomColor: '#000' }} rowSpan={map.number_shift * 2 + 2} >{map.work_day_off}</td>
                            <td style={{ verticalAlign: 'middle', textAlign: 'center', borderBottomColor: '#000' }} rowSpan={map.number_shift * 2 + 2} >{map.work_business}</td>
                            <td style={{ verticalAlign: 'middle', textAlign: 'center', borderBottomColor: '#000' }} rowSpan={map.number_shift * 2 + 2} >{map.total_work}</td>
                            <td style={{ verticalAlign: 'middle', textAlign: 'center', borderBottomColor: '#000' }} rowSpan={map.number_shift * 2 + 2} >{map.total_work_time}</td>
                            <td style={{ verticalAlign: 'middle', textAlign: 'center', borderBottomColor: '#000' }} rowSpan={map.number_shift * 2 + 2} >{map.number_late}</td>
                            <td style={{ verticalAlign: 'middle', textAlign: 'center', borderBottomColor: '#000' }} rowSpan={map.number_shift * 2 + 2} >{map.number_late_time}</td>
                            <td style={{ verticalAlign: 'middle', textAlign: 'center', borderBottomColor: '#000' }} rowSpan={map.number_shift * 2 + 2} >{map.number_soon}</td>
                            <td style={{ verticalAlign: 'middle', textAlign: 'center', borderBottomColor: '#000' }} rowSpan={map.number_shift * 2 + 2} >{map.number_soon_time}</td>
                            <td style={{ verticalAlign: 'middle', textAlign: 'center', borderBottomColor: '#000' }} rowSpan={map.number_shift * 2 + 2} >{map.ot_in_week}</td>
                            <td style={{ verticalAlign: 'middle', textAlign: 'center', borderBottomColor: '#000' }} rowSpan={map.number_shift * 2 + 2} >{map.ot_last_week}</td>
                            <td style={{ verticalAlign: 'middle', textAlign: 'center', borderBottomColor: '#000' }} rowSpan={map.number_shift * 2 + 2} >{map.ot_holiday}</td>
                        </>
                    }
                </tr>
            )
        })
        return result;
    }
    useEffect(() => {
        if (report && start_end) {
            fieldExcel();
        }
    }, [report, start_end.start_date, start_end.end_date]);
    if (!data) return <></>;
    return (
        <div className='timekeeping_excel' style={{ marginTop: '10px' }}>
            {
                report !== 9 ?
                    <>
                        <Grid
                            style={{ fontFamily: 'arial,verdana,sans-serif' }}
                            data={[...data]}
                        >
                            <GridToolbar>
                                <button
                                    title='Export Excel'
                                    onClick={exportFile}
                                    className='btn btn-primary'
                                >
                                    {language[indexLanguage].textButton.export_report}
                    </button>
                            </GridToolbar>
                            <Column field='id_staff' title='ID' width={100}/>
                            <Column field='name' title={language[indexLanguage].Report.name} width={200} />
                            <Column field='department' title={language[indexLanguage].Report.department} width={200} />
                            {
                                report === 1 &&
                                <Column field='time' title={language[indexLanguage].Report.time_in} width={200} />

                            }
                            {
                                report === 2 &&
                                <Column field='time' title={language[indexLanguage].Report.time_out} width={200} />

                            }
                            {
                                report === 3 &&
                                <Column field='time' title={language[indexLanguage].Report.time_check_in} width={200} />

                            }
                            {
                                minute &&
                                <Column field='minute' title={language[indexLanguage].Report.minute} width={200} />

                            }
                            {
                                (day_in_month && is_day_in_month) && day_in_month.map(map => (
                                    <Column field={map.toString()} title={map} width={50} />
                                ))
                            }
                            {/* {
                                (day_in_month) && day_in_month.map(map => (
                                    <Column field={map.toString()} title={map} width={map.toString() ? 60 : 50} />
                                ))
                            } */}
                            {
                                date &&
                                <Column field='date' title={language[indexLanguage].Report.date} width={100} />
                            }
                            {
                                day &&
                                <Column field='day' title={language[indexLanguage].Report.day} width={100} />
                            }
                            {
                                time_in &&
                                <Column field='time_in' title={language[indexLanguage].Report.time_in} width={70} />
                            }
                            {
                                time_out &&
                                <Column field='time_out' title={language[indexLanguage].Report.time_out} width={70} />
                            }
                            {
                                in_soon &&
                                <Column field='in_soon' title={language[indexLanguage].Report.in_soon} width={70} />
                            }
                            {
                                in_late &&
                                <Column field='in_late' title={language[indexLanguage].Report.in_late} width={70} />
                            }
                            {
                                out_soon &&
                                <Column field='out_soon' title={language[indexLanguage].Report.out_soon} width={70} />
                            }
                            {
                                out_late &&
                                <Column field='out_late' title={language[indexLanguage].Report.out_late} width={70} />
                            }
                            {
                                work &&
                                <Column field='work' title={language[indexLanguage].Report.work} width={70} />
                            }
                            {
                                shift &&
                                <Column field='name_shift' title={language[indexLanguage].Report.shift} width={100} />
                            }
                            {
                                reason_text &&
                                <Column field='reason_text' title={language[indexLanguage].Report.note} width={100} />
                            }
                            {
                                day_off &&
                                <Column field='work_day_off' title={language[indexLanguage].Report.day_off} width={100} />
                            }
                            {
                                business &&
                                <Column field='work_business' title={language[indexLanguage].Report.business} width={100} />
                            }
                            {
                                total_work &&
                                <Column field='total_work' title={language[indexLanguage].Report.total_work} width={100} />
                            }
                            {
                                report === 5 &&
                                <Column field='total_time_ot' title={language[indexLanguage].Report.total_time_ot} width={100} />
                            }
                            {
                                report === 6 &&
                                <Column field='number_in_out' title={language[indexLanguage].Report.number_in_out} width={100} />
                            }
                            {
                                report === 6 &&
                                <Column field='time' title={language[indexLanguage].Report.time} />
                            }
                            {
                                from_date &&
                                <Column field='from_date' title={language[indexLanguage].Report.from_date} width={150} />
                            }
                            {
                                to_date &&
                                <Column field='to_date' title={language[indexLanguage].Report.to_date} width={150} />
                            }
                            {
                                report === 10 &&
                                <Column field='reason' title={language[indexLanguage].Report.reason_off} width={250} />
                            }
                            {
                                report === 11 &&
                                <Column field='business_place' title={language[indexLanguage].Report.business_place} />
                            }
                            {
                                report === 11 &&
                                <Column field='business_content' title={language[indexLanguage].Report.business_content} />
                            }
                            {
                                report === 12 &&
                                <Column field='place' title={language[indexLanguage].Report.place} width={150} />
                            }
                            {
                                off &&
                                <Column field='off' title={language[indexLanguage].Report.reason} width={80} />
                            }
                        </Grid>
                        <ExcelExport
                            data={[...data]}
                            ref={_export}
                            fileName={`${titleExcel} .xlsx`}
                        >
                            <ExcelExportColumnGroup title={titleExcel} headerCellOptions={{ textAlign: 'center', verticalAlign: 'center', bold: true, fontSize: 15 }}
                            >
                                <Column field='id_staff' title='ID' width={100} />
                                <Column field='name' title={language[indexLanguage].Report.name} width={200} />
                                <Column field='department' title={language[indexLanguage].Report.department} width={200} />
                                {
                                    report === 1 &&
                                    <Column field='time' title={language[indexLanguage].Report.time_in} width={200} />

                                }
                                {
                                    report === 2 &&
                                    <Column field='time' title={language[indexLanguage].Report.time_out} width={200} />

                                }
                                {
                                    report === 3 &&
                                    <Column field='time' title={language[indexLanguage].Report.time_check_in} width={200} />

                                }
                                {
                                    minute &&
                                    <Column field='minute' title={language[indexLanguage].Report.minute} width={200} />

                                }

                                {/* {
                                report === 9 &&
                                <Column field='index' title='Chỉ số' width={60} />
                            } */}
                                {
                                    (day_in_month && is_day_in_month) && day_in_month.map(map => (
                                        <Column field={map.toString()} title={map} width={50} />
                                    ))
                                }
                                {
                                    (day_in_month) && day_in_month.map(map => (
                                        <Column field={map.toString()} title={map} width={map.toString() ? 60 : 50} />
                                    ))
                                }
                                {
                                    date &&
                                    <Column field='date' title={language[indexLanguage].Report.date} width={100} />
                                }
                                {
                                    day &&
                                    <Column field='day' title={language[indexLanguage].Report.day} width={100} />
                                }
                                {
                                    time_in &&
                                    <Column field='time_in' title={language[indexLanguage].Report.time_in} width={70} />
                                }
                                {
                                    time_out &&
                                    <Column field='time_out' title={language[indexLanguage].Report.time_out} width={70} />
                                }
                                {
                                    in_soon &&
                                    <Column field='in_soon' title={language[indexLanguage].Report.in_soon} width={70} />
                                }
                                {
                                    in_late &&
                                    <Column field='in_late' title={language[indexLanguage].Report.in_late} width={70} />
                                }
                                {
                                    out_soon &&
                                    <Column field='out_soon' title={language[indexLanguage].Report.out_soon} width={70} />
                                }
                                {
                                    out_late &&
                                    <Column field='out_late' title={language[indexLanguage].Report.out_late} width={70} />
                                }
                                {
                                    work &&
                                    <Column field='work' title={language[indexLanguage].Report.work} width={70} />
                                }
                                {
                                    shift &&
                                    <Column field='name_shift' title={language[indexLanguage].Report.shift} width={100} />
                                }
                                {
                                    reason_text &&
                                    <Column field='reason_text' title={language[indexLanguage].Report.note} width={100} />
                                }
                                {
                                    day_off &&
                                    <Column field='work_day_off' title={language[indexLanguage].Report.day_off} width={100} />
                                }
                                {
                                    business &&
                                    <Column field='work_business' title={language[indexLanguage].Report.business} width={100} />
                                }
                                {
                                    total_work &&
                                    <Column field='total_work' title={language[indexLanguage].Report.total_work} width={100} />
                                }
                                {
                                    report === 5 &&
                                    <Column field='total_time_ot' title={language[indexLanguage].Report.total_time_ot} width={100} />
                                }
                                {
                                    report === 6 &&
                                    <Column field='number_in_out' title={language[indexLanguage].Report.number_in_out} width={100} />
                                }
                                {
                                    report === 6 &&
                                    <Column field='time' title={language[indexLanguage].Report.time} />
                                }
                                {
                                    from_date &&
                                    <Column field='from_date' title={language[indexLanguage].Report.from_date} width={150} />
                                }
                                {
                                    to_date &&
                                    <Column field='to_date' title={language[indexLanguage].Report.to_date} width={150} />
                                }
                                {
                                    report === 10 &&
                                    <Column field='reason' title={language[indexLanguage].Report.reason_off} width={250} />
                                }
                                {
                                    report === 11 &&
                                    <Column field='business_place' title={language[indexLanguage].Report.business_place} />
                                }
                                {
                                    report === 11 &&
                                    <Column field='business_content' title={language[indexLanguage].Report.business_content} />
                                }
                                {
                                    report === 12 &&
                                    <Column field='place' title={language[indexLanguage].Report.place} width={150} />
                                }
                                {
                                    off &&
                                    <Column field='off' title={language[indexLanguage].Report.reason} width={80} />
                                }
                            </ExcelExportColumnGroup>
                        </ExcelExport>
                    </>
                    :
                    <>
                        <div className='k-header k-grid-toolbar' style={{ border: '1px solid #dee2d6' }}>
                            <ReactHTMLTableToExcel
                                className='btn btn-info'
                                table='tableData'
                                filename={`${language[indexLanguage].Report.report_9} ${new Date(start_end.start_date).getMonth() + 1}`}
                                sheet='tablexls'
                                buttonText={language[indexLanguage].textButton.export_report}
                            />
                        </div>
                        <div className='table-responsive text-nowrap' style={{ border: '1px solid #dee2d6', borderTop: 'none' }}>
                            <table className='table table-bordered bg-white table-hover table-checkable dataTable no-footer dtr-inline collapsed mb-0' id='tableData'>
                                <thead>
                                    <tr>
                                        <th style={{ verticalAlign: 'middle', backgroundColor: '#aaa', textAlign: 'center' }} colSpan="50">{`BÁO CÁO CHẤM CÔNG CHI TIẾT THÁNG ${new Date(start_end.start_date).getMonth() + 1}`}</th>
                                    </tr>
                                    <tr>
                                        <th style={{ whiteSpace: 'nowrap', verticalAlign: 'middle', width: 100, borderBottomColor: '#000' }}>ID</th>
                                        <th style={{ whiteSpace: 'nowrap', verticalAlign: 'middle', borderBottomColor: '#000' }}>{language[indexLanguage].Report.name}</th>
                                        <th style={{ whiteSpace: 'nowrap', verticalAlign: 'middle', borderBottomColor: '#000' }}>{language[indexLanguage].Report.department}</th>
                                        <th style={{ whiteSpace: 'nowrap', verticalAlign: 'middle', borderBottomColor: '#000', textAlign: 'center' }}>{language[indexLanguage].Report.index}</th>
                                        {
                                            day_in_month.map((value, index) => {
                                                return <th style={{ whiteSpace: 'nowrap', verticalAlign: 'middle', textAlign: 'center', borderBottomColor: '#000' }} key={index}>{value}</th>
                                            })
                                        }
                                        <th style={{ whiteSpace: 'nowrap', verticalAlign: 'middle', width: 100, textAlign: 'center', borderBottomColor: '#000' }}>{language[indexLanguage].Report.day_off}</th>
                                        <th style={{ whiteSpace: 'nowrap', verticalAlign: 'middle', width: 100, textAlign: 'center', borderBottomColor: '#000' }}>{language[indexLanguage].Report.business}</th>
                                        <th style={{ whiteSpace: 'nowrap', verticalAlign: 'middle', width: 100, textAlign: 'center', borderBottomColor: '#000' }}>{language[indexLanguage].Report.total_work}</th>
                                        <th style={{ whiteSpace: 'nowrap', verticalAlign: 'middle', width: 100, textAlign: 'center', borderBottomColor: '#000' }}>{language[indexLanguage].Report.total_work_time}</th>
                                        <th style={{ whiteSpace: 'nowrap', verticalAlign: 'middle', width: 100, textAlign: 'center', borderBottomColor: '#000' }}>{language[indexLanguage].Report.number_late}</th>
                                        <th style={{ whiteSpace: 'nowrap', verticalAlign: 'middle', width: 100, textAlign: 'center', borderBottomColor: '#000' }}>{language[indexLanguage].Report.late_minute}</th>
                                        <th style={{ whiteSpace: 'nowrap', verticalAlign: 'middle', width: 100, textAlign: 'center', borderBottomColor: '#000' }}>{language[indexLanguage].Report.number_soon}</th>
                                        <th style={{ whiteSpace: 'nowrap', verticalAlign: 'middle', width: 100, textAlign: 'center', borderBottomColor: '#000' }}>{language[indexLanguage].Report.soon_minute}</th>
                                        <th style={{ whiteSpace: 'nowrap', verticalAlign: 'middle', width: 100, textAlign: 'center', borderBottomColor: '#000' }}>{language[indexLanguage].Report.ot_in_week}</th>
                                        <th style={{ whiteSpace: 'nowrap', verticalAlign: 'middle', width: 100, textAlign: 'center', borderBottomColor: '#000' }}>{language[indexLanguage].Report.ot_late_week}</th>
                                        <th style={{ whiteSpace: 'nowrap', verticalAlign: 'middle', width: 100, textAlign: 'center', borderBottomColor: '#000' }}>{language[indexLanguage].Report.ot_holiday}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {bulletedReportDetail(data)}
                                </tbody>

                            </table>
                        </div>
                    </>
            }
        </div>
    );
}

export default TableTab;