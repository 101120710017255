import moment from "moment";
import language from "../../../../../language/language";
import store from "../../../../../store";

const indexLanguage = store.getState().changelanguage.indexLanguage;

export const coverTimeToMinute = (time) => {
    //HH:mm:ss
    const time_ = time.split(':');
    return (parseInt(time_[0]) * 60 + parseInt(time_[1]));
}

export const workTimekeeping = (time_keeping, shift, date) => {
    let work = 0;
    if (time_keeping.some(some => some.id_shift === shift.id && date === new Date(some.date).getDate())) {
        const data = time_keeping.filter(filter => filter.id_shift === shift.id && date === new Date(filter.date).getDate())[0];
        work += data.work;
        if (data.time_out === null) {
            return [work, true];
        } else {
            return [work, false];
        }
    } else {
        const data = time_keeping.filter(filter => filter.id === undefined && date === new Date(filter.date).getDate())[0];
        if (data !== undefined) {
            work += data.work;
            return [work, true];
        }
        return [-1, true];
    }
}

export const workLetter = (dof, shift, date, date_month) => {
    let work = 0;
    let symbol = '';
    let reason = '';

    let day_of_week = shift.day_of_week.slice(1, 14).split('|');
    let test = day_of_week[6];
    day_of_week.unshift(test);
    day_of_week.pop();
    for (let i = 0; i < dof.length; i++) {
        const start_date = new Date(dof[i].start_date);
        const end_date = new Date(dof[i].end_date);

        const time_in_letter = moment(dof[i].start_date).format('HH:mm:ss');
        const time_out_letter = moment(dof[i].end_date).format('HH:mm:ss');

        //Don tu nam trong thoi gian cua ca lam
        let is_true = true;
        //Don 1 ngay
        if (start_date.getDate() === end_date.getDate()) {
            is_true = (time_out_letter <= shift.time_in || time_in_letter >= shift.time_out) ? false : true;
        } else {
            //Ngay dau
            if (start_date.getDate() === date && time_in_letter >= shift.time_out) {
                is_true = false;
            }
            //Ngay cuoi
            else if (end_date.getDate() === date && time_out_letter <= shift.time_in) {
                is_true = false;
            }
        }
        // if (((moment(start_date).format('YYYY-MM-DD') <= date_month && moment(end_date).format('YYYY-MM-DD') >= date_month) && day_of_week[new Date(date_month).getDay()] === '1') && is_true) {
        if (((moment(start_date).format('YYYY-MM-DD') <= date_month && moment(end_date).format('YYYY-MM-DD') >= date_month)) && is_true) {
            if (dof[i].type !== 1) {
                work += dof[i].work_off;
            } else if (dof[i].type === 1) {
                if (dof[i].reason.coefficient !== undefined && dof[i].reason.coefficient !== 0) {
                    work += dof[i].work_off;
                }
            }

            switch (dof[i].type) {
                case 1:
                    symbol += `, ${dof[i].reason.symbol}`;
                    reason += `, ${dof[i].reason.name}`;
                    break;
                case 2:
                    symbol += ', OT';
                    reason += `, ${language[indexLanguage].Timekeeping.ot} ${moment(dof[i].start_date).format('HH:mm')}-${moment(dof[i].end_date).format('HH:mm')}`;
                    break;
                case 3:
                    symbol += ', CT';
                    reason += `, ${language[indexLanguage].Timekeeping.business}`;
                    break;
                case 4:
                    symbol += ', MS';
                    reason += `, ${language[indexLanguage].Timekeeping.late_early}`;
                    break;
                case 5:
                    symbol += ', CCB';
                    reason += `, ${language[indexLanguage].Timekeeping.compensate} ${moment(dof[i].start_date).format('HH:mm')}-${moment(dof[i].end_date).format('HH:mm')}`;
                    break;
                default:
                    break;
            }
        }
    }
    return [work, symbol.substring(2), reason.substring(2)];
}



export const workIntersect = (time_keeping, dof, shift, date, date_month) => {
    const dof_not_type_4 = dof.filter(filter => filter.type !== 4);

    const dof_not_work = dof_not_type_4.filter(filter => filter.type !== 1 || (filter.reason && filter.reason.coefficient !== 0));
    let work = 0;
    let day_of_week = shift.day_of_week.slice(1, 14).split('|');
    let test = day_of_week[6];
    day_of_week.unshift(test);
    day_of_week.pop();
    for (let i = 0; i < dof_not_work.length; i++) {
        const start_date = new Date(dof_not_work[i].start_date);
        const end_date = new Date(dof_not_work[i].end_date);

        const time_in_letter = moment(dof_not_work[i].start_date).format('HH:mm:ss');
        const time_out_letter = moment(dof_not_work[i].end_date).format('HH:mm:ss');

        //Don tu nam trong thoi gian cua ca lam
        let is_true = true;
        //Don 1 ngay
        if (start_date.getDate() === end_date.getDate()) {
            is_true = (time_out_letter <= shift.time_in || time_in_letter >= shift.time_out) ? false : true;
        } else {
            //Ngay dau
            if (start_date.getDate() === date && time_in_letter >= shift.time_out) {
                is_true = false;
            }
            //Ngay cuoi
            else if (end_date.getDate() === date && time_out_letter <= shift.time_in) {
                is_true = false;
            }
        }

        //Don tu nam trong ngay
        if (((moment(start_date).format('YYYY-MM-DD') <= date_month && moment(end_date).format('YYYY-MM-DD') >= date_month) && day_of_week[start_date.getDay()] === '1') && is_true) {
            //Cham cong nam trong ca
            if (time_keeping.some(some => some.id_shift === shift.id && date === new Date(some.date).getDate())) {
                let data = time_keeping.filter(filter => filter.id_shift === shift.id && date === new Date(filter.date).getDate())[0];

                let data_timekeeping = {
                    ...data,
                    time_in: moment(data.time_in).format('HH:mm:ss'),
                    time_out: moment(data.time_out).format('HH:mm:ss'),
                }

                if (data_timekeeping.time_in < shift.time_in) {
                    data_timekeeping.time_in = shift.time_in;
                }
                if (data_timekeeping.time_out > shift.time_out) {
                    data_timekeeping.time_out = shift.time_out;
                }
                //Khong giao
                if (moment(dof_not_work[i].start_date).format('HH:mm:ss') >= moment(data.time_out).format('HH:mm:ss') || moment(dof_not_work[i].end_date).format('HH:mm:ss') <= moment(data.time_in).format('HH:mm:ss')) {

                } else {
                    work += calculationWorkIntersect(dof_not_work[i], data_timekeeping, date);
                }
            }
        }
    }
    return work;
}


export const calculationWorkLetter = (dof, shift, date) => {
    const start_dof = new Date(dof.start_date).getDate();
    const end_dof = new Date(dof.end_date).getDate();

    const start_dof_time = moment(dof.start_date).format('HH:mm:ss');
    const end_dof_time = moment(dof.end_date).format('HH:mm:ss');

    let start = shift.time_in;
    let end = shift.time_out;

    //Don 1 ngay
    if (date === start_dof && date === end_dof) {
        if (start_dof_time > shift.time_in) {
            start = start_dof_time;
        }
        if (end_dof_time < shift.time_out) {
            end = end_dof_time;
        }
        //Don nhieu ngay
    } else {
        //Ngay dau
        if (date === start_dof) {
            if (start_dof_time > shift.time_in) {
                start = start_dof_time;
            }
            //Ngay cuoi
        } else if (date === end_dof) {
            if (end_dof_time < shift.time_out) {
                end = end_dof_time;
            }
        }
    }

    const time_off = shift.start_time_off ? coverTimeToMinute(shift.end_time_off) - coverTimeToMinute(shift.start_time_off) : 0;

    let total_time = 0;

    if (shift.start_time_off) {
        if (end <= shift.start_time_off || start >= shift.end_time_off) {
            total_time = coverTimeToMinute(end) - coverTimeToMinute(start)
        } else {
            //nghi giua ca
            let start_off = shift.start_time_off;
            let end_off = shift.end_time_off
            if (start >= start_off) {
                start_off = start;
            }
            if (end <= end_off) {
                end_off = end;
            }
            total_time = (coverTimeToMinute(end) - coverTimeToMinute(start)) - (coverTimeToMinute(end_off) - coverTimeToMinute(start_off))
        }
    } else {
        total_time = coverTimeToMinute(end) - coverTimeToMinute(start);
    }
    const total_time_shift = coverTimeToMinute(shift.time_out) - coverTimeToMinute(shift.time_in) - time_off;

    return ((total_time / total_time_shift) * shift.work);

}

export const calculationWorkIntersect = (dof, time_keeping, date) => {
    const total = coverTimeToMinute(time_keeping.time_out) - coverTimeToMinute(time_keeping.time_in);

    const start_dof = new Date(dof.start_date).getDate();
    const end_dof = new Date(dof.end_date).getDate();

    const start_dof_time = moment(dof.start_date).format('HH:mm:ss');
    const end_dof_time = moment(dof.end_date).format('HH:mm:ss');


    let start = time_keeping.time_in;
    let end = time_keeping.time_out;

    //Don 1 ngay
    if (date === start_dof && date === end_dof) {

        if (start_dof_time > time_keeping.time_in) {
            start = start_dof_time;
        }
        if (end_dof_time < time_keeping.time_out) {
            end = end_dof_time;
        }
        //Don nhieu ngay
    } else {
        //Ngay dau
        if (date === start_dof) {
            if (start_dof_time > time_keeping.time_in) {
                start = start_dof_time;
            }
            //Ngay cuoi
        } else if (date === end_dof) {
            if (end_dof_time < time_keeping.time_out) {
                end = end_dof_time;
            }
        }
    }

    const total_time = coverTimeToMinute(end) - coverTimeToMinute(start);

    return ((total_time / total) * time_keeping.work);
}

export const coverDateToNameDay = (current_day, language) => {
    let day_name = '';
    switch (current_day) {
        case 0:
            day_name = language === 0 ? 'Sunday' : 'Chủ Nhật';
            break;
        case 1:
            day_name = language === 0 ? 'Monday' : 'Thứ Hai';
            break;
        case 2:
            day_name = language === 0 ? 'Tuesday' : 'Thứ Ba';
            break;
        case 3:
            day_name = language === 0 ? 'Wednesday' : 'Thứ Tư';
            break;
        case 4:
            day_name = language === 0 ? 'Thursday' : 'Thứ Năm';
            break;
        case 5:
            day_name = language === 0 ? 'Friday' : 'Thứ Sáu';
            break;
        case 6:
            day_name = language === 0 ? 'Saturday' : 'Thứ Bảy';
    };
    return day_name;
}


export const processDataListDay = (data, key, dataSearch) => {

    let listDataDate = []
    var diff_day = (new Date(dataSearch.end_date) - new Date(dataSearch.start_date)) / (1000 * 60 * 60 * 24)
    var crrDate = new Date(dataSearch.start_date);
    for (let index = 0; index <= diff_day; index++) {
        listDataDate.push(moment(crrDate).format('YYYY-MM-DD'))
        crrDate.setDate(crrDate.getDate() + 1)
    }
    var listData = [];

    data.map((e, index) => {
        const time_keepings = e.timekeepings

        // let e_time_keeping = {};
        // let e_dof = {};
        // listDataDate.forEach(element => {
        //     let array_timekeeping = list_timekeeping.filter(filter => moment(filter.date).format('YYYY-MM-DD') === element);
        //     e_time_keeping[element] = array_timekeeping;
        // });

        listDataDate.map((dataDate) => {
            let time_keeping = time_keepings[dataDate].timekeeping_list

            let list_dof = time_keepings[dataDate].dof_list

            var symbol = ''
            let dof_letters = []

            list_dof.map(dof => {
                var renderText = ""

                if (dof.type === 4) {
                    let shiftNow
                    if (e.shifts.length > 0) {
                        for (let index = 0; index < e.shifts.length; index++) {
                            const element = e.shifts[index];
                            let day_of_week = element.day_of_week.slice(1, 14).split('|');
                            let test = day_of_week[6];
                            day_of_week.unshift(test);
                            day_of_week.pop();
                            element.dayOfWeekk = day_of_week
                            if (element.dayOfWeekk[moment(dof.start_date).isoWeekday()] === "1") {
                                shiftNow = element
                            }
                        }
                    }
                    if (shiftNow !== undefined && Object.keys(shiftNow).length !== 0 && shiftNow.time_in !== undefined) {
                        let time_in_shift = moment(dof.start_date).format('YYYY-MM-DD') + " " + shiftNow.time_in.slice(0, 5) + ':00';

                        let time_out_shift = moment(dof.end_date).format('YYYY-MM-DD') + " " + shiftNow.time_out.slice(0, 5) + ':00';
                        let time_in = moment(dof.start_date);
                        let time_out = moment(dof.end_date);

                        let in_late = time_in.diff(time_in_shift);
                        let out_soon = time_out.diff(time_out_shift);

                        let durationInLate = moment.duration(in_late);
                        let durationOutSoon = moment.duration(out_soon);

                        let minutesLate = durationInLate.asMinutes();
                        let minutesSoon = durationOutSoon.asMinutes();

                        let minute_late = Math.abs(minutesLate);
                        let minute_soon = Math.abs(minutesSoon);

                        if (minute_late === 0) {
                            renderText = `(${language[indexLanguage].Report.out_soon_time}: ${minute_soon} ${language[indexLanguage].Report.minutes})`
                        } else if (minute_soon === 0) {
                            renderText = `(${language[indexLanguage].Report.to_late_time}: ${minute_late} ${language[indexLanguage].Report.minutes})`
                        } else {
                            renderText = `(${language[indexLanguage].Report.to_late_time}: ${minute_late} ${language[indexLanguage].Report.minutes} - ${language[indexLanguage].Report.out_soon_time}: ${minute_soon} ${language[indexLanguage].Report.minutes})`
                        }

                    }
                }
                var sb = ''
                switch (dof.type) {
                    case 1:
                        sb = `${dof.reason.name}`;
                        break;
                    case 2:
                        sb = `${language[indexLanguage].Timekeeping.ot} ${moment(dof.start_date).format('HH:mm')}-${moment(dof.end_date).format('HH:mm')}`;
                        break;
                    case 3:
                        sb = `CT (${moment(dof.start_date).format("HH:mm")} - ${moment(dof.end_date).format("HH:mm")})`;
                        break;
                    case 4:
                        sb = `MS ${renderText}`;
                        break;
                    case 5:
                        sb = `CCB (${moment(dof.start_date).format("HH:mm")} - ${moment(dof.end_date).format("HH:mm")})`;
                        break;
                    default:
                        break;
                }
                if (sb != '')
                    dof_letters.push(sb)
            })

            if (dof_letters.length > 0) {
                symbol = dof_letters.join(', ')
            }
            var data = false;
            if (time_keeping.length > 0) {
                for (let i = 0; i < time_keeping.length; i++) {
                    if (time_keeping[i]) {
                        if (data && Object.keys(time_keeping[i].shift).length == 0) 
                            continue;
                        const work_timekeeping = time_keeping[i].work;
                        const work = work_timekeeping;
                        data = {
                            box_engine_id: time_keeping[i].box_engine_id,
                            id_staff: e.id_staff,
                            email: e.email,
                            id: time_keeping[i].id,
                            date: dataDate,
                            department: e.name_department,
                            name: e.name,
                            day: coverDateToNameDay(new Date(dataDate).getDay(), indexLanguage),
                            time_in: time_keeping[i].time_in ? moment(time_keeping[i].time_in).format('HH:mm:ss') : '',
                            time_out: time_keeping[i].time_out ? moment(time_keeping[i].time_out).format('HH:mm:ss') : '',
                            in_soon: time_keeping[i].in_soon === 0 ? "" : time_keeping[i].in_soon,
                            in_late: time_keeping[i].in_late === 0 ? "" : time_keeping[i].in_late,
                            out_soon: time_keeping[i].out_soon === 0 ? "" : time_keeping[i].out_soon,
                            out_late: time_keeping[i].out_late === 0 ? "" : time_keeping[i].out_late,
                            work: parseFloat(work).toFixed(2),
                            shift: {
                                id: time_keeping[i].shift.id,
                                title: time_keeping[i].shift.name,
                                time_in: time_keeping[i].shift.time_in,
                                time_out: time_keeping[i].shift.time_out,
                            },
                            id_staff_db: e.id,
                            reason_text: symbol,
                        }
                    }
                }
            } else {
                data = {
                    box_engine_id: null,
                    id_staff: e.id_staff,
                    email: e.email,
                    id: 0,
                    date: dataDate,
                    department: e.name_department,
                    name: e.name,
                    day: coverDateToNameDay(new Date(dataDate).getDay(), indexLanguage),
                    time_in: '',
                    time_out: '',
                    in_soon: '',
                    in_late: '',
                    out_soon: '',
                    out_late: '',
                    work: "0.00",
                    shift: {
                        id: 0,
                        title: '',
                        time_in: '',
                        time_out: '',
                    },
                    id_staff_db: e.id,
                    reason_text: symbol,
                }
            }
            listData.push(data);

        })
    })
    return listData;
}

export const workLetterOT = (dof) => {
    let total = 0;
    if (dof.length === 0) return total;
    dof.map(map => {
        const start = moment(map.start_date).format('HH:mm').split(':');
        const end = moment(map.end_date).format('HH:mm').split(':');
        total += (parseInt(end[0]) * 60 + parseInt(end[1])) - (parseInt(start[0]) * 60 + parseInt(start[1]));
    })
    return parseFloat((total / 60).toFixed(2));
}

export const workLetterOTHoliday = (dof, holiday) => {
    let total = 0;
    if (dof.length === 0) return total;
    dof.map(map => {
        const holi = Object.keys(holiday);
        if (holi && holi.some(some => some === moment(map.start_date).format('YYYY-MM-DD'))) {
            const start = moment(map.start_date).format('HH:mm').split(':');
            const end = moment(map.end_date).format('HH:mm').split(':');
            total += (parseInt(end[0]) * 60 + parseInt(end[1])) - (parseInt(start[0]) * 60 + parseInt(start[1]));
        }
    })
    return parseFloat((total / 60).toFixed(2));
}