export const SETBOX = 'SETBOX';
export function setbox(box_engine_cf, list_company, is_company){
    return {
        type: SETBOX,
        payload:{
            box_engine_cf: box_engine_cf,
            list_company: list_company,
            is_company: is_company,
        }
    }
}
