import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import { getApiStatisticStaff } from '../../../../../api';
import language from '../../../../../language/language';
import HeaderTimekeeping from '../components/HeaderTimekeeping';
import LoadingOverLay from '../components/LoadingOverlay';
import { processDataListDay } from '../components/processData';
import Switch from "react-switch";
import Moment from 'moment';
import ApprovalForm from '../components/approvalForm';
import axios from 'axios';
import TreeView from '../../../TreeView';
import { formatUsernameFromEmail, formatTreeViewData } from '../../../CommonFunction';
const portletBodyCss = {
    paddingTop: 0,
    paddingBottom: 0,
};
TimekeepingDetail.propTypes = {
    data: PropTypes.object,
};
TimekeepingDetail.defaultProps = {
    data: null,
}

function TimekeepingDetail(props) {
    const { data } = props;
    const indexLanguage = useSelector(state => state.changelanguage.indexLanguage);
    const box_engine_cf = useSelector(state => state.boxai.box_engine_cf);
    const token = useSelector(state => state.isLogin.access_token);

    const [crrData, setCrrData] = useState(null);
    const [loading, setLoading] = useState(false);

    const type = localStorage.getItem("type");
    const userData = JSON.parse(localStorage.getItem('data_user'));
    const dataRole = useSelector(state => state.role.role);
    const [show, setShow] = useState(false);
    const [dataConfirm, setDataConfirm] = useState([]);
    const [selectDepartment, setSelectDepartment] = useState(false);
    const [departmentCurrent, setDepartmentCurrent] = useState({
        name: language[indexLanguage].placeholderSelect.all_department,
        value: -1,
    });

    const initial = {
        data_search: '',
        id_shift: -1,
        id_department: -1,
        start_date: moment(new Date).format('YYYY-MM-DD'),
        end_date: moment(new Date).format('YYYY-MM-DD'),
        id_company: box_engine_cf.idCompany,
        index: 1,
        is_export: 0,
        item_per_page: 20,
        type_export: 6
    }
    const [dataSearch, setDataSearch] = useState(initial);

    const getDataSearch = (data) => {
        const dataPost = {
            ...Object.assign(dataSearch, data),
            index: 1,
        };
        setDataSearch(dataPost);
        fetchData(dataPost);
    }
    const fetchData = async (dataPost) => {
        setLoading(true);
        setCrrData(null);
        var data = dataPost ? dataPost : dataSearch;
        if (dataRole.indexOf(type + '/timekeeping:view_all') === -1 && dataRole.indexOf(type + '/timekeeping:view_by_manager') === -1)
            data.staff_id = userData.id_staff;
        const result = await getApiStatisticStaff(box_engine_cf.crrApiSocket, data, token);
        setCrrData(processDataListDay(result.data));
        setLoading(false);
    }

    const closeModal = () => {
        setShow(false);
    }
    const handleSelectDepartment = () => {
        setSelectDepartment(prev => !prev);
    }
    const openModalConfirm = (e) => {
        const dataPost = {
            id: e.dof_list.length > 0 ? e.dof_list[0].id : 0,
            id_staff: e.staff_id,
            end_date: Moment(new Date(e.date)).format('YYYY-MM-DD') + ' ' + e.shift_time_out,
            status: 3,
            id_supervisor: userData.id_staff != "" ? userData.id_staff : 0,
            is_approval: 1,
            data: e
        }
        setShow(true);
        setDataConfirm(dataPost);
    }

    const removeConfirm = (e) => {
        swal({
            title: language[indexLanguage].Text_Notifi.are_you_sure,
            text: language[indexLanguage].Text_Notifi.notifi_sure_refuse_dayoff_form_in_late,
            icon: "warning",
            buttons: {
                oke: language[indexLanguage].textButton.oke,
                cancel: language[indexLanguage].textButton.close
            },
        })
            .then(async (willDelete) => {
                if (willDelete === 'oke') {
                    var dof_id = e.dof_list[0].id;
                    const data = await axios({
                        method: 'GET',
                        url: `${box_engine_cf.crrApiSocket}/api/daysoff_form/delete/${dof_id}?is_approval=1`,
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': token,
                        },
                    })
                    if (data.data.status === 10000) {
                        swal({
                            title: language[indexLanguage].Text_Notifi.success,
                            text: language[indexLanguage].Text_Notifi.notifi_sure_success_refuse_dayoff_form_in_late,
                            icon: "success",
                            buttons: false,
                            timer: 1500
                        });
                        fetchData();
                    } else {
                        swal(language[indexLanguage].Text_Notifi.error, language[indexLanguage].Text_Notifi.notifi_delete_false, "error");
                    }
                }
            }).catch((error) => {
                swal(language[indexLanguage].Text_Notifi.error, error, "error");
            })
    }

    const confirmAction = (index, e) => {
        return <>
            <button
                onClick={() => openModalConfirm(e)}
                className='m-portlet__nav-link btn m-btn m-btn--hover-warning m-btn--icon m-btn--icon-only m-btn--pill'
                data-tip
                data-for='Edit'
            >
                <i className='la la-edit' />
            </button>
            <>
                {
                    e.dof_list.length > 0 ?
                        <button
                            onClick={() => removeConfirm(e)}
                            className='m-portlet__nav-link btn m-btn m-btn--hover-danger m-btn--icon m-btn--icon-only m-btn--pill'
                            data-tip
                            data-for='Close'
                        >
                            <i className='la la-times' />
                        </button> : ""
                }
            </>
        </>
    }
    // const timeKeepingAction = (index, e) => {
    //     var checked = false;
    //     if (e.dof_list.length > 0 && e.dof_list[0].status == 3)
    //         checked = true;
    //     return <>
    //         {
    //             <Switch
    //                 onChange={() => {
    //                     if (e.dof_list.length > 0) {
    //                         removeConfirm(e);
    //                     } else
    //                         openModalConfirm(e);
    //                 }}
    //                 checked={checked}
    //                 handleDiameter={17}
    //                 offColor="#969696"
    //                 onColor="#00c5dc"
    //                 offHandleColor="#ffffff"
    //                 onHandleColor="#08f"
    //                 height={25}
    //                 width={55}
    //                 className="react-switch"
    //                 id="small-radius-switch"
    //             />
    //         }
    //     </>
    // }

    const getConfirmPerson = (data) => {
        if (data.dof_list.length > 0) {
            var dof_info = data.dof_list[0];
            if (typeof dof_info.confirm_person != "undefined") {
                return formatUsernameFromEmail(dof_info.confirm_person.email);
            }
        }
        return "";
    }

    const getReason = (data, type = "content") => {
        if (data.dof_list.length > 0) {
            var dof_info = data.dof_list[0];
            var description = JSON.parse(dof_info.description);
            return typeof description[type] != "undefined" ? description[type] : "";
        }
        return "";
    }

    const formatConfirmTime = (data) => {
        if (data.dof_list.length > 0) {
            var dof_info = data.dof_list[0];
            return moment(dof_info.update_time).add(7, 'hours').format('HH:mm:ss DD-MM-YYYY');
        }
        return "";
    }

    const viewData = (data, i) => {
        return <tr key={i}>
            <td>{i + 1}</td>
            <td>{moment(data.date).format('DD-MM-YYYY')}</td>
            {/* <td>{data.id_staff}</td> */}
            <td>{formatUsernameFromEmail(data.email)}</td>
            <td>{data.name}</td>
            <td>{data.department}</td>
            <td>{data.time_in}</td>
            {/* <td>{data.time_out}</td> */}
            <td>{typeof data.in_late != "undefined" && data.in_late != 0 ? parseInt(data.in_late) : ""}</td>
            {/* <td>{data.name_shift}</td> */}
            <td>{getConfirmPerson(data)}</td>
            <td>{formatConfirmTime(data)}</td>
            <td>{getReason(data)}</td>
            <td>{getReason(data, 'note')}</td>
            {
                dataRole.indexOf(type + '/timekeeping:view_by_manager') !== -1
                    ?
                    <td>{confirmAction(i, data)}</td>
                    :
                    ""
            }
        </tr>
    }

    useEffect(() => {
        if (box_engine_cf) {
            const dataPost = {
                ...dataSearch,
                id_company: box_engine_cf.idCompany,
            }
            fetchData(dataPost);
            setDataSearch({
                ...dataSearch,
                id_company: box_engine_cf.idCompany,
            })
        }
    }, [box_engine_cf.idCompany]);
    return (
        <div>
            <HeaderTimekeeping selectedDepartment={departmentCurrent} data={dataSearch} list_shift={data ? data.list_shift : null} list_department={data ? data.list_department : null} tab={2} getData={getDataSearch} selectDepartment={handleSelectDepartment} />
            <LoadingOverLay loading={loading} />
            <div className="row-sm row m-portlet__body" style={portletBodyCss}>
                {
                    selectDepartment && (
                        <div className='m-portlet__body dashboard_spinner col-xl-3'>
                            <div style={{ maxHeight: "600px", overflow: "auto", marginRight: "20px", whiteSpace: "nowrap" }}>
                                <TreeView
                                    item={formatTreeViewData(data ? data.list_department : [])}
                                    getList={(data) => {
                                        setDepartmentCurrent({
                                            name: data.title,
                                            value: data.value,
                                            code: data.code
                                        });
                                        getDataSearch({ id_department: data.value });
                                    }}
                                />
                            </div>
                        </div>
                    )
                }
                <div className={selectDepartment ? 'col-xl-9' : 'col-xl-12'}>
                    <div className="table-responsive show-scroll-hoz mt-3">
                        <table className="table table-bordered bg-white table-hover table-checkable dataTable no-footer dtr-inline collapsed mb-0">
                            <thead>
                                <tr>
                                    <th style={{ 'verticalAlign': 'middle', 'width': "3%" }}>STT</th>
                                    <th style={{ 'verticalAlign': 'middle', 'width': selectDepartment ? "7%" : "5%" }}>{language[indexLanguage].Timekeeping.date}</th>
                                    {/* <th style={{ 'verticalAlign': 'middle' }}>ID</th> */}
                                    <th style={{ 'verticalAlign': 'middle' }}>Username</th>
                                    <th style={{ 'verticalAlign': 'middle' }}>{language[indexLanguage].DayOff.staff}</th>
                                    <th style={{ 'verticalAlign': 'middle', 'width': selectDepartment ? "10%" : "20%" }}>{language[indexLanguage].Timekeeping.department}</th>
                                    <th style={{ 'verticalAlign': 'middle', 'width': selectDepartment ? "10%" : "7%" }}>{language[indexLanguage].Timekeeping.time_in}</th>
                                    {/* <th style={{ 'verticalAlign': 'middle', width: '120px' }}>{language[indexLanguage].Timekeeping.time_out}</th> */}
                                    <th style={{ 'verticalAlign': 'middle', 'width': selectDepartment ? "10%" : "7%" }}>{language[indexLanguage].Timekeeping.in_late}</th>
                                    {/* <th style={{ 'verticalAlign': 'middle' }}>{language[indexLanguage].Timekeeping.shift}</th> */}
                                    <th style={{ 'verticalAlign': 'middle', 'width': selectDepartment ? "10%" : "7%" }}>{language[indexLanguage].DayOff.supervisor_confirm}</th>
                                    <th style={{ 'verticalAlign': 'middle', 'width': selectDepartment ? "12%" : "10%" }}>Thời gian xác nhận</th>
                                    <th style={{ 'verticalAlign': 'middle' }}>{language[indexLanguage].DayOff.reason}</th>
                                    <th style={{ 'verticalAlign': 'middle' }}>{language[indexLanguage].DayOff.note}</th>
                                    {
                                        dataRole.indexOf(type + '/timekeeping:view_by_manager') !== -1
                                            ?
                                            <th style={{ 'verticalAlign': 'middle', 'width': '90px' }}>{language[indexLanguage].DayOff.confirm}</th>
                                            : ""
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {crrData &&
                                    crrData.map((map, i) => viewData(map, i))
                                }
                            </tbody>
                        </table>
                    </div>
                    <ApprovalForm onHide={closeModal} show={show} data={dataConfirm} onRefresh={fetchData} />
                </div>
            </div>
        </div >
    );
}

export default TimekeepingDetail;