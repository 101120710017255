import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getListDepartmentSelect, getListShiftActiveSelect, getListDepartmentSupervisorSelectAll } from '../../../../api';
import language from '../../../../language/language';
import TimekeepingDetail from './TimekeepingDetail';
import ReactGA from 'react-ga';
import Header from '../../../layouts/Header';

GoLateApproval.propTypes = {

};

function GoLateApproval() {

    const indexLanguage = useSelector(state => state.changelanguage.indexLanguage);
    const box_engine_cf = useSelector(state => state.boxai.box_engine_cf);
    const token = useSelector(state => state.isLogin.access_token);

    const id_staff = localStorage.getItem('id_user');
    const type = localStorage.getItem("type");
    const dataRole = useSelector(state => state.role.role);

    const [tab, setTab] = useState(1);
    const [crrData, setCrrData] = useState({});

    const fetchData = () => {
        const admin = dataRole.indexOf(type + '/timekeeping:view_all') !== -1;
        const get_list_shift = getListShiftActiveSelect(box_engine_cf.crrApiSocket, box_engine_cf.idCompany, token);
        const get_list_department = !admin ? getListDepartmentSupervisorSelectAll(box_engine_cf.crrApiSocket, id_staff, token) : getListDepartmentSelect(box_engine_cf.crrApiSocket, box_engine_cf.idCompany, token);
        Axios.all([get_list_shift, get_list_department]).then(Axios.spread((...res) => {
            if (res[1].length <= 2 && res[1].length > 0) {
                res[1].shift();
            }
            setCrrData({
                ...crrData,
                list_shift: res[0],
                list_department: res[1],
            })

        })).catch(errors => {
            // react on errors.
        })
    }
    useEffect(() => {
        if (box_engine_cf) {
            fetchData()
        }
    }, [box_engine_cf]);

    useEffect(() => {
        ReactGA.event({
            category: tab === 1 ? 'Bao cao tong hop' : tab === 2 ? 'Bao cao chi tiet' : 'Bao cao',
            action: tab === 1 ? 'Xem bao cao tong hop' : tab === 2 ? 'Xem bao cao chi tiet' : 'Xem tung bao cao',
        });
    }, [tab])

    return (
        <>
        <Header />
        <div className={"m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body "}>
        <div className='m-grid__item m-grid__item--fluid m-wrapper p-2' id='timekeeping'>
            <div className='m-content' id='timekeepingPersonal'>
                <div className='m-portlet m-portlet--tab mb-0'>
                    <div className='m-form m-form--fit m-form--label-align-right' style={{ paddingBottom: "10px" ,paddingTop:"10px" }}>
                        <div className='m-portlet__body pl-3 pr-3 pt-0'>
                            <div>
                                <TimekeepingDetail data={crrData} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </>
    );
}

export default GoLateApproval;
