import React from 'react';
import PropTypes from 'prop-types';
import HeaderTimekeeping from '../components/HeaderTimekeeping';
import { useEffect } from 'react';
import moment from 'moment';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getApiOverviewValue } from '../../../../../api';
import PaginationTab from '../components/PaginationTab';
import LoadingOverlay from '../components/LoadingOverlay';
import language from '../../../../../language/language';
import { workLetter, workTimekeeping } from '../components/processData';
import TreeView from '../../../TreeView';
import { formatTitleOption, formatTreeViewData } from '../../../CommonFunction';

const portletBodyCss = {
    paddingTop: 0,
    paddingBottom: 0,
};
TimekeepingTotal.propTypes = {
    data: PropTypes.object,
};
TimekeepingTotal.defaultProps = {
    data: null,
}

function TimekeepingTotal(props) {
    const { data } = props;
    const indexLanguage = useSelector(state => state.changelanguage.indexLanguage);
    const box_engine_cf = useSelector(state => state.boxai.box_engine_cf);
    const token = useSelector(state => state.isLogin.access_token);

    const [totalItem, setTotalItem] = useState(0);
    const [crrData, setCrrData] = useState(null);
    const [dayInMonth, setDayInMonth] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectDepartment, setSelectDepartment] = useState(false);
    const [departmentCurrent, setDepartmentCurrent] = useState({
        name: language[indexLanguage].placeholderSelect.all_department,
        value: -1,
    });

    const initial = {
        data_search: '',
        id_shift: -1,
        id_department: -1,
        // start_date: '2020-11-01',
        // end_date: '2020-11-30',
        start_date: moment().startOf('month').format('YYYY-MM-DD'),
        end_date: moment().endOf('month').format('YYYY-MM-DD'),
        id_company: box_engine_cf.idCompany,
        index: 1,
        is_export: 0,
        item_per_page: 10,
    }

    const [dataSearch, setDataSearch] = useState(initial);

    const getDataSearch = (data, reset) => {
        const dataPost = {
            ...Object.assign(dataSearch, data),
            index: 1,
        };
        setDataSearch(dataPost);
        fetchData(dataPost);
    }
    const getDataPagination = (data) => {
        const dataPost = Object.assign(dataSearch, data);
        fetchData(dataPost);
    }
    const fetchData = async (dataPost) => {
        setCrrData(null);
        setTotalItem(0);
        setLoading(true);
        let date = [];
        const date_length = new Date(moment(dataPost.start_date).format('YYYY'), moment(dataPost.start_date).format('MM'), 0).getDate();

        const result = await getApiOverviewValue(box_engine_cf.crrApiSocket, dataPost, token);
        setTotalItem(result.count);
        setCrrData(result.data);
        for (let i = 0; i < date_length; i++) {
            date.push(i + 1);
        }
        setDayInMonth(date);
        setLoading(false);
    }

    const handleSelectDepartment = () => {
        setSelectDepartment(prev => !prev);
    }

    const viewData = (data, i) => {
        let total_work = data.total ? data.total.total_work : 0
        const time_keepings = data.timekeepings
        const holidays = data.holiday

        return (
            <>
                <tr>
                    <td style={{ 'verticalAlign': 'middle' }}>{i + 1 + ((dataSearch.index - 1) * initial.item_per_page)}</td>
                    <td style={{ 'verticalAlign': 'middle' }}>{`${data.name} (${data.id_staff})`}</td>
                    {
                        <>
                            {
                                dayInMonth.map((value, index) => {
                                    let n_day = value;
                                    if (n_day < 10) {
                                        n_day = '0' + n_day;
                                    }
                                    const y_m_d = moment(dataSearch.start_date).format('YYYY-MM-') + n_day;
                                    const date_month = `${moment(dataSearch.start_date).format('YYYY-MM')}-${n_day}`;
                                    const day = new Date(date_month).getDay();

                                    const monthHoliday = holidays[y_m_d] || []
                                    let list_dof = time_keepings[y_m_d].dof_list
                                    let time_keeping = time_keepings[y_m_d].timekeeping_list

                                    var work = 0
                                    let dof_letters = []

                                    time_keeping.map(tk => {
                                        work += tk.work
                                    })


                                    list_dof.map(dof => {
                                        var work_coefficient = 0
                                        if (dof.type == 1) {
                                            if (dof.reason.coefficient != undefined) {
                                                work_coefficient = dof.work_off * dof.reason.coefficient
                                            }
                                        }
                                        var sb = ''
                                        switch (dof.type) {
                                            case 1:
                                                sb += `${dof.reason.symbol}`;
                                                break;
                                            case 2:
                                                sb += 'OT';
                                                break;
                                            case 3:
                                                sb += 'CT';
                                                break;
                                            case 4:
                                                sb += 'MS';
                                                break;
                                            case 5:
                                                sb += 'CCB';
                                                break;
                                            default:
                                                break;
                                        }
                                        if (sb != '')
                                            dof_letters.push(sb)
                                    })

                                    return (
                                        <>
                                            {
                                                <td className='timekeeping_flag text-center' style={{ 'backgroundColor': monthHoliday.length > 0 ? '#d4f1b5' : (day === 6 || day === 0) ? '#f0f0f0 ' : 'transparent' }}>
                                                    {work > 0 ? parseFloat(work).toFixed(2) :
                                                        <span className="m-badge m-badge--metal m-badge--dot m-badge--dot-small"></span>
                                                    }
                                                    <span className='timekeeping_flag_child'>{dof_letters.join(",")}</span>
                                                </td>
                                            }
                                        </>
                                    )
                                })
                            }
                        </>
                    }
                    <td style={{ 'verticalAlign': 'middle' }}>{total_work.toFixed(2)}</td>
                </tr>
            </>
        )

    }

    useEffect(() => {
        if (box_engine_cf) {
            const dataPost = {
                ...dataSearch,
                id_company: box_engine_cf.idCompany,
            }
            fetchData(dataPost);
            setDataSearch({
                ...dataSearch,
                id_company: box_engine_cf.idCompany,
            })
        }
    }, [box_engine_cf.idCompany]);

    return (
        <div>
            <HeaderTimekeeping selectedDepartment={departmentCurrent} data={dataSearch} list_shift={data ? data.list_shift : null} list_department={data ? data.list_department : null} tab={1} getData={getDataSearch} selectDepartment={handleSelectDepartment} />
            <LoadingOverlay loading={loading} />
            <div className="row-sm row m-portlet__body" style={portletBodyCss}>
                {
                    selectDepartment && (
                        <div className='m-portlet__body dashboard_spinner col-xl-4'>
                            <div style={{ maxHeight: "500px", overflow: "auto", marginRight: "20px", whiteSpace: "nowrap" }}>
                                <TreeView
                                    item={formatTreeViewData(data ? data.list_department : [])}
                                    getList={(data) => {
                                        setDepartmentCurrent({
                                            name: data.title,
                                            value: data.value,
                                            code: data.code
                                        });
                                        getDataSearch({ id_department: data.value });
                                    }}
                                />
                            </div>
                        </div>
                    )
                }
                <div className={selectDepartment ? 'col-xl-8' : 'col-xl-12'}>
                    <div className="table-responsive text-nowrap mt-3">
                        <table className="table table-bordered bg-white table-hover table-checkable dataTable no-footer dtr-inline collapsed mb-0">
                            <thead>
                                <tr>
                                    <th style={{ 'verticalAlign': 'middle', 'width': '50px' }}>{language[indexLanguage].Other.no}</th>
                                    <th style={{ 'verticalAlign': 'middle' }}>{language[indexLanguage].Search.text_placeholder}</th>
                                    {/* <th style={{ 'verticalAlign': 'middle' }}>{language[indexLanguage].Menubar.shifts}</th> */}
                                    {
                                        dayInMonth.map((value, index) => {
                                            return <th style={{ 'verticalAlign': 'middle', textAlign: 'center' }} key={index}>{value}</th>
                                        })
                                    }
                                    <th style={{ 'verticalAlign': 'middle', 'width': '50px' }}>{language[indexLanguage].Report.total_work}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {crrData && !loading &&
                                    crrData.map((map, i) => viewData(map, i))
                                }
                            </tbody>
                        </table>
                    </div>
                    <PaginationTab total={totalItem} page={dataSearch.index} item_per_page={(initial.item_per_page).toString()} getData={getDataPagination} />
                </div>
            </div>
        </div>
    );
}

export default TimekeepingTotal;