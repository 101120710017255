import { css } from '@emotion/core';
import $ from 'jquery';
import Moment from 'moment';
import React, { Component } from 'react';
import Pagination from 'react-js-pagination';
import { Redirect } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import ReactTooltip from 'react-tooltip';
import swal from 'sweetalert';
import language from '../../../../language/language';
import Store from '../../../../store';
import ModalShift from './ModalShift';
import ReactGA from 'react-ga';

import { connect } from 'react-redux'
import Header from '../../../layouts/Header';
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const nameTab = 'shift_work';
class Shift extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: Store.getState().isLogin.isLogin,
      access_token: Store.getState().isLogin.access_token,
      crrApi2: Store.getState().boxai.box_engine_cf.crrApiSocket,
      language: Store.getState().changelanguage.language,
      box_engine_cf: Store.getState().boxai.box_engine_cf,
      dataRole: Store.getState().role.role,
      type: '',
      modalShift: false,
      loading: true,
      listShift: [],
      listStaff: [],
      crrDatas: [],
      activePage: 1,
      offset: 0,
      totalLength: 0,
      crrShift: {
        id: '',
        name: '',
        time_in: '',
        time_out: '',
        description: '',
      },
      valueSearch: '',
    };
    Store.subscribe(() => {
      this.setState(
        {
          isLogin: Store.getState().isLogin.isLogin,
          access_token: Store.getState().isLogin.access_token,
          crrApi2: Store.getState().boxai.box_engine_cf.crrApiSocket,
          language: Store.getState().changelanguage.language,
          box_engine_cf: Store.getState().boxai.box_engine_cf,
          dataRole: Store.getState().role.role,
          listShift: [],
          listStaff: [],
          crrDatas: [],
          activePage: 1,
          offset: 0,
          totalLength: 0,
          valueSearch: '',
        },
        () => {
          $('#inputSearch').val('');
          this.getShift();
        }
      );
    });
    this.itemsPerPage = 10;
    this.getShift();
  }

  componentWillMount() {
    var type = localStorage.getItem('type');
    this.setState({
      type: type,
    });
  }

  getShift = () => {
    if (this.state.crrApi2 === undefined) return;
    fetch(
      `${this.state.crrApi2}/api/shift_work/${this.state.box_engine_cf.idCompany}/get_by_active/1`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: Store.getState().isLogin.access_token,
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.status === 10000) {
          this.setState({
            loading: false,
            listShift: [...data.data],
          });
          this.FilterShift(this.state.activePage);
        } else {
          this.setState({
            loading: false,
          });
        }
      });
  };

  FilterShift = (activePage) => {
    var dataSearch = [];
    this.state.listShift.forEach((item) => {
      if (
        item.name
          .toLowerCase()
          .indexOf(this.state.valueSearch.toLowerCase()) !== -1
      ) {
        dataSearch.push(item);
      }
    });

    const offset = (activePage - 1) * this.itemsPerPage;
    const crrDatas = dataSearch.slice(offset, offset + this.itemsPerPage);

    this.setState({
      crrDatas,
      offset: offset,
      totalLength: dataSearch.length,
    });
  };

  handlePageChange = (pageNumber) => {
    this.setState({
      ...this.state,
      activePage: pageNumber,
    });
    this.FilterShift(pageNumber);
  };

  DeleteShift = async (Shift) => {
    if (this.state.crrApi2 == undefined) return;
    Shift.is_active = 0;
    let data = await fetch(`${this.state.crrApi2}/api/shift_work/update`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: Store.getState().isLogin.access_token,
      },
      body: JSON.stringify(Shift),
    }).then((response) => {
      return response.json();
    });
    if (data.status === 10000) {
      ReactGA.event({
        category: 'Ca',
        action: 'Xoa ca',
      });
      swal(
        language[this.props.indexLanguage].Text_Notifi.success,
        language[this.props.indexLanguage].Text_Notifi.notifi_delete_success,
        'success',
        {
          buttons: false,
          timer: 1500,
        }
      );
      this.getShift();
    } else {
      swal(
        language[this.props.indexLanguage].Text_Notifi.error,
        language[this.props.indexLanguage].Text_Notifi.notifi_delete_false,
        'error'
      );
    }
  };

  modalClose = () => {
    this.setState({
      modalShift: false,
    });
    this.getShift();
  };

  btnSearch = () => {
    this.setState(
      {
        activePage: 1,
      },
      () => {
        this.FilterShift(this.state.activePage);
      }
    );
  };

  isChange = (event) => {
    if (event.key === 'Enter') {
      this.btnSearch(event.target.value.trim());
    }
    this.setState({
      valueSearch: event.target.value.trim(),
    });
  };

  render() {
    if (this.state.isLogin === false) {
      return <Redirect to={'/login'} />;
    }

    let bulletedShift = this.state.crrDatas.map((e, i) => {
      var checked = true;
      if (e.is_active === 1) {
        checked = true;
      }
      if (e.is_active === 0) {
        checked = false;
      }
      var type_shift = '';
      if (e.type_shift == 0) {
        type_shift = language[this.props.indexLanguage].Shift.day_time;
      } else {
        type_shift = language[this.props.indexLanguage].Shift.night_time;
      }

      //start_time_off
      var time_off = '';
      if (e.start_time_off != null && e.start_time_off != '') {
        time_off =
          Moment(e.start_time_off, 'H:mm').format('HH:mm') +
          '-' +
          Moment(e.end_time_off, 'H:mm').format('HH:mm');
      }

      //expire_timein
      var expire_timein_tb = '';
      if (e.expire_timein) {
        if (e.expire_timein_to) {
          expire_timein_tb =
            Moment(e.expire_timein, 'H:mm').format('HH:mm') +
            ' - ' +
            Moment(e.expire_timein_to, 'H:mm').format('HH:mm');
        } else {
          expire_timein_tb =
            Moment(e.expire_timein, 'H:mm').format('HH:mm') + ' - ' + ' --:--';
        }
      }

      //expire_timeout
      var expire_timeout_tb = '';
      if (e.expire_timeout_to) {
        if (e.expire_timeout) {
          expire_timeout_tb =
            Moment(e.expire_timeout, 'H:mm').format('HH:mm') +
            ' - ' +
            Moment(e.expire_timeout_to, 'H:mm').format('HH:mm');
        } else {
          expire_timeout_tb =
            '--:-- ' + ' - ' + Moment(e.expire_timeout_to, 'H:mm').format('HH:mm');
        }
      }

      return (
        <tr key={i + 1}>
          <td>{i + this.state.offset + 1}</td>
          <td>{e.name}</td>
          <td>{Moment(e.time_in, 'H:mm').format('HH:mm')}</td>
          <td>{Moment(e.time_out, 'H:mm').format('HH:mm')}</td>
          <td>{time_off}</td>
          <td>{expire_timein_tb}</td>
          <td style={{ whiteSpace: 'pre' }}>{expire_timeout_tb}</td>
          <td>{e.work}</td>
          <td style={{ whiteSpace: 'normal' }}>{e.description}</td>
          {this.state.dataRole.indexOf(
            this.state.type + '/' + nameTab + ':insert_or_update'
          ) !== -1 ||
            this.state.dataRole.indexOf(
              this.state.type + '/' + nameTab + ':delete'
            ) !== -1 ? (
              <td>
                {this.state.dataRole.indexOf(
                  this.state.type + '/' + nameTab + ':insert_or_update'
                ) !== -1 ? (
                    <>
                      <button
                        onClick={(e) => {
                          var data = this.state.crrDatas;
                          //start_time_off
                          var start_time_off = '';
                          if (data[i].start_time_off != '') {
                            start_time_off = Moment(
                              data[i].start_time_off,
                              'H:mm'
                            ).format('HH:mm:ss');
                          }

                          //start_time_off
                          var end_time_off = '';
                          if (data[i].end_time_off != '') {
                            end_time_off = Moment(
                              data[i].end_time_off,
                              'H:mm'
                            ).format('HH:mm:ss');
                          }

                          //expire_timein
                          var expire_timein = '';
                          if (
                            data[i].expire_timein != null &&
                            data[i].expire_timein != ''
                          ) {
                            expire_timein = Moment(
                              data[i].expire_timein,
                              'H:mm'
                            ).format('HH:mm:ss');
                          }
                          var expire_timein_to = '';
                          if (
                            data[i].expire_timein_to != null &&
                            data[i].expire_timein_to != ''
                          ) {
                            expire_timein_to = Moment(
                              data[i].expire_timein_to,
                              'H:mm'
                            ).format('HH:mm:ss');
                          }

                          //expire_timeout
                          var expire_timeout = '';
                          if (
                            data[i].expire_timeout != null &&
                            data[i].expire_timeout != ''
                          ) {
                            expire_timeout = Moment(
                              data[i].expire_timeout,
                              'H:mm'
                            ).format('HH:mm:ss');
                          }
                          var expire_timeout_to = '';
                          if (
                            data[i].expire_timeout_to != null &&
                            data[i].expire_timeout_to != ''
                          ) {
                            expire_timeout_to = Moment(
                              data[i].expire_timeout_to,
                              'H:mm'
                            ).format('HH:mm:ss');
                          }

                          var dataShift = {
                            id: data[i].id,
                            name: data[i].name,
                            time_in:
                              data[i].time_in != ''
                                ? Moment(data[i].time_in, 'H:mm').format('HH:mm:ss')
                                : '',
                            time_out:
                              data[i].time_out != ''
                                ? Moment(data[i].time_out, 'H:mm').format(
                                  'HH:mm:ss'
                                )
                                : '',
                            day_of_week: data[i].day_of_week,
                            start_time_off: start_time_off,
                            end_time_off: end_time_off,
                            expire_timein: expire_timein,
                            expire_timein_to: expire_timein_to,
                            expire_timeout: expire_timeout,
                            expire_timeout_to: expire_timeout_to,
                            total_time: data[i].total_time,
                            type_shift: data[i].type_shift,
                            work: data[i].work,
                            is_active: data[i].is_active,
                            description: data[i].description,
                            id_company: this.state.box_engine_cf.idCompany,
                            modal: 'edit',
                            HOST: this.state.crrApi2,
                          };
                          this.setState({
                            crrShift: dataShift,
                            modalShift: true,
                          });
                        }}
                        className='m-portlet__nav-link btn m-btn m-btn--hover-warning m-btn--icon m-btn--icon-only m-btn--pill'
                        data-tip
                        data-for='Edit'
                      >
                        <i className='la la-edit' />
                      </button>
                      <ReactTooltip id='Edit' type='dark' effect='solid'>
                        <span>
                          {language[this.props.indexLanguage].tooltip.open_edit}
                        </span>
                      </ReactTooltip>
                    </>
                  ) : (
                    ''
                  )}
                {this.state.dataRole.indexOf(
                  this.state.type + '/' + nameTab + ':delete'
                ) !== -1 ? (
                    <>
                      <button
                        className='m-portlet__nav-link btn m-btn m-btn--hover-danger m-btn--icon m-btn--icon-only m-btn--pill'
                        data-tip
                        data-for='Delete'
                        onClick={(v) => {
                          v.preventDefault();
                          swal({
                            title:
                              language[this.props.indexLanguage].Text_Notifi
                                .are_you_sure,
                            text:
                              language[this.props.indexLanguage].Text_Notifi
                                .notifi_sure_delete_shift + e.name,
                            icon: 'warning',
                            buttons: true,
                            dangerMode: true,
                          }).then((willDelete) => {
                            if (willDelete) {
                              this.DeleteShift(e);
                            }
                          });
                        }}
                      >
                        <i className='la la-trash'></i>
                      </button>
                      <ReactTooltip id='Delete' type='dark' effect='solid'>
                        <span>
                          {language[this.props.indexLanguage].tooltip.open_delete}
                        </span>
                      </ReactTooltip>
                    </>
                  ) : (
                    ''
                  )}
              </td>
            ) : (
              ''
            )}
        </tr>
      );
    });

    var pageRangeDisplayed = 1;
    if ($(window).width() < 768) {
      pageRangeDisplayed = 3;
    } else {
      pageRangeDisplayed = 5;
    }

    return (
      <>
      <Header />
      <div className={"m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body "}>
      <div className='m-grid__item m-grid__item--fluid m-wrapper'>
        <div className='m-content p-2'>
          <div className='m-portlet m-portlet--tab mb-0'>
            <div className='m-portlet__head p-3'>
              <div className='m-portlet__head-caption pl-3'>
                <div className='m-portlet__head-title'>
                  <h3 className='m-portlet__head-text'>
                    {language[this.props.indexLanguage].Menubar.shifts}
                  </h3>
                </div>
              </div>
              <div className='m-portlet__head-tools pr-3'>
                <ul className='m-portlet__nav'>
                  <li className='m-portlet__nav-item'>
                    {this.state.dataRole.indexOf(
                      this.state.type + '/' + nameTab + ':insert_or_update'
                    ) !== -1 ? (
                        <button
                          onClick={(e) => {
                            this.setState({
                              crrShift: {
                                day_of_week: '|1|1|1|1|1|1|1|',
                                name: '',
                                time_in: '',
                                time_out: '',
                                start_time_off: '',
                                end_time_off: '',
                                expire_timein: '',
                                expire_timein_to: '',
                                expire_timeout: '',
                                expire_timeout_to: '',
                                description: '',
                                total_time: 0,
                                type_shift: 0,
                                work: 1,
                                is_active: 1,
                                id_company: this.state.box_engine_cf.idCompany,
                                modal: 'add',
                                HOST: this.state.crrApi2,
                              },
                              modalShift: true,
                            });
                          }}
                          className='btn btn-accent m-btn m-btn--custom m-btn--icon m-btn--pill m-btn--air'
                        >
                          <span>
                            <i className='la la-plus' />
                            <span>
                              {language[this.props.indexLanguage].textButton.add}
                            </span>
                          </span>
                        </button>
                      ) : (
                        ''
                      )}
                  </li>
                </ul>
              </div>
            </div>
            <div className='m-portlet__body m-portlet__body--no-padding'>
              <div className='row m-row--no-padding m-row--col-separator-xl'>
                <div className='col-xl-12'>
                  <div className='m-widget1 col-xl-12 mx-auto'>
                    <div className='row col-md-12 ml-0 mr-0 p-0 mb-2'>
                      <div className='col-lg-2 pl-0 m--margin-bottom-10-tablet-and-mobile'>
                        <input
                          type='text'
                          onKeyUp={(event) => this.isChange(event)}
                          id='inputSearch'
                          className='form-control m-input'
                          placeholder={
                            language[this.props.indexLanguage].placeholderInput
                              .enter_code_shift
                          }
                          data-col-index={0}
                        />
                      </div>
                      <div>
                        <button
                          onClick={() => {
                            this.btnSearch(this.state.valueSearch);
                          }}
                          className='btn btn-accent m-btn m-btn--icon'
                          id='m_search'
                        >
                          <span>
                            <i className='la la-search' />
                            <span>
                              {
                                language[this.props.indexLanguage].textButton
                                  .search
                              }
                            </span>
                          </span>
                        </button>
                      </div>
                    </div>
                    <div className='table-responsive text-nowrap'>
                      <table className='table table-bordered table-hover table-checkable dataTable no-footer dtr-inline collapsed'>
                        <thead>
                          <tr>
                            <th
                              style={{
                                verticalAlign: 'middle',
                                width: '100px',
                              }}
                            >
                              {language[this.props.indexLanguage].Other.no}
                            </th>
                            <th style={{ verticalAlign: 'middle' }}>
                              {
                                language[this.props.indexLanguage].Shift
                                  .id_shift
                              }
                            </th>
                            <th style={{ verticalAlign: 'middle' }}>
                              {
                                language[this.props.indexLanguage].textTable
                                  .time_in
                              }
                            </th>
                            <th style={{ verticalAlign: 'middle' }}>
                              {
                                language[this.props.indexLanguage].textTable
                                  .time_out
                              }
                            </th>
                            <th style={{ verticalAlign: 'middle' }}>
                              {
                                language[this.props.indexLanguage].textTable
                                  .time_off
                              }
                            </th>
                            <th style={{ verticalAlign: 'middle' }}>
                              {
                                language[this.props.indexLanguage].Shift
                                  .limit_checkin
                              }
                            </th>
                            <th style={{ verticalAlign: 'middle' }}>
                              {
                                language[this.props.indexLanguage].Shift
                                  .limit_checkout
                              }
                            </th>
                            <th style={{ verticalAlign: 'middle' }}>
                              {
                                language[this.props.indexLanguage].Shift
                                  .number_work
                              }
                            </th>

                            <th
                              style={{
                                verticalAlign: 'middle',
                                width: '350px',
                              }}
                            >
                              {
                                language[this.props.indexLanguage].textTable
                                  .description
                              }
                            </th>
                            {/* <th style={{ 'verticalAlign': 'middle', }}>{language[this.props.indexLanguage].textTable.status}</th> */}
                            {this.state.dataRole.indexOf(
                              this.state.type +
                              '/' +
                              nameTab +
                              ':insert_or_update'
                            ) !== -1 ||
                              this.state.dataRole.indexOf(
                                this.state.type + '/' + nameTab + ':delete'
                              ) !== -1 ? (
                                <th
                                  style={{
                                    verticalAlign: 'middle',
                                    width: '150px',
                                  }}
                                >
                                  {
                                    language[this.props.indexLanguage].textTable
                                      .action
                                  }
                                </th>
                              ) : (
                                ''
                              )}
                          </tr>
                        </thead>
                        <tbody>{bulletedShift}</tbody>
                      </table>
                      <PulseLoader
                        css={override}
                        sizeUnit={'px'}
                        size={12}
                        margin={'2px'}
                        color={'#36D7B7'}
                        loading={this.state.loading}
                      />

                      <ModalShift
                        data={this.state.crrShift}
                        show={this.state.modalShift}
                        onHide={this.modalClose}
                      />
                    </div>
                    {/* <span>
                                            Showing {this.state.showFirst} to {this.state.showLast} of {this.state.totalLength} entries
                                        </span> */}
                    <Pagination
                      firstPageText={
                        language[this.props.indexLanguage].pagination.first
                      }
                      nextPageText={
                        language[this.props.indexLanguage].pagination.next
                      }
                      lastPageText={
                        language[this.props.indexLanguage].pagination.last
                      }
                      prevPageText={
                        language[this.props.indexLanguage].pagination.previous
                      }
                      activePage={this.state.activePage}
                      itemsCountPerPage={this.itemsPerPage}
                      totalItemsCount={this.state.totalLength}
                      pageRangeDisplayed={pageRangeDisplayed}
                      onChange={this.handlePageChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
      indexLanguage: state.changelanguage.indexLanguage,
  }
}
export default connect(mapStateToProps, null)(Shift);
