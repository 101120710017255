import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { HOST_CLOUD, COMPANY_CODE } from '../config'
import Store from '../store';
import { login } from '../actions/isLogin';
import language from '../language/language';
import { changelanguage } from '../actions/changelanguage';
import swal from 'sweetalert';
import $ from 'jquery';
import { connect } from 'react-redux'
class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: 0,
            value: 'vn',
            datalogin: {
                code: null,
                id_staff: null,
                email: null,
                password: null,
                type_login: 0,
            }
        }
        localStorage.removeItem("access_token_cloud");
        localStorage.removeItem("type");
        localStorage.removeItem("type_login");
        localStorage.removeItem("valueCompany");
    }

    componentWillMount() {
        window.bootstrap_select();
        let language = 'vn';
        if(this.props.indexLanguage === 0) {
            language = 'en'
        } else if (this.props.indexLanguage === 1) {
            language = 'vn'
        } else {
            language = 'jp'
        }
        this.setState({
            value: language
        })
    }

    CheckLogin = async (event) => {
        console.log("COMPANY_CODE_ENV: ",COMPANY_CODE)
        var form = $("#formLoginCheck")
        if (form[0].checkValidity() === false) {
            event.preventDefault()
            event.stopPropagation()
            form.addClass('was-validated')
        } else {
            event.preventDefault()
            event.stopPropagation()
            $('#btnLogin').addClass('m-loader');
            $('#btnLogin').attr('disabled', true);
            var {datalogin} = this.state;
            {COMPANY_CODE ? datalogin.code = COMPANY_CODE : datalogin.code = datalogin.code.toLowerCase()}
            fetch(`${HOST_CLOUD}/api/login_biface`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(datalogin)
            }).then((response) => {
                if (response.status === 500) {
                    $('#btnLogin').removeClass('m-loader');
                    $('#btnLogin').removeAttr('disabled', true);
                    swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].PageLogin.error502, "info");
                    return;
                }
                return response.json()
            }).then((data) => {
                if (data.status === 10000) {
                    this.setState({ error: 0 })
                    localStorage.setItem("type_login", 0);
                    localStorage.setItem("access_token_cloud", "Bearer " + data.token_cloud);
                    localStorage.setItem("refresh_token", "Bearer " + data.refresh_token);
                    localStorage.setItem("type", "biface");
                    Store.dispatch(login("Bearer " + data.token_cloud));
                    this.getUser("Bearer " + data.token_cloud);
                    
                } else if(data.status === 10003){
                    $('#btnLogin').removeClass('m-loader');
                    $('#btnLogin').removeAttr('disabled', true);
                    swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].PageLogin.account_not_exist, "warning");
                } else if (data.status === 10005){
                    $('#btnLogin').removeClass('m-loader');
                    $('#btnLogin').removeAttr('disabled', true);
                    swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].PageLogin.account_is_block_30minutes, "warning");
                } else {
                    this.setState({ error: 1 })
                    $('#btnLogin').removeClass('m-loader');
                    $('#btnLogin').removeAttr('disabled', true);
                }
            }).catch((error) => {
                if (error) {
                    swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].PageLogin.error, "error");
                    $('#btnLogin').removeClass('m-loader');
                    $('#btnLogin').removeAttr('disabled', true);
                }
            })
        }
    }
    getUser = (token_cloud) => {
        fetch(`${HOST_CLOUD}/api/user/biface_getbytoken`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token_cloud
            }
        }).then((response) => response.json())
        .then((result) => {
            if(result.status === 10000){
                if (result.data.roles.indexOf('biface' + '/dashboard:view') === -1 && result.data.roles.indexOf('biface' + '/timekeeping:view_personal') !== -1) {
                    window.location.href = '/timekeeping_personal';
                }else{
                    window.location.href = "/dashboard";
                }
            }
        })
    }

    onEnterPress = (e) => {

        if (e.keyCode === 13 && e.shiftKey === false) {
            if (e.target.name === 'email') {
                this.refs.password.focus();
            }
            else {
                e.preventDefault();
                this.CheckLogin(e);
            }
        }
    }

    HandleLogin = (e) => {
        var datalogin = this.state.datalogin;
        datalogin[e.target.name] = e.target.value;
        this.setState({ datalogin: datalogin });
    }

    handleChange = (event) => {
        localStorage.setItem("language", event.target.value);
        var indexlanguage
        if (event.target.value === 'us') {
            indexlanguage = 0
        } else if (event.target.value === 'vn') {
            indexlanguage = 1
        } else {
            indexlanguage = 2
        }
        this.setState({
            value: event.target.value,
        }, () => {
                Store.dispatch(changelanguage(this.state.value, indexlanguage));
        })
    }

    render() {
        return (
            <section className="login-block" style={{ 'width': '100%', 'height': '100%' }}>
                <div className="container col-md-6 col-lg-5 col-xl-3">
                    <Row>
                        <Col md={4} className="login-sec col-md-12">

                            <form id="formLoginCheck">
                                <h2 className="text-center">{language[this.props.indexLanguage].PageLogin.login_now}</h2>
                                <div className="login-form" id="formLogin">
                                    <label id="label_check" className={"form-check-label pb-2" + (this.state.error === 1 ? "" : " d-none")}>
                                        <div className="text-danger">* {language[this.props.indexLanguage].PageLogin.wrong_company_staff_or_password}</div>
                                    </label>
                                    {COMPANY_CODE ? null :
                                        <>
                                            <div className="form-group">
                                                <label htmlFor="idcompany" className="text-uppercase">{language[this.props.indexLanguage].PageLogin.code_comnpany}</label>
                                                <input type="text" name="code" id="idcompany" className="form-control" value={this.state.datalogin.code === null ? '' : this.state.datalogin.code} placeholder={language[this.props.indexLanguage].PageLogin.enter_code_comnpany} required onKeyDown={this.onEnterPress} onChange={(e) => {
                                                    this.HandleLogin(e)
                                                }} />
                                            </div>
                                        </>
                                    }
                                    <div className="form-group">
                                        <label htmlFor="exampleInputidstaff1" className="text-uppercase">{language[this.props.indexLanguage].PageLogin.id_staff}</label>
                                        <input type="text" name="id_staff" id="exampleInputidstaff1" className="form-control" value={this.state.datalogin.id_staff === null ? '' : this.state.datalogin.id_staff} placeholder={language[this.props.indexLanguage].PageLogin.enter_id_staff} required onKeyDown={this.onEnterPress} onChange={(e) => {
                                            this.HandleLogin(e)
                                        }} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputPassword1" className="text-uppercase">{language[this.props.indexLanguage].PageLogin.password}</label>
                                        <input type="password" ref='password' name="password" value={this.state.datalogin.password === null ? '' : this.state.datalogin.password} className="form-control" placeholder={language[this.props.indexLanguage].PageLogin.placeholder_password} required onKeyDown={this.onEnterPress} onChange={(e) => {
                                            this.HandleLogin(e)
                                        }} />
                                    </div>
                                    <div className="form-check pl-0 text-center pb-5 pt-4">
                                        <button className="btn btn-login m-loader--light m-loader--right" id="btnLogin" style={{ 'width': '150px' }} onClick={(event) => {
                                            this.CheckLogin(event);
                                        }}>{language[this.props.indexLanguage].PageLogin.login}</button>
                                    </div>
                                </div>
                                <div className="col-md-12 p-0">
                                    <div className="form-group m-form__group">
                                        <div style={{ 'width': '150px' }} className="m-auto">
                                            <select className="form-control m-bootstrap-select m_selectpicker" value={this.state.value} onChange={this.handleChange}>
                                                <option value="us" data-content="<img src='/img/flag_us.svg' height='18px' width='20px' /><span class='pl-2'>English</span>">English</option>
                                                <option value="vn" data-content="<img src='/img/flag_vn.svg' height='18px' width='20px' /><span class='pl-2'>Tiếng Việt</span>">Tiếng Việt</option>
                                                <option value="jp" data-content="<img src='/img/Flag_of_Japan.png' height='18px' width='20px' style='object-fit: cover' /><span class='pl-2'>日本語</span>">日本語</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </Col>
                    </Row>
                </div>
            </section >
        )
    }
}
const mapStateToProps = state => {
    return {
        indexLanguage: state.changelanguage.indexLanguage,
    }
}
export default connect(mapStateToProps, null)(Login);
