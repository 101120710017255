import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import Switch from "react-switch";
import { HOST_CLOUD, HOST } from '../../../../config';
import swal from 'sweetalert';
import Store from '../../../../store';
import $ from 'jquery';
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import language from '../../../../language/language';
import ReactGA from 'react-ga';

import { connect } from 'react-redux'
const animatedComponents = makeAnimated();
function sortData(a, b) {
    if (a.label > b.label) {
        return 1
    } else {
        if (a.label < b.label) {
            return -1;
        }
        else {
            return 0
        }
    }
}


class ModalGroup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            language: Store.getState().changelanguage.language,
            indexlanguage: 0,
            listStaff: [],
            listStaffDefault: [],
            crrGroup: {
                name: "",
                description: "",
                id_staffs: [],
                id_company: ""
            },
        }
        Store.subscribe(() => {
            this.setState({
                language: Store.getState().changelanguage.language,
            });
        });
    }

    updateGroup = async (dataGroup, event) => {
        dataGroup.name.trim()
        var form = $("#formAddGroup")
        if (form[0].checkValidity() === false) {
            event.preventDefault()
            event.stopPropagation()
            form.addClass('was-validated')
        } else {
            //Insert
            $('#button_addGroup').addClass('m-loader');
            $('#button_addGroup').attr('disabled', true);
            if (dataGroup.id == undefined) {
                let data = await fetch(`${dataGroup.HOST}/api/group/insert`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': Store.getState().isLogin.access_token
                    },
                    body: JSON.stringify(dataGroup)
                }).then((response) => {
                    return response.json()
                });

                if (data.status === 10000) {
                    ReactGA.event({
                        category: 'Nhom',
                        action: 'Them nhom',
                      });
                    swal(language[this.props.indexLanguage].Text_Notifi.success, language[this.props.indexLanguage].Text_Notifi.add_success, "success", {
                        buttons: false,
                        timer: 1500,
                    });
                    return this.props.onHide();
                } else if(data.status === 10001){
                    $('#button_addGroup').removeClass('m-loader');
                    $('#button_addGroup').removeAttr('disabled', true);
                    swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].Text_Notifi.notifi_name_same_group, "warning");
                } else {
                    $('#button_addGroup').removeClass('m-loader');
                    $('#button_addGroup').removeAttr('disabled', true);
                    swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].Text_Notifi.add_false, "error");
                }
            } else {
                //Update
                $('#button_addGroup').addClass('m-loader');
                $('#button_addGroup').attr('disabled', true);
                let data = await fetch(`${dataGroup.HOST}/api/group/update`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': Store.getState().isLogin.access_token
                    },
                    body: JSON.stringify(dataGroup)
                }).then((response) => {
                    return response.json()
                });
                if (data.status === 10000) {
                    ReactGA.event({
                        category: 'Nhom',
                        action: 'Sua nhom',
                      });
                    swal(language[this.props.indexLanguage].Text_Notifi.success, language[this.props.indexLanguage].Text_Notifi.edit_success, "success", {
                        buttons: false,
                        timer: 1500,
                    });
                    return this.props.onHide();
                } else if(data.status === 10001){
                    $('#button_addGroup').removeClass('m-loader');
                    $('#button_addGroup').removeAttr('disabled', true);
                    swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].Text_Notifi.notifi_name_same_group, "warning");
                } else {
                    $('#button_addGroup').removeClass('m-loader');
                    $('#button_addGroup').removeAttr('disabled', true);
                    swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].Text_Notifi.edit_false, "error");
                }
            }

        }
    }


    GroupHandle(e) {
        var dataGroup = this.state.crrGroup;
        dataGroup[e.target.name] = e.target.value;
        this.setState({ crrGroup: dataGroup });
    }

    handleChangeStaffs(valueStaff) {
        var group = this.state.crrGroup;
        var dataStaff = [];
        if (valueStaff == null) {
            dataStaff = []
        } else {
            for (let index = 0; index < valueStaff.length; index++) {
                dataStaff.push(valueStaff[index].value)
            }
        }
        group['id_staffs'] = dataStaff;
        this.setState({
            crrGroup: group
        })
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.show === true) {
            //Staff
            var optionStaff = [];
            for (let index = 0; index < nextProps.liststaff.length; index++) {
                optionStaff.push({ 'value': nextProps.liststaff[index].id, 'label': nextProps.liststaff[index].name + ' ('+ nextProps.liststaff[index].id_staff + ')' })
            }

            var dataOptionSort = optionStaff.sort(sortData);

            var dataStaffdefault = [];
            var dataStaff = [];
            for (let index = 0; index < nextProps.data.staff_group.length; index++) {
                dataStaffdefault.push({ 'value': nextProps.data.staff_group[index].id_staff, 'label': nextProps.data.staff_group[index].staff.name + ' ('+ nextProps.data.staff_group[index].staff.id_staff + ')' })
                dataStaff.push(nextProps.data.staff_group[index].id_staff)
            }

            nextProps.data['id_staffs'] = dataStaff;

            this.setState({
                listStaff: dataOptionSort,
                listStaffDefault: dataStaffdefault,
                crrGroup: nextProps.data,
            });
        }
    }

    handleEnter = (event) => {
        if (event.keyCode === 13) {
            const form = event.target.form;
            const index = Array.prototype.indexOf.call(form, event.target);
            form.elements[index + 1].focus();
            event.preventDefault();
        }
    }

    render() {
        let click_handle = (event) => {
            this.updateGroup(this.state.crrGroup, event);
        }
        return (
            <Modal
                {...this.props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form id="formAddGroup">
                        <div className="col-xl-12">
                            <div className="m-widget14">
                                <div className="form-group m-form__group">
                                    <label htmlFor="Name">{language[this.props.indexLanguage].textTable.name} <span className="text-danger"> *</span></label>
                                    <input className="form-control m-input" id="Name" name='name' value={this.state.crrGroup.name === '' ? '' : this.state.crrGroup.name} onKeyDown={(event) => this.handleEnter(event)} onChange={e => this.GroupHandle(e)} placeholder={language[this.props.indexLanguage].textTable.name} required />
                                </div>
                                <div className="form-group m-form__group">
                                    <label htmlFor="Description">{language[this.props.indexLanguage].textTable.description}</label>
                                    <textarea rows="4" className="form-control m-input" id="Description" value={this.state.crrGroup.description === '' ? '' : this.state.crrGroup.description} name='description' onChange={e => this.GroupHandle(e)} placeholder={language[this.props.indexLanguage].textTable.description} />
                                </div>
                                <div className="form-group m-form__group col-md-12 p-0">
                                    <label>{language[this.props.indexLanguage].textTable.staffs}</label>
                                    <Select
                                        onChange={valueStaff => this.handleChangeStaffs(valueStaff)}
                                        options={this.state.listStaff}
                                        defaultValue={this.state.listStaffDefault}
                                        getOptionValue ={(option)=>option.label}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        placeholder={language[this.props.indexLanguage].placeholderSelect.select_staff}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="accent" id="button_addGroup" className="m-loader--light m-loader--right" onClick={click_handle}>{language[this.props.indexLanguage].textButton.save}</Button>
                    <Button variant="secondary" onClick={this.props.onHide}>{language[this.props.indexLanguage].textButton.close}</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

ModalGroup.propTypes = {
    data: PropTypes.object,
    liststaff: PropTypes.array,
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool,
}
const mapStateToProps = state => {
    return {
        indexLanguage: state.changelanguage.indexLanguage,
    }
}
export default connect(mapStateToProps, null)(ModalGroup);