import { css } from '@emotion/core';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FadeLoader } from 'react-spinners';
import { getApiDayOffFormShowAll, getApiExportHistory, getApiOverviewFull, getApiStatisticStaff, getListReasonOffSelect, getStaffsForDepartment, getApiComplianceReport } from '../../../../../api';
import HeaderTimekeeping from '../components/HeaderTimekeeping';
import LoadingOverlay from 'react-loading-overlay';
import { coverDateToNameDay, processDataListDay, workIntersect, workLetter, workLetterOT, workLetterOTHoliday, workTimekeeping } from '../components/processData';
import TableTab from '../components/TableTab';
import ReactGA from 'react-ga'
import TableReport from '../components/TableReport';
import language from '../../../../../language/language';
import TreeView from '../../../TreeView';
import { getCameraArray, formatTreeViewData, formatUsernameFromEmail } from '../../../CommonFunction';
const portletBodyCss = {
    paddingTop: 0,
    paddingBottom: 0,
};
Report.propTypes = {
    data: PropTypes.object,
};

Report.defaultProps = {
    data: null,
}
export const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;





function Report(props) {
    const { data } = props;
    const indexLanguage = useSelector(state => state.changelanguage.indexLanguage);
    const box_engine_cf = useSelector(state => state.boxai.box_engine_cf);
    const token = useSelector(state => state.isLogin.access_token);
    const [selectDepartment, setSelectDepartment] = useState(false);
    const [departmentCurrent, setDepartmentCurrent] = useState({
        name: language[indexLanguage].placeholderSelect.all_department,
        value: -1,
    });

    const [dayInMonth, setDayInMonth] = useState([]);
    const initial = {
        data_search: '',
        id_shift: -1,
        id_department: -1,
        start_date: moment().startOf('month').format('YYYY-MM-DD'),
        end_date: moment(new Date).format('YYYY-MM-DD'),
        id_company: box_engine_cf.idCompany,
        index: 1,
        is_export: 1,
        item_per_page: 10,
    }
    const optionReports = [
        { value: 1, title: language[indexLanguage].Report.report_1 },
        { value: 2, title: language[indexLanguage].Report.report_2 },
        { value: 3, title: language[indexLanguage].Report.report_3 },
        { value: 4, title: language[indexLanguage].Report.report_4 },
        { value: 13, title: language[indexLanguage].Report.report_13 },
        { value: 5, title: language[indexLanguage].Report.report_5 },
        { value: 14, title: language[indexLanguage].Report.report_14 },
        { value: 6, title: language[indexLanguage].Report.report_6 },
        { value: 7, title: language[indexLanguage].Report.report_7 },
        { value: 8, title: language[indexLanguage].Report.report_8 },
        { value: 9, title: language[indexLanguage].Report.report_9 },
        { value: 10, title: language[indexLanguage].Report.report_10 },
        { value: 11, title: language[indexLanguage].Report.report_11 },
        { value: 12, title: language[indexLanguage].Report.report_12 },
    ];

    const [dataSearch, setDataSearch] = useState(initial);


    useEffect(() => {
        let day_In_Month = [];
        const last_day_in_month = new Date(new Date(dataSearch.start_date).getFullYear(), new Date(dataSearch.start_date).getMonth() + 1, 0).getDate();
        for (let i = 0; i < last_day_in_month; i++) {
            day_In_Month.push(i + 1);
        }
        setDayInMonth(day_In_Month);
    }, [dataSearch.start_date, dataSearch.end_date])
    const [crrReport, setCrrReport] = useState();
    const [crrData, setCrrData] = useState(null);
    const [listStaff, setListStaff] = useState(null);
    const [listReason, setListReason] = useState(null);

    const [loading, setLoading] = useState(false);


    const coverTimeToHour = (data) => {
        const time = moment(data).format('HH:mm').split(':');
        return (parseFloat(time[0]) + parseFloat(time[1] / 60));
    }
    const handleSelectDepartment = () => {
        setSelectDepartment(prev => !prev);
    }
    const fetchData = async (dataPost, key, is_staff) => {
        console.log(key)
        try {
            setLoading(true);
            setCrrData(null);
            let data = null;
            if (key === 1 || key === 2 || key === 3 || key === 4 || key === 13) {
                const result = await getApiStatisticStaff(box_engine_cf.crrApiSocket, dataPost, token);
                data = processDataAPIStatisticStaff(result, key);
            }
            // api show_all
            else if (key === 10 || key === 11) {
                const result = await getApiDayOffFormShowAll(box_engine_cf.crrApiSocket, dataPost, token);
                data = processDataAPIShowAll(result, key, -1);
            }
            // api export_history
            else if (key === 6 && is_staff) {
                const result = await getApiExportHistory(box_engine_cf.crrApiSocket, dataPost, token);
                data = processDataAPIExportHistory(result);
            }
            //api overview
            else if (key === 5) {
                const result = await getApiOverviewFull(box_engine_cf.crrApiSocket, dataPost, token);
                data = processDataAPIOverview(result, key);
            } else if (key === 7 && is_staff) {
                const result = await getApiOverviewFull(box_engine_cf.crrApiSocket, dataPost, token);
                data = processDataAPIOverview(result, key);
            } else if (key === 8 || key === 12) {
                const result = await getApiOverviewFull(box_engine_cf.crrApiSocket, dataPost, token);
                data = processDataAPIOverview(result, key);
            } else if (key === 9) {
                const result = await getApiOverviewFull(box_engine_cf.crrApiSocket, dataPost, token);
                data = processDataAPIOverview(result, key);
            } else if (key == 14) {
                const result = await getApiComplianceReport(box_engine_cf.crrApiSocket, dataPost, token);
                data = processDataAPIComplianceReport(result, key);
            }
            setCrrData(data);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    }
    const formatTimeComliance = (date) => {
        if (typeof date == "undefined" || date == null)
            return "";
        const d = new Date(date);
        var hours = "0" + d.getHours();
        var minutes = "0" + d.getMinutes();
        var seconds = "0" + d.getSeconds();
        return hours.substr(-2) + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
    }
    const processDataAPIComplianceReport = (data, key) => {
        const new_data = [];
        data.data.map((map) => {
            for (const tk in map.timekeepings) {
                var time_in = "", time_out = "", in_late = "";
                var before_compliance = "", after_compliance = "", status_confirm = "", confirm_person = "", confirm_time = "";
                if (typeof map.timekeepings[tk].timekeeping != "undefined") {
                    time_in = formatTimeComliance(map.timekeepings[tk].timekeeping.time_in);
                    time_out = formatTimeComliance(map.timekeepings[tk].timekeeping.time_out);
                    in_late = map.timekeepings[tk].timekeeping.in_late_minutes;
                    before_compliance = map.timekeepings[tk].timekeeping.is_compliance_before_explanation ? "Tuân thủ" : "Không tuân thủ";
                    after_compliance = map.timekeepings[tk].timekeeping.is_compliance_before_explanation ? "Tuân thủ" : map.timekeepings[tk].timekeeping.is_compliance_after_explanation ? "Tuân thủ" : "Không tuân thủ";
                    status_confirm = map.timekeepings[tk].timekeeping.is_confirm ? "Đã xác nhận" : "";
                    confirm_person = map.timekeepings[tk].timekeeping.confirm_person ? formatUsernameFromEmail(map.timekeepings[tk].timekeeping.confirm_person.email) : "";
                    confirm_time = map.timekeepings[tk].timekeeping.create_time ? moment(map.timekeepings[tk].timekeeping.create_time).format('HH:mm:ss DD-MM-YYYY') : "";
                    var camera_in = "";
                    var camera_out = "";
                    if (map.timekeepings[tk].timekeeping.box_engine_id != "" && map.timekeepings[tk].timekeeping.box_engine_id != null) {
                        var cameraArray = getCameraArray(box_engine_cf.dataCamera);
                        var cameras = map.timekeepings[tk].timekeeping.box_engine_id.split(",");
                        camera_in = typeof cameras[0] != "undefined" ? cameraArray[cameras[0]] : "";
                        camera_out = typeof cameras[1] != "undefined" ? cameraArray[cameras[1]] : "";
                    }
                }
                var date = tk.split("|");
                var shift = "";
                if (map.shifts.length > 0) {
                    shift = map.shifts[0].name + " (" + map.shifts[0].time_in.substr(0, 5) + " - " + map.shifts[0].time_out.substr(0, 5) + ")";
                }
                var temp_data = {
                    "id_staff": map.id_staff,
                    "username": formatUsernameFromEmail(map.email),
                    "name": map.name,
                    "department": map.name_department,
                    "date": date[0],
                    "day": date[1],
                    "time_in": time_in,
                    "time_out": time_out,
                    "camera_in": camera_in,
                    "camera_out": camera_out,
                    "in_late": in_late > 0 ? in_late : "",
                    "shift": shift,
                    "before_compliance": before_compliance,
                    "after_compliance": after_compliance,
                    "status_confirm": status_confirm,
                    "confirm_person": confirm_person,
                    "confirm_time": confirm_time
                };
                new_data.push(temp_data);
            }
        });
        // console.log(new_data);
        return new_data.length > 0 ? new_data : null;
    }
    const processDataAPIStatisticStaff = (data, key) => {
        let new_data = [];
        let data_key_4 = [];
        if (Object.keys(data).length === 0) return new_data;

        if (key === 4) {
            data.data.forEach(value_1 => {
                value_1.days_off.sort().forEach(value_2 => {
                    data_key_4.push({
                        ...value_1,
                        name_shift: value_1.name_shift,
                        date: value_2,
                    })
                })
            })
        }

        const data_process = key === 4 ? data_key_4 : data.data;
        const data_letter_symbol = data_process.map(map => {
            const data_letter = map.dof_list;

            if (data_letter) {
                var data_letter_day = []
                data_letter_day = data_letter.filter(filter => {
                    if (moment(filter.start_date).format('YYYY-MM-DD HH:mm:ss') >= (moment(map.date).format('YYYY-MM-DD') + ' ' + map.shift_time_out) ||
                        moment(filter.end_date).format('YYYY-MM-DD HH:mm:ss') <= (moment(map.date).format('YYYY-MM-DD') + ' ' + map.shift_time_in)) {
                        return false;
                    } else {
                        return true;
                    }
                });
                // data_letter_day = data_letter
                if (data_letter_day.length > 0) {
                    let letter_symbol = '';
                    let time_in_letter
                    let time_out_letter
                    data_letter_day.forEach(value => {
                        let time_in_shift = moment(value.start_date).format('YYYY-MM-DD') + " " + map.shift_time_in.slice(0, 5) + ':00';

                        let time_out_shift = moment(value.end_date).format('YYYY-MM-DD') + " " + map.shift_time_out.slice(0, 5) + ':00';
                        let time_in = moment(value.start_date);
                        let time_out = moment(value.end_date);

                        let in_late = time_in.diff(time_in_shift);
                        let out_soon = time_out.diff(time_out_shift);

                        let durationInLate = moment.duration(in_late);
                        let durationOutSoon = moment.duration(out_soon);

                        let minutesLate = durationInLate.asMinutes();
                        let minutesSoon = durationOutSoon.asMinutes();

                        var inLate = Math.abs(minutesLate);
                        var outSoon = Math.abs(minutesSoon);

                        var renderText = ""
                        if (inLate === 0) {
                            renderText = `${language[indexLanguage].Report.out_soon_time}: ${outSoon} ${language[indexLanguage].Report.minutes}`
                        } else if (outSoon === 0) {
                            renderText = `${language[indexLanguage].Report.to_late_time}: ${inLate} ${language[indexLanguage].Report.minutes}`
                        } else {
                            renderText = `${language[indexLanguage].Report.to_late_time}: ${inLate} ${language[indexLanguage].Report.minutes} - ${language[indexLanguage].Report.out_soon_time}: ${outSoon} ${language[indexLanguage].Report.minutes}`
                        }

                        switch (value.type) {
                            case 1:
                                letter_symbol += `, ${value.reason.symbol} (${moment(value.start_date).format("HH:mm")} - ${moment(value.end_date).format("HH:mm")})`;
                                break;
                            case 3:
                                letter_symbol += `, CT (${moment(value.start_date).format("HH:mm")} - ${moment(value.end_date).format("HH:mm")})`;
                                break;
                            case 4:
                                letter_symbol += `, MS (${renderText})`;
                                time_in_letter = value.start_date;
                                time_out_letter = value.end_date;
                                break;
                            case 5:
                                letter_symbol += `, CCB (${moment(value.start_date).format("HH:mm")} - ${moment(value.end_date).format("HH:mm")})`;
                                break;

                            default:
                                break;
                        }
                    });
                    return {
                        ...map,
                        red_text_time_in: time_in_letter && moment(map.time_in).isAfter(moment(time_in_letter)) ? true : false,
                        red_text_time_out: time_out_letter && moment(map.time_out).isBefore(moment(time_out_letter)) ? true : false,
                        dof: letter_symbol.substring(2),
                    }
                } else {
                    return {
                        ...map,
                        dof: '',
                        red_text_time_in: false,
                        red_text_time_out: false
                    }
                }
            } else {
                return {
                    ...map,
                    dof: '',
                    red_text_time_in: false,
                    red_text_time_out: false
                }
            }
        });
        if (key === 3) {
            new_data = data_letter_symbol.map(map => ({
                ...map,
                time: moment(map.time_in).format('HH:mm DD-MM-YYYY'),
                minute: map.in_late,
                name_shift: map.name_shift,
                off: map.dof,
            }))
        } if (key === 1) {
            new_data = data_letter_symbol.map(map => ({
                ...map,
                time: moment(map.time_in).format('HH:mm DD-MM-YYYY'),
                minute: map.in_late,
                name_shift: map.name_shift,
                off: map.dof,
            }))
        } else if (key === 2) {
            new_data = data_letter_symbol.map(map => ({
                ...map,
                time: moment(map.time_out).format('HH:mm DD-MM-YYYY'),
                minute: map.out_soon,
                name_shift: map.name_shift,
                off: map.dof,
            }))
        } else if (key === 4 || key === 13) {
            new_data = data_letter_symbol.map(map => ({
                ...map,
                name_shift: map.name_shift,
                off: map.dof,
                date: moment(map.date).format('DD-MM-YYYY'),
            }))
        }
        return new_data.length > 0 ? new_data : null;
    }
    const processDataAPIOverview = (data, key) => {
        let day_In_Month = [];
        const last_day_in_month = new Date(new Date(dataSearch.start_date).getFullYear(), new Date(dataSearch.start_date).getMonth() + 1, 0).getDate();
        for (let i = 0; i < last_day_in_month; i++) {
            day_In_Month.push(i + 1);
        }
        if (key === 5) {
            const new_data = data.data.map((map) => {
                const time_keepings = map.timekeepings
                let total_work_ot = map.total.total_hour_OT_in_holiday + map.total.total_hour_OT_in_week + map.total.total_hour_OT_in_weekend;
                const day_in_month = JSON.parse(JSON.stringify(day_In_Month));
                day_in_month.forEach((value_) => {
                    let n_day = value_;
                    if (n_day < 10) {
                        n_day = '0' + n_day;
                    }
                    const y_m_d = moment(dataSearch.start_date).format('YYYY-MM-') + n_day;

                    let list_dof = time_keepings[y_m_d] ? time_keepings[y_m_d].dof_list : [];
                    const dof = list_dof.filter(filter => filter.type === 2);

                    map[value_] = 0;
                    const dof_in_day = dof.filter(filter => new Date(filter.start_date).getDate() === value_);
                    if (dof_in_day.length > 0) {
                        dof_in_day.forEach(value => {
                            map[value_] = value.work_off.toFixed(2);
                            total_work_ot = total_work_ot;
                        });
                    } else {
                        map[value_] = '';
                    }
                });
                return {
                    ...map,
                    total_time_ot: total_work_ot.toFixed(2),
                    department: map.name_department,
                }
            });
            return new_data.length > 0 ? new_data : null;
        } else if (key === 7) {
            const result = processDataListDay(data.data, data.key, dataSearch);
            const new_data = result.map(map => ({
                ...map,
                date: moment(map.date).format('DD-MM-YYYY'),
                name_shift: map.shift.title,
            }));
            return new_data.length > 0 ? new_data : null;;
        } else if (key === 8) {
            let new_data = [];
            const day_in_month = JSON.parse(JSON.stringify(day_In_Month));
            data.data.map(map => {
                const shifts = map.shifts.filter(filter => filter.id !== null && filter.day_of_week);
                const time_keepings = map.timekeepings

                const work_day_off = map.total.total_workoff_with_salary
                const work_business = map.total.total_go_on_business
                const total_work = map.total.total_work

                day_in_month.forEach((value) => {
                    let n_day = value;
                    if (n_day < 10) {
                        n_day = '0' + n_day;
                    }
                    const y_m_d = moment(dataSearch.start_date).format('YYYY-MM-') + n_day;
                    let time_keeping = time_keepings[y_m_d].timekeeping_list

                    var work = 0

                    time_keeping.map(tk => {
                        work += tk.work
                    })

                    map[value] = work === 0 ? '' : parseFloat(work).toFixed(2);
                });
                map.work_day_off = work_day_off.toFixed(2);
                map.work_business = work_business.toFixed(2);
                map.total_work = total_work.toFixed(2);
                // map.name_shift = shift.name;
                new_data.push({ ...map, department: map.name_department });

            });
            return new_data.length > 0 ? new_data : null;;
        } else if (key === 9) {
            let new_data = [];
            const day_in_month = JSON.parse(JSON.stringify(day_In_Month));
            data.data.map(map => {
                const time_keepings = map.timekeepings
                const shifts = map.shifts.filter(filter => filter.id !== null && filter.day_of_week);

                for (let i = 0; i < 2; i++) {
                    let a = { ...map };
                    let work_total = 0;
                    let work_total_time = 0;

                    day_in_month.forEach(value => {
                        let n_day = value;
                        if (n_day < 10) {
                            n_day = '0' + n_day;
                        }
                        const y_m_d = moment(dataSearch.start_date).format('YYYY-MM-') + n_day;

                        let timekeeping = time_keepings[y_m_d].timekeeping_list

                        const time_keeping = timekeeping.filter(filter => new Date(filter.date).getDate() === value);
                        let total_time = 0;
                        let total_work = 0;

                        time_keeping.forEach(data_time_keeping => {
                            const time = data_time_keeping.work_time ? data_time_keeping.work_time : 0;
                            total_time += parseFloat(time.toFixed(2));
                            total_work += parseFloat(data_time_keeping.work.toFixed(2));
                        });
                        if (i === 0) {
                            a[value] = total_time ? total_time.toFixed(2) : '';
                        } else {
                            a[value] = total_work ? total_work.toFixed(2) : '';
                        }
                    });
                    if (i === 0) {
                        a.index = language[indexLanguage].Report.total_hour_work;
                        a.key = 1;
                        a.department = map.name_department;
                        a.work_day_off = parseFloat(map.total.total_workoff_with_salary.toFixed(2));
                        a.work_business = parseFloat(map.total.total_go_on_business.toFixed(2));
                        a.total_work = parseFloat(map.total.total_work.toFixed(2));
                        a.total_work_time = parseFloat(map.total.total_work_time.toFixed(2));
                        a.number_late = parseFloat(map.total.total_in_late.toFixed(2));
                        a.number_late_time = parseFloat(map.total.total_minute_in_late.toFixed(2));
                        a.number_soon = parseFloat(map.total.total_out_soon.toFixed(2));
                        a.number_soon_time = parseFloat(map.total.total_minute_out_soon.toFixed(2));
                        a.ot_in_week = parseFloat(map.total.total_hour_OT_in_week.toFixed(2));
                        a.ot_last_week = parseFloat(map.total.total_hour_OT_in_weekend.toFixed(2));
                        a.ot_holiday = parseFloat(map.total.total_hour_OT_in_holiday.toFixed(2));
                        a.number_shift = shifts.length;
                    } else {
                        a.index = language[indexLanguage].Report.total_work;
                        if (shifts.length === 0) {
                            a.key = 9;
                        }
                    }
                    new_data.push(a);
                }

                shifts.map((shift, index_shift) => {
                    for (let i = 0; i < 2; i++) {
                        let a = { ...map };
                        day_in_month.forEach((value) => {
                            let n_day = value;
                            if (n_day < 10) {
                                n_day = '0' + n_day;
                            }
                            const y_m_d = moment(dataSearch.start_date).format('YYYY-MM-') + n_day;

                            let timekeeping = time_keepings[y_m_d].timekeeping_list

                            const time_keeping = timekeeping.filter(filter => filter.id_shift === shift.id);


                            if (i === 0) {
                                a[value] = '';
                                time_keeping.forEach(time_keeping => {
                                    if (new Date(time_keeping.date).getDate() === value) {
                                        a[value] = time_keeping.time_in ? moment(time_keeping.time_in).format('HH:mm') : '';
                                    }
                                })
                            } else {
                                a[value] = '';
                                time_keeping.forEach(time_keeping => {
                                    if (new Date(time_keeping.date).getDate() === value) {
                                        a[value] = time_keeping.time_out ? moment(time_keeping.time_out).format('HH:mm') : '';
                                    }
                                })
                            }
                        });
                        const index = shifts.length > 1 ? `${index_shift + 1}` : '';
                        if (i === 0) {
                            a.index = language[indexLanguage].Report.time_in_abb + index;
                        } else {
                            a.index = language[indexLanguage].Report.time_out_abb + index;
                            if (shifts.length === index_shift + 1) {
                                a.key = 9;
                            }
                        }
                        new_data.push(a);
                    }
                });
            });
            return new_data.length > 0 ? new_data : null;;
        } else if (key === 12) {
            const result = data.data;
            let new_data = [];
            result.map(map => {
                let a = { ...map };
                const time_keepings = map.timekeepings

                const day_in_month = JSON.parse(JSON.stringify(day_In_Month));
                day_in_month.forEach(value => {
                    let n_day = value;
                    if (n_day < 10) {
                        n_day = '0' + n_day;
                    }
                    const y_m_d = moment(dataSearch.start_date).format('YYYY-MM-') + n_day;
                    let list_timekeeping = time_keepings[y_m_d] ? time_keepings[y_m_d].timekeeping_list : [];

                    const time_keeping = list_timekeeping.filter(filter => filter.name_company !== undefined && filter.name_company !== box_engine_cf.nameCompany).map(map => ({
                        ...map,
                        id_staff: a.id_staff,
                        name: a.name,
                        department: a.name_department,
                        date: moment(map.date).format('DD-MM-YYYY'),
                        time_in: map.time_in ? moment(map.time_in).format('HH:mm') : '',
                        time_out: map.time_out ? moment(map.time_out).format('HH:mm') : '',
                        place: map.name_company,
                    }));
                    new_data = new_data.concat(time_keeping);
                })
            });
            return new_data.length > 0 ? new_data : null;;
        }
    }
    const processDataAPIExportHistory = (data) => {
        const start = new Date(dataSearch.start_date).getDate();
        const end = new Date(dataSearch.end_date).getDate();

        let new_data = [];
        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            for (let i = start; i <= end; i++) {
                let history = element.histories
                var staffInfo = element.staff_information
                const a = history.filter(filter => new Date(filter.timeget).getDate() === i).sort((a, b) => moment(a.timeget).format('YYYY-MM-DD HH:mm:ss') - moment(b.timeget).format('YYYY-MM-DD HH:mm:ss'));
                let date = '';
                let day = '';
                let time = '';
                a.forEach(value => {
                    date = moment(value.timeget).format('DD-MM-YYYY');
                    day = coverDateToNameDay(new Date(value.timeget).getDay());
                    time += `${moment(value.timeget).format('HH:mm')} / `;
                });
                if (a.length > 0) {
                    new_data.push({
                        id_staff: staffInfo.id_staff,
                        name: staffInfo.name,
                        department: staffInfo.department,
                        date: date,
                        day: day,
                        number_in_out: a.length,
                        time: time,
                    })
                }
            }
        }
        return new_data.length > 0 ? new_data : null;
    }
    const processDataAPIShowAll = (data, key, id_reason) => {
        let new_data = [];
        if (key === 10) {
            let result = data;
            if (id_reason !== -1) {
                result = data.filter(filter => filter.id_reason === id_reason);
            }

            new_data = result.map(map => {
                return {
                    id_staff: map.staff.id_staff,
                    name: map.staff.name,
                    department: map.staff.department.name,
                    from_date: moment(map.start_date).format('HH:mm DD-MM-YYYY'),
                    to_date: moment(map.end_date).format('HH:mm DD-MM-YYYY'),
                    reason: map.reason.name,
                    id_reason: map.id_reason,
                }
            })
        } else if (key === 11) {
            new_data = data.map(map => {
                return {
                    id_staff: map.staff.id_staff,
                    name: map.staff.name,
                    department: map.staff.department.name,
                    from_date: moment(map.start_date).format('HH:mm DD-MM-YYYY'),
                    to_date: moment(map.end_date).format('HH:mm DD-MM-YYYY'),
                    business_place: JSON.parse(map.description).work_place,
                    business_content: JSON.parse(map.description).work_content,
                }
            })
        }
        return new_data.length > 0 ? new_data : null;
    }

    const processShift = (timekeeping, dof, shift, date) => {
        let n_day = date;
        if (n_day < 10) {
            n_day = '0' + n_day;
        }
        const y_m_d = moment(dataSearch.start_date).format('YYYY-MM-') + n_day;
        const data_timekeeping = workTimekeeping(timekeeping, shift, date);
        const data_letter = workLetter(dof, shift, date, y_m_d);

        const work_timekeeping = data_timekeeping[0];
        const work_letter = data_letter ? data_letter[0] : 0;

        const time_out_null = data_timekeeping[1];

        let work = (time_out_null === true && work_letter === 0) ? 'x' : (work_timekeeping === - 1 && work_letter !== 0) ? parseFloat((work_letter)).toFixed(2) : parseFloat(work_timekeeping).toFixed(2);

        return work;
    }

    const getListStaffForDepartment = async (id_department) => {
        const dataPost = {
            id_company: box_engine_cf.idCompany,
            id_department: id_department
        }
        const listDepartment = data.list_department ? data.list_department : []
        const result = await getStaffsForDepartment(box_engine_cf.crrApiSocket, dataPost, token);
        let staffs = result.filter(filter => listDepartment.some(some => some.value === filter.id_department)).map(map => ({
            title: `${map.name} - ${map.id_staff} - ${formatUsernameFromEmail(map.email)}`,
            value: map.id_staff,
            id: map.id,
            obj_staff: map.object_id,
        }));
        staffs.unshift({
            title: language[indexLanguage].placeholderSelect.all_staff,
            value: '',
            obj_staff: null,
        })
        setListStaff(staffs);
    }
    const getListReason = async () => {
        const result = await getListReasonOffSelect(box_engine_cf.crrApiSocket, box_engine_cf.idCompany, token);
        setListReason(result);
    }

    const handleResetData = () => {
        setCrrData([]);
    }

    const getDataForReason = async (id_reason) => {
        const dataPost = {
            data_search: '',
            start_date: moment(dataSearch.start_date).format('YYYY-MM-DD') + ' 00:00:01',
            end_date: moment(dataSearch.end_date).format('YYYY-MM-DD') + ' 23:59:59',
            id_company: dataSearch.id_company,
            id_department: dataSearch.id_department,
            status: [3],
            type: 1,
        }
        const result = await getApiDayOffFormShowAll(box_engine_cf.crrApiSocket, dataPost, token);
        const data = processDataAPIShowAll(result, 10, id_reason);
        setCrrData(data);
    }

    const handleChangeReport = (value) => {
        setSelectDepartment(false);
        setDepartmentCurrent({
            name: language[indexLanguage].placeholderSelect.all_department,
            value: -1,
        });
        ReactGA.event({
            category: 'Bao cao',
            action: `Xem ${value.title}`,
        });
        const key = value.value;
        let dataPost = dataSearch;
        setCrrReport(value);
        if (key === 5 || key === 12) {
            dataPost = initial;
        } else if (key === 6) {
            dataPost = {
                id_department: dataSearch.id_department ? dataSearch.id_department : -1,
                start_date: moment(new Date()).startOf('month').format('YYYY-MM-DD'),
                end_date: moment(new Date()).format('YYYY-MM-DD'),
                id_staff_list: "",
                id_company: box_engine_cf.idCompany,
            };
        } else if (key === 7) {
            dataPost = { ...initial, end_date: moment(new Date()).endOf('month').format('YYYY-MM-DD') };
        } else if (key === 8 || key === 9) {
            dataPost = { ...initial, end_date: moment(new Date()).endOf('month').format('YYYY-MM-DD') };
        } else if (key === 1 || key === 2 || key === 3 || key === 4 || key === 13) {
            dataPost = {
                data_search: "",
                id_company: box_engine_cf.idCompany,
                id_shift: dataSearch.id_shift ? dataSearch.id_shift : -1,
                start_date: moment(new Date()).startOf('month').format('YYYY-MM-DD'),
                end_date: moment(new Date()).format('YYYY-MM-DD'),
                type_export: key === 13 ? 5 : key,
                id_department: dataSearch.id_department ? dataSearch.id_department : -1,
            }
        } else if (key === 10) {
            dataPost = {
                data_search: '',
                start_date: moment(new Date()).startOf('month').format('YYYY-MM-DD') + ' 00:00:01',
                end_date: moment(new Date()).format('YYYY-MM-DD') + ' 23:59:59',
                id_company: box_engine_cf.idCompany,
                id_department: dataSearch.id_department ? dataSearch.id_department : -1,
                status: [3],
                type: 1,
            }
        } else if (key === 11) {
            dataPost = {
                data_search: '',
                start_date: moment(new Date()).startOf('month').format('YYYY-MM-DD') + ' 00:00:01',
                end_date: moment(new Date()).format('YYYY-MM-DD') + ' 23:59:59',
                id_company: box_engine_cf.idCompany,
                id_department: dataSearch.id_department ? dataSearch.id_department : -1,
                status: [3],
                type: 3,
            }
        } else if (key === 14) {
            dataPost = {
                data_search: "",
                id_company: box_engine_cf.idCompany,
                id_shift: dataSearch.id_shift ? dataSearch.id_shift : -1,
                start_date: moment(new Date()).startOf('month').format('YYYY-MM-DD'),
                end_date: moment(new Date()).format('YYYY-MM-DD'),
                type_export: key === 13 ? 5 : key,
                id_department: dataSearch.id_department ? dataSearch.id_department : -1,
            }
        }
        setDataSearch(dataPost);
        setCrrData(null);
        // fetchData(dataPost, key);
    }
    const getDataSearch = (data, is_staff) => {
        const dataPost = Object.assign(dataSearch, data);
        fetchData(dataPost, crrReport.value, is_staff);
    }
    useEffect(() => {
        if (box_engine_cf.idCompany) {
            if (data.list_department && data.list_department.length > 0) {
                const id_department = data.list_department[0].value;
                getListStaffForDepartment(id_department);
            }
            getListReason();
            setDataSearch({ ...dataSearch, id_company: box_engine_cf.idCompany });
        }
    }, [box_engine_cf.idCompany, data])
    return (
        <div>
            {
                crrReport ?
                    <>
                        <LoadingOverlay
                            active={loading}
                            spinner={<FadeLoader
                                css={override}
                                sizeUnit={"px"}
                                size={150}
                                color={'rgb(54, 215, 183)'}
                                loading={true}
                            />}
                            styles={{
                                overlay: (base) => ({
                                    ...base,
                                    background: 'rgba(0, 0, 0, 0)'
                                })
                            }}
                            text={
                                <div style={{ color: '#000' }}>{language[indexLanguage].Text_Notifi.text_notifi_loadding_export}</div>
                            }
                            className="col-xl-12 p-0 loading_timekeeping"
                        >
                            <div className='select_option col-md-12'>
                                <div className="row-sm row">
                                    <div className='col-md-3 m-form__control select_option pr-2 pb-2 pl-0'>
                                        <Autocomplete
                                            value={crrReport}
                                            options={optionReports}
                                            onChange={(e, value) => handleChangeReport(value)}
                                            getOptionLabel={option => option.title}
                                            disableClearable
                                            // style={{ width: 320 }}
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    variant='outlined'
                                                    placeholder={language[indexLanguage].placeholderSelect.select_report}
                                                    fullWidth
                                                />
                                            )}
                                        />
                                    </div>
                                    <HeaderTimekeeping
                                        selectedDepartment={departmentCurrent}
                                        data={dataSearch} list_shift={data ? data.list_shift : null}
                                        list_department={data ? data.list_department : null} tab={3}
                                        report={crrReport.value} getData={getDataSearch} getListStaff={getListStaffForDepartment}
                                        list_staff={listStaff} resetData={handleResetData} list_reason={listReason}
                                        getDataForReason={getDataForReason}
                                        selectDepartment={handleSelectDepartment}
                                    />
                                </div>
                            </div>
                            <div className="row-sm row m-portlet__body" style={portletBodyCss}>
                                {
                                    selectDepartment && (
                                        <div className='m-portlet__body dashboard_spinner col-xl-4'>
                                            <div style={{ maxHeight: "600px", overflow: "auto", marginRight: "20px", whiteSpace: "nowrap" }}>
                                                <TreeView
                                                    item={formatTreeViewData(data ? data.list_department : [])}
                                                    getList={(data) => {
                                                        setDepartmentCurrent({
                                                            name: data.title,
                                                            value: data.value,
                                                            code: data.code
                                                        });
                                                        getListStaffForDepartment(data.value);
                                                        getDataSearch({ id_department: data.value });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    )
                                }
                                <div className={selectDepartment ? 'col-xl-8' : 'col-xl-12'}>
                                    {/* <TableTab data={crrData} day_in_month={dayInMonth} report={crrReport.value} start_end={dataSearch} /> */}
                                    <TableReport data={crrData} day_in_month={dayInMonth} report={crrReport.value} start_end={dataSearch} />

                                </div>
                            </div>
                        </LoadingOverlay>
                    </>
                    :
                    <div className='select_option col-md-12'>
                        <div className="row-sm row">
                            <div className='col-md-3 m-form__control select_option pr-2 pb-2 pl-0'>
                                <Autocomplete
                                    value={crrReport}
                                    options={optionReports}
                                    onChange={(e, value) => handleChangeReport(value)}
                                    getOptionLabel={option => option.title}
                                    disableClearable
                                    // style={{ width: 320 }}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            variant='outlined'
                                            placeholder={language[indexLanguage].placeholderSelect.select_report}
                                            fullWidth
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>
            }
        </div>

    );
}

export default Report;