import React, { Component } from 'react';
import Select from 'react-select';
import Switch from "react-switch";
import swal from 'sweetalert';
import Axios from 'axios';
import Store from '../../../../store';
import language from '../../../../language/language';

import { connect } from 'react-redux'

class Setting extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLogin: Store.getState().isLogin.isLogin,
            access_token: Store.getState().isLogin.access_token,
            box_engine_cf: Store.getState().boxai.box_engine_cf,
            language: Store.getState().changelanguage.language,
            dataRole: Store.getState().role.role,
            dataCamera: Store.getState().boxai.box_engine_cf.dataCamera,
            type: '',
            idStaffAutoCreate: false,
            incrementalChecked: false,
            isMonth: {
                label: 'Tháng 3',
                value: '01-03',
            },
            isDay: {
                label: 'Ngày 1',
                value: 1,
            },
            optionDay: [],
            dataConfig: 0,
            webHook: '',
            valueTemp: "",
            temp_thresholds: [],
            enable_accumultion: 0,
            expired_accumulation: 0,
            advanceTakeLeave: false,
            dataPost: {
                'id_company': 0,
                content:
                {
                    id_staff_auto_increase: 0,
                    web_hook: {
                        url: '',
                    },
                    temp_thresholds: [],
                    daysoff: {
                        enable_accumultion: 0,
                        expired_accumulation: '01-03',
                        advance_take_leave: 0,
                    }
                },

            }

        }
        Store.subscribe(() => {
            this.setState({
                ...this.state,
                isLogin: Store.getState().isLogin.isLogin,
                access_token: Store.getState().isLogin.access_token,
                box_engine_cf: Store.getState().boxai.box_engine_cf,
                dataCamera: Store.getState().boxai.box_engine_cf.dataCamera,
                language: Store.getState().changelanguage.language,
            }, () => {
                this.configGetByIDCompany();

            });
        });
        this.handleSwitch = this.handleSwitch.bind(this);
    }

    monthToString = (month) => {
        switch (month) {
            case '01-01':
                return { label: language[this.props.indexLanguage].Month.january, value: month }
            case '01-02':
                return { label: language[this.props.indexLanguage].Month.february, value: month }
            case '01-03':
                return { label: language[this.props.indexLanguage].Month.march, value: month }
            case '01-04':
                return { label: language[this.props.indexLanguage].Month.april, value: month }
            case '01-05':
                return { label: language[this.props.indexLanguage].Month.may, value: month }
            case '01-06':
                return { label: language[this.props.indexLanguage].Month.june, value: month }
            case '01-07':
                return { label: language[this.props.indexLanguage].Month.july, value: month }
            case '01-08':
                return { label: language[this.props.indexLanguage].Month.august, value: month }
            case '01-09':
                return { label: language[this.props.indexLanguage].Month.september, value: month }
            case '01-10':
                return { label: language[this.props.indexLanguage].Month.october, value: month }
            case '01-11':
                return { label: language[this.props.indexLanguage].Month.november, value: month }
            case '01-12':
                return { label: language[this.props.indexLanguage].Month.december, value: month }
            default:
                break;
        }
}

    dayToString = (day) => {
            switch (day) {
                case 1:
                    return { label: `${language[this.props.indexLanguage].Settings.day} 1`, value: day }
                case 2:
                    return { label: `${language[this.props.indexLanguage].Settings.day} 2`, value: day }
                case 3:
                    return { label: `${language[this.props.indexLanguage].Settings.day} 3`, value: day }
                case 4:
                    return { label: `${language[this.props.indexLanguage].Settings.day} 4`, value: day }
                case 5:
                    return { label: `${language[this.props.indexLanguage].Settings.day} 5`, value: day }
                case 6:
                    return { label: `${language[this.props.indexLanguage].Settings.day} 6`, value: day }
                case 7:
                    return { label: `${language[this.props.indexLanguage].Settings.day} 7`, value: day }
                case 8:
                    return { label: `${language[this.props.indexLanguage].Settings.day} 8`, value: day }
                case 9:
                    return { label: `${language[this.props.indexLanguage].Settings.day} 9`, value: day }
                case 10:
                    return { label: `${language[this.props.indexLanguage].Settings.day} 10`, value: day }
                case 11:
                    return { label: `${language[this.props.indexLanguage].Settings.day} 11`, value: day }
                case 12:
                    return { label: `${language[this.props.indexLanguage].Settings.day} 12`, value: day }
                case 13:
                    return { label: `${language[this.props.indexLanguage].Settings.day} 13`, value: day }
                case 14:
                    return { label: `${language[this.props.indexLanguage].Settings.day} 14`, value: day }
                case 15:
                    return { label: `${language[this.props.indexLanguage].Settings.day} 15`, value: day }
                case 16:
                    return { label: `${language[this.props.indexLanguage].Settings.day} 16`, value: day }
                case 17:
                    return { label: `${language[this.props.indexLanguage].Settings.day} 17`, value: day }
                case 18:
                    return { label: `${language[this.props.indexLanguage].Settings.day} 18`, value: day }
                case 19:
                    return { label: `${language[this.props.indexLanguage].Settings.day} 19`, value: day }
                case 20:
                    return { label: `${language[this.props.indexLanguage].Settings.day} 20`, value: day }
                case 21:
                    return { label: `${language[this.props.indexLanguage].Settings.day} 21`, value: day }
                case 22:
                    return { label: `${language[this.props.indexLanguage].Settings.day} 22`, value: day }
                case 23:
                    return { label: `${language[this.props.indexLanguage].Settings.day} 23`, value: day }
                case 24:
                    return { label: `${language[this.props.indexLanguage].Settings.day} 24`, value: day }
                case 25:
                    return { label: `${language[this.props.indexLanguage].Settings.day} 25`, value: day }
                case 26:
                    return { label: `${language[this.props.indexLanguage].Settings.day} 26`, value: day }
                case 27:
                    return { label: `${language[this.props.indexLanguage].Settings.day} 27`, value: day }
                case 28:
                    return { label: `${language[this.props.indexLanguage].Settings.day} 28`, value: day }
                case 29:
                    return { label: `${language[this.props.indexLanguage].Settings.day} 29`, value: day }
                case 30:
                    return { label: `${language[this.props.indexLanguage].Settings.day} 30`, value: day }
                case 31:
                    return { label: `${language[this.props.indexLanguage].Settings.day} 31`, value: day }
                default:
                    break;
            }
    }

    componentDidMount() {
        this.configGetByIDCompany();
    }

   

    configGetByIDCompany = () => {
        if (this.state.box_engine_cf.crrApiSocket == undefined || this.state.box_engine_cf.crrApiSocket == "") return;
        fetch(`${this.state.box_engine_cf.crrApiSocket}/api/config/get_info/${this.state.box_engine_cf.idCompany}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                var valueUrl = ""
                if (data.data.data.web_hook != undefined) {
                    valueUrl = data.data.data.web_hook.url
                }
                var temp_thresholds = []
                for (let index = 0; index < this.state.dataCamera.length; index++) {
                    var temperature_threshold = ""
                    if (data.data.data.temp_thresholds !== undefined) {
                        for (let i = 0; i < data.data.data.temp_thresholds.length; i++) {
                            if (this.state.dataCamera[index].id_box_engine == data.data.data.temp_thresholds[i].cam_id) {
                                temperature_threshold = data.data.data.temp_thresholds[i].temperature_threshold
                            }
                        }
                    }

                    temp_thresholds.push({
                        cam_id: this.state.dataCamera[index].id_box_engine,
                        temperature_threshold: temperature_threshold
                    })
                }
                this.setState({
                    webHook: valueUrl,
                    temp_thresholds: temp_thresholds,
                    incrementalChecked: (data.data.data.daysoff && data.data.data.daysoff.enable_accumultion === 1) ? true : false,
                    isMonth: data.data.data.daysoff ? this.monthToString(data.data.data.daysoff.expired_accumulation) : this.state.isMonth,
                    isDay: data.data.data.daysoff.date_increase_daysoff ? this.dayToString(data.data.data.daysoff.date_increase_daysoff) : this.state.isDay,
                    advanceTakeLeave: (data.data.data.daysoff && data.data.data.daysoff.advance_take_leave === 1) ? true : false,
                }, () => {
                    this.setState({
                        dataPost: {
                            'id_company': this.state.box_engine_cf.idCompany,
                            content:
                            {
                                id_staff_auto_increase: this.state.idStaffAutoCreate === true ? 1 : 0,
                                web_hook: {
                                    url: this.state.webHook
                                },
                                temp_thresholds: this.state.temp_thresholds,
                                daysoff: {
                                    enable_accumultion: this.state.incrementalChecked === true ? 1 : 0,
                                    expired_accumulation: this.state.isMonth.value,
                                    date_increase_daysoff: this.state.isDay.value,
                                    advance_take_leave: this.state.advanceTakeLeave === true ? 1 : 0,
                                }
                            },

                        }
                    })
                })
            }
        })
    }
    

    handleSwitch(e, name) {
        let name_property = '';

        if (name === 'idStaffAutoCreate') {
            swal({
                title: this.state.dataConfig == 1 ? language[this.props.indexLanguage].Settings.notifi_change_default : language[this.props.indexLanguage].Settings.notifi_change_auto,
                text: this.state.dataConfig == 1 ? language[this.props.indexLanguage].Settings.notifi_change_default_text : language[this.props.indexLanguage].Settings.notifi_change_auto_text,
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then(switchID => {
                    if (switchID) {
                        name_property = 'id_staff_auto_increase';
                        return this.setState({
                            [name]: e,
                            dataPost: {
                                ...this.state.dataPost,
                                content: {
                                    ...this.state.dataPost.content,
                                    [name_property]: e === true ? 1 : 0,
                                }
                            },
                        }, () => {
                            this.SubmitUrl();
                        })
                    };
                })

        } else {
            swal({
                title: language[this.props.indexLanguage].Text_Notifi.are_you_sure,
                text: language[this.props.indexLanguage].Text_Notifi.notifi_active,
                icon: "warning",
                buttons: {
                    oke: language[this.props.indexLanguage].textButton.oke,
                    cancel: language[this.props.indexLanguage].textButton.close
                },
            }).then(switchName => {
                if (switchName) {
                    if (name === 'incrementalChecked') {
                        name_property = 'enable_accumultion';
                    } else if (name === 'advanceTakeLeave') {
                        name_property = 'advance_take_leave';
                    }
                    return this.setState({
                        [name]: e,
                        dataPost: {
                            ...this.state.dataPost,
                            content: {
                                ...this.state.dataPost.content,
                                daysoff: {
                                    ...this.state.dataPost.content.daysoff,
                                    [name_property]: e === true ? 1 : 0,
                                }
                            }
                        }
                    }, () => {
                        this.SubmitUrl();
                    })
                }
            })

        }
    }

    SubmitUrl = async () => {
        if (this.state.box_engine_cf.crrApiSocket == undefined || this.state.box_engine_cf.crrApiSocket == "") return;

        const result = await Axios({
            method: 'POST',
            url: `${this.state.box_engine_cf.crrApiSocket}/api/config/insert_or_update`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            data: JSON.stringify(this.state.dataPost),
        });
        try {
            if (result.data.status === 10000) {
                swal(language[this.props.indexLanguage].Text_Notifi.success, language[this.props.indexLanguage].Settings.notifi_success, "success", {
                    buttons: false,
                    timer: 1500,
                });
                this.configGetByIDCompany()
            } else if (result.data.status === 10001) {
                swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].Settings.notifi_warning, "warning");
            }
            else {
                swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].Settings.notifi_error, "error");
            }
        } catch (error) {
            console.log(error)
        }
    }

    
    onChangeMonth = (value) => {
        console.log(this.state.isMonth.value?.slice(3))
        let oldMonth = this.state.isMonth.value?.slice(3)
        let nowMonth = new Date().getMonth() + 1;
        let newMonth = value.value.slice(3)
        if (nowMonth < 10) {
            nowMonth = '0' + nowMonth
        }

        if (+oldMonth < +nowMonth){
            //đã chốt tích lũy 
            //Chỉnh sửa này sẽ có hiệu lực từ năm kế tiếp vì phép tích lũy năm ngoái đã được chốt. 
            swal({
                title: language[this.props.indexLanguage].Text_Notifi.info,
                text: language[this.props.indexLanguage].Text_Notifi.date_previous_setting,
                icon: "info",
                buttons: {
                    oke: language[this.props.indexLanguage].textButton.oke,
                    cancel: language[this.props.indexLanguage].textButton.close
                },
            }).then((oke) => {
                if (oke) {
                    this.setState({
                        isMonth: value,
                        dataPost: {
                            ...this.state.dataPost,
                            content: {
                                ...this.state.dataPost.content,
                                daysoff: {
                                    ...this.state.dataPost.content.daysoff,
                                    expired_accumulation: value.value,
                                }
                            }
                        }
                    }, () => {
                        this.SubmitUrl();
                    })
                }
            })
        } else {
            //chua duoc chot
            if (+newMonth <= +nowMonth) {
                //phép tích lũy không đc tính sau khi update
               //Chỉnh sửa này sẽ có hiệu lực từ năm kế tiếp vì hiện tại đã qua thời gian thiết lập
               swal({
                   title: language[this.props.indexLanguage].Text_Notifi.info,
                   text: language[this.props.indexLanguage].Text_Notifi.date_expiration_past,
                   icon: "info",
                   buttons: {
                       oke: language[this.props.indexLanguage].textButton.oke,
                       cancel: language[this.props.indexLanguage].textButton.close
                   },
               }).then((oke) => {
                   if (oke) {
                       this.setState({
                           isMonth: value,
                           dataPost: {
                               ...this.state.dataPost,
                               content: {
                                   ...this.state.dataPost.content,
                                   daysoff: {
                                       ...this.state.dataPost.content.daysoff,
                                       expired_accumulation: value.value,
                                   }
                               }
                           }
                       }, () => {
                           this.SubmitUrl();
                       })
                   }
               })
           } else {
                //phép tích lũy đc tính sau khi update
               //no message
               this.setState({
                   isMonth: value,
                   dataPost: {
                       ...this.state.dataPost,
                       content: {
                           ...this.state.dataPost.content,
                           daysoff: {
                               ...this.state.dataPost.content.daysoff,
                               expired_accumulation: value.value,
                           }
                       }
                   }
               }, () => {
                   this.SubmitUrl();
               })
           }
        }
    }


    componentWillMount() {
        var type = localStorage.getItem("type")
        this.setState({
            type: type,
        },() => {
            const option2 = [
                    { label: `${language[this.props.indexLanguage].Settings.day} 1`, value: 1 },
                    { label: `${language[this.props.indexLanguage].Settings.day} 2`, value: 2 },
                    { label: `${language[this.props.indexLanguage].Settings.day} 3`, value: 3 },
                    { label: `${language[this.props.indexLanguage].Settings.day} 4`, value: 4 },
                    { label: `${language[this.props.indexLanguage].Settings.day} 5`, value: 5 },
                    { label: `${language[this.props.indexLanguage].Settings.day} 6`, value: 6 },
                    { label: `${language[this.props.indexLanguage].Settings.day} 7`, value: 7 },
                    { label: `${language[this.props.indexLanguage].Settings.day} 8`, value: 8 },
                    { label: `${language[this.props.indexLanguage].Settings.day} 9`, value: 9 },
                    { label: `${language[this.props.indexLanguage].Settings.day} 10`, value: 10 },
                    { label: `${language[this.props.indexLanguage].Settings.day} 11`, value: 11 },
                    { label: `${language[this.props.indexLanguage].Settings.day} 12`, value: 12 },
                    { label: `${language[this.props.indexLanguage].Settings.day} 13`, value: 13 },
                    { label: `${language[this.props.indexLanguage].Settings.day} 14`, value: 14 },
                    { label: `${language[this.props.indexLanguage].Settings.day} 15`, value: 15 },
                    { label: `${language[this.props.indexLanguage].Settings.day} 16`, value: 16 },
                    { label: `${language[this.props.indexLanguage].Settings.day} 17`, value: 17 },
                    { label: `${language[this.props.indexLanguage].Settings.day} 18`, value: 18 },
                    { label: `${language[this.props.indexLanguage].Settings.day} 19`, value: 19 },
                    { label: `${language[this.props.indexLanguage].Settings.day} 20`, value: 20 },
                    { label: `${language[this.props.indexLanguage].Settings.day} 21`, value: 21 },
                    { label: `${language[this.props.indexLanguage].Settings.day} 22`, value: 22 },
                    { label: `${language[this.props.indexLanguage].Settings.day} 23`, value: 23 },
                    { label: `${language[this.props.indexLanguage].Settings.day} 24`, value: 24 },
                    { label: `${language[this.props.indexLanguage].Settings.day} 25`, value: 25 },
                    { label: `${language[this.props.indexLanguage].Settings.day} 26`, value: 26 },
                    { label: `${language[this.props.indexLanguage].Settings.day} 27`, value: 27 },
                    { label: `${language[this.props.indexLanguage].Settings.day} 28`, value: 28 },
                    { label: `${language[this.props.indexLanguage].Settings.day} 29`, value: 29 },
                    { label: `${language[this.props.indexLanguage].Settings.day} 30`, value: 30 },
                    { label: `${language[this.props.indexLanguage].Settings.day} 31`, value: 31 },
            ]
            this.setState({
                optionDay: option2
            })
        })
        window.bootstrap_select();
    }


    render() {
        return (
            <div className='m-grid__item m-grid__item--fluid m-wrapper'>
                <div className='m-content p-2'>
                    <div className='m-portlet--tab mb-0'>
                        <div className='m-portlet__body m-portlet__body--no-padding'>
                            <div className='row m-row--no-padding m-row--col-separator-xl'>
                            <div class="col-xl-8 mx-auto">

                                <div className="col-md-12 row" style={{ paddingTop: '15px', marginTop: '15px' }}>
                                    <div className="col-md-4 col-xl-7 mb-3 mb-md-0">
                                        <b>{language[this.props.indexLanguage].Settings.incremental}</b>
                                    </div>
                                    <div className="col-md-8 col-xl-5 mb-4 mb-md-0" style={{display: 'flex', justifyContent: 'flex-end'}}>
                                        <Switch
                                            onChange={(e) => this.handleSwitch(e, 'incrementalChecked')}
                                            offColor="#969696"
                                            onColor="#00c5dc"
                                            offHandleColor="#ffffff"
                                            handleDiameter={17}
                                            onHandleColor="#08f"
                                            height={25}
                                            width={55}
                                            className="react-switch"
                                            checked={this.state.incrementalChecked}
                                        >
                                        </Switch>
                                    </div>
                                </div>
                                <div className="row col-md-12 my-md-4" style={{alignItems: "center", paddingBottom : "15px"}}>
                                    <div className="col-md-4 col-xl-7 mb-3 mb-md-0" style={{textAlign: 'justify'}}>
                                        <div>{language[this.props.indexLanguage].Settings.time_end}</div>
                                        <span>{language[this.props.indexLanguage].Settings.description}</span>
                                    </div>
                                    <div className="col-md-8 col-xl-5" style={{display: 'flex', justifyContent: 'flex-end'}}>
                                        <Select
                                            className="px-0 col-md-6"
                                            value={this.state.isMonth}
                                                options={[{ label: language[this.props.indexLanguage].Month.january, value: '01-01' },
                                                    { label: language[this.props.indexLanguage].Month.february, value: '01-02' },
                                                    { label: language[this.props.indexLanguage].Month.march, value: '01-03' },
                                                    { label: language[this.props.indexLanguage].Month.april, value: '01-04' },
                                                    { label: language[this.props.indexLanguage].Month.may, value: '01-05' },
                                                    { label: language[this.props.indexLanguage].Month.june, value: '01-06' },
                                                    { label: language[this.props.indexLanguage].Month.july, value: '01-07' },
                                                    { label: language[this.props.indexLanguage].Month.august, value: '01-08' },
                                                    { label: language[this.props.indexLanguage].Month.september, value: '01-09' },
                                                    { label: language[this.props.indexLanguage].Month.october, value: '01-10' },
                                                    { label: language[this.props.indexLanguage].Month.november, value: '01-11' },
                                                    { label: language[this.props.indexLanguage].Month.december, value: '01-12' }]}
                                            onChange={(value) => this.onChangeMonth(value)}
                                        />
                                    </div>
                                </div>
                                <h2 className="divider line"></h2>
                                <div className="row col-md-12 my-md-4" style={{alignItems: "center", padding: "15px"}}>
                                    <div className="col-md-4 col-xl-7 mb-3 mb-md-0" style={{textAlign: 'justify'}}>
                                        <div><b>{language[this.props.indexLanguage].Settings.increase_daysoff}</b></div>
                                    </div>
                                    <div className="col-md-8 col-xl-5" style={{display: 'flex', justifyContent: 'flex-end'}}>
                                        <Select
                                            className="px-0 col-md-6"
                                            value={this.state.isDay}
                                            options={this.state.optionDay}
                                            onChange={value => {
                                                this.setState({
                                                    isDay: value,
                                                    dataPost: {
                                                        ...this.state.dataPost,
                                                        content: {
                                                            ...this.state.dataPost.content,
                                                            daysoff: {
                                                                ...this.state.dataPost.content.daysoff,
                                                                date_increase_daysoff: value.value,
                                                            }
                                                        }
                                                    }
                                                }, () => {
                                                    this.SubmitUrl();
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                                <h2 className="divider line"></h2>
                                <div className="col-md-12 row" style={{ paddingTop: '15px', marginTop: '15px', alignItems: "center" }}>
                                    <div className="col-md-4 col-xl-7 mb-3 mb-md-0" style={{textAlign: 'justify'}}  >
                                        <b>{language[this.props.indexLanguage].Settings.advance_take_leave}</b>
                                        <div className='py-3'>
                                            {language[this.props.indexLanguage].Settings.advance_description}
                                        </div>
                                    </div>
                                    <div className="col-md-8 col-xl-5 mb-4 mb-md-0" style={{display: 'flex', justifyContent: 'flex-end'}}>
                                        <Switch
                                            onChange={(e) => this.handleSwitch(e, 'advanceTakeLeave')}
                                            offColor="#969696"
                                            onColor="#00c5dc"
                                            offHandleColor="#ffffff"
                                            handleDiameter={17}
                                            onHandleColor="#08f"
                                            height={25}
                                            width={55}
                                            className="react-switch"
                                            checked={this.state.advanceTakeLeave}
                                        >
                                        </Switch>
                                    </div>
                                </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        indexLanguage: state.changelanguage.indexLanguage,
    }
}
export default connect(mapStateToProps, null)(Setting);