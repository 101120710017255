import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { useSelector } from 'react-redux';
import language from '../../../../../language/language';
import { ExportCSV } from './ExportCSV';
import ViewTable from './ViewTable';
import { formatUsernameFromEmail } from '../../../CommonFunction';


TableReport.propTypes = {
    data: PropTypes.array,
    day_in_month: PropTypes.array,
    report: PropTypes.number,
    start_end: PropTypes.object,
};

TableReport.defaultProps = {
    data: null,
    day_in_month: null,
    report: 0,
    start_end: null,
}

function TableReport(props) {

    const { data, day_in_month, report, start_end } = props;
    const indexLanguage = useSelector(state => state.changelanguage.indexLanguage);

    const [titleExcel, setTitleExcel] = useState('');
    const [fieldName, setFieldName] = useState([]);
    const [sheetName, setSheetName] = useState([]);
    const [fieldHeader, setFieldHeader] = useState([]);
    const [crrDataExcel, setCrrDataExcel] = useState(null);
    const [crrDataView, setCrrDataView] = useState(null);
    const [wsCols, setWsCols] = useState([
        { wch: 15 },
        { wch: 25 },
        { wch: 20 },
        { wch: 20 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
    ])
    const fieldExcel = () => {
        let title = '';
        let fields = null;
        let field_header = null;
        let sheet_name = null;
        let data_compat_split_excel = null;
        let data_compat_split_view = null;
        let ws_col = [
            { wch: 15 },
            { wch: 25 },
            { wch: 20 },
            { wch: 20 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
        ];
        let ws_day_col = [];
        let day_in_month_string = [];
        let data_compat = null;
        let data_process = null;
        switch (report) {
            case 1:
                data_compat = data ? data.map(map => ({ id_staff: map.id_staff, name: map.name, department: map.department, time_in: map.time, minute: map.minute.toString(), name_shift: map.name_shift, off: map.off, text_time_in: map.red_text_time_in })) : null;
                break;
            case 2:
                data_compat = data ? data.map(map => ({ id_staff: map.id_staff, name: map.name, department: map.department, time_out: map.time, minute: map.minute.toString(), name_shift: map.name_shift, off: map.off, text_time_out: map.red_text_time_out })) : null;
                break;
            case 3:
                data_compat = data ? data.map(map => ({ id_staff: map.id_staff, name: map.name, department: map.department, time_check_in: map.time, name_shift: map.name_shift, off: map.off })) : null;
                break;
            case 4:
                data_compat = data ? data.map(map => ({ id_staff: map.id_staff, name: map.name, department: map.department, date: map.date, name_shift: map.name_shift, off: map.off })) : null;
                break;
            case 5:
                if (data) {
                    data.forEach(value => {
                        delete value.dof;
                        delete value.dof_arr;
                        delete value.holiday;
                        delete value.name_department;
                        delete value.object_id;
                        delete value.shifts;
                        delete value.time_work;
                        delete value.timekeepings;
                        delete value.work;
                    });
                }
                if (day_in_month) {
                    day_in_month.forEach(value => {
                        ws_day_col.push({ wch: 4 });
                        day_in_month_string.push(value.toString())
                    })
                }
                data_compat = data ? data.map(map => ({ ...map, total_time_ot: map.total_time_ot.toString() })) : null;
                break;
            case 6:
                data_compat = data ? data.map(map => ({ ...map, number_in_out: map.number_in_out.toString() })) : null;
                break;
            case 7:
                data_compat = data ? data.map(map => ({
                    id_staff: map.id_staff, name: map.name, department: map.department, date: map.date, day: map.day,
                    time_in: map.time_in, time_out: map.time_out, in_soon: map.in_soon, in_late: map.in_late, out_soon: map.out_soon, out_late: map.out_late,
                    work: map.work, name_shift: map.name_shift, note: map.reason_text,
                })) : null;
                break;
            case 8:
                if (data) {
                    data.forEach(value => {
                        delete value.dof;
                        delete value.dof_arr;
                        delete value.holiday;
                        delete value.name_department;
                        delete value.object_id;
                        delete value.shifts;
                        delete value.time_work;
                        delete value.timekeepings;
                        delete value.work;
                    });
                }
                if (day_in_month) {
                    day_in_month.forEach(value => {
                        ws_day_col.push({ wch: 4 });
                        day_in_month_string.push(value.toString())
                    })
                }
                data_compat = data ? data : null;
                break;
            case 10:
                if (data) {
                    data.forEach(value => {
                        delete value.id_reason;
                    });
                }
                data_compat = data ? data : null;
                break;
            case 11:
                data_compat = data ? data : null;
                break;
            case 12:
                data_compat = data ? data.map(map => ({ id_staff: map.id_staff, name: map.name, department: map.department, date: map.date, time_in: map.time_in, time_out: map.time_out, place: map.place })) : null;
                break;
            case 13:
                data_compat = data ? data.map(map => ({ id_staff: map.id_staff, name: map.name, department: map.department, date: map.date, name_shift: map.name_shift, off: map.off })) : null;
                break;
            case 14:
                data_compat = data ? data : null;
                break;
            default:
                break;
        }
        data_process = processData(data_compat);
        switch (report) {
            case 1:
                title = `${language[indexLanguage].Report.report_1} ${language[indexLanguage].Report.from} ${moment(start_end.start_date).format('DD-MM-YYYY')} ${language[indexLanguage].Report.to} ${moment(start_end.end_date).format('DD-MM-YYYY')}`;
                fields = ['id_staff', 'name', 'department', 'time_in', 'minute', 'name_shift', 'off'];
                field_header = [
                    language[indexLanguage].Report.name,
                    language[indexLanguage].Report.department,
                    language[indexLanguage].Report.time_in,
                    language[indexLanguage].Report.minute,
                    language[indexLanguage].Report.shift,
                    language[indexLanguage].Report.reason,
                ]
                break;
            case 2:
                title = `${language[indexLanguage].Report.report_2} ${language[indexLanguage].Report.from} ${moment(start_end.start_date).format('DD-MM-YYYY')} ${language[indexLanguage].Report.to} ${moment(start_end.end_date).format('DD-MM-YYYY')}`;
                fields = ['id_staff', 'name', 'department', 'time_out', 'minute', 'name_shift', 'off'];
                field_header = [
                    language[indexLanguage].Report.name,
                    language[indexLanguage].Report.department,
                    language[indexLanguage].Report.time_out,
                    language[indexLanguage].Report.minute,
                    language[indexLanguage].Report.shift,
                    language[indexLanguage].Report.reason,
                ]
                break;
            case 3:
                title = `${language[indexLanguage].Report.report_3} ${language[indexLanguage].Report.from} ${moment(start_end.start_date).format('DD-MM-YYYY')} ${language[indexLanguage].Report.to} ${moment(start_end.end_date).format('DD-MM-YYYY')}`;
                fields = ['id_staff', 'name', 'department', 'time_check_in', 'name_shift', 'off'];
                field_header = [
                    language[indexLanguage].Report.name,
                    language[indexLanguage].Report.department,
                    language[indexLanguage].Report.time_check_in,
                    language[indexLanguage].Report.shift,
                    language[indexLanguage].Report.reason,
                ]
                break;
            case 4:
                title = `${language[indexLanguage].Report.report_4} ${language[indexLanguage].Report.from} ${moment(start_end.start_date).format('DD-MM-YYYY')} ${language[indexLanguage].Report.to} ${moment(start_end.end_date).format('DD-MM-YYYY')}`;
                fields = ['id_staff', 'name', 'department', 'date', 'name_shift', 'off'];
                field_header = [
                    language[indexLanguage].Report.name,
                    language[indexLanguage].Report.department,
                    language[indexLanguage].Report.date,
                    language[indexLanguage].Report.shift,
                    language[indexLanguage].Report.reason,
                ]
                break;
            case 5:
                title = `${language[indexLanguage].Report.report_5} ${language[indexLanguage].Report.from} ${moment(start_end.start_date).format('DD-MM-YYYY')} ${language[indexLanguage].Report.to} ${moment(start_end.end_date).format('DD-MM-YYYY')}`;
                fields = ['id_staff', 'name', 'department'].concat(day_in_month_string).concat(['total_time_ot']);
                field_header = [
                    language[indexLanguage].Report.name,
                    language[indexLanguage].Report.department,
                ].concat(day_in_month).concat([language[indexLanguage].Report.total_time_ot]);

                ws_col = [
                    { wch: 15 },
                    { wch: 20 },
                    { wch: 20 },
                ].concat(ws_day_col).concat([{ wch: 15 }])
                break;
            case 6:
                title = `${language[indexLanguage].Report.report_6} ${language[indexLanguage].Report.from} ${moment(start_end.start_date).format('DD-MM-YYYY')} ${language[indexLanguage].Report.to} ${moment(start_end.end_date).format('DD-MM-YYYY')}`;
                fields = ['id_staff', 'name', 'department', 'date', 'day', 'number_in_out', 'time'];
                field_header = [
                    language[indexLanguage].Report.name,
                    language[indexLanguage].Report.department,
                    language[indexLanguage].Report.date,
                    language[indexLanguage].Report.day,
                    language[indexLanguage].Report.number_in_out,
                    language[indexLanguage].Report.time,
                ];
                ws_col = [{ wch: 15 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 50 }]
                break;
            case 7:
                title = `${language[indexLanguage].Report.report_7} ${language[indexLanguage].Report.month} ${new Date(start_end.start_date).getMonth() + 1}`;
                fields = ['id_staff', 'name', 'department', 'date', 'day', 'time_in', 'time_out', 'in_soon', 'in_late', 'out_soon', 'out_late', 'work', 'name_shift', 'note'];
                field_header = [
                    language[indexLanguage].Report.name,
                    language[indexLanguage].Report.department,
                    language[indexLanguage].Report.date,
                    language[indexLanguage].Report.day,
                    language[indexLanguage].Report.time_in,
                    language[indexLanguage].Report.time_out,
                    language[indexLanguage].Report.in_soon,
                    language[indexLanguage].Report.in_late,
                    language[indexLanguage].Report.out_soon,
                    language[indexLanguage].Report.out_late,
                    language[indexLanguage].Report.work,
                    language[indexLanguage].Report.shift,
                    language[indexLanguage].Report.note,
                ];
                break;
            case 8:
                title = `${language[indexLanguage].Report.report_8} ${language[indexLanguage].Report.month} ${new Date(start_end.start_date).getMonth() + 1}`;
                fields = ['id_staff', 'name', 'department'].concat(day_in_month_string).concat(['work_day_off', 'work_business', 'total_work']);
                field_header = [
                    language[indexLanguage].Report.name,
                    language[indexLanguage].Report.department,
                    // language[indexLanguage].Report.shift,
                ].concat(day_in_month).concat([language[indexLanguage].Report.day_off, language[indexLanguage].Report.business, language[indexLanguage].Report.total_work]);

                ws_col = [
                    { wch: 15 },
                    // { wch: 25 },
                    { wch: 20 },
                    { wch: 15 },
                ].concat(ws_day_col).concat([{ wch: 15 }, { wch: 15 }, { wch: 15 }])
                break;
            case 9:
                title = `${language[indexLanguage].Report.report_9} ${language[indexLanguage].Report.month} ${new Date(start_end.start_date).getMonth() + 1}`;
                break;
            case 10:
                title = `${language[indexLanguage].Report.report_10} ${language[indexLanguage].Report.from} ${moment(start_end.start_date).format('DD-MM-YYYY')} ${language[indexLanguage].Report.to} ${moment(start_end.end_date).format('DD-MM-YYYY')}`;
                fields = ['id_staff', 'name', 'department', 'from_date', 'to_date', 'reason'];
                field_header = [
                    language[indexLanguage].Report.name,
                    language[indexLanguage].Report.department,
                    language[indexLanguage].Report.from_date,
                    language[indexLanguage].Report.to_date,
                    language[indexLanguage].Report.reason_off,
                ]
                break;
            case 11:
                title = `${language[indexLanguage].Report.report_11} ${language[indexLanguage].Report.from} ${moment(start_end.start_date).format('DD-MM-YYYY')} ${language[indexLanguage].Report.to} ${moment(start_end.end_date).format('DD-MM-YYYY')}`;
                fields = ['id_staff', 'name', 'department', 'from_date', 'to_date', 'business_place', 'business_content'];
                field_header = [
                    language[indexLanguage].Report.name,
                    language[indexLanguage].Report.department,
                    language[indexLanguage].Report.from_date,
                    language[indexLanguage].Report.to_date,
                    language[indexLanguage].Report.business_place,
                    language[indexLanguage].Report.business_content,
                ]
                break;
            case 12:
                title = `${language[indexLanguage].Report.report_12} ${language[indexLanguage].Report.from} ${moment(start_end.start_date).format('DD-MM-YYYY')} ${language[indexLanguage].Report.to} ${moment(start_end.end_date).format('DD-MM-YYYY')}`;
                fields = ['id_staff', 'name', 'department', 'date', 'time_in', 'time_out', 'place'];
                field_header = [
                    language[indexLanguage].Report.name,
                    language[indexLanguage].Report.department,
                    language[indexLanguage].Report.date,
                    language[indexLanguage].Report.time_in,
                    language[indexLanguage].Report.time_out,
                    language[indexLanguage].Report.place,
                ]
                break;
            case 13:
                title = `${language[indexLanguage].Report.report_13} ${language[indexLanguage].Report.from} ${moment(start_end.start_date).format('DD-MM-YYYY')} ${language[indexLanguage].Report.to} ${moment(start_end.end_date).format('DD-MM-YYYY')}`;
                fields = ['id_staff', 'name', 'department', 'date', 'name_shift', 'off'];
                field_header = [
                    language[indexLanguage].Report.name,
                    language[indexLanguage].Report.department,
                    language[indexLanguage].Report.date,
                    language[indexLanguage].Report.shift,
                    language[indexLanguage].Report.reason,
                ]
                break;
            case 14:
                title = `${language[indexLanguage].Report.report_14} ${language[indexLanguage].Report.from} ${moment(start_end.start_date).format('DD-MM-YYYY')} ${language[indexLanguage].Report.to} ${moment(start_end.end_date).format('DD-MM-YYYY')}`;
                fields = ['id_staff', 'username', 'name', 'department', 'date', 'day', 'time_in', 'time_out', 'camera_in', 'camera_out', 'in_late', 'shift', 'before_compliance', 'after_compliance', 'status_confirm', 'confirm_person', 'confirm_time'];
                field_header = [
                    'Username',
                    language[indexLanguage].Report.name,
                    language[indexLanguage].Report.department,
                    language[indexLanguage].Report.date,
                    language[indexLanguage].Timekeeping.day_of_week,
                    language[indexLanguage].Timekeeping.time_in,
                    language[indexLanguage].Timekeeping.time_out,
                    "Vị trí vào",
                    "Vị trí ra",
                    language[indexLanguage].Timekeeping.in_late,
                    language[indexLanguage].Report.shift,
                    language[indexLanguage].Report.status_before,
                    language[indexLanguage].Report.status_after,
                    language[indexLanguage].Report.status_confirm,
                    "CBQL",
                    "Thời gian xác nhận"
                ]
                ws_col = [
                    { wch: 10 },
                    { wch: 10 },
                    { wch: 20 },
                    { wch: 25 },
                    { wch: 20 },
                    { wch: 15 },
                    { wch: 15 },
                    { wch: 15 },
                    { wch: 15 },
                    { wch: 15 },
                    { wch: 15 },
                    { wch: 15 },
                    { wch: 15 },
                    { wch: 15 },
                    { wch: 15 },
                    { wch: 10 },
                    { wch: 15 },
                ];
                break;
            default:
                break;
        }
        sheet_name = ['data'];
        // sheet_name = data_process ? data_process.keys : [];
        // data_compat_split_excel = data_process ? data_process.values : null;
        data_compat_split_excel = data_compat ? [data_compat] : null;
        data_compat_split_view = data_compat;
        setWsCols(ws_col);
        setTitleExcel(title);
        setFieldName(fields);
        setFieldHeader(field_header)
        setSheetName(sheet_name);
        setCrrDataExcel(data_compat_split_excel);
        setCrrDataView(data_compat_split_view);
    }
    const processData = (data) => {
        if (!data) return null;
        function groupBy(arr, property) {
            return arr.reduce(function (memo, x) {
                if (!memo[x[property]]) { memo[x[property]] = []; }
                memo[x[property]].push(x);
                return memo;
            }, {});
        }

        const result = groupBy(data, 'department');
        return {
            keys: Object.keys(result),
            values: Object.values(result),
        }
    }
    // const handleExportExcelDetail = () => {
    //     setLoading(true);
    //     setTimeout(() => {
    //         const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    //         const fileExtension = '.xlsx';
    //         const dom = document.getElementById('tableData');
    //         const ws = XLSX.utils.table_to_sheet(dom);
    //         ws['!cols'] = [
    //             { wch: 15 },
    //             { wch: 25 },
    //             { wch: 20 },
    //             { wch: 20 },
    //             { wch: 15 },
    //             { wch: 15 },
    //             { wch: 15 },
    //             { wch: 15 },
    //             { wch: 15 },
    //             { wch: 15 },
    //             { wch: 15 },
    //             { wch: 15 },
    //             { wch: 15 },
    //             { wch: 15 },
    //         ];
    //         const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    //         const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    //         const data = new Blob([excelBuffer], { type: fileType });
    //         FileSaver.saveAs(data, titleExcel + fileExtension);
    //         setLoading(false);
    //     }, 1);
    // }
    const bulletedReportDetail = (data) => {
        if (!data) return;
        const result = data.map((map, i) => {
            return (
                <tr key={i}>
                    {map.key === 1 &&
                        <>
                            <td style={{ verticalAlign: 'middle', borderBottomColor: '#000' }} rowSpan={map.number_shift * 2 + 2} >{map.id_staff.toString()}</td>
                            <td style={{ verticalAlign: 'middle', borderBottomColor: '#000' }} rowSpan={map.number_shift * 2 + 2} >{formatUsernameFromEmail(map.email)}</td>
                            <td style={{ verticalAlign: 'middle', borderBottomColor: '#000' }} rowSpan={map.number_shift * 2 + 2} >{map.name}</td>
                            <td style={{ verticalAlign: 'middle', borderBottomColor: '#000' }} rowSpan={map.number_shift * 2 + 2} >{map.department}</td>
                        </>
                    }
                    <td style={map.key === 9 ? { verticalAlign: 'middle', textAlign: 'center', borderBottomColor: '#000' } : { verticalAlign: 'middle', textAlign: 'center' }} >{map.index}</td>
                    {
                        day_in_month.map((value, index) => {
                            return <td style={map.key === 9 ? { verticalAlign: 'middle', width: 50, borderBottomColor: '#000' } : { verticalAlign: 'middle', width: 50 }} key={index}>{map[value].toString()}</td>
                        })
                    }
                    {map.key === 1 &&
                        <>
                            <td style={{ verticalAlign: 'middle', textAlign: 'center', borderBottomColor: '#000' }} rowSpan={map.number_shift * 2 + 2} >{map.work_day_off}</td>
                            <td style={{ verticalAlign: 'middle', textAlign: 'center', borderBottomColor: '#000' }} rowSpan={map.number_shift * 2 + 2} >{map.work_business}</td>
                            <td style={{ verticalAlign: 'middle', textAlign: 'center', borderBottomColor: '#000' }} rowSpan={map.number_shift * 2 + 2} >{map.total_work}</td>
                            <td style={{ verticalAlign: 'middle', textAlign: 'center', borderBottomColor: '#000' }} rowSpan={map.number_shift * 2 + 2} >{map.total_work_time}</td>
                            <td style={{ verticalAlign: 'middle', textAlign: 'center', borderBottomColor: '#000' }} rowSpan={map.number_shift * 2 + 2} >{map.number_late}</td>
                            <td style={{ verticalAlign: 'middle', textAlign: 'center', borderBottomColor: '#000' }} rowSpan={map.number_shift * 2 + 2} >{map.number_late_time}</td>
                            <td style={{ verticalAlign: 'middle', textAlign: 'center', borderBottomColor: '#000' }} rowSpan={map.number_shift * 2 + 2} >{map.number_soon}</td>
                            <td style={{ verticalAlign: 'middle', textAlign: 'center', borderBottomColor: '#000' }} rowSpan={map.number_shift * 2 + 2} >{map.number_soon_time}</td>
                            <td style={{ verticalAlign: 'middle', textAlign: 'center', borderBottomColor: '#000' }} rowSpan={map.number_shift * 2 + 2} >{map.ot_in_week}</td>
                            <td style={{ verticalAlign: 'middle', textAlign: 'center', borderBottomColor: '#000' }} rowSpan={map.number_shift * 2 + 2} >{map.ot_last_week}</td>
                            <td style={{ verticalAlign: 'middle', textAlign: 'center', borderBottomColor: '#000' }} rowSpan={map.number_shift * 2 + 2} >{map.ot_holiday}</td>
                        </>
                    }
                </tr>
            )
        })
        return result;
    }
    useEffect(() => {
        if (report) {
            fieldExcel();
        }
    }, [report, data]);

    return (
        <div>
            {
                report === 9 ?
                    <>
                        {/* <div className='text-right py-2'>
                            <Button disabled={loading} style={{ width: 120 }} variant="success" onClick={() => handleExportExcelDetail()} >
                                {
                                    loading ? <BeatLoader color={'#fff'} loading={true} css={override} size={10} />
                                        :
                                        language[indexLanguage].textButton.export_report
                                }
                            </Button>
                        </div> */}
                        <div style={{ position: 'absolute', right: 0, top: "-52px" }} className='text-right my-2'>
                            <ReactHTMLTableToExcel
                                className='btn btn-success'
                                table='tableData'
                                filename={titleExcel}
                                sheet='tablexls'
                                buttonText={language[indexLanguage].textButton.export_report}
                            />
                        </div>
                        <div className='table-responsive text-nowrap table_scroll' style={{ border: '1px solid #dee2d6', borderTop: 'none' }}>
                            <table className='table table-bordered bg-white table-hover table-checkable dataTable no-footer dtr-inline collapsed mb-0' id='tableData'>
                                <thead>
                                    <tr>
                                        <th style={{ whiteSpace: 'nowrap', verticalAlign: 'middle', width: 100, borderBottomColor: '#000' }}>ID</th>
                                        <th style={{ whiteSpace: 'nowrap', verticalAlign: 'middle', borderBottomColor: '#000' }}>Username</th>
                                        <th style={{ whiteSpace: 'nowrap', verticalAlign: 'middle', borderBottomColor: '#000' }}>{language[indexLanguage].Report.name}</th>
                                        <th style={{ whiteSpace: 'nowrap', verticalAlign: 'middle', borderBottomColor: '#000' }}>{language[indexLanguage].Report.department}</th>
                                        <th style={{ whiteSpace: 'nowrap', verticalAlign: 'middle', borderBottomColor: '#000', textAlign: 'center' }}>{language[indexLanguage].Report.index}</th>
                                        {
                                            day_in_month.map((value, index) => {
                                                return <th style={{ whiteSpace: 'nowrap', verticalAlign: 'middle', textAlign: 'center', borderBottomColor: '#000' }} key={index}>{value.toString()}</th>
                                            })
                                        }
                                        <th style={{ whiteSpace: 'nowrap', verticalAlign: 'middle', width: 100, textAlign: 'center', borderBottomColor: '#000' }}>{language[indexLanguage].Report.day_off}</th>
                                        <th style={{ whiteSpace: 'nowrap', verticalAlign: 'middle', width: 100, textAlign: 'center', borderBottomColor: '#000' }}>{language[indexLanguage].Report.business}</th>
                                        <th style={{ whiteSpace: 'nowrap', verticalAlign: 'middle', width: 100, textAlign: 'center', borderBottomColor: '#000' }}>{language[indexLanguage].Report.total_work}</th>
                                        <th style={{ whiteSpace: 'nowrap', verticalAlign: 'middle', width: 100, textAlign: 'center', borderBottomColor: '#000' }}>{language[indexLanguage].Report.total_work_time}</th>
                                        <th style={{ whiteSpace: 'nowrap', verticalAlign: 'middle', width: 100, textAlign: 'center', borderBottomColor: '#000' }}>{language[indexLanguage].Report.number_late}</th>
                                        <th style={{ whiteSpace: 'nowrap', verticalAlign: 'middle', width: 100, textAlign: 'center', borderBottomColor: '#000' }}>{language[indexLanguage].Report.late_minute}</th>
                                        <th style={{ whiteSpace: 'nowrap', verticalAlign: 'middle', width: 100, textAlign: 'center', borderBottomColor: '#000' }}>{language[indexLanguage].Report.number_soon}</th>
                                        <th style={{ whiteSpace: 'nowrap', verticalAlign: 'middle', width: 100, textAlign: 'center', borderBottomColor: '#000' }}>{language[indexLanguage].Report.soon_minute}</th>
                                        <th style={{ whiteSpace: 'nowrap', verticalAlign: 'middle', width: 100, textAlign: 'center', borderBottomColor: '#000' }}>{language[indexLanguage].Report.ot_in_week}</th>
                                        <th style={{ whiteSpace: 'nowrap', verticalAlign: 'middle', width: 100, textAlign: 'center', borderBottomColor: '#000' }}>{language[indexLanguage].Report.ot_late_week}</th>
                                        <th style={{ whiteSpace: 'nowrap', verticalAlign: 'middle', width: 100, textAlign: 'center', borderBottomColor: '#000' }}>{language[indexLanguage].Report.ot_holiday}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {bulletedReportDetail(data)}
                                </tbody>

                            </table>
                        </div>
                    </>
                    :
                    <>
                        <ExportCSV csvData={crrDataExcel} fileName={titleExcel} wsCols={wsCols} fields={fieldName} sheetName={sheetName} />
                        <ViewTable csvData={crrDataView} fields={fieldName} fieldHeader={fieldHeader} />
                    </>
            }

        </div>
    );
}

export default TableReport;