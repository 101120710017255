import PropTypes from 'prop-types';
import React from 'react';
import { PulseLoader } from 'react-spinners';
import { css } from '@emotion/core';

LoadingOverLay.propTypes = {
    loading: PropTypes.bool,
};

LoadingOverLay.defaultProps = {
    loading: true,
}

export const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

function LoadingOverLay(props) {
    const { loading } = props;
    return (
        <PulseLoader
            css={override}
            sizeUnit={"px"}
            size={12}
            margin={'2px'}
            color={'#36D7B7'}
            loading={loading}
        />
    );
}

export default LoadingOverLay;