import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getListDepartmentSelect, getListShiftActiveSelect, getListDepartmentSupervisorSelectAll } from '../../../../api';
import language from '../../../../language/language';
import Report from './Report';
import TimekeepingDetail from './TimekeepingDetail';
import TimekeepingTotal from './TimekeepingTotal';
import ReactGA from 'react-ga';
import Header from '../../../layouts/Header';

Timekeeping.propTypes = {

};

function Timekeeping() {

    const indexLanguage = useSelector(state => state.changelanguage.indexLanguage);
    const box_engine_cf = useSelector(state => state.boxai.box_engine_cf);
    const token = useSelector(state => state.isLogin.access_token);

    const id_staff = localStorage.getItem('id_user');
    const type = localStorage.getItem("type");
    const dataRole = useSelector(state => state.role.role);

    const [tab, setTab] = useState(1);
    const [crrData, setCrrData] = useState({});

    const fetchData = () => {
        const admin = dataRole.indexOf(type + '/timekeeping:view_all') !== -1;
        const get_list_shift = getListShiftActiveSelect(box_engine_cf.crrApiSocket, box_engine_cf.idCompany, token);
        const get_list_department = !admin ? getListDepartmentSupervisorSelectAll(box_engine_cf.crrApiSocket, id_staff, token) : getListDepartmentSelect(box_engine_cf.crrApiSocket, box_engine_cf.idCompany, token);
        Axios.all([get_list_shift, get_list_department]).then(Axios.spread((...res) => {
            if(res[1].length <=2 && res[1].length > 0) {
                res[1].shift();
            }
            setCrrData({
                ...crrData,
                list_shift: res[0],
                list_department: res[1],
            })
            
        })).catch(errors => {
            // react on errors.
        })
    }
    useEffect(() => {
        if (box_engine_cf) {
            fetchData()
        }
    }, [box_engine_cf]);

    useEffect(() => {
        ReactGA.event({
            category: tab === 1 ? 'Bao cao tong hop' : tab === 2 ? 'Bao cao chi tiet' : 'Bao cao',
            action: tab === 1 ? 'Xem bao cao tong hop' : tab === 2 ? 'Xem bao cao chi tiet' : 'Xem tung bao cao',
        });
    }, [tab])
   
    return (
        <>
        <Header />
        <div className={"m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body "}>
        <div className='m-grid__item m-grid__item--fluid m-wrapper p-2' id='timekeeping'>
            <div className='m-content' id='timekeepingPersonal'>
                <div className='m-portlet m-portlet--tab mb-0'>
                    <ul className='nav nav-tabs' role='tablist' style={{ 'padding': '15px 20px 0px 20px', marginBottom: '15px' }}>
                        <li className='nav-item' onClick={() => setTab(1)}>
                            <a className='nav-link active show' data-toggle='tab' href='#' data-target='#m_tabs_1_1'>{language[indexLanguage].Timekeeping.table_summary}</a>
                        </li>
                        <li className='nav-item' onClick={() => setTab(2)}>
                            <a className='nav-link' data-toggle='tab' href='#m_tabs_1_2'>{language[indexLanguage].Timekeeping.table_detail}</a>
                        </li>

                        <li className='nav-item' onClick={() => setTab(3)}>
                            <a className='nav-link' data-toggle='tab' href='#m_tabs_1_3'>{language[indexLanguage].Timekeeping.report}</a>
                        </li>
                    </ul>
                    <div className='m-form m-form--fit m-form--label-align-right' style={{paddingBottom: "10px"}}>
                        <div className='m-portlet__body pl-3 pr-3 pt-0'>
                            <div className='tab-content'>
                                <div className='tab-pane active show' id='m_tabs_1_1' role='tabpanel'>
                                    {tab === 1 && <TimekeepingTotal data={crrData}/>}
                                </div>
                                <div className='tab-pane' id='m_tabs_1_2' role='tabpanel'>
                                    {tab === 2 && <TimekeepingDetail data={crrData} />}
                                </div>
                                <div className='tab-pane' id='m_tabs_1_3' role='tabpanel'>
                                    {tab === 3 && <Report data={crrData} />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </>
    );
}

export default Timekeeping;
