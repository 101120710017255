export const REFRESH_ON = 'REFRESH_ON';
export const REFRESH_OFF = 'REFRESH_OFF';
export function refreshOn(){
    return {
        type: REFRESH_ON,
    }
}
export function refreshOff(){
    return {
        type: REFRESH_OFF
    }
}
