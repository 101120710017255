import { ClickAwayListener } from '@material-ui/core';
import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ListNotification from './ListNotification';
import ApprovalForm1 from '../screen/LeaveOfAbsenceLetter/leaveOfAbsenceLetter/components/approvalForm';
import ApprovalForm2 from '../screen/LeaveOfAbsenceLetter/signupForOvertime/components/approvalForm';
import ApprovalForm3 from '../screen/LeaveOfAbsenceLetter/goOnBussiness/components/approvalForm';
import ApprovalForm4 from '../screen/LeaveOfAbsenceLetter/goLateLeaveEarly/components/approvalForm';
import ApprovalForm5 from '../screen/LeaveOfAbsenceLetter/timekeepingCompensate/components/approvalForm';
import ReactGA from 'react-ga';
import { refreshOn } from '../../actions/letterNotify';


function Notifications() {

    const box_engine_cf = useSelector(state => state.boxai.box_engine_cf);
    const token = useSelector(state => state.isLogin.access_token);

    const dispatch = useDispatch();

    const [crrData,setCrrData] = useState([]);
    const [dataNotification,setDataNotification] = useState(null);
    const [open,setOpen] = useState(false);
    const [dataModal,setDataModal] = useState(null);
    const [showModal,setShowModal] = useState(false);

    const userData = JSON.parse(localStorage.getItem('data_user'));
    const [initialNotification,setInitialNotification] = useState({
        id_staff: userData ? userData.id_staff_notification : 0,
        id_company: 0,
        index: 1,
        item_per_page: 10,
        type_history: 1,
    })

    const [unread,setUnread] = useState(null);
    const [typeForm,setTypeForm] = useState(0);

    const getDataNotification = async () => {
        const result = await Axios({
            method:'POST',
            url: `${box_engine_cf.crrApiSocket}/api/staff/app/message`,
            headers:{
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            data: initialNotification,
        }) 
        if(result.data){
            if(result.data.status === 10000){
                const dataFilter = result.data.data
                setCrrData(dataFilter);
                setUnread(result.data.count_unseen);
            }
        }
    }

    const getDataNotificationScroll = async (items) => {
        const data = {
            ...dataNotification,
            item_per_page: dataNotification.item_per_page + items,
        }
        setDataNotification(data);

        const result = await Axios({
            method:'POST',
            url: `${box_engine_cf.crrApiSocket}/api/staff/app/message`,
            headers:{
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            data: data,
        }) 
        if(result.data.status === 10000){
            const dataFilter = result.data.data;
            setCrrData(dataFilter);
            setUnread(result.data.count_unseen);
        }
    }

    const handleUnread = (index) => {
        setUnread(index);
    }

    const handleClick = () => {
        ReactGA.event({
            category: 'Thong bao',
            action: 'Xem thong bao',
          });
        setOpen((prev) => !prev);
      };
    
    const handleClickAway = () => {
        setOpen(false);
    };

    const handleModal = (show,data,type) => {
        setShowModal(show);
        setDataModal(data);
        setTypeForm(type);
    }
    const closeModal = () => {
        setShowModal(false);
        setDataModal(null);
    }
    const onRefresh = () => {
        dispatch(refreshOn());
    }

    useEffect(() => {
        setInitialNotification({
            ...initialNotification,
            id_company: box_engine_cf.idCompany,
            id_staff: userData ? userData.id_staff_notification : 0,
            // id_staff: 0,
        });
    }, [box_engine_cf]);
    
    useEffect(() => {
        //Call API
        if(initialNotification.id_company && initialNotification.id_staff){
            getDataNotification();
            setDataNotification(initialNotification);
        }
    }, [initialNotification]);

    return (
        <div className='notification' style={{ padding: '15px' }}>
            {
                unread > 0 &&
                <div className='notification-unread'>{unread}</div>
            }
            <ClickAwayListener onClickAway={handleClickAway}>
               <div>
               <a style={{cursor: 'pointer'}} onClick={handleClick}>
                    <img src="https://img.icons8.com/ios/30/000000/appointment-reminders.png" />
                </a>
                {open ? (
                    <ListNotification data={crrData} scrollData={getDataNotificationScroll} setUnread={handleUnread} handleClick={handleClick} handleModal={handleModal}/>
                ) : null}
               </div>
            </ClickAwayListener>
            {
                typeForm === 1 && <ApprovalForm1 show={showModal} data={dataModal} onHide={closeModal} onRefresh={() => onRefresh()}/>
            }
            {
                typeForm === 2 && <ApprovalForm2 show={showModal} data={dataModal} onHide={closeModal} onRefresh={() => onRefresh()}/>
            }
            {
                typeForm === 3 && <ApprovalForm3 show={showModal} data={dataModal} onHide={closeModal} onRefresh={() => onRefresh()}/>
            }
            {
                typeForm === 4 && <ApprovalForm4 show={showModal} data={dataModal} onHide={closeModal} onRefresh={() => onRefresh()}/>
            }
            {
                typeForm === 5 && <ApprovalForm5 show={showModal} data={dataModal} onHide={closeModal} onRefresh={() => onRefresh()}/>
            }
        </div>
    );
}

export default Notifications;