import Axios from "axios"
import language from "../language/language";
import store from "../store";

const indexLanguage = store.getState().changelanguage.indexLanguage;

const all_shift = {
    title: language[indexLanguage]['API'].all_shift,
    value: -1,
}

const all_department = {
    title: language[indexLanguage]['API'].all_department,
    value: -1,
}
const all_reason = {
    title: language[indexLanguage]['API'].all_reason,
    value: -1,
}

export const getListShiftActiveSelect = async (url, id_company, token) => {
    const result = await Axios({
        method: 'GET',
        url: `${url}/api/shift_work/${id_company}/get_by_active/1`,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    });
    if (result.data.status === 10000) {
        let data = result.data.data.map(map => ({
            title: map.name,
            value: map.id,
        }));
        data.unshift(all_shift);
        return data;
    } else {
        return [all_shift];
    }
}

export const getListDepartmentSelect = async (url, id_company, token) => {
    const result = await Axios({
        method: 'GET',
        url: `${url}/api/department/${id_company}/get_all`,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    });
    if (result.data.status === 10000) {
        let data = result.data.data.map(map => ({
            title: map.name,
            value: map.id,
            code: map.code
        }));
        data.unshift(all_department);
        return data;
    } else {
        return [all_department];
    }
}
export const getListDepartmentSupervisorSelect = async (url, id_supervisor, token) => {
    if (!id_supervisor) return console.log('id staff not number');
    const result = await Axios({
        method: 'GET',
        url: `${url}/api/department/get_by_supervisor/${parseInt(id_supervisor)}`,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    });
    if (result.data.status === 10000) {
        let data = result.data.data.map(map => ({
            title: map.name,
            value: map.id,
        }));
        return data;
    } else {
        return [];
    }
}
export const getListDepartmentSupervisorSelectAll = async (url, id_supervisor, token) => {
    if (!id_supervisor) return console.log('id staff not number');
    const result = await Axios({
        method: 'GET',
        url: `${url}/api/department/get_by_supervisor/${parseInt(id_supervisor)}`,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    });
    if (result.data.status === 10000) {
        let data = result.data.data.map(map => ({
            title: map.name,
            value: map.id,
            code: map.code
        }));
        data.unshift(all_department);
        return data;
    } else {
        return [all_department];
    }
}

export const getApiOverviewValue = async (url, dataPost, token) => {
    const result = await Axios({
        method: 'POST',
        url: `${url}/api/time_keeping/overview_v2`,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        },
        data: dataPost
    });
    if (result.data.status === 10000) {
        return {
            data: result.data.data,
            count: result.data.count,
        };
    } else {
        return {
            data: [],
            count: 0,
        };
    }
}


export const getApiOverviewFull = async (url, dataPost, token) => {
    const result = await Axios({
        method: 'POST',
        url: `${url}/api/time_keeping/overview_v2`,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        },
        data: dataPost
    }).catch(err => {
        return 'error';
    });

    if (result === 'error') {
        return {
            data: [],
            key: [],
            count: 0,
        };
    } else if (result.data.status === 10000) {
        return {
            data: result.data.data,
            key: result.data.data,
            count: result.data.count,
        };
    } else {
        return {
            data: [],
            key: [],
            count: 0,
        };
    }
}

export const getApiComplianceReport = async (url, dataPost, token) => {
    const result = await Axios({
        method: 'POST',
        url: `${url}/api/time_keeping/compliance_report`,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        },
        data: dataPost
    }).catch(err => {
        return 'error';
    });

    if (result === 'error') {
        return {
            data: [],
            key: [],
            count: 0,
        };
    } else if (result.data.status === 10000) {
        return {
            data: result.data.data,
            key: result.data.data,
            count: result.data.count,
        };
    } else {
        return {
            data: [],
            key: [],
            count: 0,
        };
    }
}

export const updateTimeKeeping = async (url, dataPost, token) => {
    const result = await Axios({
        method: 'POST',
        url: `${url}/api/time_keeping/update`,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        },
        data: dataPost
    });
    return result.data.status;
}

export const getApiStatisticStaff = async (url, dataPost, token) => {
    const result = await Axios({
        method: 'POST',
        url: `${url}/api/time_keeping/statistic_staff_v2`,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        },
        data: dataPost
    }).catch(err => {
        return 'error';
    });
    if (result === 'error') {
        return {};
    } else if (result.data.status === 10000) {
        return result.data;
    } else {
        return {};
    }
}

export const getStaffsForDepartment = async (url, dataPost, token) => {
    const result = await Axios({
        method: 'POST',
        url: `${url}/api/staff/get_by_department`,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        },
        data: dataPost
    });
    if (result.data.status === 10000) {
        return result.data.data;
    } else {
        return [];
    }
}
export const getApiExportHistory = async (url, dataPost, token) => {
    const result = await Axios({
        method: 'POST',
        url: `${url}/api/time_keeping/checkin_histories`,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        },
        data: dataPost
    }).catch(err => {
        return 'error';
    });
    if (result === 'error') {
        return {};
    } else if (result.data.status === 10000) {
        return result.data.data;
    } else {
        return {};
    }
}
export const getListReasonOffSelect = async (url, id_company, token) => {
    const result = await Axios({
        method: 'GET',
        url: `${url}/api/reason_off/${id_company}/get_all`,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    });
    if (result.data.status === 10000) {
        let data = result.data.data.map(map => ({
            title: map.name,
            value: map.id,
        }));
        data.unshift(all_reason);
        return data;
    } else {
        return [all_reason];
    }
}
export const getApiDayOffFormShowAll = async (url, dataPost, token) => {
    const result = await Axios({
        method: 'POST',
        url: `${url}/api/daysoff_form/show_all`,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        },
        data: dataPost
    }).catch(err => {
        return 'error';
    });
    if (result === 'error') {
        return [];
    } else if (result.data.status === 10000) {
        return result.data.data;
    } else {
        return [];
    }
}