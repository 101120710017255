import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import language from '../../../../../language/language';
import { useSelector } from 'react-redux';
import moment from 'moment';
import axios from 'axios';
import swal from 'sweetalert';
import 'moment-timezone';
import ReactGA from 'react-ga';



const actionButtonStyle = {
    width: "100%",
    marginBottom: "5px",
    fontFamily: "inherit"
};

ApprovalForm.propTypes = {
    data: PropTypes.object,
    onHide: PropTypes.func,
    onRefresh: PropTypes.func,
    show: PropTypes.bool,
    page: PropTypes.number,
};

ApprovalForm.defaultProps = {
    data: null,
    onHide: null,
    onRefresh: null,
    show: false,
    page:1,
}

const NameTag = 'daysoff_form';
const type = 'biface';
function ApprovalForm(props) {
    const {data , show , onHide , onRefresh , page} = props;
    const token = useSelector(state => state.isLogin.access_token);
    
    const indexLanguage = useSelector(state => state.changelanguage.indexLanguage);
    const box_engine_cf = useSelector(state => state.boxai.box_engine_cf);
    const dataRole = useSelector(state => state.role.role);



    
    const checkStatus = (status) => {
        switch (status) {
            case 2: return language[indexLanguage].DayOff.pending        
            case 3: return language[indexLanguage].DayOff.accept        
            case 4: return language[indexLanguage].DayOff.refused 
                
            default:
                break;
        }
    }
    const handleUpdateStatus =  (status,value) => {
        swal({
          title:  status === 4 ? language[indexLanguage].DayOff.refuse : language[indexLanguage].DayOff.approve,
          text: status === 4 ? language[indexLanguage].Text_Notifi.notifi_sure_refuse_dayoff_form : language[indexLanguage].Text_Notifi.notifi_sure_accept_dayoff_form,
          buttons: {
            oke: language[indexLanguage].textButton.oke,
            cancel: language[indexLanguage].textButton.close
        },
        })
        .then(async (willDelete) => {
          if (willDelete === 'oke') {
            const result = await axios({
              method: 'POST',
              url: `${box_engine_cf.crrApiSocket}/api/daysoff_form/supervisor_handle_form`,
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token,
              },
              data: {
                arr_id: [value],
                status: status,
              },
            })
    
              if (result.data.status === 10000) {
                if (status === 3) {
                    ReactGA.event({
                      category: 'Duyet don - Cong tac',
                      action: 'Duyet don',
                    });
                  } else {
                    ReactGA.event({
                      category: 'Duyet don - Cong tac',
                      action: 'Tu choi',
                    });
                  }
                  swal({ title: language[indexLanguage].Text_Notifi.success, text: language[indexLanguage].Text_Notifi.notifi_sure_success_dayoff_form, icon: "success", buttons: false, timer: 1500 });
                  onHide();
                  onRefresh(page);
              }else if(result.data.status === 10004){
                const data = result.data.form_err;
                let name = '';
                data.map((map,index) => {
                  if(index === 0){
                    name = map.staff.name
                  }else{
                    name = name + ' / ' + map.staff.name
                  }
                })
                swal({ 
                  title: language[indexLanguage].Text_Notifi.error, 
                  text: `${language[indexLanguage].Text_Notifi.notifi_sure_holidays_exceeded}${name}`, 
                  icon: "error",});
              } else if (result.data.status === 10003) {
                  const data = result.data.staff_err;
                  let name = '';
                  data.map((map, index) => {
                      if (index === 0) {
                          name = map.name
                      } else {
                          name = name + ' / ' + map.name
                      }
                  })
                  swal({
                      title: language[indexLanguage].Text_Notifi.error,
                      text: `${language[indexLanguage].Text_Notifi.notifi_sure_staff} ${name} ${language[indexLanguage].Text_Notifi.notifi_sure_not_start_day_work}`,
                      icon: "error",
                  });
              } else {
                  swal({ title: language[indexLanguage].Text_Notifi.error, text: language[indexLanguage].Text_Notifi.notifi_sure_error_dayoff_form, icon: "error", buttons: false, timer: 1500 });
              }
          }
        });
        
    
      }
    
    if(!data) return <></>;
    return (
        <Modal show={show} size="lg" onHide={onHide}>
            <div className='row-sm row'>
                <div className="col-md-12">
                    <div className="m-portlet m-portlet--tab">
                        <Modal.Header closeButton>
                            <Modal.Title>{language[indexLanguage].DayOff.detail_letter}</Modal.Title>
                        </Modal.Header>
                        <div className="m-portlet__body">
                            <div className="form-group m-form__group row " style={{ paddingTop: 0 }}>
                                <div className='col-md-12 my-3' style={{fontSize:'14px'}}>{language[indexLanguage].DayOff.detail_staff}</div>
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="mb-3 col-md-6 text-left pr-md-5">
                                            <div className="row d-flex align-items-center">
                                                <label className="col-md-4 m-0">{language[indexLanguage].DayOff._name}</label>
                                                <div className="col-md-8 approval_form">
                                                    <span>{data && data.name}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3 col-md-6 text-left pl-md-5">
                                            <div className="row d-flex align-items-center">
                                                <label className="col-md-4 offset-md-1 m-0">{language[indexLanguage].DayOff.department}</label>
                                                <div className="col-md-8 approval_form">
                                                <span>{data && data.department}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="mb-3 col-md-6 text-left pr-md-5">
                                            <div className="row d-flex align-items-center">
                                                <label className="col-md-4 m-0">{language[indexLanguage].DayOff.id_staff}</label>
                                                <div className="col-md-8 approval_form">
                                                <span>{data && data.id_staff}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3 col-md-6 text-left pl-md-5">
                                            <div className="row d-flex align-items-center">
                                                <label className="col-md-4 m-0">{language[indexLanguage].DayOff.level}</label>
                                                <div className="col-md-8 approval_form">
                                                <span>{data && data.level}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-12 my-3' style={{fontSize:'14px'}}>{language[indexLanguage].DayOff.detail_letter}</div>
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="mb-3 col-md-6 text-left pr-md-5">
                                            <div className="row d-flex align-items-center">
                                                <label className="col-md-4 m-0">{language[indexLanguage].DayOff.date_create_time}</label>
                                                <div className="col-md-8 approval_form">
                                                    <span>{data && moment.utc(data.create_time).tz('Asia/Ho_Chi_Minh').format("DD-MM-YYYY HH:mm")}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="mb-3 col-md-6 text-left pr-md-5">
                                            <div className="row d-flex align-items-center">
                                                <label className="col-md-4 m-0">{language[indexLanguage].DayOff.from_date}</label>
                                                <div className="col-md-8 approval_form">
                                                    <span>{data && moment(data.start_date).format('DD-MM-YYYY HH:mm')}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3 col-md-6 text-left pl-md-5">
                                            <div className="row d-flex align-items-center">
                                                <label className="col-md-4 offset-md-1 m-0">{language[indexLanguage].DayOff.to_date}</label>
                                                <div className="col-md-8 approval_form">
                                                    <span>{data && moment(data.end_date).format('DD-MM-YYYY HH:mm')}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="mb-3 col-md-6 text-left pr-md-5">
                                            <div className="row d-flex align-items-center">
                                                <label className="col-md-4 m-0">{language[indexLanguage].DayOff.place_work}</label>
                                                <div className="col-md-8 approval_form">
                                                   <span>{data && data.work_place}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3 col-md-6 text-left pl-md-5">
                                            <div className="row d-flex align-items-center">
                                                <label className="col-md-4 offset-md-1 m-0">{language[indexLanguage].DayOff.calculation}</label>
                                                <div className="col-md-8 approval_form">
                                                    <span>{data && data.work_off}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="mb-3 col-md-6 text-left pr-md-5">
                                            <div className="row d-flex align-items-center">
                                                <label className="col-md-4 m-0">{language[indexLanguage].DayOff.supervisor}</label>
                                                <div className="col-md-8 approval_form">
                                                   <span>{data && data.supervisor}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3 col-md-6 text-left pl-md-5">
                                            <div className="row d-flex align-items-center">
                                                <label className="col-md-4 offset-md-1 m-0">{language[indexLanguage].DayOff.status}</label>
                                                <div className="col-md-8 approval_form" style={{ color: `${data.status === 3 ? "green" : data.status === 4 ? "red" : "orange" }`, fontWeight: 'bold' }}>
                                                    <span>{data && checkStatus(data.status)}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="mb-3 col-md-12 text-left">
                                            <div className="row d-flex align-items-center">
                                                <label className="col-md-2 m-0">{language[indexLanguage].DayOff.work_content}</label>
                                                <div className="col-md-10 approval_form pl-md-2">
                                                   <div className='approval_form_des'>{data && data.work_content}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group m-form__group row d-flex justify-content-center">
                               {dataRole.indexOf(`${type}/${NameTag}:supervisor_handle_form`) === -1 && <div className="col-md-2 px-0 mx-4">
                                        <button type="button" className="btn btn-secondary" style={actionButtonStyle} onClick={onHide}>{language[indexLanguage].textButton.close}</button>
                                    </div>}
                               {(data.status === 2 && dataRole.indexOf(`${type}/${NameTag}:supervisor_handle_form`) !== -1) &&
                                    <>
                                        <div className="col-md-2 px-0 mx-4">
                                            <button type="button" className="btn btn-accent" style={actionButtonStyle} onClick={() => handleUpdateStatus(3,data.id)}>Duyệt đơn</button>
                                        </div>
                                        <div className="col-md-2 px-0 mx-4">
                                            <button type="button" className="btn btn-danger" style={actionButtonStyle} onClick={() => handleUpdateStatus(4,data.id)}>Từ chối</button>
                                        </div>
                                    </>
                                }
                                {(data.status !== 2 && dataRole.indexOf(`${type}/${NameTag}:supervisor_handle_form`) !== -1) &&
                                    <>
                                    {
                                        (data.status === 3) &&
                                        <div className="col-md-2 px-0 mx-4">
                                            <button type="button" className="btn btn-danger" style={actionButtonStyle} onClick={() => handleUpdateStatus(4,data.id)}>Từ chối</button>
                                        </div> 
                                    }
                                    {
                                        (data.status === 4) && 
                                        <div className="col-md-2 px-0 mx-4">
                                            <button type="button" className="btn btn-accent" style={actionButtonStyle} onClick={() => handleUpdateStatus(3,data.id)}>Duyệt đơn</button>
                                        </div> 
                                    }
                                    <div className="col-md-2 px-0 mx-4">
                                        <button type="button" className="btn btn-secondary" style={actionButtonStyle} onClick={onHide}>Đóng</button>
                                    </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default ApprovalForm;