import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import swal from 'sweetalert';
import Store from '../../../../store';
import language from '../../../../language/language';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { Image } from 'react-bootstrap';

class ModalMoveImg extends Component {

    constructor(props) {
        super(props);
        this.state = {
            language: Store.getState().changelanguage.language,
            crrApi: Store.getState().boxai.box_engine_cf.crrApi,
            crrApiSocket: Store.getState().boxai.box_engine_cf.crrApiSocket,
            idCompany: Store.getState().boxai.box_engine_cf.idCompany,
            indexlanguage: 1,
            toStaff: { title: '' },
            valueDepartment: { title: '' },
            listStaff: [],
            listDepartment: [],
            dataPersonMove: "",
            dataImgMove: "",
            listPerson: [],
            typeMove: "",
        }
        Store.subscribe(() => {
            this.setState({
                language: Store.getState().changelanguage.language,
                crrApi: Store.getState().boxai.box_engine_cf.crrApi,
                crrApiSocket: Store.getState().boxai.box_engine_cf.crrApiSocket,
                idCompany: Store.getState().boxai.box_engine_cf.idCompany,
            });
        });
    }

    componentWillMount() {
        var indexlanguage = 1;
        if (this.state.language == "us") {
            indexlanguage = 0
        } else if (this.state.language == "vn") {
            indexlanguage = 1
        }
        this.setState({
            indexlanguage: indexlanguage,
        })
    }

    handleChangeStaff = (index, optionStaff) => {
        this.setState({
            toStaff: optionStaff,
        })
    }

    handleChangeDepartment = (index, value) => {
        this.setState({
            valueDepartment: value,
            toStaff: { title: '' }
        })
    }


    moveFace = async () => {
        if (this.state.crrApi == undefined) return;
        let apiMove = this.state.typeMove === 1 ? `${this.state.crrApi}/api/staff/move_from_history` : `${this.state.crrApi}/api/staff/acceptManyFace`
        let dataPost =  this.state.typeMove === 1 ? {
            toStaff: this.state.toStaff.value,
            imgFace: this.state.dataImgMove?._id
        } : {
            image_ids: [this.state.dataImgMove?._id],
            staff_object_id: this.state.toStaff.value,
        }
        let data = await fetch(apiMove, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            body: JSON.stringify(dataPost)
        }).then((response) => {
            return response.json()
        });
        if (data.status === 10000) {
            swal(language[this.state.indexlanguage].Text_Notifi.success, language[this.state.indexlanguage].Text_Notifi.move_face_success, "success", {
                buttons: false,
                timer: 1500,
            });
            return this.props.onHide(true);
        } else if (data.status === 10001) {
            swal(language[this.state.indexlanguage].Text_Notifi.warning, language[this.state.indexlanguage].Text_Notifi.same_person, "warning");
        } else {
            swal(language[this.state.indexlanguage].Text_Notifi.error, language[this.state.indexlanguage].Text_Notifi.move_failed, "error");
        }
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.show === true) {
            var indexlanguage = 1;
            if (this.state.language == "us") {
                indexlanguage = 0
            } else if (this.state.language == "vn") {
                indexlanguage = 1
            }
            this.setState({
                listDepartment: nextProps.department,
                indexlanguage: indexlanguage,
                listStaff: nextProps.datatostaff,
                valueDepartment: nextProps.valuedepartment,
                toStaff: { title: '' },
                dataPersonMove: nextProps.dataPersonMove,
                dataImgMove: nextProps.dataImgMove,
                listPerson: nextProps.valuePerson,
                typeMove: nextProps.typeMove,
            });
        }
    }

    render() {
        var options = []
        var listPersonFilter
        if (this.state.valueDepartment.value !== -1) {
            listPersonFilter = this.state.listPerson.filter(item => item.department?.id === this.state.valueDepartment.value)
        } else {
            listPersonFilter = this.state.listPerson
        }
        for (var i = 0; i < listPersonFilter.length; i++) {
            if (listPersonFilter[i]['id_staff'] === "unknown") {
                options.push({ 'label': listPersonFilter[i]['name'] + ' (' + listPersonFilter[i]['id_staff'] + ')', 'value': listPersonFilter[i]['object_id'] })
            } else {
                options.unshift(
                    { 'label': listPersonFilter[i]['name'] + ' (' + listPersonFilter[i]['id_staff'] + ')', 'value': listPersonFilter[i]['object_id'] }
                )
            }
        }

        return (
            <Modal
                {...this.props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {this.state.typeMove === 1 ? language[this.state.indexlanguage].tooltip.edit_data : language[this.state.indexlanguage].tooltip.move_to_face_sample}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form id="formMoveImage">
                        <div className="d-flex">
                            <div className="col-md-4">
                                <Image width='100%' src={this.state.dataImgMove.imgFace !== null ? `${this.state.crrApi}/view/image/0/${this.state.dataImgMove.idCamera}/${this.state.dataImgMove.imgFace}` : this.state.dataImgMove.idStaff !== 'unknown' ? `${this.state.crrApi}/view/origin/image/${this.state.dataImgMove._idStaff}` : '/img/photo-placeholder.png'} className='img-fluid imgHistory   border-3' />
                            </div>
                            <div className="col-md-8">
                                <div className="col-md-12 pr-0 pl-0 select_option">
                                    <Autocomplete
                                        options={this.state.listDepartment}
                                        getOptionLabel={option => option.label}
                                        defaultValue={{ 'value': -1, 'label': language[this.state.indexlanguage].placeholderSelect.all_department }}
                                        value={this.state.valueDepartment}
                                        onChange={this.handleChangeDepartment}
                                        disableClearable
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                placeholder={language[this.state.indexlanguage].placeholderSelect.select_department}
                                                fullWidth
                                            />
                                        )}
                                    />
                                </div>
                                <div className="col-md-12 pr-0 pl-0 select_option pt-3">
                                    <Autocomplete
                                        options={options}
                                        getOptionLabel={option => option.label}
                                        value={this.state.toStaff}
                                        onChange={this.handleChangeStaff}
                                        disableClearable
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                placeholder={language[this.state.indexlanguage].Trainning.text_select_placeholder}
                                                fullWidth
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="accent" id="button_addLevel" className="m-loader--light m-loader--right"
                        onClick={(e) => {
                            e.preventDefault();
                            if (this.state.toStaff.title === '') {
                                swal(language[this.state.indexlanguage].Text_Notifi.warning, language[this.state.indexlanguage].Text_Notifi.text_notifi_select_staff_move_item, "warning", {
                                    buttons: false,
                                    timer: 3000,
                                });
                                return;
                            }
                            this.moveFace();
                        }}
                    >{language[this.state.indexlanguage].textButton.move_img}</Button>
                    <Button variant="secondary" onClick={this.props.onHide}>{language[this.state.indexlanguage].textButton.close}</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

ModalMoveImg.propTypes = {
    valuedepartment: PropTypes.object,
    department: PropTypes.array,
    datatostaff: PropTypes.array,
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool,
}

export default ModalMoveImg;