import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import Switch from "react-switch";
import { HOST_CLOUD, HOST } from '../../../../config';
import swal from 'sweetalert';
import Store from '../../../../store';
import $ from 'jquery';
import Select from "react-select";
import language from '../../../../language/language';
import axios from 'axios';
import makeAnimated from 'react-select/animated';
import equal from 'fast-deep-equal'
import ReactGA from 'react-ga';

import { connect } from 'react-redux'
import { ClimbingBoxLoader } from 'react-spinners';
import Moment from "moment"
import { formatTitleOption } from '../../CommonFunction';
import { KeyboardTimePicker } from '@material-ui/pickers';
const animatedComponents = makeAnimated();

function sortData(a, b) {
    if (a.label > b.label) {
        return 1
    } else {
        if (a.label < b.label) {
            return -1;
        }
        else {
            return 0
        }
    }
}

class ModalDepartment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            language: Store.getState().changelanguage.language,
            crrApi2: Store.getState().boxai.box_engine_cf.crrApiSocket,
            idCompany: Store.getState().boxai.box_engine_cf.idCompany,
            indexlanguage: 0,
            crrDepartment: {
                name: "",
                code: "",
                description: "",
                id_company: "",
            },
            listStaff: [],
            listStaffSelected: [],
            listDepartment: [],
            correctListDepartment: [],
            departmentSelected: 0,
            optionsShift: [],
            valueShift: "",
            ot_time: null
        }
        Store.subscribe(() => {
            this.setState({
                language: Store.getState().changelanguage.language,
                crrApi2: Store.getState().boxai.box_engine_cf.crrApiSocket,
                idCompany: Store.getState().boxai.box_engine_cf.idCompany,
            });
        });
        this.getStaff();
        this.getShift();
        this.getDepartment();
    }

    getStaff = async () => {
        if (this.state.crrApi2 === undefined) return;
        let listStaff = [];
        const data = await axios({
            method: 'GET',
            url: `${this.state.crrApi2}/api/staff/${this.state.idCompany}/get_all`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: Store.getState().isLogin.access_token,
            }
        });
        if (data.data.status === 10000) {
            listStaff = data.data.data.map(map => ({
                label: map.name + ' - ' + map.id_staff, value: map.id,
            }))
            this.setState({
                listStaff: listStaff
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!equal(this.props.data, prevProps.data)) {
            var temp = this.props.data.code.split(" ");
            temp.pop();
            var parent_code = temp.join(" ");
            var departmentSelected = 0;
            var correctListDepartment = [];
            for (const idx in this.state.listDepartment) {
                if (this.state.listDepartment[idx].code == parent_code) {
                    departmentSelected = this.state.listDepartment[idx].value;
                }
                if (!this.state.listDepartment[idx].code.includes(this.props.data.code))
                    correctListDepartment.push(this.state.listDepartment[idx]);
            }
            if (this.props.data.code == "")
                correctListDepartment = this.state.listDepartment;
            this.setState({
                listStaffSelected: this.props.data.array_supervisor,
                valueShift: +this.props.data.description,
                departmentSelected: departmentSelected,
                correctListDepartment: correctListDepartment,
                ot_time: this.props.data.ot_time
            })
        }
        if (!equal(this.state.crrApi2, prevState.crrApi2)) {
            this.getStaff();
            this.getShift();
            this.getDepartment();
        }

    }

    getDepartment = async () => {
        if (this.state.crrApi2 === undefined) return;
        let listDepartment = [];
        const data = await axios({
            method: 'GET',
            url: `${this.state.crrApi2}/api/department/${this.state.idCompany}/get_all`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: Store.getState().isLogin.access_token,
            }
        });
        if (data.data.status === 10000) {
            listDepartment = data.data.data.map(map => ({
                label: map.name, value: map.id, code: map.code
            }))
            this.setState({
                listDepartment: listDepartment
            })
        }
    }

    getShift = () => {
        if (this.state.crrApi2 === undefined) return;
        fetch(`${this.state.crrApi2}/api/shift_work/${this.state.idCompany}/get_by_active/1`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                var optionsShift = this.state.optionsShift
                for (let index = 0; index < data.data.length; index++) {
                    optionsShift.push({ value: data.data[index].id, label: data.data[index].name + ' (' + Moment(data.data[index].time_in, "H:mm").format("HH:mm") + ' - ' + Moment(data.data[index].time_out, "H:mm").format("HH:mm") + ')' })
                }
                var optionShiftSoft = optionsShift.sort(sortData)
                this.setState({
                    optionsShift: optionShiftSoft,
                });
            }
        })
    }

    updateDepartment = async (dataDepartment, event) => {
        var dataJson = {};

        let listStaffSelected = [];
        if (this.state.listStaffSelected) {
            this.state.listStaffSelected.map(map => {
                listStaffSelected.push(map.value);
            })
        }
        dataJson['id'] = dataDepartment.id
        dataJson['name'] = dataDepartment.name.trim()
        dataJson['description'] = this.state.valueShift !== 0 ? this.state.valueShift.toString() : ""
        dataJson['id_company'] = dataDepartment.id_company
        dataJson['array_supervisor'] = listStaffSelected;
        dataJson['department_under'] = typeof this.state.departmentSelected !== "undefined" ? this.state.departmentSelected : 0;
        dataJson['ot_time'] = this.state.ot_time;
        var form = $("#formAddDepartment")
        if (form[0].checkValidity() === false) {
            event.preventDefault()
            event.stopPropagation()
            form.addClass('was-validated')
        } else {
            //Insert
            if (dataDepartment.id == undefined) {
                $('#button_addDepartment').addClass('m-loader');
                $('#button_addDepartment').attr('disabled', true);
                // let data = await fetch(`${dataDepartment.HOST}/api/department/insert_or_update`, {
                let data = await fetch(`${this.state.crrApi2}/api/department/insert_or_update`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': Store.getState().isLogin.access_token
                    },
                    body: JSON.stringify(dataJson)
                }).then((response) => {
                    return response.json()
                });

                if (data.status === 10000) {
                    ReactGA.event({
                        category: 'Phong ban',
                        action: 'Them phong ban',
                    });
                    swal(language[this.props.indexLanguage].Text_Notifi.success, language[this.props.indexLanguage].Text_Notifi.add_success, "success", {
                        buttons: false,
                        timer: 1500,
                    });
                    return this.props.onHide();
                } else if (data.status === 10001) {
                    swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].Text_Notifi.check_add_10001_department, "warning", {
                        buttons: false,
                        timer: 1500,
                    });
                    $('#button_addDepartment').removeClass('m-loader');
                    $('#button_addDepartment').removeAttr('disabled', true);
                }
                else {
                    $('#button_addDepartment').removeClass('m-loader');
                    $('#button_addDepartment').removeAttr('disabled', true);
                    swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].Text_Notifi.add_false, "error");
                }
            } else {
                //Update
                $('#button_addDepartment').addClass('m-loader');
                $('#button_addDepartment').attr('disabled', true);
                let data = await fetch(`${dataDepartment.HOST}/api/department/insert_or_update`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': Store.getState().isLogin.access_token
                    },
                    body: JSON.stringify(dataJson)
                }).then((response) => {
                    return response.json()
                });
                if (data.status === 10000) {
                    ReactGA.event({
                        category: 'Phong ban',
                        action: 'Sua phong ban',
                    });
                    swal(language[this.props.indexLanguage].Text_Notifi.success, language[this.props.indexLanguage].Text_Notifi.edit_success, "success", {
                        buttons: false,
                        timer: 1500,
                    });
                    return this.props.onHide();
                } else if (data.status === 10001) {
                    swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].Text_Notifi.check_add_10001_department, "warning", {
                        buttons: false,
                        timer: 1500,
                    });
                    $('#button_addDepartment').removeClass('m-loader');
                    $('#button_addDepartment').removeAttr('disabled', true);
                } else {
                    $('#button_addDepartment').removeClass('m-loader');
                    $('#button_addDepartment').removeAttr('disabled', true);
                    swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].Text_Notifi.edit_false, "error");
                }
            }

        }
    }


    departmentHandle(e) {
        var dataDepartment = this.state.crrDepartment;
        dataDepartment[e.target.name] = e.target.value;
        this.setState({ crrDepartment: dataDepartment });
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.show === true) {
            this.setState({
                crrDepartment: nextProps.data,
            });
        }
    }

    handleEnter = (event) => {
        if (event.keyCode === 13) {
            const form = event.target.form;
            const index = Array.prototype.indexOf.call(form, event.target);
            form.elements[index + 1].focus();
            event.preventDefault();
        }
    }
    handleChangeStaff = (value) => {
        this.setState({
            listStaffSelected: value ? value : []
        })
    }

    handleChangeShift = (value) => {
        this.setState({
            valueShift: value ? value.value : ""
        })
    }

    handleChangeDepartment = (value) => {
        this.setState({
            departmentSelected: value ? value.value : 0
        })
    }

    handleChangeOTTime = (value) => {
        console.log(value);
        this.setState({
            ot_time: value != null ? Moment(value).format('HH:mm:00') : null
        })
    }

    render() {
        let click_handle = (event) => {
            this.updateDepartment(this.state.crrDepartment, event);
        }
        return (
            <Modal
                {...this.props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form id="formAddDepartment">
                        <div className="col-xl-12">
                            <div className="m-widget14">
                                <div className="form-group m-form__group">
                                    <label htmlFor="Name">{language[this.props.indexLanguage].textTable.name} <span className="text-danger"> *</span></label>
                                    <input className="form-control m-input" id="Name" name='name' value={this.state.crrDepartment.name === '' ? '' : this.state.crrDepartment.name} onKeyDown={(event) => this.handleEnter(event)} onChange={e => this.departmentHandle(e)} placeholder={language[this.props.indexLanguage].textTable.name} required />
                                </div>
                                {/* <div className="form-group m-form__group">
                                    <label htmlFor="Description">{language[this.props.indexLanguage].textTable.description}</label>
                                    <textarea rows="4" className="form-control m-input" id="Description" value={this.state.crrDepartment.description === '' ? '' : this.state.crrDepartment.description} name='description' onChange={e => this.departmentHandle(e)} placeholder={language[this.props.indexLanguage].textTable.description} />
                                </div> */}
                                <div className="form-group m-form__group">
                                    <label>{language[this.props.indexLanguage].textTable.under}</label>
                                    <Select
                                        components={animatedComponents}
                                        value={
                                            this.state.listDepartment.filter((option) => {
                                                return this.state.departmentSelected === option.value
                                            })
                                        }
                                        onChange={this.handleChangeDepartment}
                                        isClearable="true"
                                        options={this.state.correctListDepartment.map(item => ({
                                            label: formatTitleOption(item),
                                            value: item.value
                                        }))}
                                        styles={{
                                            option: (base, state) => ({
                                                ...base,
                                                whiteSpace: "nowrap"
                                            })
                                        }}
                                        placeholder={language[this.props.indexLanguage].placeholderSelect.select_under}
                                    />
                                </div>
                                <div className="form-group m-form__group">
                                    <label>{language[this.props.indexLanguage].textTable.shift}</label>
                                    <Select
                                        components={animatedComponents}
                                        value={
                                            this.state.optionsShift.filter((option) => {
                                                return this.state.valueShift === option.value
                                            })
                                        }
                                        onChange={this.handleChangeShift}
                                        isClearable="true"
                                        options={this.state.optionsShift}
                                        placeholder={language[this.props.indexLanguage].placeholderSelect.select_shift}
                                    />
                                </div>
                                <div className="form-group m-form__group">
                                    <label htmlFor="Description">{language[this.props.indexLanguage].textTable.supervisor}</label>
                                    <Select
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        value={this.state.listStaffSelected}
                                        onChange={this.handleChangeStaff}
                                        isMulti
                                        options={this.state.listStaff}
                                        placeholder={language[this.props.indexLanguage].placeholderInput.choose_staffs}
                                        required
                                    />
                                </div>
                                <div className="form-group m-form__group">
                                    <label htmlFor="Description">Mốc thời gian tính OT</label>
                                    <KeyboardTimePicker
                                        placeholder='HH:mm'
                                        mask='__:__'
                                        ampm={false}
                                        style={{ width: "100%" }}
                                        value={
                                            this.state.ot_time != null
                                                ? new Date(
                                                    Moment(this.state.ot_time, 'H:mm')
                                                )
                                                : null
                                        }
                                        onChange={this.handleChangeOTTime}
                                        invalidDateMessage={
                                            language[this.props.indexLanguage].Text_Notifi.notifi_date_time_invalid
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="accent" id="button_addDepartment" className="m-loader--light m-loader--right" onClick={click_handle}>{language[this.props.indexLanguage].textButton.save}</Button>
                    <Button variant="secondary" onClick={() => {
                        this.props.onHide();
                        this.setState({
                            listStaffSelected: this.props.data.array_supervisor,
                        })
                    }}>{language[this.props.indexLanguage].textButton.close}</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

ModalDepartment.propTypes = {
    data: PropTypes.object,
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool,
}
const mapStateToProps = state => {
    return {
        indexLanguage: state.changelanguage.indexLanguage,
    }
}
export default connect(mapStateToProps, null)(ModalDepartment);