import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import swal from 'sweetalert';
import Store from "../../../../store";
import language from '../../../../language/language';
import $ from 'jquery';
import ReactGA  from 'react-ga'

import { connect } from 'react-redux'


class statisticalLeaveOfAbsenceForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            statisticalLOA: {},
            crrApi2: Store.getState().boxai.box_engine_cf.crrApiSocket,
            access_token: Store.getState().isLogin.access_token,
            language: Store.getState().changelanguage.language,
            // dateUsed: false
        }
        Store.subscribe(() => {
            this.setState({
                access_token: Store.getState().isLogin.access_token,
                crrApi2: Store.getState().boxai.box_engine_cf.crrApiSocket,
                language: Store.getState().changelanguage.language,
            });
        });
    }





    updateStatisticalLOA = async (datastatistical, event) => {

        var form = $("#formStatistical")
        if (form[0].checkValidity() === false) {
            event.preventDefault()
            event.stopPropagation()
            form.addClass('was-validated')
        }
        else {
            let data = await fetch(`${this.state.crrApi2}/api/daysoff/update`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': Store.getState().isLogin.access_token
                },
                body: JSON.stringify({
                    "id_staff": datastatistical.id_staff,
                    "ndo_ya": datastatistical.ndo_ya,
                    "ndo_used": datastatistical.ndo_used,
                    "year" : datastatistical.year,
                    // "ndo_senior":  +datastatistical.ndo_senior
                })
            }).then((res) => {
                return res.json()
            })
            if (data.status === 10000) {
                ReactGA.event({
                    category: 'Thong ke ngay phep',
                    action: 'Edit thong ke ngay phep',
                  });
                swal(language[this.props.indexLanguage].Text_Notifi.success, language[this.props.indexLanguage].StatisticalLeaveOfAbsence.update_success, "success", { button: false, timer: 1500, });
                return this.props.onHide()
            } else {
                swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].StatisticalLeaveOfAbsence.update_error, "error");
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.show === true) {
            this.setState({ statisticalLOA: nextProps.dataStatistical });
        }
    }

    statisticalHandler(e) {
        var dataHandler = this.state.statisticalLOA
        dataHandler[e.target.name] = e.target.value
        if(e.target.value < 0){
            dataHandler[e.target.name] = null
        }else{
            this.setState({
                statisticalLOA: dataHandler
            })
        }

    }



    render() {
        let click_handle = (e) => {
            if (parseFloat(this.state.statisticalLOA.ndo_used) > (parseFloat(this.state.statisticalLOA.ndo) + parseFloat(this.state.statisticalLOA.ndo_ya))) {
                swal({
                    // title: "Are you sure?",
                    text: language[this.props.indexLanguage].StatisticalLeaveOfAbsence.check_date,
                    icon: "warning",
                    // buttons: true,
                    cancel: true,
                    dangerMode: true,
                })
            } else {
                swal({
                    // title: "Are you sure?",
                    text: language[this.props.indexLanguage].StatisticalLeaveOfAbsence.notification_update,
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                }).then((update) => {
                    if (update) {
                        this.updateStatisticalLOA(this.state.statisticalLOA, e)
                    }
                })
            }

        }
        return (
            <Modal
                {...this.props}
                dialogClassName="modal-70w"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form id="formStatistical">
                        <div className="col-md-12">
                            <div className="m-widget14">
                                <div className="form-group m-form__group" style={{ paddingTop: 0 }}>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label htmlFor="typeDeviceName">{language[this.props.indexLanguage].StatisticalLeaveOfAbsence.name}</label>
                                            <input className="form-control m-input" name='name' value={this.state.statisticalLOA === null ? "" : this.state.statisticalLOA.name} disabled={true}
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <label htmlFor="typeDeviceName">{language[this.props.indexLanguage].StatisticalLeaveOfAbsence.numberdaysoff}</label>
                                            <input className="form-control m-input" name='ndo' value={this.state.statisticalLOA === null ? "" : this.state.statisticalLOA.ndo} disabled={true}
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <label htmlFor="typeDeviceName">{language[this.props.indexLanguage].StatisticalLeaveOfAbsence.numberdaysoff_year_ago} <span className="text-danger">*</span> </label>
                                            <input type='number' min='0' step='.01' pattern='^[+]?([.]\d+|\d+([.]\d+)?)$' className="form-control m-input" name='ndo_ya' value={this.state.statisticalLOA === null ? "" : this.state.statisticalLOA.ndo_ya} onChange={e => this.statisticalHandler(e)} required

                                            />

                                        </div>
                                        <div className="col-md-2">
                                            <label htmlFor="typeDeviceName">{language[this.props.indexLanguage].StatisticalLeaveOfAbsence.numberdaysoff_used} <span className="text-danger">*</span></label>
                                            <input type='number' min='0' step='.01' pattern='^[+]?([.]\d+|\d+([.]\d+)?)$' className="form-control m-input" name='ndo_used' value={this.state.statisticalLOA === null ? "" : this.state.statisticalLOA.ndo_used} onChange={e => this.statisticalHandler(e)} required />

                                            {
                                                parseFloat(this.state.statisticalLOA.ndo_used) > (parseFloat(this.state.statisticalLOA.ndo) + parseFloat(this.state.statisticalLOA.ndo_ya))
                                                    ?
                                                    <div className='text-danger pt-2'>{language[this.props.indexLanguage].StatisticalLeaveOfAbsence.check_date}</div>
                                                    :
                                                    ''
                                            }


                                        </div>
                                        {/* <div className="col-md-2">
                                            <label htmlFor="typeDeviceName">{language[this.props.indexLanguage].StatisticalLeaveOfAbsence.ndo_senior} <span className="text-danger">*</span> </label>
                                            <input type='number' min='0' step='.01' pattern='^[+]?([.]\d+|\d+([.]\d+)?)$' className="form-control m-input" name='ndo_senior' value={this.state.statisticalLOA.ndo_senior === null ? 0 : this.state.statisticalLOA.ndo_senior} onChange={e => this.statisticalHandler(e)} required

                                            />

                                        </div> */}
                                        <div className="col-md-2">
                                            <label htmlFor="typeDeviceName">{language[this.props.indexLanguage].StatisticalLeaveOfAbsence.total_numberdaysoff}</label>
                                            <input className="form-control m-input" name='number_day_off_rest' value={isNaN(parseFloat(this.state.statisticalLOA.ndo) + parseFloat(this.state.statisticalLOA.ndo_ya) - parseFloat(this.state.statisticalLOA.ndo_used)) ? 0 : (parseFloat(this.state.statisticalLOA.ndo) + parseFloat(this.state.statisticalLOA.ndo_ya) - parseFloat(this.state.statisticalLOA.ndo_used)).toFixed(2)} disabled={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="accent" onClick={click_handle} >{language[this.props.indexLanguage].StatisticalLeaveOfAbsence.save}</Button>
                    <Button variant="secondary" onClick={this.props.onHide}>{language[this.props.indexLanguage].StatisticalLeaveOfAbsence.close}</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}



const mapStateToProps = state => {
    return {
        indexLanguage: state.changelanguage.indexLanguage,
    }
}
export default connect(mapStateToProps, null)(statisticalLeaveOfAbsenceForm);


