import $ from 'jquery';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import swal from 'sweetalert';
import language from '../../../language/language';
// First way to import
import Store from '../../../store';
import { HOST_CLOUD } from '../../../config'
import Switch from "react-switch";
import { Row, Col, Image } from 'react-bootstrap';
import { PulseLoader } from 'react-spinners';
import { logout } from '../../../actions/isLogin';
import ReactGA  from 'react-ga'

import { connect } from 'react-redux'
import Header from '../../layouts/Header';
class ChangePassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLogin: Store.getState().isLogin.isLogin,
            access_token: Store.getState().isLogin.access_token,
            box_engine_cf: Store.getState().boxai.box_engine_cf,
            language: Store.getState().changelanguage.language,
            crrApiSocket: Store.getState().boxai.box_engine_cf.crrApiSocket,
            user: null,
            isChecked: false,
            dataConfig: 0,
            indexlanguage: 0,
            confirm_password: false,
            crrChangePassword: { old_password: "", new_password: "", confirm_password: "" }
        };
        Store.subscribe(() => {
            this.setState({
                ...this.state,
                isLogin: Store.getState().isLogin.isLogin,
                access_token: Store.getState().isLogin.access_token,
                box_engine_cf: Store.getState().boxai.box_engine_cf,
                language: Store.getState().changelanguage.language,
                crrApiSocket: Store.getState().boxai.box_engine_cf.crrApiSocket,
            }, () => {
                this.getData()
            });
        });
        this.getData()
    }

    getData = () => {
        this.configGetByIDCompany()
        // this.getUser()
    }

    btnSubmit = (event, dataPW) => {
        var form = $("#form_changepassword")
        if (form[0].checkValidity() === false) {
            event.preventDefault()
            event.stopPropagation()
            form.addClass('was-validated')
        } else {
            $('#button_submit').addClass('m-loader');
            $('#button_submit').attr('disabled', true);
            if (this.state.confirm_password === true) {
                if (this.state.crrApiSocket === undefined) return;
                fetch(`${this.state.crrApiSocket}/api/staff/update_password`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': Store.getState().isLogin.access_token
                    },
                    body: JSON.stringify(dataPW)
                }).then((response) => {
                    return response.json();
                }).then((data) => {
                    if (data.status === 10000) {
                        ReactGA.event({
                            category: 'User profile',
                            action: 'Change password',
                          });
                        swal(language[this.props.indexLanguage].Text_Notifi.success, language[this.props.indexLanguage].Text_Notifi.notifi_change_pass_success, "success", {
                            buttons: false,
                            timer: 1500,
                        });
                        window.location.href = "/login";
                    } else if (data.status === 10003) {
                        $('#button_submit').removeClass('m-loader');
                        $('#button_submit').removeAttr('disabled', false);
                        swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].Text_Notifi.notifi_change_pass_check, "warning");
                    }
                    else {
                        $('#button_submit').removeClass('m-loader');
                        $('#button_submit').removeAttr('disabled', false);
                        swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].Text_Notifi.notifi_change_pass_error, "error");
                    }
                });
            } else {
                $('#button_submit').removeClass('m-loader');
                $('#button_submit').removeAttr('disabled', false);
                swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].Text_Notifi.notifi_change_pass, "warning");
            }
        }
    }

    ChangePassword = (event) => {
        var crrChangePassword = this.state.crrChangePassword;
        crrChangePassword[event.target.name] = event.target.value.trim();
        if (crrChangePassword['new_password'] === crrChangePassword['confirm_password']) {
            this.setState({
                confirm_password: true
            })
        } else {
            this.setState({
                confirm_password: false
            })
        }
        this.setState({ crrChangePassword: crrChangePassword });
    }

    ChangeNewPassword = (event) => {
        var crrChangePassword = this.state.crrChangePassword;
        crrChangePassword[event.target.name] = event.target.value.trim();
        let regPassword =  /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\S]{8,}/

        if (regPassword.test(event.target.value.trim())) {
            if (event.target.value.trim() === crrChangePassword['confirm_password']) {
                this.setState({
                    confirm_password: true
                })
                if (crrChangePassword['confirm_password'] !== '') {
                    $('.notif-success').removeClass('d-none')
                    $('.notif-danger').addClass('d-none')
                }
            } else {
                this.setState({
                    confirm_password: false
                })
                if (crrChangePassword['confirm_password'] !== '') {
                    $('.notif-success').addClass('d-none')
                    $('.notif-danger').removeClass('d-none')
                }
            }
            $('.notif-Valid-warning').addClass('d-none')
            $('.notif-Valid-success').removeClass('d-none')
        } else {
            $('.notif-Valid-success').addClass('d-none')
            $('.notif-Valid-warning').removeClass('d-none')
            if (crrChangePassword['confirm_password'].length > 1) {
                $('.notif-success').addClass('d-none')
                $('.notif-danger').removeClass('d-none')
            }
        }
        this.setState({ crrChangePassword: crrChangePassword });
    }

    ConfirmPassword = (event) => {
        var crrChangePassword = this.state.crrChangePassword;
        crrChangePassword[event.target.name] = event.target.value.trim();
        let regPassword =  /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\S]{8,}/
        if (crrChangePassword['new_password'] !== "") {
            if (crrChangePassword['new_password'].length >= 6) {
                if (event.target.value.trim() === crrChangePassword['new_password'] && regPassword.test(event.target.value.trim())) {
                    this.setState({
                        confirm_password: true
                    })
                    $('.notif-success').removeClass('d-none')
                    $('.notif-danger').addClass('d-none')
                }
                else {
                    this.setState({
                        confirm_password: false
                    })
                    $('.notif-success').addClass('d-none')
                    $('.notif-danger').removeClass('d-none')
                }
            } else {
                this.setState({
                    confirm_password: false
                })
                $('.notif-success').addClass('d-none')
                $('.notif-danger').addClass('d-none')
                $('.notif-warning_confir').addClass('d-none')
            }
            
        } else {
            if (event.target.value.trim() === crrChangePassword['new_password']) {
                this.setState({
                    confirm_password: false
                })
                $('.notif-success').addClass('d-none')
                $('.notif-danger').addClass('d-none')
                $('.notif-warning_confir').addClass('d-none')
            } else {
                this.setState({
                    confirm_password: false
                })
                $('.notif-success').addClass('d-none')
                $('.notif-danger').removeClass('d-none')
                $('.notif-warning_confir').addClass('d-none')
            }
        }

        this.setState({ crrChangePassword: crrChangePassword });
    }

    configGetByIDCompany = () => {
        if (this.state.box_engine_cf.crrApiSocket == undefined || this.state.box_engine_cf.crrApiSocket == "") return;
        fetch(`${this.state.box_engine_cf.crrApiSocket}/api/config/get_info/${this.state.box_engine_cf.idCompany}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                this.setState({
                    dataConfig: data.data.data.id_staff_auto_increase
                })
            }
        })
    }

    render() {
        if (this.state.isLogin === false) {
            return (
                <Redirect to={'/login'} />
            )
        }

        return (
            <>
            <Header />
            <div className={"m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body "}>
            <div className="m-grid__item m-grid__item--fluid m-wrapper p-2">
                <div className="m-content">
                    <div className="row m-0">
                        <div className="col-md-12 p-0">
                            <div className="m-portlet m-portlet--full-height m-portlet--tabs  ">
                                <div className="m-portlet__head">
                                    <div className="m-portlet__head-tools">
                                        <ul className="nav nav-tabs m-tabs m-tabs-line   m-tabs-line--left m-tabs-line--primary" role="tablist">
                                            <li className="nav-item m-tabs__item">
                                                <a className="nav-link m-tabs__link active" data-toggle="tab" href="#m_user_profile_tab_1" role="tab">
                                                    <i className="flaticon-share m--hide" />
                                                    {language[this.props.indexLanguage].Profile.change_password}
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="tab-content">
                                    <div className="tab-pane active" id="m_user_profile_tab_1">
                                        <form id="form_changepassword" className="m-form m-form--fit m-form--label-align-right col-md-6 col-lg-5 mx-auto p-0">
                                            <div className="m-portlet__body">
                                                <div className="form-group m-form__group">
                                                    <label>{language[this.props.indexLanguage].Profile.current_password}</label>
                                                    <div className="m-input-icon m-input-icon--left">
                                                        <input type="password" className="form-control m-input" id="old_password" name='old_password' onChange={e => this.ChangePassword(e)} placeholder={language[this.props.indexLanguage].Profile.current_password} required />
                                                        <span className="m-input-icon__icon m-input-icon__icon--left">
                                                            <span>
                                                                <i className="la la-unlock" />
                                                            </span>
                                                        </span>
                                                        <span id="old_password_icon_show" className="m-input-icon__icon m-input-icon__icon--right d-none hover_pointer"
                                                            onClick={
                                                                () => {
                                                                    $('#old_password').prop('type', 'password');
                                                                    $('#old_password_icon_show').addClass('d-none');
                                                                    $('#old_password_icon_hide').removeClass('d-none');
                                                                }
                                                            }
                                                        >
                                                            <span>
                                                                <i className="la la-eye" />
                                                            </span>
                                                        </span>
                                                        <span id="old_password_icon_hide" className="m-input-icon__icon m-input-icon__icon--right hover_pointer"
                                                            onClick={
                                                                () => {
                                                                    $('#old_password').prop('type', 'text');
                                                                    $('#old_password_icon_hide').addClass('d-none');
                                                                    $('#old_password_icon_show').removeClass('d-none');
                                                                }
                                                            }
                                                        >
                                                            <span>
                                                                <i className="la la-eye-slash" />
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="form-group m-form__group">
                                                    <label>{language[this.props.indexLanguage].Profile.new_password}</label>
                                                    <div className="m-input-icon m-input-icon--left">
                                                        <input type="password" className="form-control m-input" minLength="8" id="new_password" name='new_password' onChange={e => this.ChangeNewPassword(e)} placeholder={language[this.props.indexLanguage].Profile.new_password} required />
                                                        <span className="m-input-icon__icon m-input-icon__icon--left">
                                                            <span>
                                                                <i className="la la-unlock" />
                                                            </span>
                                                        </span>
                                                        <span id="new_password_icon_show" className="m-input-icon__icon m-input-icon__icon--right d-none hover_pointer"
                                                            onClick={
                                                                () => {
                                                                    $('#new_password').prop('type', 'password');
                                                                    $('#new_password_icon_show').addClass('d-none');
                                                                    $('#new_password_icon_hide').removeClass('d-none');
                                                                }
                                                            }
                                                        >
                                                            <span>
                                                                <i className="la la-eye" />
                                                            </span>
                                                        </span>
                                                        <span id="new_password_icon_hide" className="m-input-icon__icon m-input-icon__icon--right hover_pointer"
                                                            onClick={
                                                                () => {
                                                                    $('#new_password').prop('type', 'text');
                                                                    $('#new_password_icon_hide').addClass('d-none');
                                                                    $('#new_password_icon_show').removeClass('d-none');
                                                                }
                                                            }
                                                        >
                                                            <span>
                                                                <i className="la la-eye-slash" />
                                                            </span>
                                                        </span>
                                                    </div>
                                                    <span className="m-form__help"><span className="text-danger">*</span> {language[this.props.indexLanguage].Profile.valid_password}</span>
                                                    <span className="form-control-feedback text-success notif-Valid-success d-none"> {language[this.props.indexLanguage].Profile.valid_password_success}</span>
                                                    <span className="form-control-feedback text-danger notif-Valid-warning d-none"> {language[this.props.indexLanguage].Profile.valid_password_warning}</span>
                                                </div>
                                                <div className="form-group m-form__group">
                                                    <label>{language[this.props.indexLanguage].Profile.confirm_password}</label>
                                                    <div className="m-input-icon m-input-icon--left">
                                                        <input type="password" className="form-control m-input" minLength="6" id="confirm_password" name='confirm_password' onChange={e => this.ConfirmPassword(e)} placeholder={language[this.props.indexLanguage].Profile.confirm_password} required />
                                                        <span className="m-input-icon__icon m-input-icon__icon--left">
                                                            <span>
                                                                <i className="la la-unlock" />
                                                            </span>
                                                        </span>
                                                        <span id="confirm_password_icon_show" className="m-input-icon__icon m-input-icon__icon--right d-none hover_pointer"
                                                            onClick={
                                                                () => {
                                                                    $('#confirm_password').prop('type', 'password');
                                                                    $('#confirm_password_icon_show').addClass('d-none');
                                                                    $('#confirm_password_icon_hide').removeClass('d-none');
                                                                }
                                                            }
                                                        >
                                                            <span>
                                                                <i className="la la-eye" />
                                                            </span>
                                                        </span>
                                                        <span id="confirm_password_icon_hide" className="m-input-icon__icon m-input-icon__icon--right hover_pointer"
                                                            onClick={
                                                                () => {
                                                                    $('#confirm_password').prop('type', 'text');
                                                                    $('#confirm_password_icon_hide').addClass('d-none');
                                                                    $('#confirm_password_icon_show').removeClass('d-none');
                                                                }
                                                            }
                                                        >
                                                            <span>
                                                                <i className="la la-eye-slash" />
                                                            </span>
                                                        </span>
                                                    </div>
                                                    <div className="form-control-feedback text-danger notif-danger d-none">{language[this.props.indexLanguage].Profile.warning}</div>
                                                    <div className="form-control-feedback text-success notif-success d-none">{language[this.props.indexLanguage].Profile.success}</div>
                                                    <div className="form-control-feedback text-danger notif-warning_confir d-none">{language[this.props.indexLanguage].Profile.warning_confirmation}</div>
                                                </div>
                                            </div>
                                        </form>
                                        <div className="m-form__actions text-center pb-4">
                                            <button id="button_submit" className="btn btn-accent m-loader--light m-loader--right" onClick={(event) => this.btnSubmit(event, this.state.crrChangePassword)}>{language[this.props.indexLanguage].Profile.submit}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </>
        );
    }
}
const mapStateToProps = state => {
    return {
        indexLanguage: state.changelanguage.indexLanguage,
    }
}


export default connect(mapStateToProps, null)(ChangePassword);
