import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Store from '../../store';
import { withRouter } from 'react-router';
import language from '../../language/language';
import { connect } from 'react-redux'
import $ from 'jquery';
const location = {
    pathname: '/capture',
}

class MenuBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogin: Store.getState().isLogin.isLogin,
            language: Store.getState().changelanguage.language,
            dataRole: Store.getState().role.role,
            crrApi2: Store.getState().boxai.box_engine_cf.crrApiSocket,
            type: '',
            type_login: '',
            supervisor: true,
            listCompany: [],
        }
        Store.subscribe(() => {
            this.setState({
                isLogin: Store.getState().isLogin.isLogin,
                language: Store.getState().changelanguage.language,
                dataRole: Store.getState().role.role,
                crrApi2: Store.getState().boxai.box_engine_cf.crrApiSocket,
                listCompany: Store.getState().boxai.list_company,
                boxAi: Store.getState().boxai,
            }, () => {
                this.checkSupervisor();
            });
        });


    }

    //WARNING! To be deprecated in React v17. Use componentDidMount instead.
    componentWillMount() {
        var type = localStorage.getItem("type")
        var type_login = localStorage.getItem("type_login")
        this.setState({
            type: type,
            type_login: type_login
        })
        this.checkSupervisor();
    }

    checkSupervisor = () => {
        if (this.state.crrApi2 === undefined) return;
        fetch(`${this.state.crrApi2}/api/department/check_supervisor`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            }
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                this.setState({
                    supervisor: true
                });
            }
            else {
                this.setState({
                    supervisor: false
                });
            }
        })
    }


    onClickClose = () => {
        $('#m_aside_left').removeClass('m-aside-left--on')
        $('#body_form').removeClass('m-aside-left--on')
        $('#m_aside_left_toggle').removeClass('m-aside-left-toggler--active')
        $('.m-aside-left-overlay').remove()
    }

    render() {
        var active = '';
        if (this.props.location.pathname === "/") {
            active = 'm-menu__item--active'
        }
        // if (process.env.REACT_APP_SITE_TYPE == 0) {
        //     d_none_menu = "d-none"
        // }
        // if (process.env.REACT_APP_IS_DEV == 0) {
        //     d_none_submodel = "d-none"
        // }

        return (
            <div id="m_aside_left" className="m-aside-left  m-aside-left--skin-dark">
                {/* BEGIN: Aside Menu */}
                <div id="m_ver_menu" className="m-aside-menu  m-aside-menu--skin-dark m-aside-menu--submenu-skin-dark " data-menu-vertical="true" m-menu-scrollable={1} m-menu-dropdown-timeout={500}>
                    <ul className="m-menu__nav  m-menu__nav--dropdown-submenu-arrow ">
                        <li className="m-menu__section m-menu__section--first">
                            {/* <h4 className="m-menu__section-text">Departments</h4> */}
                            <i className="m-menu__section-icon flaticon-more-v3" />
                        </li>
                        {
                            (this.state.dataRole.indexOf(this.state.type + '/dashboard:view_all') !== -1 && this.state.listCompany.length > 1)
                                ?
                                <li className="m-menu__item  m-menu__item--submenu" aria-haspopup="true" m-menu-submenu-toggle="hover">
                                    <NavLink to="/dashboardsa" className={"m-menu__link " + active} activeClassName="m-menu__item--active" onClick={() => this.onClickClose()}>
                                        <i className="m-menu__link-icon la la-dashcube" />
                                        <span className="m-menu__link-title">
                                            <span className="m-menu__link-wrap">
                                                <span className="m-menu__link-text">{language[this.props.indexLanguage].Menubar.dashboard_sa}</span>
                                            </span>
                                        </span>
                                    </NavLink>
                                </li>
                                :
                                ''
                        }
                        {
                            (this.state.dataRole.indexOf(this.state.type + '/timekeeping:view_personal') !== -1)
                                ?
                                <li className="m-menu__item  m-menu__item--submenu" aria-haspopup="true" m-menu-submenu-toggle="hover">
                                    <NavLink to="/kpiscreen" className={"m-menu__link " + active} activeClassName="m-menu__item--active" onClick={() => this.onClickClose()}>
                                        <i className="m-menu__link-icon la la-dashcube" />
                                        <span className="m-menu__link-title">
                                            <span className="m-menu__link-wrap">
                                                <span className="m-menu__link-text">KPI</span>
                                            </span>
                                        </span>
                                    </NavLink>
                                </li>
                                :
                                ''
                        }
                        {
                            (this.state.dataRole.indexOf(this.state.type + '/dashboard:view_all') !== -1 || this.state.dataRole.indexOf(this.state.type + '/dashboard:view_by_manager') !== -1)
                                ?
                                <li className="m-menu__item  m-menu__item--submenu" aria-haspopup="true" m-menu-submenu-toggle="hover">
                                    <NavLink to="/dashboard" className={"m-menu__link " + active} activeClassName="m-menu__item--active" onClick={() => this.onClickClose()}>
                                        <i className="m-menu__link-icon flaticon-imac" />
                                        <span className="m-menu__link-title">
                                            <span className="m-menu__link-wrap">
                                                <span className="m-menu__link-text">{language[this.props.indexLanguage].Menubar.dashboard}</span>
                                            </span>
                                        </span>
                                    </NavLink>
                                </li>
                                :
                                ''
                        }
                        {/* {
                            this.state.dataRole.indexOf(this.state.type + '/view_camera:view') !== -1
                                ?
                                <li className="m-menu__item  m-menu__item--submenu hide_mobie" aria-haspopup="true" m-menu-submenu-toggle="hover">
                                    <NavLink to="/viewcamera" className={"m-menu__link "} activeClassName="m-menu__item--active" onClick={() => this.onClickClose()}>
                                        <i className="m-menu__link-icon la la-video-camera" />
                                        <span className="m-menu__link-title">
                                            <span className="m-menu__link-wrap">
                                                <span className="m-menu__link-text">{language[this.props.indexLanguage].Menubar.viewcamera}</span>
                                            </span>
                                        </span>
                                    </NavLink>
                                </li>
                                :
                                ''
                        } */}
                        {
                            this.state.dataRole.indexOf(this.state.type + '/timekeeping:view_personal') !== -1
                                ||
                                this.state.dataRole.indexOf(this.state.type + '/timekeeping:view_all') !== -1
                                ||
                                this.state.dataRole.indexOf(this.state.type + '/timekeeping:view_by_manager') !== -1
                                ||
                                this.state.dataRole.indexOf(this.state.type + '/history:view_personal') !== -1
                                ||
                                this.state.dataRole.indexOf(this.state.type + '/history:view_all') !== -1
                                ||
                                this.state.dataRole.indexOf(this.state.type + '/statistical:view') !== -1
                                ?
                                <li className="m-menu__item  m-menu__item--submenu" aria-haspopup="true" m-menu-submenu-toggle="hover">
                                    <a href="#/" className="m-menu__link m-menu__toggle">
                                        <i className="m-menu__link-icon la la-exchange" />
                                        <span className="m-menu__link-text">{language[this.props.indexLanguage].Menubar.in_out}</span>
                                        <i className="m-menu__ver-arrow la la-angle-right" />
                                    </a>
                                    <div className="m-menu__submenu ">
                                        <span className="m-menu__arrow" />
                                        <ul className="m-menu__subnav">
                                            {
                                                this.state.dataRole.indexOf(this.state.type + '/timekeeping:view_all') !== -1 || this.state.dataRole.indexOf(this.state.type + '/timekeeping:view_by_manager') !== -1
                                                    ?
                                                    <li className="m-menu__item " aria-haspopup="true">
                                                        <NavLink to="/timekeeping" className="m-menu__link " activeClassName="m-menu__item--active" onClick={() => this.onClickClose()}>
                                                            <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                                                                <span />
                                                            </i>
                                                            <span className="m-menu__link-text">{language[this.props.indexLanguage].Menubar.timekeeping}</span>
                                                        </NavLink>
                                                    </li>
                                                    :
                                                    ''
                                            }
                                            {
                                                this.state.dataRole.indexOf(this.state.type + '/timekeeping:view_personal') !== -1
                                                    ?
                                                    <li className="m-menu__item " aria-haspopup="true">
                                                        <NavLink to="/timekeeping_personal" className="m-menu__link " activeClassName="m-menu__item--active" onClick={() => this.onClickClose()}>
                                                            <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                                                                <span />
                                                            </i>
                                                            <span className="m-menu__link-text">{language[this.props.indexLanguage].Menubar.timekeeping_personal}</span>
                                                        </NavLink>
                                                    </li>
                                                    :
                                                    ''
                                            }
                                            {
                                                this.state.dataRole.indexOf(this.state.type + '/history:view_personal') !== -1
                                                    ||
                                                    this.state.dataRole.indexOf(this.state.type + '/history:view_all') !== -1
                                                    ?
                                                    <li className="m-menu__item " aria-haspopup="true">
                                                        <NavLink to="/history" className="m-menu__link " activeClassName="m-menu__item--active" onClick={() => this.onClickClose()}>
                                                            <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                                                                <span />
                                                            </i>
                                                            <span className="m-menu__link-text">{language[this.props.indexLanguage].Menubar.history}</span>
                                                        </NavLink>
                                                    </li>
                                                    :
                                                    ''
                                            }
                                            {/* {
                                                this.state.dataRole.indexOf(this.state.type + '/warning_in_out:view') !== -1
                                                    ?
                                                    <li className="m-menu__item " aria-haspopup="true">
                                                        <NavLink to="/warning_in_out" className="m-menu__link " activeClassName="m-menu__item--active" onClick={() => this.onClickClose()}>
                                                            <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                                                                <span />
                                                            </i>
                                                            <span className="m-menu__link-text">{language[this.props.indexLanguage].Menubar.warning_in_out}</span>
                                                        </NavLink>
                                                    </li>
                                                    :
                                                    ''
                                            } */}
                                            {
                                                this.state.dataRole.indexOf(this.state.type + '/statistical:view') !== -1
                                                    ?
                                                    <li className="m-menu__item " aria-haspopup="true">
                                                        <NavLink to="/statistical" className="m-menu__link " activeClassName="m-menu__item--active" onClick={() => this.onClickClose()}>
                                                            <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                                                                <span />
                                                            </i>
                                                            <span className="m-menu__link-text">{language[this.props.indexLanguage].Menubar.statistical}</span>
                                                        </NavLink>
                                                    </li>
                                                    :
                                                    ''
                                            }
                                        </ul>
                                    </div>
                                </li>
                                :
                                ''
                        }
                        {
                            <li className="m-menu__item  m-menu__item--submenu" aria-haspopup="true" m-menu-submenu-toggle="hover">
                                <NavLink to="/go-late-approval" className={"m-menu__link " + active} activeClassName="m-menu__item--active" onClick={() => this.onClickClose()}>
                                    <i className="m-menu__link-icon fa fa-check-square" />
                                    <span className="m-menu__link-title">
                                        <span className="m-menu__link-wrap">
                                            <span className="m-menu__link-text">{language[this.props.indexLanguage].Menubar.go_late_approval}</span>
                                        </span>
                                    </span>
                                </NavLink>
                            </li>
                        }
                        <li className="m-menu__item  m-menu__item--submenu" aria-haspopup="true" m-menu-submenu-toggle="hover">
                            <a href="#/" className="m-menu__link m-menu__toggle">
                                <i className="m-menu__link-icon la la-calendar" />
                                <span className="m-menu__link-text">{language[this.props.indexLanguage].Menubar.applications_and_request}</span>
                                <i className="m-menu__ver-arrow la la-angle-right" />
                            </a>
                            <div className="m-menu__submenu ">
                                <span className="m-menu__arrow" />
                                <ul className="m-menu__subnav">
                                    <li className="m-menu__item " aria-haspopup="true">
                                        <NavLink to="/leave-of-absence-letter/2" className="m-menu__link " activeClassName="m-menu__item--active" onClick={() => this.onClickClose()}>
                                            <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                                                <span />
                                            </i>
                                            <span className="m-menu__link-text">{language[this.props.indexLanguage].Menubar.take_leave}</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div className="m-menu__submenu ">
                                <span className="m-menu__arrow" />
                                <ul className="m-menu__subnav">
                                    <li className="m-menu__item " aria-haspopup="true">
                                        <NavLink to="/signup-for-overtime/2" className="m-menu__link " activeClassName="m-menu__item--active" onClick={() => this.onClickClose()}>
                                            <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                                                <span />
                                            </i>
                                            <span className="m-menu__link-text">{language[this.props.indexLanguage].Menubar.signup_for_overtime}</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div className="m-menu__submenu ">
                                <span className="m-menu__arrow" />
                                <ul className="m-menu__subnav">
                                    <li className="m-menu__item " aria-haspopup="true">
                                        <NavLink to="/go-on-business/2" className="m-menu__link " activeClassName="m-menu__item--active" onClick={() => this.onClickClose()}>
                                            <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                                                <span />
                                            </i>
                                            <span className="m-menu__link-text">{language[this.props.indexLanguage].Menubar.go_on_business}</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div className="m-menu__submenu ">
                                <span className="m-menu__arrow" />
                                <ul className="m-menu__subnav">
                                    <li className="m-menu__item " aria-haspopup="true">
                                        <NavLink to="/go-late-leave-early/2" className="m-menu__link " activeClassName="m-menu__item--active" onClick={() => this.onClickClose()}>
                                            <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                                                <span />
                                            </i>
                                            <span className="m-menu__link-text">{language[this.props.indexLanguage].Menubar.go_late_leave_early}</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div className="m-menu__submenu ">
                                <span className="m-menu__arrow" />
                                <ul className="m-menu__subnav">
                                    <li className="m-menu__item " aria-haspopup="true">
                                        <NavLink to="/timekeeping-compensate/2" className="m-menu__link " activeClassName="m-menu__item--active" onClick={() => this.onClickClose()}>
                                            <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                                                <span />
                                            </i>
                                            <span className="m-menu__link-text">{language[this.props.indexLanguage].Menubar.timekeeping_compensate}</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div className="m-menu__submenu ">
                                <span className="m-menu__arrow" />
                                <ul className="m-menu__subnav">
                                    <li className="m-menu__item " aria-haspopup="true">
                                        <NavLink to="/long-term/2" className="m-menu__link " activeClassName="m-menu__item--active" onClick={() => this.onClickClose()}>
                                            <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                                                <span />
                                            </i>
                                            <span className="m-menu__link-text">{language[this.props.indexLanguage].Menubar.long_term}</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                            {
                                this.state.dataRole.indexOf(this.state.type + '/statistical_leave_of_absence:view') !== -1
                                &&
                                <div className="m-menu__submenu ">
                                    <span className="m-menu__arrow" />
                                    <ul className="m-menu__subnav">
                                        <li className="m-menu__item " aria-haspopup="true">
                                            <NavLink to="/statistical-leave-of-absence" className="m-menu__link " activeClassName="m-menu__item--active" onClick={() => this.onClickClose()}>
                                                <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                                                    <span />
                                                </i>
                                                <span className="m-menu__link-text">{language[this.props.indexLanguage].Menubar.statistical_leave_of_absence}</span>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            }
                            {
                                this.state.dataRole.indexOf(this.state.type + '/config:insert_or_update') !== -1 &&
                                <div className="m-menu__submenu ">
                                    <span className="m-menu__arrow" />
                                    <ul className="m-menu__subnav">
                                        <li className="m-menu__item " aria-haspopup="true">
                                            <NavLink to="/reason-off" className="m-menu__link " activeClassName="m-menu__item--active" onClick={() => this.onClickClose()}>
                                                <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                                                    <span />
                                                </i>
                                                <span className="m-menu__link-text">{language[this.props.indexLanguage].Menubar.reason_off}</span>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>

                            }
                        </li>
                        {
                            this.state.dataRole.indexOf(this.state.type + '/capture:view') !== -1
                                ||
                                this.state.dataRole.indexOf(this.state.type + '/sub_model:view') !== -1
                                ?
                                <li className="m-menu__item  m-menu__item--submenu" aria-haspopup="true" m-menu-submenu-toggle="hover">
                                    <a href="#/" className="m-menu__link m-menu__toggle">
                                        <i className="m-menu__link-icon flaticon-profile" />
                                        <span className="m-menu__link-text">{language[this.props.indexLanguage].Menubar.face_management}</span>
                                        <i className="m-menu__ver-arrow la la-angle-right" />
                                    </a>
                                    <div className="m-menu__submenu ">
                                        <span className="m-menu__arrow" />
                                        <ul className="m-menu__subnav">
                                            {
                                                this.state.dataRole.indexOf(this.state.type + '/capture:view') !== -1
                                                    ?
                                                    <li className="m-menu__item " aria-haspopup="true">
                                                        <NavLink to={location} className="m-menu__link " activeClassName="m-menu__item--active" onClick={() => this.onClickClose()}>
                                                            <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                                                                <span />
                                                            </i>
                                                            <span className="m-menu__link-text">{language[this.props.indexLanguage].Menubar.capture}</span>
                                                        </NavLink>
                                                    </li>
                                                    :
                                                    ''
                                            }
                                            {
                                                this.state.dataRole.indexOf(this.state.type + '/sub_model:view') !== -1
                                                    ?

                                                    <li className={"m-menu__item "} aria-haspopup="true">
                                                        <NavLink to="/submodel" className="m-menu__link " activeClassName="m-menu__item--active" onClick={() => this.onClickClose()}>
                                                            <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                                                                <span />
                                                            </i>
                                                            <span className="m-menu__link-text">{language[this.props.indexLanguage].Menubar.submodel}</span>
                                                        </NavLink>
                                                    </li>
                                                    :
                                                    ''
                                            }
                                        </ul>
                                    </div>
                                </li>
                                :
                                ""
                        }

                        {
                            this.state.dataRole.indexOf(this.state.type + '/door:view') !== -1
                                ||
                                this.state.dataRole.indexOf(this.state.type + '/door_management:view') !== -1
                                ?
                                <li className="m-menu__item  m-menu__item--submenu" aria-haspopup="true" m-menu-submenu-toggle="hover">
                                    <a href="#/" className="m-menu__link m-menu__toggle">
                                        <i className="m-menu__link-icon flaticon-logout" />
                                        <span className="m-menu__link-text">{language[this.props.indexLanguage].Menubar.door_management}</span>
                                        <i className="m-menu__ver-arrow la la-angle-right" />
                                    </a>
                                    <div className="m-menu__submenu ">
                                        <span className="m-menu__arrow" />
                                        <ul className="m-menu__subnav">
                                            {
                                                this.state.dataRole.indexOf(this.state.type + '/door:view') !== -1
                                                    ?
                                                    <li className="m-menu__item " aria-haspopup="true">
                                                        <NavLink to="/door" className="m-menu__link " activeClassName="m-menu__item--active" onClick={() => this.onClickClose()}>
                                                            <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                                                                <span />
                                                            </i>
                                                            <span className="m-menu__link-text">{language[this.props.indexLanguage].Menubar.door}</span>
                                                        </NavLink>
                                                    </li>
                                                    :
                                                    ''
                                            }
                                            {
                                                this.state.dataRole.indexOf(this.state.type + '/door_management:view') !== -1
                                                    ?
                                                    <li className="m-menu__item " aria-haspopup="true">
                                                        <NavLink to="/door_management" className="m-menu__link " activeClassName="m-menu__item--active" onClick={() => this.onClickClose()}>
                                                            <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                                                                <span />
                                                            </i>
                                                            <span className="m-menu__link-text">{language[this.props.indexLanguage].Menubar.open_door}</span>
                                                        </NavLink>
                                                    </li>
                                                    :
                                                    ''
                                            }
                                        </ul>
                                    </div>
                                </li>
                                :
                                ""
                        }

                        {
                            this.state.dataRole.indexOf(this.state.type + '/staff:view_all') !== -1
                                ||
                                this.state.dataRole.indexOf(this.state.type + '/staff:view_by_manager') !== -1
                                ||
                                this.state.dataRole.indexOf(this.state.type + '/department:view_all') !== -1
                                ||
                                this.state.dataRole.indexOf(this.state.type + '/department:view_by_manager') !== -1
                                ||
                                this.state.dataRole.indexOf(this.state.type + '/group:view') !== -1
                                ||
                                this.state.dataRole.indexOf(this.state.type + '/level:view') !== -1
                                ||
                                this.state.dataRole.indexOf(this.state.type + '/shift_work:view') !== -1
                                ?
                                <li className="m-menu__item  m-menu__item--submenu" aria-haspopup="true" m-menu-submenu-toggle="hover">
                                    <a href="#/" className="m-menu__link m-menu__toggle">
                                        <i className="m-menu__link-icon flaticon-map" />
                                        <span className="m-menu__link-text">{language[this.props.indexLanguage].Menubar.organization}</span>
                                        <i className="m-menu__ver-arrow la la-angle-right" />
                                    </a>
                                    <div className="m-menu__submenu ">
                                        <span className="m-menu__arrow" />
                                        <ul className="m-menu__subnav">
                                            {
                                                (this.state.dataRole.indexOf(this.state.type + '/staff:view_by_manager') !== -1 || this.state.dataRole.indexOf(this.state.type + '/staff:view_all') !== -1)
                                                    ?
                                                    <li className="m-menu__item " aria-haspopup="true">
                                                        <NavLink to="/staff" className="m-menu__link " activeClassName="m-menu__item--active" onClick={() => this.onClickClose()}>
                                                            <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                                                                <span />
                                                            </i>
                                                            <span className="m-menu__link-text">{language[this.props.indexLanguage].Menubar.staffs}</span>
                                                        </NavLink>
                                                    </li>
                                                    :
                                                    ''
                                            }
                                            {
                                                (this.state.dataRole.indexOf(this.state.type + '/department:view_by_manager') !== -1 || this.state.dataRole.indexOf(this.state.type + '/department:view_all') !== -1)
                                                    ?
                                                    <li className="m-menu__item " aria-haspopup="true">
                                                        <NavLink to="/department" className="m-menu__link " activeClassName="m-menu__item--active" onClick={() => this.onClickClose()}>
                                                            <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                                                                <span />
                                                            </i>
                                                            <span className="m-menu__link-text">{language[this.props.indexLanguage].Menubar.departments}</span>
                                                        </NavLink>
                                                    </li>
                                                    :
                                                    ''
                                            }
                                            {
                                                this.state.dataRole.indexOf(this.state.type + '/group:view') !== -1
                                                    ?
                                                    <li className="m-menu__item " aria-haspopup="true">
                                                        <NavLink to="/group" className="m-menu__link " activeClassName="m-menu__item--active" onClick={() => this.onClickClose()}>
                                                            <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                                                                <span />
                                                            </i>
                                                            <span className="m-menu__link-text">{language[this.props.indexLanguage].Menubar.groups}</span>
                                                        </NavLink>
                                                    </li>
                                                    :
                                                    ''
                                            }
                                            {
                                                this.state.dataRole.indexOf(this.state.type + '/level:view') !== -1
                                                    ?
                                                    <li className="m-menu__item " aria-haspopup="true">
                                                        <NavLink to="/level" className="m-menu__link " activeClassName="m-menu__item--active" onClick={() => this.onClickClose()}>
                                                            <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                                                                <span />
                                                            </i>
                                                            <span className="m-menu__link-text">{language[this.props.indexLanguage].Menubar.levels}</span>
                                                        </NavLink>
                                                    </li>
                                                    :
                                                    ''
                                            }
                                            {
                                                this.state.dataRole.indexOf(this.state.type + '/shift_work:view') !== -1
                                                    ?
                                                    <li className="m-menu__item " aria-haspopup="true">
                                                        <NavLink to="/shift" className="m-menu__link " activeClassName="m-menu__item--active" onClick={() => this.onClickClose()}>
                                                            <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                                                                <span />
                                                            </i>
                                                            <span className="m-menu__link-text">{language[this.props.indexLanguage].Menubar.shifts}</span>
                                                        </NavLink>
                                                    </li>
                                                    :
                                                    ''
                                            }
                                            {
                                                this.state.type_login == 1
                                                    ?
                                                    <li className="m-menu__item " aria-haspopup="true">
                                                        <NavLink to="/role" className="m-menu__link " activeClassName="m-menu__item--active" onClick={() => this.onClickClose()}>
                                                            <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                                                                <span />
                                                            </i>
                                                            <span className="m-menu__link-text">{language[this.props.indexLanguage].Menubar.Role}</span>
                                                        </NavLink>
                                                    </li>
                                                    :
                                                    ''
                                            }
                                        </ul>
                                    </div>
                                </li>
                                :
                                ""
                        }
                        {

                            <li className="m-menu__item  m-menu__item--submenu" aria-haspopup="true" m-menu-submenu-toggle="hover">
                                <a href="#/" className="m-menu__link m-menu__toggle">
                                    <i className="m-menu__link-icon flaticon-book" />
                                    <span className="m-menu__link-text">{language[this.props.indexLanguage].Menubar.utilities}</span>
                                    <i className="m-menu__ver-arrow la la-angle-right" />
                                </a>
                                <div className="m-menu__submenu ">
                                    <span className="m-menu__arrow" />
                                    <ul className="m-menu__subnav">
                                        {
                                            this.state.dataRole.indexOf(this.state.type + '/appointment:view') != -1
                                            &&
                                            <li className="m-menu__item " aria-haspopup="true">
                                                <NavLink to="/appointment" className="m-menu__link " activeClassName="m-menu__item--active" onClick={() => this.onClickClose()}>
                                                    <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                                                        <span />
                                                    </i>
                                                    <span className="m-menu__link-text">{language[this.props.indexLanguage].Menubar.appointment}</span>
                                                </NavLink>
                                            </li>
                                        }
                                    </ul>
                                </div>
                            </li>
                        }
                        {
                            this.state.dataRole.indexOf(this.state.type + '/config:insert_or_update') !== -1
                                ||
                                this.state.dataRole.indexOf(this.state.type + '/config:get_info') !== -1
                                ?
                                <li className="m-menu__item  m-menu__item--submenu" aria-haspopup="true" m-menu-submenu-toggle="hover">
                                    <NavLink to="/settings" className={"m-menu__link "} activeClassName="m-menu__item--active" onClick={() => this.onClickClose()}>
                                        <i className="m-menu__link-icon flaticon-settings" />
                                        <span className="m-menu__link-title">
                                            <span className="m-menu__link-wrap">
                                                <span className="m-menu__link-text">{language[this.props.indexLanguage].Infor.settings}</span>
                                            </span>
                                        </span>
                                    </NavLink>
                                </li>
                                :
                                ''
                        }

                    </ul>
                </div>
                {/* END: Aside Menu */}
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        indexLanguage: state.changelanguage.indexLanguage,
    }
}


export default withRouter(connect(mapStateToProps, null)(MenuBar));