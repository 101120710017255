import React, { Component } from 'react';
import {withRouter} from 'react-router';
import Store from '../../../../../store';
import {HOST} from '../../../../../config';
import './ShowImg.css';
import PropTypes from 'prop-types';

class ShowImg extends Component{
	constructor(props){
		super(props);
		this.state = {
            src: this.props.src,            
            onHide: this.props.onHide,
            show: this.props.show
        };
    }
    
    componentWillReceiveProps(nextProps) {
        this.setState({
            src: nextProps.src,
            onHide: nextProps.onHide,
            show: nextProps.show
        }, () => {
        })
       
    }

	render(){
        return(
            <div className='pnImg' style={{'display': (this.state.show == true ? '' : 'none')}}>
                <div className='pnContent'>
                    <img height='200px' src={this.state.src}></img>
                    <div className='Mask' title='Remove Img'><a href='#' onClick={() => {
                        this.state.onHide();
                    }}>X</a></div>
                </div>
            </div>
        )
    }
}

ShowImg.propTypes = {
    src: PropTypes.string,
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool,
}

export default withRouter(ShowImg);