import axios from 'axios';

export const getByCode = async (url, company_code) => {
    const result = await axios({
        method: 'GET',
        url: `${url}/api/company/get_company_by_code/${company_code}`,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    }).catch(err => {
        return 'error';
    });
    return result.data;
}

export const apiGetInfoStaff = async (url, id_company, id_staff, token) => {
    const result = await axios({
        method: 'GET',
        url: `${url}/api/staff/get_by_code/${id_company}/${id_staff}`,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token,
        },
    }).catch(err => {
        return 'error';
    });
    return result.data;
}