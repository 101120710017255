import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Table from 'react-bootstrap/Table';
import InfiniteScroll from "react-infinite-scroll-component";

ViewTable.propTypes = {
    csvData: PropTypes.array,
    fields: PropTypes.array,
    fieldHeader: PropTypes.array,
};

ViewTable.defaultProps = {
    csvData: null,
    fields: null,
    fieldHeader: null,
}

function ViewTable(props) {
    const { csvData, fields, fieldHeader } = props;
    const [index, setIndex] = useState(1);
    const items = 100;
    const [data, setData] = useState(null);

    const fetchMoreData = () => {
        if (csvData) {
            const i = index + 1;
            setIndex(i);
            setData(csvData.slice(0, (index + 1) * items));
        }
    }
    const tableHeader = <thead>
        <tr>
            <th>ID</th>
            {
                fieldHeader && fieldHeader.map((map, i) => (
                    <th key={i}>{map}</th>
                ))
            }
        </tr>
    </thead>

    useEffect(() => {
        if (csvData) {
            setData(csvData.slice(0, index * items))
        } else {
            setData(null)
        }
        return setIndex(1);
    }, [csvData]);

    return (
        <InfiniteScroll
            dataLength={data}
            next={fetchMoreData}
            hasMore={true}
            height={`calc(100vh - 360px)`}
        >
            <div className='table-responsive text-nowrap mt-3' id='tableData'>
                <table className='table table-bordered bg-white table-hover table-checkable dataTable no-footer dtr-inline collapsed mb-0'>
                    {tableHeader}
                    <tbody>
                        {data && data.map((data, index) => (
                            <tr key={index} className='even'>
                                {fields && fields.map((fields, j) => (<td style={{ color: `${(data.text_time_in || data.text_time_out) && j === 6 ? "red" : ""}` }} key={j}>{data[fields]}</td>))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </InfiniteScroll>
    );
}

export default ViewTable;

