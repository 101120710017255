import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import language from '../../../../language/language';
import Store from '../../../../store';

class ModalProgress extends Component {

    constructor(props) {
        super(props);
        this.state = {
            language: Store.getState().changelanguage.language,
            indexlanguage: 0,
            progressImport: 0
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.show === true) {
            this.setState({
                progressImport: nextProps.importProgress,
            });
        }
    }



    render() {
        return (
            <Modal
                {...this.props}
                size="md"
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                
            >
                <Modal.Body>
                    <div className="pb-3">
                        <div className="text-center">
                            <img src="/img/import.png" width='25%' className="m--img-centered text-center" alt="" />
                        </div>
                        <h4 className="pb-1 text-center">{language[this.props.indexLanguage].Text_Notifi.progress_import}</h4>
                        <div className="progress m-progress--lg">
                            <div className="progress-bar m--bg-brand progress-bar-striped bg-success" role="progressbar" style={{ width: `${this.state.progressImport}%` }} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100}>
                                <span className="countdown-holder">{parseInt(this.state.progressImport)}%</span>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

ModalProgress.propTypes = {
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool,
}
const mapStateToProps = state => {
    return {
        indexLanguage: state.changelanguage.indexLanguage,
    }
}
export default connect(mapStateToProps, null)(ModalProgress);