import { css } from '@emotion/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ExcelExport, ExcelExportColumn, ExcelExportColumnGroup } from '@progress/kendo-react-excel-export';
import '@progress/kendo-react-intl';
import swals from '@sweetalert/with-react';
import $ from 'jquery';
import Moment from 'moment';
import QueueAnim from 'rc-queue-anim';
import React, { Component } from 'react';
import { Button, Col } from 'react-bootstrap';
import ReactFileReader from 'react-file-reader';
import Pagination from "react-js-pagination";
import LoadingOverlay from 'react-loading-overlay';
import { Redirect } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import FadeLoader from 'react-spinners/FadeLoader';
import openSocket from 'socket.io-client';
import swal from 'sweetalert';
import 'sweetalert2/src/sweetalert2.scss';
import { ARRAY_ENGINES } from '../../../../config';
import language from '../../../../language/language';
import Store from '../../../../store';
import ModalMoveImg from "./ModalMoveImg";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import ReactGA from 'react-ga'
import { formatTitleOption, formatTreeViewData } from '../../CommonFunction';
import { withStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux'
import TreeView from '../../TreeView';
import Header from '../../../layouts/Header';

const useStyles = theme => ({
    paper: {
        whiteSpace: "nowrap"
    }
});

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

var timesetInterval;
const nameTab = 'capture'

function compare(a, b) {
    if (a.countImg > b.countImg) {
        return 1
    } else {
        if (a.countImg < b.countImg) {
            return -1;
        }
        else {
            if (a.title > b.title) {
                return 1;
            }
            else if (a.title < b.title) {
                return -1;
            }
        }
    }
}

class Capture extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogin: Store.getState().isLogin.isLogin,
            access_token: Store.getState().isLogin.access_token,
            box_engine_cf: Store.getState().boxai.box_engine_cf,
            crrApiSocket: Store.getState().boxai.box_engine_cf.crrApiSocket,
            crrApiSocket2: Store.getState().boxai.box_engine_cf.crrApiSocket2,
            crrApi: Store.getState().boxai.box_engine_cf.crrApi,
            crrApiImg: Store.getState().boxai.box_engine_cf.crrApiImg,
            idCompany: Store.getState().boxai.box_engine_cf.idCompany,
            dataCamera: Store.getState().boxai.box_engine_cf.dataCamera,
            language: Store.getState().changelanguage.language,
            dataRole: Store.getState().role.role,
            type: '',
            listCompany: [],
            dataStaff: [],
            listShift: [],
            listDepartment: [],
            imgError: [],
            crrImages: [],
            listImage: [],
            data_json_export: [],
            data_json_exportNotImage: [],
            dataObjStaff: [],
            listChecked: {
                valueImg: [],
                _id: [],
            },
            max_training_images: 0,
            max_upload_images: 0,
            number_of_faces: 0,
            number_of_for: 0,
            number_of_msg: 0,
            files_length: 0,
            crrIdx: 0,
            activePage: 1,
            showFirst: 0,
            showLast: 0,
            totalLength: 0,
            percentSuccess: 0,
            idCamera: '',
            toStaff: '',
            isActive: true,
            activeCapture: false,
            UpdateDataToDevice: false,
            checkDeleteMulti: false,
            readOnly: false,
            isChecked: true,
            checkedMale: true,
            checkedFemale: false,
            UploadFaces: false,
            stopFor: false,
            loadingExport: false,
            loading_img_trainning: false,
            loading_countStaffImg: true,
            openTakeCapture: false,
            disabledBtnCapture: false,
            imgHistory: [],
            disabledBtnHistory: true,
            Staff: {
                id: "",
                id_staff: "",
                name: "",
                department: null,
                abbreviated_name: "",
                birthday: "",
                gender: 1,
                id_department: '',
                id_level: '',
                is_active: 1,
                is_delete: 0,
                ids_shift: [],
            },
            Staff_img: {
                id: "",
                id_staff: "",
                name: "",
                abbreviated_name: "",
                birthday: "",
                gender: 1,
                id_department: '',
                id_level: '',
                is_active: 1,
                is_delete: 0,
                ids_shift: [],
            },
            optionDepartment: [],
            optionLevel: [],
            valueDepartment: '0',
            valueLevel: '0',
            crrBirthday: '',
            images: [],
            dataCountStaff: [],
            coutStaffImg: 0,
            totalStaff: 0,
            lengthLoadPopup: 0,
            valueStaff: { title: '' },
            crrCamera: { title: '' },
            modalMoveImage: false,
            dataCameraAll: [],
            sumCountImg: 0,
            selectDepartment: false
        };

        var itemsPerPage = 1;
        if ($(window).width() < 768) {
            itemsPerPage = 9
        } else {
            itemsPerPage = 18
        }
        this.itemsPerPage = itemsPerPage;

        Store.subscribe(() => {
            $('#count_img').html(0)
            this.setState({
                ...this.state,
                isActive: true,
                isLogin: Store.getState().isLogin.isLogin,
                access_token: Store.getState().isLogin.access_token,
                box_engine_cf: Store.getState().boxai.box_engine_cf,
                crrApiSocket: Store.getState().boxai.box_engine_cf.crrApiSocket,
                crrApiSocket2: Store.getState().boxai.box_engine_cf.crrApiSocket2,
                crrApi: Store.getState().boxai.box_engine_cf.crrApi,
                crrApiImg: Store.getState().boxai.box_engine_cf.crrApiImg,
                idCompany: Store.getState().boxai.box_engine_cf.idCompany,
                dataCamera: Store.getState().boxai.box_engine_cf.dataCamera,
                language: Store.getState().changelanguage.language,
                valueStaff: { title: '' },
                listStaff: [],
                crrImages: [],
                listImage: [],
                dataObjStaff: [],
                coutStaffImg: 0,
                totalStaff: 0,
                max_training_images: 0,
                loading_countStaffImg: true,
                showFirst: 0,
                showLast: 0,
                totalLength: 0,
                percentSuccess: 0,
                loadingExport: false,
                openTakeCapture: false,
                data_json_export: [],
                data_json_exportNotImage: [],
                imgHistory: [],
                dataCameraAll: [],
                selectDepartment: false
            }, () => {
                var crrCamera = { title: '' };
                var dataCameraAll = [];
                if (this.state.box_engine_cf.dataCamera !== undefined) {
                    for (let index = 0; index < this.state.box_engine_cf.dataCamera.length; index++) {
                        dataCameraAll.push(this.state.box_engine_cf.dataCamera[index].id_box_engine)
                    }
                    if (this.state.box_engine_cf.dataCamera.length > 0) {
                        crrCamera = { title: this.state.dataCamera[0].alias_box_engine ? `${this.state.dataCamera[0].alias_box_engine} ( ${this.state.dataCamera[0].id_box_engine} )` : this.state.dataCamera[0].id_box + '_' + this.state.dataCamera[0].id_box_engine, value: this.state.dataCamera[0].id_box_engine, idBox: this.state.dataCamera[0].id_box, sub_engine: this.state.dataCamera[0].sub_engine_id, dataCamera: this.state.dataCamera[0] }
                    }
                }
                this.setState({
                    crrCamera: crrCamera,
                    dataCameraAll: dataCameraAll
                }, () => {
                    this.getAll();
                })

            });
        });
    }

    componentWillMount() {
        var dataCameraAll = [];
        var crrCamera = { title: '' };
        if (this.state.box_engine_cf.dataCamera !== undefined) {
            for (let index = 0; index < this.state.box_engine_cf.dataCamera.length; index++) {
                dataCameraAll.push(this.state.box_engine_cf.dataCamera[index].id_box_engine)
            }
            if (this.state.box_engine_cf.dataCamera.length > 0) {
                crrCamera = { title: this.state.dataCamera[0].alias_box_engine ? `${this.state.dataCamera[0].alias_box_engine} ( ${this.state.dataCamera[0].id_box_engine} )` : this.state.dataCamera[0].id_box + '_' + this.state.dataCamera[0].id_box_engine, value: this.state.dataCamera[0].id_box_engine, idBox: this.state.dataCamera[0].id_box, sub_engine: this.state.dataCamera[0].sub_engine_id, dataCamera: this.state.dataCamera[0] }
            }
        }

        var type = localStorage.getItem("type")

        this.setState({
            type: type,
            crrCamera: crrCamera,
            lengthLoadPopup: 0,
            dataCameraAll: dataCameraAll,
        }, () => {
            this.getAll();
        })
    }

    getAll = () => {
        this.getDepartment()
    }

    //GetActionHeader
    getDepartment = () => {
        if (this.state.crrApiSocket === undefined) return;
        fetch(`${this.state.crrApiSocket}/api/department/${this.state.box_engine_cf.idCompany}/get_all`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                var optionDepartment = [{ 'value': -1, 'title': language[this.props.indexLanguage].placeholderSelect.all_department }]
                for (let index = 0; index < data.data.length; index++) {
                    optionDepartment.push({ 'value': data.data[index].id, 'title': data.data[index].name, 'code': data.data[index].code })
                }

                this.setState({
                    listDepartment: [...data.data],
                    optionDepartment: optionDepartment,
                    valueDepartment: (this.props.location.state && this.props.history.action === 'PUSH') ? this.props.location.state.department : optionDepartment.length > 1 ? optionDepartment[1] : optionDepartment[0],
                    valueStaff: { title: '' },
                    listStaff: [],
                    crrImages: [],
                    listImage: [],
                    coutStaffImg: 0,
                    totalStaff: 0,
                    max_training_images: 0,
                    Staff: {
                        id: "",
                        id_staff: "",
                        name: "",
                        department: null,
                        abbreviated_name: "",
                        birthday: "",
                        gender: 1,
                        id_department: '',
                        id_level: '',
                        is_active: 1,
                        is_delete: 0,
                        ids_shift: [],
                    },
                    Staff_img: {
                        id: "",
                        id_staff: "",
                        name: "",
                        abbreviated_name: "",
                        birthday: "",
                        gender: 1,
                        id_department: '',
                        id_level: '',
                        is_active: 1,
                        is_delete: 0,
                        ids_shift: [],
                    },
                }, () => {
                    this.dataGetObjStaff();
                });
            } else {
                this.setState({
                    loading_countStaffImg: false
                })
            }
        }).catch((error) => {
            if (error) {
                this.setState({
                    loading_countStaffImg: false
                })
            }
        })
    }

    dataGetObjStaff = (resetStaff) => {
        fetch(`${this.state.crrApiSocket}/api/staff/get_by_department`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            body: JSON.stringify({
                'id_company': this.state.box_engine_cf.idCompany,
                'id_department': this.state.valueDepartment.value
            })
        }).then((response) => {
            return response.json()
        }).then((data) => {
            var dataObjStaff = []
            if (data.status === 10000) {
                for (let index = 0; index < data.data.length; index++) {
                    dataObjStaff.push(data.data[index].object_id)
                }
                this.setState({
                    dataObjStaff: dataObjStaff,
                    dataStaff: data.data
                }, () => {
                    this.getCountStaff(resetStaff);
                });
            } else {
                this.setState({
                    loading_countStaffImg: false
                })
            }
        }).catch((error) => {
            if (error) {
                this.setState({
                    loading_countStaffImg: false
                })
            }
        })
    }

    getCountStaff = (resetStaff) => {
        if (this.state.crrApi === undefined) return;
        fetch(`${this.state.crrApi}/api/staff/getAllWithCountImage`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            body: JSON.stringify({
                'array_obj_staff': this.state.dataObjStaff
            })
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                var dataCount = []
                for (let index = 0; index < data.staffs.length; index++) {
                    if (data.staffs[index].idStaff != 'unknown') {
                        if (data.staffs[index].count_image > 0) {
                            dataCount.push(data.staffs[index])
                        }
                    }
                }
                var idCamera = "";
                if (this.state.crrCamera !== undefined) {
                    idCamera = this.state.crrCamera.value;
                }

                var { dataStaff } = this.state;
                var dataIMG = [];
                var totalStaff = 0;
                if (dataStaff.length > 0) {
                    dataStaff.map((e, index) => {
                        dataIMG.push({ 'value': e.id, 'title': e.name + ' - ' + e.id_staff + ' ( 0 ) ', 'countImg': 0, 'is_active': e.is_active, 'info_Person': e })
                        for (let i = 0; i < dataCount.length; i++) {
                            if (dataCount[i]._id === e.object_id) {
                                dataIMG.splice(index, 1);
                                dataIMG.push({ 'value': e.id, 'title': e.name + ' - ' + e.id_staff + ' ( ' + dataCount[i].count_image + ' ) ', 'countImg': dataCount[i].count_image, 'is_active': e.is_active, 'info_Person': e })
                                return
                            }
                        }
                    })
                }
                var dataActive = []
                var data_json_exportNotImage = []
                for (let index = 0; index < dataIMG.length; index++) {
                    if (dataIMG[index].is_active !== 0) {
                        if ((this.state.crrCamera.sub_engine !== 2 && dataIMG[index].countImg < 1) || (this.state.crrCamera.sub_engine === 2 && dataIMG[index].countImg < 5)) {
                            var name_department = ""
                            var department = this.state.optionDepartment.filter((value) => {
                                return value.value == dataIMG[index].info_Person.id_department
                            })
                            if (department.length > 0) {
                                name_department = department[0].title
                            }
                            data_json_exportNotImage.push({
                                'code': dataIMG[index].info_Person.id_staff,
                                'name': dataIMG[index].info_Person.name,
                                'abbreviated_name': dataIMG[index].info_Person.abbreviated_name,
                                'department': name_department,
                            })

                        }
                        dataActive.push(dataIMG[index])
                    }
                }
                totalStaff = dataActive.length;

                const orderedNewOptions = dataActive.sort(compare);
                const sumCount = orderedNewOptions.reduce((accumulator, object) => {
                    return accumulator + object.countImg;
                }, 0);

                var coutStaffImg = 0;
                // if (dataCount.length > 0) {
                var countStaffImage = []
                var countStaffImageNoPass = []
                if (this.state.crrCamera.sub_engine !== 2) {
                    orderedNewOptions.forEach(value => {
                        if (value.countImg >= 1) {
                            countStaffImage.push(value)
                        } else {
                            countStaffImageNoPass.push(value)
                        }
                    });
                    // if (countStaffImageNoPass.length > 0) {
                    //     if (this.state.lengthLoadPopup === 0) {
                    //         if (this.props.indexLanguage === 1) {
                    //             Swal.fire({
                    //                 icon: 'warning',
                    //                 title: 'Hệ thống BiFace yêu cầu số lượng ảnh của một nhân viên cần ít nhất là 1 ảnh. Từ 00:00 ngày 13/04/2020, nếu nhân viên không đủ điều kiện thì không thể chấm công được.',
                    //                 html:
                    //                     `<div class="row m-0 mb-4" style="font-size: 15px;">
                    //                         <div class="m-auto">Số lượng nhân viên không đủ điều kiện chấm công: <b>${countStaffImageNoPass.length}/${totalStaff}</b>.</div>
                    //                         <div class="m-auto pt-2">Vui lòng bổ sung thêm ảnh</div>
                    //                     </div>`,
                    //                 confirmButtonText:
                    //                     'Đóng',
                    //             })
                    //         } else {
                    //             Swal.fire({
                    //                 icon: 'warning',
                    //                 title: 'The number of employee’s image that BiFace system requires is 1. From 00:00 13/04/2020, unqualified employees whose image numbers bellow 1 cannot check in or check out. Please make the additional requirement.',
                    //                 html:
                    //                     `<div class="row m-0 mb-4" style="font-size: 15px;">
                    //                         <div class="m-auto">Current unqualified employee: <b>${countStaffImageNoPass.length}/${totalStaff}</b></div>
                    //                     </div>`,
                    //                 confirmButtonText:
                    //                     'Close',
                    //             })
                    //         }
                    //     }
                    // }

                } else {
                    orderedNewOptions.forEach(value => {
                        if (value.countImg >= 1) {
                            countStaffImage.push(value)
                        } else {
                            countStaffImageNoPass.push(value)
                        }
                    });
                    if (countStaffImageNoPass.length > 0) {
                        // if (this.state.lengthLoadPopup === 0) {
                        //     if (this.props.indexLanguage === 1) {
                        //         Swal.fire({
                        //             icon: 'warning',
                        //             title: 'Hệ thống BiFace yêu cầu số lượng ảnh của một nhân viên cần ít nhất là 5 ảnh. Từ 00:00 ngày 13/04/2020, nếu nhân viên không đủ điều kiện thì không thể chấm công được.',
                        //             html:
                        //                 `<div class="row m-0 mb-4" style="font-size: 15px;">
                        //                     <div class="m-auto">Số lượng nhân viên không đủ điều kiện chấm công: <b>${countStaffImageNoPass.length}/${totalStaff}</b>.</div>
                        //                     <div class="m-auto pt-2">Vui lòng bổ sung thêm ảnh</div>
                        //                 </div>`,
                        //             confirmButtonText:
                        //                 'Đóng',
                        //         })
                        //     } else {
                        //         Swal.fire({
                        //             icon: 'warning',
                        //             title: 'The number of employee’s image that BiFace system requires is 5. From 00:00 13/04/2020, unqualified employees whose image numbers bellow 5 cannot check in or check out. Please make the additional requirement.',
                        //             html:
                        //                 `<div class="row m-0 mb-4" style="font-size: 15px;">
                        //                     <div class="m-auto">Current unqualified employee: <b>${countStaffImageNoPass.length}/${totalStaff}</b>.</div>
                        //                 </div>`,
                        //             confirmButtonText:
                        //                 'Close',
                        //         })
                        //     }
                        // }
                    }

                }
                coutStaffImg = countStaffImage.length
                // }
                this.setState({
                    totalStaff: totalStaff,
                    sumCountImg: sumCount,
                    coutStaffImg: coutStaffImg,
                    listStaff: orderedNewOptions,
                    loading_countStaffImg: false,
                    lengthLoadPopup: 1,
                    dataCountStaff: dataCount,
                    idCamera: idCamera,
                    data_json_exportNotImage: data_json_exportNotImage
                }, () => {
                    if (this.props.location.state !== undefined) {
                        this.getStaffByID(this.props.location.state.idStaff, resetStaff)
                    }
                });
            } else {
                this.setState({
                    dataCountStaff: [],
                    loading_countStaffImg: false
                })
            }
        }).catch((error) => {
            if (error) {
                this.setState({
                    dataCountStaff: [],
                    loading_countStaffImg: false
                })
            }
        })
    }

    changeHandleCamera = (valueCamera, e) => {
        var { crrApi, crrApiSocket } = this.state;
        crrApi = e.dataCamera.api_url
        crrApiSocket = e.dataCamera.api2_url
        this.setState({
            crrCamera: e
        })
    }

    handleChangeDepartment = (index, value) => {
        this.setState({
            valueDepartment: value,
            listImage: [],
            crrImages: [],
            dataCountStaff: [],
            checkDeleteMulti: false,
            loading_img_trainning: false,
            listChecked: {
                valueImg: [],
                _id: [],
            },
            Staff: {
                id: "",
                id_staff: "",
                name: "",
                department: null,
                abbreviated_name: "",
                birthday: "",
                gender: 1,
                id_department: '',
                id_level: '',
                is_active: 1,
                is_delete: 0,
                ids_shift: [],
            },
            valueStaff: { title: '' }
        }, () => {
            this.dataGetObjStaff('resetStaff');
        })
    }

    handleChangeStaffs = (index, value) => {
        this.setState({
            valueStaff: value,
            images: [],
            max_training_images: 0,
            max_upload_images: 0,
            activePage: 1,
            toStaff: '',
            loading_img_trainning: true,
            checkDeleteMulti: false,
            listChecked: {
                valueImg: [],
                _id: [],
            },
            listImage: [],
            crrImages: [],
        }, () => {
            $('#count_img').html(0)
            this.getStaffByID(value.value)
        })
    }

    //EndGetActionHeader

    getStaffByID = (idStaff, resetStaff) => {
        if (this.state.crrApiSocket === undefined) return;
        fetch(`${this.state.crrApiSocket}/api/staff/get_by_id/${idStaff}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                window.history.replaceState("", '', 'capture');
                //Level
                var id_level = ""
                if (data.data.level != null) {
                    id_level = data.data.level.id
                }

                //Department
                var id_Department = ""
                if (data.data.department != null) {
                    id_Department = data.data.department.id
                }

                //Shift
                var id_Shift = [];
                if (data.data.staff_shift.length > 0) {
                    for (let index = 0; index < data.data.staff_shift.length; index++) {
                        id_Shift.push(data.data.staff_shift[index].shifts_work.id);
                    }
                }

                //Birthday
                var birthday = ""
                if (data.data.birthday != null) {
                    birthday = Moment(data.data.birthday).format("YYYY-MM-DD")
                }

                //Gender
                var gender = 1
                if (data.data.gender != null) {
                    gender = data.data.gender
                }

                //Active
                var is_active = 0
                if (data.data.is_active != null) {
                    is_active = data.data.is_active
                }

                var valueStaff = { title: '' }
                for (let i = 0; i < this.state.dataCountStaff.length; i++) {
                    if (this.state.dataCountStaff[i]._id === data.data.object_id) {
                        valueStaff = { 'value': data.data.id, 'title': data.data.name + ' - ' + data.data.id_staff + ' ( ' + this.state.dataCountStaff[i].count_image + ' ) ', 'countImg': this.state.dataCountStaff[i].count_image, 'is_active': data.data.is_active, 'info_Person': data.data }
                    }
                }

                if (valueStaff.title === "") {
                    valueStaff = { 'value': data.data.id, 'title': data.data.name + ' - ' + data.data.id_staff + ' ( 0 ) ', 'countImg': 0, 'is_active': data.data.is_active, 'info_Person': data.data }
                }

                this.setState({
                    Staff: {
                        "id": data.data.id,
                        "id_staff": data.data.id_staff,
                        "department": data.data.department,
                        "name": data.data.name,
                        "abbreviated_name": data.data.abbreviated_name,
                        "birthday": birthday,
                        "gender": gender,
                        "is_active": is_active,
                        "is_delete": data.data.is_delete,
                        "id_department": id_Department,
                        "id_level": id_level,
                        "ids_shift": id_Shift,
                        "object_id": data.data.object_id
                    },
                    valueStaff: resetStaff ? { title: '' } : valueStaff,
                    readOnly: true,
                }, () => {
                    this.getInfoPerson(valueStaff.info_Person)
                })
            }
        })
    }

    getInfoPerson = (name, stopCapture) => {
        this.name = name['id_staff'];
        fetch(`${this.state.crrApi}/api/staff/getAllFace/${name['object_id']}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                this.Image = data.img[0];
                if (stopCapture) {
                    const crrStaff = this.state.valueStaff;
                    const indexStaff = this.state.listStaff.findIndex(e => e.value === crrStaff.value);

                    let staff = this.state.listStaff.find(e => e.value === crrStaff.value);
                    staff.countImg = data.img.length;
                    staff.title = `${crrStaff.info_Person.name} - ${crrStaff.info_Person.id_staff} (${data.img.length})`;

                    let listStaff = this.state.listStaff;
                    listStaff.splice(indexStaff, 1, staff)

                    this.setState({
                        valueStaff: {
                            ...this.state.valueStaff,
                            countImg: data.img.length,
                            title: `${crrStaff.info_Person.name} - ${crrStaff.info_Person.id_staff} (${data.img.length})`
                        },
                        listStaff: listStaff,
                    });

                }
                this.setState({
                    ...this.state,
                    listImage: [...data.img],
                    crrImages: [],
                    crrIdx: 0,
                    Staff_img: name,
                    loading_img_trainning: false
                }, () => {
                    this.FilterImg(1)
                })
            } else {
                this.setState({
                    ...this.state,
                    listImage: [],
                    crrImages: [],
                    loading_img_trainning: false
                })
            }
        })
    }

    FilterImg(activePage) {

        const { listImage } = this.state;

        const offset = (activePage - 1) * this.itemsPerPage;
        const crrImages = listImage.slice(offset, offset + this.itemsPerPage);

        this.setState({
            crrImages,
            showFirst: offset + 1,
            showLast: crrImages.length + offset,
            totalLength: this.state.listImage.length
        })
    }

    onFilesError = (error, file) => {
        swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].Text_Notifi.error_code + error.code + ': ' + error.message, "error");
    }

    uploadFace = async (files_base64, files_fileList) => {
        if (this.state.crrApi == undefined) return;
        this.setState({
            activeCapture: true,
            UploadFaces: true
        })
        let error = false;

        await fetch(`${this.state.crrApi}/api/upload_face_import`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            body: JSON.stringify({
                idCamera: this.state.crrCamera.value + '',
                "id": this.state.Staff.id,
                "id_staff": this.state.Staff.id_staff,
                "name": this.state.Staff.name,
                "abbreviated_name": this.state.Staff.abbreviated_name,
                "birthday": this.state.Staff.birthday,
                "gender": this.state.Staff.gender,
                "is_active": this.state.Staff.is_active,
                "is_delete": this.state.Staff.is_delete,
                "id_department": this.state.Staff.id_department,
                "id_level": this.state.Staff.id_level,
                "image": files_base64.split(',').slice(1).toString()
            })
        }).then((responseData) => {
            return responseData.json()
        }).then((data) => {
            if (data.status == 10000) {
                if (data.face_images.length > 0) {
                    var images = this.state.images;
                    for (let index = 0; index < data.face_images.length; index++) {
                        images.unshift(data.face_images[index]);
                        var count_img = $('#count_img').html();
                        $('#count_img').html(parseInt(count_img) + 1)
                    }
                    this.setState({
                        images: images,
                        // number_of_for: this.state.number_of_for + 1
                    }, () => {
                        // if (this.state.number_of_for == this.state.files_length) {
                        //     this.getCountStaff();
                        //     this.getStaffByID(this.state.Staff.id)
                        //     let totalUpload = this.state.files_length - this.state.imgError.length;
                        //     if (this.state.imgError.length > 0) {
                        //         if (totalUpload == 0) {
                        //             swals({
                        //                 title: language[this.props.indexLanguage].Text_Notifi.upload_error,
                        //                 content: <div>
                        //                     <div>
                        //                         {language[this.props.indexLanguage].Text_Notifi.error_notifi}
                        //                     </div>
                        //                     <div className="scrollDashBoard listCheckin" data-scrollable="true" data-height="400" style={{ 'height': '400px', 'overflow': 'hidden' }}>
                        //                         {
                        //                             this.state.imgError.map((value) => {
                        //                                 return (
                        //                                     <div>
                        //                                         {value.name}
                        //                                     </div>
                        //                                 )
                        //                             })
                        //                         }
                        //                     </div>
                        //                 </div>,
                        //                 icon: "error",
                        //                 button: "Oke!",
                        //             });
                        //         } else {
                        //             swals({
                        //                 title: language[this.props.indexLanguage].Text_Notifi.upload_success,
                        //                 text: totalUpload + '/' + this.state.files_length + '!',
                        //                 content: <div>
                        //                     <div>
                        //                         {language[this.props.indexLanguage].Text_Notifi.error_notifi}
                        //                     </div>
                        //                     <div className="scrollDashBoard listCheckin" data-scrollable="true" data-height="400" style={{ 'height': '400px', 'overflow': 'hidden' }}>
                        //                         {
                        //                             this.state.imgError.map((value) => {
                        //                                 return (
                        //                                     <div>
                        //                                         {value.name}
                        //                                     </div>
                        //                                 )
                        //                             })
                        //                         }
                        //                     </div>
                        //                 </div>,
                        //                 icon: "success",
                        //                 button: "Oke!",
                        //             });
                        //         }
                        //     } else {
                        //         swal({
                        //             title: language[this.props.indexLanguage].Text_Notifi.upload_success,
                        //             text: this.state.files_length + '/' + this.state.files_length + '!',
                        //             icon: "success",
                        //             button: "Oke!",
                        //         });
                        //     }
                        //     $('#count_img').html(0)
                        //     this.setState({
                        //         images: [],
                        //         activeCapture: false,
                        //         activePage: 1,
                        //     })
                        // }
                    })
                } else {
                    error = true;
                    // var imgError = this.state.imgError;
                    // imgError.push(files_fileList)
                    // this.setState({
                    //     number_of_for: this.state.number_of_for + 1,
                    //     imgError: imgError
                    // }, () => {
                    //     if (this.state.number_of_for == this.state.files_length) {
                    //         this.getCountStaff();
                    //         this.getStaffByID(this.state.Staff.id)
                    //         let totalUpload = this.state.files_length - this.state.imgError.length;
                    //         if (this.state.imgError.length > 0) {
                    //             if (totalUpload == 0) {
                    //                 swals({
                    //                     title: language[this.props.indexLanguage].Text_Notifi.upload_error,
                    //                     content: <div>
                    //                         <div>
                    //                             {language[this.props.indexLanguage].Text_Notifi.error_notifi}
                    //                         </div>
                    //                         <div className="scrollDashBoard listCheckin" data-scrollable="true" data-height="400" style={{ 'height': '400px', 'overflow': 'hidden' }}>
                    //                             {
                    //                                 this.state.imgError.map((value) => {
                    //                                     return (
                    //                                         <div>
                    //                                             {value.name}
                    //                                         </div>
                    //                                     )
                    //                                 })
                    //                             }
                    //                         </div>
                    //                     </div>,
                    //                     icon: "error",
                    //                     button: "Oke!",
                    //                 });
                    //             } else {
                    //                 swals({
                    //                     title: language[this.props.indexLanguage].Text_Notifi.upload_success,
                    //                     text: totalUpload + '/' + this.state.files_length + '!',
                    //                     content: <div>
                    //                         <div>
                    //                             {language[this.props.indexLanguage].Text_Notifi.error_notifi}
                    //                         </div>
                    //                         <div className="scrollDashBoard listCheckin" data-scrollable="true" data-height="400" style={{ 'height': '400px', 'overflow': 'hidden' }}>
                    //                             {
                    //                                 this.state.imgError.map((value) => {
                    //                                     return (
                    //                                         <div>
                    //                                             {value.name}
                    //                                         </div>
                    //                                     )
                    //                                 })
                    //                             }
                    //                         </div>
                    //                     </div>,
                    //                     icon: "success",
                    //                     button: "Oke!",
                    //                 });
                    //             }
                    //         } else {
                    //             swal({
                    //                 title: language[this.props.indexLanguage].Text_Notifi.upload_success,
                    //                 text: this.state.files_length + '/' + this.state.files_length + '!',
                    //                 icon: "success",
                    //                 button: "Oke!",
                    //             });
                    //         }
                    //         $('#count_img').html(0)
                    //         this.setState({
                    //             images: [],
                    //             activeCapture: false,
                    //             activePage: 1,
                    //         })
                    //     }
                    // })
                }
            } else {
                error = true;
                // var imgError = this.state.imgError;
                // imgError.push(files_fileList);
                // console.log(this.state.imgError)
                // this.setState({
                //     number_of_for: this.state.number_of_for + 1,
                //     imgError: imgError
                // }, () => {
                //     if (this.state.number_of_for == this.state.files_length) {
                //         this.getCountStaff();
                //         this.getStaffByID(this.state.Staff.id)
                //         let totalUpload = this.state.files_length - this.state.imgError.length;
                //         if (this.state.imgError.length > 0) {
                //             if (totalUpload == 0) {
                //                 swals({
                //                     title: language[this.props.indexLanguage].Text_Notifi.upload_error,
                //                     content: <div>
                //                         <div>
                //                             {language[this.props.indexLanguage].Text_Notifi.error_notifi}
                //                         </div>
                //                         <div className="scrollDashBoard listCheckin" data-scrollable="true" data-height="400" style={{ 'height': '400px', 'overflow': 'hidden' }}>
                //                             {
                //                                 this.state.imgError.map((value) => {
                //                                     return (
                //                                         <div>
                //                                             {value.name}
                //                                         </div>
                //                                     )
                //                                 })
                //                             }
                //                         </div>
                //                     </div>,
                //                     icon: "error",
                //                     button: "Oke!",
                //                 });
                //             } else {
                //                 swals({
                //                     title: language[this.props.indexLanguage].Text_Notifi.upload_success,
                //                     text: totalUpload + '/' + this.state.files_length + '!',
                //                     content: <div>
                //                         <div>
                //                             {language[this.props.indexLanguage].Text_Notifi.error_notifi}
                //                         </div>
                //                         <div className="scrollDashBoard listCheckin" data-scrollable="true" data-height="400" style={{ 'height': '400px', 'overflow': 'hidden' }}>
                //                             {
                //                                 this.state.imgError.map((value) => {
                //                                     return (
                //                                         <div>
                //                                             {value.name}
                //                                         </div>
                //                                     )
                //                                 })
                //                             }
                //                         </div>
                //                     </div>,
                //                     icon: "success",
                //                     button: "Oke!",
                //                 });
                //             }
                //         } else {
                //             swal({
                //                 title: language[this.props.indexLanguage].Text_Notifi.upload_success,
                //                 text: this.state.files_length + '/' + this.state.files_length + '!',
                //                 icon: "success",
                //                 button: "Oke!",
                //             });
                //         }
                //         $('#count_img').html(0)
                //         this.setState({
                //             images: [],
                //             activeCapture: false,
                //             activePage: 1,
                //         })
                //     }
                // })
            }
        }).catch((err) => {
            error = true;
            // if (error) {
            //     var imgError = this.state.imgError;
            //     imgError.push(files_fileList)
            //     this.setState({
            //         number_of_for: this.state.number_of_for + 1,
            //         imgError: imgError
            //     }, () => {
            //         if (this.state.number_of_for == this.state.files_length) {
            //             this.getCountStaff();
            //             this.getStaffByID(this.state.Staff.id)
            //             let totalUpload = this.state.files_length - this.state.imgError.length;
            //             if (this.state.imgError.length > 0) {
            //                 if (totalUpload == 0) {
            //                     swals({
            //                         title: language[this.props.indexLanguage].Text_Notifi.upload_error,
            //                         content: <div>
            //                             <div>
            //                                 {language[this.props.indexLanguage].Text_Notifi.error_notifi}
            //                             </div>
            //                             <div className="scrollDashBoard listCheckin" data-scrollable="true" data-height="400" style={{ 'height': '400px', 'overflow': 'hidden' }}>
            //                                 {
            //                                     this.state.imgError.map((value) => {
            //                                         return (
            //                                             <div>
            //                                                 {value.name}
            //                                             </div>
            //                                         )
            //                                     })
            //                                 }
            //                             </div>
            //                         </div>,
            //                         icon: "error",
            //                         button: "Oke!",
            //                     });
            //                 } else {
            //                     swals({
            //                         title: language[this.props.indexLanguage].Text_Notifi.upload_success,
            //                         text: totalUpload + '/' + this.state.files_length + '!',
            //                         content: <div>
            //                             <div>
            //                                 {language[this.props.indexLanguage].Text_Notifi.error_notifi}
            //                             </div>
            //                             <div className="scrollDashBoard listCheckin" data-scrollable="true" data-height="400" style={{ 'height': '400px', 'overflow': 'hidden' }}>
            //                                 {
            //                                     this.state.imgError.map((value) => {
            //                                         return (
            //                                             <div>
            //                                                 {value.name}
            //                                             </div>
            //                                         )
            //                                     })
            //                                 }
            //                             </div>
            //                         </div>,
            //                         icon: "success",
            //                         button: "Oke!",
            //                     });
            //                 }
            //             } else {
            //                 swal({
            //                     title: language[this.props.indexLanguage].Text_Notifi.upload_success,
            //                     text: this.state.files_length + '/' + this.state.files_length + '!',
            //                     icon: "success",
            //                     button: "Oke!",
            //                 });
            //             }
            //             $('#count_img').html(0)
            //             this.setState({
            //                 images: [],
            //                 activeCapture: false,
            //                 activePage: 1,
            //             })
            //         }
            //     })
            // }
        })
        this.setState({
            activeCapture: true,
            UploadFaces: true
        })
        return error;
    }

    actionUpload = async (files, files_base64, files_fileList) => {
        let error = false;
        if (files.base64.length > 0) {
            if (this.state.Staff.id_staff == "") {
                swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].Text_Notifi.text_notifi_select_staff, "warning");
            } else {
                error = await this.uploadFace(files_base64, files_fileList)
            }

            this.setState({
                max_upload_images: files.length + this.state.max_upload_images,
                files_length: files.base64.length,
                number_of_for: 0,
                imgError: [],
            })
        }
        return error;
    }

    onFilesChange = async (files) => {
        var readerxx = new FileReader();
        readerxx.onload = function (e) {
            document.getElementById('preview').src = e.target.result;
        };
        readerxx.readAsDataURL(files.fileList[0]);
        var _URL = window.URL;
        var fileSet, imgSet;
        var size = [];
        var arrError = [];
        var that = this;
        for (let index = 0; index < files.fileList.length; index++) {
            if ((fileSet = files.fileList[index])) {
                var dataurl = files.base64[index];
                imgSet = new Image();
                imgSet.onload = async function () {
                    var heightSet = this.height;
                    var widthSet = this.width;

                    if (widthSet >= 1281 || heightSet >= 1281) {
                        if (window.File && window.FileReader && window.FileList && window.Blob) {
                            var file = files.fileList[index];
                            if (file) {
                                var reader = new FileReader();
                                // Set the image once loaded into file reader
                                reader.onload = async function (e) {
                                    var img = document.createElement("img");
                                    img.src = e.target.result;
                                    var canvas = document.createElement("canvas");
                                    var ctx = canvas.getContext("2d");
                                    ctx.drawImage(img, 0, 0);

                                    var MAX_WIDTH = 1280;
                                    var MAX_HEIGHT = 1280;
                                    var width = widthSet;
                                    var height = heightSet;
                                    if (width > height) {
                                        if (width > MAX_WIDTH) {
                                            height *= MAX_WIDTH / width;
                                            width = MAX_WIDTH;
                                        }
                                    } else {
                                        if (height > MAX_HEIGHT) {
                                            width *= MAX_HEIGHT / height;
                                            height = MAX_HEIGHT;
                                        }
                                    }
                                    canvas.width = width;
                                    canvas.height = height;
                                    var ctx = canvas.getContext("2d");
                                    ctx.drawImage(img, 0, 0, width, height);
                                    dataurl = canvas.toDataURL(file.type);
                                }
                                await reader.readAsDataURL(file);
                            }
                        }
                    }
                };
                const dataResult = await that.actionUpload(files, dataurl, files.fileList[index]);
                size.push(dataResult);
                if (dataResult) {
                    arrError.push(files.fileList[index].name);
                }
                imgSet.src = await _URL.createObjectURL(fileSet);
            } else {
                const dataResult = await that.actionUpload(files, files.base64[index], files.fileList[index]);
                size.push(dataResult);
                if (dataResult) {
                    arrError.push(files.fileList[index].name);
                }
            }
        }
        if (size.length === files.fileList.length) {
            this.getCountStaff();
            this.getStaffByID(this.state.Staff.id)
            this.setState({
                activeCapture: false,
                UploadFaces: false,
                images: []
            })
            if (size.findIndex(e => e === true) === -1) {
                swal({
                    title: language[this.props.indexLanguage].Text_Notifi.upload_success,
                    text: this.state.files_length + '/' + this.state.files_length + '!',
                    icon: "success",
                    button: "Oke!",
                });
            } else {
                if (arrError.length === files.fileList.length) {
                    swals({
                        title: language[this.props.indexLanguage].Text_Notifi.upload_error,
                        content: <div>
                            <div>
                                {language[this.props.indexLanguage].Text_Notifi.error_notifi}
                            </div>
                            <div className="scrollDashBoard listCheckin" data-scrollable="true" data-height="400" style={{ 'height': '400px', 'overflow': 'hidden' }}>
                                {
                                    arrError.map((value) => {
                                        return (
                                            <div>
                                                {value}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>,
                        icon: "error",
                        button: "Oke!",
                    });
                } else {
                    swals({
                        title: language[this.props.indexLanguage].Text_Notifi.upload_success,
                        text: `${files.fileList.length - arrError.length}` + '/' + this.state.files_length + '!',
                        content: <div>
                            <div>
                                {language[this.props.indexLanguage].Text_Notifi.error_notifi}
                            </div>
                            <div className="scrollDashBoard listCheckin" data-scrollable="true" data-height="400" style={{ 'height': '400px', 'overflow': 'hidden' }}>
                                {
                                    arrError.map((value) => {
                                        return (
                                            <div>
                                                {value}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>,
                        icon: "success",
                        button: "Oke!",
                    });
                }
            }

        }
    }

    takeCapture = (event) => {
        ReactGA.event({
            category: 'Lay Anh',
            action: 'Lay anh',
        });
        if (this.state.crrApi === '') return;
        if (this.state.Staff.id_staff == "") {
            swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].Text_Notifi.text_notifi_select_staff, "warning");
        } else {
            this.getHistory()
            // this.openSocketCapture()
            if (this.socket != null) {
                this.socket.disconnect();
            }
            this.setState({
                // openTakeCapture: true,
                activeCapture: true,
                disabledBtnHistory: true,
                listChecked: {
                    valueImg: [],
                    _id: [],
                },
            })


        }
    }

    stopCaptureSocket = (event) => {
        this.socket.disconnect();
        this.socket = null;
        $('#btn_addImg').removeClass('m-loader');
        this.setState({
            openTakeCapture: false,
        })
    }


    openSocketCapture = () => {
        $('#btn_addImg').addClass('m-loader');
        this.setState({
            openTakeCapture: true,
        })
        if (this.socket != null) {
            this.socket.disconnect();
        }
        this.socket = openSocket(`${this.state.crrApiSocket2}`);
        this.socket.on("connect", async () => {
            console.log("Socket Successfully Connected:", Moment(new Date()).format("HH:mm:ss"));
            this.socket.emit('joined', this.state.crrCamera.value + '');
            this.setState({
                activeCapture: true,
            })
            $('#button_capture').removeClass('m-loader');
            $('#button_capture').removeAttr('disabled', false);
        });
        var count = 0
        this.socket.on('my response', async (msg) => {
            console.log("msg: ", msg)
            let socket_count = count++
            console.log("Socket return response:", Moment(new Date()).format("HH:mm:ss"));
            if (msg.operationType == 'insert') {
                var count_img = $('#count_img').html();
                if (parseInt(count_img) < this.state.max_training_images) {
                    console.log("Insert image:", Moment(new Date()).format("HH:mm:ss"));
                    $('#count_img').html(parseInt(count_img) + 1)
                }

                var images = this.state.images;
                if (this.state.activeCapture) {
                    images.unshift(msg);
                    this.setState({
                        images: images,
                    })
                }

                if (socket_count === 3) {
                    this.socket.disconnect();
                    console.log("socket disconected")
                    this.socket = null;
                    $('#btn_addImg').removeClass('m-loader');
                    this.setState({
                        openTakeCapture: false,
                    })
                }
            }
        })
    }

    cancelCapture = () => {
        this.setState({
            activeCapture: false,
            openTakeCapture: false,
            disabledBtnCapture: false,
            images: [],
            listChecked: {
                valueImg: [],
                _id: [],
            },
        })
        if (this.socket != null) {
            this.socket.disconnect();
            console.log("socket disconnect")
        }
    }



    getHistory = () => {
        fetch(`${this.state.box_engine_cf.crrApi}/api/staff/search_history`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            body: JSON.stringify({
                "id_cameras": [this.state.crrCamera.value + ''],
                "type_face": 2,
                "search": "",
                "img": "",
                "department": '',
                "from_date": Moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
                "to_date": Moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
                "index": 1,
                "item_per_page": 3,
            })
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status == 10000) {
                if (data.data.length > 0) {
                    this.setState({
                        images: [...data.data],
                        imgHistory: data.data
                    })
                }

            } else {
                this.openSocketCapture()
            }
        }).catch(() => {
            this.openSocketCapture()
        });
    }

    saveCapture = () => {
        if (this.state.listChecked._id.length === 0) {
            swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].Capture.please_select_1_image, "warning");
            return
        }
        fetch(`${this.state.crrApi}/api/staff/acceptManyFace`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.access_token
            },
            body: JSON.stringify({
                'image_ids': this.state.listChecked._id.length > 0 ? this.state.listChecked._id : [],
                'staff_object_id': this.state.Staff.object_id,
            })
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status == 10000) {
                ReactGA.event({
                    category: 'Lay Anh',
                    action: 'Dung lay anh',
                });
                $('.stop-capture').removeClass('m-loader');
                $('.stop-capture').removeAttr('disabled', false);
                $('#count_img').html(0)
                this.setState({
                    images: [],
                    max_training_images: 0,
                    activeCapture: false,
                    disabledBtnCapture: false,
                    listChecked: {
                        valueImg: [],
                        _id: [],
                    },
                })
                this.getInfoPerson(this.state.Staff, 'stopCapture');
            } else {
                $('.stop-capture').removeClass('m-loader');
                $('.stop-capture').removeAttr('disabled', false);
                swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].Text_Notifi.stop_false, "error");
            }
        }).catch((error) => {
            if (error) {
                $('.stop-capture').removeClass('m-loader');
                $('.stop-capture').removeAttr('disabled', false);
                swal(language[this.props.indexLanguage].Text_Notifi.warning, error, "warning");
            }
        })
    }

    deleteImg = () => {
        let arrDelete = this.state.images.filter(item => !this.state.listChecked._id.includes(item._id))
        this.setState({
            images: arrDelete,
            listChecked: {
                valueImg: [],
                _id: [],
            }
        });
    }




    stopCapture = () => {
        if (this.state.UploadFaces == false) {
            if (this.state.crrApi == undefined) return;
            $('.stop-capture').addClass('m-loader');
            $('.stop-capture').attr('disabled', true);

            this.socket.disconnect();
            console.log('Socket disconnect')

            fetch(`${this.state.crrApi}/api/stopCapture/${this.state.crrCamera.value}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': Store.getState().isLogin.access_token
                },
            }).then((response) => {
                return response.json()
            }).then((data) => {
                if (data.status == 10000) {
                    ReactGA.event({
                        category: 'Lay Anh',
                        action: 'Dung lay anh',
                    });
                    if (data.correct == 0) {
                        if (this.state.crrCamera.sub_engine !== 2) {
                            swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].Capture.notifi_stop_capture, "warning");
                        } else {
                            swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].Capture.notifi_stop_capture, "warning");
                        }
                    }

                    $('.stop-capture').removeClass('m-loader');
                    $('.stop-capture').removeAttr('disabled', false);

                    $('#count_img').html(0)
                    this.setState({
                        images: [],
                        max_training_images: 0,
                        activeCapture: false,
                    })
                    this.getInfoPerson(this.state.Staff, 'stopCapture');
                } else {
                    $('.stop-capture').removeClass('m-loader');
                    $('.stop-capture').removeAttr('disabled', false);
                    swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].Text_Notifi.stop_false, "error");
                }
            }).catch((error) => {
                if (error) {
                    $('.stop-capture').removeClass('m-loader');
                    $('.stop-capture').removeAttr('disabled', false);
                    swal(language[this.props.indexLanguage].Text_Notifi.warning, error, "warning");
                }
            })
        } else {
            this.setState({
                activeCapture: false,
            })
        }
    }

    getStaffByIDTostaff = (idStaff) => {
        if (this.state.crrApiSocket == undefined) return;
        fetch(`${this.state.crrApiSocket}/api/staff/get_by_id/${idStaff}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {

                var valueStaff = { title: "" }
                for (let i = 0; i < this.state.dataCountStaff.length; i++) {
                    if (this.state.dataCountStaff[i]._id === data.data.object_id) {
                        valueStaff = { 'value': data.data.id, 'title': data.data.name + ' - ' + data.data.id_staff + ' ( ' + this.state.dataCountStaff[i].count_image + ' ) ', 'countImg': this.state.dataCountStaff[i].count_image, 'is_active': data.data.is_active, 'info_Person': data.data }
                    }
                }

                if (valueStaff.title == "") {
                    valueStaff = { 'value': data.data.id, 'title': data.data.name + ' - ' + data.data.id_staff + ' ( 0 ) ', 'countImg': 0, 'is_active': data.data.is_active, 'info_Person': data.data }
                }

                this.setState({
                    toStaff: valueStaff,
                })
            }
        })
    }

    handlePageChange = (pageNumber) => {
        this.setState({
            ...this.state,
            activePage: pageNumber,
            crrIdx: 0
        })
        this.FilterImg(pageNumber);

    }

    handleCheckedImg = (event, value) => {
        let listChecked = this.state.listChecked;
        let isChecked = event.target.checked;
        if (listChecked.valueImg.indexOf(event.target.value) === -1) {
            listChecked.valueImg.push(event.target.value)
            listChecked._id.push(value._id)
        } else {
            var i = listChecked.valueImg.indexOf(event.target.value);
            if (i != -1) {
                listChecked.valueImg.splice(i, 1);
                listChecked._id.splice(i, 1);
            }
        }
        this.setState({
            listChecked: listChecked
        })
    }

    deleteFaceMulti = async (_idStaff, dataImg) => {
        if (this.state.crrApi == undefined) return;
        $('#btn_deleteMulti').addClass('m-loader');
        $('#btn_deleteMulti').attr('disabled', true);
        let data = await fetch(`${this.state.crrApi}/api/staff/deleteManyFace`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            body: JSON.stringify({
                idStaff: _idStaff,
                imgFaces: dataImg._id,
                files: dataImg.valueImg
            })
        }).then((response) => {
            return response.json()
        });
        if (data.status === 10000) {
            let listImage = this.state.listImage;
            for (let index = 0; index < dataImg.valueImg.length; index++) {
                for (let i = 0; i < listImage.length; i++) {
                    if (dataImg.valueImg[index] == listImage[i].imgFace) {
                        listImage.splice(i, 1)
                    }
                }
            }

            let listStaff = this.state.listStaff;
            let valueStaff = this.state.valueStaff;
            listStaff.forEach((value, index) => {
                //FromStaff
                if (value.info_Person.object_id == _idStaff) {
                    listStaff[index] = {
                        countImg: value.countImg - 1,
                        info_Person: value.info_Person,
                        is_active: value.is_active,
                        title: value.info_Person.name + ' - ' + value.info_Person.id_staff + ' ( ' + (listImage.length) + ' ) ',
                        value: value.value
                    }
                    valueStaff = {
                        countImg: value.countImg - 1,
                        info_Person: value.info_Person,
                        is_active: value.is_active,
                        title: value.info_Person.name + ' - ' + value.info_Person.id_staff + ' ( ' + (listImage.length) + ' ) ',
                        value: value.value
                    }

                }
            });

            swal(language[this.props.indexLanguage].Text_Notifi.success, language[this.props.indexLanguage].Text_Notifi.delete_image_success, "success", {
                buttons: false,
                timer: 1500,
            });
            $('#btn_deleteMulti').removeClass('m-loader');
            $('#btn_deleteMulti').removeAttr('disabled', true);
            var activePage = this.state.activePage;
            var totalCrrItem = this.itemsPerPage * this.state.activePage;
            if ((totalCrrItem - listImage.length) == this.itemsPerPage) {
                activePage = activePage - 1
            }

            this.setState({
                ...this.state,
                listChecked: {
                    valueImg: [],
                    _id: [],
                },
                listImage: listImage,
                listStaff: listStaff,
                valueStaff: valueStaff,
                activePage: activePage,
                crrIdx: 0,
                // coutStaffImg: coutStaffImg,
            }, () => {
                this.FilterImg(this.state.activePage);
                this.getCountStaff()
            });


        } else {
            swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].Text_Notifi.delete_image_error, "error", {
                buttons: false,
                timer: 1500,
            });
            $('#btn_deleteMulti').removeClass('m-loader');
            $('#btn_deleteMulti').removeAttr('disabled', true);
        }

    }

    MoveImage = () => {
        if (this.state.listChecked.valueImg.length > 0) {
            this.setState({
                modalMoveImage: true,
            });
        } else {
            swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].Capture.check_move_img, "warning");
        }
    }

    modalClose = (valueStaff, listImage, checkSuccess) => {
        if (checkSuccess === true) {
            this.setState({
                modalMoveImage: false,
                listImage: listImage,
                valueStaff: valueStaff,
                listChecked: {
                    valueImg: [],
                    _id: [],
                },
            });
            this.getCountStaff();
            this.FilterImg(this.state.activePage);
        } else {
            this.setState({
                modalMoveImage: false,
            });
        }
    }

    tick() {
        this.setState(state => ({
            percentSuccess: state.percentSuccess + 1
        }));
    }

    componentWillUpdate(nextProps, nextState) {
        if (nextState.percentSuccess === 99) {
            clearInterval(this.interval)
        }
    }

    componentWillUnmount() {
        if (this.state.activeCapture == true) {
            this.stopCapture()
        } else {
            if (this.socket !== undefined) {
                if (this.socket.connected === true) {
                    this.socket.disconnect();
                    console.log('Socket Disconnect')
                }
            }
        }
    }

    resetUser = () => {
        this.interval = setInterval(() => this.tick(), 100);
        this.setState({
            activeCapture: true,
            UpdateDataToDevice: true,
        })
        if (this.state.crrApi === undefined) return;
        fetch(`${this.state.crrApi}/api/model/reset_log_model`,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'idCamera': this.state.crrCamera.value,
                    'is_oem': 1
                })
            }).then((response) => {
                return response.json()
            }).then((data) => {
                if (data.status === 10000) {
                    clearInterval(this.interval)
                    this.setState({
                        percentSuccess: 100,
                        activeCapture: false,
                        UpdateDataToDevice: false
                    }, () => {
                        swal({
                            title: language[this.props.indexLanguage].Capture.update_success,
                            text: language[this.props.indexLanguage].Capture.update_notifi_success,
                            icon: "success",
                            button: "Oke!",
                        });
                    })
                } else {
                    clearInterval(this.interval)
                    this.setState({
                        percentSuccess: 0,
                        activeCapture: false,
                        UpdateDataToDevice: false
                    }, () => {
                        swal({
                            title: language[this.props.indexLanguage].Capture.update_error,
                            text: data.message,
                            icon: "error",
                            button: "Oke!",
                        });
                    })
                }
            }).catch((error) => {
                // might be a timeout error
                if (error) {
                    clearInterval(this.interval)
                    this.setState({
                        percentSuccess: 0,
                        activeCapture: false,
                        UpdateDataToDevice: false
                    }, () => {
                        swal({
                            title: language[this.props.indexLanguage].Capture.update_error,
                            text: error,
                            icon: "error",
                            button: "Oke!",
                        });
                    })
                }
            })
    }

    _exporter_errorimage
    ExportListErrorImage = () => {
        if (this.state.crrApi === undefined) return;
        this.setState({ loadingExport: true });
        fetch(`${this.state.crrApi}/api/face/checkfeature/${this.state.box_engine_cf.idCompany}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                ReactGA.event({
                    category: 'Lay Anh',
                    action: 'Xuat bao cao loi anh',
                });
                this.setState({
                    data_json_export: data.data,
                    loadingExport: false
                }, () => {
                    this._exporter_errorimage.save()
                });
            } else {
                this.setState({
                    loadingExport: false
                });
            }
        }).catch((error) => {
            if (error) {
                this.setState({
                    loadingExport: false
                });
                console.log('error', error)
            }
        })
    }

    _exporter_notimage
    ExportListNotImage = () => {
        ReactGA.event({
            category: 'Lay Anh',
            action: 'Xuat bao cao chua du anh',
        });
        this._exporter_notimage.save()
    }

    render() {
        const { classes } = this.props;
        if (this.state.isLogin === false) {
            return (
                <Redirect to={'/login'} />
            )
        }

        if (this.state.Staff.is_active === 1) {
            this.state.isChecked = true
        } else {
            this.state.isChecked = false
        }

        if (this.state.Staff.gender == 1) {
            this.state.checkedMale = true
            this.state.checkedFemale = false
            this.state.checkedBD = false
        } else if (this.state.Staff.gender == 2) {
            this.state.checkedMale = false
            this.state.checkedFemale = true
            this.state.checkedBD = false
        } else if (this.state.Staff.gender == 3) {
            this.state.checkedMale = false
            this.state.checkedFemale = false
            this.state.checkedBD = true
        } else {
            this.state.checkedMale = false
            this.state.checkedFemale = false
            this.state.checkedBD = false
        }


        let bulletedImg = this.state.crrImages.map((value, index) => {
            return (
                <Col sm={2} md={2} xs={4} className="pl-0 mb-3" key={index + 1}>
                    <QueueAnim duration={1000} type="scaleX" delay={10 * index}>
                        <div key="1">
                            {
                                this.state.checkDeleteMulti == false ?
                                    <img src={value.url_img ? (value.url_img.includes("http") ? `${value.url_img}` : `${this.state.crrApiImg}${value.url_img}`) : `${this.state.crrApiImg}/view/image/0/${value.idCamera}/${value['imgFace']}`} onClick={() => {
                                        this.Image = value;
                                        this.setState({ crrIdx: index });
                                    }} className={"opacity_img_click img_check " + (this.state.crrIdx === index ? 'active' : '')} />
                                    :
                                    <label className="m-checkbox img-checkbox m-checkbox-day pl-0 mb-0" style={{ 'width': '100%' }}>
                                        <input type="checkbox" defaultValue={value.imgFace} name="img_checked" checked={this.state.listChecked.valueImg.indexOf(value.imgFace) == -1 ? false : true} onClick={e => this.handleCheckedImg(e, value)} readOnly />
                                        <img src={value.url_img ? (value.url_img.includes("http") ? `${value.url_img}` : `${this.state.crrApiImg}${value.url_img}`) : `${this.state.crrApiImg}/view/image/0/${value.idCamera}/${value.imgFace}`} className="opacity_img img_check " />
                                        <span />
                                    </label>
                            }
                        </div>
                    </QueueAnim>
                </Col>
            )
        })

        var pageRangeDisplayed = 1;
        if ($(window).width() < 768) {
            pageRangeDisplayed = 2
        } else {
            pageRangeDisplayed = 5
        }

        var display_load_countStaffImg = ""
        if (this.state.loading_countStaffImg == true) {
            display_load_countStaffImg = "d-none"
        } else {
            display_load_countStaffImg = ""
        }

        var Gender = "";
        if (this.state.valueStaff.value !== undefined) {
            if (this.state.Staff.gender === 1) {
                Gender = language[this.props.indexLanguage].Gender.Male
            } else if (this.state.Staff.gender === 2) {
                Gender = language[this.props.indexLanguage].Gender.Female
            } else if (this.state.Staff.gender === 3) {
                Gender = language[this.props.indexLanguage].Gender.OtherGender
            }
        }

        var optionsCamera = [];
        if (this.state.dataCamera !== undefined) {
            for (let index = 0; index < this.state.dataCamera.length; index++) {
                if (ARRAY_ENGINES.includes(this.state.dataCamera[index].sub_engine_id + '')) {
                    const title = this.state.dataCamera[index].alias_box_engine ?
                        `${this.state.dataCamera[index].alias_box_engine} ( ${this.state.dataCamera[index].id_box_engine} )` :
                        this.state.dataCamera[index].id_box + '_' + this.state.dataCamera[index].id_box_engine;
                    optionsCamera.push({
                        title: title,
                        value: this.state.dataCamera[index].id_box_engine,
                        idBox: this.state.dataCamera[index].id_box,
                        sub_engine: this.state.dataCamera[index].sub_engine_id,
                        dataCamera: this.state.dataCamera[index]
                    })
                }
            }
        }
        if (!this.state.listStaff) return <></>;
        return (
            <>
            <Header />
            <div className={"m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body "}>
            <LoadingOverlay
                active={this.state.activeCapture}
                fadeSpeed={200}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(0, 0, 0, 0.58)'
                    })
                }}
                text={
                    this.state.UpdateDataToDevice === false
                        ?
                        this.state.UploadFaces == true
                            ?
                            <div>
                                <div>{language[this.props.indexLanguage].Text_Notifi.text_notifi_loadding}</div>
                                <div className="mb-2 mt-2">
                                    <button className="btn btn-default m-loader--light m-loader--right stop-capture" onClick={() => this.stopCapture()}>
                                        {this.state.UploadFaces == true ? language[this.props.indexLanguage].Text_Notifi.stop_upload : language[this.props.indexLanguage].Text_Notifi.stop_capture}
                                    </button>
                                </div>
                                <div className={`row m-0 ${this.state.images.length > 0 && "listImageCapture"}`} >
                                    {
                                        this.state.images.map((image, index) => {
                                            return (
                                                <div className="col-4 col-md-2 col-xl-3 p-2" key={index + 1}>
                                                    <QueueAnim duration={1000} type="scale">
                                                        <img key="1" src={`${this.state.crrApiImg}/view/${image.idStaff}/${image.imgFace}`} alt={index} className="width-100" style={{ 'height': '175px' }} />
                                                    </QueueAnim>
                                                </div>
                                            )
                                        })
                                    }
                                </div >
                            </div>
                            :

                            <div className="m-portlet m-portlet_custom m-portlet--tabs">
                                <div className="m-portlet__head">
                                    <div className="m-portlet__head-tools">
                                        <ul className="nav nav-tabs m-tabs-line m-tabs-line--right" role="tablist">
                                            <li className="nav-item m-tabs__item">
                                                <a className="d-flex justify-content-center align-items-center" role="tab" aria-selected="true">
                                                    {language[this.props.indexLanguage].Capture.device}:&nbsp;<b>{this.state.crrCamera.title}</b>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="m-portlet__head-caption">
                                        <div className="m-portlet__head-title">
                                            <a onClick={() => this.cancelCapture()} className="m-portlet__head-text">
                                                <i style={{ fontSize: "20px", cursor: 'pointer' }} className="fa fa-times"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="m-portlet__body pt-1">
                                    <div className="tab-content">
                                        <div className="tab-pane active show" id="m_portlet_base_demo_1_tab_content" role="tabpanel">
                                            {!this.state.openTakeCapture ?
                                                <div className="text-left m-auto col-md-10 pt-3 pb-4" style={{ fontStyle: "italic" }}>
                                                    <div>
                                                        {language[this.props.indexLanguage].Capture.capture_guide}
                                                    </div>
                                                    <div >
                                                        {language[this.props.indexLanguage].Capture.capture_guide_base}
                                                    </div>
                                                    <div>
                                                        {language[this.props.indexLanguage].Capture.capture_guide_base_2}
                                                    </div>
                                                </div>
                                                :
                                                <div className="text-left m-auto col-md-11 pt-3 pb-3" style={{ fontStyle: "italic" }}>
                                                    <div>
                                                        {language[this.props.indexLanguage].Capture.capture_guide}
                                                    </div>
                                                    <div>
                                                        {language[this.props.indexLanguage].Capture.capture_guide_step_1}
                                                    </div>
                                                    <div>
                                                        {language[this.props.indexLanguage].Capture.capture_guide_base_3}
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className="col-md-12">
                                            <div className="mt-2 mb-2 pull-right d-flex">

                                                <div style={{ 'display': 'inline-flex' }} className=" mr-2">
                                                    <div id="btn_addImg" className="m-loader--success" style={{ width: "30px", display: "inline-block" }}></div>
                                                    <a className="m--font-info cursor-pointer p-2" style={{ cursor: 'pointer' }} onClick={() => {
                                                        if (!this.state.openTakeCapture) {
                                                            this.openSocketCapture()
                                                        } else {
                                                            this.stopCaptureSocket()
                                                        }
                                                    }}>{!this.state.openTakeCapture ? language[this.props.indexLanguage].Capture.add_image : language[this.props.indexLanguage].Capture.stop_image}</a>
                                                </div>
                                                <div style={{ 'display': 'inline-flex' }} className=" mb-2">
                                                    <a className=" p-2" style={{ cursor: 'pointer', color: this.state.listChecked._id.length > 0 ? "red" : "gray" }} onClick={() => this.deleteImg()}>{language[this.props.indexLanguage].tooltip.open_delete}</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ width: "100%", height: "100%" }} className={`row m-0 ${this.state.images.length > 0 && "listImageCapture"}`} >
                                            {
                                                this.state.images.map((image, index) => {
                                                    return (
                                                        <div className="col-4 col-md-4 col-xl-4 p-2" key={index + 1}>
                                                            <QueueAnim duration={1000} type="scale" style={{ position: "relative" }}>
                                                                <input className="checkbox-img" type="checkbox" defaultValue={image.imgFace} name="img_checked" checked={this.state.listChecked.valueImg.indexOf(image.imgFace) == -1 ? false : true} onClick={e => this.handleCheckedImg(e, image)} readOnly />
                                                                <label className="m-checkbox img-checkbox m-checkbox-day pl-0 mb-0" style={{ 'width': '100%' }}>
                                                                    <input type="checkbox" defaultValue={image.imgFace} name="img_checked" checked={this.state.listChecked.valueImg.indexOf(image.imgFace) == -1 ? false : true} onClick={e => this.handleCheckedImg(e, image)} readOnly />
                                                                    <img src={`${this.state.crrApi}${image.img_url}`} alt={index} key={index} className="width-100" style={{ 'height': '170px' }} />
                                                                </label>
                                                            </QueueAnim>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div >
                                    </div>
                                </div>
                                <div className="m-portlet__footer pt-1">
                                    <button type="button" className="btn btn-success  m-loader--light m-loader--right stop-capture" onClick={() => this.saveCapture()}>{language[this.props.indexLanguage].Capture.save_modal}
                                    </button>
                                </div>

                            </div>

                        :
                        <div>
                            <div>
                                {language[this.props.indexLanguage].Text_Notifi.update_data_system}
                            </div>
                            <div className="progress mt-4 m-progress--lg m-auto" style={{ width: '300px', fontSize: '13px' }}>
                                <div className="progress-bar progress-bar-striped progress-bar-animated  bg-success" role="progressbar" aria-valuenow={this.state.percentSuccess} aria-valuemin="0" aria-valuemax="100" style={{ width: `${this.state.percentSuccess}%` }}>{this.state.percentSuccess}%</div>
                            </div>
                        </div>
                }
                className={
                    this.state.UpdateDataToDevice === false
                        ?
                        "col-xl-12 p-0 loading_capture"
                        :
                        "col-xl-12 p-0"
                }
            >
                <div className="m-grid__item m-grid__item--fluid m-wrapper p-2" id="capture_form">
                    <div className="m-content">
                        <div className="m-portlet">
                            <div className="m-portlet__head capture_form_select">
                                <div className="row m-0 p-2 col-md-12">
                                    <div className="select_option col-md-2 pl-0">
                                        <Autocomplete
                                            options={optionsCamera}
                                            getOptionLabel={option => option.title}
                                            value={this.state.crrCamera}
                                            onChange={this.changeHandleCamera}
                                            disableClearable
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    placeholder="Camera"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="select_option col-md-2 pl-0">
                                        {/* <Autocomplete
                                            options={this.state.optionDepartment}
                                            getOptionLabel={option => option.title}
                                            defaultValue={{ 'value': -1, 'title': language[this.props.indexLanguage].placeholderSelect.all_department }}
                                            value={this.state.valueDepartment}
                                            onChange={this.handleChangeDepartment}
                                            disableClearable
                                            renderOption={(props, option, state) => (
                                                <div {...props}>
                                                    {formatTitleOption(props)}
                                                </div>
                                            )}
                                            classes={{ paper: classes.paper }}
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    placeholder={language[this.props.indexLanguage].placeholderSelect.select_department}
                                                    fullWidth
                                                />
                                            )}
                                        /> */}
                                        <Button onClick={() => {
                                            this.setState({
                                                selectDepartment: !this.state.selectDepartment
                                            })
                                        }}
                                            className="btn btn-secondary btn-block m-btn m-btn--icon"
                                        >
                                            <span style={{ color: "#000000" }}>
                                                {this.state.valueDepartment.value == -1 ? "Tất cả phòng ban" : this.state.valueDepartment.title}
                                            </span>
                                        </Button>
                                    </div>
                                    <div className="select_option col-md-2 pl-0">
                                        <Autocomplete
                                            options={this.state.listStaff}
                                            getOptionLabel={option => option.title}
                                            value={this.state.valueStaff}
                                            onChange={this.handleChangeStaffs}
                                            disableClearable
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    placeholder={language[this.props.indexLanguage].Capture.text_placeholder_select_staff}
                                                    fullWidth
                                                />
                                            )}
                                            renderOption={(option, { inputValue }) => {
                                                return (
                                                    <div style={{
                                                        color: this.state.crrCamera.sub_engine !== 2 ? option.countImg < 1 ? '#f44236 ' : '' : option.countImg < 1 ? '#f44236 ' : ''
                                                    }}>
                                                        <span style={{ fontWeight: 400 }}>
                                                            {option.title}
                                                        </span>
                                                    </div>
                                                );
                                            }}
                                        />
                                    </div>
                                    <div className={`m-portlet__head-caption pl-0 col-md-3 `}>
                                        <div className="m-portlet__head-title d-flex flex-column align-items-start">
                                            <span className="m-portlet__head-icon m--hide">
                                                <i className="la la-gear" />
                                            </span>
                                            <h3 className="m-portlet__head-text" style={{ fontSize: '1.2rem' }}>
                                                {language[this.props.indexLanguage].Capture.title_count_number_person} <span className={"pl-2 " + display_load_countStaffImg} > <b><span>{this.state.coutStaffImg}</span>/<span>{this.state.totalStaff}</span></b></span>
                                                <PulseLoader
                                                    css={override}
                                                    sizeUnit={"px"}
                                                    size={12}
                                                    margin={'2px'}
                                                    color={'#36D7B7'}
                                                    loading={this.state.loading_countStaffImg}
                                                />
                                            </h3>
                                            <h3 className="m-portlet__head-text" style={{ fontSize: '1.2rem' }}>
                                                {language[this.props.indexLanguage].Capture.title_count_number_photo} <span className={"pl-2 " + display_load_countStaffImg} > <b><span>{this.state.sumCountImg}</span></b></span>
                                                <PulseLoader
                                                    css={override}
                                                    sizeUnit={"px"}
                                                    size={12}
                                                    margin={'2px'}
                                                    color={'#36D7B7'}
                                                    loading={this.state.loading_countStaffImg}
                                                />
                                            </h3>
                                        </div>
                                    </div>
                                    {
                                        <div className="col-md-3 select_option">
                                            <div className="dropdown pull-right">
                                                <button className={"btn btn-accent dropdown-toggle m-loader--light m-loader--left " + (this.state.loadingExport && 'm-loader')} disabled={this.state.loadingExport} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    {language[this.props.indexLanguage].textTable.action}
                                                </button>
                                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">

                                                    {this.state.crrCamera.sub_engine !== 2 ?
                                                        <>
                                                            <a className="dropdown-item" href="#" onClick={(e) => {
                                                                swal({
                                                                    text: language[this.props.indexLanguage].Capture.notification_reset_device_data,
                                                                    icon: "warning",
                                                                    buttons: {
                                                                        oke: language[this.props.indexLanguage].textButton.oke,
                                                                        cancel: language[this.props.indexLanguage].textButton.close
                                                                    },
                                                                    dangerMode: true,
                                                                }).then((reset) => {
                                                                    if (reset) {
                                                                        this.resetUser()
                                                                    }
                                                                })
                                                            }}>
                                                                <i className="la la-rotate-left" /> {language[this.props.indexLanguage].Capture.reset_data_device}</a>
                                                            <a className="dropdown-item" href="#" onClick={() => {
                                                                this.ExportListNotImage()
                                                            }}>
                                                                <i className="la la-download" /> {language[this.props.indexLanguage].Capture.not_enough_image_list}</a>
                                                        </>
                                                        : <>
                                                            <a className="dropdown-item" href="#" onClick={() => {
                                                                this.ExportListErrorImage()
                                                            }}>
                                                                <i className="la la-download" /> {language[this.props.indexLanguage].Capture.photo_error_list}</a>
                                                            <a className="dropdown-item" href="#" onClick={() => {
                                                                this.ExportListNotImage()
                                                            }}>
                                                                <i className="la la-download" /> {language[this.props.indexLanguage].Capture.not_enough_image_list}</a>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <ExcelExport
                                        data={this.state.data_json_export}
                                        fileName={`${language[this.props.indexLanguage].Capture.photo_error_list} ` + Moment(new Date()).format("YYYY-MM-DD") + ".xlsx"}
                                        ref={(exporter) => { this._exporter_errorimage = exporter; }}
                                    >
                                        <ExcelExportColumnGroup title={`${language[this.props.indexLanguage].Capture.photo_error_list}`} format="{0:c}" headerCellOptions={{ textAlign: 'center', verticalAlign: 'center', bold: true, fontSize: 15 }}>
                                            <ExcelExportColumn field="code" title={`${language[this.props.indexLanguage].textTable.id_staff}`} width={100} />
                                            <ExcelExportColumn field="name" title={`${language[this.props.indexLanguage].textTable.full_name}`} width={200} />
                                            <ExcelExportColumn field="abbreviated_name" title={`${language[this.props.indexLanguage].textTable.nick_name}`} width={200} />
                                            <ExcelExportColumn field="department" title={`${language[this.props.indexLanguage].textTable.department}`} width={120} />
                                        </ExcelExportColumnGroup>
                                    </ExcelExport>
                                    <ExcelExport
                                        data={this.state.data_json_exportNotImage}
                                        fileName={`${language[this.props.indexLanguage].Capture.not_enough_image_list} ` + Moment(new Date()).format("YYYY-MM-DD") + ".xlsx"}
                                        ref={(exporter) => { this._exporter_notimage = exporter; }}
                                    >
                                        <ExcelExportColumnGroup title={`${language[this.props.indexLanguage].Capture.not_enough_image_list}`} format="{0:c}" headerCellOptions={{ textAlign: 'center', verticalAlign: 'center', bold: true, fontSize: 15 }}>
                                            <ExcelExportColumn field="code" title={`${language[this.props.indexLanguage].textTable.id_staff}`} width={100} />
                                            <ExcelExportColumn field="name" title={`${language[this.props.indexLanguage].textTable.full_name}`} width={200} />
                                            <ExcelExportColumn field="abbreviated_name" title={`${language[this.props.indexLanguage].textTable.nick_name}`} width={200} />
                                            <ExcelExportColumn field="department" title={`${language[this.props.indexLanguage].textTable.department}`} width={120} />
                                        </ExcelExportColumnGroup>
                                    </ExcelExport>
                                </div>
                            </div>
                            <div className="m-portlet__body  m-portlet__body--no-padding">
                                <div className="row m-row--no-padding m-row--col-separator-xl">
                                    <div className="col-xl-3">
                                        <div className="m-portlet m-portlet--tab mb-0">
                                            {/*begin::Form*/}
                                            <div className="m-form m-form--fit m-form--label-align-right" id="formCapture">

                                                <div className="m-portlet__foot--fit">
                                                    <div className="m-portlet__body pl-3 pt-0-mobie pr-0 m-auto col-12 col-md-8 col-lg-6 col-xl-12">
                                                        <div className="form-group m-form__group m-0 row text-center">
                                                            <div className="col-12 col-md-12">
                                                                <h5 className="m-form__section">{language[this.props.indexLanguage].Capture.info}</h5>
                                                            </div>
                                                        </div>
                                                        <div className="form-group m-form__group m-0 pl-4 pb-0 pt-2 pr-3 row">
                                                            <label htmlFor="example-text-input" className="col-3 col-md-3 pl-0 col-form-label text-left">ID</label>
                                                            <div className="col-9 col-md-9">
                                                                <input className="form-control m-input" type="text" value={this.state.Staff.id_staff} readOnly />

                                                            </div>
                                                        </div>
                                                        <div className="form-group m-form__group m-0 pl-4 pb-0 pt-2 pr-3 row">
                                                            <label htmlFor="example-text-input" className="col-3 col-md-3 pl-0 col-form-label text-left">{language[this.props.indexLanguage].textTable.name}</label>
                                                            <div className="col-9 col-md-9">
                                                                <input className="form-control m-input" type="text" value={this.state.Staff.name} readOnly />

                                                            </div>
                                                        </div>
                                                        <div className="form-group m-form__group m-0 pl-4 pb-0 pt-2 pr-3 row">
                                                            <label htmlFor="example-text-input" className="col-3 col-md-3 pl-0 col-form-label text-left">{language[this.props.indexLanguage].textTable.nick_name}</label>
                                                            <div className="col-9 col-md-9">
                                                                <input className="form-control m-input" type="text" value={this.state.Staff.abbreviated_name} readOnly />

                                                            </div>
                                                        </div>
                                                        <div className="form-group m-form__group m-0 pl-4 pb-0 pt-2 pr-3 row">
                                                            <label htmlFor="example-text-input" className="col-3 col-md-3 pl-0 col-form-label text-left">{language[this.props.indexLanguage].textTable.gender}</label>
                                                            <div className="col-9 col-md-9">
                                                                <input className="form-control m-input" type="text" value={Gender} readOnly />

                                                            </div>
                                                        </div>
                                                        <div className="form-group m-form__group m-0 pl-4 pb-0 pt-2 pr-3 row">
                                                            <label htmlFor="example-text-input" className="col-3 col-md-3 pl-0 col-form-label text-left">{language[this.props.indexLanguage].textTable.birthday}</label>
                                                            <div className="col-9 col-md-9">
                                                                <input className="form-control m-input" type="text" value={this.state.Staff.birthday} readOnly />

                                                            </div>
                                                        </div>
                                                        <div className="form-group m-form__group m-0 pl-4 pb-0 pt-2 pr-3 row">
                                                            <label htmlFor="example-text-input" className="col-3 col-md-3 pl-0 col-form-label text-left">{language[this.props.indexLanguage].textTable.department}</label>
                                                            <div className="col-9 col-md-9">
                                                                <input className="form-control m-input" type="text" value={this.state.Staff.department !== null ? this.state.Staff.department.name : ""} readOnly />

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="m-form__actions pt-3 text-center">
                                                        {
                                                            this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':capture') !== -1
                                                                ?
                                                                <button type="button" id="button_capture" className="btn btn-accent m-loader--light m-loader--right" disabled={this.state.valueStaff.value === undefined ? true : false} onClick={(event) => this.takeCapture(event)}>{language[this.props.indexLanguage].textButton.capture}</button>
                                                                :
                                                                ""
                                                        }
                                                        {
                                                            this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':upload_image') !== -1
                                                                ?
                                                                <div style={{ 'display': 'inline-block' }} className="ml-2">
                                                                    <ReactFileReader maxFileSize={1000} minFileSize={0} fileTypes={['image/png', 'image/jpg', 'image/jpeg']} disabled={this.state.valueStaff.value === undefined ? true : false} base64={true} multipleFiles={true} handleFiles={this.onFilesChange}>
                                                                        <button className='btn m-btn--icon m-btn button_uploadfaces btn-default m-loader--success m-loader--right' disabled={this.state.valueStaff.value === undefined ? true : false}>
                                                                            <span>
                                                                                <i className="la la-cloud-upload"></i>
                                                                                <span>{language[this.props.indexLanguage].textButton.upload}</span>
                                                                            </span>
                                                                        </button>
                                                                    </ReactFileReader>
                                                                    <img src="" id="preview" style={{ 'display': 'none' }}></img>
                                                                </div>
                                                                :
                                                                ""
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            {/*end::Form*/}
                                        </div>
                                    </div>
                                    <div className="col-xl-9" id="iframe_hide_mobie">
                                        <div className="m-portlet m-portlet--tab mb-0" style={{ 'height': '100%' }}>
                                            <div className="m-form m-form--fit m-form--label-align-right">
                                                <div className="m-portlet__body pl-5 pr-5">
                                                    <div className="row m-0">
                                                        {
                                                            this.state.valueStaff.value !== undefined
                                                                ?
                                                                <div className="col-md-12 p-0 text-danger">
                                                                    {
                                                                        this.state.crrCamera.sub_engine !== 2
                                                                            ?
                                                                            this.state.valueStaff.countImg < 1
                                                                                ?
                                                                                language[this.props.indexLanguage].Capture.notifi_length_image + ' 1'
                                                                                :
                                                                                ''
                                                                            :
                                                                            this.state.valueStaff.countImg < 1
                                                                                ?
                                                                                language[this.props.indexLanguage].Capture.notifi_length_image + ' 1'
                                                                                :
                                                                                ''
                                                                    }
                                                                </div>
                                                                :
                                                                ''
                                                        }
                                                        {
                                                            this.state.selectDepartment === true && (
                                                                <div className='col-xl-6'>
                                                                    <div style={{ maxHeight: "650px", overflow: "auto", marginRight: "20px", marginTop: "10px", whiteSpace: "nowrap" }}>
                                                                        <TreeView item={formatTreeViewData(this.state.optionDepartment)} getList={(data) => {
                                                                            this.setState({
                                                                                selectDepartment: false
                                                                            })
                                                                            this.handleChangeDepartment(0, {
                                                                                value: data.value,
                                                                                title: data.title,
                                                                                code: data.code
                                                                            });
                                                                            console.log('data', data)
                                                                        }} />
                                                                    </div>
                                                                </div>)
                                                        }
                                                        <div className="col-xl-3 col-md-4 p-0">
                                                            {
                                                                this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':move_image') !== -1
                                                                    ||
                                                                    this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':delete_image') !== -1
                                                                    ?
                                                                    <label className="m-checkbox m-checkbox--success hide_mobile mt-3" style={{ 'visibility': 'hidden' }}>
                                                                        <input type="checkbox" onClick={(e) => {
                                                                            this.setState({
                                                                                checkDeleteMulti: !this.state.checkDeleteMulti,
                                                                                listChecked: {
                                                                                    valueImg: [],
                                                                                    _id: [],
                                                                                }
                                                                            })
                                                                        }} checked={this.state.checkDeleteMulti} /> <div style={{ 'paddingTop': '2px' }}>{language[this.props.indexLanguage].Capture.select_multi}</div>
                                                                        <span />
                                                                    </label>
                                                                    :
                                                                    ""
                                                            }
                                                            {
                                                                this.name !== '' && this.state.crrImages[this.state.crrIdx] != undefined ? (
                                                                    <div className="col-xl-12 pt-3 pb-3 pl-0 pr-0">
                                                                        <div className="col-md-12 p-0  mx-auto">
                                                                            <QueueAnim duration={1000} type="scale">
                                                                                <img key="1" src={this.state.crrImages[this.state.crrIdx].url_img ? (this.state.crrImages[this.state.crrIdx].url_img.includes("http") ? `${this.state.crrImages[this.state.crrIdx].url_img}` : `${this.state.crrApiImg}${this.state.crrImages[this.state.crrIdx].url_img}`) : `${this.state.crrApiImg}/view/image/1/${this.state.crrImages[this.state.crrIdx]['idCamera'] + '/' + this.state.crrImages[this.state.crrIdx]['imgFace']}`} alt='Lựa chọn ảnh' className="image_capture_big col-md-12 p-0" />
                                                                            </QueueAnim>
                                                                        </div>

                                                                    </div>


                                                                ) : ''
                                                            }
                                                        </div>
                                                        <div className={this.state.selectDepartment ? "col-xl-5 col-md-8 list_col_image" : "col-xl-9 col-md-8 list_col_image"}>
                                                            {
                                                                this.state.listImage.length > 0
                                                                    ?
                                                                    <div className="mt-2 mb-2">
                                                                        {
                                                                            this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':move_image') !== -1
                                                                                ||
                                                                                this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':delete_image') !== -1
                                                                                ?
                                                                                <label className="m-checkbox m-checkbox--success mt-3 ml-1">
                                                                                    <input type="checkbox" onClick={(e) => {
                                                                                        this.setState({
                                                                                            checkDeleteMulti: !this.state.checkDeleteMulti,
                                                                                            listChecked: {
                                                                                                valueImg: [],
                                                                                                _id: [],
                                                                                            }
                                                                                        })
                                                                                    }} checked={this.state.checkDeleteMulti} /> <div style={{ 'paddingTop': '2px' }}>{language[this.props.indexLanguage].Capture.select_multi}</div>
                                                                                    <span />
                                                                                </label>
                                                                                :
                                                                                ""
                                                                        }
                                                                        {
                                                                            this.state.checkDeleteMulti === true
                                                                                ?
                                                                                <div style={{ 'display': 'inline-flex' }} className="pull-right">
                                                                                    {/* {
                                                                                        this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':move_image') !== -1
                                                                                            ?
                                                                                            <button
                                                                                                className="btn btn-accent mr-2 width-mobie--100 m-loader--light m-loader--right" disabled={this.state.listChecked.valueImg.length > 0 ? false : true} onClick={() => this.MoveImage()} id="btn_move_img">
                                                                                                {language[this.props.indexLanguage].textButton.move_img}
                                                                                            </button>
                                                                                            :
                                                                                            ""
                                                                                    } */}
                                                                                    {
                                                                                        this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':delete_image') !== -1
                                                                                            ?
                                                                                            <button
                                                                                                onClick={(e) => {
                                                                                                    if (this.state.listChecked.valueImg.length > 0) {
                                                                                                        e.preventDefault();
                                                                                                        if (!window.confirm(language[this.props.indexLanguage].Text_Notifi.text_notifi_delete_item)) {
                                                                                                            return;
                                                                                                        }
                                                                                                        this.deleteFaceMulti(
                                                                                                            this.state.Staff_img['object_id'],
                                                                                                            this.state.listChecked
                                                                                                        );
                                                                                                    } else {
                                                                                                        swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].Capture.check_move_img, "warning");
                                                                                                    }
                                                                                                }}
                                                                                                className="btn btn-danger width-mobie--100 m-loader--light m-loader--right" disabled={this.state.listChecked.valueImg.length > 0 ? false : true} id="btn_deleteMulti">
                                                                                                {language[this.props.indexLanguage].tooltip.open_delete}
                                                                                            </button>
                                                                                            :
                                                                                            ""
                                                                                    }
                                                                                </div>
                                                                                :
                                                                                ""
                                                                        }

                                                                    </div>
                                                                    :
                                                                    ""
                                                            }

                                                            <div className="row col-md-12 pr-0 image_capture_small pull-left">
                                                                {
                                                                    bulletedImg
                                                                }
                                                            </div>
                                                            {
                                                                this.state.listImage.length > 0
                                                                    ?
                                                                    <div className="col-xl-12  pl-0 pr-4 pull-left">
                                                                        <div className="pull-left">
                                                                            <Pagination
                                                                                firstPageText={language[this.props.indexLanguage].pagination.first}
                                                                                nextPageText={language[this.props.indexLanguage].pagination.next}
                                                                                lastPageText={language[this.props.indexLanguage].pagination.last}
                                                                                prevPageText={language[this.props.indexLanguage].pagination.previous}
                                                                                activePage={this.state.activePage}
                                                                                itemsCountPerPage={this.itemsPerPage}
                                                                                totalItemsCount={this.state.listImage.length}
                                                                                pageRangeDisplayed={pageRangeDisplayed}
                                                                                onChange={this.handlePageChange}
                                                                            />
                                                                        </div>
                                                                        <span className="pull-right p-2">
                                                                            {language[this.props.indexLanguage].Capture.showing} {this.state.showFirst} {language[this.props.indexLanguage].Capture.to} {this.state.showLast} {language[this.props.indexLanguage].Capture.of} {this.state.totalLength}
                                                                        </span>
                                                                    </div>
                                                                    :
                                                                    ""
                                                            }

                                                            <ModalMoveImg
                                                                listimage={this.state.listImage}
                                                                listchecked={this.state.listChecked}
                                                                staffimage={this.state.Staff_img}
                                                                crrimages={this.state.crrImages}
                                                                crridx={this.state.crrIdx}
                                                                datacountstaff={this.state.dataCountStaff}
                                                                department={this.state.optionDepartment}
                                                                valuedepartment={this.state.valueDepartment}
                                                                datatostaff={this.state.listStaff}
                                                                show={this.state.modalMoveImage}
                                                                onHide={(valueStaff, listImage, checkSuccess) => this.modalClose(valueStaff, listImage, checkSuccess)}
                                                            />
                                                            <div className="text-center col-md-12">
                                                                <PulseLoader
                                                                    css={override}
                                                                    sizeUnit={"px"}
                                                                    size={12}
                                                                    margin={'2px'}
                                                                    color={'#36D7B7'}
                                                                    loading={this.state.loading_img_trainning}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            {/*end::Form*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LoadingOverlay>
            </div>
            </>
        );
    }
}
const mapStateToProps = state => {
    return {
        indexLanguage: state.changelanguage.indexLanguage,
    }
}


export default withStyles(useStyles)(connect(mapStateToProps, null)(Capture));
