import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import language from '../../../../../language/language';
import { useSelector } from 'react-redux';
import moment from 'moment';
import axios from 'axios';
import swal from 'sweetalert';
import 'moment-timezone';
import ReactGA from 'react-ga';
import { Button } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Moment from 'moment';

const actionButtonStyle = {
    width: "100%",
    marginBottom: "5px",
    fontFamily: "inherit"
};

ApprovalForm.propTypes = {
    data: PropTypes.object,
    onHide: PropTypes.func,
    show: PropTypes.bool,
    onRefresh: PropTypes.func,
};

ApprovalForm.defaultProps = {
    data: null,
    onHide: null,
    show: false,
    onRefresh: null,
}
const NameTag = 'daysoff_form';
const type = 'biface';
function ApprovalForm(props) {
    const { data, show, onHide, onRefresh } = props;
    const token = useSelector(state => state.isLogin.access_token);

    const indexLanguage = useSelector(state => state.changelanguage.indexLanguage);
    const box_engine_cf = useSelector(state => state.boxai.box_engine_cf);
    const dataRole = useSelector(state => state.role.role);
    const [optionReason, setOptionReason] = useState([]);
    // [
    //     { value: "4_1", title: "Xin đến muộn - Lý do CBQL chấp nhận" },
    //     { value: "5_1", title: "Xin đến muộn do golive" },
    //     { value: "5_2", title: "Đi đào tạo/Đi công tác/Đi họp" },
    //     { value: "5_3", title: "WFH-Làm việc tại nhà do dịch bệnh" },
    //     { value: "5_4", title: "Nghỉ việc/Nghỉ sinh" },
    //     { value: "5_5", title: "Lỗi máy VDI/ VPN/ Hệ Thống Checkin" },
    //     { value: "5_6", title: "Nghỉ bù" },
    //     { value: "5_7", title: "Nghỉ phép" },
    //     { value: "5_8", title: "Trực/hỗ trợ" },
    // ];

    const getCurrentData = () => {
        var currentData = data.data;
        setNote("");
        if (typeof currentData == "undefined")
            return;
        if (currentData.dof_list.length == 0)
            return;
        var reason = JSON.parse(currentData.dof_list[0].description);
        for (var i = 0; i < optionReason.length; i++) {
            if (reason.content == optionReason[i].title)
                setDescription(optionReason[i]);
        }
        if (typeof reason.note != "undefined" && reason.note != "")
            setNote(reason.note);
    }
    const [description, setDescription] = useState({});
    const [note, setNote] = useState("");
    useEffect(() => {
        getCurrentData();
    }, [data])

    useEffect(() => {
        getReasonConfirm();
    }, [box_engine_cf])

    useEffect(() => {
        setDescription(optionReason[0]);
    }, [optionReason])

    const getReasonConfirm = async () => {
        const result = await axios({
            method: 'GET',
            url: `${box_engine_cf.crrApiSocket}/api/reason_off/compliance_default`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token
            }
        });
        setOptionReason(result.data.data)
    }

    const handleConfirm = async () => {
        var temp = description.value.split("_");
        var type = parseInt(temp[0]);
        // if (data.data.time_in == "" && type == 4) {
        //     alert("Lí do không phù hợp");
        //     return;
        // }
        data.start_date = Moment(new Date(data.data.date)).format('YYYY-MM-DD') + ' ' + (type == 4 && data.data.time_in != "" ? (data.data.time_in) : data.data.shift_time_in);
        data.type = data.data.time_in == "" && type == 4 ? 5 : type;
        if (type == 4) {
            data.description = data.data.time_in != "" ? JSON.stringify({
                content: description.title,
                note: note,
                time_in: data.data.time_in,
                time_out: data.data.shift_time_out.substring(0, 5)
            }) : JSON.stringify({
                content: description.title,
                note: note,
                is_go_late: true
            });
        } else {
            data.description = JSON.stringify({
                content: description.title,
                note: note
            });
        }
        if (type == 1) {
            data.id_reason = 1;
            data.work_off = 1;
            data.work_off_1 = 1;
        }
        delete data.data;
        var dataPost = data;
        const result = await axios({
            method: 'POST',
            url: `${box_engine_cf.crrApiSocket}/api/daysoff_form/insert_or_update`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token
            },
            data: dataPost,
        });

        if (result.data.status === 10000) {
            swal({
                title: language[indexLanguage].Text_Notifi.success,
                text: language[indexLanguage].Text_Notifi.notifi_sure_success_dayoff_form_in_late,
                icon: "success",
                buttons: false,
                timer: 1500
            });
            onHide();
            setDescription(description);
            onRefresh();
        } else if (result.data.status === 10004) {
            const data = result.data.form_err;
            let name = '';
            data.map((map, index) => {
                if (index === 0) {
                    name = map.staff.name
                } else {
                    name = name + ' / ' + map.staff.name
                }
            })
            swal({
                title: language[indexLanguage].Text_Notifi.error,
                text: `${language[indexLanguage].Text_Notifi.notifi_sure_holidays_exceeded}${name}`,
                icon: "error",
            });
        } else if (result.data.status === 10003) {
            const data = result.data.staff_err;
            let name = '';
            data.map((map, index) => {
                if (index === 0) {
                    name = map.name
                } else {
                    name = name + ' / ' + map.name
                }
            })
            swal({
                title: language[indexLanguage].Text_Notifi.error,
                text: `${language[indexLanguage].Text_Notifi.notifi_sure_staff} ${name} ${language[indexLanguage].Text_Notifi.notifi_sure_not_start_day_work}`,
                icon: "error",
            });
        } else {
            swal({ title: language[indexLanguage].Text_Notifi.error, text: language[indexLanguage].Text_Notifi.notifi_sure_error_dayoff_form, icon: "error", buttons: false, timer: 1500 });
        }
    }

    if (!data) return <></>;
    return (
        <Modal show={show} size="md" onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{language[indexLanguage].DayOff.confirm_in_late}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form-group m-form__group col-md-12 pl-0 pr-0 pt-0">
                    <label htmlFor="Staff">{language[indexLanguage].DayOff.reason} <span className="text-danger">* </span></label>
                    <Autocomplete
                        value={description}
                        options={optionReason}
                        onChange={(e, value) => setDescription(value)}
                        getOptionLabel={option => option.title}
                        disableClearable
                        renderInput={params => (
                            <TextField
                                {...params}
                                variant='outlined'
                                placeholder={language[indexLanguage].DayOff.choose_reason}
                                fullWidth
                            />
                        )}
                    />
                </div>
                <div className="form-group m-form__group col-md-12 pl-0 pr-0 pt-0">
                    <label htmlFor="Staff">{language[indexLanguage].DayOff.note}</label>
                    <textarea
                        className="form-control m-input"
                        name="description"
                        placeholder={language[indexLanguage].DayOff.description_approval}
                        rows="5"
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                    ></textarea>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="accent" id="button_addStaff" className="m-loader--light m-loader--right" onClick={() => handleConfirm()}>{language[indexLanguage].DayOff.confirm}</Button>
                <Button variant="secondary" onClick={onHide}>{language[indexLanguage].textButton.close}</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ApprovalForm;