import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { HOST } from '../../../../config';
import Store from '../../../../store';
import swal from 'sweetalert';
import ModalRole from "./ModalRole";
import { css } from '@emotion/core';
import { PulseLoader } from 'react-spinners';
import Pagination from "react-js-pagination";
import language from '../../../../language/language';
import ReactTooltip from 'react-tooltip';
import $ from 'jquery';
import ReactGA from 'react-ga';

import { connect } from 'react-redux'
import Header from '../../../layouts/Header';
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class Role extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogin: Store.getState().isLogin.isLogin,
            access_token: Store.getState().isLogin.access_token,
            crrApi2: Store.getState().boxai.box_engine_cf.crrApiSocket,
            language: Store.getState().changelanguage.language,
            box_engine_cf: Store.getState().boxai.box_engine_cf,
            modalRole: false,
            listRole: [],
            listStaff: [],
            crrDatas: [],
            activePage: 1,
            offset: 0,
            crrRole: {
                name: "",
                description: ""
            },
        };
        Store.subscribe(() => {
            this.setState({
                isLogin: Store.getState().isLogin.isLogin,
                access_token: Store.getState().isLogin.access_token,
                crrApi2: Store.getState().boxai.box_engine_cf.crrApiSocket,
                language: Store.getState().changelanguage.language,
                box_engine_cf: Store.getState().boxai.box_engine_cf,
                listRole: [],
                listStaff: [],
                crrDatas: [],
                activePage: 1,
                offset: 0,
            }, () => {
                this.getData()
            });
        });
        this.itemsPerPage = 10;
        this.getData()
    }


    getData = () => {
        this.getRole()
    }

    getRole = () => {
        if (this.state.crrApi2 == undefined) return;
        fetch(`${this.state.crrApi2}/api/role/${this.state.box_engine_cf.idCompany}/get_all`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                this.setState({
                    listRole: data.data
                });
                this.FilterRole(this.state.activePage)
            }
        })
    }

    FilterRole(activePage) {

        //console.log('123')
        const { listRole } = this.state;

        const offset = (activePage - 1) * this.itemsPerPage;
        const crrDatas = listRole.slice(offset, offset + this.itemsPerPage);
        console.log(crrDatas)
        this.setState({
            crrDatas,
            offset: offset
        })
    }

    handlePageChange = (pageNumber) => {
        this.setState({
            ...this.state,
            activePage: pageNumber
        })
        this.FilterRole(pageNumber);

    }



    DeleteRole = async (Role) => {
        if (Role.is_default === 1) {
            swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].Text_Notifi.notifi_error_delete_role_is_default, "warning");
            return;
        }
        let data = await fetch(`${this.state.crrApi2}/api/role/delete`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token,
            },
            body: JSON.stringify({
                'id': Role.id
            })
        }).then((response) => {
            return response.json()
        });
        if (data.status === 10000) {
            ReactGA.event({
                category: 'Quyen',
                action: 'Xoa quyen',
              });
            swal(language[this.props.indexLanguage].Text_Notifi.success, language[this.props.indexLanguage].Text_Notifi.notifi_delete_success, "success", {
                buttons: false,
                timer: 1500,
            });
            this.getRole();
        }
        else {
            swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].Text_Notifi.notifi_delete_false, "error");
        }

    }

    modalClose = () => {
        this.setState({
            modalRole: false,
        });
        this.getRole();
    }

    render() {
        if (this.state.isLogin === false) {
            return (
                <Redirect to={'/login'} />
            )
        }

        let bulletedRole = this.state.crrDatas.map((e, i) => {
            var is_default = ""
            if (e.is_default === 1) {
                is_default = " (Default)"
            }
            return (
                <tr key={(i + 1)}>
                    <td>{(i + this.state.offset + 1)}</td>
                    <td>{e.name}</td>
                    <td>
                        {
                            e.active === 0
                                ?
                                <span className="m-badge  m-badge--secondary m-badge--wide">Inactive {is_default}</span>
                                :
                                <span className="m-badge  m-badge--accent m-badge--wide">Active {is_default}</span>
                        }

                    </td>
                    <td>
                        <button onClick={(e) => {
                            var data = this.state.crrDatas
                            var dataRole = {
                                id: data[i].id,
                                name: data[i].name,
                                active: data[i].active,
                                id_company: this.state.box_engine_cf.idCompany,
                                roles: data[i].roles,
                                is_default: data[i].is_default,
                                HOST: this.state.crrApi2
                            };
                            this.setState({
                                crrRole: dataRole,
                                modalRole: true,
                            });


                        }} className="m-portlet__nav-link btn m-btn m-btn--hover-warning m-btn--icon m-btn--icon-only m-btn--pill" data-tip data-for='Edit'>
                            <i className="la la-edit" />
                        </button>
                        <ReactTooltip id='Edit' type='dark' effect='solid'>
                            <span>{language[this.props.indexLanguage].tooltip.open_edit}</span>
                        </ReactTooltip>


                        <button className="m-portlet__nav-link btn m-btn m-btn--hover-danger m-btn--icon m-btn--icon-only m-btn--pill" data-tip data-for='Delete'
                            onClick={v => {
                                v.preventDefault();
                                swal({
                                    title: language[this.props.indexLanguage].Text_Notifi.are_you_sure,
                                    text: language[this.props.indexLanguage].Text_Notifi.notifi_sure_delete_role + e.name,
                                    icon: "warning",
                                    buttons: true,
                                    dangerMode: true,
                                })
                                    .then(willDelete => {
                                        if (willDelete) {
                                            this.DeleteRole(e);
                                        }
                                    });

                            }}
                        ><i className="la la-trash"></i></button>
                        <ReactTooltip id='Delete' type='dark' effect='solid'>
                            <span>{language[this.props.indexLanguage].tooltip.open_delete}</span>
                        </ReactTooltip>
                    </td>
                </tr>
            )
        });

        var pageRangeDisplayed = 1;
        if ($(window).width() < 768) {
            pageRangeDisplayed = 3
        } else {
            pageRangeDisplayed = 5
        }

        return (
            <>
            <Header />
            <div className={"m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body "}>
            <div className="m-grid__item m-grid__item--fluid m-wrapper">
                <div className="m-content p-2">
                    <div className="m-portlet m-portlet--tab mb-0">
                        <div className="m-portlet__head p-3">
                            <div className="m-portlet__head-caption pl-3">
                                <div className="m-portlet__head-title">
                                    <h3 className="m-portlet__head-text">
                                        {language[this.props.indexLanguage].Menubar.Role}
                                    </h3>
                                </div>
                            </div>
                            <div className="m-portlet__head-tools pr-3">
                                <ul className="m-portlet__nav">
                                    <li className="m-portlet__nav-item">
                                        <button onClick={(e) => {
                                            this.setState({
                                                crrRole: {
                                                    name: "",
                                                    active: 1,
                                                    id_company: this.state.box_engine_cf.idCompany,
                                                    roles: [],
                                                    is_default: 0,
                                                    HOST: this.state.crrApi2
                                                },
                                                modalRole: true,
                                            });
                                        }} className="btn btn-accent m-btn m-btn--custom m-btn--icon m-btn--pill m-btn--air">
                                            <span>
                                                <i className="la la-plus" />
                                                <span>{language[this.props.indexLanguage].textButton.add}</span>
                                            </span>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="m-portlet__body m-portlet__body--no-padding">
                            <div className="row m-row--no-padding m-row--col-separator-xl">
                                <div className="col-xl-12">
                                    <div className="m-widget1 col-xl-8 mx-auto">
                                        <div className="table-responsive text-nowrap">
                                            <table className="table table-bordered table-hover table-checkable dataTable no-footer dtr-inline collapsed">
                                                <thead>
                                                    <tr>
                                                        <th style={{ 'verticalAlign': 'middle', 'width': '100px' }}>{language[this.props.indexLanguage].Other.no}</th>
                                                        <th style={{ 'verticalAlign': 'middle' }}>{language[this.props.indexLanguage].textTable.name}</th>
                                                        <th style={{ 'verticalAlign': 'middle', 'width': '200px' }}>{language[this.props.indexLanguage].textTable.status}</th>
                                                        <th style={{ 'verticalAlign': 'middle', 'width': '150px' }}>{language[this.props.indexLanguage].textTable.action}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {bulletedRole}
                                                </tbody>
                                            </table>
                                            {/* <PulseLoader
                                                css={override}
                                                sizeUnit={"px"}
                                                size={12}
                                                margin={'2px'}
                                                color={'#36D7B7'}
                                                loading={this.state.loading}
                                            /> */}

                                            <ModalRole
                                                data={this.state.crrRole}
                                                show={this.state.modalRole}
                                                onHide={this.modalClose}
                                            />
                                        </div>
                                        {/* <span>
                                            Showing {this.state.showFirst} to {this.state.showLast} of {this.state.totalLength} entries
                                        </span> */}
                                        <Pagination
                                            firstPageText={language[this.props.indexLanguage].pagination.first}
                                            nextPageText={language[this.props.indexLanguage].pagination.next}
                                            lastPageText={language[this.props.indexLanguage].pagination.last}
                                            prevPageText={language[this.props.indexLanguage].pagination.previous}
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={this.itemsPerPage}
                                            totalItemsCount={this.state.listRole.length}
                                            pageRangeDisplayed={pageRangeDisplayed}
                                            onChange={this.handlePageChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </>
        );
    }
}
const mapStateToProps = state => {
    return {
        indexLanguage: state.changelanguage.indexLanguage,
    }
}
export default connect(mapStateToProps, null)(Role);
