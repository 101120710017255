import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { HOST } from '../../../../config';
import Store from '../../../../store';
import swal from 'sweetalert';
import ModalGroup from "./ModalGroup";
import { css } from '@emotion/core';
import { PulseLoader } from 'react-spinners';
import Pagination from "react-js-pagination";
import language from '../../../../language/language';
import ReactTooltip from 'react-tooltip';
import $ from 'jquery';
import ReactGA from 'react-ga';

import { connect } from 'react-redux'
import Header from '../../../layouts/Header';
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;
const nameTab = 'group'
class Group extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogin: Store.getState().isLogin.isLogin,
            access_token: Store.getState().isLogin.access_token,
            crrApi2: Store.getState().boxai.box_engine_cf.crrApiSocket,
            language: Store.getState().changelanguage.language,
            box_engine_cf: Store.getState().boxai.box_engine_cf,
            dataRole: Store.getState().role.role,
            modalGroup: false,
            listGroup: [],
            listStaff: [],
            crrDatas: [],
            activePage: 1,
            offset: 0,
            totalLength: 0,
            crrGroup: {
                name: "",
                description: ""
            },
            type: '',
            valueSearch: '',
        };
        Store.subscribe(() => {
            this.setState({
                isLogin: Store.getState().isLogin.isLogin,
                access_token: Store.getState().isLogin.access_token,
                crrApi2: Store.getState().boxai.box_engine_cf.crrApiSocket,
                language: Store.getState().changelanguage.language,
                box_engine_cf: Store.getState().boxai.box_engine_cf,
                dataRole: Store.getState().role.role,
                listGroup: [],
                listStaff: [],
                crrDatas: [],
                activePage: 1,
                offset: 0,
                totalLength: 0,
                valueSearch: '',
            }, () => {
                $('#inputSearch').val('')
                this.getData()
            });
        });
        this.itemsPerPage = 10;
        this.getData()
    }

    componentWillMount() {
        var type = localStorage.getItem("type")
        this.setState({
            type: type
        })
    }

    getData = () => {
        this.getGroup()
        this.getStaff()
    }

    getGroup = () => {
        if (this.state.crrApi2 == undefined) return;
        fetch(`${this.state.crrApi2}/api/group/${this.state.box_engine_cf.idCompany}/get_all`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                for (let index = 0; index < data.data.length; index++) {
                    for (let ii = 0; ii < data.data[index].staff_group.length; ii++) {
                        if (data.data[index].staff_group[ii].staff.is_active === 0) {
                            data.data[index].staff_group.splice(ii, 1)
                            ii--
                        }
                    }
                }
                this.setState({
                    listGroup: [...data.data],
                });
                this.FilterGroup(this.state.activePage)
            }
        })
    }

    getStaff = () => {
        if (this.state.crrApi2 == undefined) return;
        fetch(`${this.state.crrApi2}/api/staff/${this.state.box_engine_cf.idCompany}/get_all`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                this.setState({
                    listStaff: [...data.data],
                });
            }
        })
    }

    FilterGroup(activePage) {

        //console.log('123')
        var dataSearchGroup = [];
        this.state.listGroup.forEach((item) => {
            if (item.name.toLowerCase().indexOf(this.state.valueSearch.toLowerCase()) !== -1) {
                dataSearchGroup.push(item);
            }
        })
        const offset = (activePage - 1) * this.itemsPerPage;
        const crrDatas = dataSearchGroup.slice(offset, offset + this.itemsPerPage);

        this.setState({
            crrDatas,
            offset: offset,
            totalLength: dataSearchGroup.length
        })
    }

    handlePageChange = (pageNumber) => {
        this.setState({
            ...this.state,
            activePage: pageNumber
        })
        this.FilterGroup(pageNumber);
    }

    DeleteGroup = async (Group) => {
        let data = await fetch(`${this.state.crrApi2}/api/group/delete`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token,
            },
            body: JSON.stringify({
                'id': Group.id
            })
        }).then((response) => {
            return response.json()
        });
        if (data.status === 10000) {
            ReactGA.event({
                category: 'Nhom',
                action: 'Xoa nhom',
              });
            swal(language[this.props.indexLanguage].Text_Notifi.success, language[this.props.indexLanguage].Text_Notifi.notifi_delete_success, "success", {
                buttons: false,
                timer: 1500,
            });
            this.getGroup();
        }
        else {
            swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].Text_Notifi.notifi_delete_false, "error");
        }

    }

    modalClose = () => {
        this.setState({
            modalGroup: false,
        });
        this.getGroup();
    }

    btnSearch = () => {
        this.setState({
            activePage: 1
        }, () => {
            this.FilterGroup(this.state.activePage);
        })
    }

    isChange = (event) => {
        if (event.key === 'Enter') {
            this.btnSearch(event.target.value.trim())
        }
        this.setState({
            valueSearch: event.target.value.trim()
        });
    }

    render() {
        if (this.state.isLogin === false) {
            return (
                <Redirect to={'/login'} />
            )
        }

        console.log(this.state.activePage)

        let bulletedGroup = this.state.crrDatas.map((e, i) => {
            var listStaff = [];
            if (e.staff_group.length > 0) {
                for (let index = 0; index < e.staff_group.length; index++) {
                    listStaff.push(e.staff_group[index].staff.name)
                }
            } else {
                listStaff = ""
            }

            return (
                <tr key={(i + 1)}>
                    <td>{(i + this.state.offset + 1)}</td>
                    <td>{e.name}</td>
                    <td style={{ "whiteSpace": "normal" }}>{listStaff.toString()}</td>
                    <td style={{ "whiteSpace": "normal" }}>{e.description}</td>
                    {
                        this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':insert_or_update') !== -1
                            ||
                            this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':delete') !== -1
                            ?
                            <td>
                                {
                                    this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':insert_or_update') !== -1
                                        ?
                                        <>
                                            <button onClick={(e) => {
                                                var data = this.state.crrDatas
                                                var dataGroup = {
                                                    id: data[i].id,
                                                    name: data[i].name,
                                                    id_company: this.state.box_engine_cf.idCompany,
                                                    description: data[i].description,
                                                    staff_group: data[i].staff_group,
                                                    HOST: this.state.crrApi2
                                                };
                                                this.setState({
                                                    crrGroup: dataGroup,
                                                    modalGroup: true,
                                                });


                                            }} className="m-portlet__nav-link btn m-btn m-btn--hover-warning m-btn--icon m-btn--icon-only m-btn--pill" data-tip data-for='Edit'>
                                                <i className="la la-edit" />
                                            </button>
                                            <ReactTooltip id='Edit' type='dark' effect='solid'>
                                                <span>{language[this.props.indexLanguage].tooltip.open_edit}</span>
                                            </ReactTooltip>
                                        </>
                                        :
                                        ""
                                }
                                {
                                    this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':delete') !== -1
                                        ?
                                        <>
                                            <button className="m-portlet__nav-link btn m-btn m-btn--hover-danger m-btn--icon m-btn--icon-only m-btn--pill" data-tip data-for='Delete'
                                                onClick={v => {
                                                    v.preventDefault();
                                                    swal({
                                                        title: language[this.props.indexLanguage].Text_Notifi.are_you_sure,
                                                        text: language[this.props.indexLanguage].Text_Notifi.notifi_sure_delete_group + e.name,
                                                        icon: "warning",
                                                        buttons: true,
                                                        dangerMode: true,
                                                    })
                                                        .then(willDelete => {
                                                            if (willDelete) {
                                                                this.DeleteGroup(e);
                                                            }
                                                        });

                                                }}
                                            ><i className="la la-trash"></i></button>
                                            <ReactTooltip id='Delete' type='dark' effect='solid'>
                                                <span>{language[this.props.indexLanguage].tooltip.open_delete}</span>
                                            </ReactTooltip>
                                        </>
                                        :
                                        ""
                                }
                            </td>
                            :
                            ""
                    }
                </tr>
            )
        });

        var pageRangeDisplayed = 1;
        if ($(window).width() < 768) {
            pageRangeDisplayed = 3
        } else {
            pageRangeDisplayed = 5
        }

        return (
            <>
            <Header />
            <div className={"m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body "}>
            <div className="m-grid__item m-grid__item--fluid m-wrapper">
                <div className="m-content p-2">
                    <div className="m-portlet m-portlet--tab mb-0">
                        <div className="m-portlet__head p-3">
                            <div className="m-portlet__head-caption pl-3">
                                <div className="m-portlet__head-title">
                                    <h3 className="m-portlet__head-text">
                                        {language[this.props.indexLanguage].textTable.groups}
                                    </h3>
                                </div>
                            </div>
                            <div className="m-portlet__head-tools pr-3">
                                <ul className="m-portlet__nav">
                                    <li className="m-portlet__nav-item">
                                        {
                                            this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':insert_or_update') !== -1
                                                ?
                                                <button onClick={(e) => {
                                                    this.setState({
                                                        crrGroup: {
                                                            name: "",
                                                            description: "",
                                                            id_company: this.state.box_engine_cf.idCompany,
                                                            staff_group: [],
                                                            HOST: this.state.crrApi2
                                                        },
                                                        modalGroup: true,
                                                    });
                                                }} className="btn btn-accent m-btn m-btn--custom m-btn--icon m-btn--pill m-btn--air">
                                                    <span>
                                                        <i className="la la-plus" />
                                                        <span>{language[this.props.indexLanguage].textButton.add}</span>
                                                    </span>
                                                </button>
                                                :
                                                ""
                                        }
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="m-portlet__body m-portlet__body--no-padding">
                            <div className="row m-row--no-padding m-row--col-separator-xl">
                                <div className="col-xl-12">
                                    <div className="m-widget1 col-xl-8 mx-auto">
                                        <div className="row col-md-12 ml-0 mr-0 p-0 mb-2">
                                            <div className="col-lg-2 pl-0 m--margin-bottom-10-tablet-and-mobile">
                                                <input type="text" onKeyUp={(event) => this.isChange(event)} id="inputSearch" className="form-control m-input" placeholder={language[this.props.indexLanguage].placeholderInput.enter_name} data-col-index={0} />
                                            </div>
                                            <div>
                                                <button
                                                    onClick={() => {
                                                        this.btnSearch(this.state.valueSearch)
                                                    }}
                                                    className="btn btn-accent m-btn m-btn--icon" id="m_search">
                                                    <span>
                                                        <i className="la la-search" />
                                                        <span>{language[this.props.indexLanguage].textButton.search}</span>
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="table-responsive text-nowrap">
                                            <table className="table table-bordered table-hover table-checkable dataTable no-footer dtr-inline collapsed">
                                                <thead>
                                                    <tr>
                                                        <th style={{ 'verticalAlign': 'middle', 'width': '100px' }}>{language[this.props.indexLanguage].Other.no}</th>
                                                        <th style={{ 'verticalAlign': 'middle' }}>{language[this.props.indexLanguage].textTable.name}</th>
                                                        <th style={{ 'verticalAlign': 'middle', 'width': '350px' }}>{language[this.props.indexLanguage].textTable.staffs}</th>
                                                        <th style={{ 'verticalAlign': 'middle', 'width': '350px' }}>{language[this.props.indexLanguage].textTable.description}</th>
                                                        {
                                                            this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':insert_or_update') !== -1
                                                                ||
                                                                this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':delete') !== -1
                                                                ?
                                                                <th style={{ 'verticalAlign': 'middle', 'width': '150px' }}>{language[this.props.indexLanguage].textTable.action}</th>
                                                                :
                                                                ""
                                                        }

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {bulletedGroup}
                                                </tbody>
                                            </table>
                                            {/* <PulseLoader
                                                css={override}
                                                sizeUnit={"px"}
                                                size={12}
                                                margin={'2px'}
                                                color={'#36D7B7'}
                                                loading={this.state.loading}
                                            /> */}

                                            <ModalGroup
                                                liststaff={this.state.listStaff}
                                                data={this.state.crrGroup}
                                                show={this.state.modalGroup}
                                                onHide={this.modalClose}
                                            />
                                        </div>
                                        {/* <span>
                                            Showing {this.state.showFirst} to {this.state.showLast} of {this.state.totalLength} entries
                                        </span> */}
                                        <Pagination
                                            firstPageText={language[this.props.indexLanguage].pagination.first}
                                            nextPageText={language[this.props.indexLanguage].pagination.next}
                                            lastPageText={language[this.props.indexLanguage].pagination.last}
                                            prevPageText={language[this.props.indexLanguage].pagination.previous}
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={this.itemsPerPage}
                                            totalItemsCount={this.state.totalLength}
                                            pageRangeDisplayed={pageRangeDisplayed}
                                            onChange={this.handlePageChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </>
        );
    }
}
const mapStateToProps = state => {
    return {
        indexLanguage: state.changelanguage.indexLanguage,
    }
}
export default connect(mapStateToProps, null)(Group);
