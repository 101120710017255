import axios from 'axios';
import $ from 'jquery';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import swal from 'sweetalert';
import Store from '../../../../../store';
import { useSelector } from 'react-redux';
import language from '../../../../../language/language';
import { xoa_dau } from '../../../../../config';
import ReactGA from 'react-ga';

ReasonOffForm.propTypes = {
    show : PropTypes.bool,
    onHide : PropTypes.func,
    data: PropTypes.object,
    box_engine_cf:PropTypes.object,
    onRefresh: PropTypes.func,
};

ReasonOffForm.defaultProps = {
    show: false,
    onHide: null,
    onRefresh: null,
    data: {},
    box_engine_cf: null,
}

const actionButtonStyle = {
    width: "100%",
    marginBottom: "5px",
    fontFamily: "inherit"
};


function ReasonOffForm(props) {
    const {show, onHide , data, box_engine_cf ,onRefresh } = props;

    
    const indexLanguage = useSelector(state => state.changelanguage.indexLanguage);
    
    const [crrData,setCrrData] = useState({
        id : 0,
        name: '',
        description: '',
        coefficient: 100,
        status: 1,
        symbol: '',
        id_company: 0,
    })

    const refreshForm = () => {
        setCrrData({
            id: 0,
            name: '',
            description: '',
            coefficient: 100,
            status: 1,
            symbol: '',
            id_company: 0,
        })
    }

    const submitForm = async (e) => { 
        var form = $("#addReason")
        if (form[0].checkValidity() === false) {
            e.preventDefault()
            e.stopPropagation()
            form.addClass('was-validated')
        }else{

            if (crrData.id !== 0) {
                swal({
                    title: language[indexLanguage].Text_Notifi.are_you_sure,
                    text: language[indexLanguage].Text_Notifi.notifi_sure_edit_reason_off,
                    icon: "warning",
                    button: {
                        text: "Oke!",
                        closeModal: false,
                    },
                })
                    .then(name => {
                        if (!name) throw null;
                        return handleUpdateDate();
                    })
            }else{
                return handleUpdateDate();
            }
           
        }
    };
    const cancelForm = () => { 
        return onHide();
    };

    const handleUpdateDate = async () => {
        const data = await axios({
            method: 'POST',
            url: `${box_engine_cf.crrApiSocket}/api/reason_off/insert_or_update`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            data: crrData,
        })
        if(data.data.status === 10000){
            if(crrData.id !== 0){
                ReactGA.event({
                    category: 'Li do nghi',
                    action: 'Sua li do nghi',
                  });
                swal({ title: language[indexLanguage].Text_Notifi.success, text: language[indexLanguage].Text_Notifi.update_success, icon: "success", buttons: false, timer: 1500 });
            }else{
                ReactGA.event({
                    category: 'Li do nghi',
                    action: 'Them li do nghi',
                  });
                swal({ title: language[indexLanguage].Text_Notifi.success, text: language[indexLanguage].Text_Notifi.notifi_sure_reason_off, icon: "success", buttons: false, timer: 1500 });
            }
            onRefresh();
            refreshForm();
            return onHide();
        }else if(data.data.status === 10001){
            swal({title: language[indexLanguage].Text_Notifi.error, text: language[indexLanguage].Text_Notifi.notifi_sure_already_exits, icon: "error", buttons: false, timer: 1500 });
        }else{
            swal({ title: language[indexLanguage].Text_Notifi.error, text: language[indexLanguage].Text_Notifi.update_false, icon: "error", buttons: false, timer: 1500 });
        }
    }

    const handleChange = (e) => {
        let value = e.target.value;
        if(e.target.name === 'coefficient'){
            return setCrrData({...crrData,coefficient:parseInt(value)})
        }else if(e.target.name === 'symbol'){
            const symbol = xoa_dau(value).toUpperCase();
            return setCrrData({ ...crrData, symbol: symbol })
        }
        setCrrData({...crrData,[e.target.name]:value})
    }
    useEffect(() => {
        setCrrData({
            ...crrData, id: data.id, name: data.name, coefficient: data.coefficient, id_company: data.id_company , symbol: data.symbol, status: data.status,
        })
    },[data])
    

    return (
        <Modal show={show} size="lg" onHide={onHide}>
            <div className='row-sm row'>
                <div className="col-md-12">
                    <div className="m-portlet m-portlet--tab">
                        <Modal.Header closeButton>
                            <Modal.Title className='text-uppercase'>{language[indexLanguage].ReasonOff.create_reason_off}</Modal.Title>
                        </Modal.Header>
                        <form id='addReason' className="m-form m-form--fit">
                            <div className="m-portlet__body">
                                <div className="form-group m-form__group" style={{ paddingTop: 0 }}>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label htmlFor="example-datetime-local-input" className="col-form-label" style={{ textAlign: "left", height: '60px', lineHeight: '1.5' }}>{language[indexLanguage].ReasonOff.reason}  <span className='text-danger'> *</span></label>
                                            <div>
                                                <input className='m-input form-control' onChange={handleChange} name='name' type="text" value={crrData.name} placeholder="Lý do" required />
                                                {/* <span className="m-form__help" style={{ visibility: isValidFromDate ? "hidden" : "visible" }}>Yêu cầu chọn ngày bắt đầu</span> */}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <label htmlFor="example-datetime-local-input" className="col-form-label" style={{ textAlign: "left", height: '60px', lineHeight: '1.5' }}>{language[indexLanguage].ReasonOff.symbol} <span className='text-danger'> *</span><br />{language[indexLanguage].ReasonOff._1_4_word}</label>
                                            <div>
                                                <input className='m-input form-control' onChange={handleChange} name='symbol' type="text" style={{textTransform:'uppercase'}} value={crrData.symbol} placeholder="Ký hiệu" maxLength="4" required/>
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <label htmlFor="example-datetime-local-input" className="col-form-label" style={{ textAlign: "left", height: '60px', lineHeight: '1.5' }}>{language[indexLanguage].ReasonOff.calculation_percent} <span className='text-danger'> *</span></label>
                                            <div className=' percent_salary'>
                                                <input className='m-input form-control' type="number" max='100' min='0' name='coefficient' value={crrData.coefficient} onChange={handleChange} placeholder="Tỷ lệ lương công" defaultValue='100' required/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group m-form__group text-right">
                                    <div className="d-inline-block mr-2">
                                        <button type="button" className="btn btn-accent" style={actionButtonStyle} onClick={(e) => submitForm(e)}>{language[indexLanguage].textButton.save}</button>
                                    </div>
                                    <div className="d-inline-block">
                                        <button type="button" className="btn btn-secondary" style={actionButtonStyle} onClick={() => cancelForm()}>{language[indexLanguage].textButton.close}</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default ReasonOffForm;
