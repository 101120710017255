import axios from 'axios';
import $ from 'jquery';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import language from '../../../../../language/language';
import { xoa_dau } from '../../../../../config';
import Moment from 'moment';
import ReactGA from 'react-ga';
import Store from '../../../../../store';


HolidayForm.propTypes = {
    show: PropTypes.bool,
    onHide: PropTypes.func,
    data: PropTypes.object,
    box_engine_cf: PropTypes.object,
    onRefresh: PropTypes.func,
};

HolidayForm.defaultProps = {
    show: false,
    onHide: null,
    onRefresh: null,
    data: {},
    box_engine_cf: null,
}

const actionButtonStyle = {
    width: "100%",
    marginBottom: "5px",
    fontFamily: "inherit"
};


function HolidayForm(props) {
    const { show, onHide, data, box_engine_cf, onRefresh } = props;

    
    const indexLanguage = useSelector(state => state.changelanguage.indexLanguage);
    const [date,setDate] = useState({
        fromDate: new Date().setDate(new Date().getDate() +1),
        toDate: '',
    })
    const [crrData, setCrrData] = useState({
        id: 0,
        name: '',
        description: '',
        year: 0,
        start_date: Moment(new Date(date.fromDate)).format('YYYY-MM-DD HH:mm') + ':00',
        end_date: Moment(date.toDate).format('YYYY-MM-DD HH:mm') + ':00',
        id_company: 0,
    })

    const refreshForm = () => {
        setCrrData({
            id: 0,
            name: '',
            description: '',
            year: 0,
            start_date: Moment(new Date(date.fromDate)).format('YYYY-MM-DD HH:mm') + ':00',
            end_date: Moment(date.toDate).format('YYYY-MM-DD HH:mm') + ':00',
            id_company: 0,
        })
    }

    const submitForm = async (e) => {
        var form = $("#addReason")
        if (form[0].checkValidity() === false) {
            e.preventDefault()
            e.stopPropagation()
            form.addClass('was-validated')
        } else {

            if (crrData.id !== 0) {
                swal({
                    title: language[indexLanguage].Text_Notifi.are_you_sure,
                    text: language[indexLanguage].Text_Notifi.notifi_sure_edit_holiday,
                    icon: "warning",
                    button: {
                        text: "Oke!",
                        closeModal: false,
                    },
                })
                    .then(name => {
                        if (!name) throw null;
                        return handleUpdateDate();
                    })
            } else {
                return handleUpdateDate();
            }

        }
    };
    const cancelForm = () => {
        swal({
            title: language[indexLanguage].Text_Notifi.are_you_sure,
            // text: language[indexLanguage].Text_Notifi.notifi_sure_cancel_holiday,
            icon: "warning",
            buttons: {
                oke: language[indexLanguage].textButton.oke,
                cancel: language[indexLanguage].textButton.close
            },
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {   
                onHide();
            }
        });
    };

    const handleUpdateDate = async () => {
        const dataPost = {
            id: crrData.id, 
            name: crrData.name, 
            description: crrData.description, 
            year: crrData.year, 
            current_date:  Moment(new Date()).format('YYYY-MM-DD HH:mm') + ':00',
            start_date: Moment(new Date(date.fromDate)).format('YYYY-MM-DD 00:00') + ':00',
            end_date: Moment(date.toDate).format('YYYY-MM-DD 23:59') + ':00',
            id_company: crrData.id_company,
        }
        const data = await axios({
            method: 'POST',
            url: `${box_engine_cf.crrApiSocket}/api/holiday/insert_or_update`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            data: dataPost,
        })
        if (data.data.status === 10000) {
            if (crrData.id !== 0) {
                ReactGA.event({
                    category: 'Ngay le',
                    action: 'Sua ngay le',
                  });
                swal({ title: language[indexLanguage].Text_Notifi.success, text: language[indexLanguage].Text_Notifi.update_success, icon: "success", buttons: false, timer: 1500 });
            } else {
                ReactGA.event({
                    category: 'Ngay le',
                    action: 'Them ngay le',
                  });
                swal({ title: language[indexLanguage].Text_Notifi.success, text: language[indexLanguage].Text_Notifi.notifi_sure_holiday, icon: "success", buttons: false, timer: 1500 });
            }
            onRefresh();
            refreshForm();
            return onHide();
        }else if(data.data.status === 10001){
            swal({title: language[indexLanguage].Text_Notifi.error, text: language[indexLanguage].Text_Notifi.notifi_sure_edit_holiday_name_already_exits, icon: "error", buttons: false, timer: 1500 });
        }else if(data.data.status === 10004){
            swal({title: language[indexLanguage].Text_Notifi.error, text: language[indexLanguage].Text_Notifi.notifi_sure_edit_holiday_expired_date, icon: "error", buttons: false, timer: 1500 });
        }else if(data.data.status === 10003){
            swal({title: language[indexLanguage].Text_Notifi.error, text: language[indexLanguage].Text_Notifi.notifi_sure_edit_holiday_already_exits, icon: "error", buttons: false, timer: 1500 });
        }
    }

    const handleChange = (e) => {
        let value = e.target.value;
        setCrrData({ ...crrData, [e.target.name]: value })
    }

    useEffect(() => {
        setCrrData({
            ...crrData, 
            id: data.id, 
            name: data.name, 
            description: data.description, 
            year: data.year, 
            current_date: Moment(new Date()).format('YYYY-MM-DD HH:mm') + ':00' ,
            start_date: Moment(new Date(date.fromDate)).format('YYYY-MM-DD HH:mm') + ':00',
            end_date: Moment(date.toDate).format('YYYY-MM-DD HH:mm') + ':00',
            id_company: data.id_company,
        });
        setDate({...date,fromDate: new Date(data.start_date).getTime(),toDate: new Date(data.end_date).getTime()});
     
    }, [data])
    

    return (
        <Modal show={show} size="md" onHide={onHide}>
            <div className='row-sm row'>
                <div className="col-xl-12">
                    <div className="m-portlet m-portlet--tab">
                        <Modal.Header closeButton>
                            <Modal.Title className='text-uppercase'>{language[indexLanguage].Holiday.create_holiday}</Modal.Title>
                        </Modal.Header>
                        <form id='addReason' className="m-form m-form--fit">
                            <div className="m-portlet__body">
                                <div className="form-group m-form__group" style={{ paddingTop: 0 }}>
                                    <div className="form-group m-form__group">
                                        <label htmlFor="example-datetime-local-input" className="col-form-label">{language[indexLanguage].Holiday.name_holiday}  <span className='text-danger'> *</span></label>
                                        <div>
                                            <input className='m-input form-control' onChange={handleChange} name='name' type="text" value={crrData.name} placeholder={language[indexLanguage].Holiday.name_holiday} required />
                                        </div>
                                    </div>
                                    <div className="form-group m-form__group">
                                        <label htmlFor="example-datetime-local-input" className="col-form-label">{language[indexLanguage].Holiday.start_date} <span className='text-danger'> *</span></label>
                                       
                                        <div>
                                            <DatePicker
                                                 className="form-control m-input m_datetimepicker_6 test"
                                                 selected={date.fromDate}
                                                 onChange={(newDate) => setDate({  ...date,fromDate: new Date(newDate).getTime()})}
                                                 dateFormat="dd-MM-yyyy"
                                                 selectsStart
                                                 minDate={new Date().setDate(new Date().getDate()+1)}
                                                 name='fromDate'
                                                 startDate={date.fromDate}
                                                 endDate={date.toDate}
                                                 minDate={date.toDate ? new Date(Moment(date.toDate).startOf('month').format('YYYY-MM-DD 00:00:00')) : ''}
                                                 maxDate={date.toDate}
                                                 placeholderText="DD-MM-YYYY"
                                                 required
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group m-form__group">
                                        <label htmlFor="example-datetime-local-input" className="col-form-label">{language[indexLanguage].Holiday.end_date} <span className='text-danger'> *</span></label>
                                       
                                        <div>
                                            <DatePicker
                                                 className="form-control m-input m_datetimepicker_6"
                                                 onChange={(newDate) => setDate({...date,toDate: new Date(newDate).getTime()})}
                                                 selected={date.toDate}
                                                 dateFormat="dd-MM-yyyy"
                                                 selectsStart
                                                 name='toDate'
                                                 startDate={date.fromDate}
                                                 endDate={date.toDate}
                                                 minDate={date.fromDate}
                                                 maxDate={date.fromDate ? new Date(Moment(date.fromDate).endOf('month').format('YYYY-MM-DD 23:59:59')) : ''}
                                                 placeholderText="DD-MM-YYYY"
                                                 required
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group m-form__group">
                                        <label htmlFor="Description">{language[indexLanguage].Holiday.description}</label>
                                        <textarea rows="4" className="form-control m-input" id="Description" name='description' value={crrData.description} onChange={handleChange} placeholder={language[indexLanguage].Holiday.description} />
                                    </div>
                                </div>
                                <div className="form-group m-form__group text-right">
                                    <div className="d-inline-block mr-2">
                                        <button type="button" className="btn btn-accent" style={actionButtonStyle} onClick={(e) => submitForm(e)}>{language[indexLanguage].textButton.save}</button>
                                    </div>
                                    <div className="d-inline-block">
                                        <button type="button" className="btn btn-secondary" style={actionButtonStyle} onClick={() => cancelForm()}>{language[indexLanguage].DayOff.cancel}</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default HolidayForm;
