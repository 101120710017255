import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import language from '../../../../../language/language';
import { KeyboardTimePicker } from '@material-ui/pickers'
import ReactGA from 'react-ga';



LeaveOfAbsenceForm.propTypes = {
    show: PropTypes.bool,
    onHide: PropTypes.func,
    supervisor: PropTypes.array,
    data: PropTypes.object,
    onRefresh: PropTypes.func,
    onDelete: PropTypes.func,
    staff_shift: PropTypes.array,
    list_staff: PropTypes.array,
    s_a_create: PropTypes.bool,
    getStaffShift: PropTypes.func,
}

LeaveOfAbsenceForm.defaultProps = {
    show: false,
    onHide: null,
    supervisor: [],
    data: {},
    onRefresh: null,
    onDelete : null,
    staff_shift: [],
    list_staff: [],
    s_a_create: false,
    getStaffShift: null,
}
const actionButtonStyle = {
    width: "100%",
    marginBottom: "5px",
    fontFamily: "inherit"
};

export default function LeaveOfAbsenceForm(props) {
    const { show, onHide, supervisor, data , onRefresh , onDelete , staff_shift, list_staff, s_a_create, getStaffShift } = props;

    const userData = JSON.parse(localStorage.getItem('data_user'));
    const box_engine_cf = useSelector(state => state.boxai.box_engine_cf);
    const token = useSelector(state => state.isLogin.access_token);

    const [currentManager, setCurrentManager] = useState({});
    const [currentStaff, setCurrentStaff] = useState({});
    const [date,setDate] = useState({
        fromDate: new Date().setHours('08', '00' , '00'),
        toDate: new Date().setHours('08', '00' , '00'),
    })
    const [breakWork,setBreakWork] = useState(0);
    
    
    const indexLanguage = useSelector(state => state.changelanguage.indexLanguage);

    const [status,setStatus] = useState(-1);
    const [crrData, setCrrData] = useState({});
    const [time,setTime] = useState({
        time_in: null,
        time_out: null,
    })

    const [isValidateFromDate, setIsValidateFromDate] = useState(false);
    const [isValidateSupervisor, setIsValidateSuper] = useState(false);
    const [isValidateStaff, setIsValidateStaff] = useState(false);
    const [isValidateTimeIn, setIsValidateTimeIn] = useState(false);
    const [isValidateTimeOut, setIsValidateTimeOut] = useState(false);
    const [validated, setValidated] = useState(false);

    function resetFormToDefault() {
        setDate({
            fromDate: new Date().setHours('08', '00' , '00'),
            toDate: new Date().setHours('08', '00' , '00'),
        })
        setCurrentManager({});
        setCurrentStaff({});
        setIsValidateSuper(false);
        setIsValidateStaff(false);
        setIsValidateTimeIn(false);
        setIsValidateTimeOut(false);
        setIsValidateFromDate(false);
        setCrrData({});
        setTime({
            time_in: null,
            time_out:null,
        })
    }
    const validateForm = () => {

        if (!date.fromDate) {
            setIsValidateFromDate(true);
        }
        if (supervisor.length === 0) {
            setIsValidateSuper(true);
        }
        if (!time.time_in) {
            setIsValidateTimeIn(true);
        }
        if (!time.time_out) {
            setIsValidateTimeOut(true);
        }
        if(Object.keys(currentStaff).length === 0){
            setIsValidateStaff(true);
        }
    }

    const resetValidate = () => {
        setIsValidateSuper(false);
        setIsValidateFromDate(false);
        setIsValidateTimeIn(false);
        setIsValidateTimeOut(false);
        setIsValidateStaff(false);
    }

    const handleUploadData = async () => {
        const dataPost = {
            id: crrData.id,
            id_staff: s_a_create ? currentStaff.id : userData.id_staff,
            start_date: Moment(new Date(date.fromDate)).format('YYYY-MM-DD') + ' ' + Moment(time.time_in).format("HH:mm") + ':00',
            end_date: Moment(new Date(date.fromDate)).format('YYYY-MM-DD') + ' ' + Moment(time.time_out).format("HH:mm") + ':00',
            status: status,
            id_supervisor: s_a_create ? userData.id_staff : currentManager ? currentManager.id : '',
            description: JSON.stringify({
                content: crrData.description ? crrData.description : '',
                time_in: Moment(time.time_in).format('HH:mm'),
                time_out: Moment(time.time_out).format('HH:mm'),
            }),
            work_off:   Math.round(breakWork * 100) / 100,
            work_off_1:   breakWork,
            type: 5,
        }
        const result = await axios({
            method: 'POST',
            url: `${box_engine_cf.crrApiSocket}/api/daysoff_form/insert_or_update`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            data: dataPost,
        })
        if (result.data.status === 10000) {
            if (status === 2) {
                ReactGA.event({
                    category: 'Danh sach don cham cong bu',
                    action: 'Gui don',
                    label: supervisor.length > 0 ? "Nhan vien gui" : "Quan li gui"
                  });
            } else {
                ReactGA.event({
                    category: 'Danh sach don cham cong bu',
                    action: 'Luu nhap',
                  });
            }
            if (crrData.id !== 0) {
                swal({ title: language[indexLanguage].Text_Notifi.success, text: language[indexLanguage].Text_Notifi.update_success, icon: "success", buttons: false, timer: 1500 });
            } else {
                swal({ title: language[indexLanguage].Text_Notifi.success, text: language[indexLanguage].Text_Notifi.notifi_sure_reason_off, icon: "success", buttons: false, timer: 1500 });
            }
            onRefresh(1);
            resetFormToDefault();
            setValidated(false);
            return onHide();
        } else if(result.data.status === 10004){
            const data = result.data.data;
            const reason = data.reason ? `${data.reason.name}` : data.type === 2 ? `${language[indexLanguage].DayOff.ot}`
                : data.type === 3 ? `${language[indexLanguage].DayOff.business}`
                    : data.type === 4 ? `${language[indexLanguage].DayOff.go_late_leave_early}`
                        : data.type === 5 ? `${language[indexLanguage].DayOff.compensate}` : '';
            const date = `${Moment(data.start_date).format('HH:mm DD-MM-YYYY')} - ${Moment(data.end_date).format('HH:mm DD-MM-YYYY')}`
            let my_html = document.createElement('div');
            my_html.innerHTML = `${language[indexLanguage].Text_Notifi.notifi_sure_letter_intersect} <br>${reason.bold()}</br>${date}`;
            swal({
                title: language[indexLanguage].Text_Notifi.notifi_sure_reason_off_error, content: my_html, icon: "warning"
            });
        } else if (result.data.status === 10007) {
            swal({ title: language[indexLanguage].Text_Notifi.error, text: language[indexLanguage].Text_Notifi.notifi_check_through_year, icon: "error", buttons: false, timer: 1500 });
        } else {
            swal({ title: language[indexLanguage].Text_Notifi.error, text: language[indexLanguage].Text_Notifi.notifi_sure_reason_off_error, icon: "error", buttons: false, timer: 1500 });
        }
    }
    const handleSubmit = async (event) => {
        validateForm();
        event.preventDefault();
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
          event.stopPropagation();
        }else{

            if(time.time_in > time.time_out){
                return swal({
                    title: language[indexLanguage].Text_Notifi.warning, text: language[indexLanguage].Text_Notifi.notifi_sure_time_out_than_time_in, icon: "warning", buttons: {
                        text: "Oke!",
                        closeModal: false,
                    }
                });
            }
           
            // Nếu gửi đơn từ bản nháp
            if (status === 2 && data.status === 1) {
                swal({
                    title: language[indexLanguage].Text_Notifi.are_you_sure,
                    text: language[indexLanguage].Text_Notifi.notifi_send_letter,
                    icon: "warning",
                    buttons: {
                        oke: language[indexLanguage].textButton.oke,
                        cancel: language[indexLanguage].textButton.close
                    },
                }).then(async (value) => {
                    if (value === 'oke') {
                       return handleUploadData();
                    }
                })
            } else {
               return handleUploadData();
            }

        }
        setValidated(true);
    }
    const coverTimeToMinutes = (time) => {
        //HH:mm -> mm
        if(time){
            const a = time.split(':');
            return parseInt(a[0]) * 60 + parseInt(a[1]);
        }
    }

    const handleCalculate = (data) => {
        let start = 0;
        let end = 0;

        if(data.start_date <= data.time_in){
            start = data.time_in;
            if(data.end_date < data.time_out){
                end = data.end_date;
            }else{
                end = data.time_out;
            }
        }
        if(data.start_date < data.time_out && data.start_date > data.time_in){
            start = data.start_date;
            if(data.end_date < data.time_out){
                end = data.end_date;
            }else{
                end = data.time_out;
            }
        }
        const time = (coverTimeToMinutes(end) - coverTimeToMinutes(start)) / (coverTimeToMinutes(data.time_out) - coverTimeToMinutes(data.time_in));
        return ((time * 100) / 100) * data.work;
    }

    //Chia 1 ca thanh 2 ca neu co gio nghi
    const splitShiftTimeOff = (data,first,last) => {
        const first_shift = coverTimeToMinutes(data.start_time_off) - coverTimeToMinutes(data.time_in);
        const last_shift = coverTimeToMinutes(data.time_out) - coverTimeToMinutes(data.end_time_off)
        const total_time = first_shift + last_shift;

        return [
            {
                start_date: last ? data.time_in : data.start_date,
                end_date: first ? data.time_out : data.end_date,
                time_in: data.time_in,
                time_out: data.start_time_off,
                work: first_shift * data.work / total_time,
            },
            {
                start_date: last ? data.time_in : data.start_date,
                end_date: first ? data.time_out : data.end_date,
                time_in: data.end_time_off,
                time_out: data.time_out,
                work: last_shift * data.work / total_time,
            }
        ]
    }
    const handleInputDate = () => {
        const start_date = date.fromDate;
        const end_date = date.fromDate;
        if(!end_date){
            return setBreakWork(0);
        }
        
        const data_staff = staff_shift.map(map => {
            let day_of_week = map.shifts_work.day_of_week.slice(1,14).split('|');
            let test = day_of_week[6];
            day_of_week.unshift(test);
            day_of_week.pop();
            return {
                day_of_week: day_of_week,
                time_in: map.shifts_work.time_in,
                time_out: map.shifts_work.time_out,
                start_time_off: map.shifts_work.start_time_off,
                end_time_off: map.shifts_work.end_time_off,
                work: map.shifts_work.work,
            };
        })        
        let break_work = 0;
        var crr_date = start_date;
        for(let i = 0; i < data_staff.length; i++){
                const day = data_staff[i].day_of_week;
                while(Moment(crr_date).format('YYYY-MM-DD') <= Moment(end_date).format('YYYY-MM-DD')){
                    // process on crr_date
                    var crr_shift = day[new Date(crr_date).getDay()]
                    if(crr_shift === '1'){
                        const dataCalculate = {
                            start_date : Moment(time.time_in).format('HH:mm'),
                            end_date: Moment(time.time_out).format('HH:mm'),
                            time_in: data_staff[i].time_in.slice(0,5),
                            time_out: data_staff[i].time_out.slice(0,5),
                            start_time_off: data_staff[i].start_time_off,
                            end_time_off: data_staff[i].end_time_off,
                            work: data_staff[i].work,
                        }
                        //Chi nghi 1 ngay
                        if(Moment(start_date).format('YYYY-MM-DD') === Moment(end_date).format('YYYY-MM-DD')){
                            if ((coverTimeToMinutes(Moment(time.time_in).format('HH:mm:ss')) <= coverTimeToMinutes(data_staff[i].time_in) &&
                                coverTimeToMinutes(Moment(time.time_out).format('HH:mm:ss')) <= coverTimeToMinutes(data_staff[i].time_in)) ||
                                coverTimeToMinutes(data_staff[i].time_out) <= coverTimeToMinutes(Moment(time.time_in).format('HH:mm:ss'))
                            ) {
                            }else{
                                if(dataCalculate.start_time_off){
                                    const a = splitShiftTimeOff(dataCalculate);
                                    a.forEach((value) => {
                                        // Cong them cong neu cong khong am
                                        if(handleCalculate(value) > 0){
                                            break_work+= handleCalculate(value);
                                        }
                                    })

                                }else{
                                    break_work += handleCalculate(dataCalculate);
                                }
                               
                            }
                        }
                    }
                    // add 1 day
                    var ms = crr_date + 86400000;
                    crr_date = ms;
                }
                crr_date = start_date;
        }
        setBreakWork(Math.round(break_work * 100) / 100);
    }
    const handleDelete = () => {
        onDelete(crrData.id);
    }
    function cancelForm() {
        //Delete current Form from DB if exists, if not, redirect to dashboard
        swal({
            title: language[indexLanguage].Text_Notifi.are_you_sure,
            text: language[indexLanguage].Text_Notifi.notifi_sure_delete_daysoff_form,
            icon: "warning",
            buttons: {
                oke: language[indexLanguage].textButton.oke,
                cancel: language[indexLanguage].textButton.close
            },
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete === 'oke') {
                resetFormToDefault();
                onHide();
            }
        });
    }
    const checkStatus = (status) => {
        switch (status) {
            case 2: return language[indexLanguage].DayOff.pending;
            case 3: return language[indexLanguage].DayOff.accept;       
            case 4: return language[indexLanguage].DayOff.refused;
                
            default:
                break;
        }
    }
    const handleChangeTime = (value,name) => {
        resetValidate();
        setTime({
            ...time,[name]: value,
        });
    }
    useEffect(() => {
        setCrrData(Object.assign(crrData,data));
        setDate({...date,fromDate: data.start_date ? new Date(data.start_date).getTime() : date.fromDate ,toDate: date.toDate ? date.toDate : new Date(data.end_date).getTime()});
        setTime({
            ...time,
            time_in: time.time_in ? time.time_in : data.time_in ? new Date(data.time_in) : data.time_in,
            time_out: time.time_out ? time.time_out : data.time_out ? new Date(data.time_out) : data.time_out,
        });
        setCurrentManager(data.supervisor);
    }, [data]);
    useEffect(() => {
        setCurrentManager(supervisor[0]);
    }, [supervisor]);
    useEffect(() => {
        handleInputDate();
    },[date, time]);
    useEffect(() => {
        if(!show){
            resetFormToDefault();
            setValidated(false);
        }
    },[show])
       
    return (
        <Modal show={show} onHide={onHide}>
            <div className='row-sm row'>
                <div className="col-md-12">
                    <div className="m-portlet m-portlet--tab">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {
                                    crrData.status === -1 ? language[indexLanguage].DayOff.create_letter : language[indexLanguage].DayOff.detail_letter
                                }
                            </Modal.Title>
                        </Modal.Header>
                        <Form noValidate validated={validated} onSubmit={(e) => handleSubmit(e)} className="m-form m-form--fit">
                            <div className="m-portlet__body">
                                <div className="form-group m-form__group" style={{ paddingTop: 0 }}>
                                    <div className="row mb-3">
                                        <label htmlFor="example-datetime-local-input" className="col-md-3 pr-md-0 col-form-label" style={{ textAlign: "left" }}>
                                            {language[indexLanguage].TimekeepingCompensate.day}
                                            <span className='text-danger'> *</span></label>
                                        <div className="col-md-9">
                                            <DatePicker
                                                className="form-control m-input m_datetimepicker_6 test"
                                                selected={date.fromDate}
                                                onChange={(newDate) => {
                                                    if (newDate) {
                                                        resetValidate();
                                                    }
                                                    setDate({ ...date, fromDate: new Date(newDate).getTime(), toDate: new Date(newDate).getTime()})}
                                                }
                                                dateFormat="dd-MM-yyyy"
                                                selectsStart
                                                name='fromDate'
                                                startDate={date.fromDate}
                                                endDate={date.toDate}
                                                maxDate={new Date()}
                                                disabled={(crrData.status === 1 || crrData.status === -1) ? false : true}
                                                autoComplete="off"
                                                required
                                            />
                                            {isValidateFromDate && <div className='text-danger pt-2'>{language[indexLanguage].DayOff.information_blank}</div>}
                                        </div>
                                    </div>
                                    <div className="row pb-4">
                                        <label htmlFor="example-datetime-local-input" className="col-md-3 pr-md-0 col-form-label" style={{ textAlign: "left" }}>
                                            {language[indexLanguage].TimekeepingCompensate.time_in}
                                            <span className='text-danger'> *</span>
                                        </label>
                                        <div className="col-md-4">
                                            <KeyboardTimePicker
                                                placeholder='HH:mm'
                                                mask='__:__'
                                                ampm={false}
                                                style={{ width: "100%" }}
                                                value={time.time_in}
                                                onChange={(date) => {
                                                    handleChangeTime(date, 'time_in')}
                                                }
                                                invalidDateMessage={
                                                    language[indexLanguage].Text_Notifi
                                                        .notifi_date_time_invalid
                                                }
                                                required
                                                disabled={(crrData.status === 1 || crrData.status === -1) ? false : true}
                                            />
                                        </div>
                                        {isValidateTimeIn && <div className='text-danger pt-2 offset-md-3 col-md-12'>{language[indexLanguage].DayOff.information_blank}</div>}
                                    </div>
                                    <div className="row pb-4">
                                        <label htmlFor="example-datetime-local-input" className="col-md-3 pr-md-0 col-form-label" style={{ textAlign: "left" }}>
                                            {language[indexLanguage].TimekeepingCompensate.time_out}
                                            <span className='text-danger'> *</span>
                                        </label>
                                        <div className="col-md-4">
                                            <KeyboardTimePicker
                                                placeholder='HH:mm'
                                                mask='__:__'
                                                ampm={false}
                                                style={{ width: "100%" }}
                                                value={time.time_out}
                                                onChange={(date) =>  {
                                                    handleChangeTime(date, 'time_out')}}
                                                invalidDateMessage={
                                                    language[indexLanguage].Text_Notifi
                                                        .notifi_date_time_invalid
                                                }
                                                required
                                                disabled={(crrData.status === 1 || crrData.status === -1) ? false : true}
                                            />
                                        </div>
                                        {isValidateTimeOut && <div className='text-danger pt-2 offset-md-3 col-md-12'>{language[indexLanguage].DayOff.information_blank}</div>}
                                    </div>
                                    {
                                        s_a_create ?
                                            <div className="row mb-3">
                                                <label htmlFor="example-datetime-local-input" className="col-md-3 col-form-label" style={{ textAlign: "left" }}>{language[indexLanguage].DayOff.staff} <span className='text-danger'> *</span></label>
                                                <div className="col-md-9">
                                                    <Autocomplete
                                                        className="select_option disable_style"
                                                        options={list_staff}
                                                        getOptionLabel={(staff) => staff.name}
                                                        value={currentStaff}
                                                        onChange={(event, value) => {
                                                            if (value) {
                                                                resetValidate();
                                                            };
                                                            setCurrentStaff(value);
                                                            getStaffShift(value.id);
                                                        }}
                                                        disableClearable
                                                        renderInput={params => (<TextField {...params} variant="outlined" placeholder={language[indexLanguage].DayOff.choose_staff} />)}
                                                        disabled={(crrData.status === 1 || crrData.status === -1) ? false : true}
                                                    />
                                                    {isValidateStaff && <div className='text-danger pt-2'>{language[indexLanguage].DayOff.information_blank}</div>}
                                                </div>
                                            </div>
                                            :
                                            <div className="row mb-4">
                                                <label htmlFor="example-datetime-local-input" className="col-md-3 pr-md-0 col-form-label" style={{ textAlign: "left" }}>
                                                    {language[indexLanguage].Business.supervisor}
                                                    <span className='text-danger'> *</span></label>
                                                <div className="col-md-9">
                                                    <Autocomplete
                                                        className="select_option disable_style"
                                                        options={supervisor}
                                                        getOptionLabel={(supervisor) => supervisor.name}
                                                        value={currentManager}
                                                        onChange={(event, value) => { setCurrentManager(value) }}
                                                        disableClearable
                                                        renderInput={params => (<TextField {...params} variant="outlined" placeholder={language[indexLanguage].DayOff.choose_supervisor} />)}
                                                        disabled={(crrData.status === 1 || crrData.status === -1) ? false : true}
                                                    />
                                                    {isValidateSupervisor && <div className='text-danger pt-2'>{language[indexLanguage].DayOff.information_blank}</div>}
                                                </div>
                                            </div>
                                    }
                                    
                                    <div className="row">
                                        <label htmlFor="example-datetime-local-input" className="col-md-3 col-form-label" style={{ textAlign: "left" }}>{language[indexLanguage].DayOff.reason}</label>
                                        <div className="col-md-9">
                                            <textarea
                                                className="form-control m-input"
                                                name="description"
                                                placeholder={language[indexLanguage].DayOff.description_detail}
                                                rows="5"
                                                value={crrData.description}
                                                onChange={(e) => setCrrData({ ...crrData, description: e.target.value })}
                                                disabled={(crrData.status === 1 || crrData.status === -1) ? false : true}
                                            ></textarea>
                                        </div>
                                    </div>
                                    {
                                        (crrData.status && crrData.status !== 1 && crrData.status !== -1) &&
                                        <div className="row pb-4 mt-3">
                                            <label htmlFor="example-datetime-local-input" className="col-md-3 pr-md-0 col-form-label" style={{ textAlign: "left" }}>{language[indexLanguage].DayOff.status}</label>
                                            <div className="col-md-9">
                                                <input type="text" className="form-control m-input" value={checkStatus(crrData.status)} disabled={true} disabled />
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            
                            <div className="form-group m-form__group row d-flex justify-content-center">
                                {(crrData.status === -1) &&
                                    <div className="px-0 mx-2" style={{ width: '90px' }}>
                                        <button type="submit" className="btn btn-accent" onClick={() => setStatus(2)} style={actionButtonStyle}>{language[indexLanguage].DayOff.send}</button>
                                    </div>
                                }
                                {crrData.status === -1 &&
                                    <>
                                        {
                                            !s_a_create &&
                                            <div className="col-md-2 px-0 mx-4">
                                                <button type="submit" className="btn btn-info w-auto" onClick={() => setStatus(1)} style={actionButtonStyle}>{language[indexLanguage].DayOff.save_draff}</button>
                                            </div>
                                        }
                                        <div className="col-md-2 px-0 mx-4">
                                            <button type="button" className="btn btn-danger" style={actionButtonStyle} onClick={() => cancelForm()}>{language[indexLanguage].DayOff.cancel}</button>
                                        </div>
                                    </>
                                }
                                {crrData.status === 2 &&
                                    <div className="px-0 mx-2" style={{ width: '90px' }}>
                                        <button type="button" className="btn btn-danger" style={actionButtonStyle} onClick={() => handleDelete()}>{language[indexLanguage].DayOff.delete}</button>
                                    </div>
                                }
                                {crrData.status === 1 &&
                                    <>
                                        <div className="col-md-2 px-0 mx-4">
                                            <button type="submit" className="btn btn-accent" onClick={() => setStatus(2)} style={actionButtonStyle}>{language[indexLanguage].DayOff.send}</button>
                                        </div>
                                        {
                                            !s_a_create &&
                                            <div className="col-md-2 px-0 mx-4">
                                                <button type="submit" className="btn btn-info w-auto" onClick={() => setStatus(1)} style={actionButtonStyle}>{language[indexLanguage].DayOff.save_draff}</button>
                                            </div>
                                        }
                                        <div className="col-md-2 px-0 mx-4">
                                            <button type="button" className="btn btn-danger" style={actionButtonStyle} onClick={() => handleDelete()}>{language[indexLanguage].DayOff.delete}</button>
                                        </div>
                                    </>
                                }
                                {(crrData.status !== 1 && crrData.status !== -1) &&
                                    <div className="px-0 mx-2" style={{ width: '90px' }}>
                                        <button type="button" className="btn btn-secondary" style={actionButtonStyle} onClick={() => onHide()}>{language[indexLanguage].DayOff.close}</button>
                                    </div>
                                }
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
