import * as FileSaver from 'file-saver';
import React from 'react';
import Button from 'react-bootstrap/Button';
import BeatLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/core";
import { useSelector } from 'react-redux';
import * as XLSX from 'xlsx';
import language from '../../../../../language/language';
import { useState } from 'react';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export const ExportCSV = ({ csvData, fileName, wsCols, fields, sheetName }) => {
    const indexLanguage = useSelector(state => state.changelanguage.indexLanguage);
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const [loading, setLoading] = useState(false);

    const exportToCSV = (csvData, fileName, wsCols, fields, sheetName) => {
        setLoading(true);
        const titles = {
            id_staff: 'ID',
            name: language[indexLanguage].Report.name,
            department: language[indexLanguage].Report.department,
            time_in: language[indexLanguage].Report.time_in,
            time_out: language[indexLanguage].Report.time_out,
            time_check_in: language[indexLanguage].Report.time_check_in,
            date: language[indexLanguage].Report.date,
            minute: language[indexLanguage].Report.minute,
            name_shift: language[indexLanguage].Report.shift,
            off: language[indexLanguage].Report.reason,
            total_time_ot: language[indexLanguage].Report.total_time_ot,
            day: language[indexLanguage].Report.day,
            number_in_out: language[indexLanguage].Report.number_in_out,
            time: language[indexLanguage].Report.time,
            in_soon: language[indexLanguage].Report.in_soon,
            in_late: language[indexLanguage].Report.in_late,
            out_soon: language[indexLanguage].Report.out_soon,
            out_late: language[indexLanguage].Report.out_late,
            work: language[indexLanguage].Report.work,
            note: language[indexLanguage].Report.note,
            work_day_off: language[indexLanguage].Report.day_off,
            work_business: language[indexLanguage].Report.business,
            total_work: language[indexLanguage].Report.total_work,
            from_date: language[indexLanguage].Report.from_date,
            to_date: language[indexLanguage].Report.to_date,
            reason: language[indexLanguage].Report.reason_off,
            business_place: language[indexLanguage].Report.business_place,
            business_content: language[indexLanguage].Report.business_content,
            place: language[indexLanguage].Report.place,
            shift: language[indexLanguage].Report.shift,
            before_compliance: language[indexLanguage].Report.status_before,
            after_compliance: language[indexLanguage].Report.status_after,
            status_confirm: language[indexLanguage].Report.status_confirm,
            camera_in: "Vị trí vào",
            camera_out: "Vị trí ra",
            username: "Username",
            confirm_person: "CBQL",
            confirm_time: "Thời gian xác nhận",
            '1': '1',
            '2': '2',
            '3': '3',
            '4': '4',
            '5': '5',
            '6': '6',
            '7': '7',
            '8': '8',
            '9': '9',
            '10': '10',
            '11': '11',
            '12': '12',
            '13': '13',
            '14': '14',
            '15': '15',
            '16': '16',
            '17': '17',
            '18': '18',
            '19': '19',
            '20': '20',
            '21': '21',
            '22': '22',
            '23': '23',
            '24': '24',
            '25': '25',
            '26': '26',
            '27': '27',
            '28': '28',
            '29': '29',
            '30': '30',
            '31': '31',
        }
        setTimeout(() => {
            if (csvData) {
                const index = csvData.length;
                let ws = [];
                let sheet_name = [];
                let sheets = [];
                for (let i = 0; i < index; i++) {
                    ws[i] = createWs(csvData[i], fields, titles, wsCols)
                    sheet_name.push(sheetName[i]);
                    sheets.push([sheetName[i], ws[i]])
                }

                const entries = new Map(sheets);
                const object = Object.fromEntries(entries);
                const wb = { Sheets: object, SheetNames: sheet_name };
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array', compression: true });
                const data = new Blob([excelBuffer], { type: fileType });
                FileSaver.saveAs(data, fileName + fileExtension);
            }
            setLoading(false);
        }, 1);
    }
    // Create a worksheet, set the table header during the creation process
    const createWs = (data, fields, titles, wsCols) => {
        if (data !== null) {
            data.map((item => {
                delete item.text_time_in;
                delete item.text_time_out;
                if (typeof item.minute != "undefined")
                    item.minute = parseFloat(item.minute)
                return item
            }))
        }
        const ws = XLSX.utils.json_to_sheet(
            data,
            {
                header: fields,
            }
        )
        const range = XLSX.utils.decode_range(ws['!ref'])

        for (let c = range.s.c; c <= range.e.c; c++) {
            const header = XLSX.utils.encode_col(c) + '1'
            ws[header].v = titles[ws[header].v]
        }
        ws['!cols'] = wsCols;
        return ws
    }

    return (
        <div className='text-right py-2'>
            <Button disabled={loading} style={{ width: 120, position: 'absolute', top: -45, right: 0 }} variant="success" onClick={(e) => exportToCSV(csvData, fileName, wsCols, fields, sheetName)} >
                {
                    loading ? <BeatLoader color={'#fff'} loading={true} css={override} size={10} />
                        :
                        language[indexLanguage].textButton.export_report
                }
            </Button>
        </div>
    )
}