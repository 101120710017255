import Autocomplete from '@material-ui/lab/Autocomplete';
import Axios from 'axios';
import Highcharts from 'highcharts';
import TextField from '@material-ui/core/TextField';
import HighchartsReact from 'highcharts-react-official';
import LoadingOverlay from 'react-loading-overlay';
import moment, { lang } from 'moment';
import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import language from '../../../language/language';
import { CHART } from '../../chart';
import ReactGA from 'react-ga';
import { getListDepartmentSupervisorSelectAll } from '../../../api';
import { formatTitleOption, formatTreeViewData } from '../CommonFunction';
import { makeStyles } from "@material-ui/core/styles";
import { Button } from 'react-bootstrap';
import TreeView from '../TreeView';
import Header from '../../layouts/Header';

const today = moment(new Date()).format('YYYY-MM-DD') + ' 23:59:59';
const useStyles = makeStyles({
    paper: {
        whiteSpace: "nowrap"
    }
});
function KPIScreen() {

    const box_engine_cf = useSelector(state => state.boxai.box_engine_cf);
    const token = useSelector(state => state.isLogin.access_token);
    const type = localStorage.getItem("type");
    const id_staff = localStorage.getItem('id_user');

    const indexLanguage = useSelector(state => state.changelanguage.indexLanguage);
    const list_company = useSelector(state => state.boxai.list_company);
    const dataRole = useSelector(state => state.role.role);
    const [optionsDepartment, setOptionsDepartment] = useState([]);
    const [optionsStaff, setOptionsStaff] = useState([]);
    const [valueDepartment, setValueDepartment] = useState({});
    const [valueStaff, setValueStaff] = useState({});
    const [selectDepartment, setSelectDepartment] = useState(false);

    let xAxis = [];
    for (let i = 0; i <= 12; i++) {
        let month = ''
        switch (i + 1) {
            case 1:
                month = language[indexLanguage].Month.january
                break;
            case 2:
                month = language[indexLanguage].Month.february
                break;
            case 3:
                month = language[indexLanguage].Month.march
                break;
            case 4:
                month = language[indexLanguage].Month.april
                break;
            case 5:
                month = language[indexLanguage].Month.may
                break;
            case 6:
                month = language[indexLanguage].Month.june
                break;
            case 7:
                month = language[indexLanguage].Month.july
                break;
            case 8:
                month = language[indexLanguage].Month.august
                break;
            case 9:
                month = language[indexLanguage].Month.september
                break;
            case 10:
                month = language[indexLanguage].Month.october
                break;
            case 11:
                month = language[indexLanguage].Month.november
                break;
            case 12:
                month = language[indexLanguage].Month.december
                break;

            default:
                break;
        }
        xAxis.push(month);
    }

    const [view, setView] = useState(2);
    const [col, setCol] = useState('col-xl-6');
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')

    useEffect(() => {
        if (parseInt(moment().format('MM')) <= 6) {
            setStartDate(new Date(moment().format('YYYY'), 0, 1))
            setEndDate(new Date(moment().format('YYYY'), 6, 0))
        } else {
            setStartDate(new Date(moment().format('YYYY'), 6, 1))
            setEndDate(new Date(moment().format('YYYY'), 0, 0))
        }
    }, [])

    const [chartOptions_1, setChartOptions_1] = useState(Object.assign({
        title: {
            text: language[indexLanguage].KPI.chart_api_kpi,
        },
        chart: {
            type: 'pie'
        },
        series: [{
            name: "KPI",
            colorByPoint: true,
            data: [{
                name: language[indexLanguage].KPI.compliance,
                y: 99,
                sliced: true,
                selected: true
            }, {
                name: language[indexLanguage].KPI.not_compliance,
                y: 1
            },]
        }]
    }));

    const [chartOptions_2, setChartOptions_2] = useState(Object.assign({
        chart: {
            type: 'pie',
        },
        title: {
            text: language[indexLanguage].KPI.chart_api_kpi,
        },
        series: [{
            colorByPoint: true,
            data: [{
                name: language[indexLanguage].KPI.compliance,
                y: 99,
                sliced: true,
                selected: true
            }, {
                name: language[indexLanguage].KPI.not_compliance,
                y: 1
            },]
        }]
    }));

    const [chartOptions_4, setChartOptions_4] = useState(Object.assign({
        chart: {
            type: 'column'
        },
        title: {
            text: '',
        },
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: true,
                    color: 'red',
                    formatter: function () {
                        return (this.y !== 0) ? this.y : "";
                    }
                }
            }
        },
        xAxis: {
            categories: xAxis,
        },
        yAxis: {
            min: 0,
            title: {
                text: language[indexLanguage].KPI.percent_kpi,
            },
            allowDecimals: false,
        },
    }));
    const [chartOptions_3, setChartOptions_3] = useState(Object.assign({
        chart: {
            type: 'column'
        },
        title: {
            text: '',
        },
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: true,
                    color: 'red',
                    formatter: function () {
                        return (this.y !== 0) ? this.y : "";
                    }
                }
            }
        },
        xAxis: {
            categories: xAxis,
        },
        yAxis: {
            title: {
                text: language[indexLanguage].KPI.percent_kpi,
            },
            allowDecimals: false,
        },
    }));

    const [isActive_1, setIsActive_1] = useState(false);
    const [isActive_2, setIsActive_2] = useState(false);
    const [isActive_3, setIsActive_3] = useState(false);
    const [isActive_4, setIsActive_4] = useState(false);

    const default_department = {
        title: language[indexLanguage].DashBoard.all_department,
        value: -1,
        code: ""
    }

    const getDepartment = async () => {
        if (typeof box_engine_cf.crrApiSocket === "undefined") return;
        if (dataRole && dataRole.indexOf(type + '/dashboard:view_all') !== -1) {
            fetch(`${box_engine_cf.crrApiSocket}/api/department/${box_engine_cf.idCompany}/get_all`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
            }).then((response) => {
                return response.json()
            }).then((data) => {
                if (data.status === 10000) {
                    var optionDepartment = []
                    for (let index = 0; index < data.data.length; index++) {
                        optionDepartment.push({ 'value': data.data[index].id, 'title': data.data[index].name, 'code': data.data[index].code })
                    }
                    setValueDepartment(optionDepartment.length > 1 ? default_department : optionDepartment[0])
                    setOptionsDepartment(optionDepartment)
                } else {

                }
            }).catch((error) => {
                if (error) {
                }
            })
        } else {
            const result = await getListDepartmentSupervisorSelectAll(box_engine_cf.crrApiSocket, id_staff, token);
            const data = result.map(map => ({
                title: map.title,
                value: map.value,
                code: map.code
            }))
            if (data.length <= 2 && data.length > 0) {
                data.shift();
            }
            setValueDepartment(data[0])
            setOptionsDepartment(data)
        }
    }

    const getDataHighChart_2 = async (date) => {
        if (typeof box_engine_cf.crrApiSocket === "undefined") return;
        setIsActive_2(true);
        setIsActive_4(true);
        const dataPost = {
            "start_date": moment(startDate).format('YYYY-MM-DD'),
            "end_date": moment(endDate).format("YYYY-MM-DD"),
            "department_ids": [valueDepartment.value],
        }
        if (valueDepartment.value == -1)
            dataPost.id_company = box_engine_cf.idCompany;
        const result = await Axios({
            method: 'POST',
            url: `${box_engine_cf.crrApiSocket}/api/compliance_report/kpi`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            data: dataPost,
        });
        if (result.data.status === 10000) {
            setIsActive_2(false);
            setIsActive_4(false);
            var data = result.data.data;
            var results = Object.keys(data).map(key => ({ key, compliance: data[key].number_of_compliance, not_compliance: data[key].number_of_not_compliance }))
            if (results.length > 0) {
                setChartOptions_4({
                    ...chartOptions_4,
                    xAxis: {
                        categories: results.map(item => {
                            return item.key
                        })
                    },

                    series: {
                        name: "Tuân thủ (%)",
                        data: results.map(item => {
                            return item.compliance
                        })
                    }
                });
                setChartOptions_2({
                    ...chartOptions_2,
                    series: [{
                        name: "KPI",
                        colorByPoint: true,
                        data: [{
                            name: language[indexLanguage].KPI.compliance,
                            y: result.data.total.total_number_of_compliance,
                            sliced: true,
                            selected: true
                        }, {
                            name: language[indexLanguage].KPI.not_compliance,
                            y: result.data.total.total_number_of_not_compliance,
                        },]
                    }]
                })
            }
        }
    }
    const getDataHighChart_3 = async () => {
        if (typeof box_engine_cf.crrApiSocket === "undefined") return;
        setIsActive_1(true)
        setIsActive_3(true);
        const dataPost = {
            "start_date": moment(startDate).format('YYYY-MM-DD'),
            "end_date": moment(endDate).format('YYYY-MM-DD'),
            "staff_ids": [valueStaff.value],
        };
        const result = await Axios({
            method: 'POST',
            url: `${box_engine_cf.crrApiSocket}/api/compliance_report/kpi`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            data: dataPost,
        });
        if (result.data.status === 10000) {
            setIsActive_1(false);
            setIsActive_3(false);
            var data = result.data.data;
            var results = Object.keys(data).map(key => ({ key, compliance: data[key].number_of_compliance, not_compliance: data[key].number_of_not_compliance }))
            if (results.length > 0) {
                setChartOptions_3({
                    ...chartOptions_3,
                    xAxis: {
                        categories: results.map(item => {
                            return item.key
                        })
                    },

                    series: {
                        name: "Tuân thủ (%)",
                        data: results.map(item => {
                            return item.compliance
                        })
                    }
                });
                setChartOptions_1({
                    ...chartOptions_1,
                    series: [{
                        name: "KPI",
                        colorByPoint: true,
                        data: [{
                            name: language[indexLanguage].KPI.compliance,
                            y: result.data.total.total_number_of_compliance,
                            sliced: true,
                            selected: true
                        }, {
                            name: language[indexLanguage].KPI.not_compliance,
                            y: result.data.total.total_number_of_not_compliance,
                        },]
                    }]
                })
            }
        }
    }

    useEffect(() => {
        const fetchData = () => {
            if (Highcharts.chart[0]) {
                Highcharts.charts[0].reflow();
                Highcharts.charts[1].reflow();
                Highcharts.charts[2].reflow();
                Highcharts.charts[3].reflow();
            }
        }
        fetchData();
    }, [view]);

    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        const fetchData = () => {
            if (list_company.length > 1) {
                let array_id_company = [];
                list_company.map(map => {
                    array_id_company.push(map.id_company);
                })
            }
        }
        fetchData();
    }, [list_company]);
    const dataGetObjStaff = (resetStaff) => {
        if (typeof box_engine_cf.crrApiSocket === "undefined") return;
        const dataPost = {
            'id_company': box_engine_cf.idCompany,
            'id_department': -1
        }
        if (dataRole && dataRole.indexOf(type + '/dashboard:view_all') == -1) {
            var dep_ids = [];
            optionsDepartment.forEach(element => {
                dep_ids.push(element.value)
            });
            dataPost.dep_ids = dep_ids;
        }
        fetch(`${box_engine_cf.crrApiSocket}/api/staff/get_by_department`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify(dataPost)
        }).then((response) => {
            return response.json()
        }).then((data) => {
            var dataObjStaff = []
            if (data.status === 10000) {
                var dataObjStaff = []
                for (let index = 0; index < data.data.length; index++) {
                    dataObjStaff.push({ 'value': data.data[index].id, 'title': data.data[index].name + " - " + data.data[index].id_staff })
                }
                setValueStaff({
                    value: dataObjStaff[0].value,
                    title: dataObjStaff[0].title
                })
                setOptionsStaff(dataObjStaff)
            } else {
            }
        }).catch((error) => {
            if (error) {
            }
        })
    }
    const changeHandleDepartment = (e) => {
        setValueDepartment(e)
    }
    useEffect(() => {
        getDataHighChart_2();
    }, [box_engine_cf, valueDepartment, startDate, endDate]);
    useEffect(() => {
        getDataHighChart_3();
    }, [box_engine_cf, valueStaff, startDate, endDate]);
    useEffect(() => {
        getDepartment()
    }, [box_engine_cf]);
    useEffect(() => {
        dataGetObjStaff()
    }, [box_engine_cf, optionsDepartment]);

    const changeHandleStaff = (value, e) => {
        setValueStaff(e)
    }
    const classes = useStyles();
    return (
        <>
        <Header />
        <div className={"m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body "}>
        <div className="m-grid__item m-grid__item--fluid m-wrapper dashboard_wrapper">
            <div className="m-content mt-3-phone pd_phone_0 pl-0 pr-0">
                <div className="row m_phone_0 mr-0 ml-0 d-flex">
                    <div className={`col-xl-12 col-md-12 pr-2 pl-2 pd_phone_0`}>
                        <div className="m-portlet mb-2 pr-5">
                            <div className="m-portlet__head d-md-flex justify-content-md-end">
                                {/* <div className='d-flex align-items-center' style={{ fontSize: '18px' }}>
                                    {
                                        language[indexLanguage].DashBoard.dash_board_sa
                                    }
                                </div> */}
                                <div className="m-portlet__head-tools select_date_month body_dashboard_portlet d-md-flex justify-content-md-end">
                                    <div className='mr-2'>{language[indexLanguage].Shift.from}: </div>
                                    <ReactDatePicker
                                        className="form-control m-input m_datetimepicker_6"
                                        selected={startDate}
                                        onChange={date => {
                                            console.log('datee start', moment(date).format('DD-MM-YYYY'))
                                            setStartDate(date)
                                        }}
                                        showMonthYearPicker
                                        dateFormat="MM-yyyy"
                                    // customInput={<ExampleCustomInput />}
                                    />
                                </div>
                                <div className="m-portlet__head-tools select_date_month body_dashboard_portlet d-md-flex justify-content-md-end">
                                    <div className='mr-2 ml-3'>{language[indexLanguage].Shift.to}: </div>
                                    <ReactDatePicker
                                        className="form-control m-input m_datetimepicker_6"
                                        selected={endDate}
                                        onChange={date => {
                                            setEndDate(date)
                                        }}
                                        showMonthYearPicker
                                        dateFormat="MM-yyyy"
                                    // customInput={<ExampleCustomInput />}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`col-xl-12 col-md-12 pr-2 pl-2 pd_phone_0`}>
                        <div className="m-portlet mb-2">
                            <div className="m-portlet__head d-md-flex justify-content-md-between">
                                <div className='d-flex align-items-center' style={{ fontSize: '18px' }}>
                                    {language[indexLanguage].KPI.department_kpi}
                                </div>
                                <div className="m-portlet__head-tools col-xl-3">
                                    {/* <Autocomplete
                                        options={optionsDepartment}
                                        className="m-input col-xl-12"
                                        getOptionLabel={option => option.title}
                                        value={valueDepartment}
                                        onChange={changeHandleDepartment}
                                        disableClearable
                                        renderOption={(props, option, state) => (
                                            <div {...props}>
                                                {formatTitleOption(props)}
                                            </div>
                                        )}
                                        classes={{ paper: classes.paper }}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                className="btn btn-secondary m-btn m-btn--icon col-md-12"
                                                placeholder="Department"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                    /> */}
                                    <Button onClick={() => {
                                        setSelectDepartment(prev => !prev)
                                    }}
                                        className="btn btn-secondary btn-block m-btn m-btn--icon"
                                    >
                                        <span style={{ color: "#000000" }}>
                                            {valueDepartment.value == -1 ? "Tất cả phòng ban" : valueDepartment.title}
                                        </span>
                                    </Button>
                                </div>
                            </div>
                            <div className='row'>
                                <div className={selectDepartment ? 'm-portlet__body dashboard_spinner col-xl-4' : "m-portlet__body dashboard_spinner col-xl-8"}>
                                    <LoadingOverlay
                                        active={isActive_4}
                                        spinner
                                        styles={{
                                            overlay: (base) => ({
                                                ...base,
                                                background: 'rgba(202, 202, 202, 0)',
                                            })
                                        }}
                                    >
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={chartOptions_4}
                                        />
                                    </LoadingOverlay>
                                </div>
                                <div className="m-portlet__body dashboard_spinner col-xl-4">
                                    <LoadingOverlay
                                        active={isActive_2}
                                        spinner
                                        styles={{
                                            overlay: (base) => ({
                                                ...base,
                                                background: 'rgba(202, 202, 202, 0)',
                                            })
                                        }}
                                    >
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={chartOptions_2}
                                        />
                                    </LoadingOverlay>
                                </div>
                                {
                                    selectDepartment && (
                                        <div className='m-portlet__body dashboard_spinner col-xl-4'>
                                            <div style={{ maxHeight: "400px", overflow: "auto", whiteSpace: "nowrap" }}>
                                                <TreeView
                                                    item={formatTreeViewData(optionsDepartment)}
                                                    getList={(data) => {
                                                        console.log(data)
                                                        changeHandleDepartment(data);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className={`col-xl-12 col-md-12 pr-2 pl-2 pd_phone_0`}>
                        <div className="m-portlet mb-2">
                            <div className="m-portlet__head d-md-flex justify-content-md-between">
                                <div className='d-flex align-items-center' style={{ fontSize: '18px' }}>
                                    {language[indexLanguage].KPI.person_kpi}
                                </div>
                                <div className="m-portlet__head-tools col-xl-2">
                                    <Autocomplete
                                        options={optionsStaff}
                                        className="m-input col-xl-12"
                                        getOptionLabel={option => option.title}
                                        value={valueStaff}
                                        onChange={changeHandleStaff}
                                        disableClearable
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                placeholder="Staff"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </div>
                            </div>

                            <div className='row' >
                                <div className="m-portlet__body dashboard_spinner flex col-xl-8">
                                    <div className='col-xl-12'>
                                        <LoadingOverlay
                                            active={isActive_3}
                                            spinner
                                            styles={{
                                                overlay: (base) => ({
                                                    ...base,
                                                    background: 'rgba(202, 202, 202, 0)',
                                                })
                                            }}
                                        >
                                            <HighchartsReact
                                                highcharts={Highcharts}
                                                options={chartOptions_3}
                                            />
                                        </LoadingOverlay>
                                    </div>

                                </div>
                                <div className="m-portlet__body dashboard_spinner flex col-xl-4">
                                    <div className='col-xl-12'>
                                        <LoadingOverlay
                                            active={isActive_1}
                                            spinner
                                            styles={{
                                                overlay: (base) => ({
                                                    ...base,
                                                    background: 'rgba(202, 202, 202, 0)',
                                                })
                                            }}
                                        >
                                            <HighchartsReact
                                                highcharts={Highcharts}
                                                options={chartOptions_1}
                                            />

                                        </LoadingOverlay>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>

        </>
    );
}

export default KPIScreen;
