export const formatTitleOption = (props) => {
	var prefix = "";
	if (typeof props.code != "undefined") {
		var total_blank = props.code.split(" ").length - 1;
		if (total_blank == 1)
			prefix = prefix + "┗━";
		else
			for (const x of Array(total_blank).keys()) {
				prefix = x == total_blank - 1 ? prefix + "┗━" : prefix + "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0";
			}
	}
	var title = prefix + " " + (typeof props.title != "undefined" ? props.title : typeof props.label != "undefined" ? props.label : props.name);
	return title;
}
export const getCameraArray = (cameras) => {
	var dict = [];
	cameras.forEach(e => {
		dict[e.id_box_engine] = e.alias_box_engine;
	});
	return dict;
}
export const formatUsernameFromEmail = (email) => {
	if (email == "" || email == null)
		return "";
	var temp = email.split("@");
	return temp[0];
}
export const formatTreeViewData = (data) => {
	var dict = [];
	data.forEach(e => {
		if (typeof e.code != "undefined") {
			var code = e.code.split(" ");
			code.pop();
			code = code.join(" ");
			code = code == "" ? "root" : code;
			if (typeof dict[code] == "undefined")
				dict[code] = [e];
			else
				dict[code].push(e);
		}
		if (typeof e.title === 'undefined') {
			e.title = e.name
		}
	});
	var maxLevel = 0;
	for (const [key, value] of Object.entries(dict)) {
		var code = key.split(" ");
		maxLevel = code.length > maxLevel ? code.length : maxLevel;
	}
	var minLevel = maxLevel;
	for (const [key, value] of Object.entries(dict)) {
		var code = key.split(" ");
		minLevel = code.length < minLevel ? code.length : minLevel;
	}
	for (var i = maxLevel; i > 0; i--) {
		for (const [key, value] of Object.entries(dict)) {
			var code = key.split(" ");
			if (code.length == i) {
				for (const [k, v] of Object.entries(dict)) {
					var c = k.split(" ");
					if (c.length == i - 1) {
						v.forEach(e => {
							if (key == e.code)
								e.children = value;
						});
					}
				}
			}
		}
	}
	const departments = [];
	var all_department = false;
	data.forEach(e => {
		if (typeof e.code == "undefined") {
			if (typeof e.value != "undefined")
				departments.push({ value: e.value, title: e.title, name: typeof e.name != "undefined" ? e.name : e.title, code: "" });
			else
				departments.push({ id: e.id, title: e.title, name: typeof e.name != "undefined" ? e.name : e.title, code: "" });
			all_department = true;
		}
	});
	if (!all_department)
		departments.push({ value: -1, title: "Tất cả phòng ban", code: "" });
	if (typeof dict['root'] != "undefined") {
		dict['root'].forEach(e => {
			if (typeof dict[e.code] != "undefined")
				e.children = dict[e.code];
			departments.push(e);
		});
	} else {
		for (const [key, value] of Object.entries(dict)) {
			var code = key.split(" ");
			if (code.length == minLevel) {
				for (var j = 0; j < value.length; j++)
					departments.push(value[j]);

			}
		}
	}
	return departments;
}