import subDays from "date-fns/subDays";
import $ from 'jquery';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import toastr from 'reactjs-toastr';
import ReactGA from 'react-ga';
import "react-datepicker/dist/react-datepicker.css";
import swal from 'sweetalert';
import language from '../../../../language/language';
import Store from '../../../../store';
import { connect } from 'react-redux'

const animatedComponents = makeAnimated();

function sortData(a, b) {
    if (a.label > b.label) {
        return 1
    } else {
        if (a.label < b.label) {
            return -1;
        }
        else {
            return 0
        }
    }
}

class ModalDoorOpen extends Component {

    constructor(props) {
        super(props);
        this.state = {
            language: Store.getState().changelanguage.language,
            box_engine_cf: Store.getState().boxai.box_engine_cf,
            id_door_calendar: 'null',
            optionDoor: [],
            DayOfWeek: [],
            listStaff: [],
            listGroup: [],
            valueGroups: [],
            valueStaffs: [],
            listDepartment: [],
            listLevel: [],
            DataDoorOpen: {
                "department_levels": [],
                "groups": [],
                "staffs": []
            },
            HOST: '',
            valueDoor: '0',
            display: true,
            crrDoorOpen: {
                id_door: "",
                day_of_week: "",
                time_in: Moment(new Date()).format("HH:mm"),
                day_in: Moment(new Date()).format("YYYY-MM-DD"),
                time_out: Moment(new Date()).format("HH:mm"),
                day_out: Moment(new Date()).format("YYYY-MM-DD"),
            },
            valueType: "",
            displayAdd: false,
            selectAll: false,
        }
        Store.subscribe(() => {
            this.setState({
                language: Store.getState().changelanguage.language,
                box_engine_cf: Store.getState().boxai.box_engine_cf,
                valueType: ""
            });
        });
    }

    componentWillMount() {
        const Type = [
            {
                value: -1, label: language[this.props.indexLanguage].Text_Notifi.all
            },
            {
                value: 0, label: language[this.props.indexLanguage].Text_Notifi.self_setup
            }
        ]
        this.setState({
            listType: Type,

        })
    }

    handleChangeType(valueType) {
        if (valueType.value === 0 && this.state.DataDoorOpen.department_levels.find(x => x.department.id === -1)) {
            var DataDoorOpen = this.state.DataDoorOpen;
            DataDoorOpen.department_levels = [];
            this.setState({
                DataDoorOpen:DataDoorOpen,
                selectAll: false
            });
        } else if (valueType.value === -1) {
            this.setState({
                selectAll: true
            });
        } else {
            this.setState({
                selectAll: false
            });
        }
        this.setState({
            valueType: valueType
        })
    }

    getDoorByID = (data) => {
        if (data.HOST == undefined) return;
        if (data.id_door !== "") {
            fetch(`${data.HOST}/api/door/get_by_id/${data.id_door}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': Store.getState().isLogin.access_token
                },
            }).then((response) => {
                return response.json()
            }).then((data) => {
                if (data.status === 10000) {
                    this.setState({
                        display: false,
                        valueDoor: { 'value': data.data.id, 'label': data.data.name }
                    });
                } else {
                    this.setState({
                        valueDoor: '0'
                    });
                }
            })
        } else {
            this.setState({
                valueDoor: '0'
            });
        }
    }

    getDataDoorOpen = (data,id) => {
        if (data.HOST == undefined) return;
        if (data.id !== undefined) {
            fetch(`${data.HOST}/api/door_open/get_by_id_door_calendar/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': Store.getState().isLogin.access_token
                },
            }).then((response) => {
                return response.json()
            }).then((data) => {
                if (data.status === 10000) {
                    var valueGroups = [];
                    for (let index = 0; index < data.data.groups.length; index++) {
                        valueGroups.push({ 'value': data.data.groups[index].id, 'label': data.data.groups[index].name })
                    }

                    var valueStaffs = [];
                    for (let index = 0; index < data.data.staffs.length; index++) {
                        valueStaffs.push({ 'value': data.data.staffs[index].id, 'label': data.data.staffs[index].name+' ('+data.data.staffs[index].id_staff+')' })
                    }

                    this.setState({
                        DataDoorOpen: data.data,
                        valueGroups: valueGroups,
                        valueStaffs: valueStaffs,
                        valueType: (data.data.department_levels.length > 0 && data.data.department_levels.find((x) => x.department.id === -1)) ? {value: -1, label: language[this.props.indexLanguage].Text_Notifi.all} : (data.data.department_levels.length === 0 && data.data.groups.length === 0 && data.data.staffs.length === 0) ? "" : {value: 0, label: language[this.props.indexLanguage].Text_Notifi.self_setup} 
                    })
                }
            })
        }
    }

    getStaff = (data) => {
        if (data.HOST == undefined) return;
        fetch(`${data.HOST}/api/staff/${this.state.box_engine_cf.idCompany}/get_all`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                var dataStaff = [];
                for (let index = 0; index < data.data.length; index++) {
                    dataStaff.push({ 'value': data.data[index].id, 'label': data.data[index].name + ' ('+data.data[index].id_staff+')' })
                }
                var dataOptionSort = dataStaff.sort(sortData);
                this.setState({
                    listStaff: dataOptionSort,
                });
            }
        })
    }

    getGroup = (data) => {
        if (data.HOST == undefined) return;
        fetch(`${data.HOST}/api/group/${this.state.box_engine_cf.idCompany}/get_all`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                var dataGroup = [];
                for (let index = 0; index < data.data.length; index++) {
                    dataGroup.push({ 'value': data.data[index].id, 'label': data.data[index].name })
                }
                var dataOptionSort = dataGroup.sort(sortData);
                this.setState({
                    listGroup: dataOptionSort,
                });
            }
        })
    }

    getDepartment = (data) => {
        if (data.HOST == undefined) return;
        fetch(`${data.HOST}/api/department/${this.state.box_engine_cf.idCompany}/get_all`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                var listDepartment = [];
                for (let index = 0; index < data.data.length; index++) {
                    listDepartment.push({ 'value': data.data[index].id, 'label': data.data[index].name })
                }
                var dataOptionSort = listDepartment.sort(sortData);
                this.setState({
                    listDepartment: dataOptionSort
                });
            }
        })
    }

    getLevel = (data) => {
        if (data.HOST == undefined) return;
        fetch(`${data.HOST}/api/level/${this.state.box_engine_cf.idCompany}/get_all`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                var listLevel = [];
                for (let index = 0; index < data.data.length; index++) {
                    listLevel.push({ 'value': data.data[index].id, 'label': data.data[index].name })
                }
                var dataOptionSort = listLevel.sort(sortData);
                this.setState({
                    listLevel: [{ 'value': -1, 'label': language[this.props.indexLanguage].Text_Notifi.all_level }, ...dataOptionSort]
                });
            }
        })
    }

    updateDoorOpen = async (dataDoorOpen, event) => {
        //Insert
        var form = $("#formUpdateDoorOpen")
        if (form[0].checkValidity() === false) {
            event.preventDefault()
            event.stopPropagation()
            form.addClass('was-validated')
        } else {
            if (dataDoorOpen['day_in'] <= dataDoorOpen['day_out']) {
                if (dataDoorOpen.id == undefined) {
                    let data = await fetch(`${dataDoorOpen.HOST}/api/door_calendar/insert`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': Store.getState().isLogin.access_token
                        },
                        body: JSON.stringify(dataDoorOpen)
                    }).then((response) => {
                        return response.json()
                    });
                    if (data.status === 10000) {
                        ReactGA.event({
                            category: 'Quyen mo cua',
                            action: 'Them quyen mo cua',
                          });
                        swal(language[this.props.indexLanguage].Text_Notifi.success, language[this.props.indexLanguage].Text_Notifi.add_success, "success", {
                            buttons: false,
                            timer: 1500,
                        });
                        var crrDoorOpen = this.state.crrDoorOpen;
                        crrDoorOpen['id'] = data.data
                        this.setState({
                            id_door_calendar: data.data,
                            crrDoorOpen: crrDoorOpen,
                            display: false,
                        });
                    }
                    else {
                        swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].Text_Notifi.add_false, "error");
                    }
                } else {
                    let data = await fetch(`${dataDoorOpen.HOST}/api/door_calendar/update`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': Store.getState().isLogin.access_token
                        },
                        body: JSON.stringify(dataDoorOpen)
                    }).then((response) => {
                        return response.json()
                    });
                    if (data.status === 10000) {
                        ReactGA.event({
                            category: 'Quyen mo cua',
                            action: 'Sua quyen mo cua',
                          });
                        swal(language[this.props.indexLanguage].Text_Notifi.success, language[this.props.indexLanguage].Text_Notifi.edit_success, "success", {
                            buttons: false,
                            timer: 1500,
                        });
                    }
                    else {
                        swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].Text_Notifi.edit_false, "error");
                    }
                }
            } else {
                swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].textTable.end_date +' '+ language[this.props.indexLanguage].Text_Notifi.notifi_time_2 +' '+language[this.props.indexLanguage].textTable.start_date, "warning");
            }
        }
    }

    click_handle_setup = async () => {
        const arr_staff = []
        const arr_group = []
        const arr_dep_lev = []
        this.state.valueStaffs !== null && this.state.valueStaffs.map((x) => {
            arr_staff.push({"0": [1,`${x.value}`]})
        })
        this.state.valueGroups !== null && this.state.valueGroups.map((x) => {
            arr_group.push({"2": [2,`${x.value}`]})
        })
        this.state.DataDoorOpen !== null && this.state.DataDoorOpen.department_levels.map((x) => {
            arr_dep_lev.push({"3": [3,x.department.id+'_'+x.level.id]})
        })
        let result_arr 
        if (this.state.selectAll) {
            result_arr = [{"3": [3,"-1_-1"]}]
        } else {
            result_arr = arr_staff.concat(arr_group,arr_dep_lev)
        }
     
        await fetch(`${this.state.HOST}/api/door_open/insert`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.  access_token
            },
            body: JSON.stringify({
                "id_door_calendar": this.state.id_door_calendar,
                "arr_obj": result_arr
            })
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                swal(language[this.props.indexLanguage].Text_Notifi.success, language[this.props.indexLanguage].Text_Notifi.setup_success, "success", {
                    buttons: false,
                    timer: 1500,
                });
                console.log(this.state.DataDoorOpen.department_levels)
                this.getDataDoorOpen(this.state.crrDoorOpen,this.state.id_door_calendar)
            }
            else {
                swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].Text_Notifi.add_false, "warning");
            }
        }).catch((error) => {
            if (error) {
                swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].Text_Notifi.add_false, "warning");
            }
        });
    }

    


    DoorOpenHandle(e) {
        var DoorOpenHand = this.state.crrDoorOpen;
        DoorOpenHand[e.target.name] = e.target.value;
        this.setState({ crrDoorOpen: DoorOpenHand });
    }

    handleChangeHourStartTime = startTime => {
        var DoorOpenHand = this.state.crrDoorOpen;
        if (Moment(startTime).format("HH:mm") < DoorOpenHand['time_out']) {
            DoorOpenHand['time_in'] = Moment(startTime).format("HH:mm");
            this.setState({
                crrDoorOpen: DoorOpenHand
            });
        } else {
            swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].textTable.time_out +' '+ language[this.props.indexLanguage].Text_Notifi.notifi_time +' '+language[this.props.indexLanguage].textTable.time_in, "warning");
        }
        this.setState({
            crrDoorOpen: DoorOpenHand
        });
    }

    handleChangeHourEndTime = endTime => {
        var DoorOpenHand = this.state.crrDoorOpen;
        if (Moment(endTime).format("HH:mm") > DoorOpenHand['time_in']) {
            DoorOpenHand['time_out'] = Moment(endTime).format("HH:mm");
            this.setState({
                crrDoorOpen: DoorOpenHand
            });
        } else {
            swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].textTable.time_out +' '+ language[this.props.indexLanguage].Text_Notifi.notifi_time +' '+language[this.props.indexLanguage].textTable.time_in, "warning");
        }
    }

    handleChangeDayStartTime = startDay => {
        var DoorOpenHand = this.state.crrDoorOpen;
        DoorOpenHand['day_in'] = startDay ? Moment(startDay).format("YYYY-MM-DD") : '';
        DoorOpenHand['day_out'] = startDay ? Moment(startDay).format("YYYY-MM-DD") : '';
        this.setState({
            crrDoorOpen: DoorOpenHand
        });

    }

    handleChangeDayEndTime = endDay => {
        var DoorOpenHand = this.state.crrDoorOpen;
        DoorOpenHand['day_out'] = endDay ? Moment(endDay).format("YYYY-MM-DD") : '';
        this.setState({
            crrDoorOpen: DoorOpenHand
        });
    }

    handleChangeDoor(value) {
        var DoorOpenHand = this.state.crrDoorOpen;
        DoorOpenHand['id_door'] = value.value
        this.setState({
            valueDoor: value,
            crrDoorOpen: DoorOpenHand
        })
    }

    handleChangeWeek(event) {
        var DoorOpenHand = this.state.crrDoorOpen;
        const checkedArr = [];
        let value;
        let checked_count = 0;
        if (event.target.type !== 'checkbox') {
            value = event.target.value;
        } else {
            const checkeds = document.getElementsByName('day_of_week');
            for (let i = 0; i < checkeds.length; i++) {
                if (checkeds[i].checked) {
                    checkedArr.push(checkeds[i].value);
                    checked_count++
                }
            }
            value = checkedArr;
        }
        if (checked_count > 0) {
            $("input[name='all']").attr("disabled", true);
            $(".m-checkbox-all").addClass("m-checkbox--disabled");
        } else {
            $(".m-checkbox-all").removeClass("m-checkbox--disabled");
            $("input[name='all']").removeAttr("disabled", true);
        }
        var listWeekofDay = '|';
        if (value.length > 0) {
            for (let index = 0; index < value.length; index++) {
                listWeekofDay += value[index] + '|'
            }
        } else {
            listWeekofDay = ''
        }


        DoorOpenHand['day_of_week'] = listWeekofDay
        this.setState({
            crrDoorOpen: DoorOpenHand
        })
    }

    handleChangeWeekAll(event) {
        var DoorOpenHand = this.state.crrDoorOpen;
        const checkedArr = [];
        let value = [];
        if (event.target.checked == true) {
            $("input[name='day_of_week']").attr("disabled", true);
            $(".m-checkbox-day").addClass("m-checkbox--disabled");
            const checkeds = document.getElementsByName('all');
            if (checkeds[0].checked) {
                checkedArr.push(checkeds[0].value);
            }
            value = checkedArr;
        } else {
            $(".m-checkbox-day").removeClass("m-checkbox--disabled");
            $("input[name='day_of_week']").removeAttr("disabled", true);
        }
        DoorOpenHand['day_of_week'] = value.toString()
        this.setState({
            crrDoorOpen: DoorOpenHand
        })
    }

    handleChangeStaffs(valueStaff) {

        // var valueStaff = valueStaff
        // if (valueStaff == null) {
        //     valueStaff = []
        // }
        // var difference = this.state.valueStaffs.filter(x => !valueStaff.includes(x)); // calculates diff

        // if (difference.length == 0) {
        //     var dataUpdate = {
        //         "id_obj": valueStaff[valueStaff.length - 1].value.toString(),
        //         "type_obj": 1,
        //         "id_door_calendar": this.state.id_door_calendar
        //     }
        //     this.update(dataUpdate, 1)
        // } else {
        //     var dataDelete = {
        //         "id_obj": difference[0].value.toString(),
        //         "type_obj": 1,
        //         "id_door_calendar": this.state.id_door_calendar
        //     }
        //     this.delete(dataDelete, 1)
        // }

        this.setState({
            valueStaffs: valueStaff,
        })
    }

    handleChangeGroups(valueGroup) {
        // var valueGroup = valueGroup
        // if (valueGroup == null) {
        //     valueGroup = []
        // }
        // var difference = this.state.valueGroups.filter(x => !valueGroup.includes(x)); // calculates diff
        // if (difference.length == 0) {
        //     var dataUpdate = {
        //         "id_obj": valueGroup[valueGroup.length - 1].value.toString(),
        //         "type_obj": 2,
        //         "id_door_calendar": this.state.id_door_calendar
        //     }
        //     this.update(dataUpdate, 2)
        // } else {
        //     var dataDelete = {
        //         "id_obj": difference[0].value.toString(),
        //         "type_obj": 2,
        //         "id_door_calendar": this.state.id_door_calendar
        //     }
        //     this.delete(dataDelete, 2)
        // }

        this.setState({
            valueGroups: valueGroup,
        })
    }

    update = async (dataUpdate, type) => {
        if (this.state.HOST == undefined) return;
        var dataUpdate;
        if (type == 1) {
            dataUpdate['type_obj'] = 1
        } else if (type == 2) {
            dataUpdate['type_obj'] = 2
        } else if (type == 3) {
            dataUpdate['type_obj'] = 3
        }
        await fetch(`${this.state.HOST}/api/door_open/insert`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            body: JSON.stringify(dataUpdate)
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                if (data.data.type_obj == 1) {
                    toastr.success(language[this.props.indexLanguage].Text_Notifi.add_staff_success, 'Title', { displayDuration: 2000 })
                } else if (data.data.type_obj == 2) {
                    toastr.success(language[this.props.indexLanguage].Text_Notifi.add_group_success, 'Title', { displayDuration: 2000 })
                } else if (data.data.type_obj == 3) {
                    this.getDataDoorOpen(this.state.crrDoorOpen,this.state.id_door_calendar)
                    swal(language[this.props.indexLanguage].Text_Notifi.success, language[this.props.indexLanguage].Text_Notifi.add_department_level_success, "success", {
                        buttons: false,
                        timer: 1500,
                    });
                }
            }
            else {
                swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].Text_Notifi.add_false, "warning");
            }
        }).catch((error) => {
            if (error) {
                swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].Text_Notifi.add_false, "warning");
            }
        });
        
    }

    delete = async (dataDelete, type) => {
        if (this.state.HOST == undefined) return;
        let data = await fetch(`${this.state.HOST}/api/door_open/delete`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            body: JSON.stringify(dataDelete)
        }).then((response) => {
            return response.json()
        });
        if (data.status === 10000) {
            if (type == 1) {
                toastr.success(language[this.props.indexLanguage].Text_Notifi.delete_staff_success, 'Title', { displayDuration: 2000 })
            } else if (type == 2) {
                toastr.success(language[this.props.indexLanguage].Text_Notifi.delete_group_success, 'Title', { displayDuration: 2000 })
            }else if (type == 3) {
                this.getDataDoorOpen(this.state.crrDoorOpen,this.state.id_door_calendar)
                swal(language[this.props.indexLanguage].Text_Notifi.success, language[this.props.indexLanguage].Text_Notifi.delete_department_level_success, "success", {
                    buttons: false,
                    timer: 1500,
                });
            }

        }
        else {
            swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].Text_Notifi.notifi_delete_false, "error");
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.show === true) {
            var optionDoor = [];
            for (let index = 0; index < nextProps.listdoor.length; index++) {
                optionDoor.push({ 'value': nextProps.listdoor[index].id, 'label': nextProps.listdoor[index].name })
            }
            var DayOfWeek = Array.prototype.filter.call(nextProps.data.day_of_week, eachChar => eachChar !== '|');
            $("input[name='all']").attr("disabled", true);
            $(".m-checkbox-all").addClass("m-checkbox--disabled");
            this.setState({
                id_door_calendar: nextProps.data.id,
                DayOfWeek: DayOfWeek,
                crrDoorOpen: nextProps.data,
                optionDoor: optionDoor,
                HOST: nextProps.data.HOST,
                display: true,
                valueGroups: [],
                valueStaffs: [],
                DataDoorOpen: {
                    "department_levels": [],
                    "groups": [],
                    "staffs": []
                },
                valueType: ""
            });
            this.getDoorByID(nextProps.data)
            this.getStaff(nextProps.data)
            this.getGroup(nextProps.data)
            this.getDepartment(nextProps.data)
            this.getLevel(nextProps.data)
            this.getDataDoorOpen(nextProps.data,nextProps.data.id)
        }
    }

    appendDepartmentLevel = () => {
        var DataDoorOpen = this.state.DataDoorOpen;
        var department_levels = {
            "department": {},
            "level":  {}
        }
        DataDoorOpen.department_levels.push(department_levels);
        this.setState(DataDoorOpen)
    }

    render() {
        let click_handle = (event) => {
            this.updateDoorOpen(this.state.crrDoorOpen, event);
        }
        let pndepartment ;
        if (this.state.DataDoorOpen.department_levels.length > 0  ) {
            pndepartment = this.state.DataDoorOpen.department_levels.map((e, i) => {
                var value_Department
                if (e.department.id != undefined) {
                    value_Department = { value: e.department.id, label: e.department.name };
                }
                
                var value_Level
                if (e.level.id != undefined) {
                    if (e.level.id !== -1) {
                        value_Level = { value: e.level.id, label: e.level.name };
                    } else {
                        value_Level = { value: -1, label: language[this.props.indexLanguage].Text_Notifi.all_level };
                    }
                }

                var display = "";
                if (e.department.description !== undefined) {
                    display = "d-none"
                } else {
                    display = ""
                }
                return (
                    <tr key={i}>
                        <td>
                            <Select
                                getOptionValue ={(option)=>option.label}
                                onChange={(value) => {
                                    var DataDoorOpen = this.state.DataDoorOpen;
                                    DataDoorOpen.department_levels[i].department.id = value.value;
                                    DataDoorOpen.department_levels[i].department.name = value.label;
                                    this.setState({
                                        DataDoorOpen: DataDoorOpen,
                                    });
                                }}
                                required
                                options={this.state.listDepartment}
                                placeholder={language[this.props.indexLanguage].placeholderSelect.select_department}
                                value={value_Department}
                                isDisabled={this.state.DataDoorOpen.department_levels[i].level.description === undefined ? false : true}
                            />
                        </td>
                        <td>
                            <Select
                                getOptionValue ={(option)=>option.label}
                                onChange={(value) => {
                                    var DataDoorOpen = this.state.DataDoorOpen;
                                    DataDoorOpen.department_levels[i].level.id = value.value;
                                    DataDoorOpen.department_levels[i].level.name = value.label;
                                    this.setState({
                                        DataDoorOpen: DataDoorOpen,
                                    });
                                }}
                                options={this.state.listLevel}
                                required
                                placeholder={language[this.props.indexLanguage].placeholderSelect.select_level}
                                value={value_Level}
                                isDisabled={this.state.DataDoorOpen.department_levels[i].level.description === undefined ? false : true}
                            />
                        </td>
                        <td>
                            <button
                                onClick={(event) => {
                                    event.preventDefault()
                                    var DataDoorOpen = this.state.DataDoorOpen;
                                    if (DataDoorOpen.department_levels[i].level.id) {
                                        event.preventDefault();
                                        if (!window.confirm(language[this.props.indexLanguage].Text_Notifi.text_notifi_delete_department_level)) {
                                            return;
                                        }
                                        var obj = value_Department.value +'_'+value_Level.value
                                        var dataDelete = {
                                            "id_obj": obj.toString(),
                                            "type_obj": 3,
                                            "id_door_calendar": this.state.id_door_calendar
                                        }
                                        this.delete(dataDelete, 3)
                                    } else {
                                        DataDoorOpen.department_levels.splice(i, 1)
                                        this.setState({
                                            DataDoorOpen: DataDoorOpen
                                        });
                                    }
                                }}
                                className="m-portlet__nav-link btn m-btn m-btn--hover-danger m-btn--icon m-btn--icon-only m-btn--pill" title="delete"><b><i className="la la-remove"></i></b></button>
                        </td>
                    </tr>
                )
            })
        }
        var display = "";
        if (this.state.display == true) {
            display = "d-none"
        } else {
            display = ""
        }

        var display2 = "d-none"
        if (!this.state.display) {
            if (this.state.valueType.value === 0) {
                display2 = ""
            }
        }

        var display3 = "d-none"
        if (!this.state.display) {
            if (this.state.valueType.value !== undefined) {
                display3 = ""
            }
        }
        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form id="formUpdateDoorOpen">
                        <div className="col-xl-12">
                            <div className="m-widget14 row">
                                <div className="form-group m-form__group col-md-12 p-0">
                                    <label htmlFor="DoorOpen">{language[this.props.indexLanguage].textTable.id_door_calendar}</label>
                                    <div>
                                        {this.state.id_door_calendar}
                                    </div>
                                </div>
                                <div className="form-group m-form__group col-md-12 p-0">
                                    <label htmlFor="DoorOpen">ID {language[this.props.indexLanguage].Menubar.door} <span className="text-danger"> *</span></label>
                                    <Select
                                        getOptionValue ={(option)=>option.label}
                                        onChange={value => this.handleChangeDoor(value)}
                                        options={this.state.optionDoor}
                                        value={this.state.valueDoor}
                                        placeholder={language[this.props.indexLanguage].placeholderSelect.select_door}
                                    />
                                </div>
                                <div className="form-group m-form__group col-md-6 pl-0">
                                    <label>{language[this.props.indexLanguage].textTable.time_in}</label>
                                    <div>
                                        <DatePicker
                                            className="form-control m-input m_datetimepicker_6"
                                            value={this.state.crrDoorOpen.time_in}
                                            onChange={this.handleChangeHourStartTime}
                                            showTimeSelectOnly
                                            showTimeSelect
                                            timeFormat="HH:mm"
                                            timeCaption="Time"
                                            placeholderText="Hour"
                                        />
                                    </div>
                                </div>
                                <div className="form-group m-form__group col-md-6 pr-0">
                                    <label>{language[this.props.indexLanguage].textTable.time_out}</label>
                                    <div>
                                        <DatePicker
                                            className="form-control m-input m_datetimepicker_6"
                                            value={this.state.crrDoorOpen.time_out}
                                            onChange={this.handleChangeHourEndTime}
                                            showTimeSelectOnly
                                            showTimeSelect
                                            timeFormat="HH:mm"
                                            timeCaption="Time"
                                            placeholderText="Hour"
                                        />
                                    </div>
                                </div>
                                <div className="form-group m-form__group col-md-6 pl-0">
                                    <label>{language[this.props.indexLanguage].textTable.start_date}</label>
                                    <div>
                                        <DatePicker
                                            className="form-control m-input m_datetimepicker_6"
                                            selected={this.state.crrDoorOpen.day_in ? new Date(this.state.crrDoorOpen.day_in) : null}
                                            onChange={this.handleChangeDayStartTime}
                                            dateFormat="dd-MM-yyyy"
                                            placeholderText="Start Date"
                                        />
                                    </div>
                                </div>
                                <div className="form-group m-form__group col-md-6 pr-0">
                                    <label>{language[this.props.indexLanguage].textTable.end_date}</label>
                                    <div>
                                        <DatePicker
                                            className="form-control m-input m_datetimepicker_6"
                                            selected={this.state.crrDoorOpen.day_out ? new Date(this.state.crrDoorOpen.day_out) : null}
                                            onChange={this.handleChangeDayEndTime}
                                            minDate={this.state.crrDoorOpen.day_in ? subDays(new Date(this.state.crrDoorOpen.day_in),0) : null}
                                            dateFormat="dd-MM-yyyy"
                                            placeholderText="End Date"
                                        />
                                    </div>
                                </div>
                                <div className="m-form__group form-group form_week">
                                    <label>{language[this.props.indexLanguage].textTable.day_of_week}</label>
                                    <div className="m-checkbox-inline">
                                        <label className="m-checkbox m-checkbox-day">
                                            <input type="checkbox" name="day_of_week" defaultValue={0} onClick={e => this.handleChangeWeek(e)} defaultChecked={this.state.DayOfWeek.indexOf('0') === -1 ? false : true} /> {language[this.props.indexLanguage].DayOfWeek.monday}
                                            <span />
                                        </label>
                                        <label className="m-checkbox m-checkbox-day">
                                            <input type="checkbox" name="day_of_week" defaultValue={1} onClick={e => this.handleChangeWeek(e)} defaultChecked={this.state.DayOfWeek.indexOf('1') === -1 ? false : true} /> {language[this.props.indexLanguage].DayOfWeek.tuesday}
                                            <span />
                                        </label>
                                        <label className="m-checkbox m-checkbox-day">
                                            <input type="checkbox" name="day_of_week" defaultValue={2} onClick={e => this.handleChangeWeek(e)} defaultChecked={this.state.DayOfWeek.indexOf('2') === -1 ? false : true} /> {language[this.props.indexLanguage].DayOfWeek.wednesday}
                                            <span />
                                        </label>
                                        <label className="m-checkbox m-checkbox-day">
                                            <input type="checkbox" name="day_of_week" defaultValue={3} onClick={e => this.handleChangeWeek(e)} defaultChecked={this.state.DayOfWeek.indexOf('3') === -1 ? false : true} /> {language[this.props.indexLanguage].DayOfWeek.thursday}
                                            <span />
                                        </label>
                                        <label className="m-checkbox m-checkbox-day">
                                            <input type="checkbox" name="day_of_week" defaultValue={4} onClick={e => this.handleChangeWeek(e)} defaultChecked={this.state.DayOfWeek.indexOf('4') === -1 ? false : true} /> {language[this.props.indexLanguage].DayOfWeek.friday}
                                            <span />
                                        </label>
                                        <label className="m-checkbox m-checkbox-day">
                                            <input type="checkbox" name="day_of_week" defaultValue={5} onClick={e => this.handleChangeWeek(e)} defaultChecked={this.state.DayOfWeek.indexOf('5') === -1 ? false : true} /> {language[this.props.indexLanguage].DayOfWeek.saturday}
                                            <span />
                                        </label>
                                        <label className="m-checkbox m-checkbox-day">
                                            <input type="checkbox" name="day_of_week" defaultValue={6} onClick={e => this.handleChangeWeek(e)} defaultChecked={this.state.DayOfWeek.indexOf('6') === -1 ? false : true} /> {language[this.props.indexLanguage].DayOfWeek.sunday}
                                            <span />
                                        </label>
                                    </div>
                                </div>
                                <div className="mx-auto p-3">
                                    <Button variant="accent" onClick={click_handle}>{language[this.props.indexLanguage].textButton.save}</Button>
                                </div>
                                <div className={"test col-xl-12 p-0 "+ display}>
                                    <div className="form-group m-form__group col-md-12 p-0">
                                        <label>{language[this.props.indexLanguage].textTable.select_type_obj}</label>
                                        <Select
                                            getOptionValue={(option) => option.label}
                                            onChange={Type => this.handleChangeType(Type)}
                                            options={this.state.listType}
                                            value={this.state.valueType}
                                            placeholder={language[this.props.indexLanguage].placeholderSelect.select_type_obj}
                                        />
                                    </div>
                                </div>

                                <div className={"test col-xl-12 p-0 "+ display2}>
                                    <div className="form-group m-form__group col-md-12 p-0">
                                        <label>{language[this.props.indexLanguage].textTable.staffs}</label>
                                        <Select
                                            getOptionValue ={(option)=>option.label}
                                            onChange={valueStaff => this.handleChangeStaffs(valueStaff)}
                                            options={this.state.listStaff}
                                            value={this.state.valueStaffs}
                                            placeholder={language[this.props.indexLanguage].placeholderSelect.select_staff}
                                            isMulti
                                            isClearable={false}
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                        />
                                    </div>
                                    <div className="form-group m-form__group col-md-12 p-0">
                                        <label>{language[this.props.indexLanguage].textTable.groups}</label>
                                        <Select
                                            getOptionValue ={(option)=>option.label}
                                            onChange={valueGroup => this.handleChangeGroups(valueGroup)}
                                            options={this.state.listGroup}
                                            value={this.state.valueGroups}
                                            isMulti
                                            isClearable={false}
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            placeholder={language[this.props.indexLanguage].placeholderSelect.select_group}
                                        />
                                    </div>
                                    <div className="form-group m-form__group col-md-12 p-0">
                                        <label>{language[this.props.indexLanguage].textTable.department} - {language[this.props.indexLanguage].textTable.level}</label>
                                        <div className="text-nowrap" id="table_devices">
                                            <table className="table table-bordered table-hover table-checkable dataTable no-footer dtr-inline collapsed">
                                                <thead>
                                                    <tr>
                                                        <th style={{ 'verticalAlign': 'middle' }}>{language[this.props.indexLanguage].textTable.department}</th>
                                                        <th style={{ 'verticalAlign': 'middle' }}>{language[this.props.indexLanguage].textTable.level}</th>
                                                        <th style={{ 'verticalAlign': 'middle', 'width': '80px' }}>{language[this.props.indexLanguage].textButton.action}
                                                            <button
                                                                onClick={(event) => {
                                                                    event.preventDefault()
                                                                    this.appendDepartmentLevel()
                                                                }}
                                                                style={{ 'height': '25px', 'width': '25px', 'marginLeft': '5px' }} className="btn btn-outline-success m-btn m-btn--icon m-btn--icon-only">
                                                                <i className="la la-plus"></i>
                                                            </button>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody id="append_body">
                                                    {pndepartment}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className={"mx-auto p-3 " +display3}>
                                    <Button variant="success" className={"btn btn-success m-btn--icon mr-2 m-loader--light m-loader--right"} onClick={this.click_handle_setup}>{language[this.props.indexLanguage].textButton.save_setup}</Button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.onHide}>{language[this.props.indexLanguage].textButton.close}</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

ModalDoorOpen.propTypes = {
    data: PropTypes.object,
    listdoor: PropTypes.array,
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool,
}
const mapStateToProps = state => {
    return {
        indexLanguage: state.changelanguage.indexLanguage,
    }
}


export default connect(mapStateToProps, null)(ModalDoorOpen);