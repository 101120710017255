import Axios from 'axios';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import LoadingOverlay from 'react-loading-overlay';
import { useSelector } from 'react-redux';
import language from '../../../language/language';
import { CHART } from '../../chart';
import Header from '../../layouts/Header';

const today = moment(new Date()).format('YYYY-MM-DD') + ' 23:59:59';

function DashBoard() {

    const box_engine_cf = useSelector(state => state.boxai.box_engine_cf);
    const token = useSelector(state => state.isLogin.access_token);
    
    const indexLanguage = useSelector(state => state.changelanguage.indexLanguage);
    const list_company = useSelector(state => state.boxai.list_company);
    
    let xAxis = [];
    for (let i = 0; i <= 12; i++) {
        let month = ''
        switch (i + 1) {
            case 1:
                month = language[indexLanguage].Month.january
                break;
            case 2:
                month = language[indexLanguage].Month.february
                break;
            case 3:
                month = language[indexLanguage].Month.march
                break;
            case 4:
                month = language[indexLanguage].Month.april
                break;
            case 5:
                month = language[indexLanguage].Month.may
                break;
            case 6:
                month = language[indexLanguage].Month.june
                break;
            case 7:
                month = language[indexLanguage].Month.july
                break;
            case 8:
                month = language[indexLanguage].Month.august
                break;
            case 9:
                month = language[indexLanguage].Month.september
                break;
            case 10:
                month = language[indexLanguage].Month.october
                break;
            case 11:
                month = language[indexLanguage].Month.november
                break;
            case 12:
                month = language[indexLanguage].Month.december
                break;
        
            default:
                break;
        }
        xAxis.push(month);
    }

    const [view, setView] = useState(2);
    const [col, setCol] = useState('col-xl-6');

    const [date_1, setDate_1] = useState(new Date());

    const [date_2, setDate_2] = useState(new Date());

    const [total_3, setTotal_3] = useState({
        staff_on_time: '',
        staff_business: '',
        staff_day_off: '',
        staff_to_late: '',
    })
    
    const [listIDCompany,setListIDCompany] = useState([]);

    const [chartOptions_1, setChartOptions_1] = useState(Object.assign({
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: true,
                    color: 'red',
                    formatter: function () {
                        return (this.y !== 0) ? this.y : "";
                    }
                }
            }
        },
        series: [
            {
                name: language[indexLanguage].DashBoard.is_work,
                data: [],
            },
            {
                name: language[indexLanguage].DashBoard.quit_job,
                data: [],
            }
        ],
        xAxis: {
            categories: xAxis,
        },
        yAxis: {
            title: {
                text: language[indexLanguage].DashBoard.staff,
            },
            allowDecimals: false,
        }
    }, CHART));

    const [chartOptions_2, setChartOptions_2] = useState(Object.assign({
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: true,
                    color: 'red',
                    formatter: function () {
                        return (this.y !== 0) ? this.y : "";
                    }
                }
            }
        },
        xAxis: {
            categories: xAxis,
        },
        yAxis: {
            title: {
                text: language[indexLanguage].DashBoard.hour,
            },
            allowDecimals: false,
        },
        series: [
            {
                name: language[indexLanguage].DashBoard.hour_timekeeping,
                data: [],
            },
            {
                name: language[indexLanguage].DashBoard.ot,
                data: [],
            },
            {
                name: language[indexLanguage].DashBoard.hour_business,
                data: [],
            },
            {
                name: language[indexLanguage].DashBoard.hour_in_late,
                data: [],
            },
            {
                name: language[indexLanguage].DashBoard.hour_out_soon,
                data: [],
            },
        ]
    }, CHART));

    const [chartOptions_3, setChartOptions_3] = useState(Object.assign({
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: true,
                    color: 'red',
                    formatter: function () {
                        return '' + this.point.text;
                    }
                }
            }
        },
        yAxis: {
            title: {
                text: language[indexLanguage].DashBoard.staff,
            },
            allowDecimals: false,
        },
        series: [
            {
                type: 'column',
                name: language[indexLanguage].DashBoard.present,
                data: [],
            },
            {
                type: 'column',
                name: language[indexLanguage].DashBoard.off,
                data: [],
            },
            {
                type: 'column',
                name: language[indexLanguage].DashBoard.business,
                data: [],
            },
            {
                type: 'column',
                name: language[indexLanguage].DashBoard.no_timekeeping,
                data: [],
            }
        ]
    }, CHART));
    
    const [chartOptions_4, setChartOptions_4] = useState(Object.assign({
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: true,
                    color: 'red',
                    formatter: function () {
                        return (this.y !== 0) ? this.y : "";
                    }
                }
            }
        },
        xAxis: {
            categories: xAxis,
        },
        yAxis: {
            title: {
                text: language[indexLanguage].DashBoard._number,
            },
            allowDecimals: false,
        },
        series: [
            {
                name: language[indexLanguage].DashBoard.out_soon,
                data: [],
            },
            {
                name: language[indexLanguage].DashBoard.in_late,
                data: [],
            }
        ]
    }, CHART));

    const [isActive_1, setIsActive_1] = useState(false);
    const [isActive_2, setIsActive_2] = useState(false);
    const [isActive_3, setIsActive_3] = useState(false);
    const [isActive_4, setIsActive_4] = useState(false);

    const getDataHighChart_1 = async (date) => {
        setIsActive_1(true);
        const dataPost = {
            start_date: moment(date ? date : date_1).startOf('year').format('YYYY-MM-DD HH:mm:ss'),
            end_date: parseInt(moment(date ? date : date_1).format('YYYY')) === new Date(today.replace(/-/g, "/")).getFullYear() ? today : moment(date ? date : date_1).endOf('year').format('YYYY-MM-DD HH:mm:ss'),
            ids_company: listIDCompany,
            id_department: -1,
        }
        const result = await Axios({
            method: 'POST',
            url: `${box_engine_cf.crrApiSocket}/api/time_keeping/statistics_staff_month`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            data: dataPost,
        });
        if (result.data.status === 10000) {
            setIsActive_1(false);
            const data = Object.values(result.data.data);
            let staff = [];
            let quit_job = [];
            data.map(map => {
                const array = Object.values(map);
                let total_staff = 0;
                let total_quit = 0;
                array.map(map_1 => {
                    total_staff += map_1.total_staff;
                    total_quit += map_1.total_staff_quit_job;
                })
                staff.push(total_staff);
                quit_job.push(total_quit);
            });
            setChartOptions_1({
                ...chartOptions_1,
                series: [
                    {
                        name: language[indexLanguage].DashBoard.is_work,
                        data: staff,
                    },
                    {
                        name: language[indexLanguage].DashBoard.quit_job,
                        data: quit_job,
                    }
                ]
            })
        }
    }

    const getDataHighChart_2 = async (date) => {
        setIsActive_2(true);
        setIsActive_4(true);
        const dataPost = {
            start_date: moment(date ? date : date_2).startOf('year').format('YYYY-MM-DD HH:mm:ss'),
            end_date: parseInt(moment(date ? date : date_2).format('YYYY')) === new Date(today).getFullYear() ? today : moment(date ? date : date_2).endOf('year').format('YYYY-MM-DD HH:mm:ss'),
            ids_company: listIDCompany,
            id_department: -1,
            id_shift: -1,
        }
        const result = await Axios({
            method: 'POST',
            url: `${box_engine_cf.crrApiSocket}/api/time_keeping/statistic_by_year`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            data: dataPost,
        });
        if (result.data.status === 10000) {
            setIsActive_2(false);
            setIsActive_4(false);
            const data = Object.values(Object.values(result.data.data));

            let result_1 = {};
            let ot = [];
            let ct = [];
            let time_inlate = [];
            let time_outsoon = [];
            let timekeeping = [];
            let out_soon = [];
            let in_late = [];

            data.forEach(basket => { //(2)
                for (let [key, value] of Object.entries(basket)) { //(3)
                    if (result_1[key]) { //(4)
                        result_1[key] = {
                            time_inlate: result_1[key].time_inlate + value.time_inlate,
                            time_outsoon: result_1[key].time_outsoon + value.time_outsoon,
                            CT: result_1[key]['CT'] + value['CT'],
                            OT: result_1[key]['OT'] + value['OT'],
                            timekeeping: result_1[key].timekeeping + value.timekeeping,
                            in_late: result_1[key].in_late + value.in_late,
                            out_soon: result_1[key].out_soon + value.out_soon,
                        }; //(5)
                    } else { //(6)
                        result_1[key] = {
                            time_inlate: value.time_inlate,
                            time_outsoon: value.time_outsoon,
                            CT: value['CT'],
                            OT: value['OT'],
                            timekeeping: value.timekeeping,
                            in_late: value.in_late,
                            out_soon: value.out_soon,
                        };
                    }
                }
            });

            Object.values(result_1).map(map => {
                ot.push(parseInt(map['OT']));
                ct.push(parseInt(map['CT']));
                time_inlate.push(parseInt(map.time_inlate));
                time_outsoon.push(parseInt(map.time_outsoon));
                timekeeping.push(parseInt(map.timekeeping));
                out_soon.push(parseInt(map.out_soon));
                in_late.push(parseInt(map.in_late));
            });

            setChartOptions_2({
                ...chartOptions_2,
                series: [
                    {
                        name: language[indexLanguage].DashBoard.hour_timekeeping,
                        data: timekeeping,
                    },
                    {
                        name: language[indexLanguage].DashBoard.ot,
                        data: ot,
                    },
                    {
                        name: language[indexLanguage].DashBoard.hour_business,
                        data: ct,
                    },
                    {
                        name: language[indexLanguage].DashBoard.hour_in_late,
                        data: time_inlate,
                    },
                    {
                        name: language[indexLanguage].DashBoard.hour_out_soon,
                        data: time_outsoon,
                    },
                ]
            });
            setChartOptions_4({
                ...chartOptions_4,
                series: [
                    {
                        name: language[indexLanguage].DashBoard.out_soon,
                        data: out_soon,
                    },
                    {
                        name: language[indexLanguage].DashBoard.in_late,
                        data: in_late,
                    }
                ]
            })
        }
    }
    const getDataHighChart_3 = async () => {
        setIsActive_3(true);
        const dataPost = {
            start_date: moment(new Date()).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            end_date: today,
            ids_company: listIDCompany,
            id_shift: -1,
        };
        const result = await Axios({
            method: 'POST',
            url: `${box_engine_cf.crrApiSocket}/api/time_keeping/statistics_staff_day`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            data: dataPost,
        });
        if (result.data.status === 10000) {
            setIsActive_3(false);

            const data = Object.values(Object.entries(result.data.data));
            let staff_business = [];
            let staff_day_off = [];
            let staff_on_time = [];
            let staff_to_late = [];

            let sum_total_on_time = 0;
            let sum_total_staff = 0;
            let sum_total_day_off = 0;
            let sum_total_business = 0;
            let sum_total_staff_to_late = 0;

            let xAxis = [];
            let newXAxis = [];
            data.map(map => {
                xAxis.push(map[0]);
                let _business = 0;
                let _on_time = 0;
                let _day_off = 0;
                let _late = 0;
                let _total_staff = 0;
                map[1].map(map_1 => {
                    _business += map_1.staff_business;
                    _on_time += map_1.staff_on_time;
                    _day_off += map_1.staff_day_off;
                    _late += map_1.staff_late;
                    _total_staff += map_1.total_staff;
                })
                const on_time = _on_time + _late;

                staff_business.push(_business ? { y: _business, text: _business } : {y: 0, text: ''});
                staff_day_off.push(_day_off ? { y: _day_off, text: _day_off } : {y: 0, text: ''});
                staff_on_time.push(on_time ? {y: on_time, text: `${on_time}/${_total_staff}`} : {y: 0, text: ''});
                staff_to_late.push(map.staff_late ? { y: map.staff_late, text: map.staff_late } : {y: 0, text: ''});

                sum_total_business += _business;
                sum_total_day_off += _day_off;
                sum_total_on_time += on_time;
                sum_total_staff += _total_staff;
                sum_total_staff_to_late += map.staff_late;
            });
            list_company.map(map => {
                xAxis.some(some => {
                    if (parseInt(some) === map.id_company) {
                        newXAxis.push(map.name);
                    }
                })
            })
            setTotal_3({
                staff_on_time: sum_total_on_time + '/' + sum_total_staff,
                staff_day_off: sum_total_day_off,
                staff_business: sum_total_business,
                staff_to_late: sum_total_staff_to_late,
            })
            setChartOptions_3({
                ...chartOptions_3,
                xAxis: {
                    categories: newXAxis,
                },
                series: [
                    {
                        type: 'column',
                        name: language[indexLanguage].DashBoard.present,
                        data: staff_on_time,
                    },
                    {
                        type: 'column',
                        name: language[indexLanguage].DashBoard.off,
                        data: staff_day_off,
                    },
                    {
                        type: 'column',
                        name: language[indexLanguage].DashBoard.business,
                        data: staff_business,
                    },
                    {
                        type: 'column',
                        name: language[indexLanguage].DashBoard.to_late,
                        data: staff_to_late,
                    },
                ]
            })
        }
    }

    const handleGirdLayout = (data) => {
        setView(data);
        setCol(data === 1 ? 'col-xl-12' : data === 2 ? 'col-xl-6' : '');
        setChartOptions_1({
            ...chartOptions_1,
            chart: {
                ...chartOptions_1.chart,
                height: data === 1 ? windowSize.height - 255 : data === 2 ? 300 : '',
            }
        });
        setChartOptions_2({
            ...chartOptions_2,
            chart: {
                ...chartOptions_2.chart,
                height: data === 1 ? windowSize.height - 255 : data === 2 ? 300 : '',
            }
        });
        setChartOptions_3({
            ...chartOptions_3,
            chart: {
                ...chartOptions_3.chart,
                height: data === 1 ? windowSize.height - 255 : data === 2 ? 300 : '',
            }
        });
        setChartOptions_4({
            ...chartOptions_4,
            chart: {
                ...chartOptions_4.chart,
                height: data === 1 ? windowSize.height - 255 : data === 2 ? 300 : '',
            }
        });
    }
    
    const ExampleCustomInput = ({ value, onClick }) => (
        <button className="btn btn-secondary m-btn m-btn--icon col-md-12" onClick={onClick}>
            {value}
        </button>
    );

    useEffect(() => {
        const fetchData = () => {
            if(Highcharts.chart[0]){
                Highcharts.charts[0].reflow();
                Highcharts.charts[1].reflow();
                Highcharts.charts[2].reflow();
                Highcharts.charts[3].reflow();
            }
        }
        fetchData();
    },[view]);

    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
            setChartOptions_1({
                ...chartOptions_1,
                chart: {
                    ...chartOptions_1.chart,
                    height: window.innerWidth < 550 ? 300 : view === 2 ? 300 : window.innerHeight - 255,
                }
            });
            setChartOptions_2({
                ...chartOptions_2,
                chart: {
                    ...chartOptions_2.chart,
                    height: window.innerWidth < 550 ? 300 : view === 2 ? 300 : window.innerHeight - 255,
                }
            });
            setChartOptions_3({
                ...chartOptions_3,
                chart: {
                    ...chartOptions_3.chart,
                    height: window.innerWidth < 550 ? 300 : view === 2 ? 300 : window.innerHeight - 255,
                }
            });
            setChartOptions_4({
                ...chartOptions_4,
                chart: {
                    ...chartOptions_4.chart,
                    height: window.innerWidth < 550 ? 300 : view === 2 ? 300 :  window.innerHeight - 255,
                }
            });
        }

        // Add event listener
        window.addEventListener("resize", handleResize);

        // Call handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); 
 
    useEffect(() => {
        const fetchData = () => {
            if (list_company.length > 1) {
                let array_id_company = [];
                list_company.map(map => {
                    array_id_company.push(map.id_company);
                })
                setListIDCompany(array_id_company)
            }
        }
        fetchData();
    }, [list_company]);
    useEffect(() => {
        if (box_engine_cf && listIDCompany.length > 1) {
            getDataHighChart_1();
            getDataHighChart_2();
            getDataHighChart_3();
        }
    }, [box_engine_cf, listIDCompany]);
    
    return (
        <>
        <Header />
        <div className={"m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body "}>
        <div className="m-grid__item m-grid__item--fluid m-wrapper dashboard_wrapper">
            <div className="m-content mt-3-phone pd_phone_0 pl-0 pr-0">
                {
                    windowSize.width > 1200 &&
                    <div className="px-2">
                        <div className="m-portlet header_dashboard my-1">
                    <button onClick={() => handleGirdLayout(2)}>
                                <i className="fas fa-th-large" style={view === 2 ? { color: '#000' } : {}}></i>
                            </button>
                            <button onClick={() => handleGirdLayout(1)}>
                                <i className="fas fa-th-list" style={view === 1 ? { color: '#000' } : {}}></i>
                            </button>
                        </div>
                    </div>
                }
                <div className="row m_phone_0 mr-0 ml-0 d-flex">
                    <div className={`${col} col-md-12 pr-2 pl-2 pd_phone_0 order-1`}>
                        <div className="m-portlet mb-2">
                            <div className="m-portlet__head d-md-flex justify-content-md-between">
                                <div className='d-flex align-items-center' style={{ fontSize: '18px' }}>
                                    {
                                        language[indexLanguage].DashBoard.dash_board_sa
                                    }
                                </div>
                                <div className="m-portlet__head-tools select_date_month body_dashboard_portlet d-md-flex justify-content-md-end">

                                    <ReactDatePicker
                                        className="form-control m-input m_datetimepicker_6"
                                        selected={date_1}
                                        onChange={date => {
                                            setDate_1(date);
                                            getDataHighChart_1(date);
                                        }}
                                        showYearPicker
                                        dateFormat="yyyy"
                                        customInput={<ExampleCustomInput />}
                                    />
                                </div>
                            </div>
                            <div className="m-portlet__body dashboard_spinner">

                                <LoadingOverlay
                                    active={isActive_1}
                                    spinner
                                    styles={{
                                        overlay: (base) => ({
                                            ...base,
                                            background: 'rgba(202, 202, 202, 0)',
                                        })
                                    }}
                                >
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={chartOptions_1}
                                    />

                                </LoadingOverlay>
                            </div>
                        </div>
                    </div>
                    <div className={`${col} col-md-12 pr-2 pl-2 pd_phone_0 ${windowSize.width < 1200 ? 'order-0' : view === 1 ? 'order-0' : 'order-2'}`}>
                        <div className="m-portlet mb-2">
                            <div className="m-portlet__head d-md-flex justify-content-md-between flex-column flex-md-row high_chart_3_sa">
                                <div className="m-portlet__head-tools select_date_month col-md-6 p-0 body_dashboard_portlet d-md-flex justify-content-md-start " style={{ fontSize: '18px' }}>
                                    {`${language[indexLanguage].DashBoard.to_day} ${moment(new Date()).format('DD/MM/YYYY')}`}
                                </div>
                                <div className='d-md-flex align-items-md-center high_chart_3_param' style={{ fontSize: '18px', whiteSpace: 'pre' }}>
                                    <span>{`${language[indexLanguage].DashBoard.present}: ${total_3.staff_on_time}`}    </span>
                                    <span>{`${language[indexLanguage].DashBoard.off} : ${total_3.staff_day_off}`}      </span>
                                    <span>{`${language[indexLanguage].DashBoard.business} : ${total_3.staff_business}`}     </span>
                                    <span>{`${language[indexLanguage].DashBoard.to_late} : ${total_3.staff_no_timekeeping}`}</span>
                                </div>
                            </div>
                            <div className="m-portlet__body dashboard_spinner">
                                <LoadingOverlay
                                    active={isActive_3}
                                    spinner
                                    styles={{
                                        overlay: (base) => ({
                                            ...base,
                                            background: 'rgba(202, 202, 202, 0)',
                                        })
                                    }}
                                >
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={chartOptions_3}
                                    />
                                </LoadingOverlay>
                            </div>
                        </div>
                    </div>
                    <div className={`${col} col-md-12 pr-2 pl-2 pd_phone_0 order-3`}>
                        <div className="m-portlet mb-2">
                            <div className="m-portlet__head d-md-flex justify-content-md-start">
                                <div className="m-portlet__head-tools select_date_month col-md-6 p-0 body_dashboard_portlet d-md-flex justify-content-md-start">
                                    <ReactDatePicker
                                        className="form-control m-input m_datetimepicker_6"
                                        selected={date_2}
                                        onChange={date => {
                                            setDate_2(date);
                                            getDataHighChart_2(date);
                                        }}
                                        showYearPicker
                                        dateFormat="yyyy"
                                        customInput={<ExampleCustomInput />}
                                    />
                                </div>
                            </div>
                            <div className="m-portlet__body dashboard_spinner">
                                <LoadingOverlay
                                    active={isActive_2}
                                    spinner
                                    styles={{
                                        overlay: (base) => ({
                                            ...base,
                                            background: 'rgba(202, 202, 202, 0)',
                                        })
                                    }}
                                >
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={chartOptions_2}
                                    />
                                </LoadingOverlay>
                            </div>
                        </div>
                    </div>
                    <div className={`${col} col-md-12 pr-2 pl-2 pd_phone_0 order-4`}>
                        <div className="m-portlet mb-3 ">
                            <div className="m-portlet__head d-md-flex justify-content-md-start">
                               
                            </div>
                            <div className="m-portlet__body dashboard_spinner">
                                <LoadingOverlay
                                    active={isActive_4}
                                    spinner
                                    styles={{
                                        overlay: (base) => ({
                                            ...base,
                                            background: 'rgba(202, 202, 202, 0)',
                                        })
                                    }}
                                >
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={chartOptions_4}
                                    />
                                </LoadingOverlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </>
    );
}

export default DashBoard;
