import React, { Component } from 'react';
import { css } from '@emotion/core';
import Select from "react-select";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { OutlinedInput, TextField } from '@material-ui/core';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Pagination from 'react-js-pagination';
import language from '../../../language/language';
import { PulseLoader } from 'react-spinners';
import Store from '../../../store';
import equal from 'fast-deep-equal'
import ReactTooltip from 'react-tooltip';
import StatisticalLeaveOfAbsenceForm from './components/statisticalLeaveOfAbsenceForm'
import { data } from 'jquery';
import vi from 'date-fns/locale/vi';
import Moment from 'moment';
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import { connect } from 'react-redux'
import { formatTitleOption, formatTreeViewData } from '../CommonFunction';
import { withStyles } from "@material-ui/core/styles";
import TreeView from '../TreeView';
import { Button } from 'react-bootstrap';
import Header from '../../layouts/Header';

const useStyles = theme => ({
    paper: {
        whiteSpace: "nowrap"
    }
});

registerLocale('vi', vi)
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;


class statisticalLeaveOfAbsence extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLogin: Store.getState().isLogin.isLogin,
            access_token: Store.getState().isLogin.access_token,
            crrApi2: Store.getState().boxai.box_engine_cf.crrApiSocket,
            box_engine_cf: Store.getState().boxai.box_engine_cf,
            language: Store.getState().changelanguage.language,
            dataRole: Store.getState().role.role,
            optionDepartment: [],
            listDepartment: [],
            activePage: 1,
            offset: 0,
            totalLength: 0,
            listUser: [],
            valueSearch: '',
            valueDepartment: null,
            modalDepartment: false,
            valueSearch: '',
            crrDataUser: [],
            loading: false,
            crrData: [],
            id_user: null,
            supervisor: true,
            listDepartment: [],
            modalShow: false,
            crrStatistical: {
                id: "",
                ndo: "",
                ndo_ya: "",
                ndo_used: "",
                ndo_unpaid: "",
                year: ""
            },
            countDataStaff: 0,
            crrDate: new Date(),
            dataRole: Store.getState().role.role,
            type: '',
            selectDepartment: false
        };
        Store.subscribe(() => {
            this.setState({
                isLogin: Store.getState().isLogin.isLogin,
                access_token: Store.getState().isLogin.access_token,
                crrApi2: Store.getState().boxai.box_engine_cf.crrApiSocket,
                box_engine_cf: Store.getState().boxai.box_engine_cf,
                language: Store.getState().changelanguage.language,
                dataRole: Store.getState().role.role,
                crrData: [],
                activePage: 1,
                offset: 0,
                totalLength: 0,
                valueSearch: '',
                optionDepartment: [],
                valueDepartment: null,
                listDepartment: [],
                crrDate: new Date(),
                dataRole: Store.getState().role.role,
                selectDepartment: false
            }, () => {
                this.getData();
            });
        });
        this.itemsPerPage = 10;
    }


    componentWillMount() {
        var id_user = localStorage.getItem("id_user")
        var type = localStorage.getItem("type")
        this.setState({
            id_user: id_user,
            type: type,
            optionDepartment: [],
            valueDepartment: null
        })
    }

    getData = (supervisor) => {
        if (this.state.crrApi2 == undefined) return;
        if (this.state.dataRole.indexOf(this.state.type + '/statistical_leave_of_absence:view_all') !== -1) {
            const url = `${this.state.crrApi2}/api/department/${this.state.box_engine_cf.idCompany}/get_all`
            fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': Store.getState().isLogin.access_token
                },
            }).then((response) => {
                return response.json()
            }).then((data) => {
                if (data.status === 10000) {
                    var optionDepartment = []
                    var valueAllDepartment = []
                    for (let index = 0; index < data.data.length; index++) {
                        optionDepartment.push({ 'value': [data.data[index].id], 'title': data.data[index].name, 'code': data.data[index].code })
                        valueAllDepartment.push(data.data[index].id)
                    }
                    optionDepartment.unshift({ value: valueAllDepartment, title: language[this.props.indexLanguage].placeholderSelect.all_department })
                    this.setState({
                        valueDepartment: { value: valueAllDepartment, title: language[this.props.indexLanguage].placeholderSelect.all_department },
                        optionDepartment,
                        loading: false,
                    }, () => {
                        this.getByDepartment()
                    });
                }
            })
        } else {
            const url = `${this.state.crrApi2}/api/department/get_by_supervisor/${this.state.id_user}`
            fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': Store.getState().isLogin.access_token
                },
            }).then((response) => {
                return response.json()
            }).then((data) => {
                if (data.status === 10000) {
                    var optionDepartment = data.data.length > 1 ? [{ value: valueAllDepartment, title: language[this.props.indexLanguage].placeholderSelect.all_department }] : []
                    var valueAllDepartment = []
                    for (let index = 0; index < data.data.length; index++) {
                        optionDepartment.push({ 'value': [data.data[index].id], 'title': data.data[index].name, 'code': data.data[index].code })
                        valueAllDepartment.push(data.data[index].id)
                    }
                    this.setState({
                        valueDepartment: optionDepartment[0],
                        optionDepartment,
                        loading: false,
                    }, () => {
                        this.getByDepartment()
                    });
                }
            })
        }

    }

    getByDepartment = () => {
        if (this.state.crrApi2 === undefined) {
            this.setState({
                loading: false
            })
        }
        if (this.state.crrApi2 === undefined) return;
        fetch(`${this.state.crrApi2}/api/daysoff/get_by_department`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            body: JSON.stringify({
                "ids": this.state.valueDepartment.value,
                "data_search": this.state.valueSearch.trim(),
                "year": parseInt(Moment(this.state.crrDate).format("YYYY")),
                "index": this.state.activePage,
                "item_per_page": this.itemsPerPage
            })
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {

                this.setState({
                    listUser: [...data.data],
                    loading: false,
                    countDataStaff: data.count
                }, () => {
                    this.filterUser(this.state.activePage)
                });

            }
        }).catch((error) => {
            console.log(error)
        })
    }

    handleChangeDepartment = (index, value) => {
        this.setState({
            valueDepartment: value,
            activePage: 1,
            listUser: [],
            loading: true,
        }, () => {
            this.getByDepartment()
        })

    }


    filterUser = (activePage) => {
        const offset = (activePage - 1) * this.itemsPerPage;


        this.setState({

            offset,

        })
    }


    componentDidMount() {
        this.getData();
    }



    modalClose = () => {
        this.setState({
            modalShow: false
        })
        this.getByDepartment();

    }



    handlePageChange = (pageNumber) => {
        this.setState({
            ...this.state,
            activePage: pageNumber
        }, () => {
            this.getByDepartment();
        })


    }

    btnSearch = () => {
        this.setState({
            activePage: 1,

        }, () => {
            this.getByDepartment();
        })
    }

    isChange = (event) => {
        if (event.key === 'Enter') {
            this.btnSearch(event.target.value)
        }
        this.setState({
            valueSearch: event.target.value
        });
    }

    handleChangeYear = date => {
        this.setState({
            crrDate: date
        }, () => {
            this.getByDepartment()
        });


    }


    reset = (e) => {
        this.setState({
            activePage: 1,
            valueSearch: '',

        }, () => {
            this.getByDepartment();
            this.getData();
        });
    }

    render() {
        const { classes } = this.props;
        return (
            <>
            <Header />
            <div className={"m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body "}>
            <div className='m-grid__item m-grid__item--fluid m-wrapper'>
                <div className='m-content p-2'>
                    <div className='m-portlet m-portlet--tab mb-0'>
                        <div className="m-portlet__head p-3">
                            <div className="m-portlet__head-caption pl-3">
                                <div className="m-portlet__head-title">
                                    <h3 className="m-portlet__head-text">{language[this.props.indexLanguage].StatisticalLeaveOfAbsence.statistical_leave_of_absence}</h3>
                                </div>
                            </div>
                        </div>
                        <div className='m-portlet__body m-portlet__body--no-padding'>
                            <div className='row m-row--no-padding m-row--col-separator-xl'>
                                <div className='col-xl-12 mx-auto'>
                                    <div className='m-widget1 col-xl-12 mx-auto'>
                                        <div className="m-portlet__head-tools select_date_month col-md-1 p-0 mb-2">
                                            <DatePicker
                                                className="form-control m-input m_datetimepicker_6"
                                                selected={this.state.crrDate}
                                                onChange={this.handleChangeYear}
                                                showYearPicker
                                                dateFormat="yyyy"
                                                // yearItemNumber={3}
                                                placeholderText="Nhập năm"
                                            />
                                        </div>


                                        <div className="col-md-9 p-0 m-0 row pb-3">
                                            <div className="col-lg-3 pl-0 m--margin-bottom-10-tablet-and-mobile select_option">

                                                {/* <Autocomplete
                                                    options={this.state.optionDepartment}
                                                    getOptionLabel={option => option.title}
                                                    // defaultValue={{ 'value': [], 'title': language[this.props.indexLanguage].placeholderSelect.all_department }}
                                                    value={this.state.valueDepartment}
                                                    onChange={this.handleChangeDepartment}
                                                    disableClearable
                                                    renderOption={(props, option, state) => (
                                                        <div {...props}>
                                                            {formatTitleOption(props)}
                                                        </div>
                                                    )}
                                                    classes={{ paper: classes.paper }}
                                                    renderInput={params => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            placeholder={language[this.props.indexLanguage].placeholderSelect.select_department}
                                                            fullWidth
                                                        />
                                                    )}

                                                /> */}
                                                <Button 
                                                    onClick={() => {
                                                        this.setState({
                                                            selectDepartment: !this.state.selectDepartment
                                                        })
                                                    }}
                                                    className="btn btn-secondary btn-block m-btn m-btn--icon"
                                                >
                                                    <span style={{ color: "#000000" }}>
                                                        {this.state.valueDepartment?.value == -1 ? "Chọn phòng ban" : this.state.valueDepartment?.title}
                                                    </span>
                                                </Button>
                                            </div>

                                            <div className="col-lg-3 pl-0 m--margin-bottom-10-tablet-and-mobile">
                                                <input type="text" value={this.state.valueSearch} onChange={(event) => this.isChange(event)} onKeyUp={(event) => this.isChange(event)} className="form-control m-input" placeholder={language[this.props.indexLanguage].Search.text_placeholder_input_search_history} />
                                            </div>
                                            <div>
                                                <button
                                                    onClick={() => {
                                                        this.btnSearch(this.state.valueSearch)
                                                    }}
                                                    className="btn btn-accent m-btn m-btn--icon" id="m_search">
                                                    <span>
                                                        <i className="la la-search" />
                                                        <span>{language[this.props.indexLanguage].textButton.search}</span>
                                                    </span>
                                                </button>
                                            </div>

                                            <div className="ml-3">
                                                <button
                                                    onClick={() => {
                                                        this.reset()
                                                    }}
                                                    className="btn btn-secondary m-btn m-btn--icon" id="m_reset">
                                                    <span>
                                                        <i className="la la-rotate-left" />
                                                        <span>{language[this.props.indexLanguage].textButton.reload}</span>
                                                    </span>
                                                </button>
                                            </div>

                                        </div>

                                        <div className='row m-0 pb-3'>
                                        {
                                                    this.state.selectDepartment === true && (
                                                        <div className='col-xl-4'>
                                                            <div style={{ maxHeight: "650px", overflow: "auto", marginRight: "20px", marginTop: "10px", whiteSpace: "nowrap" }}>
                                                                <TreeView item={formatTreeViewData(this.state.optionDepartment)} getList={(data) => {
                                                                    this.handleChangeDepartment(0, {
                                                                        value: data.value,
                                                                        title: data.title,
                                                                        code: data.code
                                                                    });
                                                                    console.log('data', data)
                                                                }} />
                                                            </div>
                                                        </div>)
                                                }
                                            <div className={this.state.selectDepartment ? 'col-xl-8' : 'col-xl-12'}>
                                                <div className='table-responsive text-nowrap'>
                                                    <table className='table table-bordered table-hover table-checkable dataTable no-footer dtr-inline collapsed'>
                                                        <thead>
                                                            <tr>
                                                                <th style={{ verticalAlign: 'middle' }}>{language[this.props.indexLanguage].StatisticalLeaveOfAbsence.id}</th>
                                                                <th style={{ verticalAlign: 'middle' }}>{language[this.props.indexLanguage].StatisticalLeaveOfAbsence.name}</th>
                                                                <th style={{ verticalAlign: 'middle' }}>{language[this.props.indexLanguage].StatisticalLeaveOfAbsence.department}</th>
                                                                <th style={{ verticalAlign: 'middle' }}>{language[this.props.indexLanguage].StatisticalLeaveOfAbsence.numberdaysoff}</th>
                                                                <th style={{ verticalAlign: 'middle' }}>{language[this.props.indexLanguage].StatisticalLeaveOfAbsence.numberdaysoff_year_ago}</th>
                                                                <th style={{ verticalAlign: 'middle' }}>{language[this.props.indexLanguage].StatisticalLeaveOfAbsence.numberdaysoff_used}</th>
                                                                {/* <th style={{ verticalAlign: 'middle' }}>{language[this.props.indexLanguage].StatisticalLeaveOfAbsence.ndo_senior}</th> */}
                                                                <th style={{ verticalAlign: 'middle' }}>{language[this.props.indexLanguage].StatisticalLeaveOfAbsence.total_numberdaysoff}</th>
                                                                {this.state.dataRole.indexOf(this.state.type + '/days_off:update') !== -1 &&
                                                                    <th style={{ verticalAlign: 'middle' }}>{language[this.props.indexLanguage].StatisticalLeaveOfAbsence.action}</th>
                                                                }
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.listUser.map((e, i) => {
                                                                var number_day_off_rest = (e.ndo + e.ndo_ya - e.ndo_used)
                                                                return (
                                                                    <tr key={(i + 1)}>
                                                                        <td>{e.staff.id_staff}</td>
                                                                        <td>{e.staff.name}</td>
                                                                        <td>{e.staff.department.name}</td>
                                                                        <td>{e.ndo !== null ? e.ndo : 0}</td>
                                                                        <td>{e.ndo_ya !== null ? e.ndo_ya : 0}</td>
                                                                        <td>{e.ndo_used !== null ? e.ndo_used : 0}</td>
                                                                        {/* <td>{e.ndo_senior ? e.ndo_senior : 0}</td> */}
                                                                        <td>{(e.ndo !== null && e.ndo_ya !== null && e.ndo_used !== null) ? parseFloat(number_day_off_rest).toFixed(2) : 0}</td>
                                                                        {this.state.dataRole.indexOf(this.state.type + '/days_off:update') !== -1 &&
                                                                            <td>
                                                                                <button
                                                                                    onClick={(e) => {
                                                                                        var data = this.state.listUser
                                                                                        var dataStatistical = {
                                                                                            id: data[i].id,
                                                                                            id_staff: data[i].staff.id,
                                                                                            name: data[i].staff.name,
                                                                                            ndo: data[i].ndo,
                                                                                            ndo_ya: data[i].ndo_ya,
                                                                                            ndo_used: data[i].ndo_used,
                                                                                            ndo_unpaid: data[i].ndo_unpaid,
                                                                                            // ndo_senior: data[i].ndo_senior,
                                                                                            year: parseInt(Moment(this.state.crrDate).format("YYYY"))
                                                                                        }
                                                                                        this.setState({
                                                                                            modalShow: true,
                                                                                            crrStatistical: dataStatistical
                                                                                        })
                                                                                    }}
                                                                                    className="m-portlet__nav-link btn m-btn m-btn--hover-warning m-btn--icon m-btn--icon-only m-btn--pill" data-tip data-for='Edit'>
                                                                                    <i className="la la-edit" />
                                                                                </button>
                                                                                <ReactTooltip id='Edit' type='dark' effect='solid'>
                                                                                    <span>{language[this.props.indexLanguage].tooltip.open_edit}</span>
                                                                                </ReactTooltip>
                                                                            </td>
                                                                        }
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                    <StatisticalLeaveOfAbsenceForm
                                                        dataStatistical={this.state.crrStatistical}
                                                        show={this.state.modalShow}
                                                        onHide={this.modalClose}
                                                    />
                                                    <PulseLoader
                                                        css={override}
                                                        sizeUnit={"px"}
                                                        size={12}
                                                        margin={'2px'}
                                                        color={'#36D7B7'}
                                                        loading={this.state.loading}
                                                    />
                                                    <Pagination
                                                        firstPageText={language[this.props.indexLanguage].pagination.first}
                                                        nextPageText={language[this.props.indexLanguage].pagination.next}
                                                        lastPageText={language[this.props.indexLanguage].pagination.last}
                                                        prevPageText={language[this.props.indexLanguage].pagination.previous}
                                                        activePage={this.state.activePage}
                                                        itemsCountPerPage={this.itemsPerPage}
                                                        totalItemsCount={this.state.countDataStaff}
                                                        pageRangeDisplayed={3}
                                                        onChange={this.handlePageChange}
                                                    />
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </>
        );
    }
}
const mapStateToProps = state => {
    return {
        indexLanguage: state.changelanguage.indexLanguage,
    }
}

export default withStyles(useStyles)(connect(mapStateToProps, null)(statisticalLeaveOfAbsence));
