import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import language from '../../../../../language/language';
import Pagination from 'react-js-pagination';

PaginationTab.propTypes = {
    total: PropTypes.number,
    getData: PropTypes.func,
    page: PropTypes.number,
    item_per_page: PropTypes.string,
};
PaginationTab.defaultProps = {
    total: 0,
    getData: null,
    page: 1,
    item_per_page: '10',
}

function PaginationTab(props) {

    const { total, getData, page, item_per_page } = props;

    const indexLanguage = useSelector(state => state.changelanguage.indexLanguage);

    const [activePage, setActivePage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(item_per_page);
    const page_range = 5;

    const handleChangePage = (page) => {
        setActivePage(page);
        const dataPost = {
            ...getDataSearch(),
            index: page,
        }
        getData(dataPost);
    }
    const handleChangeItemPerPage = (items) => {
        setItemsPerPage(items);
        const dataPost = {
            ...getDataSearch(),
            item_per_page: parseInt(items),
        }
        getData(dataPost);
    }

    const getDataSearch = () => {
        const data = {
            index: activePage,
            item_per_page: parseInt(itemsPerPage),
        }
        return data;
    }
    useEffect(() => {
        setActivePage(page);
    },[page]);

    useEffect(() => {
        setItemsPerPage(item_per_page);
    },[item_per_page])
    return (
        <>
            <div className="pb-2 pt-3 timekeeping_pagination pr-3">
                <Pagination
                    firstPageText={language[indexLanguage].pagination.first}
                    nextPageText={language[indexLanguage].pagination.next}
                    lastPageText={language[indexLanguage].pagination.last}
                    prevPageText={language[indexLanguage].pagination.previous}
                    activePage={activePage}
                    itemsCountPerPage={itemsPerPage}
                    totalItemsCount={total}
                    pageRangeDisplayed={page_range}
                    onChange={(page) => handleChangePage(page)}
                />
            </div>
            <div className="col-xl-3 pr-0 pull-right">
                <div className="dataTables_length pull-right">
                    <span>{language[indexLanguage].Timekeeping.display} </span>
                    <label>
                        <select name="m_table_1_length" value={itemsPerPage} onChange={(e) => handleChangeItemPerPage(e.target.value)} aria-controls="m_table_1" className="form-control form-control-sm">
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={30}>30</option>
                            <option value={50}>50</option>
                        </select>
                    </label>
                </div>
            </div>
        </>
    );
}

export default PaginationTab;