import React from 'react';
import { useSelector } from 'react-redux';
import { HOST_BITABLE } from '../../../config';
import Header from '../../layouts/Header';

Appointment.propTypes = {

};

function Appointment(props) {
    const object_id = localStorage.getItem('data_user') ? JSON.parse(localStorage.getItem('data_user')).object_id : undefined;
    const name_staff = localStorage.getItem('data_user') ? JSON.parse(localStorage.getItem('data_user')).name : undefined;
    const box_engine = useSelector(state => state.boxai.box_engine_cf);
    return (
        <>
        <Header />
        <div className={"m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body "}>
        <div style={{ width: '100%', backgroundColor: '#fff' }}>
            <iframe src={`${HOST_BITABLE}/history-appointment?id_company=${box_engine.idCompany}&obj_id=${object_id}&name_staff=${name_staff}`} frameborder="0" style={{ width: '100%', height: '100%' }}></iframe>
        </div>
        </div>
        </>
    );
}

export default Appointment;
