import { REFRESH_ON, REFRESH_OFF } from '../../actions/letterNotify';

const initialState = {
    refresh: false
};
export default function letterNotify(state = initialState, action) {
    switch(action.type){
        case REFRESH_ON:
            return{
                refresh: true,
            };
        case REFRESH_OFF:
            return{
                refresh: false,
            };
        default:
            return state;
    }
}
