import { css } from '@emotion/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import Pagination from "react-js-pagination";
import { useSelector } from 'react-redux';
import { PulseLoader } from 'react-spinners';
import ReactTooltip from 'react-tooltip';
import Moment from 'moment';
import swal from 'sweetalert';
import language from '../../../../language/language';
import LeaveOfAbsenceForm from './components/leaveOfAbsenceForm';
import 'moment-timezone';
import ReactGA from 'react-ga';

const NameTag = 'daysoff_form';

const actionButtonStyle = {
    marginBottom: "5px",
    fontFamily: "inherit"
};
const portletBodyCss = {
    paddingTop: 0,
    paddingBottom: 0
};

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const to_date = `${new Date().getFullYear()}-12-31T23:59:59Z`;
const from_date = `${new Date().getFullYear()}-01-01 00:00:00`;

function LeaveOfAbsenceLetterTableTab(props) {
    const [fromDate, setFromDate] = useState(new Date(from_date));
    const [toDate, setToDate] = useState(new Date(to_date));

    const box_engine_cf = useSelector(state => state.boxai.box_engine_cf);
    const token = useSelector(state => state.isLogin.access_token);


    const indexLanguage = useSelector(state => state.changelanguage.indexLanguage);
    const userData = JSON.parse(localStorage.getItem('data_user'));

    const status = [
        { name: language[indexLanguage].DayOff.all, value: [1, 2, 3, 4] },
        { name: language[indexLanguage].DayOff.draff, value: [1] },
        { name: language[indexLanguage].DayOff.pending, value: [2] },
        { name: language[indexLanguage].DayOff.accept, value: [3] },
        { name: language[indexLanguage].DayOff.refused, value: [4] }
    ];

    const initialSearch = {
        id: userData ? userData.id_staff : 0,
        type_search: 0,
        type_form: 7,
        id_department: -1,
        arr_status: [1, 2, 3, 4],
        start_date: `${new Date().getFullYear()}-01-01 00:00:00`,
        end_date: Moment(to_date).format('YYYY-MM-DD 23:59:59'),
        data_search: '',
        index: 1,
        item_per_page: 10,
    }
    const [loading, setLoading] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [crrData, setCrrData] = useState(null);
    const [staffShift, setStaffShift] = useState([]);
    const [offset, setOffset] = useState(0)

    const [letterRows, setLetterRows] = useState([]);
    const [reason, setReason] = useState([]);
    const [supervisor, setSupervisor] = useState([]);
    const [statusCurrent, setStatusCurrent] = useState(status[0]);

    const [dataLetter, setDataLetter] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [dataSearch, setDataSearch] = useState(initialSearch);
    const [ndoRest, setNdoRest] = useState(null);

    const totalItems = letterRows;
    const range = 5;
    const itemsForPage = 10;


    const initial = {
        id: 0,
        start_date: new Date(new Date().setHours(8, 0, 0)).setMilliseconds(0),
        end_date: '',
        description: '',
        supervisor: supervisor[0],
        status: -1,
    }



    const getDataListSupervisor = async () => {
        try {
            const data = await axios({
                method: 'GET',
                url: `${box_engine_cf.crrApiSocket}/api/department/get_by_id/${userData.id_department}`,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': token,
                }
            })
            if (data.data.status === 10000) {
                setSupervisor(data.data.data.array_supervisor.map(map => ({
                    id: map.id_staff,
                    name: `${map.staff.name} - ${map.department.name} (${map.staff.id_staff})`,
                })));
            }
        } catch (error) {
            console.log(error);
        }
    }
    const getReasonOff = async () => {
        try {
            const data = await axios({
                method: 'GET',
                url: `${box_engine_cf.crrApiSocket}/api/reason_off/${box_engine_cf.idCompany}/get_all`,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': token,
                }
            })
            if (data.data.status === 10000) {
                setReason(data.data.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const getLetterOff = async (page) => {
        setLoading(true);
        try {
            const data = await axios({
                method: 'POST',
                url: `${box_engine_cf.crrApiSocket}/api/daysoff_form/get_by_id_staff`,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                data: {
                    type: 3,
                    index: activePage,
                    item_per_page: itemsForPage,
                }
            })
            if (data.data.status === 10000) {
                //Sort by date create
                const sortByDateCreate = data.data.data;

                //filter count items pagination
                // const index = page === 1 ? 0 :  (activePage - 1) * itemsForPage;
                // const filterCountItems = sortByDateCreate.slice(index, index + itemsForPage);

                setCrrData(sortByDateCreate);
                setLetterRows(data.data.count);
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const getStaffShift = async () => {
        try {
            const data = await axios({
                method: 'GET',
                url: `${box_engine_cf.crrApiSocket}/api/staff/get_by_id/${userData.id_staff}`,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': token,
                }
            })
            if (data.data.status === 10000) {
                setStaffShift(data.data.data.staff_shift);
            }
        } catch (error) {
            console.log(error);
        }
    }
    const handleOpenModal = (id) => {
        if (id) {
            const data = crrData.filter(filter => filter.id === id)[0];
            const supervisor = {
                id: data.supervisor.id,
                name: `${data.supervisor.name} - ${data.supervisor.department.name} (${data.supervisor.id_staff})`,
            }
            setDataLetter({
                id: data.id,
                start_date: data.start_date,
                end_date: data.end_date,
                supervisor: supervisor,
                work_content: data.description ? JSON.parse(data.description).work_content : data.description,
                work_place: data.description ? JSON.parse(data.description).work_place : data.description,
                status: data.status,
            });
        } else {
            setDataLetter(initial);
        }
        setShowModal(true);
    }
    const closeModal = () => {
        setShowModal(false);
    }

    const handleDeleteForId = (id, name) => {
        swal({
            title: language[indexLanguage].Text_Notifi.are_you_sure,
            text: language[indexLanguage].Text_Notifi.notifi_sure_delete_daysoff_form,
            icon: "warning",
            buttons: {
                oke: language[indexLanguage].textButton.oke,
                cancel: language[indexLanguage].textButton.close
            },
        })
            .then(async (willDelete) => {
                if (willDelete === 'oke') {
                    const data = await axios({
                        method: 'GET',
                        url: `${box_engine_cf.crrApiSocket}/api/daysoff_form/delete/${id}`,
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': token,
                        },
                    })
                    if (data.data.status === 10000) {
                        ReactGA.event({
                            category: 'Danh sach don cong tac',
                            action: 'Xoa don',
                        });
                        swal(language[indexLanguage].Text_Notifi.success, language[indexLanguage].Text_Notifi.notifi_delete_success, "success", {
                            buttons: false,
                            timer: 1500,
                        });
                        searchFilter(activePage);
                        setShowModal(false);
                    } else {
                        swal(language[indexLanguage].Text_Notifi.error, language[indexLanguage].Text_Notifi.notifi_delete_false, "error");
                    }
                }
            });
    }

    const checkStatus = (status) => {
        switch (status) {
            case 1: return <span style={{ fontWeight: 'bold' }}>{language[indexLanguage].DayOff.draff}</span>
            case 2: return <span style={{ color: 'orange', fontWeight: 'bold' }}>{language[indexLanguage].DayOff.pending}</span>
            case 3: return <span style={{ color: 'green', fontWeight: 'bold' }}>{language[indexLanguage].DayOff.accept}</span>
            case 4: return <span style={{ color: 'red', fontWeight: 'bold' }}>{language[indexLanguage].DayOff.refused}</span>

            default:
                break;
        }
    }

    const handleChangePage = (pageNumber) => {
        const index = (pageNumber - 1) * itemsForPage;
        setOffset(index)
        // const filterCountItems = letterRows.slice(index, index + itemsForPage);

        // setCrrData(filterCountItems);

        setDataSearch({
            ...dataSearch, index: pageNumber,
        })
        setActivePage(pageNumber);
    }

    useEffect(() => {
        searchFilter(activePage);
    }, [activePage])


    const handleChangeSearch = (event, value, name) => {
        if (name === 'arr_status') {
            return setDataSearch({
                ...dataSearch, arr_status: value.value,
            })
        }
        if (name === 'start_date') {
            return setDataSearch({
                ...dataSearch, [name]: Moment(value).format('YYYY-MM-DD'),
            })
        }
        if (name === 'end_date') {
            return setDataSearch({
                ...dataSearch, [name]: Moment(value).format('YYYY-MM-DD') + ' 23:59:59',
            })
        }
    }
    const refreshFilter = () => {
        searchFilter(1, initialSearch)
        setStatusCurrent(status[0]);
        setDataSearch(initialSearch);
        setFromDate(new Date(from_date));
        setToDate(new Date(to_date));
        setActivePage(1);
    }
    const searchFilter = async (page, data) => {
        try {
            const result = await axios({
                method: 'POST',
                url: `${box_engine_cf.crrApiSocket}/api/daysoff_form/search`,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                data: data ? data : dataSearch,
            })

            if (result.data.status === 10000) {
                //Sort by date create
                const sortByDateCreate = result.data.data;

                //filter count items pagination
                const index = (activePage - 1) * itemsForPage;
                setOffset(index)
                // const filterCountItems = sortByDateCreate.length > 10 ? sortByDateCreate.slice(index, index + itemsForPage) : sortByDateCreate;

                setCrrData(sortByDateCreate);
                setLetterRows(result.data.count);
                setLoading(false);
                setActivePage(page);
            }
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        //Call API to fetch Data
        if (box_engine_cf) {
            getReasonOff();
            getDataListSupervisor();
            searchFilter(1);
            getStaffShift();
        }
        // Check Super Admin Login
        if (userData === null) {
            localStorage.setItem('data_user', JSON.stringify({ id_staff: '', id_department: '', id_staff_notification: '' }));
        }
    }, [box_engine_cf]);

    function createTableRow() {
        let tableRows = crrData && crrData.map((value, index) => {
            const work_content = value.description ? JSON.parse(value.description).work_content : '';
            return (
                <tr key={index}>
                    <td>{index + offset + 1}</td>
                    <td>{Moment.utc(value.create_time).tz('Asia/Ho_Chi_Minh').format("DD-MM-YYYY HH:mm")}</td>
                    <td>{Moment(value.start_date).format('DD-MM-YYYY HH:mm')}</td>
                    <td>{Moment(value.end_date).format('DD-MM-YYYY HH:mm')}</td>
                    <td style={{ whiteSpace: 'break-spaces' }}>{work_content}</td>
                    <td>{value.supervisor.name}</td>
                    <td>{checkStatus(value.status)}</td>
                    <td>
                        <button onClick={() => handleOpenModal(value.id)} className="m-portlet__nav-link btn m-btn m-btn--hover-warning m-btn--icon m-btn--icon-only m-btn--pill" data-tip data-for='View'>
                            <i className="la la-eye" />
                        </button>
                        <ReactTooltip id='View' type='dark' effect='solid'>
                            <span>{language[indexLanguage].tooltip.open_detail}</span>
                        </ReactTooltip>
                        {(value.status === 2 || value.status === 1) &&
                            <>
                                <button onClick={() => handleDeleteForId(value.id)} className="m-portlet__nav-link btn m-btn m-btn--hover-warning m-btn--icon m-btn--icon-only m-btn--pill" data-tip data-for='Delete'>
                                    <i className="la la-trash" />
                                </button>
                                <ReactTooltip id='Delete' type='dark' effect='solid'>
                                    <span>{language[indexLanguage].tooltip.open_delete}</span>
                                </ReactTooltip>
                            </>
                        }
                    </td>
                </tr>
            );
        });
        return tableRows;
    }
    return (
        <>
            <div className="row-sm row m-portlet__body mb-md-5 mb-3" style={portletBodyCss}>
                <div className="col-xl-1 pl-xl-0 my-1">
                    <DatePicker
                        className="form-control m-input m_datetimepicker_6"
                        selected={fromDate}
                        onChange={(date) => {
                            handleChangeSearch('', date, 'start_date');
                            return setFromDate(date);
                        }}
                        dateFormat="dd-MM-yyyy"
                        maxDate={toDate}
                    />
                </div>
                <div className="col-xl-1 my-1">
                    <DatePicker
                        className="form-control m-input m_datetimepicker_6"
                        selected={toDate}
                        onChange={(date) => {
                            handleChangeSearch('', date, 'end_date');
                            return setToDate(date);
                        }}
                        dateFormat="dd-MM-yyyy"
                        minDate={fromDate}
                    />
                </div>
                <Autocomplete
                    className="select_option col-xl-2 my-1"
                    options={status}
                    onChange={(event, value) => {
                        handleChangeSearch(event, value, 'arr_status')
                        return setStatusCurrent(value);
                    }}
                    getOptionLabel={option => typeof option === 'string' ? option : option.name}
                    value={statusCurrent}
                    disableClearable
                    renderInput={params => (<TextField {...params} variant="outlined" />)}
                />
                <div className="col-xl-3 my-1">
                    <button type="button" className="btn btn-accent m-btn m-btn--icon mr-3" onClick={() => {
                        ReactGA.event({
                            category: 'Danh sach don cong tac',
                            action: 'Tim kiem',
                        });
                        searchFilter(1)
                        setDataSearch({
                            ...dataSearch, index: 1,
                        })
                    }} style={actionButtonStyle}>
                        <span>
                            <i className="la la-search" />
                            <span>{language[indexLanguage].textButton.search}</span>
                        </span>
                    </button>
                    <button type="button" className="btn btn-secondary m-btn m-btn--icon" onClick={() => {
                        setDataSearch(initialSearch);
                        refreshFilter()
                    }} style={actionButtonStyle}>
                        <span>
                            <i className="la la-rotate-left" />
                            <span>{language[indexLanguage].textButton.reload}</span>
                        </span>
                    </button>
                </div>
                <div className="col-xl-2 offset-xl-3 text-right mt-md-0 mt-5">
                    <button type="button" className="btn btn-accent m-btn m-btn--custom m-btn--icon m-btn--pill m-btn--air" onClick={() => handleOpenModal()} style={actionButtonStyle}>
                        <span>
                            <i className="la la-plus"></i>
                            <span>{language[indexLanguage].textButton.add}</span>
                        </span>
                    </button>
                </div>
            </div>
            <div className="row-sm row m-portlet__body" style={portletBodyCss}>
                <div className="table-responsive text-nowrap">
                    <table className="table table-bordered table-hover table-checkable dataTable no-footer dtr-inline collapsed">
                        <thead>
                            <tr>
                                <th style={{ 'verticalAlign': 'middle', 'width': '50px' }}>{language[indexLanguage].Other.no}</th>
                                <th style={{ 'verticalAlign': 'middle' }}>{language[indexLanguage].Business.create_date}</th>
                                <th style={{ 'verticalAlign': 'middle' }}>{language[indexLanguage].LongTerm.date_from}</th>
                                <th style={{ 'verticalAlign': 'middle' }}>{language[indexLanguage].LongTerm.date_to}</th>
                                <th style={{ 'verticalAlign': 'middle' }}>{language[indexLanguage].LongTerm.description}</th>
                                <th style={{ 'verticalAlign': 'middle' }}>{language[indexLanguage].Business.supervisor}</th>
                                <th style={{ 'verticalAlign': 'middle' }}>{language[indexLanguage].Business.status}</th>
                                <th style={{ 'verticalAlign': 'middle', width: '120px' }}>{language[indexLanguage].Business.action}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {createTableRow()}
                        </tbody>
                    </table>
                    <Pagination
                        firstPageText={
                            language[indexLanguage].pagination.first
                        }
                        nextPageText={
                            language[indexLanguage].pagination.next
                        } start_date
                        lastPageText={
                            language[indexLanguage].pagination.last
                        }
                        prevPageText={
                            language[indexLanguage].pagination.previous
                        }
                        activePage={activePage}
                        itemsCountPerPage={itemsForPage}
                        totalItemsCount={totalItems}
                        pageRangeDisplayed={range}
                        onChange={handleChangePage}
                    />
                    <PulseLoader
                        css={override}
                        sizeUnit={"px"}
                        size={12}
                        margin={'2px'}
                        color={'#36D7B7'}
                        loading={loading}
                    />
                </div>
            </div>
            <LeaveOfAbsenceForm show={showModal} ndo_rest={ndoRest} data={dataLetter} staff_shift={staffShift} reason_off={reason} onDelete={handleDeleteForId} supervisor={supervisor} onHide={closeModal} onRefresh={searchFilter} />
        </>
    );
}

export default LeaveOfAbsenceLetterTableTab;