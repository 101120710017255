import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import Switch from "react-switch";
import { HOST_CLOUD, HOST } from '../../../../config';
import swal from 'sweetalert';
import Store from '../../../../store';
import $ from 'jquery';
import Select from "react-select";
import language from '../../../../language/language';
import ReactGA from 'react-ga';

import { connect } from 'react-redux'
class ModalLevel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            language: Store.getState().changelanguage.language,
            indexlanguage: 0,
            crrLevel: {
                name: "",
                description: "",
                level: "",
            },
        }
        Store.subscribe(() => {
            this.setState({
                language: Store.getState().changelanguage.language,
            });
        });
    }


    updateLevel = async (dataLevel, event) => {
        dataLevel.name.trim()
        var form = $("#formAddEngine")
        if (form[0].checkValidity() === false) {
            event.preventDefault()
            event.stopPropagation()
            form.addClass('was-validated')
        } else {
            //Insert
            $('#button_addLevel').addClass('m-loader');
            $('#button_addLevel').attr('disabled', true);
            if (dataLevel.id == undefined) {
                let data = await fetch(`${dataLevel.HOST}/api/level/insert`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': Store.getState().isLogin.access_token
                    },
                    body: JSON.stringify(
                        dataLevel,
                        dataLevel.level = 1)
                }).then((response) => {
                    return response.json()
                });
                console.log(data)
                if (data.status === 10000) {
                    ReactGA.event({
                        category: 'Chuc vu',
                        action: 'Them chuc vu',
                      });
                    swal(language[this.props.indexLanguage].Text_Notifi.success, language[this.props.indexLanguage].Text_Notifi.add_success, "success", {
                        buttons: false,
                        timer: 1500,
                    });
                    return this.props.onHide();
                } else if(data.status === 10001){
                    $('#button_addLevel').removeClass('m-loader');
                    $('#button_addLevel').removeAttr('disabled', true);
                    swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].Text_Notifi.notifi_name_same, "warning");
                } else {
                    $('#button_addLevel').removeClass('m-loader');
                    $('#button_addLevel').removeAttr('disabled', true);
                    swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].Text_Notifi.add_false, "error");
                }
            } else {
                //Update
                $('#button_addLevel').addClass('m-loader');
                $('#button_addLevel').attr('disabled', true);
                let data = await fetch(`${dataLevel.HOST}/api/level/update`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': Store.getState().isLogin.access_token
                    },
                    body: JSON.stringify(
                        dataLevel,
                        dataLevel.level = 1
                         )
                }).then((response) => {
                    return response.json()
                });
                if (data.status === 10000) {
                    ReactGA.event({
                        category: 'Chuc vu',
                        action: 'Sua chuc vu',
                      });
                    swal(language[this.props.indexLanguage].Text_Notifi.success, language[this.props.indexLanguage].Text_Notifi.edit_success, "success", {
                        buttons: false,
                        timer: 1500,
                    });
                    return this.props.onHide();
                } else if(data.status === 10001){
                    $('#button_addLevel').removeClass('m-loader');
                    $('#button_addLevel').removeAttr('disabled', true);
                    swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].Text_Notifi.notifi_name_same, "warning");
                } else {
                    $('#button_addLevel').removeClass('m-loader');
                    $('#button_addLevel').removeAttr('disabled', true);
                    swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].Text_Notifi.edit_false, "error");
                }
            }

        }
    }


    LevelHandle(e) {
        var dataLevel = this.state.crrLevel;
        dataLevel[e.target.name] = e.target.value;
        this.setState({ crrLevel: dataLevel });
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.show === true) {
            this.setState({
                crrLevel: nextProps.data,
            });
        }
    }

    handleEnter = (event) => {
        if (event.keyCode === 13) {
            const form = event.target.form;
            const index = Array.prototype.indexOf.call(form, event.target);
            form.elements[index + 1].focus();
            event.preventDefault();
        }
    }

    render() {
        let click_handle = (event) => {
            this.updateLevel(this.state.crrLevel, event);
        }

        return (
            <Modal
                {...this.props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form id="formAddEngine">
                        <div className="col-xl-12">
                            <div className="m-widget14">
                                <div className="form-group m-form__group">
                                    <label htmlFor="Name">{language[this.props.indexLanguage].textTable.name} <span className="text-danger">* </span></label>
                                    <input className="form-control m-input" id="Name" name='name' value={this.state.crrLevel.name === '' ? '' : this.state.crrLevel.name} onKeyDown={(event) => this.handleEnter(event)} onChange={e => this.LevelHandle(e)} placeholder={language[this.props.indexLanguage].textTable.name} required />
                                </div>
                                <div className="form-group m-form__group">
                                    <label htmlFor="Description">{language[this.props.indexLanguage].textTable.description}</label>
                                    <textarea rows="4" className="form-control m-input" id="Description" value={this.state.crrLevel.description === '' ? '' : this.state.crrLevel.description} name='description' onChange={e => this.LevelHandle(e)} placeholder={language[this.props.indexLanguage].textTable.description} />
                                </div>
                                {/* <div className="form-group m-form__group">
                                    <label htmlFor="Level">{language[this.props.indexLanguage].textTable.level} <span className="text-danger">* </span></label>
                                    <input type="number" min="1" className="form-control m-input" id="Level" name='level' value={this.state.crrLevel.level === '' ? '' : this.state.crrLevel.level} onChange={e => this.LevelHandle(e)} placeholder={language[this.props.indexLanguage].textTable.level} required />
                                </div> */}
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="accent" id="button_addLevel" className="m-loader--light m-loader--right" onClick={click_handle}>{language[this.props.indexLanguage].textButton.save}</Button>
                    <Button variant="secondary" onClick={this.props.onHide}>{language[this.props.indexLanguage].textButton.close}</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

ModalLevel.propTypes = {
    data: PropTypes.object,
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool,
}
const mapStateToProps = state => {
    return {
        indexLanguage: state.changelanguage.indexLanguage,
    }
}
export default connect(mapStateToProps, null)(ModalLevel);