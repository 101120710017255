import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import Switch from "react-switch";
import swal from 'sweetalert';
import Store from '../../../../store';
import $ from 'jquery';
import Select from "react-select";
import makeAnimated from 'react-select/animated';
// import DatePicker from "react-datepicker";
import Moment from 'moment';
import { NavLink } from 'react-router-dom';
// import "react-datepicker/dist/react-datepicker.css";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
// import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import language from '../../../../language/language';
import vi from 'date-fns/locale/vi';
import DateFnsUtils from '@date-io/date-fns';
import ReactGA from 'react-ga';

import { connect } from 'react-redux'
import { formatTitleOption } from '../../CommonFunction';

const animatedComponents = makeAnimated();



function sortData(a, b) {
    if (a.label > b.label) {
        return 1
    } else {
        if (a.label < b.label) {
            return -1;
        }
        else {
            return 0
        }
    }
}

class ModalStaff extends Component {

    constructor(props) {
        super(props);
        this.state = {
            language: Store.getState().changelanguage.language,
            box_engine_cf: Store.getState().boxai.box_engine_cf,
            listEngine: [],
            optionDepartment: [],
            valueShift: [],
            optionRole: [],
            listCheckOperation: [],
            valueDepartment: null,
            valueLevel: null,
            valuRole: null,
            isChecked: false,
            checkedMale: true,
            checkedFemale: false,
            checkedBD: false,
            readOnly: false,
            goToCapture: false,
            crrStaff: null,
            crrBirthday: '',
            crrStartWork: '',
            crrOfficialWork: '',
            crrResignationDate: '',
            location: '',
            errorDateBirthDay: {
                value: '',
                error: ''
            },
            is_active: '',
            isBirthday: false,
            isEdit: false,
            isView: false,
        }
        Store.subscribe(() => {
            this.setState({
                language: Store.getState().changelanguage.language,
                box_engine_cf: Store.getState().boxai.box_engine_cf,
            });
        });
    }


    updateStaff = async (dataStaff, event) => {
        //Insert
        dataStaff.id_staff.trim()
        dataStaff.abbreviated_name.trim()
        dataStaff.name.trim()
        var form = $("#formUpdateStaff");
        let is_birthday = dataStaff.birthday;

        const is_department = dataStaff.id_department;
        const is_role = dataStaff.id_role;
        const is_shift = dataStaff.ids_shift ? dataStaff.ids_shift.length : 0;
        dataStaff['email'] = dataStaff['email'] ? dataStaff['email'] : '';

        const check_edit_is_birthday = this.state.isEdit ? false : !is_birthday ? true : false;
        if (form[0].checkValidity() === false || check_edit_is_birthday || false || !is_role || !is_shift) {
            event.preventDefault()
            event.stopPropagation()
            form.addClass('was-validated')
            if (!is_birthday) {
                this.setState({
                    isBirthday: true,
                })
            }
            this.setState({
                isDepartment: !is_department,
                isRole: !is_role,
                isShift: !is_shift,
            })
        } else {
            if (this.state.errorDateBirthDay.error !== "") {
                swal(language[this.props.indexLanguage].Text_Notifi.error, this.state.errorDateBirthDay.error, "error");
                return
            }
            this.setState({
                isBirthday: false,
                isDepartment: false,
                isRole: false,
                isShift: false,
            })
            if (dataStaff.id == undefined) {

                $('#button_addStaff').addClass('m-loader');
                $('#button_addStaff').attr('disabled', true);
                let data = await fetch(`${dataStaff.HOST}/api/staff/insertOrUpdate`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': Store.getState().isLogin.access_token
                    },
                    body: JSON.stringify(dataStaff)
                }).then((response) => {
                    return response.json()
                });
                if (data.status === 10000) {
                    ReactGA.event({
                        category: 'Nhan vien',
                        action: 'Them moi nhan vien',
                    });
                    swal(language[this.props.indexLanguage].Text_Notifi.success, language[this.props.indexLanguage].Text_Notifi.add_success, "success", {
                        buttons: false,
                        timer: 1500,
                    });
                    $('#button_addStaff').removeClass('m-loader');
                    $('#button_addStaff').removeAttr('disabled', true);
                    const { crrStaff } = this.state;

                    crrStaff['id_staff'] = data.data.id_staff;
                    crrStaff['id'] = data.data.id;
                    console.log(crrStaff, data)
                    this.setState({
                        crrStaff: crrStaff,
                        goToCapture: true,
                        location: {
                            pathname: '/capture',
                            state: { idStaff: data.data.id, department: { title: data.data.department ? data.data.department.name : '', value: data.data.department ? data.data.department.id : null } }
                        }
                    });
                    // return this.props.onHide();
                } else if (data.status === 10001) {
                    $('#button_addStaff').removeClass('m-loader');
                    $('#button_addStaff').removeAttr('disabled', true);
                    swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].Text_Notifi.id_staff_already_exists, "warning");
                } else if (data.status === 10005) {
                    $('#button_addStaff').removeClass('m-loader');
                    $('#button_addStaff').removeAttr('disabled', true);
                    swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].Text_Notifi.notifi_full_limit_staff, "warning");
                } else if (data.status === 10006) {
                    $('#button_addStaff').removeClass('m-loader');
                    $('#button_addStaff').removeAttr('disabled', true);
                    swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].Text_Notifi.notifi_validate_phone, "warning");
                } else if (data.status === 10007) {
                    $('#button_addStaff').removeClass('m-loader');
                    $('#button_addStaff').removeAttr('disabled', true);
                    swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].Text_Notifi.notifi_validate_email, "warning");
                } else {
                    $('#button_addStaff').removeClass('m-loader');
                    $('#button_addStaff').removeAttr('disabled', true);
                    swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].Text_Notifi.add_false, "error");
                }
            } else {
                $('#button_addStaff').addClass('m-loader');
                $('#button_addStaff').attr('disabled', true);
                let data = await fetch(`${dataStaff.HOST}/api/staff/insertOrUpdate`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': Store.getState().isLogin.access_token
                    },
                    body: JSON.stringify(dataStaff)
                }).then((response) => {
                    return response.json()
                });
                if (data.status === 10000) {
                    ReactGA.event({
                        category: 'Nhan vien',
                        action: 'Sua nhan vien',
                    });
                    swal(language[this.props.indexLanguage].Text_Notifi.success, language[this.props.indexLanguage].Text_Notifi.edit_success, "success", {
                        buttons: false,
                        timer: 1500,
                    });
                    $('#button_addStaff').removeClass('m-loader');
                    $('#button_addStaff').removeAttr('disabled', true);
                    // return this.props.onHide();
                } else if (data.status === 10001) {
                    $('#button_addStaff').removeClass('m-loader');
                    $('#button_addStaff').removeAttr('disabled', true);
                    swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].Text_Notifi.id_staff_already_exists, "warning");
                } else if (data.status === 10005) {
                    $('#button_addStaff').removeClass('m-loader');
                    $('#button_addStaff').removeAttr('disabled', true);
                    swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].Text_Notifi.notifi_full_limit_staff, "warning");
                } else if (data.status === 10006) {
                    $('#button_addStaff').removeClass('m-loader');
                    $('#button_addStaff').removeAttr('disabled', true);
                    swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].Text_Notifi.notifi_validate_phone, "warning");
                } else if (data.status === 10007) {
                    $('#button_addStaff').removeClass('m-loader');
                    $('#button_addStaff').removeAttr('disabled', true);
                    swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].Text_Notifi.notifi_validate_email, "warning");
                } else {
                    $('#button_addStaff').removeClass('m-loader');
                    $('#button_addStaff').removeAttr('disabled', true);
                    swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].Text_Notifi.edit_false, "error");
                }
            }
        }
    }

    StaffHandle(e) {
        var StaffHand = this.state.crrStaff;
        StaffHand[e.target.name] = e.target.value;
        this.setState({ crrStaff: StaffHand });
    }

    handleChangeDepartment(value) {
        var StaffHand = this.state.crrStaff;
        value.label = value.label.replace("┗━", "").trim();
        StaffHand['id_department'] = value.value
        this.setState({
            valueDepartment: value,
            crrStaff: StaffHand,
            isDepartment: false,
        })
    }

    handleChangeLevel(value) {
        var StaffHand = this.state.crrStaff;
        StaffHand['id_level'] = value.value
        this.setState({
            valueLevel: value,
            crrStaff: StaffHand
        })
    }

    handleChangeRole(value) {
        var StaffHand = this.state.crrStaff;
        StaffHand['id_role'] = value.value
        this.setState({
            crrStaff: StaffHand,
            isRole: false,
        })
    }

    handleChangeBirthday = dateBirthDay => {
        var StaffHand = this.state.crrStaff;
        let is_birthday = false;
        if (dateBirthDay !== null) {
            StaffHand['birthday'] = Moment(dateBirthDay).format("YYYY-MM-DD");
        } else {
            StaffHand['birthday'] = null;
            is_birthday = true
        }
        this.setState({
            crrBirthday: dateBirthDay,
            crrStaff: StaffHand,
            isBirthday: is_birthday,
        });
    }

    configGetByIDCompany = (Data) => {
        if (Data.HOST == undefined) return;
        fetch(`${Data.HOST}/api/config/get_info/${this.state.box_engine_cf.idCompany}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                if (data.data.data.id_staff_auto_increase === 1) {
                    this.setState({
                        readOnly: true,
                    })
                } else {
                    this.setState({
                        readOnly: false,
                    })
                }
            }
        })
    }


    handleChangeOfficialWork = dateOfficialWork => {
        var StaffHand = this.state.crrStaff;
        if (dateOfficialWork !== null) {
            StaffHand['start_official_work'] = Moment(dateOfficialWork).format("YYYY-MM-DD");
        } else {
            StaffHand['start_official_work'] = null
        }
        this.setState({
            crrOfficialWork: dateOfficialWork,
            crrStaff: StaffHand
        });
    }
    handleChangeStartWork = dateStartWork => {
        var StaffHand = this.state.crrStaff;
        if (dateStartWork !== null) {
            StaffHand['start_work'] = Moment(dateStartWork).format("YYYY-MM-DD");
        } else {
            StaffHand['start_work'] = null
        }
        this.setState({
            crrStartWork: dateStartWork,
            crrStaff: StaffHand
        });
    }

    handleChangeResignationDate = dateResignationDate => {
        var StaffHand = this.state.crrStaff;
        if (dateResignationDate !== null) {
            StaffHand['end_official_work'] = Moment(dateResignationDate).format("YYYY-MM-DD");
            StaffHand['is_active'] = 0;
        } else {
            StaffHand['end_official_work'] = null;
            StaffHand['is_active'] = 1;
        }
        this.setState({
            crrResignationDate: dateResignationDate,
            crrStaff: StaffHand,
        });
    }





    staffGetById = (Data) => {
        if (Data.HOST == undefined) return;
        fetch(`${Data.HOST}/api/staff/get_by_id/${Data.id}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                //Level
                var valueLevel = null;
                if (data.data.level !== null) {
                    valueLevel = { 'value': data.data.level.id, 'label': data.data.level.name };
                }
                //Department
                var valueDepartment = null;
                if (data.data.department !== null) {
                    valueDepartment = { 'value': data.data.department.id, 'label': data.data.department.name };
                }
                //Shift
                var valueShift = [];
                var id_Shift = [];
                if (data.data.staff_shift.length > 0) {
                    for (let index = 0; index < data.data.staff_shift.length; index++) {
                        valueShift.push({ 'value': data.data.staff_shift[index].shifts_work.id, 'label': data.data.staff_shift[index].shifts_work.name + ' (' + Moment(data.data.staff_shift[index].shifts_work.time_in, "H:mm").format("HH:mm") + ' - ' + Moment(data.data.staff_shift[index].shifts_work.time_out, "H:mm").format("HH:mm") + ')', 'expire_timein': data.data.staff_shift[index].shifts_work.expire_timein, 'expire_timeout_to': data.data.staff_shift[index].shifts_work.expire_timeout_to, 'weekdays': data.data.staff_shift[index].shifts_work.day_of_week })
                        id_Shift.push(data.data.staff_shift[index].shifts_work.id);
                    }
                }
                var crrStaff = Data;
                crrStaff['ids_shift'] = id_Shift
                this.setState({
                    valueLevel: valueLevel,
                    valueDepartment: valueDepartment,
                    crrStaff: crrStaff,
                    valueShift: valueShift,
                });
            }
        })
    }




    changeHandleShift = (valuedata) => {
        function checkGiao(addShift, existShifts) {
            if (existShifts.length == 0) {
                return false
            }
            if (existShifts.every(every => {
                return addShift.expire_timeout_to < every.expire_timein || addShift.expire_timein > every.expire_timeout_to
            })) {
                return false
            }
            return true
        }

        var crrStaff = this.state.crrStaff;
        var listShift = []
        var weekdays = [[], [], [], [], [], [], []]

        if (valuedata == null) {
            listShift = []
        } else {

            var crrStaff = this.state.crrStaff;
            var listShift = []
            if (valuedata == null) {
                listShift = []
            } else {
                for (let index = 0; index < valuedata.length; index++) {
                    var shift = valuedata[index]
                    var a = shift.weekdays.slice(1, 14).split('|')
                    for (let i = 0; i < a.length; i++) {
                        if (a[i] == 1) {
                            if (checkGiao(shift, weekdays[i]) == false) {
                                weekdays[i].push(shift)
                            } else {
                                swal(language[this.props.indexLanguage].Text_Notifi.warning, language[this.props.indexLanguage].Text_Notifi.notifi_warning_change_shift, "warning");
                                return;
                            }

                        }
                    }
                    listShift.push(valuedata[index].value)
                }
            }

            crrStaff['ids_shift'] = listShift
            this.setState({
                crrStaff: crrStaff,
                valueShift: valuedata,
                isShift: false,
            })
        }
        crrStaff['ids_shift'] = listShift
        this.setState({
            crrStaff: crrStaff,
            valueShift: valuedata,
            isShift: false,
        })
    }

    handleEnter = (event) => {
        if (event.keyCode === 13) {
            const form = event.target.form;
            const index = Array.prototype.indexOf.call(form, event.target);
            form.elements[index + 1].focus();
            event.preventDefault();
        }
    }

    getRole = (Data, checkModal) => {
        if (Data.HOST == undefined) return;
        fetch(`${Data.HOST}/api/role/${this.state.box_engine_cf.idCompany}/get_by_active`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            //Role
            if (data.status === 10000) {
                var crrStaff = this.state.crrStaff
                var optionRole = [];
                for (let index = 0; index < data.data.length; index++) {
                    optionRole.push({ 'value': data.data[index].id, 'label': data.data[index].name })
                    if (checkModal === false) {
                        if (data.data[index].is_default === 1) {
                            crrStaff['id_role'] = data.data[index].id
                        }
                    }
                }
                var dataOptionSort = optionRole.sort(sortData);
                this.setState({
                    optionRole: dataOptionSort,
                    crrStaff: crrStaff
                });
            }
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.show === true) {
            if (nextProps.data.modelCheck == false) {
                //Department
                var optionDepartment = [];
                var valueDepartment = null;
                var id_Department = null;
                if (nextProps.listdepartment.length > 0) {
                    for (let index = 0; index < nextProps.listdepartment.length; index++) {
                        optionDepartment.push({ 'value': nextProps.listdepartment[index].id, 'label': nextProps.listdepartment[index].name, 'code': nextProps.listdepartment[index].code })
                    }
                    id_Department = nextProps.listdepartment[0].id;
                    valueDepartment = { 'value': nextProps.listdepartment[0].id, 'label': nextProps.listdepartment[0].name, 'code': nextProps.listdepartment[0].code };
                }

                var dataOptionSortDepartment = optionDepartment;//.sort(sortData);

                //Level
                var optionLevel = [];
                var valueLevel = null
                var id_level = null;
                if (nextProps.listlevel.length > 0) {
                    for (let index = 0; index < nextProps.listlevel.length; index++) {
                        optionLevel.push({ 'value': nextProps.listlevel[index].id, 'label': nextProps.listlevel[index].name })
                    }
                    id_level = nextProps.listlevel[0].id;
                    valueLevel = { 'value': nextProps.listlevel[0].id, 'label': nextProps.listlevel[0].name };
                }

                var dataOptionSortLevel = optionLevel.sort(sortData);

                //Shift
                var optionShift = [];
                var valueShift = [];
                var id_Shift = [];
                if (nextProps.listshift.length > 0) {
                    for (let index = 0; index < nextProps.listshift.length; index++) {
                        optionShift.push({ 'value': nextProps.listshift[index].id, 'label': nextProps.listshift[index].name + ' (' + Moment(nextProps.listshift[index].time_in, "H:mm").format("HH:mm") + ' - ' + Moment(nextProps.listshift[index].time_out, "H:mm").format("HH:mm") + ')', 'expire_timein': nextProps.listshift[index].expire_timein, 'expire_timeout_to': nextProps.listshift[index].expire_timeout_to, 'weekdays': nextProps.listshift[index].day_of_week })
                    }
                    id_Shift = [nextProps.listshift[0].id];
                    valueShift = { 'value': nextProps.listshift[0].id, 'label': nextProps.listshift[0].name + ' (' + Moment(nextProps.listshift[0].time_in, "H:mm").format("HH:mm") + ' - ' + Moment(nextProps.listshift[0].time_out, "H:mm").format("HH:mm") + ')', 'expire_timein': nextProps.listshift[0].expire_timein, 'expire_timeout_to': nextProps.listshift[0].expire_timeout_to, 'weekdays': nextProps.listshift[0].day_of_week };
                }
                var dataOptionSortShift = optionShift.sort(sortData);

                var StaffHand = nextProps.data;
                StaffHand['id_level'] = id_level
                StaffHand['id_department'] = id_Department
                StaffHand['ids_shift'] = id_Shift
                StaffHand['id_company'] = StaffHand.idCompany
                this.setState({
                    goToCapture: false,
                    crrStaff: StaffHand,
                    optionDepartment: dataOptionSortDepartment,
                    valueDepartment: valueDepartment,
                    optionLevel: dataOptionSortLevel,
                    valueLevel: valueLevel,
                    optionShift: dataOptionSortShift,
                    valueShift: valueShift,
                });
                this.configGetByIDCompany(nextProps.data)
                this.getRole(nextProps.data, nextProps.data.modelCheck)
            } else {
                var optionDepartment = [];
                if (nextProps.listdepartment.length > 0) {
                    for (let index = 0; index < nextProps.listdepartment.length; index++) {
                        optionDepartment.push({ 'value': nextProps.listdepartment[index].id, 'label': nextProps.listdepartment[index].name, 'code': nextProps.listdepartment[index].code })
                    }
                }

                var dataOptionSortDepartment = optionDepartment;//.sort(sortData);

                //Level
                var optionLevel = [];
                if (nextProps.listlevel.length > 0) {
                    for (let index = 0; index < nextProps.listlevel.length; index++) {
                        optionLevel.push({ 'value': nextProps.listlevel[index].id, 'label': nextProps.listlevel[index].name, 'code': nextProps.listlevel[index].code })
                    }
                }

                var dataOptionSortLevel = optionLevel.sort(sortData);

                //Shift
                var optionShift = [];
                if (nextProps.listshift.length > 0) {
                    for (let index = 0; index < nextProps.listshift.length; index++) {
                        var expire_timein = nextProps.listshift[index].expire_timein
                        var expire_timeout_to = nextProps.listshift[index].expire_timeout_to
                        if (expire_timein == "") {
                            expire_timein = "00:00:01"
                        }
                        if (expire_timeout_to == "") {
                            expire_timeout_to = "23:59:59"

                        }
                        optionShift.push({
                            'value': nextProps.listshift[index].id,
                            'label': nextProps.listshift[index].name + ' (' + Moment(nextProps.listshift[index].time_in, "H:mm").format("HH:mm") + ' - ' + Moment(nextProps.listshift[index].time_out, "H:mm").format("HH:mm") + ')',
                            'expire_timein': expire_timein,
                            'expire_timeout_to': expire_timeout_to,
                            'weekdays': nextProps.listshift[index].day_of_week,
                            'allshift': nextProps.listshift
                        })
                    }
                }

                var dataOptionSortShift = optionShift.sort(sortData);

                var StaffHand = nextProps.data;
                StaffHand['id_company'] = StaffHand.idCompany;

                const crrDepartment = nextProps.listdepartment.find(e => e.id === nextProps.data.id_department);
                this.setState({
                    goToCapture: true,
                    crrStaff: StaffHand,
                    optionDepartment: dataOptionSortDepartment,
                    optionLevel: dataOptionSortLevel,
                    optionShift: dataOptionSortShift,
                    location: {
                        pathname: '/capture',
                        state: { idStaff: nextProps.data.id, department: { title: crrDepartment ? crrDepartment.name : '', value: crrDepartment ? crrDepartment.id : '' } }
                    }
                });
                this.configGetByIDCompany(nextProps.data)
                this.getRole(nextProps.data, nextProps.data.modelCheck)
                this.staffGetById(nextProps.data)
            }
            this.setState({
                isEdit: nextProps.isEdit ? true : false,
                isView: nextProps.isView,
            })
        } else {
            this.setState({
                isBirthday: false,
                isEdit: false,
                isView: false,
                isDepartment: false,
                isRole: false,
                isShift: false,
            })
        }

    }


    render() {
        let click_handle = (event) => {
            var Staff = { ...this.state.crrStaff };

            Staff['start_work'] = Staff['start_work'] ? Moment(Staff['start_work']).format('YYYY-MM-DD 00:00:00') : Staff['start_work'];
            Staff['start_official_work'] = Staff['start_official_work'] ? Moment(Staff['start_official_work']).format('YYYY-MM-DD 00:00:00') : Staff['start_official_work'];
            Staff['end_official_work'] = Staff['end_official_work'] ? Moment(Staff['end_official_work']).format('YYYY-MM-DD 23:59:59') : Staff['end_official_work'];

            if (Staff.end_official_work !== null) {
                swal({
                    title: language[this.props.indexLanguage].Text_Notifi.are_you_sure,
                    text: language[this.props.indexLanguage].Text_Notifi.notifi_active + Staff.name,
                    icon: "warning",
                    buttons: true
                }).then(isActive => {
                    if (Staff['start_work']) {
                        if (Staff['end_official_work'] < Staff['start_work']) {
                            swal({
                                title: language[this.props.indexLanguage].Text_Notifi.warning,
                                text: language[this.props.indexLanguage].Text_Notifi.notifi_inactive_failed,
                                icon: "warning",
                            })
                        } else {
                            if (isActive) {
                                Staff['is_active'] = 0
                                this.updateStaff(Staff, event);
                            }
                        }
                    } else if (Staff['start_official_work']) {
                        if (Staff['end_official_work'] < Staff['start_official_work']) {
                            swal({
                                title: language[this.props.indexLanguage].Text_Notifi.warning,
                                text: language[this.props.indexLanguage].Text_Notifi.notifi_inactive_failed,
                                icon: "warning",
                            })
                        } else {
                            if (isActive) {
                                Staff['is_active'] = 0
                                this.updateStaff(Staff, event);
                            }
                        }
                    } else {
                        if (isActive) {
                            Staff['is_active'] = 0
                            this.updateStaff(Staff, event);
                        }
                    }
                })
            } else if (Staff['start_work'] > Staff['start_official_work']) {
                swal({
                    title: language[this.props.indexLanguage].Text_Notifi.warning,
                    text: language[this.props.indexLanguage].Text_Notifi.notifi_validate_start_work,
                    icon: "warning",
                })
            } else {
                this.updateStaff(Staff, event);
            }
            this.setState({
                crrStaff: Staff
            }, () => {
                console.log(this.state.crrStaff)
            });
        }

        if (this.state.crrStaff === null ? 1 : this.state.crrStaff.is_active === 1) {
            this.state.isChecked = true
        } else {
            this.state.isChecked = false
        }

        if (this.state.crrStaff === null ? 1 : this.state.crrStaff.gender == 1) {
            this.state.checkedMale = true
            this.state.checkedFemale = false
            this.state.checkedBD = false
        } else if (this.state.crrStaff.gender == 2) {
            this.state.checkedMale = false
            this.state.checkedFemale = true
            this.state.checkedBD = false
        } else if (this.state.crrStaff.gender == 3) {
            this.state.checkedMale = false
            this.state.checkedFemale = false
            this.state.checkedBD = true
        } else {
            this.state.checkedMale = false
            this.state.checkedFemale = false
            this.state.checkedBD = false
        }

        var goToCapture = 'd-none'
        if (this.state.goToCapture == true) {
            goToCapture = ''
        }

        var disabledId = false
        if (this.state.readOnly) {
            disabledId = true
        } else {
            if (this.state.isView) {
                disabledId = true
            } else {
                disabledId = false
            }
        }


        return (
            <Modal
                {...this.props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form id="formUpdateStaff" className="m-form">
                        <div className="col-xl-12">
                            <div className="m-widget14 row">
                                <div className="form-group m-form__group col-md-12 pl-0 pr-0 pt-0">
                                    <label htmlFor="Staff">{language[this.props.indexLanguage].textTable.id_staff} <span className="text-danger">* </span>{this.state.readOnly == true ? '(' + language[this.props.indexLanguage].Text_Notifi.text_notifi_modal_staff + ')' : ''}</label>
                                    <input className="form-control m-input" id="Staff" name='id_staff' value={this.state.crrStaff === null ? null : this.state.crrStaff.id_staff} disabled={disabledId} onKeyDown={(event) => this.handleEnter(event)} onChange={e => this.StaffHandle(e)} placeholder={language[this.props.indexLanguage].textTable.id_staff} required />
                                </div>
                                <div className="form-group m-form__group col-md-12 pl-0 pr-0 pt-0">
                                    <label htmlFor="Name">{language[this.props.indexLanguage].textTable.full_name} <span className="text-danger">*</span></label>
                                    <input disabled={this.state.isView} className="form-control m-input" id="Name" name='name' value={this.state.crrStaff === null ? null : this.state.crrStaff.name} onKeyDown={(event) => this.handleEnter(event)} onChange={e => this.StaffHandle(e)} placeholder={language[this.props.indexLanguage].textTable.full_name} required />
                                </div>
                                <div className="form-group m-form__group col-md-12 pl-0 pr-0 pt-0">
                                    <label htmlFor="Abbreviated_Name">{language[this.props.indexLanguage].textTable.nick_name} <span className="text-danger">*</span></label>
                                    <input disabled={this.state.isView} className="form-control m-input" pattern="[\w.\- ]+" id="Abbreviated_Name" name='abbreviated_name' maxLength="45" value={this.state.crrStaff === null ? null : this.state.crrStaff.abbreviated_name} onKeyDown={(event) => this.handleEnter(event)} onChange={e => this.StaffHandle(e)} placeholder={language[this.props.indexLanguage].textTable.nick_name} required />
                                    <span className="m-form__help">{language[this.props.indexLanguage].Text_Notifi.notifi_check_abbreviated_name}</span>
                                </div>
                                <div className="form-group m-form__group col-md-12 pl-0 pr-0 pt-0">
                                    <label htmlFor="Phone">{language[this.props.indexLanguage].textTable.phone} <span className="text-danger">*</span></label>
                                    <input disabled={this.state.isView} pattern='[0-9]{9,13}' type="tel" className="form-control m-input" id="Phone" name='phone_number' value={this.state.crrStaff === null ? null : this.state.crrStaff.phone_number} onKeyDown={(event) => this.handleEnter(event)} onChange={e => this.StaffHandle(e)} placeholder={language[this.props.indexLanguage].textTable.phone} required={this.state.isEdit ? false : true} />
                                </div>
                                <div className="form-group m-form__group col-md-12 pl-0 pr-0 pt-0">
                                    <label htmlFor="Email">{language[this.props.indexLanguage].textTable.email}</label>
                                    <input disabled={this.state.isView} pattern='[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}' type="email" className="form-control m-input" id="Email" name='email' value={this.state.crrStaff === null ? null : this.state.crrStaff.email} onKeyDown={(event) => this.handleEnter(event)} onChange={e => this.StaffHandle(e)} placeholder={language[this.props.indexLanguage].textTable.email} />
                                </div>
                                <div className="form-group m-form__group col-md-6 pl-0 pr-0 pt-0 pr-md-3">
                                    <label htmlFor="Birthday">{language[this.props.indexLanguage].textTable.birthday} <span className="text-danger">*</span></label>
                                    <MuiPickersUtilsProvider locale={this.props.indexLanguage === 1 ? vi : ''} utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            disableFuture
                                            openTo="year"
                                            format="dd-MM-yyyy"
                                            placeholder="DD-MM-YYYY"
                                            views={["year", "month", "date"]}
                                            value={this.state.crrStaff === null ? null : this.state.crrStaff.birthday}
                                            onChange={this.handleChangeBirthday}
                                            onKeyDown={(event) => this.handleEnter(event)}
                                            onError={(error, value) => {
                                                var errorDateBirthDay = this.state.errorDateBirthDay
                                                if (value !== errorDateBirthDay.value) {
                                                    errorDateBirthDay.value = value;
                                                    errorDateBirthDay.error = error;
                                                    this.setState({
                                                        errorDateBirthDay: errorDateBirthDay
                                                    });
                                                }
                                            }}
                                            disabled={this.state.isView}
                                            invalidDateMessage={language[this.props.indexLanguage].Text_Notifi.notifi_date_time_invalid}
                                            maxDateMessage={language[this.props.indexLanguage].Text_Notifi.notifi_date_time_future}
                                            minDateMessage={language[this.props.indexLanguage].Text_Notifi.notifi_date_time_min}
                                            className={`form-control m-input m_datetimepicker_6 ${this.state.isEdit ? '' : this.state.isBirthday ? 'border_red' : ''}`}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className="form-group m-form__group col-md-6 pl-0 pr-0 pt-0 pl-md-3">
                                    <label>{language[this.props.indexLanguage].textTable.status}</label>
                                    <div className="pt-2 pb-2">
                                        <Switch
                                            onChange={() => {
                                                var is_active;
                                                var dateResignation;
                                                if (!this.state.isChecked == true) {
                                                    is_active = 1;
                                                    dateResignation = null;
                                                } else {
                                                    is_active = 0;
                                                    dateResignation = Moment(new Date()).format('YYYY-MM-DD')
                                                }
                                                var Staff = this.state.crrStaff;
                                                Staff['is_active'] = is_active;
                                                Staff['end_official_work'] = dateResignation;
                                                this.setState({
                                                    crrStaff: Staff,
                                                    isChecked: !this.state.isChecked
                                                });
                                            }}
                                            checked={this.state.isChecked}
                                            handleDiameter={17}
                                            offColor="#969696"
                                            onColor="#00c5dc"
                                            offHandleColor="#ffffff"
                                            onHandleColor="#08f"
                                            height={25}
                                            width={55}
                                            disabled={this.state.isView}
                                            className="react-switch"
                                            id="small-radius-switch"
                                        />
                                    </div>
                                </div>
                                <div className="form-group m-form__group col-md-6 pl-0 pr-0 pt-0 pr-md-3">
                                    <label htmlFor="Start_Work">{language[this.props.indexLanguage].textTable.start_work}</label>
                                    <MuiPickersUtilsProvider locale={this.props.indexLanguage === 1 ? vi : ''} utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            // disableFuture
                                            disabled={this.state.isView}
                                            openTo="year"
                                            format="dd-MM-yyyy"
                                            placeholder="DD-MM-YYYY"
                                            views={["year", "month", "date"]}
                                            value={this.state.crrStaff === null ? null : this.state.crrStaff.start_work}
                                            onChange={this.handleChangeStartWork}
                                            onKeyDown={(event) => this.handleEnter(event)}
                                            invalidDateMessage={language[this.props.indexLanguage].Text_Notifi.notifi_date_time_invalid}
                                            maxDateMessage={language[this.props.indexLanguage].Text_Notifi.notifi_date_time_future}
                                            minDateMessage={language[this.props.indexLanguage].Text_Notifi.notifi_date_time_min}
                                            className="form-control m-input m_datetimepicker_6"
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className="form-group m-form__group col-md-6 pl-0 pr-0 pt-0 pr-md-3">
                                    <label htmlFor="Start_Official_Work">{language[this.props.indexLanguage].textTable.start_official_work}</label>
                                    <MuiPickersUtilsProvider locale={this.props.indexLanguage === 1 ? vi : ''} utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            // disableFuture
                                            disabled={this.state.isView}
                                            openTo="year"
                                            format="dd-MM-yyyy"
                                            placeholder="DD-MM-YYYY"
                                            views={["year", "month", "date"]}
                                            value={this.state.crrStaff === null ? null : this.state.crrStaff.start_official_work}
                                            onChange={this.handleChangeOfficialWork}
                                            onKeyDown={(event) => this.handleEnter(event)}
                                            invalidDateMessage={language[this.props.indexLanguage].Text_Notifi.notifi_date_time_invalid}
                                            maxDateMessage={language[this.props.indexLanguage].Text_Notifi.notifi_date_time_future}
                                            minDateMessage={language[this.props.indexLanguage].Text_Notifi.notifi_date_time_min}
                                            className="form-control m-input m_datetimepicker_6"
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                {
                                    this.state.crrStaff !== null && this.state.crrStaff.id_staff !== "" &&
                                    <div className="form-group m-form__group col-md-12 pl-0 pr-0 pt-0 pr-md-3">
                                        <label htmlFor="Start_Official_Work">{language[this.props.indexLanguage].textTable.resignationDate}</label>
                                        <MuiPickersUtilsProvider locale={this.props.indexLanguage === 1 ? vi : ''} utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                disableFuture
                                                disabled={this.state.isView}
                                                openTo="year"
                                                format="dd-MM-yyyy"
                                                placeholder="DD-MM-YYYY"
                                                views={["year", "month", "date"]}
                                                value={this.state.crrStaff === null ? null : this.state.crrStaff.end_official_work}
                                                onChange={this.handleChangeResignationDate}
                                                onKeyDown={(event) => this.handleEnter(event)}
                                                invalidDateMessage={language[this.props.indexLanguage].Text_Notifi.notifi_date_time_invalid}
                                                maxDateMessage={language[this.props.indexLanguage].Text_Notifi.notifi_date_time_future}
                                                minDateMessage={language[this.props.indexLanguage].Text_Notifi.notifi_date_time_min}
                                                className="form-control m-input m_datetimepicker_6"
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                }
                                <div className="m-form__group form-group col-xl-12 pl-0 pr-0 pt-0">
                                    <label>{language[this.props.indexLanguage].textTable.gender}</label>
                                    <div className="m-radio-inline">
                                        <label className="m-radio">
                                            <input disabled={this.state.isView} type="radio" name="gender" defaultChecked={this.state.checkedMale} defaultValue={1} onClick={e => this.StaffHandle(e)} /> {language[this.props.indexLanguage].textTable.male}
                                            <span />
                                        </label>
                                        <label className="m-radio">
                                            <input disabled={this.state.isView} type="radio" name="gender" defaultChecked={this.state.checkedFemale} defaultValue={2} onClick={e => this.StaffHandle(e)} /> {language[this.props.indexLanguage].textTable.female}
                                            <span />
                                        </label>
                                        <label className="m-radio">
                                            <input disabled={this.state.isView} type="radio" name="gender" defaultChecked={this.state.checkedBD} defaultValue={3} onClick={e => this.StaffHandle(e)} /> {language[this.props.indexLanguage].textTable.other_gender}
                                            <span />
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group m-form__group col-md-12 pl-0 pr-0 pt-0">
                                    <label htmlFor="Department">{language[this.props.indexLanguage].textTable.department} <span className="text-danger">* </span></label>
                                    <Select
                                        getOptionValue={(option) => option.label}
                                        isDisabled={this.state.isView}
                                        onChange={value => this.handleChangeDepartment(value)}
                                        options={this.state.optionDepartment.map(item => ({
                                            label: formatTitleOption(item),
                                            value: item.value
                                        }))}
                                        value={this.state.valueDepartment}
                                        placeholder={language[this.props.indexLanguage].placeholderSelect.select_department}
                                        className={`${this.state.isDepartment ? 'border_red-select' : ''}`}
                                        styles={{
                                            option: (base, state) => ({
                                                ...base,
                                                whiteSpace: "nowrap"
                                            })
                                        }}
                                    />
                                </div>
                                <div className="form-group m-form__group col-md-12 pl-0 pr-0 pt-0">
                                    <label>{language[this.props.indexLanguage].textTable.level}</label>
                                    <Select
                                        getOptionValue={(option) => option.label}
                                        onChange={value => this.handleChangeLevel(value)}
                                        options={this.state.optionLevel}
                                        isDisabled={this.state.isView}
                                        value={this.state.valueLevel}
                                        placeholder={language[this.props.indexLanguage].placeholderSelect.select_level}
                                    />
                                </div>
                                <div className="form-group m-form__group col-md-12 pl-0 pr-0 pt-0">
                                    <label>{language[this.props.indexLanguage].textTable.shift} <span className="text-danger">* </span></label>
                                    <Select
                                        getOptionValue={(option) => option.label}
                                        onChange={this.changeHandleShift}
                                        options={this.state.optionShift}
                                        value={this.state.valueShift}
                                        isMulti
                                        isDisabled={this.state.isView}
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        placeholder={language[this.props.indexLanguage].placeholderSelect.select_shift}
                                        className={`${this.state.isShift ? 'border_red-select' : ''}`}
                                    />
                                </div>
                                <div className="form-group m-form__group col-md-12 pl-0 pr-0 pt-0">
                                    <label>{language[this.props.indexLanguage].Menubar.Role} <span className="text-danger">* </span></label>
                                    <Select
                                        getOptionValue={(option) => option.label}
                                        onChange={value => this.handleChangeRole(value)}
                                        options={this.state.optionRole}
                                        isDisabled={this.state.isView}
                                        value={
                                            this.state.optionRole.filter((option) => {
                                                return this.state.crrStaff.id_role === option.value
                                            })
                                        }
                                        placeholder={language[this.props.indexLanguage].placeholderSelect.select_role}
                                        className={`${this.state.isRole ? 'border_red-select' : ''}`}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    {!this.state.isView ?
                        <>
                            {
                                this.state.crrStaff !== null
                                    ?
                                    this.state.crrStaff.is_active !== 0
                                        ?
                                        <NavLink onClick={() => {
                                            ReactGA.event({
                                                category: 'Nhan vien',
                                                action: 'Click lay anh',
                                                label: 'Lay anh tu form',
                                            })
                                        }} to={this.state.location} className={"btn btn-success " + goToCapture}  >{language[this.props.indexLanguage].textButton.go_to_capture}</NavLink>
                                        :
                                        ''
                                    :
                                    ''
                            }
                            <Button variant="accent" id="button_addStaff" className="m-loader--light m-loader--right" onClick={click_handle}>{language[this.props.indexLanguage].textButton.save}</Button>
                        </>
                        : ""}
                    <Button variant="secondary" onClick={this.props.onHide}>{language[this.props.indexLanguage].textButton.close}</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

ModalStaff.propTypes = {
    listdepartment: PropTypes.array,
    liststaff: PropTypes.array,
    listlevel: PropTypes.array,
    listshift: PropTypes.array,
    data: PropTypes.object,
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool,
}
const mapStateToProps = state => {
    return {
        indexLanguage: state.changelanguage.indexLanguage,
    }
}
export default connect(mapStateToProps, null)(ModalStaff);