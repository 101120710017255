import { css } from '@emotion/core';
import $ from 'jquery';
import React, { Component } from 'react';
import Pagination from "react-js-pagination";
import { Redirect } from 'react-router-dom';
import Switch from "react-switch";
import ReactTooltip from 'react-tooltip';
import ReactGA from 'react-ga';
import swal from 'sweetalert';
import language from '../../../../language/language';
import Store from '../../../../store';
import ModalDoor from "./ModalDoor";
import { connect } from 'react-redux'
import Header from '../../../layouts/Header';
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;
const nameTab = 'door'
class Door extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogin: Store.getState().isLogin.isLogin,
            access_token: Store.getState().isLogin.access_token,
            crrApi2: Store.getState().boxai.box_engine_cf.crrApiSocket,
            dataBoxengine: Store.getState().boxai.box_engine_cf.dataCamera,
            language: Store.getState().changelanguage.language,
            box_engine_cf: Store.getState().boxai.box_engine_cf,
            dataRole: Store.getState().role.role,
            type: '',
            modalDoor: false,
            isChecked: false,
            listDoor: [],
            crrDatas: [],
            activePage: 1,
            offset: 0,
            totalLength: 0,
            crrDoor: {
                box_engine_id: "",
                name: "",
                description: "",
                is_active: 0
            },
            valueSearch: '',
        };
        Store.subscribe(() => {
            this.setState({
                isLogin: Store.getState().isLogin.isLogin,
                access_token: Store.getState().isLogin.access_token,
                crrApi2: Store.getState().boxai.box_engine_cf.crrApiSocket,
                dataBoxengine: Store.getState().boxai.box_engine_cf.dataCamera,
                language: Store.getState().changelanguage.language,
                box_engine_cf: Store.getState().boxai.box_engine_cf,
                listDoor: [],
                crrDatas: [],
                activePage: 1,
                offset: 0,
                totalLength: 0,
                valueSearch: '',
            }, () => {
                $('#inputSearch').val('')
                this.getDoor()
            });
        });
        this.itemsPerPage = 10;
        this.getDoor()
    }

    componentWillMount() {
        var type = localStorage.getItem("type")
        this.setState({
            type: type
        })
    }

    getDoor = () => {
        if (this.state.crrApi2 == undefined) return;
        fetch(`${this.state.crrApi2}/api/door/${this.state.box_engine_cf.idCompany}/get_all`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                this.setState({
                    listDoor: [...data.data],
                });
                this.FilterDoor(this.state.activePage)
            }
        })
    }


    FilterDoor(activePage) {

        //console.log('123')
        var dataSearch = [];
        this.state.listDoor.forEach((item) => {
            if (item.name.toLowerCase().indexOf(this.state.valueSearch.toLowerCase()) !== -1) {
                dataSearch.push(item);
            }
        })

        const offset = (activePage - 1) * this.itemsPerPage;
        const crrDatas = dataSearch.slice(offset, offset + this.itemsPerPage);

        this.setState({
            crrDatas,
            offset: offset,
            totalLength: dataSearch.length
        })
    }

    handlePageChange = (pageNumber) => {
        this.setState({
            ...this.state,
            activePage: pageNumber
        })
        this.FilterDoor(pageNumber);

    }

    DeleteDoor = async (door) => {
        let data = await fetch(`${this.state.crrApi2}/api/door/delete`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token,
            },
            body: JSON.stringify({
                'id': door.id
            })
        }).then((response) => {
            return response.json()
        });
        if (data.status === 10000) {
            ReactGA.event({
                category: 'Quan li cua',
                action: 'Xoa cua',
              });
            swal(language[this.props.indexLanguage].Text_Notifi.success, language[this.props.indexLanguage].Text_Notifi.notifi_delete_success, "success", {
                buttons: false,
                timer: 1500,
            });
            this.getDoor();
        }
        else {
            swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].Text_Notifi.notifi_delete_false, "error");
        }

    }

    modalClose = () => {
        this.setState({
            modalDoor: false,
        });
        this.getDoor()
    }

    btnSearch = () => {
        this.setState({
            activePage: 1
        }, () => {
            this.FilterDoor(this.state.activePage);
        })
    }

    isChange = (event) => {
        if (event.key === 'Enter') {
            this.btnSearch(event.target.value.trim())
        }
        this.setState({
            valueSearch: event.target.value.trim()
        });
    }

    render() {
        if (this.state.isLogin === false) {
            return (
                <Redirect to={'/login'} />
            )
        }

        let bulletedDoor = this.state.crrDatas.map((e, i) => {
            if (e.is_active === 1) {
                this.state.isChecked = true
            }
            if (e.is_active === 0) {
                this.state.isChecked = false
            }
            var box_engine_id = e.box_engine_id.split('|');
            box_engine_id.pop();
            box_engine_id.shift();
            return (
                <tr key={(i + 1)}>
                    <td>{(i + this.state.offset + 1)}</td>
                    <td>{box_engine_id.toString()}</td>
                    <td>{e.name}</td>
                    <td style={{ "whiteSpace": "normal" }}>{e.description}</td>
                    {
                        this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':insert_or_update') !== -1
                            ?
                            <td>
                                <Switch
                                    onChange={() => {
                                        if (e.is_active === 0) {
                                            e.is_active = 1;
                                            swal({
                                                title: language[this.props.indexLanguage].Text_Notifi.are_you_sure,
                                                text: language[this.props.indexLanguage].Text_Notifi.notifi_active + e.name,
                                                icon: "warning",
                                                button: {
                                                    text: "Oke!",
                                                    closeModal: false,
                                                },
                                            })
                                                .then(name => {
                                                    if (!name) throw null;

                                                    return fetch(`${this.state.crrApi2}/api/door/insert_or_update`, {
                                                        method: 'POST',
                                                        headers: {
                                                            'Content-Type': 'application/json',
                                                            'Authorization': Store.getState().isLogin.access_token
                                                        },
                                                        body: JSON.stringify(e)
                                                    })
                                                })
                                                .then(response => {
                                                    return response.json()
                                                }).then(data => {
                                                    if (data.status === 10000) {
                                                        this.getDoor();
                                                        swal(language[this.props.indexLanguage].Text_Notifi.success, language[this.props.indexLanguage].Text_Notifi.notifi_active_door_success, "success", {
                                                            buttons: false,
                                                            timer: 1500,
                                                        });
                                                    }
                                                    else {
                                                        e.is_active = 0;
                                                        swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].Text_Notifi.notifi_active_door_false, "error");
                                                    }
                                                })
                                        } else {
                                            e.is_active = 0;
                                            swal({
                                                title: language[this.props.indexLanguage].Text_Notifi.are_you_sure,
                                                text: language[this.props.indexLanguage].Text_Notifi.notifi_active + e.name,
                                                icon: "warning",
                                                button: {
                                                    text: "Oke!",
                                                    closeModal: false,
                                                },
                                            })
                                                .then(name => {
                                                    if (!name) throw null;

                                                    return fetch(`${this.state.crrApi2}/api/door/insert_or_update`, {
                                                        method: 'POST',
                                                        headers: {
                                                            'Content-Type': 'application/json',
                                                            'Authorization': Store.getState().isLogin.access_token
                                                        },
                                                        body: JSON.stringify(e)
                                                    })
                                                })
                                                .then(response => {
                                                    return response.json()
                                                }).then(data => {
                                                    if (data.status === 10000) {
                                                        this.getDoor();
                                                        swal(language[this.props.indexLanguage].Text_Notifi.success, language[this.props.indexLanguage].Text_Notifi.notifi_inactive_door_success, "success", {
                                                            buttons: false,
                                                            timer: 1500,
                                                        });
                                                    }
                                                    else {
                                                        e.is_active = 1;
                                                        swal(language[this.props.indexLanguage].Text_Notifi.error, language[this.props.indexLanguage].Text_Notifi.notifi_inactive_door_false, "error");
                                                    }
                                                })
                                        }
                                    }}
                                    checked={this.state.isChecked}
                                    handleDiameter={17}
                                    offColor="#969696"
                                    onColor="#00c5dc"
                                    offHandleColor="#ffffff"
                                    onHandleColor="#08f"
                                    height={25}
                                    width={55}
                                    className="react-switch"
                                    id="small-radius-switch"
                                />
                            </td>

                            :
                            <td>
                                {
                                    e.is_active === 0
                                        ?
                                        <span className="m-badge  m-badge--secondary m-badge--wide">{language[this.props.indexLanguage].textTable.active_door}</span>
                                        :
                                        <span className="m-badge  m-badge--accent m-badge--wide">{language[this.props.indexLanguage].textTable.inactive_door}</span>
                                }
                            </td>
                    }
                    {
                        this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':insert_or_update') !== -1
                            ||
                            this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':delete') !== -1
                            ?
                            <td>
                                {
                                    this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':insert_or_update') !== -1
                                        ?
                                        <>
                                            <button onClick={(e) => {
                                                var data = this.state.crrDatas
                                                var dataDoor = {
                                                    id: data[i].id,
                                                    box_engine_id: data[i].box_engine_id,
                                                    is_active: data[i].is_active,
                                                    name: data[i].name,
                                                    id_company: this.state.box_engine_cf.idCompany,
                                                    description: data[i].description,
                                                    HOST: this.state.crrApi2
                                                };
                                                this.setState({
                                                    crrDoor: dataDoor,
                                                    modalDoor: true,
                                                });


                                            }} className="m-portlet__nav-link btn m-btn m-btn--hover-warning m-btn--icon m-btn--icon-only m-btn--pill" data-tip data-for='Edit'>
                                                <i className="la la-edit" />
                                            </button>
                                            <ReactTooltip id='Edit' type='dark' effect='solid'>
                                                <span>{language[this.props.indexLanguage].tooltip.open_edit}</span>
                                            </ReactTooltip>
                                        </>
                                        :
                                        ""
                                }
                                {
                                    this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':delete') !== -1
                                        ?
                                        <>
                                            <button className="m-portlet__nav-link btn m-btn m-btn--hover-danger m-btn--icon m-btn--icon-only m-btn--pill" data-tip data-for='Delete'
                                                onClick={v => {
                                                    v.preventDefault();
                                                    swal({
                                                        title: language[this.props.indexLanguage].Text_Notifi.are_you_sure,
                                                        text: language[this.props.indexLanguage].Text_Notifi.notifi_sure_delete + e.name,
                                                        icon: "warning",
                                                        buttons: true,
                                                        dangerMode: true,
                                                    })
                                                        .then(willDelete => {
                                                            if (willDelete) {
                                                                this.DeleteDoor(e);
                                                            }
                                                        });

                                                }}
                                            ><i className="la la-trash"></i></button>
                                            <ReactTooltip id='Delete' type='dark' effect='solid'>
                                                <span>{language[this.props.indexLanguage].tooltip.open_delete}</span>
                                            </ReactTooltip>
                                        </>
                                        :
                                        ""
                                }
                            </td>
                            :
                            ""
                    }

                </tr>
            )
        });

        var pageRangeDisplayed = 1;
        if ($(window).width() < 768) {
            pageRangeDisplayed = 3
        } else {
            pageRangeDisplayed = 5
        }

        return (
            <>
            <Header />
            <div className={"m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body "}>
            <div className="m-grid__item m-grid__item--fluid m-wrapper">
                <div className="m-content p-2">
                    <div className="m-portlet m-portlet--tab mb-0">
                        <div className="m-portlet__head p-3">
                            <div className="m-portlet__head-caption pl-3">
                                <div className="m-portlet__head-title">
                                    <h3 className="m-portlet__head-text">
                                        {language[this.props.indexLanguage].Menubar.door}
                                    </h3>
                                </div>
                            </div>
                            <div className="m-portlet__head-tools pr-3">
                                <ul className="m-portlet__nav">
                                    <li className="m-portlet__nav-item">
                                        {
                                            this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':insert_or_update') !== -1
                                                ?
                                                <button onClick={(e) => {
                                                    this.setState({
                                                        crrDoor: {
                                                            box_engine_id: "",
                                                            name: "",
                                                            description: "",
                                                            id_company: this.state.box_engine_cf.idCompany,
                                                            is_active: 0,
                                                            HOST: this.state.crrApi2
                                                        },
                                                        modalDoor: true,
                                                    });
                                                }} className="btn btn-accent m-btn m-btn--custom m-btn--icon m-btn--pill m-btn--air">
                                                    <span>
                                                        <i className="la la-plus" />
                                                        <span>{language[this.props.indexLanguage].textButton.add}</span>
                                                    </span>
                                                </button>
                                                :
                                                ""
                                        }
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="m-portlet__body m-portlet__body--no-padding">
                            <div className="row m-row--no-padding m-row--col-separator-xl">
                                <div className="col-xl-12">
                                    <div className="m-widget1 col-xl-8 mx-auto">
                                        <div className="row col-md-12 ml-0 mr-0 p-0 mb-2">
                                            <div className="col-lg-2 pl-0 m--margin-bottom-10-tablet-and-mobile">
                                                <input type="text" onKeyUp={(event) => this.isChange(event)} id="inputSearch" className="form-control m-input" placeholder={language[this.props.indexLanguage].placeholderInput.enter_name} data-col-index={0} />
                                            </div>
                                            <div>
                                                <button
                                                    onClick={() => {
                                                        this.btnSearch(this.state.valueSearch)
                                                    }}
                                                    className="btn btn-accent m-btn m-btn--icon" id="m_search">
                                                    <span>
                                                        <i className="la la-search" />
                                                        <span>{language[this.props.indexLanguage].textButton.search}</span>
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="table-responsive text-nowrap">
                                            <table className="table table-bordered table-hover table-checkable dataTable no-footer dtr-inline collapsed">
                                                <thead>
                                                    <tr>
                                                        <th style={{ 'verticalAlign': 'middle', 'width': '100px' }}>{language[this.props.indexLanguage].Other.no}</th>
                                                        <th style={{ 'verticalAlign': 'middle' }}>BiFaceID</th>
                                                        <th style={{ 'verticalAlign': 'middle' }}>{language[this.props.indexLanguage].textTable.name}</th>
                                                        <th style={{ 'verticalAlign': 'middle', 'width': '350px' }}>{language[this.props.indexLanguage].textTable.description}</th>
                                                        <th style={{ 'verticalAlign': 'middle', 'width': '150px' }}>{language[this.props.indexLanguage].textTable.status}</th>
                                                        {
                                                            this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':insert_or_update') !== -1
                                                                ||
                                                                this.state.dataRole.indexOf(this.state.type + '/' + nameTab + ':delete') !== -1
                                                                ?
                                                                <th style={{ 'verticalAlign': 'middle', 'width': '200px' }}>{language[this.props.indexLanguage].textTable.action}</th>
                                                                :
                                                                ''
                                                        }

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {bulletedDoor}
                                                </tbody>

                                            </table>
                                            <ModalDoor
                                                data={this.state.crrDoor}
                                                databoxengine={this.state.dataBoxengine}
                                                show={this.state.modalDoor}
                                                onHide={this.modalClose}
                                            />
                                        </div>
                                       
                                        <Pagination
                                            firstPageText={language[this.props.indexLanguage].pagination.first}
                                            nextPageText={language[this.props.indexLanguage].pagination.next}
                                            lastPageText={language[this.props.indexLanguage].pagination.last}
                                            prevPageText={language[this.props.indexLanguage].pagination.previous}
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={this.itemsPerPage}
                                            totalItemsCount={this.state.totalLength}
                                            pageRangeDisplayed={pageRangeDisplayed}
                                            onChange={this.handlePageChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </>
        );
    }
}
const mapStateToProps = state => {
    return {
        indexLanguage: state.changelanguage.indexLanguage,
    }
}


export default connect(mapStateToProps, null)(Door);
