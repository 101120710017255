import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import Pagination from "react-js-pagination";
import Store from '../../../../store';
import swal from 'sweetalert';
import $ from 'jquery';
import language from '../../../../language/language';
import { connect } from 'react-redux'
//Khong dung
class ModalShowImgEmotion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            api_countface: '',
            check: '',
            activePage: 1,
            listImage: [],
            crrImages: [],
            language: Store.getState().changelanguage.language,
            indexlanguage: 0
        }
        this.itemsPerPage = 8;
    }


    FilterImg = (activePage) => {
        const { listImage } = this.state;
        const offset = (activePage - 1) * this.itemsPerPage;
        const crrImages = listImage.slice(offset, offset + this.itemsPerPage);
        this.setState({ crrImages })
    }

    handlePageChange = (pageNumber) => {
        this.setState({
            ...this.state,
            activePage: pageNumber
        })
        this.FilterImg(pageNumber);

    }

    getImg = (idCamera, crrDate) => {
        var time = new Date(crrDate - 25200000);
        var dd = time.getDate();
        var MM = time.getMonth() + 1;
        var yyyy = time.getFullYear();
        var hh = time.getHours();

        if (hh < 10) {
            hh = '0' + hh
        }

        if (dd < 10) {
            dd = '0' + dd
        }

        if (MM < 10) {
            MM = '0' + MM
        }
        var date = yyyy + '-' + MM + '-' + dd;
        var time = '';
        if (this.state.check == 'Hour') {
            time = hh;
        } else if(this.state.check == 'Day'){
            time = -1;
        }
        if (this.state.api_countface === "" || this.state.api_countface === undefined) return;
        fetch(`${this.state.api_countface}/api/imgs/getbyTime`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            dataType: 'json',
            body: JSON.stringify({
                'idCamera': idCamera,
                'date': date,
                'time': time + ''
            })
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                this.setState({
                    listImage: data.data
                },() => {
                    this.FilterImg(this.state.activePage)
                });
            }
        });

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.show == true) {
            this.setState({
                crr_idCamera: nextProps.idcamera,
                crrDate: nextProps.datatime,
                api_countface: nextProps.apicountface,
                check: nextProps.check,
                activePage: 1,
                show: nextProps.show,
                crrImages: [],
                listImage: [],
            }, () => {
                this.getImg(this.state.crr_idCamera,this.state.crrDate);
            });
        }
    }

    render() {
        let bulletedDatamol = this.state.crrImages.map((e, i) => {
            var time = new Date(e.timeget);
            var dd = time.getDate();
            var MM = time.getMonth() + 1;
            var yyyy = time.getFullYear();
            var hh = time.getHours();
            var mm = time.getMinutes();
            var ss = time.getSeconds();
            if (ss < 10) {
                ss = '0' + ss
            }
            if (mm < 10) {
                mm = '0' + mm
            }

            if (hh < 10) {
                hh = '0' + hh
            }

            if (dd < 10) {
                dd = '0' + dd
            }

            if (MM < 10) {
                MM = '0' + MM
            }

            var timeget = yyyy + '-' + MM + '-' + dd + ' ' + hh + ':' + mm + ':' + ss;
            return (
                <div className="col-md-3 col-sm-6 col-6 mb-4 pr-2 pl-2" key={i}>
                    <div className={"card border-0 shadow " + (e.isface === false ? "bg-warning" : "")}>
                        <img src={this.state.api_countface +'/view/thumbnail/'+ e._id} className="card-img-top" alt="xx" height={150} />
                        <div className="card-body text-center">
                            <div className="pb-1">{timeget}</div>
                        </div>
                    </div>
                </div>
            )
        });
        var pageRangeDisplayed = 1;
        if ($(window).width() < 768) {
            pageRangeDisplayed = 3
        } else {
            pageRangeDisplayed = 5
        }

        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Info Traffic
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        {bulletedDatamol}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Pagination
                        firstPageText={language[this.props.indexLanguage].pagination.first}
                        nextPageText={language[this.props.indexLanguage].pagination.next}
                        lastPageText={language[this.props.indexLanguage].pagination.last}
                        prevPageText={language[this.props.indexLanguage].pagination.previous}
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.itemsPerPage}
                        totalItemsCount={this.state.listImage.length}
                        pageRangeDisplayed={pageRangeDisplayed}
                        onChange={this.handlePageChange}
                    />
                </Modal.Footer>
            </Modal>
        );
    }
}


ModalShowImgEmotion.propTypes = {
    idcamera: PropTypes.string,
    apicountface: PropTypes.string,
    check: PropTypes.string,
    datatime: PropTypes.number,
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool,
}
const mapStateToProps = state => {
    return {
        indexLanguage: state.changelanguage.indexLanguage,
    }
}
export default connect(mapStateToProps, null)(ModalShowImgEmotion);