import React, { useEffect } from 'react';
import { useState } from 'react';
import { Checkbox } from '@material-ui/core';
import axios from 'axios';
import Store from '../../../../store';
import { PulseLoader } from 'react-spinners';
import { css } from '@emotion/core';
import ReactTooltip from 'react-tooltip';
import { useSelector } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import swal from 'sweetalert';
import language from '../../../../language/language';
import HolidayForm from './components/holidayForm.js';
import DatePicker, { registerLocale } from "react-datepicker";
import vi from 'date-fns/locale/vi';
import Moment from 'moment';
import Pagination from 'react-js-pagination';
import ReactGA from 'react-ga';

registerLocale('vi', vi)

const NameTag = 'reason_off';

const useStyles = makeStyles(() => ({
  root: {
    padding: '0',
  }
}));

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;


function LeaveOfAbsenceLetterTableTab(props) {

  const [showModal, setShowModal] = useState(false);
  const [crrData, setCrrData] = useState([]);
  const [dataHoliday, setDataHoliday] = useState({});
  const [loading, setLoading] = useState(false);


  const classes = useStyles();

  const dataRole = useSelector(state => state.role.role);
  const box_engine_cf = useSelector(state => state.boxai.box_engine_cf);

  const type = localStorage.getItem('type');

  
  const indexLanguage = useSelector(state => state.changelanguage.indexLanguage);
  const [crrDate, setCrrDate] = useState(new Date())
  const [letterRows, setLetterRows] = useState([]);


  const [activePage, setActivePage] = useState(1);
  const totalItems = letterRows.length;
  const range = 3;
  const itemsForPage = 10;

  const initial = {
    id: 0,
    name: '',
    description: '',
    year: Moment(crrDate).format('YYYY'),
    current_date: new Date(),
    start_date: "",
    end_date: "",
    id_company: box_engine_cf.idCompany,
  }


  const handleCloseModal = () => {
    setShowModal(false);
  }

  const handleChangePage = (pageNumber) => {
    const index = (pageNumber - 1) * itemsForPage;
    const filterCountItems = letterRows.slice(index, index + itemsForPage);
    setCrrData(filterCountItems);
    setActivePage(pageNumber);
  }

  const handleShowModal = (e, value) => {
    setShowModal(true);
    if (value) {
      setDataHoliday({
        id: value.id,
        name: value.name,
        description: value.description,
        year: value.year,
        start_date: value.start_date,
        end_date: value.end_date,
        current_date: value.current_date,
        id_company: value.id_company,
      })
    } else {
      setDataHoliday(initial);
    }
  }



  const handleDeleteById = (id, name) => {
    swal({
      title: language[indexLanguage].Text_Notifi.are_you_sure,
      text: language[indexLanguage].Text_Notifi.notifi_sure_delete_holiday + name,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          const data = await axios({
            method: 'POST',
            url: `${box_engine_cf.crrApiSocket}/api/holiday/delete`,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': Store.getState().isLogin.access_token
            },
            data: {
              id: id,
              current_date: Moment(new Date()).format('YYYY-MM-DD HH:mm') + ':00',
            }
          })
          if (data.data.status === 10000) {
            ReactGA.event({
              category: 'Ngay le',
              action: 'Xoa ngay le',
            });
            var page = activePage === 1 ? 0 : activePage - 1 ;
            if (crrData.length === 1) {
              setActivePage(page)
            }
            swal(language[indexLanguage].Text_Notifi.success, language[indexLanguage].Text_Notifi.notifi_delete_success, "success", {
              buttons: false,
              timer: 1500,
            });
            fetchData(0, page);
          } else {
            swal(language[indexLanguage].Text_Notifi.error, language[indexLanguage].Text_Notifi.notifi_delete_false, "error");
          }
        }
      });
  }


  const fetchData = async (date, page) => {
    const data = await axios({
      method: 'GET',
      url: `${box_engine_cf.crrApiSocket}/api/holiday/get_all/${box_engine_cf.idCompany}/${date ? Moment(date).format('YYYY') : Moment(crrDate).format('YYYY')}`,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': Store.getState().isLogin.access_token
      },
    })
    if (data.data.status === 10000) {
      //Sort by date create
      const sortByDateCreate = data.data.data;

      //filter count items pagination
      const index = page === 1 ? 0 : (activePage - 1) * itemsForPage;
      const filterCountItems = sortByDateCreate.slice(index, index + itemsForPage);

      setCrrData(filterCountItems);
      setLetterRows(sortByDateCreate);
      setLoading(false);
    }
  }

  const handleChangeYear = (date) => {
    setCrrDate(date)
    fetchData(date, 1);
  }
  const handleUpdateHoliday = () => {
    if (crrData.length !== 0) return;
    swal({
      title: language[indexLanguage].Text_Notifi.are_you_sure,
      text: language[indexLanguage].Text_Notifi.notifi_sure_handle_auto_update_holiday,
      icon: "warning",
      buttons: {
        oke: language[indexLanguage].textButton.oke,
        cancel: language[indexLanguage].textButton.close
      },
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const result_auto_update = await axios({
          method: 'GET',
          url: `${box_engine_cf.crrApiSocket}/api/holiday/auto_update/${box_engine_cf.idCompany}/${parseInt(Moment(crrDate).format('YYYY')) - 1}`,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': Store.getState().isLogin.access_token
          },
        })
        if (result_auto_update.data.status === 10000) {
          fetchData(0, 1);
          return swal({
            title: language[indexLanguage].Text_Notifi.success,
            text: language[indexLanguage].Text_Notifi.notifi_sure_auto_update_holiday_complete,
            icon: "success", buttons: false, timer: 1500
          });
        } else if (result_auto_update.data.status === 10001) {
          return swal({
            title: language[indexLanguage].Text_Notifi.warning,
            text: language[indexLanguage].Text_Notifi.notifi_sure_auto_update_holiday_last_year_empty,
            icon: "warning", buttons: false, timer: 1500
          });
        } else if (result_auto_update.data.status === 10002) {
          return swal({
            title: language[indexLanguage].Text_Notifi.error,
            text: language[indexLanguage].Text_Notifi.notifi_sure_auto_update_holiday_year,
            icon: "error", buttons: false, timer: 1500
          });
        }
      }
    });
  }

  useEffect(() => {
    setLoading(true);
    if (box_engine_cf) {
      fetchData();
    }
  }, [box_engine_cf.idCompany])
  
  const tableRows = crrData.map((value, index) => {
    var dateFormat = ""
    if (Moment(value.start_date).format('DD-MM-YYYY') === Moment(value.end_date).format('DD-MM-YYYY')) {
      dateFormat = Moment(value.start_date).format('DD-MM-YYYY')
    } else {
      dateFormat = (Moment(value.start_date).format('DD-MM-YYYY') + " - " + Moment(value.end_date).format('DD-MM-YYYY'))
    }

    if (dataRole.indexOf(`${type}/${NameTag}:get_all`) !== -1) return (
      <tr key={index}>
        <td>{value.name}</td>
        <td>{dateFormat}</td>
        <td>{value.description}</td>
        {
          (dataRole.indexOf(`${type}/${NameTag}:insert_or_update`) !== -1/* && dataRole.indexOf(`${type}/${NameTag}:delete`) !== -1*/) &&
          <td>
            {
              dataRole.indexOf(`${type}/${NameTag}:insert_or_update`) !== -1 &&
              <>
                <button onClick={(e) => handleShowModal(e, value)} className="m-portlet__nav-link btn m-btn m-btn--hover-warning m-btn--icon m-btn--icon-only m-btn--pill" data-tip data-for='Edit'>
                  <i className="la la-edit" />
                </button>
                <ReactTooltip id='Edit' type='dark' effect='solid'>
                  <span>{language[indexLanguage].tooltip.open_edit}</span>
                </ReactTooltip>
              </>
            }
            {
              dataRole.indexOf(`${type}/${NameTag}:delete`) !== -1 &&
              <>
                <button onClick={() => handleDeleteById(value.id, value.name)} className="m-portlet__nav-link btn m-btn m-btn--hover-warning m-btn--icon m-btn--icon-only m-btn--pill" data-tip data-for='Delete'>
                  <i className="la la-trash" />
                </button>
                <ReactTooltip id='Delete' type='dark' effect='solid'>
                  <span>{language[indexLanguage].tooltip.open_delete}</span>
                </ReactTooltip>
              </>
            }
          </td>
        }
      </tr>
    );
  });

  if (!crrData) return <></>
  return (
    <div className='m-grid__item m-grid__item--fluid m-wrapper'>
      <div className='m-content p-2'>
        <div className='m-portlet--tab mb-0'>
          <div className="m-portlet__head p-3">
            <div className="m-portlet__head-caption pl-3">
            </div>
            {
              dataRole.indexOf(`${type}/${NameTag}:insert_or_update`) !== -1 &&
              <div className="m-portlet__head-tools pr-md-3">
                <ul className="m-portlet__nav">
                  <li className="m-portlet__nav-item">
                    <button
                      className='btn btn-accent m-btn m-btn--custom m-btn--icon m-btn--pill m-btn--air'
                      style={{ fontFamily: 'inherit' }}
                      onClick={handleShowModal}
                    >
                      <span>
                        <i className="la la-plus"></i>
                        <span>{language[indexLanguage].textButton.add}</span>
                      </span>
                    </button>
                  </li>
                </ul>
              </div>
            }
          </div>
          <div className='m-portlet__body m-portlet__body--no-padding'>
            <div className='row m-row--no-padding m-row--col-separator-xl'>
              <div className='col-xl-8 mx-auto'>
                <div className='m-widget1 col-xl-12 mx-auto'>
                  <div className="m-portlet__head-tools select_date_month row py-0 px-3 mb-2">
                    <div className='col-12 col-md-2 p-0'>
                      <DatePicker
                        className="form-control m-input m_datetimepicker_6"
                        selected={crrDate}
                        onChange={handleChangeYear}
                        showYearPicker
                        dateFormat="yyyy"
                        yearItemNumber={3}
                      />
                    </div>
                    {
                      (crrData.length === 0 && new Date(crrDate).getFullYear() >= new Date().getFullYear()) &&
                      <div className='col-12 col-md-2 offset-md-8 p-0 text-right pt-2'>
                        <button className='btn btn-accent m-btn m-btn--icon' onClick={() => handleUpdateHoliday()}>
                          {language[indexLanguage].Holiday.update_holiday}
                        </button>
                      </div>
                    }
                  </div>
                  <div className='table-responsive text-nowrap'>
                    <table className='table table-bordered table-hover table-checkable dataTable no-footer dtr-inline collapsed'>
                      <thead>
                        <tr>
                          <th style={{ verticalAlign: 'middle' }}>{language[indexLanguage].Holiday.name_holiday}</th>
                          <th style={{ verticalAlign: 'middle' }}>{language[indexLanguage].Holiday.date}</th>
                          <th style={{ verticalAlign: 'middle' }}>{language[indexLanguage].Holiday.description}</th>
                          {
                            dataRole.indexOf(`${type}/${NameTag}:insert_or_update`) !== -1 &&
                            <th style={{ verticalAlign: 'middle', width: '100px' }}>{language[indexLanguage].Holiday.action}</th>
                          }
                        </tr>
                      </thead>
                      <tbody>{tableRows}</tbody>
                    </table>
                    <PulseLoader
                      css={override}
                      sizeUnit={"px"}
                      size={12}
                      margin={'2px'}
                      color={'#36D7B7'}
                      loading={loading}
                    />
                    <Pagination
                      firstPageText={
                        language[indexLanguage].pagination.first
                      }
                      nextPageText={
                        language[indexLanguage].pagination.next
                      }
                      lastPageText={
                        language[indexLanguage].pagination.last
                      }
                      prevPageText={
                        language[indexLanguage].pagination.previous
                      }
                      activePage={activePage}
                      itemsCountPerPage={itemsForPage}
                      totalItemsCount={totalItems}
                      pageRangeDisplayed={range}
                      onChange={handleChangePage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <HolidayForm show={showModal} data={dataHoliday} onRefresh={fetchData} box_engine_cf={box_engine_cf} onHide={handleCloseModal} />
    </div>
  );
}

export default LeaveOfAbsenceLetterTableTab;
